<template>
  <div>
    <app-options
      :header-text="$t('view.apps.feed.name')"
      header-icon="comment"
      :app-filled="true"
      :app-with-type="appWithType"
      :show-comments-toggle="false"
    >
      <div class="p-3 rounded-md border border-gray-500 text-gray-700 text-sm">
        Sin opciones disponible
      </div>
    </app-options>
  </div>
</template>

<script>
import AppOptions from './app-options.vue';

export default {
  components: {
    AppOptions,
  },
  computed: {
    appWithType() {
      return {
        app: {},
        appType: 'FeedApp',
        commentsEnabled: true,
      };
    },
  },
};
</script>
