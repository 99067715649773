import { eventBus } from '../packs/application';

export default {
  created() {
    window.addEventListener('click', this.clickCommand);
  },
  destroyed() {
    window.removeEventListener('click', this.clickCommand);
  },
  methods: {
    clickCommand(clickEvent) {
      try {
        if (this.$refs.dropDown.id !== clickEvent.target.id) {
          this.close();
        }
        if (clickEvent.target.id === 'app-grid-container' || clickEvent.target.id === 'tabs-container' ||
          clickEvent.target.id === 'wrapper-grid' || clickEvent.target.id.includes('matrix-element') ||
          clickEvent.target.id.includes('sub-grid')
        ) {
          if (window.appsEditable && !window.withZoom) {
            window.grid.enable();
          }
          eventBus.$emit('unselectedContainers', clickEvent);
          const targets = document.getElementsByClassName('selected');
          Array.prototype.forEach.call(targets, (target) => {
            target.classList.remove('selected');
          });
        }
      } catch (err) {
        console.log('click handler not registered');
      }
    },
  },
};
