<template>
  <div
    v-if="!showContainer"
    :ref="appType"
    class="menu-app-container flex flex-col justify-center align-items-center cursor-pointer"
    :class="buttomBorder"
    :title="appTitle"
    @click="createNewAppInGrid"
  >
    <i
      class="material-icons  material-icons-apps"
      :class="iconColor"
    >{{ icon }}</i>
    <p class="text-app-title">
      {{ appTitleToShow }}
    </p>
  </div>

  <div
    v-else
    :ref="appType"
    class="w-16"
    title="Add more apps from the Bigstore"
    @click="openBigstoreModal"
  >
    <i class="material-icons cursor-grab">{{ icon }}</i>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import _ from 'lodash';
import 'quill/dist/quill.core.css';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';
import objectIdMixin from '../mixins/objectIdMixin';
import positionMixin from '../mixins/positionMixin';
import { APP_TYPES, APP_CATEGORIES, APP_DIMENSIONS_BY_TYPE } from '../constants';
import {
  OPEN_BIGSTORE_MODAL,
  SAVE_CONTAINER,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';

export default {
  mixins: [dragAndResizeMixin, objectIdMixin, positionMixin],
  components: {
  },
  props: {
    appType: { type: String, default: 'app_table' },
    icon: { type: String, default: 'table_chart' },
    showContainer: { type: Boolean, default: false },
    name: { type: String, default: null },
    category: { type: String, default: null },
    template: { type: Object, default: null },
  },
  data() {
    return {
      title: '',
      content: null,
      appTypes: APP_TYPES,
      appCategories: APP_CATEGORIES,
      appDimensionsByType: APP_DIMENSIONS_BY_TYPE,
      customToolbar: customToolbarHelper.customToolbar,
      container: null,
    };
  },
  computed: {
    ...mapState({
      selectedView: state => state.views.selected,
      appContainers: state => state.views.appContainers,
    }),
    appContainersFiltered() {
      const filtered = _.pickBy(this.appContainers, (container) => container.appType !== undefined);

      return filtered;
    },
    appTitle() {
      if (!this.appType.startsWith('Custom')) {
        return this.appType.replace(/([A-Z])/g, ' $1').replace('App', '');
      }

      return this.name;
    },
    appTitleToShow() {
      if (!this.appType.startsWith('Custom')) {
        if (this.appType === 'WallApp') {
          return 'Feed';
        }

        return this.appType.replace(/([A-Z])/g, ' $1').replace('App', '');
      }

      return this.name;
    },
    iconColor() {
      if (this.category) {
        switch (this.category) {
        case this.appCategories.BASIC:
          return 'material-icons-apps--vivel';
        case this.appCategories.DATA_DISPLAYS:
          return 'material-icons-apps--yellow';
        case this.appCategories.OTHER:
          return 'material-icons-apps--green';
        default:
          return 'material-icons-apps--black';
        }
      }

      return null;
    },
    buttomBorder() {
      if (this.iconColor) {
        if (this.iconColor.includes('vivel')) return 'app-border--vivel';
        if (this.iconColor.includes('yellow')) return 'app-border--yellow';
        if (this.iconColor.includes('green')) return 'app-border--green';

        return 'app-border';
      }

      return null;
    },

  },
  methods: {
    openBigstoreModal() {
      this.$store.dispatch(OPEN_BIGSTORE_MODAL);
    },
    updateContainer() {
      console.log('calling API');
    },
    findLastContainerVertically() {
      const lastContainerId = Object.keys(this.appContainersFiltered).reduce((a, b) =>
        (this.appContainersFiltered[a].position.y > this.appContainersFiltered[b].position.y ? a : b));
      if (lastContainerId) {
        const lastContainer = this.appContainersFiltered[lastContainerId];

        return lastContainer;
      }

      return null;
    },
    async createNewAppInGrid() {
      let params = {};
      if (Object.keys(this.appContainersFiltered).length) {
        const lastContainer = this.findLastContainerVertically();
        if (lastContainer) {
          params = {
            title: 'Sin Titulo', appType: this.appType,
            position: {
              x: 0,
              y: lastContainer.position.y + lastContainer.position.h,
              w: this.appDimensionsByType.defaultWidth[this.appType],
              h: this.appDimensionsByType.defaultHeight[this.appType],
            },
            row: 0, app: {},
          };
        }
      } else {
        params = {
          title: 'Sin Titulo', appType: this.appType,
          position: {
            x: 0,
            y: 0,
            w: this.appDimensionsByType.defaultWidth[this.appType],
            h: this.appDimensionsByType.defaultHeight[this.appType],
          },
          row: 0, app: {},
        };
      }
      if (this.appType === 'CustomApp' && this.template) {
        params.app = { 'custom_app_template_id': this.template.id };
      }
      const newContainer = await this.$store.dispatch(SAVE_CONTAINER,
        { params, containerId: null });
      window.grid.makeWidget(`#${newContainer.id}`);
    },
  },

};
</script>
<style>

.floating-container {
  width: 547px;
  height: 328px;
}

.floating-container-text {
  width: 547px;
  min-height: 179px;
}

.unset-input {
  background: unset;
  border: unset;
}

.cursor-grab {
  cursor: grab;
}

.menu-app-container {
  width: 73px;
  height: 73px;
  margin-right: 5px;
  background-color: #fff;
}

.text-app-title {
  font-size: 10px;
  color: #555859;
  opacity: 1;
  letter-spacing: 0;
}
</style>
