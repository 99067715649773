<template>
  <div>
    <modal
      name="user"
      :width="670"
      :min-height="534"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header">
        {{ this.editMode ? 'Edit Collaborator' : $t("user.invite") }}
      </div>
      <div class="app-modal__inputs">
        <input
          v-model="email"
          type="email"
          @keyup="validateEmail"
          :placeholder="$t('user.email')"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
        >
        <p
          v-if="invalidEmail"
          class="text-red-600"
        >
          Email is incorrecto
        </p>
        <input
          v-model="fullName"
          type="text"
          :placeholder="$t('user.nameTag')"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
        >
        <div class="flex mb-4">
          <div class="w-1/2 relative">
            <input
              ref="roles"
              placeholder="Select a Role"
              type="text"
              v-model="role"
              @focus="toggleList = !toggleList"
              class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
            >
            <i
              @click.stop="toggleList = !toggleList"
              class="material-icons-outlined material-icons--secondary
              absolute right-0 center-top cursor-pointer rotated"
              :class="{'rotate-down': toggleList}"
            >
              arrow_drop_down
            </i>
            <div
              @click.stop="toggleList = true"
              class="w-full h-full opacity-0 absolute inset-0"
            />
            <div
              ref="dropDown"
              id="dropDown"
              v-if="toggleList"
              class="w-full bg-white height-232 shadow absolute top-48 overflow-y-auto z-10"
            >
              <div
                @click="selectRole(roleOption)"
                :key="index"
                v-for="(roleOption, index) in roleOptions"
                class="height-38 border-b border-input font-size-14 text-menu-label pl-4
                flex items-center hover:bg-menu-hover cursor-pointer"
              >
                {{ roleOption[0] }}
              </div>
            </div>
          </div>

          <div class="ml-2 w-1/2 relative">
            <input
              ref="groups"
              placeholder="Choose Groups"
              type="text"
              v-model="writtingTag"
              @click.stop="toggleGroupList = true"
              @focus="toggleGroupList = !toggleGroupList"
              class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
            >
            <i
              @click.stop="toggleGroupList = !toggleGroupList"
              class="material-icons-outlined material-icons--secondary
              absolute right-0 center-top cursor-pointer rotated"
              :class="{'rotate-down': toggleGroupList}"
            >
              arrow_drop_down
            </i>

            <div
              ref="dropDown"
              id="dropDown"
              v-if="toggleGroupList"
              class="w-full bg-white height-232 shadow absolute top-48 overflow-y-auto z-10"
            >
              <div
                @click.stop="isAdded(group) ? '': addGroup(group)"
                :key="index"
                v-for="(group, index) in filteredAutocomplete"
                class="height-38 border-b border-input font-size-14 text-menu-label pl-4
                flex items-center hover:bg-menu-hover"
                :class="{'cursor-pointer': !isAdded(group)}"
              >
                {{ group }}
                <span
                  v-if="isAdded(group)"
                  class="w-full self-center text-right mr-2"
                >
                  <i class="material-icons material-icons-inline material-icons-inline--light-green">done</i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <tags-input
          value=""
          :tags="selectedTags"
          :avoid-adding-duplicates="true"
          :placeholder="$t('user.tagsModal.tagPlaceholder')"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateSelectedTags"
          :validation="tagValidations"
          :delete-on-backspace="false"
        />

        <button
          class="height-48 width-306 margin-ontop"
          :class="{
            'share-resource-modal__btn--disabled': !requiredInputsFilled,
            'bg-icon-secondary text-white rounded-lg' : requiredInputsFilled
          }"
          @click="save"
        >
          {{ this.editMode ? 'Edit Collaborator' : 'Send Invitation' }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import TagsInput from '@johmun/vue-tags-input';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { CLOSE_USER_MODAL, SAVE_USER } from '../store/action-types';
import { ADD_TENANT_TAGS } from '../store/mutation-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';

const DEBOUNCE_TIME = 500;
export default {
  mixins: [escapeMixinHandler],
  components: {
    TagsInput,
  },
  props: {
    roleOptions: {
      type: Array,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const userf = this.$store.getters.selectedUser;

    return {
      firstName: _.get(userf, 'firstName'),
      lastName: _.get(userf, 'lastName'),
      fullName: _.get(userf, 'fullName'),
      email: _.get(userf, 'email'),
      invalidEmail: false,
      // role: _.get(userf, 'role'),
      role: userf ? _.find(this.roleOptions, (role) => role[1] === userf.role)[0] : '',
      roleId: userf ? _.find(this.roleOptions, (role) => role[1] === userf.role)[1] : '',
      profilePictureUrl: _.get(userf, 'profilePictureUrl'),
      loadingImage: false,
      fileSignedId: null,
      tag: '',
      writtingTag: '',
      toggleList: false,
      toggleGroupList: false,
      selectedTags: [],
      addedFromTagList: false,
      tagValidations: [{
        classes: 'avoid-item',
        rule: () => this.addedFromTagList,
        disableAdd: true,
      }],
    };
  },
  mounted() {
    this.$modal.show('user');
    if (this.user === undefined) {
      this.selectedTags = [];
    } else {
      this.selectedTags = this.user.tagList.map(t => ({ text: t }));
    }
  },
  computed: {
    ...mapGetters({
      user: 'selectedUser',
    }),
    ...mapState({
      tenantTags: state => state.users.tenantTags,
    }),
    filteredAutocomplete() {
      const self = this;
      const filteredResults = this.tenantTags.filter((t) => (
        // t.toLowerCase().indexOf(self.writtingTag.toLowerCase()) !== -1)).map(tag => ({ text: tag }));
        t.toLowerCase().indexOf(self.writtingTag.toLowerCase()) !== -1));
      this.setReadOnlyMode(filteredResults);

      return filteredResults;
    },
    userParams() {
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        role: this.roleId,
        tagList: this.selectedTags.map(tag => tag.text),
      };
      if (this.fileSignedId) params.profilePicture = this.fileSignedId;

      return params;
    },
    requiredInputsFilled() {
      return !!(this.fullName && this.email && this.role && !this.invalidEmail);
    },
  },
  methods: {
    validateEmail: _.debounce(function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      this.invalidEmail = !re.test(String(this.email).toLowerCase());
    }, DEBOUNCE_TIME),
    addGroup(group) {
      this.selectedTags.push({ text: group });
      this.$store.commit(ADD_TENANT_TAGS, this.tenantTags);
    },
    isAdded(group) {
      return this.selectedTags.map(tag => tag.text).includes(group);
    },
    selectRole(roleOption) {
      this.role = roleOption[0];
      this.roleId = roleOption[1];
      this.toggleList = false;
    },
    setReadOnlyMode(filteredResults) {
      if (filteredResults.length === 0) {
        this.addedFromTagList = true;
      } else {
        this.addedFromTagList = false;
      }
    },
    close() {
      if (this.toggleList || this.toggleGroupList) {
        this.toggleList = false;
        this.toggleGroupList = false;
      } else {
        this.$store.dispatch(CLOSE_USER_MODAL);
      }
    },
    setUserFullName() {
      const rawNameArray = this.fullName.split(' ');
      this.firstName = rawNameArray[0];
      if (rawNameArray.length > 1) {
        this.lastName = rawNameArray.slice(1, rawNameArray.length).join(' ');
      }
    },
    save() {
      this.setUserFullName();
      this.$store.dispatch(SAVE_USER, this.userParams).then(() => this.close());
    },
    handleImageUploaded({ url, fileSignedId }) {
      this.profilePictureUrl = url;
      this.fileSignedId = fileSignedId;
    },
    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },
  },
};
</script>

<style>

.height-48 {
  height: 48px;
}

.height-56 {
  height: 56px;
}

.text-size-16 {
  font-size: 16px;
}

.text-size-14 {
  font-size: 14px;
}

.center-top {
  top: 12px;
  right: 12px;
}

.height-38 {
  height: 38px;
}

.rotated {
  transform: rotate(90deg);
  transition: all .1s linear;
}

.rotate-down {
  transform: rotate(0);
}

input:disabled {
  background-color: #fff;
}

.width-306 {
  width: 306px;
}

.margin-ontop {
  margin-top: 70px;
}
</style>
