<template>
  <div>
    <div>
      <label
        id="dropdownBox-label"
        class="block text-sm font-medium"
      >
        <slot name="SelectLabel" />
      </label>
      <div
        class="relative"
        v-click-outside="closeDropdown"
      >
        <span class="inline-block w-full rounded-md shadow-sm btn-dimensions">
          <button
            type="button"
            @click="openDropdown"
            aria-haspopup="listbox"
            aria-expanded="true"
            aria-labelledby="dropdownBox-label"
            class="cursor-pointer relative w-full rounded-md border-gray-300
              bg-white pl-3 pr-10 py-1 text-left focus:outline-none
              focus:shadow-outline-blue transition ease-in-out duration-150
              sm:text-sm sm:leading-5 btn-dimensions select"
          >
            <div class="flex items-center space-x-3">
              <span
                class="block truncate font-inter-type-1 selected-text-color"
              >
                {{ currentSelectedOptions }}
              </span>
            </div>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <i
                class="w-2/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </span>
          </button>
        </span>
        <!-- Select popover, show/hide based on select state. -->
        <div
          v-show="isOpen"
          class="absolute mt-1 w-full rounded-md shadow-lg scrollable-option ul-style"
          :style="heightStyle"
        >
          <ul
            class=""
          >
            <!--
              Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
              Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
            -->
            <li
              tabindex="0"
              @click="select(option)"
              id="listbox-item-0"
              role="option"
              v-for="option in options"
              :key="option.id"
              class="border-t text-color cursor-default select-none
                relative py-2 pl-3 pr-9 cursor-pointer hover:text-white
                hover:bg-indigo-600 focus:outline-none"
            >
              <div class="flex items-center space-x-3">
                <input
                  class="checkbox-style"
                  type="checkbox"
                  v-model="option.checked"
                  :id="option.id"
                >
                <span
                  class="block truncate font-inter-type-1"
                >
                  {{ option.name }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ClickOutside from 'vue-click-outside';

Vue.directive('click-outside', {
  bin(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

export default {
  name: 'DropdownCheckbox',
  props: {
    options: { type: Array, default: () => [] },
    listener: { type: String, default: 'dropdownCheckboxListener' },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    heightStyle() {
      return 'height: 152px;';
    },
    currentSelectedOptions() {
      const checkedOptions = this.options.filter(option => option.checked);
      if (checkedOptions.length === 0) {
        return 'Nadie (excepto yo)';
      }
      const allNames = checkedOptions.map(checkedOption => checkedOption.name).join(', ');
      if (allNames.includes('Todos')) {
        return 'Todos';
      }

      return allNames;
    },
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    select(value) {
      this.$emit(this.listener, value);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.btn-dimensions {
  height: 32px;
}

.arrows-padding {
  padding-left: 18px;
}

.select {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.ul-style {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 0.5px solid #7A7A7A;
  opacity: 1;
}

.scrollable-option{
  height: 162px;
  overflow: auto;
  position: relative;
  z-index: 100;
}

.checkbox-style {
  accent-color: #0031FF;
  opacity: 1;
}

.text-color {
  color: #7A7A7A;
}

.selected-text-color {
  color: #555859;
}

</style>
