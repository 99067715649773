<template>
  <div
    class="grid-index"
    :class="[{'collapsed': false}]"
  >
    <div class="grid-index__header">
      <div
        v-if="inFavorites"
        class="w-3/4 text-lg text-gray-700"
      />
      <div
        v-else
        class="w-3/4 text-lg text-gray-700"
      >
        <a
          class="grid-index__folder-link"
          v-for="folder in currentFolder.parentTree"
          :key="folder.id"
          :href="showPath(folder.id)"
        >
          {{ folder.name }}/
        </a>
        <a> {{ currentFolder.name === 'Mi Unidad' || currentFolder.name === 'My Unit' ? '' : currentFolder.name }} </a>
      </div>
      <div
        v-if="!inFavorites"
        class="folder-list"
      >
        <button
          ref="dropDown"
          id="dropDown"
          v-if="currentFolder.enabledViewCreation || currentFolder.enabledFolderCreation"
          @click.stop="createDropdownOpen = !createDropdownOpen"
          class="h-10 w-24 bg-action-secondary rounded-lg outline-none"
        >
          <i class="material-icons  material-icons-menu--selected outline-none">add</i>
        </button>
        <transition name="fade">
          <div
            v-if="createDropdownOpen"
            class="absolute top-0 w-148 bg-white rounded-md shadow-md dropdown-center pt-2 pb-2"
          >
            <new-view-btn v-if="currentFolder.enabledViewCreation" />
            <div
              @click="openFolderModal"
              class="flex items-center cursor-pointer border-t hover:bg-menu-hover"
            >
              <i
                v-if="currentFolder.enabledFolderCreation"
                class="material-icons-outlined material-icons--secondary p-2"
              >
                create_new_folder
              </i>
              <div class="p-2 text-sm text-menu-label">
                {{ $t('view.newFolderButton') }}
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div v-if="!inFavorites">
      <div
        class="flex flex-row pb-6 "
      >
        <div class="w-3/4 text-lg text-title">
          Recents
        </div>
        <div class="w-1/4">
          <i
            @click="changeListMode"
            class="material-icons material-icons-blue new-btn__plus cursor-pointer float-right"
          >
            {{ listModeIcon }}
          </i>
        </div>
      </div>
      <div
        v-if="listModeIcon === 'view_list'"
        class="grid-index__grid"
      >
        <view-list-item
          v-for="view in latestBp"
          :key="view.id"
          :current-folder="currentFolder"
          :view="view"
          :is-admin="isAdmin"
        />
      </div>
      <div
        v-else
        class=""
      >
        <vuetable
          ref="vuetablelatest"
          :css="{
            tableWrapper: 'table-auto',
            tableClass: 'text-table text-sm font-normal',
            tableHeaderClass: '',
            tableBodyClass:'w-full grid-index__grid-table bg-white p-2 text-gray-600 text-sm font-normal rounded-lg',
          }"
          :api-mode="false"
          :fields="viewsHeaderTable"
          :data-manager="dataManagerRecents"
        >
          <div
            slot="icon"
            slot-scope="props"
            :style="{textAlign:'center'}"
          >
            <i class="material-icons-outlined material-icons">article</i>
          </div>
          <div
            slot="actionsNonAdmin"
            slot-scope="props"
            v-if="!isAdmin"
            :style="{textAlign:'center'}"
          >
            <a :href="viewPath(props.rowData)">
              <button
                class="btn btn--inverted-colors-no-border"
              >
                <i class="material-icons-outlined material-icons--primary">preview</i>
              </button>
            </a>
          </div>
          <div
            slot="actions"
            slot-scope="props"
            v-if="isAdmin"
          >
            <view-row-item :row-data="props.rowData" />
          </div>
        </vuetable>
      </div>
    </div>
    <div class="flex flex-row py-10">
      <div class="w-3/4 text-lg text-title">
        Folders
      </div>
      <div class="w-1/4">
        <i
          @click="changeListMode"
          class="material-icons material-icons-blue new-btn__plus cursor-pointer float-right"
        >
          {{ listModeIcon }}
        </i>
      </div>
    </div>
    <div class="grid-index__grid">
      <folder-list-item
        v-for="folder in displayableFolders"
        :key="folder.id"
        :folder="folder"
        :is-admin="isAdmin"
        :is-shareable="areFoldersShareable"
        :was-shared="folder.wasShared"
        :in-shared-folder="inSharedFolder"
      />
    </div>
    <div class="flex flex-row py-10">
      <div class="w-3/4 text-lg text-title">
        Bigpictures
      </div>
      <div class="w-1/4">
        <i
          @click="changeListMode"
          class="material-icons material-icons-blue new-btn__plus cursor-pointer float-right"
        >
          {{ listModeIcon }}
        </i>
      </div>
    </div>
    <div
      v-if="listModeIcon === 'view_list'"
      class="grid-index__grid"
    >
      <view-list-item
        v-for="view in displayableViews"
        :key="view.id"
        :current-folder="currentFolder"
        :view="view"
        :is-admin="isAdmin"
      />
    </div>
    <div v-else>
      <vuetable
        ref="vuetable"
        :css="{
          tableWrapper: 'table-auto',
          tableClass: 'text-table text-sm font-normal',
          tableHeaderClass: '',
          tableBodyClass:'w-full grid-index__grid-table bg-white p-2 text-gray-600 text-sm font-normal rounded-lg',
        }"
        :api-mode="false"
        :fields="viewsHeaderTable"
        :data-manager="dataManager"
      >
        <div
          slot="icon"
          slot-scope="props"
          :style="{textAlign:'center'}"
        >
          <i class="material-icons-outlined material-icons--primary">article</i>
        </div>
        <div
          slot="actionsNonAdmin"
          slot-scope="props"
          v-if="!isAdmin"
          :style="{textAlign:'center'}"
        >
          <a :href="viewPath(props.rowData)">
            <button
              class="btn btn--inverted-colors-no-border"
            >
              <i class="material-icons-outlined material-icons--primary">preview</i>
            </button>
          </a>
        </div>
        <div
          slot="actions"
          slot-scope="props"
          v-if="isAdmin"
        >
          <view-row-item :row-data="props.rowData" />
        </div>
      </vuetable>
    </div>

    <confirmation-modal v-if="confirmationModalOpened" />
    <share-resource-modal v-if="shareResourceModalOpened" />
    <move-resource-modal v-if="moveResourceModalOpened" />
    <folder-modal v-if="folderModalOpened" />
  </div>
</template>

<script>

import _ from 'lodash';
import Vuetable from 'vuetable-2';
import { mapState } from 'vuex';
import ViewListItem from './view-list-item';
import FolderListItem from './folder-list-item';
import NewViewBtn from './new-view-btn';
import ConfirmationModal from './confirmation-modal';
import ShareResourceModal from './share-resource-modal';
import MoveResourceModal from './move-resource-modal';
import FolderModal from './folder-modal';
import ViewRowItem from './view-row-item';
import {
  SET_NESTED_VIEWS,
  SET_NESTED_FOLDERS,
  SELECT_VIEW_FOR_DELETION,
  SELECT_RESOURCE_FOR_SHARING,
  SELECT_RESOURCE_FOR_MOVING,
} from '../store/action-types';
import {
  OPEN_FOLDER_MODAL,
  SET_CURRENT_FOLDER,
  UPDATE_SELECTED_VIEW,
  SET_PERSONAL_FOLDERS,
} from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';
import escapeMixin from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';
import api from '../api';

const MIN_WIDTH = 1600;
const MAX_RECENTS_BP = 4;

export default {
  mixins: [showMessageMixin, escapeMixin, clickMixinHandler],
  components: {
    ViewListItem,
    FolderListItem,
    FolderModal,
    NewViewBtn,
    ConfirmationModal,
    ShareResourceModal,
    Vuetable,
    MoveResourceModal,
    ViewRowItem,
  },
  props: {
    views: {
      type: Array,
      required: true,
    },
    currentFolder: {
      type: Object,
      required: true,
    },
    folders: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    areFoldersShareable: {
      type: Boolean,
      default: false,
    },
    personalFolders: {
      type: Array,
      required: true,
    },
    sharedFolders: {
      type: Array,
      required: true,
    },
    inSharedFolder: {
      type: Boolean,
      default: false,
    },
    inFavorites: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listModeIcon: 'view_list',
      perPage: 3,
      viewsHeaderTable: this.getHeader(),
      isCollapsed: false,
      createDropdownOpen: false,
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    if (this.inFavorites) {
      document.title = currentTitle + ' | ' + 'Favoritos';
    } else if (this.inSharedFolder) {
      document.title = currentTitle + ' | ' + 'Compartidos';
    } else if (this.currentFolder.name === 'My Unit'){
      document.title = currentTitle + ' | ' + 'Mi Unidad';
    } else if (this.currentFolder) {
      document.title = currentTitle + ' | ' + this.currentFolder.name;
    }
    this.$store.dispatch(SET_NESTED_VIEWS, this.views);
    this.$store.dispatch(SET_NESTED_FOLDERS, this.folders);
    this.$store.commit(SET_CURRENT_FOLDER, this.currentFolder);
    const allFolders = { personal: this.personalFolders, shared: this.sharedFolders };
    this.$store.commit(SET_PERSONAL_FOLDERS, allFolders);
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    ...mapState({
      displayableViews: state => state.views.list,
      displayableFolders: state => state.views.folderList,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      shareResourceModalOpened: state => state.ui.shareResourceModalOpened,
      moveResourceModalOpened: state => state.ui.moveResourceModalOpened,
      folderModalOpened: state => state.ui.folderModalOpened,
    }),
    latestBp() {
      return _.orderBy(this.displayableViews, 'updatedAt', ['desc']).slice(0, MAX_RECENTS_BP);
    },
  },
  watch: {
    displayableViews() {
      if (this.$refs.vuetable) this.$refs.vuetable.refresh();
    },
    latestBp() {
      if (this.$refs.vuetablelatest) this.$refs.vuetablelatest.refresh();
    },
  },
  methods: {
    openMoveResourceModal(view) {
      const options = { resource: view, resourceType: 'View' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
    },
    onResize() {
      if (window.outerWidth <= MIN_WIDTH) {
        this.isCollapsed = true;
      }
    },
    openFolderModal() {
      this.$store.commit(OPEN_FOLDER_MODAL);
      this.createDropdownOpen = false;
    },
    showPath(folderId) {
      if (this.inSharedFolder) return `/shared/${folderId}`;

      return `/folders/${folderId}`;
    },
    getListModeIcon() {
      if (this.listModeIcon === 'view_list') {
        this.listModeIcon = 'view_module';

        return;
      }
      this.listModeIcon = 'view_list';
    },
    viewPath(view) {
      return `/views/${view.id}`;
    },
    editPath(view) {
      return `/views/${view.id}/edit`;
    },
    changeListMode() {
      this.getListModeIcon();
    },
    getHeader() {
      const header = [{
        name: 'icon',
        title: '',
      },
      {
        name: 'title',
        title: this.$t('view.listTableTitles.name'),
        sortField: 'title',
      },
      {
        name: 'creatorName',
        title: this.$t('view.listTableTitles.owner'),
        sortField: 'creatorName',
      },
      {
        name: 'updatedAt',
        title: this.$t('view.listTableTitles.updatedAt'),
        sortField: 'updatedAt',
      },
      ];
      if (this.isAdmin) {
        header.push({ name: 'actions', title: '' });
      } else {
        header.push({ name: 'actionsNonAdmin', title: '' });
      }

      return header;
    },
    onActionClicked(action, data) {
      if (action === 'delete-item') {
        this.$store.dispatch(SELECT_VIEW_FOR_DELETION, data.id);
      } else if (action === 'share-item') {
        const options = { resource: data, resourceType: data.type };
        this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
      } else if (action === 'move-item') {
        const options = { resource: data, resourceType: data.type };
        this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
      } else if (action === 'copy-item') {
        this.duplicate(data);
      }
    },
    dataManager(sortOrder) {
      if (Object.keys(this.displayableViews).length < 1) return [];
      let local = Object.keys(this.displayableViews).map(ele => this.displayableViews[ele]);

      if (sortOrder.length > 0) {
        console.log('orderBy:', sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction,
        );
      }

      return local;
    },

    dataManagerRecents(sortOrder) {
      if (Object.keys(this.latestBp).length < 1) return [];
      let local = Object.keys(this.latestBp).map(ele => this.latestBp[ele]);

      if (sortOrder.length > 0) {
        console.log('orderBy:', sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction,
        );
      }

      return local;
    },
    duplicate(selectedView) {
      let msg;
      api.duplicateView(selectedView.id, this.currentFolder.id).then((view) => {
        this.$store.commit(UPDATE_SELECTED_VIEW, view);
        msg = this.$t('view.duplicateSuccessMessage');
        this.showMessage(msg);
      }).catch(() => {
        msg = this.$t('view.duplicateFailedMessage');
        this.showMessage(msg);
      });
    },
    close() {
      this.createDropdownOpen = false;
    },
  },
};
</script>
<style scoped>
.collapsed {
  padding-left: 80px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.grid-index__grid-table {
  max-width: 903px;

}

.dropdown-center {
  right: -16px;
}

table thead tr th {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a5464;
}

table tr td {
  padding-left: 15px;
}

table tr {
  height: 54px;
  text-align: left;
}

thead {
  border-bottom: 1px solid #edebf5;
}

table tbody tr:not(:last-child) {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:first-child {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:last-child {
  /* border-bottom: 1px solid #edebf5; */
  border-top: 1px solid #edebf5;
}

.vuetable-slot {
  text-align: center;
}

.vuetable-slot i {
  font-size: 1.2rem;
}

.w-148 {
  width: 148px;
}

</style>

