var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center w-full",class:{
    'flex-col': _vm.orientation === 'horizontal',
    'w-full': _vm.orientation === 'horizontal',
    'auto-height': _vm.orientation === 'horizontal',
    'h-full': _vm.orientation === 'vertical',
  },staticStyle:{"overflow-x":"hidden"},attrs:{"id":"tab-master-div"}},[_c('div',{staticClass:"flex items-center p-4 sticky",class:{
      'flex-col': _vm.orientation === 'vertical',
      'h-full': _vm.orientation === 'vertical',
      'h-16' : _vm.orientation === 'horizontal',
      'w-full': _vm.orientation === 'horizontal',
      'auto-height': _vm.orientation === 'horizontal',
      'vertical-top-padding': _vm.orientation === 'vertical',
    },style:(_vm.tabStyle),attrs:{"id":"orientation-div"}},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"text-left cursor-pointer tabs-font",staticStyle:{"flex-grow":"1","margin-left":"30px"},style:(_vm.isSelected(index)),attrs:{"id":"div-with-text"},on:{"click":function($event){return _vm.selectedTab(index)}}},[(_vm.orientation === 'horizontal')?_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-1/12 left-border",style:(_vm.selectedBorder(index))}),_vm._v(" "),_c('div',{staticClass:"w-11/12"},[_vm._v("\n          "+_vm._s(tab.title)+"\n        ")])]):_c('div',{staticClass:"flex flex-row-reverse"},[_c('div',{staticClass:"w-1/12 left-border",style:(_vm.selectedBorder(index))}),_vm._v(" "),_c('div',{staticClass:"w-11/12"},[_vm._v("\n          "+_vm._s(tab.title)+"\n        ")])])])}),0),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }