import _ from 'lodash';
import * as mutations from '../../mutation-types';

export default {
  [mutations.SET_SELECTED_RESOURCE](state, { resource, resourceType }) {
    if (resourceType === 'Folder') {
      state.selectedFolder = resource;
    } else if (resourceType === 'View'){
      state.selectedId = resource.uuid;
    } else {
      state.selectedId = resource.id;
    }
  },
  [mutations.UNSET_SELECTED_RESOURCE](state, resourceType) {
    if (resourceType === 'Folder') {
      state.selectedFolder = null;
    } else {
      state.selectedId = null;
    }
  },
  [mutations.PERMIT_USER_IN_SELECTED_RESOURCE](state, { userId, resourceType }) {
    const resource = (resourceType === 'Folder') ? state.selectedFolder : state.selected ||
    state.list[state.selectedId];
    resource.usersWithPermission.push(userId);
  },
  [mutations.UNPERMIT_USER_IN_SELECTED_RESOURCE](state, { userId, resourceType }) {
    const resource = (resourceType === 'Folder') ? state.selectedFolder : state.selected ||
    state.list[state.selectedId];
    const permitedUsersCopy = [...resource.usersWithPermission];
    const index = permitedUsersCopy.findIndex(user => user === userId);
    permitedUsersCopy.splice(index, 1);
    resource.usersWithPermission = [...permitedUsersCopy];
  },
  [mutations.SET_SELECTED_FOLDER](state, folder) {
    state.selectedFolder = folder;
  },
  [mutations.SET_FOLDERS](state, folders) {
    state.folderList = folders;
  },
  [mutations.ADD_FOLDER](state, folder) {
    state.folderList = { ...state.folderList, [folder.id]: folder };
  },
  [mutations.ADD_PERSONAL_FOLDER](state, folder) {
    // state.personalFolders= { ...state.personalFolders, [folder.id]: folder };
    state.personalFolders.personal = [...state.personalFolders.personal, folder];
  },
  [mutations.UPDATE_SELECTED_FOLDER](state, folder) {
    state.folderList = { ...state.folderList, [folder.id]: folder };
    const folderIndex = _.findIndex(state.personalFolders.personal, (pf) => pf.id === folder.id);
    state.personalFolders.personal.splice(folderIndex, 1, folder);
  },
  [mutations.REMOVE_FOLDER](state) {
    const foldersCopy = { ...state.folderList };
    const personalFoldersCopy = [...state.personalFolders.personal];
    delete foldersCopy[state.selectedFolder.id];
    _.remove(personalFoldersCopy, (pf) => pf.id === state.selectedFolder.id);
    state.folderList = { ...foldersCopy };
    state.personalFolders.personal = [...personalFoldersCopy];
  },
  [mutations.SET_CURRENT_FOLDER](state, folder) {
    state.currentFolder = folder;
  },
  [mutations.SET_PERSONAL_FOLDERS](state, folders) {
    state.personalFolders = folders;
  },
  [mutations.SET_VIEWS](state, views) {
    state.list = views;
  },
  [mutations.SET_SELECTED_VIEW_ID](state, id) {
    state.selectedId = id;
  },
  [mutations.SET_SELECTED_VIEW](state, view) {
    state.selected = view;
  },
  [mutations.REMOVE_VIEW](state) {
    const viewsCopy = { ...state.list };
    delete viewsCopy[state.selectedId];
    state.list = { ...viewsCopy };
  },
  [mutations.UPDATE_SELECTED_VIEW](state, view) {
    if (state.list) {
      state.list = { ...state.list, [state.selectedId]: view };
    } else {
      state.selected = view;
    }
  },
  [mutations.UPDATE_VIEWS](state, view) {
    if (state.list) {
      state.list = { ...state.list, [view.uuid]: view };
    } else {
      state.selected = view;
    }
  },
  [mutations.PERMIT_USER_IN_SELECTED_VIEW](state, userId) {
    const view = state.list ? state.list[state.selectedId] : state.selected;
    view.usersWithPermission.push(userId);
  },
  [mutations.UNPERMIT_USER_IN_SELECTED_VIEW](state, userId) {
    const view = state.list ? state.list[state.selectedId] : state.selected;
    const permitedUsersCopy = [...view.usersWithPermission];
    const index = permitedUsersCopy.findIndex(user => user === userId);
    permitedUsersCopy.splice(index, 1);
    view.usersWithPermission = [...permitedUsersCopy];
  },
  [mutations.SET_APP_CONTAINERS](state, appContainers) {
    console.log(appContainers);
    state.appContainers = appContainers;
  },
  [mutations.SET_SELECTED_CONTAINER_ID](state, id) {
    state.selectedContainerId = id;
  },
  [mutations.SET_SELECTED_CONTAINER](state, container) {
    state.selectedContainer = container;
  },
  [mutations.SET_SELECTED_CHECKED_FILTER_INDEX](state, index) {
    state.selectedCheckedFilterIndex = index;
  },
  [mutations.SET_SELECTED_UNCHECKED_FILTER_INDEX](state, index) {
    state.selectedUncheckedFilterIndex = index;
  },
  [mutations.REMOVE_CONTAINER](state) {
    const containersCopy = { ...state.appContainers };
    delete containersCopy[state.selectedContainerId];
    state.appContainers = { ...containersCopy };

    const viewContainersCopy = [...state.selected.appContainers];
    const index = viewContainersCopy.findIndex(containerId => containerId === state.selectedContainerId);
    viewContainersCopy.splice(index, 1);
    state.selected.appContainers = [...viewContainersCopy];
  },
  [mutations.REMOVE_TAB_CONTAINER](state) {
    const containerId = state.tabContainerId;
    const customTabsCopy = { ...state.appContainers[containerId].app.customTabs };
    Object.keys(customTabsCopy).forEach((key) => {
      const index = customTabsCopy[key].appContainers.findIndex(app => app.id === state.selectedContainerId);
      if (index !== -1) {
        customTabsCopy[key].appContainers.splice(index, 1);
      }
    });
    state.appContainers[containerId].app.customTabs = customTabsCopy;
  },
  [mutations.UPDATE_CONTAINER](state, container) {
    state.appContainers = { ...state.appContainers, [container.id]: container };
  },
  [mutations.ADD_CONTAINER](state, container) {
    state.appContainers = { ...state.appContainers, [container.id]: container };
  },
  [mutations.SET_MAX_APP_WIDTH](state, maxAppWidth) {
    state.maxAppWidth = maxAppWidth;
  },
  [mutations.SET_VIEW_TEMPLATE](state, viewTemplate) {
    state.viewTemplate = viewTemplate;
  },
  [mutations.SET_INDUSTRY_TAGS](state, industryTags) {
    state.industryTags = industryTags;
  },
  [mutations.SET_COMPANY_SIZE_OPTIONS](state, companySizeOptions) {
    state.companySizeOptions = companySizeOptions;
  },
  [mutations.REVENUE_TYPE_OPTIONS](state, revenueTypeOptions) {
    state.revenueTypeOptions = revenueTypeOptions;
  },
  [mutations.PROCESS_OPTIONS](state, processOptions) {
    state.processOptions = processOptions;
  },
  [mutations.VIEWING_LEVEL_OPTIONS](state, viewingLevelOptions) {
    state.viewingLevelOptions = viewingLevelOptions;
  },
  [mutations.AREA_OPTIONS](state, areaOptions) {
    state.areaOptions = areaOptions;
  },
  [mutations.SET_APP_MODAL_TYPE](state, type) {
    state.appModalType = type;
  },
  [mutations.SET_APP_MODAL_TO_SHOW](state, id) {
    state.appModalToShow = id;
  },
  [mutations.SET_APP_MODAL_NAME](state, appName) {
    state.appModalName = appName;
  },
  [mutations.SET_SELECTED_CUSTOM_APP_TEMPLATE](state, template) {
    state.selectedCustomAppTemplate = template;
  },
  [mutations.SET_CUSTOM_APP_TEMPLATES](state, templates) {
    state.customAppTemplates = templates;
  },
  [mutations.UPDATE_SELECTED_CUSTOM_APP_TEMPLATE](state, customAppTemplate) {
    state.customAppTemplates = { ...state.customAppTemplates, [customAppTemplate.id]: customAppTemplate };
  },
  [mutations.ADD_COMMENTS](state, comments) {
    state.comments = { ...state.comments, ...comments };
  },
  [mutations.ADD_COMMENT](state, comment) {
    state.comments = { ...state.comments, [comment.id]: comment };
  },
  [mutations.ADD_COMMENT_TO_CONTAINER](state, { commentId, containerId }) {
    state.appContainers[containerId].comments.push(commentId);
  },
  [mutations.SET_THREAD_COMMENTS_TO_COMMENT](state, { threadCommentIds, parentCommentId }) {
    state.comments[parentCommentId].threadComments = threadCommentIds;
  },
  [mutations.ADD_THREAD_COMMENT_TO_COMMENT](state, { threadCommentId, parentCommentId }) {
    state.comments[parentCommentId].threadComments.push(threadCommentId);
  },
  [mutations.SET_SELECTED_COMMENT_ID](state, id) {
    state.selectedCommentId = id;
  },
  [mutations.REMOVE_COMMENT](state) {
    const appContainerId = state.comments[state.selectedCommentId].appContainerId;
    _.remove(state.appContainers[appContainerId].comments, (comment) => comment === state.selectedCommentId);

    const parentId = state.comments[state.selectedCommentId].parentId;
    if (parentId) {
      _.remove(state.comments[parentId].threadComments,
        (comment) => String(comment) === String(state.selectedCommentId));
    }

    const commentsCopy = { ...state.comments };
    delete commentsCopy[state.selectedCommentId];
    state.comments = { ...commentsCopy };
  },
  [mutations.REMOVE_CUSTOM_APP_TEMPLATE](state) {
    const templatesCopy = { ...state.customAppTemplates };
    delete templatesCopy[state.selectedCustomAppTemplate.id];
    state.customAppTemplates = { ...templatesCopy };
  },
  [mutations.SET_PREVIEW_TEMPLATE_APP_OPEN](state, modalStatus) {
    state.openPreviewModal = modalStatus;
  },
  [mutations.SET_CONNECTIONS](state, connections) {
    state.connections = connections;
  },
  [mutations.ADD_DINAMYC_INSTANCE](state, instance) {
    state.dinamycallyCreatedContainerInstances[instance.uuid] = instance;
  },

  [mutations.IMAGE_UPLOADED](state, value) {
    state.imageUploaded = value;
  },
  [mutations.SET_MERGE_USER_EMAIL](state, value) {
    state.mergeUserEmail = value;
  },
  [mutations.SET_CUSTOM_TABS](state, { containerId, value }) {
    state.appContainers[containerId].app.customTabs = value;
    // state.selectedContainer.app.customTabs = value;
  },
  [mutations.SET_TAB_CONTAINER_ID](state, { value }) {
    state.tabContainerId = value;
  },
  [mutations.UPDATE_CONTAINED_CONTAINER](state, value) {
    console.log('customTabs', state.selectedContainer.app.customTabs);
    console.log('container value is ', value);
  },
  [mutations.SET_APP_TAB_COLORS](state, { containerId, value }) {
    // state.selectedContainer.app = { ...value };
    // state.appContainers = {
    //   ...state.appContainers,
    //   [state.selectedContainer.id]: {
    //     ...state.appContainers[state.selectedContainer.id],
    //     app: value } };
    state.appContainers[containerId].app = value;
  },
  [mutations.SET_TOOGLE_TOOLBAR_COLLAPSIBLE](state, value) {
    state.collapsibleIsOpen = !value;
  },
  [mutations.ADD_PUBLICATIONS](state, publications) {
    state.publications = publications;
  },
  [mutations.ADD_PUBLICATION](state, publication) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publicationToReplace = publicationsCopy.find(p => p.id === publication.id);
    const indexToReplace = publicationsCopy.indexOf(publicationToReplace);
    if (indexToReplace === -1) {
      publicationsCopy.push(publication);
    } else {
      publicationsCopy[indexToReplace] = publication;
    }
    state.publications = [...publicationsCopy];
  },
  [mutations.SET_SELECTED_PUBLICATION_ID](state, id) {
    state.selectedPublicationId = id;
  },
  [mutations.REMOVE_PUBLICATION](state) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publicationToDelete = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    const indexToDelete = publicationsCopy.indexOf(publicationToDelete);
    publicationsCopy.splice(indexToDelete, 1);
    state.publications = [...publicationsCopy];
  },
  [mutations.SET_COMMENTS_TO_PUBLICATION](state, { publicationCommentIds, publicationId }) {
    state.publications[publicationId].comments = publicationCommentIds;
  },
  [mutations.ADD_LINK_PREVIEW_METADATA](state, { metadata, publicationId }) {
    if (publicationId) {
      state.linkPreviewMetadataByPublicationId = {
        ...state.linkPreviewMetadataByPublicationId, [publicationId]: metadata };
    } else {
      state.linkPreviewMetadata = metadata;
    }
  },
  [mutations.ADD_PUBLICATION_COMMENT](state, { publicationId, comment }) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === publicationId);
    if (publication) {
      const indexToReplace = publicationsCopy.indexOf(publication);
      if (indexToReplace !== -1) {
        publicationsCopy[indexToReplace].comments.push(comment);
        publicationsCopy[indexToReplace].commentsCount += 1;
      }
      state.publications = [...publicationsCopy];
    }
  },
  [mutations.UPDATE_PUBLICATION_COMMENT](state, { publicationComment }) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    if (publication) {
      const publicationIndexToReplace = publicationsCopy.indexOf(publication);
      if (publicationIndexToReplace !== -1) {
        const comment = publicationsCopy[publicationIndexToReplace].comments.find(c => c.id === publicationComment.id);
        const commentIndexToReplace = publicationsCopy[publicationIndexToReplace].comments.indexOf(comment);
        if (commentIndexToReplace !== -1) {
          publicationsCopy[publicationIndexToReplace].comments[commentIndexToReplace] = publicationComment;
        }
      }
    }
    state.publications = [...publicationsCopy];
  },
  [mutations.REMOVE_PUBLICATION_COMMENT](state) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    const publicationIndexToReplace = publicationsCopy.indexOf(publication);
    if (publicationIndexToReplace !== -1) {
      const comment = publicationsCopy[publicationIndexToReplace]
        .comments.find(c => c.id === state.selectedCommentId);
      const commentIndexToDelete = publicationsCopy[publicationIndexToReplace].comments.indexOf(comment);
      if (commentIndexToDelete !== -1) {
        publicationsCopy[publicationIndexToReplace].comments.splice(commentIndexToDelete, 1);
        publicationsCopy[publicationIndexToReplace].commentsCount -= 1;
      }
    }
    state.publications = [...publicationsCopy];
  },
  [mutations.ADD_PUBLICATION_THREAD_COMMENTS](state, { comments, commentId }) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    if (publication) {
      const indexToReplace = publicationsCopy.indexOf(publication);
      if (indexToReplace !== -1) {
        const comment = publicationsCopy[indexToReplace].comments.find(c => c.id === commentId);
        const commentIndex = publicationsCopy[indexToReplace].comments.indexOf(comment);
        if (commentIndex !== -1) {
          publicationsCopy[indexToReplace].comments[commentIndex].threadComments = comments;
          publicationsCopy[indexToReplace].comments[commentIndex].threadCommentsCount = comments.length;
        }
      }
      state.publications = [...publicationsCopy];
    }
  },
  [mutations.ADD_PUBLICATION_THREAD_COMMENT](state, { parentCommentId, comment }) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(pu => pu.id === state.selectedPublicationId);
    if (publication) {
      const indexToReplace = publicationsCopy.indexOf(publication);
      if (indexToReplace !== -1) {
        if (publicationsCopy[indexToReplace].comments.find(c => c.id === parentCommentId).threadCommentsCount === 0) {
          publicationsCopy[indexToReplace].comments.find(c => c.id === parentCommentId).threadComments = [comment];
        } else {
          publicationsCopy[indexToReplace].comments.find(c => c.id === parentCommentId).threadComments.push(comment);
        }
        publicationsCopy[indexToReplace].comments.find(c => c.id === parentCommentId).threadCommentsCount += 1;
      }
      state.publications = [...publicationsCopy];
    }
  },
  [mutations.SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID](state, id) {
    state.selectedPublicationThreadCommentId = id;
  },
  [mutations.REMOVE_PUBLICATION_THREAD_COMMENT](state) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    const publicationIndexToReplace = publicationsCopy.indexOf(publication);
    if (publicationIndexToReplace !== -1) {
      const comment = publicationsCopy[publicationIndexToReplace]
        .comments.find(c => c.id === state.selectedCommentId);
      const commentIndexToDelete = publicationsCopy[publicationIndexToReplace].comments.indexOf(comment);
      if (commentIndexToDelete !== -1) {
        const threadComment = publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToDelete].threadComments.find(c => c.id === state.selectedPublicationThreadCommentId);
        const threadCommentIndexToDelete = publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToDelete].threadComments.indexOf(threadComment);
        publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToDelete]
          .threadComments
          .splice(threadCommentIndexToDelete, 1);
        publicationsCopy[publicationIndexToReplace].comments[commentIndexToDelete].threadCommentsCount -= 1;
      }
    }
    state.publications = [...publicationsCopy];
  },
  [mutations.UPDATE_PUBLICATION_THREAD_COMMENT](state, { publicationThreadComment }) {
    const publicationsCopy = JSON.parse(JSON.stringify(state.publications));
    const publication = publicationsCopy.find(p => p.id === state.selectedPublicationId);
    const publicationIndexToReplace = publicationsCopy.indexOf(publication);
    if (publicationIndexToReplace !== -1) {
      const comment = publicationsCopy[publicationIndexToReplace]
        .comments.find(c => c.id === state.selectedCommentId);
      const commentIndexToReplace = publicationsCopy[publicationIndexToReplace].comments.indexOf(comment);
      if (commentIndexToReplace !== -1) {
        const threadComment = publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToReplace].threadComments.find(c => c.id === state.selectedPublicationThreadCommentId);
        const threadCommentIndexToReplace = publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToReplace].threadComments.indexOf(threadComment);
        publicationsCopy[publicationIndexToReplace]
          .comments[commentIndexToReplace]
          .threadComments[threadCommentIndexToReplace] = publicationThreadComment;
      }
    }
    state.publications = [...publicationsCopy];
  },
  [mutations.ADD_MORE_PUBLICATIONS](state, { publications }) {
    const currentPublicationsIds = state.publications.map(p => p.id);
    const newPublications = publications.filter(p => !currentPublicationsIds.includes(p.id));
    state.publications.push(...newPublications);
  },
  [mutations.SET_APP_ATTRIBUTES](state, { containerId, params }) {
    state.appsAttrs = { ...state.appsAttrs, [containerId]: params };
  },
  [mutations.SET_SHOULD_SAVE](state, { containerId, value }) {
    state.shouldSaves = { ...state.shouldSaves, [containerId]: value };
  },
  [mutations.SET_APPS_DRAGGABLE](state, value) {
    state.appsDraggable = value;
  },
  [mutations.SET_DATA_SOURCE_UPDATED](state, value) {
    state.dataSourceUpdated = value;
  },
  [mutations.UPDATE_APP_FLOATING](state, containerId) {
    if (containerId in state.appFloatingUpdater) {
      state.appFloatingUpdater[containerId] += 1;
    } else {
      state.appFloatingUpdater[containerId] = 0;
    }
  },
  [mutations.SET_TAB_SELECTED_ID](state, id) {
    state.tabSelectedId = id;
  },
  [mutations.SET_MENU_WITH_ITEMS](state, menuWithItems) {
    state.menuWithItems = menuWithItems;
  },
};
