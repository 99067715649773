<template>
  <div>
    <modal
      name="newentry"
      :width="670"
      :min-height="534"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div
        v-if="editMode"
        class="app-modal__header"
      >
        Edit entry for: {{ this.selectedCustomType.name }}
      </div>

      <div
        v-else
        class="app-modal__header"
      >
        New entry for: {{ this.selectedCustomType.name }}
      </div>
      <div class="app-modal__inputs">
        <div
          :key="index"
          v-for="(customColumn,index) in selectedCustomColumn"
        >
          <input
            v-if="customColumn.type === 'text'"
            type="text"
            v-model="customModels[customColumn.name]"
            :placeholder="customColumn.name"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
          >

          <input
            v-if="customColumn.type === 'number'"
            type="number"
            v-model="customModels[customColumn.name]"
            :placeholder="customColumn.name"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
          >
          <date-picker
            v-else-if="customColumn.type === 'date'"
            placeholder="Select a Date"
            type="date"
            value-type="YYYY-MM-DD"
            format="YYYY-MM-DD"
            input-class="mx-input w-full height-48"
            v-model="customModels[customColumn.name]"
          />
          <select
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
            v-else-if="customColumn.type === 'boolean'"
          >
            <option value="true">
              Yes
            </option>
            <option value="false">
              No
            </option>
          </select>
          <image-upload
            v-else-if="customColumn.type === 'image'"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 mb-4"
            @loading-start="loadingImage = true"
            @loading-end="loadingImage = false"
            @uploaded="handleImageUploaded"
            :from="customColumn.name"
          >
            {{ customModels[customColumn.name] || `Select image for ${customColumn.name}` }}
          </image-upload>
        </div>

        <button
          class="height-48 width-306 margin-ontop bg-icon-secondary text-white rounded-lg"
          @click="save"
        >
          {{ this.editMode === true? 'Edit Entry ' : 'Create new Entry' }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  UPDATE_SELECTED_DATA,
  CLOSE_USER_DEFINED_TYPE_MODAL,
  SET_SELECTED_CUSTOM_DATUM,
} from '../store/mutation-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import api from '../api';

export default {
  mixins: [escapeMixinHandler],
  components: {
    DatePicker,
  },
  props: {
  },
  data() {
    return {
      customModels: {},
      imageList: {},
      fileList: {},
    };
  },
  mounted() {
    this.$modal.show('newentry');
    this.selectedCustomColumn.forEach(column => {
      // this.customModels[column.name] = this.selectedCustomDatum[column.name] || null;
      this.customModels[column.name] = this.getValue(column.type, this.selectedCustomDatum[column.name]);
    });
    this.customModels = { ...this.customModels };
  },
  computed: {
    ...mapState({
      selectedCustomColumn: state => state.users.selectedCustomColumn,
      selectedCustomType: state => state.users.selectedCustomType,
      selectedCustomDatum: state => state.users.selectedCustomDatum,
    }),
    editMode() {
      return !_.isEmpty(this.selectedCustomDatum);
    },
    datumParam() {
      const params = {
        userTypeDatum: {
          files: this.getSignedIds(this.fileList),
          images: this.getSignedIds(this.imageList),
          dataRow: { ...this.customModels },
        },
      };

      return params;
    },
  },
  methods: {
    getValue(columnType, value) {
      if (value && columnType === 'image') {
        const fileName = value.split('/');

        return fileName[fileName.length - 1];
      }

      return value || null;
    },
    getImageName(imgPath) {
      const pathArray = imgPath.split('/');

      return pathArray[pathArray.length - 1];
    },
    getSignedIds(objectList) {
      return Object.keys(objectList).reduce((imgList, imgKey) => {
        imgList.push(objectList[imgKey].fileSignedId);

        return imgList;
      }, []);
    },
    handleImageUploaded({ url, fileSignedId, name, from }) {
      this.imageUrl = url;
      this.fileSignedId = fileSignedId;
      this.imageName = name;
      this.customModels[from] = name;
      this.imageList[from] = { name, fileSignedId };
      // this.$store.commit(IMAGE_UPLOADED, true);
    },
    close() {
      this.$store.commit(CLOSE_USER_DEFINED_TYPE_MODAL);
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, {});
    },
    setUserFullName() {
      const rawNameArray = this.fullName.split(' ');
      this.firstName = rawNameArray[0];
      if (rawNameArray.length > 1) {
        this.lastName = rawNameArray.slice(1, rawNameArray.length).join(' ');
      }
    },
    save() {
      if (this.editMode) {
        api.updateUserDefinedTypeData(
          this.selectedCustomType.id,
          this.selectedCustomDatum.id,
          this.datumParam,
        ).then(result => {
          if (result.errors) {
            this.showMessage(result.errors);
          } else {
            result.dataRow.createdAt = result.createdAt;
            result.dataRow.updatedAt = result.updatedAt;
            result.dataRow.id = result.id;
            this.$store.commit(UPDATE_SELECTED_DATA, result.dataRow);
            this.showMessage('Fila actualizada exitosamente');
          }
          this.close();
        });
      } else {
        api.createUserDefinedTypeData(this.selectedCustomType.id, this.datumParam)
          .then(result => {
            result.dataRow.createdAt = result.createdAt;
            result.dataRow.updatedAt = result.updatedAt;
            result.dataRow.id = result.id;
            this.$store.commit(UPDATE_SELECTED_DATA, result.dataRow);
            this.close();
          });
      }
    },
    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },
  },
};
</script>

<style>

</style>
