<template>
  <div
    id="tab-master-div"
    class="flex items-center w-full"
    :class="{
      'flex-col': orientation === 'horizontal',
      'w-full': orientation === 'horizontal',
      'auto-height': orientation === 'horizontal',
      'h-full': orientation === 'vertical',
    }"
    style="overflow-x: hidden;"
  >
    <div
      id="orientation-div"
      :style="tabStyle"
      class="flex items-center p-4 sticky"
      :class="{
        'flex-col': orientation === 'vertical',
        'h-full': orientation === 'vertical',
        'h-16' : orientation === 'horizontal',
        'w-full': orientation === 'horizontal',
        'auto-height': orientation === 'horizontal',
        'vertical-top-padding': orientation === 'vertical',
      }"
    >
      <div
        id="div-with-text"
        class="text-left cursor-pointer tabs-font"
        :style="isSelected(index)"
        style="flex-grow: 1; margin-left: 30px;"
        @click="selectedTab(index)"
        :key="index"
        v-for="(tab, index) in tabs"
      >
        <div
          v-if="orientation === 'horizontal'"
          class="flex flex-row"
        >
          <div
            class="w-1/12 left-border"
            :style="selectedBorder(index)"
          />
          <div class="w-11/12">
            {{ tab.title }}
          </div>
        </div>
        <div
          v-else
          class="flex flex-row-reverse"
        >
          <div
            class="w-1/12 left-border"
            :style="selectedBorder(index)"
          />
          <div class="w-11/12">
            {{ tab.title }}
          </div>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import {
  SET_TAB_SELECTED_ID,
} from '../store/mutation-types';

export default {
  props: {
    orientation: { type: String, required: true },
    colors: { type: Object, default: null },
    containerId: { type: Number, required: true },
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
      fontColor: _.get(this.colors, 'fontColor', '#ffffff'),
      fontColorActive: _.get(this.colors, 'fontColorActive', '#ffffff80'),
      backgroundColor: _.get(this.colors, 'backgroundColor', '#555859'),
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectedTab(0);
  },
  updated() {
    this.selectedTab(this.selectedIndex);
  },
  computed: {
    ...mapGetters({
      selectedContainer: 'selectedContainer',
    }),
    ...mapState({
      appContainers: state => state.views.appContainers,
    }),
    tabStyle() {
      const source = this.appContainers[this.containerId];

      return {
        'color': source.app.colors.fontColorActive,
        'backgroundColor': source.app.colors.backgroundColor,
      };
    },
    borderStyle() {
      return {
        'backgroundColor': this.fontColor,
      };
    },
  },
  methods: {
    selectedTab(i) {
      const tabInstance = this.appContainers[this.containerId].app.customTabs[i];
      this.$store.commit(SET_TAB_SELECTED_ID, tabInstance.id);
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i);
      });
      this.selectedIndex = i;
    },

    isSelected(index) {
      const source = this.appContainers[this.containerId];
      if (index !== this.selectedIndex) return { 'color': source.app.colors.fontColor };

      return {};
    },
    selectedBorder(index) {
      const source = this.appContainers[this.containerId];
      if (index !== this.selectedIndex) return { 'backgroundColor': source.app.colors.fontColor };

      return { 'backgroundColor': source.app.colors.fontColorActive };
    },
  },
};
</script>

<style scoped>

.tabs-font {
  font: normal normal bold 14px/22px Inter;
  letter-spacing: 0.7px;
  opacity: 1;
}

.left-border {
  width: 8px;
  margin-right: 10px;
}

.right-border {
  width: 8px;
  margin-left: 10px;
}

.auto-height {
  height: auto;
}

.vertical-top-padding {
  padding-top: 50px !important;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 900;
}

</style>
