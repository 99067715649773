import { normalize, schema } from 'normalizr';
import humps from 'humps';
import * as mutations from '../../mutation-types';
import * as actions from '../../action-types';
import api from '../../../api';

const templateEntity = new schema.Entity('templates');

export default {
  [actions.SET_TEMPLATES]({ commit }, templates) {
    const camelizedTemplates = humps.camelizeKeys(templates);
    const normalizedTemplates = normalize(camelizedTemplates, [templateEntity]).entities;
    commit(mutations.SET_TEMPLATES, normalizedTemplates.templates);
  },
  [actions.SELECT_TEMPLATE_FOR_EDITION]({ commit }, template) {
    commit(mutations.SET_SELECTED_TEMPLATE, template);
    commit(mutations.OPEN_TEMPLATE_MODAL);
  },
  [actions.SELECT_TEMPLATE_FOR_DELETION]({ commit }, template) {
    commit(mutations.SET_SELECTED_TEMPLATE, template);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_TEMPLATE);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_TEMPLATE_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'template.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_TEMPLATE_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_TEMPLATE, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_TEMPLATE]({ state, commit, dispatch }) {
    api.deleteTemplate(state.selected.id).then(() => {
      commit(mutations.REMOVE_TEMPLATE);
      dispatch(actions.UNSELECT_TEMPLATE_FOR_DELETION);
    });
  },
  [actions.CLOSE_TEMPLATE_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_TEMPLATE, null);
    commit(mutations.CLOSE_TEMPLATE_MODAL);
  },
  [actions.SAVE_TEMPLATE]({ state, commit }, params) {
    if (state.selected) {
      api.updateTemplate({ id: state.selected.id, params }).then((template) => {
        commit(mutations.UPDATE_SELECTED_TEMPLATES, template);
      });
    } else {
      api.createTemplate(params).then((newTemplate) => {
        // window.location.href = `/views/${newTemplate.viewId}/edit/`;
        window.location.href = `/view_templates/${newTemplate.id}/edit/`;
      });
    }
  },
};
