
import api from '../api';

const SHEET_MYMETYPE = 'application/vnd.google-apps.spreadsheet';

export default {
  data() {
    return {
      sheetFiles: [],
      sheets: [],
      googleSheetDocuments: [],
      excelFiles: [],
    };
  },
  methods: {
    getAllGSheetDocuments(documentSearchParam) {
      window.gapi.client.drive.files.list({
        'pageSize': 30, 'q': `mimeType='${SHEET_MYMETYPE}' and name contains '${documentSearchParam}'`,
        'fields': 'nextPageToken, files(id, name)',
      }).then((response) => {
        this.googleSheetDocuments = response.result.files;
      });
    },
    getFiles(name) {
      window.gapi.client.drive.files.list({
        'pageSize': 10, 'q': `mimeType='${SHEET_MYMETYPE}' and name contains '${name}'`,
        'fields': 'nextPageToken, files(id, name)',
      }).then((response) => {
        this.sheetFiles = response.result.files;
      });
    },
    getExcelFiles(name) {
      api.getDriveFiles(name, 'excel')
        .then((response) => {
          this.sheetFiles = response.files.filter(fil => fil.name.endsWith('xlsx'));
        });
    },
    getAllMicrosoftExcelFiles(name) {
      api.getDriveFiles(name, 'excel')
        .then((response) => {
          this.excelFiles = response.files.filter(fil => fil.name.endsWith('xlsx'));
        });
    },
    getCustomTables(name) {
      api.getDriveFiles(name, 'custom')
        .then((response) => {
          this.sheetFiles = response.files;
        });
    },
    getMetadata(file) {
      window.gapi.client.sheets.spreadsheets.get({
        'spreadsheetId': file.id,
        'includeGridData': 'false',
      }).then(response => {
        this.sheets = response.result.sheets;
      });
    },
    getExcelMetadata(file) {
      api.getDriveFileSheets(file.id, 'excel')
        .then((response) => {
          this.sheets = response.sheets.map(sheet => ({ properties: { title: sheet.name } }));
        });
    },
  },
};
