<template>
  <img
    id="dropDown"
    :src="url"
    class="profile-picture profile-picture--img"
    v-if="url"
  >
  <i
    id="dropDown"
    v-else
    class="material-icons profile-picture profile-picture--icon"
    :style="{color: iconColor}"
  >
    person
  </i>
</template>

<script>
export default {
  props: {
    url: { type: String, default: null },
    iconColor: { type: String, default: '#7a7a7a' },
  },
};
</script>
