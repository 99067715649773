<template>
  <div>
    <modal
      name="move-resource"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal share-resource-modal']"
    >
      <div
        class="share-resource-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="share-resource-modal__header">
        <i class="material-icons share-resource-modal__header-icon">snippet_folder</i>
        {{ folderPath }}
      </div>
      <div class="share-resource-modal__content">
        <div class="share-resource-modal__input-header">
          {{ $t('resource.move.destination') }}
        </div>
        <select
          v-model="selectedDestinationFolder"
          class="share-resource-modal__input"
        >
          <option
            value=""
            disabled
            selected="selected"
          >
            {{ $t("resource.move.selectDestinationFolder") }}
          </option>
          <option
            :key="folder.folderId"
            v-for="folder in folderPathNames()"
            :value="folder.folderId"
          >
            {{ folder.name }}
          </option>
        </select>
      </div>
      <button
        id="btn__move-resource"
        @click="moveResource"
        class="btn share-resource-modal__btn"
        :class="{ 'share-resource-modal__btn--disabled': !canSave }"
      >
        {{ $t("generalActions.save") }}
      </button>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import {
  UNSELECT_RESOURCE_FOR_MOVING,
  UPDATE_SELECTED_VIEW,
  SAVE_FOLDER,
} from '../store/action-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  data() {
    return {
      selectedDestinationFolder: '',
      type: '',
    };
  },
  computed: {
    ...mapState({
      currentFolder: state => state.views.currentFolder,
      currentView: state => state.views.selected,
      personalFolders: state => state.views.personalFolders,
    }),
    resource() {
      return this.$store.getters.selectedResource;
    },
    title() {
      return (this.resource.type === 'View') ? this.resource.title : this.resource.name;
    },
    folderPath() {
      if (!this.resource || !this.resource.parentTree) {
        return '';
      }
      let folderThree = this.resource.parentTree.reduce((path, folder) => `${path}${folder.name}/`, '');
      if (this.resource.type === 'Folder') {
        folderThree += `${this.resource.name}`;
      } else {
        folderThree += `${this.resource.title || 'Sin título'}`;
      }

      return folderThree;
    },

    canSave() {
      return this.selectedDestinationFolder !== '';
    },
  },
  mounted() {
    this.openModal();
    this.type = this.resource.type;
  },
  methods: {
    folderPathNames() {
      const personalFolder = this.personalFolders.personal;
      const sharedFolder = this.personalFolders.shared;
      const pathList = [];

      function traversePath(fol, parent) {
        fol.forEach(currentFolder => {
          pathList.push({ name: `${parent}/${currentFolder.name}`, folderId: currentFolder.id });
          traversePath(currentFolder.childs || [], `${parent}/${currentFolder.name}`);
        });
      }
      const copySharedFolder = [...sharedFolder];
      /*
      * The user is not allowed to write on General Shared Folder or (Compartidas Conmigo)
      */
      _.remove(copySharedFolder, (f) => f.name === 'General');
      traversePath(personalFolder, '');
      traversePath(copySharedFolder, '/Unidades Compartidas');

      return pathList;
    },
    openModal() {
      this.$modal.show('move-resource');
    },
    moveResource() {
      const params = { parentId: this.selectedDestinationFolder };
      if (this.resource.type === 'Folder') {
        this.$store.dispatch(SAVE_FOLDER, params).then(() => this.close());
      } else if (this.resource.type === 'View') {
        this.$store.dispatch(UPDATE_SELECTED_VIEW, params).then(() => this.close());
      }
    },
    close() {
      if (this.resource && this.type) {
        this.$store.dispatch(UNSELECT_RESOURCE_FOR_MOVING, this.type);
      }
    },
  },
};
</script>
