<template>
  <div>
    <modal
      name="user-tags"
      :width="670"
      :min-height="534"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal share-resource-modal']"
    >
      <div
        class="share-resource-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="share-resource-modal__header">
        {{ editMode ? 'Edit Workspace' : $t('workspace.title') }}
      </div>
      <div class="share-resource-modal__content">
        <div class="app-modal__inputs--tags">
          <input
            ref="taginput"
            placeholder="Name your workspace"
            type="text"
            autofocus
            v-model="workspaceName"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 leading-snug"
          >
        </div>
        <div class="w-full relative margin-bottom-16">
          <input
            ref="taginput"
            placeholder="Assign Owner"
            type="text"
            v-model="writingUser"
            @click.stop="toggleList = true"
            @focus="toggleList = !toggleList"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
          >
          <i
            @click.stop="toggleList = !toggleList"
            class="material-icons-outlined material-icons--secondary absolute right-0 center-top cursor-pointer rotate"
            :class="{'rotate-iso': toggleList}"
          >
            add_circle_outline
          </i>
          <div
            ref="dropDown"
            id="dropDown"
            v-if="toggleList"
            class="w-full bg-white height-232 shadow absolute top-48 overflow-y-auto z-10"
          >
            <div
              v-if="filteredAutocompleteUsers.length === 0"
              class="border-b border-input flex items-center"
            >
              <p class="text-menu-label font-size-13">
                No matches
              </p>
            </div>
            <div
              v-else
              :key="user.id"
              v-for="user in filteredAutocompleteUsers"
              class="height-56 border-b border-input flex items-center"
            >
              <div class="w-1/12 ml-4">
                <img
                  :src="user.profilePictureUrl || profilePlaceholder"
                  alt="pp"
                  class="rounded-full bg-gray-500 profile-square"
                >
              </div>

              <div class="w-5/12">
                <p
                  :title="user.fullName"
                  class="truncate text-menu-label font-size-13"
                >
                  {{ user.fullName }}
                </p>
                <p class="font-size-13 text-setting-sublabel">
                  {{ user.role }}
                </p>
              </div>

              <div class="w-4/12">
                <p
                  :title="user.email"
                  class="truncate font-size-13 text-setting-sublabel"
                >
                  {{ user.email }}
                </p>
              </div>

              <div
                v-if="isAdded(user)"
                class="w-2/12 self-center text-center"
              >
                <i class="material-icons material-icons-inline material-icons-inline--light-green">done</i>
              </div>
              <div
                v-else
                @click.stop="addUser(user)"
                class="w-2/12 cursor-pointer"
              >
                <i class="material-icons material-icons-inline">add</i>
                <span class="text-icon-secondary font-size-13"> Add</span>
              </div>
            </div>
          </div>
          <div
            v-if="!editMode"
            class="px-2 py-2"
          >
            <label class="text-size-16 text-menu-label">
              Copiar recursos de un Workspace existente
              <input
                v-model="copyFromExistingWorkspace"
                type="checkbox"
              >
            </label>
          </div>
          <div
            v-if="copyFromExistingWorkspace && !editMode"
            class="px-2 py-4"
          >
            <label class="text-size-16 text-menu-label">
              <select
                class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 leading-snug"
                v-model="workspaceFrom"
              >
                <option
                  disabled
                  value="placeholder"
                > Select a Workspace</option>

                <option
                  :value="workspace.id"
                  :key="index"
                  v-for="(workspace,index) in workspacesList"
                >
                  {{ workspace.name }}
                </option>
              </select>
              <div class="py-4 flex flex-col">
                <h3 class="py-4 text-size-18 text-title">
                  Select Resources
                </h3>
                <label>
                  View && Folders
                  <input
                    v-model="viewAndFolderCheck"
                    type="checkbox"
                  >
                </label>
                <label>
                  Databases
                  <input
                    v-model="databasesCheck"
                    type="checkbox"
                  >
                </label>
                <label>
                  Users
                  <input
                    v-model="userCheck"
                    type="checkbox"
                  >
                </label>
                <label>
                  Templates && Apps
                  <input
                    v-model="templatesCheck"
                    type="checkbox"
                  >
                </label>
              </div>
            </label>
          </div>
        </div>

        <button
          v-if="!editMode"
          class="height-48 width-306 margin-ontop"
          :class="{
            'share-resource-modal__btn--disabled': !canSave,
            'bg-icon-secondary text-white rounded-lg': canSave }"
          @click="createWorkspacePlatform"
        >
          Create Workspace
        </button>
        <button
          v-else
          class="height-48 width-306 margin-ontop"
          :class="{
            'share-resource-modal__btn--disabled': !canSave,
            'bg-icon-secondary text-white rounded-lg': canSave }"
          @click="updateWorkspacePlatform"
        >
          Save Changes
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import profilePlaceholder from '../images/upload-photo.png';
import api from '../api';

import {
  ADD_TAG,
  UPDATE_EDITED_TAG,
  REMOVE_SELECTED_TAG,
  SAVE_TAG_ASSIGNATIONS,
} from '../store/action-types';
import {
  CLOSE_WORKSPACE_MODAL,
  SET_TAG_REMOVE_MODE,
  SET_TAG_EDIT_MODE,
  UPDATE_WORKSPACES_LIST,
  SET_WORKSPACE_FOR_EDITION,
  SET_WORKSPACE_MODAL_EDIT_MODE,
} from '../store/mutation-types';
import { KEY_CODES } from '../constants';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';
import clickMixinHandler from '../mixins/clickMixinHandler';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  mixins: [escapeMixinHandler, showMessageMixin, clickMixinHandler],
  props: {
    editMode: { type: Boolean, default: false },
    platformProps: { type: Object, required: true },
  },
  data() {
    const newTagName = '';
    let alreadySelectedUsers = [];
    let selectedWorkspace = {};
    if (this.editMode) {
      selectedWorkspace = this.$store.state.users.selectedWorkspace;
      alreadySelectedUsers = selectedWorkspace.users.map(selected =>
        ({ text: selected.fullName, id: selected.id, email: selected.email }),
      );
    }

    return {
      addTagKeys: [KEY_CODES.ENTER, KEY_CODES.TAB, KEY_CODES.SPACE],
      workspaceName: _.get(selectedWorkspace, 'name', ''),
      toggleList: false,
      profilePlaceholder,
      writingTag: '',
      writingUser: '',
      selectedTags: [],
      selectedUsers: alreadySelectedUsers,
      selectedUserNormalized: {},
      newTagName,
      selectedWorkspace,
      oldTag: newTagName,
      addedFromTagList: false,
      inputActive: false,
      showTagState: null,
      copyFromExistingWorkspace: false,
      workspaceFrom: 'placeholder',
      tagValidations: [{
        classes: 'avoid-item',
        rule: () => this.addedFromTagList,
        disableAdd: true,
      }],
      viewAndFolderCheck: true,
      databasesCheck: true,
      userCheck: true,
      templatesCheck: true,
    };
  },
  computed: {
    ...mapState({
      tenantTags: state => state.users.tenantTags,
      usersTagged: state => state.users.usersTagged,
      currentCompanyId: state => state.users.currentCompanyId,
      tagEditMode: state => state.users.tagEditMode,
      tagRemoveMode: state => state.users.tagRemoveMode,
      selectedTag: state => state.users.selectedTag,
      userList: state => state.users.consultantsList,
      workspacesList: state => state.users.workspacesList,
    }),
    placeholderText() {
      if (this.selectedUsers.length > 0) {
        return '';
      }

      return 'Selected Consultant';
    },
    filteredAutocompleteItems() {
      const self = this;
      const filteredResults = this.tenantTags.filter((tag) => (
        tag.toLowerCase().indexOf(self.writingTag.toLowerCase()) !== -1))
        .map(t => ({ text: t }));
      this.setReadOnlyMode(filteredResults);

      return filteredResults;
    },
    filteredAutocompleteUsers() {
      const self = this;
      const filteredUsers = Object.keys(this.userList).filter((key) => self.userList[key].email.toLowerCase()
        .indexOf(self.writingUser
          .toLowerCase()) !== -1 ||
        self.userList[key].fullName.toLowerCase()
          .indexOf(self.writingUser.toLowerCase()) !== -1).map(key => (self.userList[key]));
      this.setReadOnlyMode(filteredUsers);

      return filteredUsers;
    },
    canSave() {
      const baseConditions = this.workspaceName !== '' > 0 &&
      (this.selectedUsers.length > 0 || this.isValidEmail(this.writingUser));
      if (this.copyFromExistingWorkspace) {
        return baseConditions && this.workspaceFrom !== 'placeholder';
      }

      return baseConditions;
    },
    hoverList() {
      if (this.showTagState === null) {
        return this.tenantTags.reduce((tagOjb, tag) => {
          tagOjb[tag] = false;

          return tagOjb;
        }, {});
      }

      return this.tenantTags.reduce((tagOjb, tag) => {
        if (this.showTagState === tag) {
          tagOjb[tag] = true;
        } else {
          tagOjb[tag] = false;
        }

        return tagOjb;
      }, {});
    },
    workspacePlatformParams() {
      const params = {
        workspace: {
          name: this.workspaceName,
          userList: this.selectedUsers.map(user => this.userList[user.id]),
          userWriting: this.writingUser,
        },
      };

      return params;
    },
    workspaceParams() {
      const params = {
        workspace: {
          name: this.workspaceName,
          copyFromWorkspace: this.workspaceFrom,
          copyResources: this.copyFromExistingWorkspace,
          viewFolders: this.viewAndFolderCheck,
          databases: this.databasesCheck,
          users: this.userCheck,
          templateApps: this.templatesCheck,
          userList: [{ email: this.writingUser }],
        },
      };

      return params;
    },
  },
  mounted() {
    this.openModal();
    document.activeElement.blur();
  },
  methods: {
    isValidEmail(email) {
      return EMAIL_REGEX.test(String(email).toLowerCase());
    },
    addUser(user) {
      this.writingUser = user.email;
      this.toggleList = !this.toggleList;
    },
    isAdded(user) {
      return this.selectedUsers.map(u => u.email).includes(user.email);
    },
    createWorkspacePlatform() {
      if (!this.canSave) return;
      api.createWorkspaceForPlatform(this.platformProps.id, this.workspaceParams)
        .then((newWorkspace) => {
          console.log('new workspace and users created');
          this.$store.commit(UPDATE_WORKSPACES_LIST, newWorkspace);
          this.close();
        });
    },
    setReadOnlyMode(filteredResults) {
      if (filteredResults.length === 0) {
        this.addedFromTagList = true;
      } else {
        this.addedFromTagList = false;
      }
    },
    onMouseHover(tag) {
      this.showTagState = tag;
    },
    onMouseLeave() {
      this.showTagState = null;
    },
    addTag() {
      const params = { id: this.currentCompanyId, newTag: this.newTagName };
      this.$store.dispatch(ADD_TAG, params);
      this.newTagName = '';
    },
    saveEditedTag() {
      this.$store.dispatch(UPDATE_EDITED_TAG, { oldValue: this.oldTag, newValue: this.newTagName });
      this.$store.commit(SET_TAG_EDIT_MODE, false);
      this.newTagName = '';
      this.inputActive = false;
    },
    openModal() {
      this.$modal.show('user-tags');
    },
    editTag(tag) {
      this.$store.commit(SET_TAG_EDIT_MODE, true);
      this.oldTag = tag;
      this.newTagName = tag;
      this.$refs.taginput.focus();
    },
    deleteTag(tag) {
      this.$store.commit(SET_TAG_REMOVE_MODE, true);
      this.$store.dispatch(REMOVE_SELECTED_TAG, tag);
    },
    selectTag(tag) {
      if (this.tagEditMode) {
        if (this.inputActive) {
          this.showMessage('Primero debes terminar de editar la etiqueta seleccionada');
        } else {
          this.$refs.taginput.focus();
          this.inputActive = true;
        }
      } else if (this.tagRemoveMode) {
        console.log('do nothing');
      } else {
        if (this.selectedTags.map(st => st.text).indexOf(tag) === -1) {
          this.selectedTags.push(createTag(tag, this.tenantTags));
        }
        this.writingTag = '';
      }
    },
    isDuplicated(tags, tag) {
      return tags.map(t => t.text).indexOf(tag.text) !== -1;
    },
    async setUserTags() {
      if (!this.canSave) return;
      const tagList = [this.newTagName];
      const userList = this.selectedUsers.map(user => user.id);

      this.addTag();
      this.$store.dispatch(SAVE_TAG_ASSIGNATIONS, { companyId: this.currentCompanyId, tagList, userList });
    },
    close() {
      if (this.toggleList) {
        this.toggleList = false;
      } else {
        this.$store.commit(CLOSE_WORKSPACE_MODAL);
        this.$store.commit(SET_WORKSPACE_MODAL_EDIT_MODE, false);
        this.$store.commit(SET_WORKSPACE_FOR_EDITION, null);
      }
    },
    updateWorkspacePlatform() {
      api.updateWorkspaceForPlatform(this.platformProps.id, this.selectedWorkspace.id, this.workspacePlatformParams)
        .then((newWorkspace) => {
          this.$store.commit(UPDATE_WORKSPACES_LIST, newWorkspace);
          this.close();
        });
    },
    deleteWorkspace() {
      api.removeWorkspacePlatform(this.platformProps.id, this.selectedWorkspace.id);
    },
    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },
    updateSelectedUsers(newUsers) {
      this.selectedUsers = newUsers;
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 1.17em;
  font-weight: 200;
}

.height-48 {
  height: 48px;
}

.height-56 {
  height: 56px;
}

.height-232 {
  max-height: 232px;
}

.text-size-16 {
  font-size: 16px;
}

.center-top {
  top: 12px;
  right: 12px;
}

.top-48 {
  top: 48px;
}

.profile-square {
  height: 40px;
  width: 40px;
}

.font-size-13 {
  font-size: 13px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.width-306 {
  width: 306px;
}

.margin-ontop {
  margin-top: 70px;
}

.rotate {
  transition: all .1s linear;
}

.rotate-iso {
  transform: rotate(45deg);
}

</style>

