<template>
  <div
    class="bg-white min-h-full container"
  >
    <div class="px-8 pt-4 pb-1 h-16 flex items-center">
      <div class="w-3/12 text-setting-label text-size-12 self-end">
        Workspace
      </div>
      <div class="w-6/12 text-setting-label text-size-12 self-end">
        Owner
      </div>
      <div class="w-3/12 text-center flex justify-end">
        <button
          @click="openWorkspacePlatformModal"
          class="border border-gray-400  rounded-100 px-4 py-2 cursor-pointer bg-buttom-primary"
        >
          <i
            class="material-icons material-icons-inline material-icons-active cursor-pointer"
          >add</i>
          <span class="text-settings-selected">Crear Workspace</span>
        </button>
      </div>
    </div>
    <div class="block">
      <settings-workspace-item
        v-for="(workspace, key, index) in workspacesList"
        :key="index"
        :workspace="workspace"
      />
    </div>

    <workspace-platform-modal
      v-if="openWorkspaceModalOpened"
      :platform-props="platformProps"
      :edit-mode="workspaceModalEditMode"
    />
  </div>
</template>
<script>

import { mapState } from 'vuex';
import SettingsWorkspaceItem from '../components/settings-workspace-item';
import WorkspacePlatformModal from './workspace-platform-no-c-modal';
import {
  OPEN_WORKSPACE_MODAL,
  SET_SELECTED_PLATFORM,
  SET_WORKSPACES_LIST,
  SET_CONSULTANTS,
} from '../store/mutation-types';

export default {
  components: {
    SettingsWorkspaceItem,
    WorkspacePlatformModal,
  },
  props: {
    workspacesProps: { type: Array, default: () => [] },
    platformProps: { type: Object, required: true },
    teammatesProps: { type: Array, required: true },
  },
  mounted() {
    this.$store.commit(SET_SELECTED_PLATFORM, this.platformProps);

    const normalizedWorkspaces = this.workspacesProps.reduce((normalizedWorkspace, currentWorkspace) => {
      normalizedWorkspace[currentWorkspace.id] = currentWorkspace;

      return normalizedWorkspace;
    }, {});
    this.$store.commit(SET_WORKSPACES_LIST, normalizedWorkspaces);

    const consultants = this.teammatesProps.reduce((usrObj, currentUser) => {
      usrObj[currentUser.id] = currentUser;

      return usrObj;
    }, {});
    this.$store.commit(SET_CONSULTANTS, consultants);
  },
  methods: {
    openWorkspacePlatformModal() {
      this.$store.commit(OPEN_WORKSPACE_MODAL);
    },
  },
  computed: {
    ...mapState({
      openWorkspaceModalOpened: state => state.ui.openWorkspaceModalOpened,
      workspaceModalEditMode: state => state.users.workspaceModalEditMode,
    }),
    workspacesList() {
      return this.workspacesProps.reduce((normalizedWorkspace, currentWorkspace) => {
        normalizedWorkspace[currentWorkspace.id] = currentWorkspace;

        return normalizedWorkspace;
      }, {});
    },
  },
};
</script>
<style scoped>
  .container {
    width: 886px;
    margin: auto;
    margin-top: 60px;
  }

  .rounded-100 {
    border-radius: 100px;
  }
</style>
