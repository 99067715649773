<template>
  <div
    ref="publication_comment"
    :class="{
      'normal-padding': !parentId,
      'thread-padding': parentId,
    }"
  >
    <div
      class="flex flex-row publication-box-normal"
    >
      <img
        class="w-1/6 avatar-image2"
        :src="this.userProfilePictureUrl"
      >
      <div class="w-5/6 flex flex-col">
        <div
          class="flex flex-row"
        >
          <span class="username-publication-font">{{ userDisplayName }}</span>
          <span class="datetime-publication-font">
            •
          </span>
          <span
            class="datetime-publication-font"
            :datetime="comment.createdAt"
            ref="timestamp"
          />
        </div>
        <div
          id="publication-box"
          class="w-100 flex flex-col"
          style="padding-right: 15.05px; padding-top: 5px;"
        >
          <div
            v-if="!selectedForEdition(comment)"
          >
            <span
              class="publication-font"
              v-autolinker="comment.content"
              style="white-space: pre-line; word-wrap: break-word;"
            />
          </div>
          <div
            v-else
          >
            <textarea-autoheight
              autofocus
              class="publication-font"
              :style="{width: '100%'}"
              v-model="editedContent"
              @keyup.enter.exact.prevent="updatePublicationComment"
              @keyup.esc.prevent="unselectPublicationCommentForEdition"
            />
          </div>
        </div>
      </div>
      <div
        v-if="currentUserId === comment.user.id && !selectedForEdition(comment)"
        class="comment__actions-wrapper"
      >
        <div
          @click="showActions = !showActions"
          class="comment__show-actions-btn"
        >
          <i
            class="material-icons material-icons-inline"
            :class="{
              'more-vert-normal': !comment.parentId,
              'more-vert-thread': comment.parentId,
            }"
          >more_vert</i>
        </div>
        <div
          class="publication-actions-dropdown"
          v-if="showActions === true"
        >
          <button
            @click="selectPublicationCommentForEdition(comment)"
            class="comment__action"
          >
            <i class="material-icons comment__action-icon pr-2">edit</i>Editar
          </button>
          <button
            @click="selectPublicationCommentForDeletion(comment)"
            class="comment__action"
          >
            <i class="material-icons comment__action-icon pr-2">delete</i>Eliminar
          </button>
        </div>
      </div>
    </div>
    <div
      class="comment__footer"
      v-if="!comment.parentId && canComment"
    >
      <template>
        <a
          v-if="!showThread"
          href="#"
          @click.prevent="openThread"
        >
          Responder
        </a>
        <a
          v-else
          href="#"
          @click.prevent="closeThread"
        >
          Cerrar
        </a>
        •
        <a
          href="#"
          @click.prevent="openThread"
        >
          Comentarios ({{ threadCommentsCount }})
        </a>
        •
      </template>
    </div>
    <publication-thread-comment
      v-if="showThread"
      :comments="threadComments"
      :parent-id="comment.id"
      :publication-id="publicationId"
      :can-comment="canComment"
    />
  </div>
</template>

<script>
/* global require */

import _ from 'lodash';
import { mapState } from 'vuex';
import { render, cancel } from 'timeago.js';
import PublicationThreadComment from './publication-thread-comment.vue';
import {
  UPDATE_SELECTED_PUBLICATION_COMMENT,
  SELECT_PUBLICATION_COMMENT_FOR_DELETION,
  GET_PUBLICATION_THREAD_COMMENTS,
  SELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION,
  UPDATE_SELECTED_PUBLICATION_THREAD_COMMENT,
} from '../store/action-types';
import {
  SET_SELECTED_COMMENT_ID,
  SET_SELECTED_PUBLICATION_ID,
  SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID,
} from '../store/mutation-types';
import profilePlaceholder from '../images/upload-photo.png';

export default {
  props: {
    comment: { type: Object, required: true },
    publicationId: { type: Number, default: null },
    parentId: { type: Number, default: null },
    canComment: { type: Boolean, default: false },
  },
  components: { PublicationThreadComment },
  data() {
    return {
      newComment: null,
      editedContent: null,
      showActions: false,
      showThread: false,
    };
  },
  computed: {
    members() {
      const resource = this.$store.getters.selectedResource;
      const alreadySharedUsers = _.cloneDeepWith(this.$store.getters.selectedResourceSharedUsers(resource.type));

      return alreadySharedUsers.map((user) => {
        if (user.profilePictureUrl === undefined || user.profilePictureUrl === null) {
          user.profilePictureUrl = profilePlaceholder;
        }

        return {
          name: user.displayName,
          avatar: user.profilePictureUrl,
        };
      });
    },
    ...mapState({
      users: state => state.users.list,
      selectedCommentId: state => state.views.selectedCommentId,
      selectedPublicationId: state => state.views.selectedPublicationId,
      selectedPublicationThreadCommentId: state => state.views.selectedPublicationThreadCommentId,
      currentUserId: state => state.users.currentId,
    }),
    threadComments() {
      if (this.parentId) {
        if (this.$store.state.views.publications.find(p => p.id === this.publicationId).comments
          .find(c => c.id === this.parentId).threadCommentsCount > 0) {
          return this.$store.state.views.publications
            .find(p => p.id === this.publicationId)
            .comments
            .find(c => c.id === this.parentId).threadComments;
        }

        return [];
      }

      if ('threadComments' in this.$store.state.views.publications.find(p => p.id === this.publicationId).comments
        .find(c => c.id === this.comment.id)) {
        return this.$store.state.views.publications
          .find(p => p.id === this.publicationId)
          .comments
          .find(c => c.id === this.comment.id).threadComments;
      }

      return [];
    },
    threadCommentsCount() {
      const commentCount = this.$store.getters.threadCommentsCount(this.comment.id);
      if (!commentCount) {
        return this.comment.threadCommentsCount;
      }

      return commentCount;
    },
    userDisplayName() {
      return this.comment.user.displayName;
    },
    userProfilePictureUrl() {
      return this.comment.user.profilePictureUrl;
    },
    canEditOrDelete() {
      return this.currentUserId === this.comment.user.id && !this.selectedForEdition(this.comment);
    },
  },
  mounted() {
    render(this.$refs.timestamp, 'es', { minInterval: 10 });
  },
  beforeDestroy() {
    cancel();
  },
  methods: {
    selectPublicationCommentForEdition(comment) {
      this.showActions = false;
      if (this.parentId) {
        this.$store.commit(SET_SELECTED_COMMENT_ID, this.parentId);
        this.$store.commit(SET_SELECTED_PUBLICATION_ID, this.publicationId);
        this.$store.commit(SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID, comment.id);
      } else {
        this.$store.commit(SET_SELECTED_COMMENT_ID, this.comment.id);
        this.$store.commit(SET_SELECTED_PUBLICATION_ID, this.publicationId);
      }
      this.editedContent = comment.content;
    },
    selectPublicationCommentForDeletion(comment) {
      this.showActions = false;
      if (comment.parentId) {
        this.$store.commit(SET_SELECTED_COMMENT_ID, this.parentId);
        this.$store.commit(SET_SELECTED_PUBLICATION_ID, this.publicationId);
        this.$store.dispatch(SELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION, comment.id);
      } else {
        this.$store.dispatch(SELECT_PUBLICATION_COMMENT_FOR_DELETION, comment.id);
      }
      this.$store.commit(SET_SELECTED_PUBLICATION_ID, this.publicationId);
    },
    unselectPublicationCommentForEdition() {
      this.$store.commit(SET_SELECTED_COMMENT_ID, null);
      this.$store.commit(SET_SELECTED_PUBLICATION_ID, null);
      this.editedContent = null;
    },
    updatePublicationComment() {
      if (!this.editedContent) return;

      this.editedContent = event.target.value;
      if (this.parentId) {
        this.$store.dispatch(UPDATE_SELECTED_PUBLICATION_THREAD_COMMENT, { content: this.editedContent }).then(() => {
          this.unselectPublicationCommentForEdition();
        });
      } else {
        this.$store.dispatch(UPDATE_SELECTED_PUBLICATION_COMMENT, { content: this.editedContent }).then(() => {
          this.unselectPublicationCommentForEdition();
        });
      }
    },
    selectedForEdition(comment) {
      if (this.parentId) {
        return this.editedContent !== null && this.selectedCommentId === this.parentId;
      }

      return this.selectedCommentId === comment.id && this.editedContent !== null;
    },
    openThread() {
      if (this.showThread) return;
      this.$store.commit(SET_SELECTED_PUBLICATION_ID, this.publicationId);
      if (this.parentId) {
        this.$store.dispatch(GET_PUBLICATION_THREAD_COMMENTS, this.parentId).then(() => {
          this.showThread = true;
        });
      } else {
        this.$store.dispatch(GET_PUBLICATION_THREAD_COMMENTS, this.comment.id).then(() => {
          this.showThread = true;
        });
      }
    },
    closeThread() {
      this.showThread = false;
    },
  },
};
</script>

<style scoped>
.publication-resource-margin {
  padding-top: 22px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.centered {
  display:block;
  margin:auto;
}

.publication-box-normal {
  padding: 17.8px 17.8px 17.8px 0;
  width: 100%;
  height: auto;
}

.publication-box-thread {
  padding: 17.8px 17.8px 0 0;
  width: 100%;
  height: auto;
}

.avatar-image2 {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 13px;
}

.comment-image {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 13px;
}

.comment-box {
  height: 38.9px !important;
}

.publication-text-box {
  margin-left: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
}

.publication-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  margin: 0 17.8px 0 17.8px;
  width: inherit;
}

.card-title {
  padding-top: 15px;
  font: normal normal bold 16px/20px Inter;
  text-align: left;
  letter-spacing: 0px;
  width: 80%;
  margin: 0 auto;
}

.card-text {
  padding-top: 6px;
  font: normal normal normal 10px/10px Inter;
  text-align: left;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  margin: 0 auto;
}

.card-block {
  background-color: #DDE6F779;
  padding-bottom: 15px;
  border-radius: 0 0 0.5rem 0.5rem;
}

.card-img-top {
  border-radius: 0.5rem 0.5rem 0 0;
}

.username-publication-font {
  font: normal normal 800 14px/17px Inter;
  letter-spacing: 0.34px;
  color: #2B2B2B;
  opacity: 1;
}

.datetime-publication-font {
  padding-top: 1.8px;
  padding-left: 7.55px;
  font: normal normal normal 11px/14px Inter;
  letter-spacing: 0.28px;
  color: #B3B3B3;
  opacity: 1;
}

.role-publication-font {
  font: normal normal normal 11px/14px Inter;
  letter-spacing: 0.28px;
  color: #7A7A7A;
  opacity: 1;
}

.publication_comments {
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #7A7A7A;
  opacity: 1;
}

.content-publication-font {
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #2B2B2B;
}

.comment__action-icon {
  margin-right: 0 !important;
  font-size: 1.2rem;
}

.options-item {
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}

.publication-font {
  font: normal normal normal 13px/17px Inter;
  letter-spacing: 0px;
  color: #2B2B2B;
}

textarea {
  border: none;
  outline: none;
}

.more-vert-normal {
  padding-left: 25px;
}

.more-vert-thread {
  padding-left: 16px;
}

.normal-padding {
  padding-left: 81px;
}

.thread-padding {
  padding-left: 54px;
}
</style>
