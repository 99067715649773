var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"height-32 w-208px flex absolute header-position items-center bg-buttom-primary text-settings-selected\n         cursor-pointer border-t border-workspace rounded-md shadow-md",on:{"click":function($event){$event.stopPropagation();_vm.open = true}}},[_c('div',{staticClass:"w-10/12 overflow-hidden"},[_c('p',{staticClass:"py-1 px-4 truncate font-14"},[_vm._v("\n      "+_vm._s(_vm.currentWorkspace.name)+"\n    ")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),(_vm.open)?_c('div',{ref:"dropDown",staticClass:"width-240 absolute z-300 bg-white items-center cursor-pointer shadow-md",class:{
      'dropdown': _vm.expandTo === 'top',
      'dropup': _vm.expandTo === 'bottom'
    },attrs:{"id":"switcher"}},_vm._l((_vm.displayableWorkspace),function(ws){return _c('div',{key:ws.id,staticClass:"flex border-b border-workspace cursor-pointer height-42 items-center w-full hover:bg-menu-hover",class:{'bg-gray-100': _vm.currentWorkspace.id !== ws.id},on:{"click":function($event){return _vm.goToWorkspace(ws.id)}}},[_c('div',{staticClass:"w-12/12"},[_c('p',{staticClass:"py-1 px-4 truncate font-14  ",class:{
            'text-settings-selected': _vm.currentWorkspace.id === ws.id,
            'text-label': _vm.currentWorkspace.id !== ws.id,
            'font-semibold': !ws.id
          }},[_vm._v("\n          "+_vm._s(ws.name)+"\n        ")])])])}),0):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-2/12 py-2 cursor-pointer overflow-hidden"},[_c('span',{staticClass:"flex align-center"},[_c('i',{staticClass:"material-icons material-icons--primary"},[_vm._v("keyboard_arrow_down")])])])}]

export { render, staticRenderFns }