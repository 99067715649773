<template>
  <Collapsible
    class="width-collapsible"
    :is-open="true"
  >
    <div
      slot="trigger"
    >
      <div class="flex items-center">
        <p class="w-4/6 container-section-title text-base">
          COLUMNAS
        </p>
        <i
          class="w-2/6 material-icons material-icons-round cursor-pointer"
        >expand_more</i>
      </div>
    </div>
    <div slot="closedTrigger">
      <div class="flex items-center ">
        <p class="w-4/6 container-section-title text-base">
          COLUMNAS
        </p>
        <i
          class=" w-2/6 material-icons material-icons-round cursor-pointer"
        >chevron_right</i>
      </div>
    </div>
    <div
      v-if="headersReady"
    >
      <div
        class="flex flex-col"
        v-for="(column, index) in columnsChecked"
        :key="'checked' + index"
      >
        <template>
          <div class="flex mb-4">
            <label class="toggle-filter">
              <input
                type="checkbox"
                checked
              >
              <span class="slider" />
              <span
                style="text-transform: uppercase;"
                class="labels slider-text-truncate"
                :data-on="column"
                :data-off="column"
                @click="changeColumnField(column)"
              />
            </label>
          </div>
        </template>
      </div>
      <div
        class="flex flex-col"
        v-for="(column, index) in columnsUnchecked"
        :key="'unchecked' + index"
      >
        <template>
          <div class="flex mb-4">
            <label class="toggle-filter">
              <input
                type="checkbox"
              >
              <span class="slider" />
              <span
                style="text-transform: uppercase;"
                class="labels slider-text-truncate"
                :data-on="column"
                :data-off="column"
                @click="changeColumnField(column)"
              />
            </label>
          </div>
        </template>
      </div>
    </div>
  </Collapsible>
</template>

<script>
import Collapsible from 'vue-collapsible-component';
import { mapState } from 'vuex';
import api from '../api';
import {
  SET_COLUMN_FIELD,
  SET_HEADERS_READY,
  SET_COLUMNS_FROM_DOCUMENT,
  SET_FILTERS_FROM_DOCUMENT,
} from '../store/mutation-types';

export default {
  components: {
    Collapsible,
  },
  props: {
    container: { type: Object, required: true },
    template: { type: Object, default: null },
  },
  data() {
    const initialFieldValues = {};
    this.template.fields.forEach((field) => {
      initialFieldValues[field.name] = this.container ? this.container.app.fieldValues[field.name] : null;
    });

    return {
      fieldValues: initialFieldValues,
      headers: [],
    };
  },
  async mounted() {
    await this.getHeaderFromDataSource(this.selectedDataSource);
    this.updateInitialColumnField(this.columnsUnchecked);
    const columnsFilter = this.headers.map((header, index) => ({ id: index, name: header.toUpperCase() }));
    this.$store.commit(SET_FILTERS_FROM_DOCUMENT, columnsFilter);
  },
  computed: {
    columnsChecked() {
      if (this.selectedDataSource === null) {
        return [];
      }

      return this.columnsToShow;
    },
    columnsUnchecked() {
      const headers = this.headers.map(header => header.toUpperCase());
      if (this.columnsChecked === undefined) {
        return [];
      }
      const checked = this.columnsChecked.map(check => check.toUpperCase());
      if (checked === undefined || checked === 'undefined' || checked.length === 0) {
        return [];
      }
      const unchecked = headers.filter(header => !checked.includes(header));

      return unchecked;
    },
    ...mapState({
      selectedDataSource: state => state.users.selectedDataSource,
      headersReady: state => state.ui.headersReady,
      columnsToShow: state => state.ui.columnsToShow,
      columnField: state => state.ui.columnField,
    }),
  },
  methods: {
    columnsToSave() {
      const keys = Object.keys(this.columnField);
      const columnsToSave = keys.filter(column => this.columnField[column].show) || [];
      if (columnsToSave.length === 0) {
        if ('columns' in this.fieldValues) {
          return this.fieldValues.columns;
        }

        return undefined;
      }

      return columnsToSave;
    },
    updateInitialColumnField(unchecked) {
      const columnField = Object.assign({}, this.columnField);
      unchecked.forEach((columnUnchecked) => {
        columnField[columnUnchecked] = { show: false };
      });
      if (columnField !== this.columnField) {
        this.$store.commit(SET_COLUMN_FIELD, columnField);
        this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, this.columnsToSave());
      }
    },
    changeColumnField(column) {
      const columnField = Object.assign({}, this.columnField);
      columnField[column] = { show: !columnField[column].show };
      this.$store.commit(SET_COLUMN_FIELD, columnField);
    },
    columnsAndFiltersFromPreviouslyUsed(dataSource) {
      const columnsFilter = dataSource.typeColumns.map((column, index) => ({ id: index, name: column.toUpperCase() }));
      const columns = columnsFilter.map(column => column.name);

      return [columns, columnsFilter];
    },
    columnsAndFiltersFromSystemDataSource(dataSource) {
      const columnsFilter = dataSource.customAttrs.map((column, index) =>
        ({ id: index, name: column.name.toUpperCase() }),
      );
      const columns = columnsFilter.map(column => column.name);

      return [columns, columnsFilter];
    },
    async columnsAndFiltersFromGSheetOrExcel(dataSource) {
      const response = await api.getDataSourceValues(dataSource.id);
      const columns = response.values[0];
      const columnsFilter = columns.map((header, index) => ({ id: index, name: header.toUpperCase() }));

      return [columns, columnsFilter];
    },
    async getColumnsFromDataSource(dataSource) {
      let result;
      if (dataSource.service === 'custom') { // this is when is a previously used DataSource
        result = this.columnsAndFiltersFromPreviouslyUsed(dataSource);
      } else if ('customAttrs' in dataSource) { // this is when is a system DataSource
        result = this.columnsAndFiltersFromSystemDataSource(dataSource);
      } else { // this is when is a GSheet or excel DataSource
        result = this.columnsAndFiltersFromGSheetOrExcel(dataSource);
      }

      return result;
    },
    async getHeaderFromDataSource() {
      const dataSource = this.selectedDataSource;
      if (dataSource === null || !('id' in dataSource) || dataSource.id === null) {
        return;
      }
      this.$store.commit(SET_HEADERS_READY, false);
      const result = await this.getColumnsFromDataSource(dataSource);
      this.headers = result[0];
      this.$store.commit(SET_HEADERS_READY, true);
    },
  },
};
</script>

<style scoped>
.toggle {
  --width: 170px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 5px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 115px;
}

.toggle .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 5px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 115px;
}

.toggle input:checked~.labels::after {
  opacity: 0;
}

.toggle input:checked~.labels::before {
  opacity: 1;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

</style>
