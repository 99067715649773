<template>
  <div class="grid-index shadow-md">
    <div
      class="flex flex-row mt-20 p-8 bg-white h-20 items-center justify-left
                border-b border-gray-400 rounded-t-lg text-size-18"
    >
      <h4 class="w-6/12">
        Database : {{ this.userDefinedTypeProp.name }}
      </h4>

      <div class="w-3/12 flex justify-center items-center">
        <button
          @click="openNewColumnModal"
          class="border border-gray-400 rounded-md px-4 py-2 cursor-pointer "
        >
          <i class="material-icons-outlined material-icons-inline material-icons-active">add</i>
          <span class="text-icon-secondary pl-2">Edit Columns</span>
        </button>
      </div>
      <div class="w-3/12 flex justify-center items-center">
        <button
          @click="openNewEntryModal"
          class="border border-gray-400 rounded-md px-4 py-2 cursor-pointer "
        >
          <i class="material-icons-outlined material-icons-inline material-icons-active">add</i>
          <span class="text-icon-secondary pl-2">new entry</span>
        </button>
      </div>
    </div>
    <!-- Tags Tab -->
    <div
      class="bg-white min-h-full w-full"
    >
      <div class="flex">
        <div class="w-full pl-8">
          <div class="flex">
            <vuetable
              ref="vuetablelatest"
              :css="{
                tableWrapper: 'table-auto w-full',
                tableClass: 'text-table text-sm font-normal',
                tableHeaderClass: '',
                tableBodyClass:`w-full grid-index__grid-table bg-white
                                p-2 text-gray-600 text-sm font-normal rounded-lg`,
              }"
              :api-mode="false"
              :fields="headerTable"
              :data="displayableCustomData"
            >
              <div
                slot-scope="props"
                :slot="blobCol.name"
                :key="index"
                v-for="(blobCol, index) in blobColumns"
              >
                <a
                  v-if="props.rowData[blobCol.name]"
                  target="_blank"
                  :href="props.rowData[blobCol.name]"
                >
                  Click to download the image or file
                </a>
                <p v-else>
                  No attached file found
                </p>
              </div>
              <div
                slot="actions"
                slot-scope="props"
                class="flex"
              >
                <button
                  @click="openEditUserDefinedDatum(props.rowData)"
                  class="btn btn--inverted-colors-no-border"
                  title="Edit Entry"
                >
                  <i class="material-icons-outlined material-icons--primary">edit</i>
                </button>

                <button
                  @click="destroyUserDefinedDatum(props.rowData)"
                  class="btn btn--inverted-colors-no-border"
                  title="Remove Entry"
                >
                  <i class="material-icons-outlined material-icons--primary">delete_forever</i>
                </button>
              </div>
            </vuetable>
          </div>
        </div>
      </div>
      <user-defined-type-modal
        v-if="userDefinedTypeModalOpened"
      />
      <user-defined-type-columns-modal
        v-if="userDefinedTypeColumnsModalOpened"
      />
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2';
import humps from 'humps';
import { mapState } from 'vuex';
import api from '../api';

import UserDefinedTypeModal from './user-defined-type-modal';
import UserDefinedTypeColumnsModal from './user-defined-type-columns-modal';
import {
  OPEN_USER_DEFINED_TYPE_MODAL,
  SET_SELECTED_CUSTOM_COLUMNS,
  SET_SELECTED_CUSTOM_TYPE,
  SET_SELECTED_CUSTOM_DATUM,
  SET_SELECTED_DATA,
  REMOVE_SELECTED_DATA,
  OPEN_USER_DEFINED_TYPE_COLUMN_MODAL,
} from '../store/mutation-types';

export default {
  components: {
    Vuetable,
    UserDefinedTypeModal,
    UserDefinedTypeColumnsModal,
  },
  props: {
    userDefinedTypeProp: { type: Object, required: true },
    userDataProp: { type: Array, required: true },

  },
  data() {
    return {
      selectedTab: 'usertypes',
      openDropdown: false,
      fileSignedId: null,
      userTypeName: null,
      selectedType: this.userDefinedTypeProp,
      loadingImage: false,
      newColumnName: null,
      newColumnType: null,
      customTypeData: [],

    };
  },
  mounted() {
    const userData = humps.camelizeKeys(this.userDataProp);
    this.customTypeData = userData.map(ut => {
      ut.dataRow.createdAt = ut.createdAt;
      ut.dataRow.updatedAt = ut.updatedAt;
      ut.dataRow.id = ut.id;

      return ut.dataRow;
    });
    this.$store.commit(SET_SELECTED_DATA, this.customTypeData);
    this.$store.commit(SET_SELECTED_CUSTOM_COLUMNS, this.userDefinedTypeProp.customAttrs || []);
    this.$store.commit(SET_SELECTED_CUSTOM_TYPE, this.userDefinedTypeProp);
  },
  computed: {
    ...mapState({
      userDefinedTypeModalOpened: state => state.ui.userDefinedTypeModalOpened,
      userDefinedTypeColumnsModalOpened: state => state.ui.userDefinedTypeColumnsModalOpened,
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    displayableSelectedDatabaseOwner() {
      return `${this.selectedType.user.first_name} ${this.selectedType.user.last_name}`;
    },
    displayableCustomData() {
      return Object.keys(this.selectedCustomData).map(index => this.selectedCustomData[index]);
    },
    headerTable() {
      const header = this.selectedType.customAttrs
        // .filter(cc => (cc.type !== 'file' && cc.type !== 'image'))
        .map(cc => ({
          name: humps.camelize(cc.name),
          sortField: humps.camelize(cc.name),
        }));
      header.push({ name: 'updatedAt', title: 'Updated' });
      header.push({ name: 'actions', title: '' });

      return header;
    },
    blobColumns() {
      const blobs = this.selectedType.customAttrs
        .map(cc => ({ name: humps.camelize(cc.name), type: cc.type }))
        .filter(cc => (cc.type === 'file' || cc.type === 'image'));

      return blobs;
    },
    userTypesActive() {
      return this.selectedTab === 'usertypes';
    },
    typesDataActive() {
      return this.selectedTab === 'typesDataActive';
    },
    customTypeParams() {
      const params = {};
      params.name = this.userTypeName;
      if (this.customColumns) {
        params.customAttrs = this.customColumns;
      }

      return params;
    },
    newInputFilled() {
      return this.newColumnName && this.newColumnType;
    },
    canSave() {
      return this.userTypeName !== null;
    },
  },

  methods: {
    editDatabase(dataBaseId) {
      window.location = `/user_types/${dataBaseId}}/edit`;
    },
    destroyUserDefinedDatum(row) {
      api.removeUserDefinedTypeData(this.selectedType.id, row.id)
        .then(() => {
          this.$store.commit(REMOVE_SELECTED_DATA, row.id);
        });
    },
    openEditUserDefinedDatum(row) {
      console.log('row is', row);
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, row);
      this.$store.commit(OPEN_USER_DEFINED_TYPE_MODAL);
    },
    selectType(selectedType) {
      this.selectedType = {
        id: selectedType.id,
        name: selectedType.name,
        numberOfEntries: selectedType.numberOfEntries,
        createdAt: selectedType.createdAt,
        user: selectedType.user,
        customAttrs: selectedType.customAttrs || [],

      };
    },
    selectTypeForData(selectedType) {
      this.selectedType = {
        id: selectedType.id,
        name: selectedType.name,
        customAttrs: selectedType.customAttrs || [],
      };
      this.$store.commit(SET_SELECTED_CUSTOM_COLUMNS, this.selectedType.customAttrs);
      this.$store.commit(SET_SELECTED_CUSTOM_TYPE, this.selectedType);

      api.getUserDefinedTypeData(selectedType.id)
        .then((data) => {
          this.customTypeData = data.userTypes.map(ut => {
            ut.dataRow.createdAt = ut.createdAt;
            ut.dataRow.updatedAt = ut.updatedAt;
            ut.dataRow.id = ut.id;

            return ut.dataRow;
          });
          this.$store.commit(SET_SELECTED_DATA, this.customTypeData);
        });
    },
    selectTab(selectedTab) {
      this.selectedTab = selectedTab;
    },
    createNewType() {
      if (!this.canSave) return;
      api.createUserDefinedType(this.customTypeParams)
        .then(response => {
          this.updateUserTypes(response);
          this.userTypeName = null;
        });
    },
    removeUserTypes(id) {
      api.removeUserDefinedType(id)
        .then(() => {
          this.removeSelectedType(id);
          this.selectedType = { name: null, customAttrs: [] };
        });
    },
    removeSelectedType(id) {
      const userTypesCopy = { ...this.userDefinedTypes };
      delete userTypesCopy[id];
      this.userDefinedTypes = { ...userTypesCopy };
    },
    addColumnToType() {
      if (!this.newInputFilled) return;
      const copyCustomAttrs = [...this.selectedType.customAttrs];
      copyCustomAttrs.push({ name: this.newColumnName.toLowerCase(), type: this.newColumnType });
      api.updateUserDefinedType(this.selectedType.id, {
        userType: { name: this.selectedType.name, customAttrs: copyCustomAttrs },
      })
        .then(() => {
          this.selectedType.customAttrs.push({ name: this.newColumnName.toLowerCase(), type: this.newColumnType });
          this.newColumnName = null;
          this.newColumnType = null;
        });
    },
    removeColumnToType(removeColumnName) {
      const copyCustomAttrs = [...this.selectedType.customAttrs];
      const indexFor = this.selectedType.customAttrs.map(cols => cols.name)
        .indexOf(removeColumnName);
      if (indexFor > -1) {
        copyCustomAttrs.splice(indexFor, 1);
        api.updateUserDefinedType(this.selectedType.id, {
          userType: { name: this.selectedType.name, customAttrs: copyCustomAttrs },
        })
          .then(() => {
            this.selectedType.customAttrs.splice(indexFor, 1);
          });
      }
    },
    updateUserTypes(userType) {
      this.userDefinedTypes = { ...this.userDefinedTypes, [userType.id]: userType };
    },
    openNewEntryModal() {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, {});
      this.$store.commit(OPEN_USER_DEFINED_TYPE_MODAL);
    },
    openNewColumnModal() {
      this.$store.commit(OPEN_USER_DEFINED_TYPE_COLUMN_MODAL);
    },
  },
};
</script>
<style scoped>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

.text-size-18 {
  font-size: 18px;
}

.text-size-12 {
  font-size: 12px;
}

.text-size-13 {
  font-size: 13px;
}

.input-name {
  height: 48px;
  width: 250px;
}

.logo {
  height: 96px;
  width: 96px;
}

.padding-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}

table thead tr th {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a5464;
}

table tr td {
  padding-left: 15px;
}

table tbody tr {
  height: 54px;
  text-align: left;
}

thead {
  border-bottom: 1px solid #edebf5;
}

table tbody tr:not(:last-child) {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:first-child {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:last-child {
  /* border-bottom: 1px solid #edebf5; */
  border-top: 1px solid #edebf5;
}

.vuetable-slot {
  text-align: center;
}

.vuetable-slot i {
  font-size: 1.2rem;
}

.w-148 {
  width: 148px;
}

</style>
