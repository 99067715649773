<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">photo</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Imagen
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CARGA DE IMAGEN
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CARGA DE IMAGEN
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col mb-4"
        >
          <div class="flex flex-row mt-4">
            <div
              class="flex flex-row"
            >
              <image-upload
                class="w-full text-left text-sm font-inter-type-1 cells1"
                style="padding-top: 7px; padding-left: 12px;"
                @loading-start="loadingFile = true"
                @loading-end="loadingFile = false"
                @uploaded="handleImageUploaded"
                from="image-app-modal"
              >
                <div class="text-left text-sm font-inter-type-1">
                  {{ imageName || 'Adjuntar imagen...' }}
                </div>
              </image-upload>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Redirección
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasRedirectLink"
              :sync="true"
              @input="hasRedirectLink = !hasRedirectLink; updateAppAttributes();"
            />
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
      v-show="hasRedirectLink"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              DESTINO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              DESTINO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col mb-16"
        >
          <div class="flex flex-row">
            <div class="flex flex-col pr-6">
              <div class="flex flex-row">
                <input
                  class="w-1/6 radio-btn-size mt-2"
                  type="radio"
                  id="view-redirect"
                  value="view"
                  v-model="redirectType"
                >
                <VueSelectStandard
                  class="w-5/6 ml-2 text-center text-sm vue-select-container"
                  style="width: 170px !important;"
                  :data="resourcePathNames"
                  v-model="viewObject"
                  @documentListener="selectViewRadioButton"
                  :placeholder="'Vista existente...'"
                  :listener="'documentListener'"
                />
              </div>
              <div class="flex flex-row mt-2">
                <input
                  class="w-1/6 radio-btn-size mt-2"
                  type="radio"
                  id="url"
                  value="url"
                  v-model="redirectType"
                >
                <input
                  v-model="urlLink"
                  class="ml-2 w-5/6 text-left text-sm font-inter-type-1 cells"
                  style="padding-left: 12px;"
                  placeholder="URL..."
                >
              </div>
              <div
                class="flex flex-row mt-4"
              >
                <div>
                  <input
                    class="w-1/6 radio-btn-size mt-2"
                    type="radio"
                    id="imageFile"
                    value="file"
                    v-model="redirectType"
                  >
                </div>
                <div>
                  <file-upload
                    class="ml-2 w-5/6 text-left text-sm font-inter-type-1 cells"
                    style="padding-top: 7px; padding-left: 12px;"
                    @loading-start="loadingFile = true"
                    @loading-end="loadingFile = false"
                    @uploaded="handleFileUploaded"
                    from="button-app-modal"
                  >
                    <div class="text-left text-sm font-inter-type-1">
                      {{ redirectName || 'Carga documento...' }}
                    </div>
                  </file-upload>
                </div>
              </div>
              <div
                v-show="redirectType === 'view'"
                class="flex flex-eor mt-4 mb-32"
              >
                <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                  Abrir nueva pestaña
                </p>
                <toggle-button
                  color="#2c6eeb"
                  class="w-2/6"
                  :value="openNewTab"
                  :sync="true"
                  @input="openNewTab = !openNewTab"
                />
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Título
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasDescriptiveText"
              :sync="true"
              @input="hasDescriptiveText = !hasDescriptiveText; updateAppAttributes();"
            />
          </div>
          <div class="flex mt-4 pl-3">
            <p
              class="text-left text-menu-label w-3/6 text-sm font-inter-type-1"
              style="margin-top: 5px;"
            >
              Superponer
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-3/6"
              style="padding-left: 31px;"
              :value="descriptiveTextOverlap"
              :sync="true"
              @input="descriptiveTextOverlap = !descriptiveTextOverlap; updateAppAttributes();"
            />
          </div>
          <div
            class="border-t mt-4"
            style="margin-right: 14.14px"
          />
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Imagen
            </p>
          </div>
          <div class="pl-3 flex flex-col mt-5 mb-4">
            <div>
              <div class="flex flex-row">
                <p class="text-left text-menu-label text-sm font-inter-type-1 mt-1">
                  Ajustar
                </p>
                <div
                  class="flex flex-row mt-1"
                  style="margin-left: 66px;"
                >
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAdjustment == 'center' }"
                    @click="changeContentAdjustment('center'); updateAppAttributes();"
                  >close_fullscreen_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAdjustment == 'expanded' }"
                    @click="changeContentAdjustment('expanded'); updateAppAttributes();"
                  >open_in_full_icon</i>
                  <!-- <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAdjustment == 'vertical' }"
                    @click="changeContentAdjustment('vertical')"
                  >swap_vert_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAdjustment == 'horizontal' }"
                    @click="changeContentAdjustment('horizontal')"
                  >swap_horiz_icon</i> -->
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAdjustment == 'none' }"
                    @click="changeContentAdjustment('none'); updateAppAttributes();"
                  >do_not_disturb_alt_icon</i>
                </div>
              </div>
            </div>
            <div>
              <div class="flex flex-row mt-4">
                <p class="text-left text-menu-label text-sm font-inter-type-1 mt-1">
                  Alinear
                </p>
                <div
                  class="flex flex-row mt-1"
                  style="margin-left: 9px;"
                >
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAlignment === 'right' }"
                    @click="changeContentAlignment('right'); updateAppAttributes();"
                  >align_horizontal_right_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAlignment === 'left' }"
                    @click="changeContentAlignment('left'); updateAppAttributes();"
                  >align_horizontal_left_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAlignment === 'center' }"
                    @click="changeContentAlignment('center'); updateAppAttributes();"
                  >filter_center_focus_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAlignment === 'bottom' }"
                    @click="changeContentAlignment('bottom'); updateAppAttributes();"
                  >align_vertical_bottom_icon</i>
                  <i
                    class="material-icons format-icon cursor-pointer icon-limits"
                    :class="{ 'selected-alignment': contentAlignment === 'top' }"
                    @click="changeContentAlignment('top'); updateAppAttributes();"
                  >align_vertical_top_icon</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import _ from 'lodash';
import Collapsible from 'vue-collapsible-component';
import VueSelectStandard from './vue-select-standard';
import {
  IMAGE_UPLOADED,
  SAVE_ALERT,
} from '../store/mutation-types';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import showMessageMixin from '../mixins/showMessageMixin';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';
import FileUpload from '../components/utils/file-upload.vue';

export default {
  mixins: [showMessageMixin],
  components: {
    Collapsible,
    ContainerSection,
    AppTitleInOptions,
    VueSelectStandard,
    FileUpload,
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    let imageName = _.get(container, 'app.imageName', null);
    if (imageName === 'default_placeholder.png') {
      imageName = null;
    }

    const initialRedirection = this.identifyInitialRedirection(container);

    return {
      container,
      borderColor: _.get(container, 'borderColor', ''),
      showShading: _.get(container, 'showShading', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      showTransparency: _.get(container, 'showTransparency', false),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      contentAlignment: _.get(container, 'app.contentAlignment', 'center'),
      contentAdjustment: _.get(container, 'app.contentAdjustment', 'none'),
      hasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false),
      descriptiveText: _.get(container, 'app.descriptiveText', 'Título...'),
      descriptiveTextOverlap: _.get(container, 'app.descriptiveTextOverlap', false),
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      imageUrl: _.get(container, 'app.imageUrl', null),
      redirectType: _.get(container, 'app.redirectType', 'view'),
      redirectLink: _.get(container, 'app.redirectLink', 'https://app.bigpictures.co/'),
      hasRedirectLink: _.get(container, 'app.hasRedirectLink', false),
      redirectUrl: _.get(container, 'app.redirectUrl', null),
      openNewTab: _.get(container, 'app.openNewTab', false),
      urlLink: null,
      viewLinkID: null,
      redirectSignedId: null,
      fileSignedId: null,
      viewLink: null,
      containerID: _.get(container, 'id', -1),
      loadingImage: false,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      imageName,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTransparency: _.get(container, 'showTransparency', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialImageUrl: _.get(container, 'app.imageUrl', null),
      initialHasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false),
      initialDescriptiveTextOverlap: _.get(container, 'app.descriptiveTextOverlap', false),
      initialContentAlignment: _.get(container, 'app.contentAlignment', 'center'),
      initialContentAdjustment: _.get(container, 'app.contentAdjustment', 'none'),
      initialRedirectType: _.get(container, 'app.redirectType', 'view'),
      initialRedirectLink: _.get(container, 'app.redirectLink', 'https://app.bigpictures.co/'),
      initialHasRedirectLink: _.get(container, 'app.hasRedirectLink', false),
      initialOpenNewTab: _.get(container, 'app.openNewTab', false),
      fileName: this.initialFileName(container),
      redirectName: this.initialRedirectName(container),
      initialRedirection,
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  mounted() {
    this.redirectLinkHandle();
  },
  computed: {
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      saveAlert: state => state.ui.saveAlert,
      appAttrs: state => state.views.appsAttrs,
      shouldSaves: state => state.views.shouldSaves,
      personalFolders: state => state.views.personalFolders,
    }),
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    imageID() {
      return `#image${this.containerID}`;
    },
    appInputsReady() {
      return !!(this.imageUrl && !this.loadingImage);
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        borderColor: this.borderColor,
        isPrivate: this.isPrivate,
        showTitle: this.showTitle,
        showTransparency: this.showTransparency,
        showShading: this.showShading,
        backgroundColor: this.containerBackgroundColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      const appAndType = {
        app: {
          imageUrl: this.imageUrl,
          hasDescriptiveText: this.hasDescriptiveText,
          descriptiveTextOverlap: this.descriptiveTextOverlap,
          contentAlignment: this.contentAlignment,
          contentAdjustment: this.contentAdjustment,
          redirectType: this.redirectType,
          redirectLink: this.redirectLink,
          hasRedirectLink: this.hasRedirectLink,
          openNewTab: this.openNewTab,
        },
        appType: 'ImageApp',
      };
      if (this.fileSignedId) appAndType.app.image = this.fileSignedId;
      if (this.redirectType === 'file') {
        appAndType.app.redirectUrl = this.redirectUrl;
        if (this.redirectSignedId) appAndType.app.redirect = this.redirectSignedId;
      } else if (this.redirectType === 'url') {
        appAndType.app.redirectLink = this.urlLink;
      } else if (this.redirectType === 'view') {
        appAndType.app.redirectLink = this.viewLinkID;
      }

      return appAndType;
    },
    changesToSend() {
      let redirection = null;
      if (this.redirectType === 'file') {
        redirection = this.redirectUrl;
      } else if (this.redirectType === 'url') {
        redirection = this.urlLink;
      } else if (this.redirectType === 'view') {
        redirection = this.viewLinkID;
      }
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialImageUrl !== this.imageUrl ||
        this.initialHasDescriptiveText !== this.hasDescriptiveText ||
        this.initialDescriptiveTextOverlap !== this.descriptiveTextOverlap ||
        this.initialContentAlignment !== this.contentAlignment ||
        this.initialContentAdjustment !== this.contentAdjustment ||
        this.initialRedirectType !== this.redirectType ||
        this.initialRedirection !== redirection ||
        this.initialHasRedirectLink !== this.hasRedirectLink ||
        this.initialOpenNewTab !== this.openNewTab
      ) {
        return true;
      }

      return false;
    },
    canSave() {
      const canSave = this.appInputsReady && this.changesToSend;
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    viewObject() {
      return { name: this.viewLink || 'Vista existente...', id: 0 };
    },
    resourcePathNames() {
      let personalViews = this.personalFolders.filter(resource => resource.resourceType === 'Views')[0].pathList;
      const views = personalViews.map(
        (view) => ({ id: view.uuid, name: view.name }));
      return views;
    },
    viewNamesByIdHash() {
      let personalViews = this.personalFolders.filter(resource => resource.resourceType === 'Views')[0].pathList;
      const hash = {};
      for (const view of personalViews) {
        hash[view.id] = view.name;
      }

      return hash;
    },
  },
  methods: {
    redirectLinkHandle() {
      if (this.redirectType === 'url') {
        this.urlLink = this.redirectLink;
      } else if (this.redirectType === 'view') {
        this.viewLink = this.viewNamesByViewID(this.redirectLink);
        this.viewLinkID = this.redirectLink;
      }
    },
    viewNamesByViewID(viewID) {
      const hash = this.viewNamesByIdHash;

      return hash[viewID];
    },
    identifyInitialRedirection(container) {
      const redirectType = _.get(container, 'app.redirectType', 'view');
      let redirection = null;
      if (redirectType === 'file') {
        redirection = _.get(container, 'app.redirectUrl', null);
      } else {
        redirection = _.get(container, 'app.redirectLink', 'https://app.bigpictures.co/');
      }

      return redirection;
    },
    initialFileName(container) {
      const fileName = _.get(container, 'app.fileName', null);
      if (fileName === 'default_placeholder.png') {
        return null;
      }

      return fileName;
    },
    initialRedirectName(container) {
      const redirectName = _.get(container, 'app.redirectName', null);
      if (redirectName === 'default_placeholder.png') {
        return null;
      }

      return redirectName;
    },
    selectViewRadioButton(redirectLink) {
      $('#view-redirect').trigger('click');
      this.viewLink = redirectLink.name;
      this.viewLinkID = redirectLink.id;
    },
    handleFileUploaded({ url, fileSignedId, name }) {
      this.redirectUrl = url;
      this.redirectSignedId = fileSignedId;
      this.redirectName = name;
      $('#imageFile').trigger('click');
    },
    handleImageUploaded({ url, fileSignedId, name }) {
      this.imageUrl = url;
      this.fileSignedId = fileSignedId;
      this.imageName = name;
      this.$store.commit(IMAGE_UPLOADED, true);
      this.updateAppAttributes();
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
      this.resetInitialValues();
    },
    resetInitialValues2() {
      this.initialShowShading = this.showShading;
      this.initialContainerBackgroundColor = this.containerBackgroundColor.toLowerCase();
      this.initialImageUrl = this.imageUrl;
      this.initialHasDescriptiveText = this.hasDescriptiveText;
      this.initialDescriptiveTextOverlap = this.descriptiveTextOverlap;
      this.initialContentAlignment = this.contentAlignment;
      this.initialContentAdjustment = this.contentAdjustment;
      this.initialOpenNewTab = this.openNewTab;
      this.resetInitialRedirect();
    },
    setInitialRedirection() {
      let redirection = null;
      if (this.redirectType === 'file') {
        redirection = this.redirectUrl;
      } else if (this.redirectType === 'url') {
        redirection = this.urlLink;
      } else if (this.redirectType === 'view') {
        redirection = this.viewLinkID;
      }

      return redirection;
    },
    resetInitialRedirect() {
      this.initialRedirectType = this.redirectType;
      this.initialRedirectLink = this.redirectLink;
      this.initialHasRedirectLink = this.hasRedirectLink;
      this.initialRedirection = this.setInitialRedirection();
    },
    resetInitialValues() {
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialBorderColor = this.borderColor.toLowerCase();
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTransparency = this.showTransparency;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    applyRightAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '15px');
    },
    applyLeftAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '15px');
    },
    applyCenterAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content:', 'center');
      $(this.imageID).addClass('self-center center-elements');
    },
    applyBottomAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-bottom', '15px');
    },
    applyTopAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-top', '15px');
    },
    changeContentAlignment(alignment) {
      if (alignment === 'right') {
        this.applyRightAlignmentStyle();
      } else if (alignment === 'left') {
        this.applyLeftAlignmentStyle();
      } else if (alignment === 'center') {
        this.applyCenterAlignmentStyle();
      } else if (alignment === 'bottom') {
        this.applyBottomAlignmentStyle();
      } else if (alignment === 'top') {
        this.applyTopAlignmentStyle();
      }
      this.contentAlignment = alignment;
    },
    changeContentAdjustment(adjustment) {
      this.contentAdjustment = adjustment;
    },
    updateAppAttributes() {
      const newImageUpdater = this.appAttrs[this.container.id].imageUpdater + 1;
      const params = {
        hasDescriptiveText: this.hasDescriptiveText,
        descriptiveTextOverlap: this.descriptiveTextOverlap,
        contentAlignment: this.contentAlignment,
        contentAdjustment: this.contentAdjustment,
        imageUrl: this.imageUrl,
        imageUpdater: newImageUpdater,
        redirectType: this.redirectType,
        redirectLink: this.redirectLink,
        hasRedirectLink: this.hasRedirectLink,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>

<style scoped>

.app {
  margin-top: 12px;
}

.icon {
  color: #28C6DA;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.radio-btn-size {
  height: 16px;
  width: 16px;
}

.cells1 {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 195px;
  height: 32px;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.width-icon {
  transform: rotate(90deg);
}

.scrollable-toolbar-item-option {
  padding-bottom: 45px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #28C6DA;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.arrows-padding {
  padding-top: 26.88px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.shape-box {
  background: #2C6EEB 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  font: normal normal bold 10px/24px Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  height: 23px;
  width: 98.2px;
}

.straight-shape-box {
  border-radius: 3px;
}

.round-shape-box {
  border-radius: 12px;
}

.radio-btn-size {
  height: 16px;
  width: 16px;
}

.transparent-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 170px;
  height: 32px;
}

.vue-select-container {
  height: 42px;
}

.scrollable-toolbar-item-option {
  padding-bottom: 90px;
}

</style>
