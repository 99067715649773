<template>
  <div class="feedback">
    <i
      class="material-icons feedback__icon"
      @click="openSite"
    >
      rate_review
    </i>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import queryHelpers from '../helpers/query-helpers';

export default {
  name: 'Feedback',
  data() {
    return {
      baseLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfOK59OEBd3RAtuR7M60wOxEg55H2NgMSVK_NSUUXGHHh9GNA/viewform',
      companyNameInputKey: 'entry.1518615212',
      userEmailInputKey: 'entry.368537382',
      actualAddressInputKey: 'entry.1315350942',
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapState({
      currentCompanyName: state => state.users.currentCompanyName,
    }),
    completeLink() {
      const params = {
        [this.companyNameInputKey]: this.currentCompanyName,
        [this.userEmailInputKey]: this.currentUser.email,
        [this.actualAddressInputKey]: window.location.href,
      };

      return queryHelpers.addQueryParams(this.baseLink, params);
    },
  },
  methods: {
    openSite() {
      window.open(this.completeLink);
    },
  },
};
</script>
