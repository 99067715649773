<template>
  <div
    ref="editor"
    class="fixed top-with-bar bottom-0 right-0 h-screen flex flex-col
           text-center items-center
           mx-auto
           rounded-lg shadow-md bg-gray-apps"
    :class="isClosed()"
    style="z-index: 100;"
  >
    <div
      class="flex flex-row bg-menu-apps box-shadow-menu  w-full items-center h-38px"
    >
      <button
        :class="{
          'hide':!collapsibleIsOpen,
          'app-border--yellow-sm': appsActive && !appInstanceSelected()
        }"
        @click="selectTab('apps')"
        class="w-1/2 uppercase text-white text-center p-11px cursor-pointer "
      >
        apps
      </button>
      <button
        :class="{
          'hide':!collapsibleIsOpen,
          'app-border--yellow-sm': itemActive || appInstanceSelected()
        }"
        @click="selectTab('item')"
        class="w-1/2 h-38px uppercase text-white text-center p-11px cursor-pointer"
      >
        item
      </button>
    </div>
    <div
      :class="{
        'hide':!collapsibleIsOpen,
        hidden:!appsActive || appInstanceSelected(),
      }"
      class="relative flex flex-col p-5px w-full"
    >
      <input
        class=" w-full search-input outline-none"
        placeholder="Buscar"
      >
      <i class="absolute material-icons material-icons-rounded search-icon-position">search</i>

      <p class="margin-top-40px text-left color-gray-title text-category">
        MÁS USADOS
      </p>
      <div class="flex flex-row">
        <!-- {{ mostUsed }} -->
      </div>
      <!-- <section class="flex flex-row">
      </section> -->
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex margin-top-40px items-center">
            <p class="w-5/6  text-left color-gray-title border-t border-gray-400 pt-4 text-category ">
              BÁSICOS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex margin-top-40px items-center">
            <p class="w-5/6  text-left color-gray-title border-t border-gray-400 pt-4 text-category ">
              BÁSICOS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">chevron_right</i>
          </div>
        </div>
        <div
          ref="basic"
          class="flex flex-row flex-wrap mb-4"
        >
          <!-- {{ basicApps }} -->
        </div>
      </Collapsible>
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div slot="trigger">
          <div class="flex items-center ">
            <p class="w-5/6 text-category text-left color-gray-title border-t border-gray-400 pt-4 ">
              DINÁMICOS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex  items-center ">
            <p class="w-5/6 text-category  text-left color-gray-title border-t border-gray-400 pt-4">
              DINÁMICOS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">chevron_right</i>
          </div>
        </div>
        <div
          ref="dataDisplays"
          class="flex flex-row flex-wrap mb-4"
        >
          <!-- {{ dataDisplayApps }} -->
        </div>
      </Collapsible>

      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div slot="trigger">
          <div class="flex items-center ">
            <p class="w-5/6 text-category text-left color-gray-title border-t border-gray-400 pt-4 ">
              OTROS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex  items-center ">
            <p class="w-5/6 text-category  text-left color-gray-title border-t border-gray-400 pt-4">
              OTROS
            </p>
            <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">chevron_right</i>
          </div>
        </div>
        <div
          ref="others"
          class="flex flex-row flex-wrap"
        >
          <!-- {{ dataDisplayApps }} -->
        </div>
      </Collapsible>
    </div>
    <div
      :class="{
        'hide':!collapsibleIsOpen,
        hidden:!itemActive && !appModalType,
      }"
      class="relative flex flex-col h-full w-full  border-l-8 border-r-8 border-b-8 border-gray-300 overflow-y-scroll"
    >
      <div class="bg-white h-full margin-top: 14px;">
        <div
          v-if="!appModalType"
          class="flex flex-col mt-12"
        >
          <i class="material-icons material-icons-apps">
            block
          </i>
          <p class="mt-2 text-gray-700">
            No hay ningún ítem seleccionado
          </p>
        </div>

        <text-app-options v-if="selectedModalOfType(appTypes.TEXT)" />
        <source-app-options v-if="selectedModalOfType(appTypes.SOURCE)" />
        <image-app-options v-if="selectedModalOfType(appTypes.IMAGE)" />
        <file-app-options v-if="selectedModalOfType(appTypes.FILE)" />
        <tab-app-options v-if="selectedModalOfType(appTypes.TABS)" />
        <feed-app-options v-if="selectedModalOfType(appTypes.FEED)" />
        <wall-app-options v-if="selectedModalOfType(appTypes.WALL)" />
        <space-app-modal v-if="selectedModalOfType(appTypes.SPACE)" />
        <menu-app-modal v-if="selectedModalOfType(appTypes.MENU)" />
        <article-app-options v-if="selectedModalOfType(appTypes.ARTICLE)" />
        <table-app-options v-else-if="selectedModalOfType(appTypes.CUSTOM) && selectedModalOfAppName('Table')" />
        <deck-app-options v-else-if="selectedModalOfType(appTypes.CUSTOM) && selectedModalOfAppName('Deck')" />
        <gantt-app-options v-else-if="selectedModalOfType(appTypes.CUSTOM) && selectedModalOfAppName('Gantt')" />
        <single-value-app-options v-else-if="selectedModalOfType(appTypes.SINGLE_VALUE)" />
        <button-app-options v-else-if="selectedModalOfType(appTypes.BUTTON)" />
        <custom-app-options
          :key="customOptionKey"
          v-if="
            selectedModalOfType(appTypes.CUSTOM) &&
              !selectedModalOfAppName('Table') &&
              !selectedModalOfAppName('Deck') &&
              !selectedModalOfAppName('Gantt')"
        />
        <profile-app-options v-else-if="selectedModalOfType(appTypes.PROFILE)" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import 'vue-collapsible-component/lib/vue-collapsible.css';
import Collapsible from 'vue-collapsible-component';
import EditorFloatingAppButton from './editor-floating-app-button';
import NewAppButton from './new-app-button';
import AppContainerFloating from './app-container-floating';
import TextAppOptions from './text-app-options';
import SourceAppOptions from './source-app-options';
import ImageAppOptions from './image-app-options';
import FileAppOptions from './file-app-options';
import FeedAppOptions from './feed-app-options';
import TabAppOptions from './tab-app-options';
import TableAppOptions from './table-app-options';
import DeckAppOptions from './deck-app-options';
import GanttAppOptions from './gantt-app-options';
import WallAppOptions from './wall-app-options';
import ProfileAppOptions from './profile-app-options';
import SingleValueAppOptions from './single-value-app-options';
import ArticleAppOptions from './article-app-options';
import ButtonAppOptions from './button-app-options';
import SpaceAppModal from './space-app-modal';
import MenuAppModal from './menu-app-modal';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';
import { APP_TYPES, APP_CATEGORIES } from '../constants';
import api from '../api';
import {
  SET_APP_MODAL_TYPE,
  SET_SELECTED_CUSTOM_APP_TEMPLATE,
} from '../store/mutation-types';

import {
  TOOGLE_TOOLBAR_COLLAPSIBLE,
  UNSELECT_CONTAINER_FOR_EDITION,
  OPEN_SAVE_ALERT,
} from '../store/action-types';
import CustomAppOptions from './custom-app-options.vue';

const SIDEBAR_MINUS_HALF_EDITOR_BAR_HORIZONTALLY = 300;
const HEADER_MINUS_HALF_EDITOR_VERTICALLY = 120;

export default {
  mixins: [dragAndResizeMixin],
  components: {
    Collapsible,
    TextAppOptions,
    SourceAppOptions,
    ImageAppOptions,
    FeedAppOptions,
    SpaceAppModal,
    FileAppOptions,
    MenuAppModal,
    CustomAppOptions,
    TabAppOptions,
    TableAppOptions,
    SingleValueAppOptions,
    ButtonAppOptions,
    DeckAppOptions,
    GanttAppOptions,
    ArticleAppOptions,
    WallAppOptions,
    NewAppButton,
    ProfileAppOptions,
  },
  props: {
    viewProp: { type: Object, required: true },
  },
  data() {
    return {
      appTypes: APP_TYPES,
      appCategories: APP_CATEGORIES,
      appMenuOptions: [],
      appProps: [],
      customAppTemplates: [],
      selectedTab: 'apps',
    };
  },
  mounted() {
    this.appMenuOptions = [
      {
        appType: this.appTypes.TEXT,
        icon: 'text_fields',
        category: this.appCategories.BASIC,
      },
      {
        appType: this.appTypes.IMAGE,
        icon: 'photo',
        category: this.appCategories.BASIC,
      },
      {
        appType: this.appTypes.SINGLE_VALUE,
        icon: 'call_to_action_icon',
        category: this.appCategories.DATA_DISPLAYS,
      },
      {
        appType: this.appTypes.FILE,
        icon: 'note_add',
        category: this.appCategories.DATA_DISPLAYS,
      },
      {
        appType: this.appTypes.BUTTON,
        icon: 'add_box',
        category: this.appCategories.BASIC,
      },
      {
        appType: this.appTypes.ARTICLE,
        icon: 'vertical_split',
        category: this.appCategories.DATA_DISPLAYS,
      },
      {
        appType: this.appTypes.SOURCE,
        icon: 'code',
        category: this.appCategories.DATA_DISPLAYS,
      },
      {
        appType: this.appTypes.WALL,
        icon: 'feed',
        category: this.appCategories.BASIC,
      },
      {
        appType: this.appTypes.PROFILE,
        icon: 'person',
        category: this.appCategories.DATA_DISPLAYS,
      },
      {
        appType: this.appTypes.TABS,
        icon: 'tab',
        category: this.appCategories.OTHER,
      },
    ];

    api.getCustomAppTemplates({ disabled: false }).then((response) => {
      const self = this;
      this.customAppTemplates = response.customAppTemplates.filter(app =>
        app.name === 'Card' || app.name === 'Table' || app.name === 'Gantt' || app.name === 'Deck')
        .forEach(customApp => {
          const icon = self.getIcon(customApp.name);
          self.appMenuOptions.push({
            appType: self.appTypes.CUSTOM,
            icon,
            template: customApp,
            name: `${customApp.name}`,
            category: this.appCategories.DATA_DISPLAYS,
          });
        });
      this.appProps = this.appMenuOptions.reduce((normalized, option) => {
        const component = this.viewProp.version === 3.0 ? NewAppButton : EditorFloatingAppButton;
        const { instance, props } = this.cloneAndMountAppButton(
          component,
          option.appType, option.icon,
          false, option.template,
          option.name, option.category);
        instance.$on('start-dragging-manually', this.cloneApp);
        if (option.category === this.appCategories.BASIC) {
          this.$refs.basic.appendChild(instance.$el);
        } else if (option.category === this.appCategories.DATA_DISPLAYS) {
          this.$refs.dataDisplays.appendChild(instance.$el);
          if (instance.$el.getAttribute('title').trim() === 'Single Value') {
            instance.$el.children[0].className += ' single-value-icon';
          }
        } else if (option.category === this.appCategories.OTHER) {
          this.$refs.others.appendChild(instance.$el);
        }

        normalized[instance.uuid] = { appType: option.appType, props };

        return normalized;
      }, {});
    });
    // $('.').children().first();
  },
  computed: {
    appsActive() {
      return this.selectedTab === 'apps';
    },
    itemActive() {
      return this.selectedTab === 'item';
    },
    mostUsed() {
      return [];
    },
    basicApp() {
      return this.appMenuOptions.filter(menuOption => menuOption.category === this.appCategories.BASIC);
    },
    dataDisplayApps() {
      return this.appMenuOptions.filter(menuOption => menuOption.category === this.appCategories.DATA_DISPLAYS);
    },
    ...mapGetters(['selectedModalOfType', 'isAdmin', 'appInstanceSelected', 'selectedModalOfAppName']),

    ...mapState({
      collapsibleIsOpen: state => state.views.collapsibleIsOpen,
      appModalType: state => state.views.appModalType,
      saveAlert: state => state.ui.saveAlert,
    }),
    customOptionKey() {
      if(this.appModalType === "CustomApp") return Math.round(Math.random() * 1000)
        return 1;
    },
  },
  methods: {
    closeToolbar() {
      this.$store.dispatch(TOOGLE_TOOLBAR_COLLAPSIBLE);
    },
    isClosed() {
      if (this.collapsibleIsOpen) return 'editor-bar-width';

      return 'collapse-toolbar';
    },
    getIcon(customAppName) {
      return {
        'Table': 'table_chart',
        'Card': 'view_quilt',
        'Gantt': 'event_note',
        'Deck': 'assignment_ind',
      }[customAppName];
    },
    selectApp(appType) {
      console.log(appType);
    },
    selectTab(tabSelected) {
      if (tabSelected === 'apps') {
        if (this.saveAlert) {
          this.$store.dispatch(OPEN_SAVE_ALERT);
        } else {
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
      this.selectedTab = tabSelected;
    },
    cloneAndMountAppButton(component, appType, icon, showContainer, template, name = null, category = null) {
      const AppOption = Vue.extend(component);
      const instance = new AppOption({ store: this.$store });
      const options = {
        appType, icon,
        showContainer, appsEditable: true,
        showHeader: true, isNew: true,
        template,
        name,
        category,
      };
      const props = Vue.observable({
        ...instance.props,
        ...options,
      });
      // eslint-disable-next-line no-underscore-dangle
      instance._props = props;
      instance.$mount();
      this.appProps[instance.uuid] = { appType, props };

      return { instance, props };
    },
    // eslint-disable-next-line max-statements
    cloneApp(original, event) {
      const interaction = event.interaction;
      if (interaction.pointerIsDown && !interaction.interacting()) {
        const appGridContainer = this.getAppGridContainer();
        // eslint-disable-next-line no-underscore-dangle
        let { instance, _ } = this.cloneAndMountAppButton(
          AppContainerFloating, original.appType,
          original.icon, true,
          original._props.template,
          original.name, original.category);
        instance = this.configureClonedInstance(instance, appGridContainer, event);
        interaction.start({ name: 'drag' },
          event.interactable,
          instance.$el);
      }
    },
    getAppGridContainer() {
      // return this.$refs.editor.closest('#app-grid-container');
      return document.getElementById('app-grid-container');
    },
    addTableStyle(instance) {
      instance.$el.style.minWidth = '385px';
      instance.$el.style.minHeight = '175px';
      instance.$el.style.width = '700px';
      instance.$el.style.height = '280px';
    },
    addGanttStyle(instance) {
      instance.$el.style.minWidth = '385px';
      instance.$el.style.minHeight = '175px';
      instance.$el.style.width = '700px';
      instance.$el.style.height = '385px';
      instance.$el.style.maxHeight = '20000px';
    },
    addDeckStyle(instance) {
      instance.$el.style.minWidth = '385px';
      instance.$el.style.minHeight = '175px';
      instance.$el.style.width = '700px';
      instance.$el.style.height = '280px';
    },
    addCustomClasses(instance) {
      if (instance.appType === 'CustomApp') {
        const customType = instance.template.name;
        if (customType === 'Table') {
          this.addTableStyle(instance);
        } else if (customType === 'Gantt') {
          this.addGanttStyle(instance);
        } else if (customType === 'Deck') {
          this.addDeckStyle(instance);
        }
      }
    },
    configureClonedInstance(instance, appGridContainer, event) {
      this.addCustomClasses(instance);

      const { x, y } = this.getStartCoordinates(event);

      instance.$el.style.left = `${x - SIDEBAR_MINUS_HALF_EDITOR_BAR_HORIZONTALLY}px`;
      instance.$el.style.top = `${y - HEADER_MINUS_HALF_EDITOR_VERTICALLY}px`;

      appGridContainer.prepend(instance.$el);

      return instance;
    },
    selectCustomAppModal(template) {
      this.$store.commit(SET_APP_MODAL_TYPE, APP_TYPES.CUSTOM);
      this.$store.commit(SET_SELECTED_CUSTOM_APP_TEMPLATE, template);
    },
  },

};
</script>
<style>
.bg-gray-apps {
  background-color:#e5e9ec;
}

.editor-bar-width {
  width: 254px;
  transition: width .5s;
}

.collapse-toolbar {
  width: 23px;
  transition: width .5s;

}

.p-5px {
  padding: 0 10px;
}

.p-11px {
  padding: 11px 0;
}

.h-38px {
  height: 38px;
}

.search-input {
  margin-top: 14px;
  height: 28px;
  padding-left: 48px;
  border-radius: 3px;
}

.box-shadow-menu {
  box-shadow: 0 3px 6px #00000042;
  opacity: 1;
}

.search-icon-position {
  top: 16.37px;
  bottom: 6.8px;
  left: 22.55px;
}

.margin-top-40px {
  margin-top: 40.75px;
}

.color-gray-title {
  color: #555859;
}

.width-collapsible .Collapsible__trigger {
  width: 100%;
  /* padding: 20px; */
}

.text-category {
  font-size: 11px;
}

.toolbar-handler {
  top: calc(50% - 30px);
  right: 254px;
  height: 59px;
  width: 19px;
  background-color: #e5e9ec;
  transition: right .5s ease;
}

.toolbar-handler-shrinked {
  top: calc(50% - 30px);
  right: 23px;
  height: 59px;
  width: 19px;
  background-color: #e5e9ec;
  transition: right .5s ease;
  transform: rotate(180deg);
}

.top-with-bar {
  top: 56px;
}

.hide {
  opacity: 0;
  transition: .5s steps(2, end);
}

.single-value-icon {
  padding-left: 16px;
}

</style>
