export const SET_VIEWS = 'SET_VIEWS';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const SET_APP_CONTAINERS = 'SET_APP_CONTAINERS';
export const REMOVE_VIEW = 'REMOVE_VIEW';
export const UPDATE_SELECTED_VIEW = 'UPDATE_SELECTED_VIEW';
export const UPDATE_VIEWS = 'UPDATE_VIEWS';
export const SET_SELECTED_VIEW_ID = 'SET_SELECTED_VIEW_ID';
export const ADD_NORMALIZED_USERS = 'ADD_NORMALIZED_USERS';
export const SET_NORMALIZED_USERS = 'SET_NORMALIZED_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID';
export const SET_CURRENT_USER_ID = 'SET_CURRENT_USER_ID';
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL';
export const CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL';
export const SET_CONFIRMATION_BTN_TEXT = 'SET_CONFIRMATION_BTN_TEXT';
export const OPEN_FULLSCREEN_APP_MODAL = 'OPEN_FULLSCREEN_APP_MODAL';
export const CLOSE_FULLSCREEN_APP_MODAL = 'CLOSE_FULLSCREEN_APP_MODAL';
export const SET_FOLDERS = 'SET_FOLDERS';
export const SET_CURRENT_FOLDER = 'SET_CURRENT_FOLDER';
export const SET_PERSONAL_FOLDERS = 'SET_PERSONAL_FOLDERS';
export const ADD_PERSONAL_FOLDER = 'ADD_PERSONAL_FOLDER';
export const OPEN_FOLDER_MODAL = 'OPEN_FOLDER_MODAL';
export const CLOSE_FOLDER_MODAL = 'CLOSE_FOLDER_MODAL';
export const SET_SELECTED_FOLDER = 'SET_SELECTED_FOLDER';
export const ADD_FOLDER = 'ADD_FOLDER';
export const UPDATE_SELECTED_FOLDER = 'UPDATE_SELECTED_FOLDER';
export const REMOVE_FOLDER = 'REMOVE_FOLDER';
export const OPEN_USER_MODAL = 'OPEN_USER_MODAL';
export const CLOSE_USER_MODAL = 'CLOSE_USER_MODAL';
export const OPEN_MEMBER_MODAL = 'OPEN_MEMBER_MODAL';
export const CLOSE_MEMBER_MODAL = 'CLOSE_MEMBER_MODAL';
export const OPEN_USER_TAGS_MODAL = 'OPEN_USER_TAGS_MODAL';
export const CLOSE_USER_TAGS_MODAL = 'CLOSE_USER_TAGS_MODAL';
export const ADD_TENANT_TAGS = 'ADD_TENANT_TAGS';
export const ADD_NORMALIZED_CONNECTION = 'ADD_NORMALIZED_CONNECTION';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const SET_SELECTED_CONNECTION_ID = 'SET_SELECTED_CONNECTION_ID';
export const UPDATE_SELECTED_CONNECTION = 'UPDATE_SELECTED_CONNECTION';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';
export const SET_CURRENT_CONNECTION_ID = 'SET_CURRENT_CONNECTION_ID';
export const OPEN_CONNECTION_MODAL = 'OPEN_CONNECTION_MODAL';
export const CLOSE_CONNECTION_MODAL = 'CLOSE_CONNECTION_MODAL';
export const SET_CONFIRMATION_ACTION = 'SET_CONFIRMATION_ACTION';
export const SET_DECONFIRMATION_ACTION = 'SET_DECONFIRMATION_ACTION';
export const PERMIT_USER_IN_SELECTED_VIEW = 'PERMIT_USER_IN_SELECTED_VIEW';
export const UNPERMIT_USER_IN_SELECTED_VIEW = 'UNPERMIT_USER_IN_SELECTED_VIEW';
export const SET_SELECTED_CONTAINER_ID = 'SET_SELECTED_CONTAINER_ID';
export const SET_SELECTED_CONTAINER = 'SET_SELECTED_CONTAINER';
export const REMOVE_CONTAINER = 'REMOVE_CONTAINER';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const ADD_CONTAINER = 'ADD_CONTAINER';
export const SET_MAX_APP_WIDTH = 'SET_MAX_APP_WIDTH';
export const SET_APP_MODAL_TYPE = 'SET_APP_MODAL_TYPE';
export const SET_SELECTED_CUSTOM_APP_TEMPLATE = 'SET_SELECTED_CUSTOM_APP_TEMPLATE';
export const SET_CUSTOM_APP_TEMPLATES = 'SET_CUSTOM_APP_TEMPLATES';
export const REMOVE_CUSTOM_APP_TEMPLATE = 'REMOVE_CUSTOM_APP_TEMPLATE';
export const UPDATE_SELECTED_CUSTOM_APP_TEMPLATE = 'UPDATE_SELECTED_CUSTOM_APP_TEMPLATE';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const SET_THREAD_COMMENTS_TO_COMMENT = 'SET_THREAD_COMMENTS_TO_COMMENT';
export const ADD_THREAD_COMMENT_TO_COMMENT = 'ADD_THREAD_COMMENT_TO_COMMENT';
export const ADD_COMMENT_TO_CONTAINER = 'ADD_COMMENT_TO_CONTAINER';
export const SET_SELECTED_COMMENT_ID = 'SET_SELECTED_COMMENT_ID';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const SET_CURRENT_COMPANY_NAME = 'SET_CURRENT_COMPANY_NAME';
export const ADD_CURRENT_USER_CONNECTIONS = 'ADD_CURRENT_USER_CONNECTIONS';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const UPDATE_SELECTED_TEMPLATES = 'UPDATE_SELECTED_TEMPLATES';
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const OPEN_TEMPLATE_MODAL = 'OPEN_TEMPLATE_MODAL';
export const CLOSE_TEMPLATE_MODAL = 'CLOSE_TEMPLATE_MODAL';
export const SET_SELECTED_RESOURCE = 'SET_SELECTED_RESOURCE';
export const UNSET_SELECTED_RESOURCE = 'UNSET_SELECTED_RESOURCE';
export const OPEN_SHARE_RESOURCE_MODAL = 'OPEN_SHARE_RESOURCE_MODAL';
export const CLOSE_SHARE_RESOURCE_MODAL = 'CLOSE_SHARE_RESOURCE_MODAL';
export const OPEN_VIEW_SETTINGS_MODAL = 'OPEN_VIEW_SETTINGS_MODAL';
export const CLOSE_VIEW_SETTINGS_MODAL = 'CLOSE_VIEW_SETTINGS_MODAL';
export const SELECT_VIEW_FOR_SETTINGS = 'SELECT_VIEW_FOR_SETTINGS';
export const PERMIT_USER_IN_SELECTED_RESOURCE = 'PERMIT_USER_IN_SELECTED_RESOURCE';
export const UNPERMIT_USER_IN_SELECTED_RESOURCE = 'UNPERMIT_USER_IN_SELECTED_RESOURCE';
export const SET_CURRENT_COMPANY_ID = 'SET_CURRENT_COMPANY_ID';
export const UPDATE_TAG = 'UPDATE_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const SET_TAG_REMOVE_MODE = 'SET_TAG_REMOVE_MODE';
export const SET_TAG_EDIT_MODE = 'SET_TAG_EDIT_MODE';
export const SELECT_RESOURCE_FOR_MOVING = ' SELECT_RESOURCE_FOR_MOVING';
export const UNSELECT_RESOURCE_FOR_MOVING = 'UNSELECT_RESOURCE_FOR_MOVING';
export const CLOSE_MOVE_RESOURCE_MODAL = 'CLOSE_MOVE_RESOURCE_MODAL';
export const OPEN_MOVE_RESOURCE_MODAL = 'OPEN_MOVE_RESOURCE_MODAL';
export const SET_PREVIEW_TEMPLATE_APP_OPEN = 'SET_PREVIEW_TEMPLATE_APP_OPEN';
export const SET_VIEW_TEMPLATE = 'SET_VIEW_TEMPLATE';
export const SET_INDUSTRY_TAGS = 'SET_INDUSTRY_TAGS';
export const SET_COMPANY_SIZE_OPTIONS = 'SET_COMPANY_SIZE_OPTIONS';
export const REVENUE_TYPE_OPTIONS = 'REVENUE_TYPE_OPTIONS';
export const PROCESS_OPTIONS = 'PROCESS_OPTIONS';
export const VIEWING_LEVEL_OPTIONS = 'VIEWING_LEVEL_OPTIONS';
export const AREA_OPTIONS = 'AREA_OPTIONS';
export const SET_SELECTED_DATA_SOURCE = 'SET_SELECTED_DATA_SOURCE';
export const SET_DATA_SOURCE_SERVICE = 'SET_DATA_SOURCE_SERVICE';
export const SET_DATA_SOURCE_OPEN_MODAL = 'SET_DATA_SOURCE_OPEN_MODAL';
export const UNSET_SELECTED_DATA_SOURCE = 'UNSET_SELECTED_DATA_SOURCE';
export const SET_CONNECTIONS = 'SET_CONNECTIONS';
export const ADD_DINAMYC_INSTANCE = 'ADD_DINAMYC_INSTANCE';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const SET_BIGSTORE_OPEN_MODAL = 'SET_BIGSTORE_OPEN_MODAL';
export const SET_USERS_TAGGED = 'SET_USERS_TAGGED';
export const SET_TAG_MODAL_EDIT_MODE = 'SET_TAG_MODAL_EDIT_MODE';
export const SET_TAG_FOR_EDITION = 'SET_TAG_FOR_EDITION';
export const SET_SELECTED_TAG = 'SET_SELECTED_TAG';
export const SET_USER_MODAL_EDIT_MODE = 'SET_USER_MODAL_EDIT_MODE';
export const OPEN_WORKSPACE_MODAL = 'OPEN_WORKSPACE_MODAL';
export const CLOSE_WORKSPACE_MODAL = 'CLOSE_WORKSPACE_MODAL';
export const SET_WORKSPACE_MODAL_EDIT_MODE = 'SET_WORKSPACE_MODAL_EDIT_MODE';
export const SET_WORKSPACE_FOR_EDITION = 'SET_WORKSPACE_FOR_EDITION';
export const SET_SELECTED_WORKPACE = 'SET_SELECTED_WORKPACE';
export const SET_SELECTED_PLATFORM = 'SET_SELECTED_PLATFORM';
export const SET_WORKSPACES_LIST = 'SET_WORKSPACES_LIST';
export const ADD_WORKSPACES_LIST = 'ADD_WORKSPACES_LIST';
export const REMOVE_WORKSPACE = 'REMOVE_WORKSPACE';
export const UPDATE_WORKSPACES_LIST = 'UPDATE_WORKSPACES_LIST';
export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE';
export const SET_CONSULTANTS = 'SET_CONSULTANTS';
export const UPDATE_CONSULTANT_LIST = 'UPDATE_CONSULTANT_LIST';
export const SET_SELECTED_CONSULTANT = 'SET_SELECTED_CONSULTANT';
export const REMOVE_CONSULTANT = 'REMOVE_CONSULTANT';
export const OPEN_USER_DEFINED_TYPE_MODAL = 'OPEN_USER_DEFINED_TYPE_MODAL';
export const CLOSE_USER_DEFINED_TYPE_MODAL = 'CLOSE_USER_DEFINED_TYPE_MODAL';
export const SET_SELECTED_CUSTOM_COLUMNS = 'SET_SELECTED_CUSTOM_COLUMNS';
export const SET_SELECTED_CUSTOM_TYPE = 'SET_SELECTED_CUSTOM_TYPE';
export const SET_SELECTED_CUSTOM_DATUM = 'SET_SELECTED_CUSTOM_DATUM';
export const UPDATE_SELECTED_DATA = 'UPDATE_SELECTED_DATA';
export const SET_SELECTED_DATA = 'SET_SELECTED_DATA';
export const REMOVE_SELECTED_DATA = 'REMOVE_SELECTED_DATA';
export const OPEN_USER_DEFINED_TYPE_COLUMN_MODAL = 'OPEN_USER_DEFINED_TYPE_COLUMN_MODAL';
export const CLOSE_USER_DEFINED_TYPE_COLUMN_MODAL = 'CLOSE_USER_DEFINED_TYPE_COLUMN_MODAL';
export const SET_MERGE_USER_EMAIL = 'SET_MERGE_USER_EMAIL';
export const SET_CUSTOM_TABS = 'SET_CUSTOM_TABS';
export const SET_APP_TAB_COLORS = 'SET_APP_TAB_COLORS';
export const UPDATE_CONTAINED_CONTAINER = 'UPDATE_CONTAINED_CONTAINER';
export const SET_TOOGLE_TOOLBAR_COLLAPSIBLE = 'SET_TOOGLE_TOOLBAR_COLLAPSIBLE';
export const SET_APP_MODAL_NAME = 'SET_APP_MODAL_NAME';
export const SET_COLUMNS_FROM_DOCUMENT = 'SET_HEADERS_FROM_DOCUMENT';
export const SET_HEADERS_READY = 'SET_HEADERS_READY';
export const SET_FILTERS_FROM_DOCUMENT = 'SET_FILTERS_FROM_DOCUMENT';
export const SET_COLUMN_FIELD = 'SET_COLUMN_FIELD';
export const REMOVE_FILTER_CHECKED = 'REMOVE_FILTER_CHECKED';
export const REMOVE_FILTER_UNCHECKED = 'REMOVE_FILTER_UNCHECKED';
export const SET_FILTERS_CHECKED = 'SET_FILTERS_CHECKED';
export const SET_FILTERS_UNCHECKED = 'SET_FILTERS_UNCHECKED';
export const SET_APP_MODAL_TO_SHOW = 'SET_APP_MODAL_TO_SHOW';
export const SAVE_ALERT = 'SAVE_ALERT';
export const OPEN_UNSAVED_CHANGES_MODAL = 'OPEN_UNSAVED_CHANGES_MODAL';
export const CLOSE_UNSAVED_CHANGES_MODAL = 'CLOSE_UNSAVED_CHANGES_MODAL';
export const OPEN_DELETE_CONFIRMATION_MODAL = 'OPEN_DELETE_CONFIRMATION_MODAL';
export const CLOSE_DELETE_CONFIRMATION_MODAL = 'CLOSE_DELETE_CONFIRMATION_MODAL';
export const SET_SELECTED_CHECKED_FILTER_INDEX = 'SET_SELECTED_CHECKED_FILTER_INDEX';
export const SET_SELECTED_UNCHECKED_FILTER_INDEX = 'SET_SELECTED_UNCHECKED_FILTER_INDEX';
export const SET_FILENAMES = 'SET_FILENAMES';
export const SET_FILE_URLS = 'SET_FILE_URLS';
export const SET_FILENAMES_IN_APP = 'SET_FILENAMES_IN_APP';
export const SET_BLOCK_APP_TAB_SELECTION = 'SET_BLOCK_APP_TAB_SELECTION';
export const ADD_PUBLICATION = 'ADD_PUBLICATION';
export const SET_SELECTED_PUBLICATION_ID = 'SET_SELECTED_PUBLICATION_ID';
export const REMOVE_PUBLICATION = 'REMOVE_PUBLICATION';
export const SET_COMMENTS_TO_PUBLICATION = 'SET_COMMENTS_TO_PUBLICATION';
export const ADD_LINK_PREVIEW_METADATA = 'ADD_LINK_PREVIEW_METADATA';
export const ADD_PUBLICATIONS = 'ADD_PUBLICATIONS';
export const ADD_PUBLICATION_COMMENT = 'ADD_PUBLICATION_COMMENT';
export const UPDATE_PUBLICATION_COMMENT = 'UPDATE_PUBLICATION_COMMENT';
export const REMOVE_PUBLICATION_COMMENT = 'REMOVE_PUBLICATION_COMMENT';
export const ADD_PUBLICATION_THREAD_COMMENTS = 'ADD_PUBLICATION_THREAD_COMMENTS';
export const ADD_PUBLICATION_THREAD_COMMENT = 'ADD_PUBLICATION_THREAD_COMMENT';
export const SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID = 'SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID';
export const REMOVE_PUBLICATION_THREAD_COMMENT = 'REMOVE_PUBLICATION_THREAD_COMMENT';
export const UPDATE_PUBLICATION_THREAD_COMMENT = 'UPDATE_PUBLICATION_THREAD_COMMENT';
export const ADD_MORE_PUBLICATIONS = 'ADD_MORE_PUBLICATIONS';
export const SET_APP_ATTRIBUTES = 'SET_APP_ATTRIBUTES';
export const DELETE_SELECTED_TAB_CONTAINER = 'DELETE_SELECTED_TAB_CONTAINER';
export const REMOVE_TAB_CONTAINER = 'REMOVE_TAB_CONTAINER';
export const SET_TAB_CONTAINER_ID = 'SET_TAB_CONTAINER_ID';
export const SET_TEXT_APP_BEEN_EDITED = 'SET_TEXT_APP_BEEN_EDITED';
export const SET_BOOLEAN_SELECTED = 'SET_BOOLEAN_SELECTED';
export const ADD_SELECTED_DATA = 'ADD_SELECTED_DATA';
export const SET_SELECTED_ITEM_ID = 'SET_SELECTED_ITEM_ID';
export const SET_SHOULD_SAVE = 'SET_SHOULD_SAVE';
export const SET_APPS_DRAGGABLE = 'SET_APPS_DRAGGABLE';
export const SET_DATA_SOURCE_UPDATED = 'SET_DATA_SOURCE_UPDATED';
export const UPDATE_APP_FLOATING = 'UPDATE_APP_FLOATING';
export const SET_TAB_SELECTED_ID = 'SET_TAB_SELECTED_ID';
export const SET_MENU_WITH_ITEMS = 'SET_MENU_WITH_ITEMS';
