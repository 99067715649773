<template>
  <div
    :id="appButtonID"
  >
    <a
      v-if="redirectType === 'file'"
      :href="container.app.fileUrl"
      target="_blank"
      class="cursor-pointer"
    >
      <div
        v-if="container && !editable"
        ref="ButtonApp"
        class="h-full"
        :class="{
          'show-bg-transparent': showTransparency,
        }"
      >
        <div class="flex flex-col">
          <div
            v-if="hasIcon && iconPosition === 'top'"
            class="icon-bottom-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
          <div
            :class="{
              'flex flex-row': ['right', 'left'].includes(iconPosition),
            }"
          >
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'left'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
            <div
              class="flex flex-col self-center center-elements"
            >
              <div
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
              />
            </div>
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'right'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
          </div>
          <div
            v-if="hasIcon && iconPosition === 'bottom'"
            class="icon-top-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
        </div>
      </div>
    </a>
    <a
      v-else-if="redirectType === 'url'"
      :href="redirectLink"
      target="_blank"
      class="cursor-pointer"
    >
      <div
        v-if="container && !editable"
        ref="ButtonApp"
        class="h-full"
        :class="{
          'show-bg-transparent': showTransparency,
        }"
      >
        <div class="flex flex-col">
          <div
            v-if="hasIcon && iconPosition === 'top'"
            class="icon-bottom-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
          <div
            :class="{
              'flex flex-row': ['right', 'left'].includes(iconPosition),
            }"
          >
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'left'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
            <div
              class="flex flex-col self-center center-elements"
            >
              <div
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
              />
            </div>
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'right'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
          </div>
          <div
            v-if="hasIcon && iconPosition === 'bottom'"
            class="icon-top-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
        </div>
      </div>
    </a>
    <a
      v-else-if="redirectType === 'view'"
      :href="getResourceUrl(redirectLink)"
      :target="openNewTab ? '_blank' : '_self'"
      class="cursor-pointer"
    >
      <div
        v-if="container && !editable"
        ref="ButtonApp"
        class="h-full"
        :class="{
          'show-bg-transparent': showTransparency,
        }"
      >
        <div class="flex flex-col">
          <div
            v-if="hasIcon && iconPosition === 'top'"
            class="icon-bottom-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
          <div
            :class="{
              'flex flex-row': ['right', 'left'].includes(iconPosition),
            }"
          >
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'left'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
            <div
              class="flex flex-col self-center center-elements"
            >
              <div
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
              />
            </div>
            <div
              class="flex self-center center-elements"
              v-if="hasIcon && iconPosition === 'right'"
            >
              <i
                :id="buttonIconID"
                class="material-icons button-icon"
              >{{ icon }}</i>
            </div>
          </div>
          <div
            v-if="hasIcon && iconPosition === 'bottom'"
            class="icon-top-padding self-center center-elements"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
        </div>
      </div>
    </a>
    <div
      v-if="!(container && !editable)"
      class="h-full :overflow-hidden overflow-y-scroll"
      :class="{
        'show-bg-transparent': showTransparency,
      }"
      ref="buttonApp"
      id="button-app"
    >
      <div
        class="flex flex-col"
        style="justify-content: center; align-items: center;"
      >
        <div
          class="self-center center-elements"
          v-if="hasIcon && iconPosition === 'top'"
          :class="{
            'icon-bottom-padding': iconPosition === 'top',
          }"
        >
          <i
            :id="buttonIconID"
            class="material-icons button-icon"
          >{{ icon }}</i>
        </div>
        <div
          class="flex flex-row"
        >
          <div
            class="flex self-center center-elements"
            v-if="hasIcon && iconPosition === 'left'"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
          <div
            id="editor-div"
            class="flex flex-col self-center center-elements"
          >
            <vue-editor
              v-if="hasDescriptiveText"
              class="flex padding-horizontal-zero"
              :id="editorId"
              v-model="descriptiveText"
              :editor-toolbar="customToolbar"
              @text-change="autoSaveDescriptiveText"
              @focus="toolbarShow()"
              @blur="toolbarHide()"
            />
          </div>
          <div
            class="flex self-center center-elements"
            v-if="hasIcon && iconPosition === 'right'"
          >
            <i
              :id="buttonIconID"
              class="material-icons button-icon"
            >{{ icon }}</i>
          </div>
        </div>
        <div
          class="flex self-center center-elements icon-top-padding"
          v-if="hasIcon && iconPosition === 'bottom'"
        >
          <i
            :id="buttonIconID"
            class="material-icons button-icon"
          >{{ icon }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { VueEditor } from 'vue2-editor';
import $ from 'jquery';
import Quill from 'quill';
import { APP_TYPES } from '../constants';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';

const DEBOUNCE_TIME = 1000;
const DECIMAL_BASE = 10;
const DEFAULT_ICON_FONT_SIZE = 26;
const DEFAULT_ICON_SIZE = 40;
const DEFAULT_ICON_PADDING = 5;

export default {
  mixins: [positionMixin],
  components: {
    VueEditor,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
  },
  async mounted() {
    this.setIconSize();
    $('.ql-toolbar.ql-snow').hide();
    $('.ql-editor').attr('style',
      `min-height: 0px !important; padding-left: 15.4px; padding-right: 15.4px;
      padding-bottom: 0px; padding-top: 0px;`,
    );
    this.updateIconColor();
    this.setContentCenterAlignment();
    this.colorSelected();
  },
  updated() {
    this.setIconSize();
    $('.ql-toolbar.ql-snow').hide();
    $('.ql-editor').attr('style',
      `min-height: 0px !important; padding-left: 15.4px; padding-right: 15.4px;
      padding-bottom: 0px; padding-top: 0px;`,
    );
    this.updateIconColor();
    this.setContentCenterAlignment();
    this.colorSelected();
  },
  data() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
      'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
      'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
      'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
    ];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = [
      '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
    ];
    Quill.register(Size, true);
    this.defineButtonAppAttrs();

    return {
      appTypes: APP_TYPES,
      customToolbar: customToolbarHelper.customToolbar,
      descriptiveText: _.get(this.container, 'app.descriptiveText', '<p>BOTÓN</p>'),
      containerID: _.get(this.container, 'id', -1),
      redirectType: _.get(this.container, 'app.redirectType', 'view'),
      openNewTab: _.get(this.container, 'app.openNewTab', false),
      redirectLink: _.get(this.container, 'app.redirectLink', 'https://bigpictures.co/'),
      initialDescriptiveText: _.get(this.container, 'app.descriptiveText', '<p>BOTÓN</p>'),
    };
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
    }),
    editorId() {
      return `text-editor${this.containerID}`;
    },
    textEditor() {
      return '#text-editor'.concat(this.containerID.toString());
    },
    hasDescriptiveText() {
      return this.appAttrs[this.container.id].hasDescriptiveText;
    },
    hasIcon() {
      return this.appAttrs[this.container.id].hasIcon;
    },
    icon() {
      return this.appAttrs[this.container.id].icon;
    },
    iconPosition() {
      return this.appAttrs[this.container.id].iconPosition;
    },
    iconColor() {
      return this.appAttrs[this.container.id].iconColor;
    },
    shape() {
      return this.appAttrs[this.container.id].shape;
    },
    iconSize() {
      return this.appAttrs[this.container.id].iconSize;
    },
    containerBackgroundColor() {
      return _.get(this.container, 'backgroundColor', '#FFFFFF');
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    showTransparency() {
      return _.get(this.container, 'showTransparency', false);
    },
    buttonIconID() {
      return `button-icon${this.containerID}`;
    },
    appButtonID() {
      return `button-app${this.containerID}`;
    },
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    roundShape() {
      return this.shape === 'round';
    },
  },
  methods: {
    setContentCenterAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content:', 'center');
    },
    setIconSize() {
      const factor = parseFloat(this.iconSize, DECIMAL_BASE);
      const iconIDSearch = '#'.concat(this.buttonIconID);
      $(iconIDSearch).css({
        'font-size': `${factor * DEFAULT_ICON_FONT_SIZE}px`,
        'width': `${factor * DEFAULT_ICON_SIZE}px`,
        'height': `${factor * DEFAULT_ICON_SIZE}px`,
        'padding-top': `${factor * DEFAULT_ICON_PADDING}px`,
        'padding-left': `${factor * DEFAULT_ICON_PADDING}px`,
      });
    },
    updateIconColor() {
      const iconIDSearch = '#'.concat(this.buttonIconID);
      $(iconIDSearch).css('color', this.iconColor);
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.textEditor).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow() {
      $(this.textEditor).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    hasContainer() {
      return !_.isEmpty(this.container);
    },
    autoSaveDescriptiveText: _.debounce(function () {
      this.updateDescriptiveTextContent();
    }, DEBOUNCE_TIME),
    updateDescriptiveTextContent() {
      if (this.initialDescriptiveText !== this.descriptiveText) {
        const params = { app: { descriptiveText: this.descriptiveText } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        const initialDescriptiveText = this.descriptiveText;
        this.initialDescriptiveText = initialDescriptiveText;
      }
    },
    defineButtonAppAttrs() {
      const params = {
        hasDescriptiveText: _.get(this.container, 'app.hasDescriptiveText', true),
        hasIcon: _.get(this.container, 'app.hasIcon', true),
        icon: _.get(this.container, 'app.icon', 'add_circle'),
        iconPosition: _.get(this.container, 'app.iconPosition', 'top'),
        iconSize: _.get(this.container, 'app.iconSize', 1.0),
        shape: _.get(this.container, 'app.shape', 'straight'),
        iconColor: _.get(this.container, 'app.iconColor', '#0031ff'),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
    },
    applyBackgroundColor(draggableID, polymorphicAppID, buttonIconID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
      $(buttonIconID).css('background-color', this.containerBackgroundColor);
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID, buttonIconID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
      $(buttonIconID).css('background-color', 'transparent');
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      const buttonIconID = `#${this.buttonIconID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID, buttonIconID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID, buttonIconID);
      }
    },
    getResourceUrl(viewID) {
      return `/views/${viewID}`;
    },
  },
};
</script>
<style>

.button-icon {
  font-size: 26px;
  width: 62px;
  height: 62px;
  padding-top: 18px;
  padding-left: 18px;
  opacity: 1;
  border-radius: 50px;
}

.show-bg-transparent {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.padding-horizontal-zero {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.app-container__content .ql-editor {
  padding: 0px 0px !important;
}

.icon-bottom-padding {
  padding-bottom: 10px;
}

.icon-top-padding {
  padding-top: 10px;
}

.center-elements {
  justify-content: center;
  align-items: center;
}

.transparent-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

</style>
