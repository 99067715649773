<template>
  <div
    :id="positionProp"
    class="flex-col"
    :v-if="positionProp"
  >
    <div
      class="flex items-center height-40 cursor-pointer"
      :class="styleSelectedClass(defaultHome.url)"
      :style="fontIconColor"
    >
      <a :href="defaultHome.url">
        <i
          :class="{
            'material-icons-menu-custom--selected': styleSelectedClass(defaultHome.url),
            'material-icons-menu-custom': !styleSelectedClass(defaultHome.url)
          }"
          class="p-2 material-icons material-icons-rounded material-icons-menu-custom"
        >
          home
        </i>
      </a>
      <a
        :href="defaultHome.url"
        class="inline-block"
      >
        <div
          :class="{'text-menu-selected': styleSelectedClass(defaultHome.url)}"
          class="pl-2 text-menu-custom"
        >
          Home
        </div>
      </a>
    </div>
    <div
      class="flex items-center height-40 cursor-pointer"
      :class="styleSelectedClass(getResourceUrl(item))"
      :style="fontIconColor"
      :key="item.resource_id"
      v-for="item in menuItems"
    >
      <a :href="getResourceUrl(item)">
        <i
          class="p-2 material-icons material-icons-menu-custom"
          :class="{
            'material-icons-menu-custom--selected': styleSelectedClass(getResourceUrl(item)),
            'material-icons-menu-custom': !styleSelectedClass(getResourceUrl(item))
          }"
        >{{ item.icon }}</i>
      </a>
      <a
        class="inline-block"
        :href="getResourceUrl(item)"
      >
        <div
          class="pl-2 text-menu-custom"
          :class="{'text-menu-selected': styleSelectedClass(getResourceUrl(item))}"
        >
          {{ item.label }}
        </div>
      </a>
    </div>

    <div
      class="flex items-center height-40 cursor-pointer"
      :class="styleSelectedClass('/users')"
      :style="fontIconColor"
    >
      <a
        v-if="isAdminProp"
        href="/users"
      >
        <i
          :class="{
            'material-icons-menu-custom--selected': styleSelectedClass('/users'),
            'material-icons-menu-custom': !styleSelectedClass('/users')
          }"
          class="p-2 material-icons material-icons-rounded material-icons-menu-custom"
        >
          group_work
        </i>
      </a>
      <a
        v-if="isAdminProp"
        href="/users"
        class="inline-block"
      >
        <div
          :class="{'text-menu-selected': styleSelectedClass('/users')}"
          class="pl-2 text-menu-custom"
        >
          Equipo
        </div>
      </a>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  components: {
  },
  props: {
    masterViewProp: { type: Object, default: () => {} },
    brandColorsProp: { type: Object, default: () => {} },
    positionProp: { type: String, default: 'leftMenu' },
    isAdminProp: { type: Boolean, default: false },
    defaultHome: { type: Object, default: () => ({ url: '/my_unit' }) },

  },
  data() {
    return {
      masterView: this.masterViewProp,
      brandColors: this.brandColorsProp,
    };
  },
  mounted() {
  },
  methods: {
    styleSelectedClass(resourceUri) {
      const pathName = window.location.pathname;

      return resourceUri === pathName ? 'bg-action-secondary text-menu-selected' : null;
    },
    getResourceUrl(item) {
      if (item.type === 'folder') return `/folders/${item.resource_id}`;
      if (item.type === 'view') return `/views/${item.resource_id}`;

      return item.resource_id;
    },
  },
  computed: {
    leftMenu() {
      if (!!this.masterView === false) return false;
      const lMenu = this.masterView.appContainers.filter(c => c.app.position === 'left');
      if (lMenu.length > 0) return lMenu[0];

      return false;
    },
    rightMenu() {
      if (!!this.masterView === false) return false;
      const lMenu = this.masterView.appContainers.filter(c => c.app.position === 'right');
      if (lMenu.length > 0) return lMenu[0];

      return false;
    },
    menuItems() {
      return _.get(this[this.positionProp], 'app.menuItems', []);
    },
    fontIconColor() {
      const color = this.brandColors ? this.brandColors.font_icon_color : '';

      return { color };
    },
  },

};
</script>
