export default {
  // eslint-disable-next-line max-len
  iconsList: ['person_add_alt_1', 'record_voice_over', 'person', 'group', 'groups', 'accessible',
    'family_restroom', 'directions_walk', 'psychology', 'sentiment_satisfied_alt', 'sentiment_neutral',
    'sentiment_very_dissatisfied', 'support_agent', 'thumb_up', 'thumb_down', 'star', 'build', 'check_circle',
    'cancel', 'add_circle', 'do_disturb_on', 'info', 'help', 'report_problem', 'circle_notifications',
    'arrow_upward', 'arrow_forward', 'arrow_downward', 'arrow_back', 'file_upload', 'download', 'do_not_disturb_alt',
    'moving', 'bar_chart', 'timeline', 'apps', 'category', 'group_work', 'auto_awesome_mosaic', 'view_headline',
    'facebook', 'whatsapp', 'call', 'mail_outline', 'insert_link', 'share', 'phone_iphone', 'laptop_mac',
    'message', 'photo_camera', 'play_circle_filled', 'videocam', 'my_location', 'fmd_good', 'near_me', 'south_america',
    'home', 'home_work', 'business', 'account_balance', 'filter_hdr', 'airplanemode_active', 'directions_bus',
    'directions_car', 'local_shipping', 'create', 'search', 'construction', 'door_back', 'work', 'insert_drive_file',
    'settings_applications', 'account_tree', 'save', 'assignment', 'access_alarm', 'access_time_filled',
    'calendar_month', 'credit_card', 'attach_money', 'bookmark', 'bolt', 'auto_awesome', 'favorite', 'balance',
    'dining', 'theater_comedy', 'filter_drama', 'cake', 'audiotrack', 'emoji_events', 'flag', 'emoji_objects',
    'extension', 'fastfood', 'free_breakfast', 'light_mode', 'dark_mode', 'hourglass_bottom', 'rocket_launch',
    'redeem', 'luggage', 'shopping_cart', 'school', 'visibility', 'menu_book', 'local_offer', 'folder', 'volume_up'],
  nameIconList: ['person', 'person_outline', 'person_pin', 'person_pin_circle', 'account_box', 'account_circle',
    'assignment_ind', 'badge', 'contact_page', 'insert_emoticon'],
  professionIconList: ['work', 'work_outline', 'workspace_premium', 'business_center'],
  emailIconList: ['email', 'alternate_email', 'mail_outline', 'contact_mail', 'message', 'send', 'chat_bubble',
    'chat_bubble_outline', 'inbox', 'forward_to_inbox'],
  phoneIconList: ['phone', 'contact_phone', 'perm_phone_msg', 'phone_android', 'phone_enabled', 'phone_iphone',
    'dialpad'],
  birthdayIconList: ['cake', 'celebration', 'event', 'today', 'event_note', 'calendar_today',
    'date_range', 'calendar_month', 'event_available', 'child_care'],
};
