<template>
  <div>
    <modal
      name="add-row-modal"
      :width="498"
      :height="365.24"
      :scrollable="true"
      :click-to-close="false"
      :resizable="false"
    >
      <div
        class="flex flex-row border-b"
        style="height: 54.51px; margin-bottom: 33.47px; position: sticky;
          top: 0px; background-color: white; z-index: 10;"
      >
        <div
          class="w-9/12"
          style="margin-left: 26.38px; margin-top: 15.45px;"
        >
          <i
            class="material-icons material-icons-inline add-style"
          >add</i>
          <span
            v-if="editMode"
            class="new-database-title"
          >
            Editar fila
          </span>
          <span
            v-else
            class="new-database-title"
          >
            Agregar entrada manual
          </span>
        </div>
        <div
          class="w-3/12"
        >
          <div
            class="app-modal__close-btn text-right close-box"
            @click="close()"
          >
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      <div
        v-for="(column, index) in columns"
        :key="indexKey(index)"
        style="margin-left: 26.38px; margin-bottom: 9.29px;"
        class="flex flex-col"
      >
        <span
          class="database-param-font"
          style="height: 32px;"
        >
          {{ column.columnName }}
        </span>
        <div
          class="flex flex-row"
        >
          <input
            v-if="column.name === 'Texto'"
            type="text"
            v-model="values[column.columnName]"
            :placeholder="'Escribe un texto...'"
            class="database-value-input-param-box input-params-font"
            style="width: 53.87%;"
          >
          <input
            v-else-if="column.name === 'Número'"
            type="number"
            v-model="values[column.columnName]"
            :placeholder="'Escribe un número...'"
            class="database-value-input-param-box input-params-font"
            style="width: 53.87%;"
          >
          <div
            v-else-if="column.name === 'Fecha'"
            style="width: 53.87%;"
          >
            <date-picker
              :key="dateKey"
              placeholder="Selecciona una fecha..."
              type="date"
              value-type="DD-MM-YYYY"
              format="DD-MM-YYYY"
              input-class="add-row-datepicker input-params-font height-32
                input-param-datepicker-width database-value-input-param-box-datepicker"
              v-model="values[column.columnName]"
              :lang="lang"
              @pick="updateDatePickerValue()"
              @clear="updateDatePickerValue()"
            />
          </div>
          <div
            v-else-if="column.name === 'Booleano'"
            style="width: 53.87%;"
          >
            <VueSelectStandard
              class="text-center text-sm"
              style="width: 100% !important; height: 0;"
              :data="[{ name: 'Si', id: 1 }, { name: 'No', id: 2 }]"
              v-model="booleans[column.columnName]"
              @boolListener="selectBooleanType(column.columnName)"
              :listener="'boolListener'"
              :font-class="'database-param-font'"
              :boolean-case="true"
            />
          </div>
          <image-upload
            :key="imageUploadKey"
            v-else-if="column.name === 'Imagen'"
            class="database-value-input-param-box input-params-font"
            @loading-start="loadingImage = true"
            @loading-end="loadingImage = false"
            @uploaded="handleImageUploaded"
            :from="column.columnName"
            style="width: 53.87%;"
          >
            {{ values[column.columnName] || 'Selecciona una imagen...' }}
          </image-upload>
          <file-upload
            :key="documentUploadKey"
            v-else-if="column.name === 'Archivo'"
            class="database-value-input-param-box input-params-font"
            @loading-start="loadingFile = true"
            @loading-end="loadingFile = false"
            @uploaded="handleFileUploaded"
            :from="column.columnName"
            style="width: 53.87%;"
          >
            {{ values[column.columnName] || 'Selecciona un archivo...' }}
          </file-upload>
          <div style="width: 3.07%;" />
          <span
            class="internal-type-box internal-type-font height-32"
            style="width: 38.41%; display: inline-flex; align-items: center; height: 32px;"
          >
            {{ column.name }}
          </span>
          <div style="width: 4.65%;" />
        </div>
      </div>
      <div
        v-if="canSave()"
        class="create-database-btn create-database-btn-font"
        style="margin-bottom: 32.76px; margin-top: 65.2px;"
        @click="save()"
      >
        <span v-if="editMode">
          Guardar cambios
        </span>
        <span v-else>
          Crear
        </span>
      </div>
      <div
        v-else
        class="create-database-btn create-database-btn-font create-disabled"
        style="margin-bottom: 32.76px; margin-top: 65.2px;"
      >
        <span v-if="editMode">
          Guardar cambios
        </span>
        <span v-else>
          Crear
        </span>
      </div>
    </modal>
  </div>
</template>

<script>

import $ from 'jquery';
import _ from 'lodash';
import { mapState } from 'vuex';
import DatePicker from 'vue2-datepicker';
import humps from 'humps';
import 'vue2-datepicker/index.css';
import VueSelectStandard from './vue-select-standard';
import showMessageMixin from '../mixins/showMessageMixin';
import FileUpload from '../components/utils/file-upload.vue';
import { CLOSE_APP_MODAL } from '../store/action-types';
import {
  UPDATE_SELECTED_DATA,
  ADD_SELECTED_DATA,
  SET_SELECTED_CUSTOM_DATUM,
} from '../store/mutation-types';
import api from '../api';

export default {
  mixins: [showMessageMixin],
  props: {
    listener: { type: String, default: 'addRowDatabasesListener' },
    customAttrs: { type: Array, default: () => [] },
  },
  components: {
    DatePicker,
    VueSelectStandard,
    FileUpload,
  },
  data() {
    return {
      values: {},
      imagesHash: {},
      filesHash: {},
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
          months:
            ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
              'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
          weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
          weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
        },
        monthBeforeYear: true,
      },
      columns: humps.camelizeKeys(this.customAttrs),
      booleans: this.constructBooleans(this.customAttrs),
      loadingImage: false,
      loadingFile: false,
      imageKey: 0,
      documentKey: 0,
      editDataKey: 0,
      dateKey: 0,
    };
  },
  mounted() {
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
    if (this.editMode) {
      this.selectedCustomDatum.dataRow.forEach(column => {
        if (column.value && !this.isInt(column.value) && !this.isFloat(column.value) &&
          column.value.includes('/rails/active_storage')) {
          this.values[column.name] = column.filename;
        } else if (['Si', 'No'].includes(column.value)) {
          let yesOrNoObject;
          if (column.value === 'Si') {
            yesOrNoObject = { name: 'Si', id: 1 };
          } else {
            yesOrNoObject = { name: 'No', id: 2 };
          }
          this.booleans[column.name] = yesOrNoObject;
          this.values[column.name] = column.value;
        } else {
          this.values[column.name] = column.value;
        }
      });
      this.editDataKey += 1;
    }
  },
  computed: {
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
      mergeUserEmail: state => state.views.mergeUserEmail,
      saveAlert: state => state.ui.saveAlert,
      selectedContainerId: state => state.views.selectedContainerId,
      booleanSelected: state => state.ui.booleanSelected,
      selectedCustomColumn: state => state.users.selectedCustomColumn,
      selectedCustomType: state => state.users.selectedCustomType,
      selectedCustomDatum: state => state.users.selectedCustomDatum,
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    imageUploadKey() {
      return this.imageKey;
    },
    documentUploadKey() {
      return this.documentKey;
    },
    editMode() {
      return !_.isEmpty(this.selectedCustomDatum);
    },
  },
  methods: {
    datumParam() {
      const params = {
        userTypeDatum: {
          files: this.getSignedIds(this.filesHash),
          images: this.getSignedIds(this.imagesHash),
          dataRow: this.valuesProcessed(),
        },
      };

      return params;
    },
    valuesProcessed() {
      const columnNames = Object.keys(this.values);

      const processedData = columnNames.map(columnName => ({ columnName, value: this.values[columnName] }));

      return processedData;
    },
    close() {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, null);
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show('add-row-modal');
    },
    selectBooleanType(columnName) {
      this.values[columnName] = this.booleanSelected.name;
      this.booleans[columnName] = { name: this.booleanSelected.name, id: this.booleanSelected.id };
    },
    constructBooleans() {
      const booleansObject = {};
      const booleans = humps.camelizeKeys(this.customAttrs)
        .filter(column => column.name === 'Booleano');
      for (const boolean of booleans) {
        booleansObject[boolean.columnName] = { name: 'Selecciona una opción', id: 0 };
      }

      return booleansObject;
    },
    handleImageUploaded({ _url, fileSignedId, name, from }) {
      this.values[from] = name;
      this.imagesHash[from] = { name, fileSignedId };
      this.imageKey += 1;
    },
    handleFileUploaded({ _url, fileSignedId, name, from }) {
      this.values[from] = name;
      this.filesHash[from] = { name, fileSignedId };
      this.documentKey += 1;
    },
    getSignedIds(objectList) {
      return Object.keys(objectList).reduce((imgList, imgKey) => {
        imgList.push(objectList[imgKey].fileSignedId);

        return imgList;
      }, []);
    },
    save() {
      if (this.editMode) {
        api.updateUserDefinedTypeData(
          this.selectedCustomType.id,
          this.selectedCustomDatum.id,
          this.datumParam(),
        ).then(result => {
          if (result.errors) {
            this.showMessage(result.errors);
          } else {
            result.dataRow.createdAt = result.createdAt;
            result.dataRow.updatedAt = result.updatedAt;
            result.dataRow.id = result.id;
            const updatedRow = { dataRow: result.dataRow, id: result.id };
            const indexToReplace = this.selectedCustomData.findIndex(v => v.id === result.id);
            this.$store.commit(UPDATE_SELECTED_DATA,
              { value: updatedRow, replaceIndex: indexToReplace });
            this.showMessage('Fila actualizada exitosamente');
          }
          this.close();
        });
      } else {
        api.createUserDefinedTypeData(this.selectedCustomType.id, this.datumParam())
          .then(result => {
            console.log('result', result);
            if (result === null || result === undefined) {
              this.showMessage('No se ha podido crear la entrada para evitar duplicados. Revisa las llaves seteadas y comprueba tus datos');
            } else {
              const newRow = { dataRow: result.dataRow, id: result.id };
              this.$store.commit(ADD_SELECTED_DATA, newRow);
              this.$emit(this.listener, result.dataRow);
            }
            this.close();
          });
      }
    },
    indexKey(index) {
      return `${this.editDataKey}-${index}`;
    },
    updateDatePickerValue() {
      this.dateKey += 1;
    },
    badValues() {
      Object.entries(this.values).forEach(([_key, value]) => {
        if (value === null || value === '') {
          return true;
        }
      });

      return false;
    },
    canSave() {
      if (
        this.columns
          .map(column => column.columnName)
          .map(name => this.values.hasOwnProperty(name)).includes(false) ||
        this.badValues()
      ) {
        return false;
      }

      return true;
    },
    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
  },
};
</script>

<style>

.database-value-input-param-box {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.internal-type-font {
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
  padding-left: 12.13px;
}

.internal-type-box {
  background: #C7D8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.database-value-input-param-box-datepicker {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 53.87%;
  padding-top: 0.5%;
}

.input-param-datepicker-width {
  width: 100% !important;
}

/* input.add-row-datepicker[type="date"] {
  width: 100% !important;
} */

/* input {
  box-sizing     : border-box;
  vertical-align : top;
  border         : #0f0 2px solid;
  border-radius  : 5px;
  font-size      : 12px;
  height         : 32px;
  background     : #0000;
} */

.mx-datepicker {
  width: 100%;
}

.mx-datepicker-popup {
  /* height: 100%; */
}

.mx-date-row {
  text-align: center;
}

th {
  text-align: center !important;
}

.mx-calendar-content .cell.active {
  border-radius: 50%;
}

.mx-calendar-content .cell.hover {
  border-radius: 50% !important;
}

table tr {
  height: 32px;
}

.mx-calendar {
  width: 252px;
}

.cell {
  border-radius: 50%;
}

.mx-datepicker-popup {
  top: 426.255px ;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.file-uploads {
  display: inline-flex !important;
  align-items: center;
  height: 32px;
  position: none !important;
  text-align: none !important;
}
</style>
