<template>
  <div class="grid-index">
    <internal-databases-modal
      v-if="selectedModalOfType('NewInternalDatabase')"
      @updateDatabasesListener="updateUserTypes"
      :listener="'updateDatabasesListener'"
    />
    <div
      class="flex flex-row mt-20 p-8 bg-white h-20 items-center justify-left
             border-gray-400 rounded-t-lg text-size-18"
    >
      <h4 class="w-9/12 internal-databases-title">
        Listado de bases de datos
      </h4>

      <div class="w-3/12 flex">
        <button
          @click="createNewType"
          class="px-2 py-2 cursor-pointer new-database-button"
        >
          <i class="material-icons-outlined material-icons-inline material-icons-active">add</i>
          <span class="new-database-button-font pr-1">Nueva base de datos</span>
        </button>
      </div>
    </div>
    <div
      class="bg-white min-h-full w-full"
      :class="{ hidden: !userTypesActive }"
    >
      <div
        class="px-8 pt-4 pb-1 h-16 flex items-center bg-white"
        style="position: sticky; top: 0;"
      >
        <div
          class="databases-table-header-font"
          style="width: 16.6%; padding-left: 1rem;"
        >
          Nombre
        </div>
        <div
          class="databases-table-header-font"
          style="width: 16.6%; padding-left: 1rem;"
        >
          Tipo
        </div>
        <div
          class="databases-table-header-font"
          style="width: 11.1%; padding-left: .47rem;"
        >
          Entradas
        </div>
        <div
          class="databases-table-header-font"
          style="width: 22.2%; padding-left: .34rem;"
        >
          Fecha creación
        </div>
        <div
          class="databases-table-header-font"
          style="width: 22.2%; padding-left: .14rem;"
        >
          Propietario
        </div>
        <div
          class="databases-table-header-font"
          style="width: 11.1%; padding-left: .34rem;"
        >
          Acciones
        </div>
      </div>
      <div
        class="flex flex-col items-center px-8"
        style="height: auto;"
      >
        <div
          :key="userTypes['id']"
          v-for="userTypes in userDefinedTypes"
          class="flex w-full databases-table-entries-font databases-table-entries-bg mb-4"
          style="height: 59.73px; align-items: center;"
        >
          <div style="width: 14%; margin-left: 17.57px; overflow: hidden;">
            {{ userTypes.name }}
          </div>
          <div style="width: 2.6%;" />
          <div
            class="flex flex-row"
            style="width: 16.6%;"
          >
            <div
              v-if="userTypes.internalType === 'static'"
              style="width: 5%;"
              class="align-dots"
            >
              <span class="static-dot" />
            </div>
            <div
              v-else
              style="width: 5%;"
            >
              <span class="dynamic-dot" />
            </div>
            <div style="width: 5%;" />
            <div style="width: 90%;">
              {{ userTypes.internalType === 'static' ? 'Estática' : 'Dinámica' }}
            </div>
          </div>
          <div
            v-if="userTypes.internalType === 'static'"
            style="width: 11.1%;"
          >
            {{ userTypes.numberOfEntries }}
          </div>
          <div
            v-else
            style="width: 11.1%;"
          >
            {{ formatDynamicEntries(userTypes) }}
          </div>
          <div style="width: 22.2%;">
            {{ formatDate(userTypes.createdAt) }}
          </div>
          <div
            style="width: 22.2%; overflow: hidden;"
          >
            {{ `${userTypes.user.firstName} ${userTypes.user.lastName}` }}
          </div>
          <div
            class="text-setting-label text-size-12 flex"
            style="width: 11.1%; margin-right: 0.5rem;"
          >
            <div
              @click="showDatabase(userTypes.id)"
              title="Ver Base de datos"
              class="w-1/3 cursor-pointer text-left"
              style="padding-left: 0.5rem;"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >visibility</i>
            </div>
            <div
              v-if="userTypes.internalType === 'static'"
              title="Ver historial"
              class="w-1/3 cursor-pointer text-center"
              @click="excelUploadsIndex(userTypes.id)"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >history</i>
            </div>
            <div
              title="Eliminar Base de datos"
              class="w-1/3 cursor-pointer text-left"
              @click="removeUserTypes(userTypes.id)"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >delete_outline</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{ hidden: !typesDataActive }"
      class="bg-white min-h-full w-full"
    >
      <div class="px-8 pt-4 pb-1 h-16 flex items-center">
        <div class="w-3/12 text-setting-label text-size-12 self-middle">
          Type Name
        </div>
        <div class="w-6/12 text-setting-label text-size-12 self-middle" />
        <div class="w-5/12 text-center flex justify-middle">
          <button
            v-if="selectedType.name !== undefined"
            @click="openNewEntryModal"
            class="border border-gray-400  rounded-md px-4 py-2 cursor-pointer "
          >
            <i
              class="material-icons material-icons-inline material-icons-active cursor-pointer"
            >add</i>
            <span class="text-icon-secondary">New data entry</span>
          </button>
        </div>
      </div>
      <div class="flex">
        <div class="w-2/12">
          <div
            class="h-20 p-8 w-full flex text-sm text-indigo-600 border-gray-300
          border-b items-center hover:bg-notification"
            :class="{'bg-notification': (selectedType.id === userTypes['id'])}"
            :key="userTypes['id']"
            v-for="userTypes in userDefinedTypes"
            @click="selectTypeForData(userTypes)"
          >
            <div class="w-11/12 pl-6">
              {{ userTypes.name }}
            </div>
            <div
              class="w-1/12"
            />
          </div>
        </div>
        <div class="w-10/12 pl-8">
          <div class="flex">
            <vuetable
              ref="vuetablelatest"
              :css="{
                tableWrapper: 'table-auto w-full',
                tableClass: 'text-table text-sm font-normal',
                tableHeaderClass: '',
                tableBodyClass:`w-full grid-index__grid-table bg-white
                                p-2 text-gray-600 text-sm font-normal rounded-lg`,
              }"
              :api-mode="false"
              :fields="headerTable"
              :data="displayableCustomData"
            >
              <div
                slot="actions"
                slot-scope="props"
                class="flex"
              >
                <button
                  @click="openEditUserDefinedDatum(props.rowData)"
                  class="btn btn--inverted-colors-no-border"
                  title="Edit Entry"
                >
                  <i class="material-icons-outlined material-icons--primary">edit</i>
                </button>

                <button
                  @click="destroyUserDefinedDatum(props.rowData)"
                  class="btn btn--inverted-colors-no-border"
                  title="Remove Entry"
                >
                  <i class="material-icons-outlined material-icons--primary">delete_forever</i>
                </button>
              </div>
            </vuetable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from 'vuetable-2';
import { mapState, mapGetters } from 'vuex';
import humps from 'humps';
import api from '../api';
import {
  OPEN_USER_DEFINED_TYPE_MODAL,
  SET_SELECTED_CUSTOM_COLUMNS,
  SET_SELECTED_CUSTOM_TYPE,
  SET_SELECTED_CUSTOM_DATUM,
  SET_SELECTED_DATA,
  REMOVE_SELECTED_DATA,
  SET_APP_MODAL_TYPE,
} from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';
import InternalDatabasesModal from './internal-databases-modal';

export const DECIMAL_BASE = 10;

export default {
  mixins: [showMessageMixin],
  components: {
    Vuetable,
    InternalDatabasesModal,
  },
  props: {
    userDefinedTypesProp: { type: Array, required: true },

  },
  data() {
    return {
      selectedTab: 'usertypes',
      openDropdown: false,
      fileSignedId: null,
      userTypeName: null,
      userDefinedTypes: this.userDefinedTypesProp.reduce((norObj, currentObj) => {
        norObj[currentObj.id] = humps.camelizeKeys(currentObj);

        return norObj;
      }, {}),
      selectedType: { customAttrs: [] },
      loadingImage: false,
      newColumnName: null,
      newColumnType: null,
      customTypeData: [],
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Bases de datos';
    document.body.classList.add('internal-databases-bg');
  },
  computed: {
    ...mapState({
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    ...mapGetters(['selectedModalOfType']),

    displayableCustomData() {
      return Object.keys(this.selectedCustomData).map(index => this.selectedCustomData[index]);
    },
    headerTable() {
      const header = this.selectedType.customAttrs.map(cc => ({
        name: cc.name,
        sortField: cc.name,
      }));
      header.push({ name: 'updatedAt', title: 'Updated' });
      header.push({ name: 'actions', title: '' });

      return header;
    },
    userTypesActive() {
      return this.selectedTab === 'usertypes';
    },
    typesDataActive() {
      return this.selectedTab === 'typesDataActive';
    },
    customTypeParams() {
      const params = {};
      params.name = this.userTypeName;
      if (this.customColumns) {
        params.customAttrs = this.customColumns;
      }

      return params;
    },
    newInputFilled() {
      return this.newColumnName && this.newColumnType;
    },
  },

  methods: {
    showDatabase(databaseId) {
      console.log('databaseID', databaseId);
      window.location = `/user_types/${databaseId}`;
    },
    destroyUserDefinedDatum(row) {
      api.removeUserDefinedTypeData(this.selectedType.id, row.id)
        .then(() => {
          this.$store.commit(REMOVE_SELECTED_DATA, row.id);
        });
    },
    openEditUserDefinedDatum(row) {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, row);
      this.$store.commit(OPEN_USER_DEFINED_TYPE_MODAL);
    },
    selectType(selectedType) {
      this.selectedType = {
        id: selectedType.id,
        name: selectedType.name,
        numberOfEntries: selectedType.numberOfEntries,
        createdAt: selectedType.createdAt,
        user: selectedType.user,
        customAttrs: selectedType.customAttrs || [],

      };
    },
    selectTypeForData(selectedType) {
      this.selectedType = {
        id: selectedType.id,
        name: selectedType.name,
        customAttrs: selectedType.customAttrs || [],
      };
      this.$store.commit(SET_SELECTED_CUSTOM_COLUMNS, this.selectedType.customAttrs);
      this.$store.commit(SET_SELECTED_CUSTOM_TYPE, this.selectedType);

      api.getUserDefinedTypeData(selectedType.id)
        .then((data) => {
          this.customTypeData = data.userTypes.map(ut => {
            ut.dataRow.createdAt = ut.createdAt;
            ut.dataRow.updatedAt = ut.updatedAt;
            ut.dataRow.id = ut.id;

            return ut.dataRow;
          });
          this.$store.commit(SET_SELECTED_DATA, this.customTypeData);
        });
    },
    selectTab(selectedTab) {
      this.selectedTab = selectedTab;
    },
    createNewType() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'NewInternalDatabase');
    },
    removeUserTypes(id) {
      api.removeUserDefinedType(id)
        .then(response => {
          if (response.status === '200') {
            this.removeSelectedType(id);
            this.selectedType = { name: null, customAttrs: [] };
            this.showMessage(response.message);
          } else {
            this.showMessage(
              'No se puede eliminar la base de datos porque las siguientes vistas la utilizan: '
                .concat(response.message),
            );
          }
        });
    },
    removeSelectedType(id) {
      const userTypesCopy = { ...this.userDefinedTypes };
      delete userTypesCopy[id];
      this.userDefinedTypes = { ...userTypesCopy };
    },
    addColumnToType() {
      if (!this.newInputFilled) return;
      const copyCustomAttrs = [...this.selectedType.customAttrs];
      copyCustomAttrs.push({ name: this.newColumnName.toLowerCase(), type: this.newColumnType });
      api.updateUserDefinedType(this.selectedType.id, {
        userType: { name: this.selectedType.name, customAttrs: copyCustomAttrs },
      })
        .then(() => {
          this.selectedType.customAttrs.push({ name: this.newColumnName.toLowerCase(), type: this.newColumnType });
          this.newColumnName = null;
          this.newColumnType = null;
        });
    },
    removeColumnToType(removeColumnName) {
      const copyCustomAttrs = [...this.selectedType.customAttrs];
      const indexFor = this.selectedType.customAttrs.map(cols => cols.name)
        .indexOf(removeColumnName);
      if (indexFor > -1) {
        copyCustomAttrs.splice(indexFor, 1);
        api.updateUserDefinedType(this.selectedType.id, {
          userType: { name: this.selectedType.name, customAttrs: copyCustomAttrs },
        })
          .then(() => {
            this.selectedType.customAttrs.splice(indexFor, 1);
          });
      }
    },
    updateUserTypes(userType) {
      this.userDefinedTypes = { ...this.userDefinedTypes, [userType.id]: userType };
    },
    openNewEntryModal() {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, {});
      this.$store.commit(OPEN_USER_DEFINED_TYPE_MODAL);
    },
    excelUploadsIndex(userTypeId) {
      window.location = `/excel_uploads/?user_type_id=${userTypeId}`;
    },
    redefineDateChunk(number) {
      if (number < DECIMAL_BASE) {
        return `0${number}`;
      }

      return number;
    },
    formatDate(datetime) {
      const date = new Date(datetime);
      const year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDay();
      month = this.redefineDateChunk(month);
      day = this.redefineDateChunk(day);

      return `${day}-${month}-${year}`;
    },
    formatDynamicEntries(userTypes) {
      if (userTypes.dataSource === null || userTypes.dataSource === undefined) {
        return 0;
      }
      const arrayWithValues = userTypes.dataSource.range.split(':').map(el => el.replace(/^\D+/g, ''));

      return arrayWithValues[1] - arrayWithValues[0];
    },
  },
};
</script>
<style>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

.text-size-18 {
  font-size: 18px;
}

.text-size-12 {
  font-size: 12px;
}

.text-size-13 {
  font-size: 13px;
}

.input-name {
  height: 48px;
  width: 250px;
}

.logo {
  height: 96px;
  width: 96px;
}

.padding-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}

table thead tr th {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #4a5464;
}

table tr td {
  padding-left: 15px;
}

table tr {
  height: 54px;
  text-align: left;
}

thead {
  border-bottom: 1px solid #edebf5;
}

table tbody tr:not(:last-child) {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:first-child {
  border-bottom: 1px solid #edebf5;
  /* border-top: 1px solid #edebf5; */
}

table tbody tr:last-child {
  /* border-bottom: 1px solid #edebf5; */
  border-top: 1px solid #edebf5;
}

.vuetable-slot {
  text-align: center;
}

.vuetable-slot i {
  font-size: 1.2rem;
}

.w-148 {
  width: 148px;
}

.internal-databases-title {
  text-align: left;
  font: normal normal normal 27px/37px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.new-database-button {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
}

.new-database-button-font {
  text-align: left;
  font: normal normal 600 16px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
}

.internal-databases-bg {
  background: #FAFAFA 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

.databases-table-header-font {
  text-align: left;
  font: normal normal bold 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.databases-table-entries-font {
  text-align: left;
  font: normal normal normal 15px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.databases-table-entries-bg {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
}

.static-dot {
  width: 10px;
  height: 10px;
  background: #555859 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.dynamic-dot {
  width: 10px;
  height: 10px;
  background: #4ECA85 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  display: inline-block;
}

.align-dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
