<template>
  <div class="mt-20">
    <div class="grid-index bg-white pl-8 pr-8 rounded-lg flex flex-col pb-8 mb-8">
      <div class="pt-4  pb-4 flex flex-row items-center">
        <div class="text-lg text-gray-700">
          {{ this.pageTitle }}
        </div>
        <!-- <button
          class="btn btn--inverted-colors-no-border"
          @click="changeListMode"
        >
          <i class="material-icons material-icons-inline new-btn__plus">{{ listModeIcon }}</i>
        </button> -->
        <button
          class="btn btn--inverted-colors new-btn"
          @click="openTemplateModal"
          v-if="isAdminUser || isDevUser"
        >
          <i class="material-icons material-icons--blue new-btn__plus">add</i>
          {{ $t('template.newButton') }}
        </button>
      </div>

      <div class="mb-4 relative self-center">
        <input
          class="self-center h-10 bg-gray-100 p2 rounded-lg pl-10 search-bar border border-gray-300 outline-none"
          type="text"
          placeholder="Search"
          v-model="searchTerm"
        >
        <i class="material-icons absolute inset-y-0 pl-2 at-middle">search</i>
      </div>
      <div class="mt-4 mb-4 flex flex-row self-center">
        <div
          @click="selectedFilter = 'name' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'name',
            'text-indigo-500': selectedFilter !== 'name'}"
        >
          Name
        </div>
        <div
          @click="selectedFilter = 'areaList' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'areaList',
            'text-indigo-500': selectedFilter !== 'areaList'}"
        >
          Area
        </div>
        <div
          @click="selectedFilter = 'industryList' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'industryList',
            'text-indigo-500': selectedFilter !== 'industryList'
          }"
        >
          Industry
        </div>
        <div
          @click="selectedFilter = 'processList' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'processList',
            'text-indigo-500': selectedFilter !== 'processList'
          }"
        >
          Process
        </div>

        <div
          @click="selectedFilter = 'revenueType' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'revenueType',
            'text-indigo-500': selectedFilter !== 'revenueType'
          }"
        >
          Revenue Type
        </div>

        <div
          @click="selectedFilter = 'viewingLevelList' "
          class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
          :class="{
            'bg-indigo-600 text-white': selectedFilter === 'viewingLevelList',
            'text-indigo-500': selectedFilter !== 'viewingLevelList'
          }"
        >
          Viewing Level
        </div>
      </div>
      <div
        v-if="listModeIcon === 'view_list'"
        class="grid grid-cols-3 gap-4 items-center"
      >
        <template-list-item
          v-for="template in filteredResources"
          :key="template.id"
          :template="template"
          :is-admin-user="isAdminUser"
          :current-user-id="currentUser.id"
        />
      </div>
      <div
        v-else
        class="grid-index__list--table"
      >
        <vuetable
          ref="templategrid"
          :css="{tableClass:'grid-index__list--table', tableHeaderClass: 'grid-index__list--table-header'}"
          :api-mode="false"
          :fields="viewsHeaderTable"
          :data-manager="dataManager"
        >
          <div
            slot="actionsNonAdmin"
            slot-scope="props"
            v-if="!isAdminUser"
            :style="{textAlign:'center'}"
          >
            <a :href="viewPath(props.rowData)">
              <button
                class="btn btn--inverted-colors-no-border"
              >
                <i class="material-icons material-icons-blue">preview</i>
              </button>
            </a>
          </div>
          <div
            slot="actions"
            slot-scope="props"
            v-if="isAdminUser || isCreator(props.rowData)"
          >
            <a :href="viewPath(props.rowData)">
              <button
                class="btn btn--inverted-colors-no-border"
              >
                <i class="material-icons material-icons-blue">preview</i>
              </button>
            </a>

            <a @click="selectForEdition(props.rowData)">
              <button
                class="btn btn--inverted-colors-no-border"
              >
                <i
                  class="material-icons material-icons-blue"
                >edit</i>
              </button>
            </a>
            <button
              class="btn btn--inverted-colors-no-border"
              @click="onActionClicked('delete-item', props.rowData)"
            >
              <i class="material-icons material-icons-blue">delete_forever</i>
            </button>
          </div>
        </vuetable>
      </div>
      <confirmation-modal v-if="confirmationModalOpened" />
      <template-modal
        v-if="templateModalOpened"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import Vuetable from 'vuetable-2';
import TemplateListItem from './template-list-item';
import TemplateModal from './template-modal';
import ConfirmationModal from './confirmation-modal';
import { SET_TEMPLATES, SELECT_TEMPLATE_FOR_EDITION, SELECT_TEMPLATE_FOR_DELETION } from '../store/action-types';
import {
  OPEN_TEMPLATE_MODAL,
  SET_INDUSTRY_TAGS,
  SET_COMPANY_SIZE_OPTIONS,
  REVENUE_TYPE_OPTIONS,
  PROCESS_OPTIONS,
  VIEWING_LEVEL_OPTIONS,
  AREA_OPTIONS,
} from '../store/mutation-types';

export default {
  components: {
    TemplateListItem,
    TemplateModal,
    ConfirmationModal,
    Vuetable,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    templates: {
      type: Array,
      required: true,
    },
    isAdminUser: {
      type: Boolean,
      default: false,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    industryTags: { type: Array, default: () => [] },
    companySizeOptions: { type: Array, default: () => [] },
    revenueTypeOptions: { type: Array, default: () => [] },
    processOptions: { type: Array, default: () => [] },
    viewingLevelOptions: { type: Array, default: () => [] },
    areaOptions: { type: Array, default: () => [] },
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Plantillas';
    this.$store.dispatch(SET_TEMPLATES, this.templates);
    this.$store.commit(SET_INDUSTRY_TAGS, this.industryTags);
    this.$store.commit(SET_COMPANY_SIZE_OPTIONS, this.companySizeOptions);
    this.$store.commit(REVENUE_TYPE_OPTIONS, this.revenueTypeOptions);
    this.$store.commit(PROCESS_OPTIONS, this.processOptions);
    this.$store.commit(VIEWING_LEVEL_OPTIONS, this.viewingLevelOptions);
    this.$store.commit(AREA_OPTIONS, this.areaOptions);
  },
  data() {
    return {
      listModeIcon: 'view_list',
      viewsHeaderTable: this.getHeader(),
      searchTerm: null,
      resultsOpen: false,
      selectedFilter: 'name',
    };
  },
  computed: {
    ...mapState({
      displayableTemplates: state => state.templates.list,
      templateModalOpened: state => state.ui.templateModalOpened,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
    }),
    isDevUser() {
      return this.currentUser.role === 'dev';
    },

    filteredResources() {
      if (this.searchTerm === null || this.searchTerm === '') return this.displayableTemplates;

      return this.objectFilter(this.displayableTemplates, this.filterFunction);
    },
  },
  watch: {
    displayableTemplates() {
      this.$refs.templategrid.refresh();
    },
  },
  methods: {
    objectFilter(obj, predicate) {
      return Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => {
          res[key] = obj[key];

          return res;
        }, {});
    },
    filterFunction(template) {
      const term = this.selectedFilter === null ? _.get(template, 'name', '') :
        _.get(template, this.selectedFilter, '');
      const self = this;
      if (Array.isArray(term)) {
        return term.map(res => res.toLowerCase())
          .join('|')
          .includes(self.searchTerm.toLowerCase());
      }

      return term.toLowerCase().includes(self.searchTerm.toLowerCase());
    },
    openTemplateModal() {
      this.$store.commit(OPEN_TEMPLATE_MODAL);
    },
    changeListMode() {
      this.getListModeIcon();
    },
    getListModeIcon() {
      if (this.listModeIcon === 'view_list') {
        this.listModeIcon = 'view_module';

        return;
      }
      this.listModeIcon = 'view_list';
    },
    selectForEdition(template) {
      this.$store.dispatch(SELECT_TEMPLATE_FOR_EDITION, template);
    },
    viewPath(view) {
      return `/view_templates/${view.uuid}`;
    },
    getHeader() {
      const header = [{
        name: 'name',
        title: this.$t('view.listTableTitles.name'),
        sortField: 'view.title',
      },
      {
        name: 'view.companyName',
        title: this.$t('view.listTableTitles.tenant'),
        sortField: 'view.companyName',
      },
      {
        name: 'view.updatedAt',
        title: this.$t('view.listTableTitles.updatedAt'),
        sortField: 'view.updatedAt',
      },
      ];
      if (this.isAdminUser || this.isCreator) {
        header.push({ name: 'actions', title: 'Acciones' });
      } else {
        header.push({ name: 'actionsNonAdmin', title: 'Acciones' });
      }

      return header;
    },

    onActionClicked(action, data) {
      if (action === 'delete-item') {
        this.$store.dispatch(SELECT_TEMPLATE_FOR_DELETION, data);
      }
    },
    dataManager(sortOrder) {
      if (Object.keys(this.displayableTemplates).length < 1) return [];
      let local = Object.keys(this.displayableTemplates).map(ele => this.displayableTemplates[ele]);

      if (sortOrder.length > 0) {
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction,
        );
      }

      return local;
    },
    isCreator(template) {
      return template.creatorId === this.currentUser.id;
    },
  },
};
</script>
<style scoped>

.search-bar {
  width: 320px;
}

.min-description {
  min-height: 18px;
}

.at-middle {
  top: 8px;
}
</style>
