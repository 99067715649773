<template>
  <div>
    <app-modal-floating
      :header-text="$t('view.apps.menu.name')"
      header-icon="view_sidebar"
      :app-filled="appInputsReady"
      :app-with-type="appWithType"
      :modal-height="700"
      :show-general-options="false"
      :should-save="this.shouldSave()"
    >
      <!-- <div class="app-modal__input-header">
        Position
      </div> -->
      <div class="flex text-gray-600 pl-5 my-4 hidden">
        <label>
          Left
          <input
            class="mr-2 "
            type="radio"
            id="left"
            value="left"
            v-model="selectedPosition"
          >
        </label>

        <label>
          Right
          <input
            class="mr-2"
            type="radio"
            value="right"
            id="right"
            v-model="selectedPosition"
          >
        </label>

        <label>
          Top
          <input
            class="mr-2"
            value="top"
            id="top"
            type="radio"
            v-model="selectedPosition"
          >
        </label>
      </div>

      <div class="app-modal__input-header">
        {{ $t("view.apps.menu.formName") }}
      </div>
      <div
        class="flex mt-4"
      >
        <input
          class="rounded-md h-40px bg-gray-200 outline-none ml-2 pl-4 pr-2.5 w-4/12 mr-2"
          type="text"
          v-model="selectedLabel"
          placeholder="Nombre item"
        >
        <div
          class="w-4/12 ml-2"
        >
          <multi-select
            v-model="selectedResource"
            :options="resourcePathNames"
            :multiple="false"
            group-values="pathList"
            group-label="resourceType"
            :group-select="true"
            placeholder="Selecciona un recurso"
            track-by="name"
            label="name"
          />
        </div>

        <div class="w-3/12 ml-2">
          <multi-select
            v-model="icon"
            :options="iconList"
            placeholder="Selecciona un icono"
          >
            <template
              slot="option"
              slot-scope="props"
            >
              <i
                class="material-icons material-icons-inline"
                alt="material icon "
              >
                {{ props.option }}
              </i>
              <span class="">{{ props.option }}</span>
            </template>
          </multi-select>
        </div>
        <button
          class="w-1/12 text-center"
          @click="addNewItem"
        >
          <i
            class="material-icons"
          >add</i>
          Item
        </button>
        <div class="share-resource-modal__content" />
      </div>
      <div class="flex flex-col mb-4 overflow-auto max-height-600">
        <draggable
          :list="menuItems"
          :move="checkMove"
          @start="dragging = true"
          @end="dragging = false"
        >
          <div
            class="flex flex-row text-center rounded-lg p-2 m-2 bg-filter"
            :key="index"
            v-for="(item, index) in menuItems"
          >
            <div class="w-4/12">
              {{ item.label }}
            </div>
            <div class="w-4/12">
              {{ item.resourceName }}
            </div>
            <div class="w-2/12">
              <i
                class="material-icons material-icons-inline"
                alt="material icon "
              >
                {{ item.icon }}
              </i>
              <span class="">{{ item.icon }}</span>
            </div>
            <div

              title="Remove Item"
              class="w-2/12 cursor-pointer"
              @click="removeItem(item, index)"
            >
              <i
                class="material-icons material-icons-inline"
                alt="material icon "
              >
                delete_forever
              </i>
            </div>
          </div>
        </draggable>
      </div>
    </app-modal-floating>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';

import MultiSelect from 'vue-multiselect';
import Draggable from 'vuedraggable';
import AppModalFloating from './app-modal-floating';
import iconNames from '../helpers/material-icon-names';
import {
} from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';

export default {
  mixins: [showMessageMixin],
  components: {
    AppModalFloating,
    MultiSelect,
    Draggable,
  },
  data() {
    const container = this.$store.getters.selectedContainer;

    return {
      selectedPosition: _.get(container, 'app.position', 'left'),
      selectedResource: null,
      selectedIcon: null,
      selectedLabel: null,
      icon: null,
      menuItems: _.get(container, 'app.menuItems', []),
      iconList: iconNames.iconsList,
      dragging: false,
      saveRequired: false,
      resourceIdsAlreadyInMenu: _.get(container, 'app.menuItems', []).map(item => item.resourceId),
    };
  },
  computed: {
    ...mapGetters({
      container: 'selectedContainer',
    }),
    ...mapState({
      personalFolders: state => state.views.personalFolders,
      menuWithItems: state => state.views.menuWithItems,
    }),

    resourcePathNames() {
      const personalFolder = this.personalFolders.filter(folder => folder.type.toLowerCase() === 'folder');
      const personalViews = this.personalFolders.filter(folder => folder.type.toLowerCase() === 'view');
      const pathList = [];

      function traversePath(fol, parent) {
        fol.forEach(currentFolder => {
          pathList.push({ name: `${parent}/${currentFolder.name}`, folderId: currentFolder.id });
          traversePath(currentFolder.childs || [], `${parent}/${currentFolder.name}`);
        });
      }
      traversePath(personalFolder, '');

      return [
        {
          resourceType: 'Folders',
          pathList: pathList.map(ele => {
            ele.type = 'folder';

            return ele;
          }),
        },
        {
          resourceType: 'Views',
          pathList: personalViews.map(ele => {
            ele.type = 'view';

            return ele;
          }).filter(el => !this.resourceIdsAlreadyInMenu.includes(el.id)),
        },
        {
          resourceType: 'System',
          pathList: [
            {
              name: 'Users',
              id: '/users',
              type: 'system',
            },

            {
              name: 'Settings',
              id: '/users',
              type: 'system',
            },

            {
              name: 'Profile',
              id: '/account-config',
              type: 'system',
            },
            {
              name: 'Favorites',
              id: '/favorites',
              type: 'system',
            },
            {
              name: 'Databases',
              id: '/user_types',
              type: 'system',
            },

            {
              name: 'Connections',
              id: '/connections',
              type: 'system',
            },

            {
              name: 'Templates',
              id: '/view_templates',
              type: 'system',
            },

            {
              name: 'Apps',
              id: '/workspace_apps',
              type: 'system',
            },
            {
              name: 'My Unit',
              id: '/my_unit',
              type: 'system',
            },
          ],
        },
      ];

      // return pathList;
    },
    appWithType() {
      const container = {
        app: {
          position: this.selectedPosition,
          menuItems: this.menuItems,
        },
        appType: 'MenuApp',
      };

      return container;
    },
    appInputsReady() {
      return this.menuItems.length > 0;
    },
  },
  methods: {
    shouldSave() {
      if (!this.menuWithItems) {
        return true;
      }
      if (this.menuItems.length !== this.menuWithItems.length) {
        return true;
      }

      for (let i = 0; i < this.menuItems.length; i++) {
        const item = this.menuItems[i];
        const withItem = this.menuWithItems[i];

        for (const prop of Object.keys(item)) {
          if (item[prop] !== withItem[prop]) {
            return false;
          }
        }
      }

      return true;
    },
    checkMove(event) {
      console.log(this.menuItems);
      console.log(event.draggedContext.futureIndex);
    },
    addNewItem() {
      if (this.icon === null || this.selectedResource === null ||
        [null, ''].includes(this.selectedLabel)) {
        this.showMessage(
          'Debes agregar nombre de item, seleccionar un recurso y seleccionar un icono para agregar un item!');

        return;
      }
      this.menuItems.push({
        label: this.selectedLabel,
        resourceName: this.selectedResource.name,
        resourceId: this.selectedResource.uuid || this.selectedResource.folderId,
        type: this.selectedResource.type,
        icon: this.icon,
      });
      this.showMessage('Item agregado, recuerda guardar para prevalecer los cambios');
      this.cleanItemForm();
    },
    removeItem(item, index) {
      this.menuItems.splice(index, 1);
    },
    cleanItemForm() {
      this.selectedLabel = null;
      this.selectedResource = null;
      this.selectedResource = null;
      this.icon = null;
    },
    onIconSelected(icon) {
      this.icon = icon;
    },
  },
};
</script>
<style scoped>

.app-modal__header {
  align-items: center;
  grid-area: header;
  height: auto;
  display: flex;
  font-size: 18px;
  color: #474747;
  letter-spacing: .6px;
  margin-bottom: 25px;
  padding-left: 16px;
}

.app-modal__input-header {
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 10px;
  padding-left: 21px;
}

.h-40px {
  height: 40px;

}

.max-height-200 {
  max-height: 200px;
}

</style>
