import { mapGetters } from 'vuex';
import api from '../api';
import {
  UNDO_CACHE,
  REDO_CACHE,
} from '../store/action-types';
import {
  SET_SELECTED_CONTAINER_ID,
  REMOVE_CONTAINER,
} from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';

export default {
  mixins: [
    showMessageMixin,
  ],
  data() {
    return {
      metaLeftPressed: false,
      zWithShiftKey: false,
    };
  },
  created() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  computed: {
    ...mapGetters(['selectedView']),
  },
  methods: {
    keyupHandler(event) {
      this.macImplementation(event);
      if (event.key === 'z' && event.ctrlKey === true) {
        this.undoHandler();
      } else if (event.key === 'Z' && event.ctrlKey === true) {
        this.redoHandler();
      }
    },
    macImplementation(event) {
      if (event.key === 'Meta' && event.code === 'MetaLeft') {
        this.metaLeftPressed = true;
      }
      if (event.key === 'Z') {
        this.zWithShiftKey = true;
      }
      if (event.key === 'z' && this.metaLeftPressed) {
        this.undoHandler();
        this.metaLeftPressed = false;
      } else if (this.zWithShiftKey && this.metaLeftPressed && event.key === 'Shift') {
        this.redoHandler();
        this.metaLeftPressed = false;
        this.zWithShiftKey = false;
      }
    },
    containerDestroyed(response) {
      this.showMessage(`Se han deshecho cambios y se ha eliminado la app ${response.title}`);
      this.$store.commit(SET_SELECTED_CONTAINER_ID, response.id);
      this.$store.commit(REMOVE_CONTAINER);
    },
    containerCreated(response) {
      const updatedContainer = response.container;
      this.$store.dispatch(REDO_CACHE, { container: updatedContainer });
      this.showMessage(`Se han rehecho cambios y se ha recreado la app ${response.title}`);
    },
    containerUndo(response) {
      const updatedContainer = response;
      this.$store.dispatch(UNDO_CACHE, { container: updatedContainer });
      const el = document.getElementById(updatedContainer.id);
      this.grid.update(el,
        {
          w: updatedContainer.position.w, h: updatedContainer.position.h,
          x: updatedContainer.position.x, y: updatedContainer.position.y,
        },
      );
      this.showMessage(`Se han desecho cambios en la app con título ${updatedContainer.title}`);
    },
    containerRedo(response) {
      const updatedContainer = response;
      console.log('response.container', response);
      this.$store.dispatch(UNDO_CACHE, { container: updatedContainer });
      const el = document.getElementById(updatedContainer.id);
      this.grid.update(el,
        {
          w: updatedContainer.position.w, h: updatedContainer.position.h,
          x: updatedContainer.position.x, y: updatedContainer.position.y,
        },
      );
      this.showMessage(`Se han rehecho cambios en la app con título ${updatedContainer.title}`);
    },
    async undoHandler() {
      const response = await api.getUndoViewCache(this.selectedView.id);
      if (response) {
        if ('destroyed' in response) {
          this.containerDestroyed(response);
        } else {
          this.containerUndo(response);
        }
      } else {
        this.showMessage('Ya se han deshecho todos los cambios guardados!');
      }
    },
    async redoHandler() {
      const response = await api.getRedoViewCache(this.selectedView.id);
      if (response) {
        if ('created' in response) {
          this.containerCreated(response);
        } else {
          this.containerRedo(response);
        }
      } else {
        this.showMessage('Ya se han rehecho todos los cambios guardados!');
      }
    },
  },
};
