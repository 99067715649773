<template>
  <div>
    <modal
      name="bigstore"
      :width="915"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <bigstore-app-template-list
        :custom-app-templates-prop="customAppTemplates"
      />
    </modal>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import api from '../api';
import { CLOSE_BIGSTORE_MODAL, SAVE_USER } from '../store/action-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import BigstoreAppTemplateList from './bigstore-app-template-list';

export default {
  mixins: [escapeMixinHandler],
  components: {
    BigstoreAppTemplateList,
  },
  data() {
    return {
      customAppTemplates: [],
    };
  },
  mounted() {
    api.getCustomAppTemplates({ disabled: false }).then((response) => {
      this.customAppTemplates = response.customAppTemplates;
      this.$modal.show('bigstore');
    });
  },
  computed: {
    ...mapGetters({
      user: 'selectedUser',
    }),
    ...mapState({
      // tenantTags: state => state.users.tenantTags,
      // customAppTemplatesVisibilityOptions: state => state.views.customAppTemplatesVisibilityOptions,
      // customAppTemplates: state => state.views.customAppTemplates,
    }),
    filteredAutocomplete() {
      const self = this;
      const filteredResults = this.tenantTags.filter((t) => (
        t.toLowerCase().indexOf(self.tag.toLowerCase()) !== -1)).map(tag => ({ text: tag }));
      this.setReadOnlyMode(filteredResults);

      return filteredResults;
    },
    userParams() {
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        role: this.role,
        tagList: this.selectedTags.map(tag => tag.text),
      };
      if (this.fileSignedId) params.profilePicture = this.fileSignedId;

      return params;
    },
    requiredInputsFilled() {
      return !!(this.firstName && this.lastName && this.email && this.role);
    },
  },
  methods: {
    setReadOnlyMode(filteredResults) {
      if (filteredResults.length === 0) {
        this.addedFromTagList = true;
      } else {
        this.addedFromTagList = false;
      }
    },
    close() {
      this.$store.dispatch(CLOSE_BIGSTORE_MODAL);
    },
    save() {
      this.$store.dispatch(SAVE_USER, this.userParams).then(() => this.close());
    },
    handleImageUploaded({ url, fileSignedId }) {
      this.profilePictureUrl = url;
      this.fileSignedId = fileSignedId;
    },
    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },
  },
};
</script>
