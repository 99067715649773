import * as mutations from '../../mutation-types';

export default {
  [mutations.ADD_NORMALIZED_CONNECTION](state, connection) {
    state.list = { ...state.list, ...connection };
  },
  [mutations.ADD_CONNECTION](state, connection) {
    state.list = { ...state.list, [connection.id]: connection };
  },
  [mutations.SET_SELECTED_CONNECTION_ID](state, id) {
    state.selectedId = id;
  },
  [mutations.REMOVE_CONNECTION](state) {
    const connectionsCopy = { ...state.list };
    delete connectionsCopy[state.selectedId];
    state.list = { ...connectionsCopy };
  },
  [mutations.UPDATE_SELECTED_CONNECTION](state, connection) {
    state.list = { ...state.list, [state.selectedId]: connection };
  },
};
