<template>
  <div class="grid-internal-databases-show p-8">
    <div
      class="back-button"
      style="cursor: pointer;"
      @click="goBack()"
    >
      <div class="flex flex-row">
        <i
          class="material-icons back-text-position"
          style="width: 30%; color: #FFFFFF; padding-left: 13px; font-size: 1.3em;"
        >arrow_back</i>
        <div
          class="back-font back-text-position"
          style="width: 70%; padding-left: 10px;"
        >
          Volver
        </div>
      </div>
    </div>
    <div
      class="flex flex-row"
      style="margin-left: 30px; margin-bottom: 28.37px;"
    >
      <div
        class="database-title-font"
        style="width: 66.5%;"
      >
        Base de datos
      </div>
      <div
        class="edit-button"
        @click="editDatabase()"
        style="margin-right: 18px; width: 19.5%; cursor: pointer;"
      >
        <div class="flex flex-row">
          <i
            class="material-icons edit-text-position"
            style="width: 20%; color: #FFFFFF; padding-left: 12%; font-size: 1.3em;"
          >edit</i>
          <div
            class="back-font edit-text-position"
            style="width: 80%; padding-left: 6%;"
          >
            Editar Base de datos
          </div>
        </div>
      </div>
      <div style="width: 1%;" />
      <div
        class="share-button create-disabled"
        @click="shareDatabase()"
        style="width: 13%;"
      >
        <div class="flex flex-row">
          <i
            class="material-icons edit-text-position"
            style="width: 30%; color: #FFFFFF; padding-left: 16%; font-size: 1.3em;"
          >share</i>
          <div
            class="back-font edit-text-position"
            style="width: 70%; padding-left: 5%;"
          >
            Compartir
          </div>
        </div>
      </div>
    </div>
    <div
      class="internal-database-table-bg flex flex-col"
      style="padding-left: 12.63px; width: 100%; height: auto;"
    >
      <div
        class="flex flex-row"
        style="margin-top: 19px;"
      >
        <div
          class="internal-database-name-and-description-box flex flex-col"
          style="margin-left: 13px; padding-left: 18px; width: 65%;"
        >
          <div class="internal-database-name-font">
            {{ name }}
          </div>
          <div
            class="internal-database-description-font"
            style="transform: translate(0, -10px);"
          >
            {{ description }}
          </div>
        </div>
        <div style="width: 2%;" />
        <div
          class="massive-import-button"
          @click="massiveImport()"
          style="margin-right: 18px; width: 13%; cursor: pointer; margin-top: 8px;"
        >
          <div class="flex flex-row">
            <i
              class="material-icons add-entry-text-position"
              style="width: 30%; color: #0031FF; padding-left: 13%; font-size: 1.3em;"
            >upload_file</i>
            <div
              class="massive-import-font add-entry-text-position"
              style="width: 70%; padding-left: 1%;"
            >
              Carga masiva
            </div>
          </div>
        </div>
        <div style="width: 1%;" />
        <div
          v-if="internalType === 'static'"
          class="add-entry-button"
          @click="addRow()"
          style="width: 17%; cursor: pointer; margin-top: 8px;"
        >
          <div class="flex flex-row">
            <i
              class="material-icons add-entry-text-position"
              style="width: 30%; color: #0031FF; padding-left: 17%; font-size: 1.3em;"
            >add</i>
            <div
              class="add-entry-font add-entry-text-position"
              style="width: 70%;"
            >
              Agregar entrada
            </div>
          </div>
        </div>
        <div
          v-else
          style="width: 17%; margin-top: 8px;"
        />
        <div style="width: 2%;" />
      </div>
      <div
        class="flex flex-row internal-database-table-bg"
        style="width: 100%; margin-top: 21px; height: 38px; position: sticky; top: 0;"
        :key="rowsKey2"
      >
        <div
          v-for="(customAttr, index) in customAttrs"
          :key="index"
          :style="defineWidth()"
        >
          <div
            class="header-font"
            style="text-align: center;"
          >
            {{ customAttr.columnName }}
          </div>
        </div>
        <div
          class="header-font"
          :style="defineWidth()"
          style="text-align: center;"
        >
          Acciones
        </div>
      </div>
      <div
        class="header-border mb-4"
        style="height: 1px; margin-right: 12.63px;"
        :style="`height:  ${customAttrs.length >= 9 ? '10' : '1'}px;`"
      />
      <div
        v-if="badHeadersInDataSource && selectedCustomData.length === 0"
        class="w-full text-center bad-header-message-font databases-table-entries-bg mb-4 items-center"
        style="height: 59.73px;"
      >
        <div
          class="flex flex-col"
          style="padding-top: 0.4rem;"
        >
          <span>
            El header en el archivo dinámico asociado no coincide con la base de datos
          </span>
          <span class="mt-1">
            Por favor, comprueba que tu archivo tenga el formato especificado en el archivo de ejemplo y
            vuelve a cargarlo
          </span>
        </div>
      </div>
      <div
        class="flex flex-col"
        :key="rowsKey"
      >
        <div
          :key="index"
          v-for="(row, index) in selectedCustomData"
          class="flex databases-table-entries-font databases-table-entries-bg mb-4"
          style="height: 59.73px; align-items: center; margin-right: 12.63px;"
        >
          <div
            :key="index2"
            v-for="(dataInRow, index2) in row.dataRow"
            class=""
            :style="defineWidth()"
          >
            <div
              v-if="dataInRow.value === null"
              style="text-align: center; margin-left: 17.57px;"
              title="Nulo"
            >
              Nulo
            </div>
            <div
              v-else-if="isInt(dataInRow.value) || isFloat(dataInRow.value)"
              style="text-align: center; margin-left: 17.57px;"
              class="long-text-treatment"
              :title="dataInRow.value"
            >
              {{ dataInRow.value }}
            </div>
            <div
              v-else-if="dataInRow.value.includes('/rails/active_storage')"
              style="text-align: center; margin-left: 17.57px;"
              class="long-text-treatment"
              :title="dataInRow.value"
            >
              <a
                :href="dataInRow.value"
                target="_blank"
                class="cursor-pointer"
              >
                <div
                  class="fileText underline-file long-text-treatment"
                  v-html="dataInRow.filename"
                  :title="dataInRow.filename"
                />
              </a>
            </div>
            <div
              v-else-if="dataInRow.value.includes('http')"
              style="text-align: center; margin-left: 17.57px;"
              class="long-text-treatment"
              :title="dataInRow.value"
            >
              <a
                :href="dataInRow.value"
                target="_blank"
                class="cursor-pointer"
              >
                <div
                  class="fileText underline-file long-text-treatment"
                  v-html="dataInRow.value"
                  :title="dataInRow.value"
                />
              </a>
            </div>
            <div
              v-else
              style="text-align: center; margin-left: 17.57px;"
              class="long-text-treatment"
              :title="dataInRow.value"
            >
              {{ dataInRow.value }}
            </div>
          </div>
          <div
            class="text-setting-label text-size-12 flex"
            :style="defineWidth()"
          >
            <div
              v-if="internalType === 'static'"
              @click="editRow(row)"
              title="Editar fila"
              class="w-1/2 cursor-pointer text-center ml-10"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >edit</i>
            </div>
            <div
              v-else
              title="Editar fila no disponible en carga dinámica"
              class="w-1/2 cursor-pointer text-center ml-10"
              style="cursor: not-allowed;"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >edit</i>
            </div>
            <div
              v-if="internalType === 'static'"
              title="Eliminar fila"
              class="w-1/2 cursor-pointer text-center mr-10"
              @click="removeRow(row)"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >delete_outline</i>
            </div>
            <div
              v-else
              title="Eliminar fila no disponible en carga dinámica"
              class="w-1/2 cursor-pointer text-center mr-10"
              style="cursor: not-allowed;"
            >
              <i
                class="material-icons"
                style="color: #0031FF;"
              >delete_outline</i>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="internalType === 'static'"
        class="add-entry-in-table"
        @click="addRow()"
        style="width: 95.7%; cursor: pointer; margin-top: 2rem; margin-left: 31.66px; margin-bottom: 3rem;"
      >
        <div
          class="flex flex-row"
          style="justify-content: center; align-items: center;"
        >
          <i
            class="material-icons add-entry-in-table-text-position"
            style="color: #0031FF; font-size: 1.3em; text-align: center; margin-right: 7.5px;"
          >add</i>
          <div
            class="add-entry-font add-entry-in-table-text-position"
            style="text-align: center;"
          >
            Agregar entrada
          </div>
        </div>
      </div>
    </div>
    <databases-edit-modal
      v-if="selectedModalOfType('EditInternalDatabase')"
      @myNewListener="updateDatabase"
      :listener="'myNewListener'"
      :internal-database-data="internalDatabaseData"
    />
    <add-row-modal
      v-if="selectedModalOfType('AddRowModal')"
      @addRowDatabasesListener="addUserTypeRow"
      :listener="'addRowDatabasesListener'"
      :custom-attrs="customAttrs"
    />
    <static-import-modal
      v-if="selectedModalOfType('StaticImportModal')"
      @addRowDatabasesListener="addUserTypeRow"
      :listener="'addRowDatabasesListener'"
      :user-defined-type-prop="userDefinedTypeProp"
      :custom-attrs="customAttrs"
    />
    <dynamic-import-modal
      v-if="selectedModalOfType('DynamicImportModal')"
      @addRowDatabasesListener="addUserTypeRow"
      :listener="'addRowDatabasesListener'"
      :user-defined-type-prop="userDefinedTypeProp"
      :custom-attrs="customAttrs"
      :connections="connections"
    />
  </div>
</template>

<script>

import humps from 'humps';
import { mapGetters, mapState } from 'vuex';
import {
  SET_APP_MODAL_TYPE,
  SET_SELECTED_CUSTOM_TYPE,
  SET_SELECTED_DATA,
  REMOVE_SELECTED_DATA,
  SET_SELECTED_CUSTOM_DATUM,
} from '../store/mutation-types';
import api from '../api';
import DatabasesEditModal from './databases-edit-modal';
import AddRowModal from './add-row-modal';
import StaticImportModal from './static-databases-import-modal.vue';
import DynamicImportModal from './dynamic-databases-import-modal.vue';

export const TOTAL_WIDTH_PERCENTAGE = 100;

export default {
  props: {
    userDefinedTypeProp: { type: Object, required: true },
    userDataProp: { type: Array, required: true },
    connections: { type: Array, default: () => [] },
  },
  components: {
    DatabasesEditModal,
    AddRowModal,
    StaticImportModal,
    DynamicImportModal,
  },
  data() {
    return {
      customAttrs: humps.camelizeKeys(this.userDefinedTypeProp.customAttrs),
      name: this.userDefinedTypeProp.name,
      description: this.userDefinedTypeProp.description,
      internalType: this.userDefinedTypeProp.internalType,
      internalDatabaseRows: this.userDataProp,
      rowsKey2: `header-${this.userDataProp.length}`,
      orderedSelectedCustomData: [],
      badHeadersInDataSource: null,
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + this.name;
    document.body.classList.add('internal-databases-bg');
    this.$store.commit(SET_SELECTED_CUSTOM_TYPE, this.userDefinedTypeProp);
    const orderedRows = this.orderRowsInFunctionOfHeader(this.internalDatabaseRows);
    this.$store.commit(SET_SELECTED_DATA, orderedRows);
    this.extractDataFromDataSource(this.userDefinedTypeProp.dataSource);
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    internalDatabaseData() {
      return {
        name: this.name,
        internalType: this.internalType,
        description: this.description,
        customAttrs: this.customAttrs,
      };
    },
    rowsKey() {
      if (this.userDefinedTypeProp.internalType === 'static') {
        return this.userDataProp.length;
      }
      if (this.selectedCustomData) {
        return this.selectedCustomData.length;
      }

      return 0;
    },
  },
  methods: {
    orderedWithCustomAttr(customAttrs) {
      const newUserDataProp = [];
      this.userDataProp.forEach((userProp) => {
        const newDataRow = [];
        const dataRow = userProp.dataRow;
        customAttrs.forEach((customAttr) => {
          const orderElement = dataRow.find(object => object.name === customAttr.columnName);
          newDataRow.push(orderElement);
        });
        newUserDataProp.push(newDataRow);
      });

      return newUserDataProp;
    },
    goBack() {
      window.location = '/user_types';
    },
    editDatabase() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'EditInternalDatabase');
    },
    shareDatabase() {
      console.log('coming soon');
    },
    defineWidth() {
      return `width: ${TOTAL_WIDTH_PERCENTAGE / (this.customAttrs.length + 1)}%;`;
    },
    updateDatabase(newDatabase) {
      api.updateUserDefinedType(this.userDefinedTypeProp.id, {
        userType: newDatabase,
      })
        .then((result) => {
          console.log('result', result);
          this.customAttrs = newDatabase.customAttrs;
          this.name = newDatabase.name;
          this.internalType = newDatabase.internalType;
          this.description = newDatabase.description;
          const orderedNewRows = this.orderRowsInFunctionOfHeader(result.userTypeDatum);
          this.$store.commit(SET_SELECTED_DATA, orderedNewRows);
        });
    },
    addRow() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'AddRowModal');
    },
    addUserTypeRow(dataRow) {
      console.log('dataRows', dataRow);
      console.log('selectedCustomData', this.selectedCustomData);
    },
    editRow(row) {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, row);
      this.$store.commit(SET_APP_MODAL_TYPE, 'AddRowModal');
    },
    removeRow(row) {
      api.removeUserDefinedTypeData(this.userDefinedTypeProp.id, row.id)
        .then(() => {
          const indexToRemove = this.selectedCustomData.findIndex(v => v.id === row.id);
          this.$store.commit(REMOVE_SELECTED_DATA, indexToRemove);
        });
    },
    orderRowsInFunctionOfHeader(rows) {
      const orderedRows = [];
      rows.forEach((row) => {
        const unorderedDataRow = row.dataRow;
        const orderedDataRow = [];
        this.customAttrs.forEach((customAttr) => {
          const columnName = customAttr.columnName;
          const element = unorderedDataRow.find(data => data.name === columnName);
          orderedDataRow.push(element);
        });
        const newDataRow = { id: row.id, dataRow: orderedDataRow };
        orderedRows.push(newDataRow);
      });

      return orderedRows;
    },
    massiveImport() {
      if (this.internalType === 'static') {
        this.$store.commit(SET_APP_MODAL_TYPE, 'StaticImportModal');
      } else {
        this.$store.commit(SET_APP_MODAL_TYPE, 'DynamicImportModal');
      }
    },
    isInt(n) {
      return Number(n) === n && n % 1 === 0;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },
    async extractDataFromDataSource(dataSource) {
      if (!dataSource) {
        return null;
      }
      const response = await api.getDataSourceValues(dataSource.id);
      const dataSourceValues = response.values;
      const header = dataSourceValues.shift();
      const difference = this.customAttrs.map(customAttr => customAttr.columnName).filter(x => !header.includes(x));
      if (difference.length === 0) {
        this.updateSelectedDataWithDataSourceValues(header, dataSourceValues);
      } else {
        this.badHeadersInDataSource = true;
      }

      return dataSourceValues;
    },
    updateSelectedDataWithDataSourceValues(header, dataSourceValues) {
      const orderedData = this.orderDataSourceValuesInFunctionOfHeader(header, dataSourceValues);
      this.$store.commit(SET_SELECTED_DATA, orderedData);
    },
    orderDataSourceValuesInFunctionOfHeader(header, dataSourceValues) {
      const orderedRows = [];
      dataSourceValues.forEach((row, index) => {
        const orderedRow = [];
        this.customAttrs.forEach((customAttr) => {
          const columnName = customAttr.columnName;
          const indexInDataSourceRow = header.indexOf(columnName);
          const newRow = { name: columnName, value: row[indexInDataSourceRow] };
          orderedRow.push(newRow);
        });
        const newDataRow = { id: index, dataRow: orderedRow };
        orderedRows.push(newDataRow);
      });

      return orderedRows;
    },
  },
};

</script>

<style scoped>

.grid-internal-databases-show {
  max-width: 1133;
  margin: auto;
  margin-left: 56px;
}

.database-title-font {
  text-align: left;
  font: normal normal 300 27px/37px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.back-button {
  height: 31.63px;
  width: 107.25px;
  background: #0239F5 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  margin-bottom: 60px;
}

.back-font {
  text-align: left;
  font: normal normal 600 14px/17px Inter;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  opacity: 1;
}

.back-text-position {
  vertical-align: middle;
  line-height: 31.63px;
}

.edit-button {
  height: 35px;
  background: #0239F5 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.edit-text-position {
  vertical-align: middle;
  line-height: 35px;
}

.share-button {
  width: 142px;
  height: 35px;
  background: #4ECA85 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.internal-database-name-and-description-box {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.internal-database-name-font {
  text-align: left;
  font: normal normal bold 24px/37px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.internal-database-description-font {
  text-align: left;
  font: normal normal 300 14px/37px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.internal-database-table-bg {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.massive-import-button {
  height: 38.92px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0239F5;
  border-radius: 6px;
  opacity: 1;
}

.massive-import-font {
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  line-height: 38.92px;
}

.add-entry-button {
  height: 38.92px;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
}

.add-entry-font {
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  line-height: 38.92px;
}

.add-entry-text-position {
  vertical-align: middle;
  line-height: 38.92px;
}

.header-font {
  text-align: left;
  font: normal normal bold 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  text-transform: capitalize;
}

.header-border {
  border-bottom: 1px solid black;
  margin-right: 25.91px;
}

.add-entry-in-table {
  height: 24.31px;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
}

.add-entry-in-table-text-position {
  vertical-align: middle;
  line-height: 24.31px;
}
.databases-table-entries-font {
  text-align: left;
  font: normal normal normal 15px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.databases-table-entries-bg {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
}

.underline-file {
  text-decoration: underline;
  color: #0031FF;
}

.long-text-treatment {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bad-header-message-font {
  font: normal normal normal 15px/22px Inter;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

</style>
