<template>
  <sidebar-menu
    :menu="menu"
    width="280px"
    :show-child="true"
    :collapsed="isCollapsed"
    @toggle-collapse="onToggleCollapse"
    width-collapsed="45px"
    @item-click="onItemClick"
  >
    <div slot="toggle-icon">
      <i class="material-icons material-icons--white">menu_open</i>
    </div>
    <div slot="dropdown-icon">
      <i class="material-icons material-icons--white">chevron_right</i>
    </div>
  </sidebar-menu>
</template>
<script>
import { mapState } from 'vuex';
import cookiesMixin from '../mixins/cookiesMixin';

const FOLDER_ICON = {
  element: 'i',
  class: 'material-icons material-icons-white',
  text: 'folder',

};

const FOLDER_ICON_SHARE = {
  element: 'i',
  class: 'material-icons material-icons-white',
  text: 'folder_shared',

};
const COOKIES_EXP = 365;
const MIN_WIDTH = 1600;

export default {
  mixins: [cookiesMixin],
  methods: {
    onToggleCollapse(collapsed) {
      this.createCookie('sideVarStatus', collapsed.toString(), COOKIES_EXP);
      this.isCollapsed = collapsed;
    },
    onItemClick(event, item) {
      const targetPath = item.href;
      const currentPath = window.location.pathname;
      if (targetPath === currentPath) {
        event.preventDefault();
        event.returnValue = '';

        return;
      }
    },
    getPersonalFolderTree(parentFolder, folderIcon) {
      return parentFolder.map(folder => {
        const item = {
          title: folder.name === 'General' && folderIcon.text === 'folder_shared' ? 'Compartidas Conmigo' : folder.name,
          href: `/folders/${folder.id}`,
          icon: folderIcon,
          hiddenOnCollapse: false,
          exactPath: true,
          child: this.getPersonalFolderTree(folder.childs || []),
        };
        if (window.location.pathname === item.href) {
          item.class = 'vsm--link_level-1';
        }

        return item;
      });
    },
    onResize() {
      if (window.outerWidth <= MIN_WIDTH) {
        this.isCollapsed = true;
      }
    },
  },
  computed: {
    ...mapState({
      personalFolders: state => state.views.personalFolders,
    }),
    menu() {
      return [
        {
          header: true,
          title: 'Vistas',
          hiddenOnCollapse: true,
        },
        {
          href: '/my_unit',
          title: 'Mi Unidad',
          icon: FOLDER_ICON,
          child: this.getPersonalFolderTree(this.personalFolders.personal[0] ?
            this.personalFolders.personal[0].childs : [], FOLDER_ICON),
        },
        {
          href: '/shared_unit',
          title: 'Unidades Compartidas',
          icon: FOLDER_ICON_SHARE,
          child: this.getPersonalFolderTree(this.personalFolders.shared, FOLDER_ICON_SHARE),
        },
      ];
    },

  },
  data() {
    return {
      isCollapsed: this.readCookie('sideVarStatus') === 'true',
    };
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
};
</script>
<style scoped>
.v-sidebar-menu {
  background: #000;
}
</style>

