<template>
  <html-renderer
    :code="codeWithFields"
    :container="container"
    :app-icons="appIcons"
  >
    <div
      v-if="dataSource && dataSource.service ==='gsheet' && showDataSource"
    >
      <a
        :href="googleSheetUrl"
        target="_blank"
        class="cursor-pointer custom-app-renderer__link"
        :class="{
          'custom-app-renderer__link_table': container.appType === 'CustomApp' &&
            container.app.customAppTemplate.name === 'Table',
          'custom-app-renderer__link_deck': container.appType === 'CustomApp' &&
            container.app.customAppTemplate.name === 'Deck',
        }"
      >
        fuente
      </a>
    </div>
  </html-renderer>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import HtmlRenderer from './utils/html-renderer';
import {
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import { APP_ICONS } from '../constants';

const SUBSTITUTION_CHAR_COUNT = 2;
const SUBSTITUTION_REGEX = /({{.+}})+/g;
const CURRENT_USER_VAR_NAME = 'currentUser';
// let MINISDKTEMPLATE = '<script>';
const HEADER = `const headers = {
  'X-CSRF-Token': window.parent.document.querySelector('meta[name="csrf-token"]') &&
    window.parent.document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'credentials': 'same-origin',
  };`;

const MAINSDKMETHODS = `const BPSdk = { 
  dataSource: {
    getData: function(){
      return new Promise((resolve, reject) => {
        fetch(endpoint, {
          method: 'GET',
          headers,
        }).then(response => response.json())
        .then(result => resolve(result))
        .catch(err => reject(err));
  })
}}};`;

export default {
  components: {
    HtmlRenderer,
  },
  props: {
    fields: { type: Object, required: true },
    // view_variables: { type: Object, required: true },
    view_variables: { type: Object, default: () => {} },
    code: { type: String, required: true },
    dataSource: { type: Object, default: null },
    container: { type: Object, default: null },
  },
  data() {
    this.defineAppAttrs();

    return {
      appIcons: APP_ICONS,
    };
  },
  methods: {
    getSdkEnpoint(dataSourceId) {
      return `let endpoint = "/api/v1/data_sources/${dataSourceId}/values?${this.mergeUserEmail ? `merge_user_email=${this.mergeUserEmail}` : ''}";`;
    },
    defineAppAttrs() {
      const params = {
        dataSource: _.get(this.container, 'app.dataSource', null),
        showDataSource: _.get(this.container, 'showDataSource', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
  },
  computed: {
    ...mapGetters(['customAppCurrentUserFields']),
    ...mapState({
      mergeUserEmail: state => state.views.mergeUserEmail,
      appAttrs: state => state.views.appsAttrs,
    }),
    getBpSdk() {
      if (this.dataSource === null) return '';
      let sdk = '<script>';
      sdk += HEADER;
      sdk += this.getSdkEnpoint(this.dataSource.id);
      sdk += MAINSDKMETHODS;
      sdk += '<\/script>';

      return sdk;
    },
    fieldsCode() {
      let code = '';
      if (_.isEmpty(this.view_variables)) {
        code = Object.entries(this.fields).reduce(
          (accCode, [name, value]) => (value ? `${accCode}var ${name} = ${JSON.stringify(value)};` : accCode),
          '<script>',
        );
      } else {
        // It has view_variables assigned
        code = Object.entries(this.view_variables).reduce(
          (accCode, [name, value]) => {
            const refValue = _.get(this.view_variables, name, this.fields[name]);

            return value ? `${accCode}var ${name} = ${JSON.stringify(value)};` : accCode;
          }, '<script>');
      }
      code += `var ${CURRENT_USER_VAR_NAME} = ${JSON.stringify(this.customAppCurrentUserFields)};`;
      code += '<\/script>'; // eslint-disable-line no-useless-escape

      return code;
    },
    substitutedCode() {
      return this.code.replace(SUBSTITUTION_REGEX, (match) => {
        const cleanMatch = match.substring(SUBSTITUTION_CHAR_COUNT, match.length - SUBSTITUTION_CHAR_COUNT).trim();
        if (cleanMatch.startsWith(CURRENT_USER_VAR_NAME)) {
          return `${_.get(this.customAppCurrentUserFields, cleanMatch.substring(CURRENT_USER_VAR_NAME.length + 1))}`;
        }

        return `${this.fields[cleanMatch]}`;
      });
    },
    codeWithFields() {
      // return `${MINISDKTEMPLATE}\n${this.fieldsCode}\n${this.substitutedCode}`;
      return `${this.getBpSdk}\n${this.fieldsCode}\n${this.substitutedCode}`;
    },
    googleSheetUrl() {
      return `https://docs.google.com/spreadsheets/d/${this.dataSource.sourceId}`;
    },
    showDataSource() {
      return this.appAttrs[this.container.id].showDataSource;
    },
  },
};
</script>

<style>

.custom-app-renderer__link {
  text-align: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  position: absolute;
  top: -10px;
  right: 0;
}

.custom-app-renderer__link_table {
  top: 3px;
  margin-right: 12px;
}

.custom-app-renderer__link_deck {
  top: 3px;
  margin-right: 28px;
}
</style>
