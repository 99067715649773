<template>
  <div>
    <modal
      name="data-sources"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal share-resource-modal']"
    >
      <div
        class="share-resource-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div
        :style="{marginBottom:'0px'}"
        class="share-resource-modal__header"
      >
        Data Sources
      </div>
      <div class="share-resource-modal__content">
        <label
          class="custom-app-template-editor__section-label--block"
        >Usados Recientemente
          <div class="data-source__container">

            <ul class="data-source__list">
              <li
                :key="dataSource.id"
                v-for="dataSource in recentlyUsed"
                @click="selectDataSource(dataSource)"
              >
                <label> {{ dataSource.dataSourceFilename }}
                </label>
                <!-- <div class="data-source__list-control"> -->
                <button
                  @click.stop="startEditing(dataSource)"
                  class="icon-button"
                  title="Editar"
                ><i class="material-icons">edit</i></button>
                <button
                  @click.stop="startDeleting(dataSource)"
                  class="icon-button"
                  title="Remover"
                ><i class="material-icons">delete</i></button>
                <!-- </div> -->

              </li>
            </ul>
          </div>
        </label>

        <label class="custom-app-template-editor__section-label"> {{ title }}Data Source</label>
        <div
          class="custom-app-template-editor__section-search"
        >
          <input
            class="custom-app-template-editor__input"
            type="text"
            v-model="dataSourceFilename"
            placeholder="Nombre"
          >
          <select
            class="custom-app-template-editor__input"
          >
            <option value="">
              Google sheet
            </option>
          </select>
        </div>
        <br>
        <div
          class="custom-app-template-editor__section-search"
        >
          <input
            class="custom-app-template-editor__input"
            v-model="selectedFile"
            @keyup="fileLookup"
            placeholder="Selecciona un archivo"
          >

          <div
            v-if="resultsOpen === true"
            class="search__result--list-aligned "
          >
            <a
              class="search__item"
              :key="resource.id"
              v-for="resource in sheetFiles"
              @click="selectFile(resource)"
            >
              <span class="">
                {{ resource.name }}
              </span>
            </a>
          </div>

          <select
            class="custom-app-template-editor__input"
            v-model="sheetName"
          >
            <option
              v-if="sheets.length === 0 || sheets === null"
            >
              Selecciona Hoja
            </option>
            <option
              v-else
              :key="sheet.spreadsheetId"
              v-for="sheet in sheets"
            >
              {{ sheet.properties.title }}
            </option>
          </select>
          <input
            v-model="range"
            class="custom-app-template-editor__input"
            placeholder="Rango"
          >
          <button
            class="icon-button"
            @click="saveAndSelect"
          >
            <i class="material-icons">save</i>
          </button>
        </div>
      </div>

      <!-- <button
        class="btn share-resource-modal__btn"
        :class="{ 'share-resource-modal__btn--disabled': !canSave }"
        @click="setUserTags"
      >
        {{ $t("generalActions.save") }}
      </button> -->
    </modal>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import api from '../api';
import { CLOSE_DATA_SOURCE_MODAL } from '../store/action-types';
import {
  SET_SELECTED_DATA_SOURCE,
  UNSET_SELECTED_DATA_SOURCE,
} from '../store/mutation-types';

import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';

const DEBOUNCE_DELAY = 1000;

const RECENTLY_USED_COUNT_INDEX = 5;

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  data() {
    return {
      dataSources: [],
      selectedFile: null,
      selectedSourceId: null,
      selectedId: null,
      resultsOpen: false,
      nameFile: null,
      sheetName: null,
      range: null,
      title: 'Crear Nuevo',
      dataSourceFilename: null,

    };
  },
  mounted() {
    api.getDataSources().then(response => {
      const normalizedDataSources = response.dataSources.reduce((objAccum, ds) => {
        objAccum[ds.id] = ds;

        return objAccum;
      }, {});
      // this.dataSources = response.dataSources;
      this.dataSources = normalizedDataSources;
    });
    this.openModal();
  },
  computed: {
    ...mapState({
      selectedDataSource: state => state.users.selectedDataSource,
    }),
    recentlyUsed() {
      // const recently = _.orderBy(this.dataSources, ['createdAt'], ['desc'])
      //   .slice(0, RECENTLY_USED_COUNT_INDEX);

      // return recently;
      return _.orderBy(this.dataSources, ['createdAt'], ['desc']);
    },
  },
  methods: {
    openModal() {
      this.$modal.show('data-sources');
    },
    close() {
      this.$store.dispatch(CLOSE_DATA_SOURCE_MODAL);
    },
    fileLookup: _.debounce(function () {
      if (this.selectedId) {
        return;
      }
      this.findFile();
    }, DEBOUNCE_DELAY),
    findFile() {
      this.getFiles(this.selectedFile);
      this.showResultPanel(true);
    },
    showResultPanel(value) {
      this.resultsOpen = value;
    },
    selectFile(file) {
      this.selectedFile = file.name;
      this.selectedSourceId = file.id;
      this.showResultPanel(false);
      this.getMetadata(file);
    },
    saveDataSource() {
      const validateMessages = this.validate();
      if (validateMessages.length === 0) return;
      const dataRange = `${this.sheetName}!${this.range}`;
      const params = { name: this.selectedFile, dataSourceType: 'table', dataRange, sourceId: this.selectedSourceId };
      api.createDataSource(params).then(response => this.dataSources.push(response));
    },
    saveAndSelect() {
      const validateMessages = this.validate();
      if (validateMessages.length > 0) {
        this.showMessage(validateMessages.join(','));

        return;
      }
      this.fillNameIfNecessary();
      const dataRange = `${this.sheetName}!${this.range}`;
      const params = { name: this.selectedFile, dataSourceFilename: this.dataSourceFilename, dataSourceType: 'table',
        dataRange, sourceId: this.selectedSourceId };
      if (this.selectedId === null) {
        api.createDataSource(params).then(response => {
          this.$store.commit(SET_SELECTED_DATA_SOURCE, response);
          this.showMessage('Nuevo DataSource creado');
          this.close();
        });
      } else {
        api.updateDataSource({ id: this.selectedId, params }).then(response => {
          this.dataSources[response.id] = response;
          this.clearDataSourceForm();
          this.showMessage('DataSource actualizado correctamente');
        });
      }
    },
    fillNameIfNecessary() {
      if (this.dataSourceFilename === null || this.dataSourceFilename === '') {
        this.dataSourceFilename = `${this.selectedFile}_${this.sheetName}!${this.range}`;
      }
    },
    selectDataSource(dataSource) {
      const selectedDataSource = {
        id: dataSource.id,
        name: dataSource.name, sheetName: dataSource.sheetName,
        range: dataSource.range, sourceId: dataSource.sourceId,
      };
      this.$store.commit(SET_SELECTED_DATA_SOURCE, selectedDataSource);
      this.close();
    },
    clearDataSourceForm() {
      this.sheetName = null;
      this.range = null;
      this.selectedFile = null;
      this.selectedId = null;
    },
    startEditing(dataSource) {
      this.title = 'Editar ';
      this.getMetadata({ id: dataSource.sourceId });
      this.selectedId = dataSource.id;
      this.selectedFile = dataSource.name;
      this.dataSourceFilename = dataSource.dataSourceFilename;
      this.selectedSourceId = dataSource.sourceId;
      this.range = dataSource.range;
      this.sheetName = dataSource.sheetName;
      this.dataRange = dataSource.dataRange;
    },
    startDeleting(dataSource) {
      api.deleteDataSource(dataSource.id).then((response) => {
        const copyDataSources = { ...this.dataSources };
        delete copyDataSources[dataSource.id];
        this.dataSources = copyDataSources;
        this.showMessage('Datasource Eliminado');
        this.clearDataSourceForm();
        if (dataSource.id === this.selectedDataSource.id) {
          this.$store.commit(UNSET_SELECTED_DATA_SOURCE);
        }
      });
    },
    validate() {
      const msg = [];
      if (this.selectedFile === null || this.selectedFile === '') {
        msg.push('Selecciona un archivo valido');
      } else if (this.sheetName === null) {
        msg.push('Selecciona un hoja valida');
      } else if (this.range === null || this.sheetName === '') {
        msg.push('Selecciona un rango valido');
      // } else if (this.dataSourceFilename === null || this.dataSourceFilename === '') {
      //   msg.push('Selecciona un nombre valido');
      }

      return msg;
    },
  },

};
</script>
<style  scoped>

.data-source__container {
  position: relative;
}

.data-source__list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.data-source__list > li {
  /* display: inline; */
  /* display: flex; */
  border-radius: 7px;
  border: .5px solid black;
  align-self: flex-start;
  margin-bottom: 5px;
}

.data-source__list > li > label {

  padding: 7px;
  /* background-color: blue;
  border-radius: 7px 0px 0px 7px;
  color: white;
  margin-left: 5px; */
  font-size: 1rem;
}

.data-source__list-control {
  padding: 5px;
  /* vertical-align: middle; */
  display: inline;
  border-radius: 0px 7px 7px 0px;
  border: .5px solid black;
}

.icon-button {
  background-color: rgba(0, 0, 0, 0);
  /* position: absolute; */
  vertical-align: middle;
  left: 5px;
}

</style>
