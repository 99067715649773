export default {
  currentFolder: null,
  folderList: null,
  selectedFolder: null,
  list: null,
  selectedId: null,
  selected: null,
  selectedUnnormalizerView: null,
  appContainers: null,
  maxAppWidth: null,
  comments: null,
  publications: null,
  selectedCommentId: null,
  selectedPublicationId: null,
  selectedPublicationThreadCommentId: null,
  linkPreviewMetadata: null,
  linkPreviewMetadataByPublicationId: {},
  selectedContainerId: null,
  selectedContainer: null,
  appModalType: null,
  appModalToShow: null,
  appModalName: null,
  selectedCustomAppTemplate: null,
  customAppTemplates: [],
  personalFolders: { personal: [], shared: [] },
  openPreviewModal: false,
  viewTemplate: null,
  industryTags: [],
  companySizeOptions: [],
  revenueTypeOptions: [],
  processOptions: [],
  viewingLevelOptions: [],
  areaOptions: [],
  connections: [],
  dinamycallyCreatedContainerInstances: {},
  imageUploaded: false,
  customAppTemplatesVisibilityOptions: [],
  mergeUserEmail: null,
  collapsibleIsOpen: true,
  selectedCheckedFilterIndex: null,
  selectedUncheckedFilterIndex: null,
  appsAttrs: {},
  tabContainerId: null,
  grid: undefined,
  shouldSaves: {},
  appsDraggable: true,
  dataSourceUpdated: false,
  appFloatingUpdater: {},
  tabSelectedId: null,
  menuWithItems: null,
};
