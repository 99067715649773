<template>
  <file-upload
    :accept="accept"
    v-model="files"
    @input-file="inputFile"
  >
    <slot />
  </file-upload>
</template>

<script>
import _ from 'lodash';
import { DirectUpload } from 'activestorage';
import FileUpload from 'vue-upload-component';

export default {
  components: {
    FileUpload,
  },
  props: {
    from: { type: String, default: null },
    accept: { type: String, default: '*/*' },
  },
  data() {
    return {
      files: [],
    };
  },
  computed: {
    imageName() {
      return _.get(this.files[0], 'name', null);
    },
  },
  methods: {
    toDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        const upload = new DirectUpload(newFile.file, '/api/v1/direct_uploads');
        this.$emit('loading-start');
        upload.create(async (error, blob) => {
          if (!error) {
            const imageUrl = await this.toDataUrl(this.files[0].file);
            this.$emit('loading-end');
            this.$emit('uploaded', { url: imageUrl, fileSignedId: blob.signed_id,
              name: this.files[0].name, from: this.from });
          }
        });
      }
    },
  },
};
</script>
