var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.appButtonID}},[(_vm.redirectType === 'file')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.container.app.fileUrl,"target":"_blank"}},[(_vm.container && !_vm.editable)?_c('div',{ref:"ButtonApp",staticClass:"h-full",class:{
        'show-bg-transparent': _vm.showTransparency,
      }},[_c('div',{staticClass:"flex flex-col"},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{staticClass:"icon-bottom-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{class:{
            'flex flex-row': ['right', 'left'].includes(_vm.iconPosition),
          }},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col self-center center-elements"},[(_vm.hasDescriptiveText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{staticClass:"icon-top-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_vm._e()]):(_vm.redirectType === 'url')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.redirectLink,"target":"_blank"}},[(_vm.container && !_vm.editable)?_c('div',{ref:"ButtonApp",staticClass:"h-full",class:{
        'show-bg-transparent': _vm.showTransparency,
      }},[_c('div',{staticClass:"flex flex-col"},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{staticClass:"icon-bottom-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{class:{
            'flex flex-row': ['right', 'left'].includes(_vm.iconPosition),
          }},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col self-center center-elements"},[(_vm.hasDescriptiveText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{staticClass:"icon-top-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_vm._e()]):(_vm.redirectType === 'view')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.getResourceUrl(_vm.redirectLink),"target":_vm.openNewTab ? '_blank' : '_self'}},[(_vm.container && !_vm.editable)?_c('div',{ref:"ButtonApp",staticClass:"h-full",class:{
        'show-bg-transparent': _vm.showTransparency,
      }},[_c('div',{staticClass:"flex flex-col"},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{staticClass:"icon-bottom-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{class:{
            'flex flex-row': ['right', 'left'].includes(_vm.iconPosition),
          }},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col self-center center-elements"},[(_vm.hasDescriptiveText)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{staticClass:"icon-top-padding self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_vm._e()]):_vm._e(),_vm._v(" "),(!(_vm.container && !_vm.editable))?_c('div',{ref:"buttonApp",staticClass:"h-full :overflow-hidden overflow-y-scroll",class:{
      'show-bg-transparent': _vm.showTransparency,
    },attrs:{"id":"button-app"}},[_c('div',{staticClass:"flex flex-col",staticStyle:{"justify-content":"center","align-items":"center"}},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{staticClass:"self-center center-elements",class:{
          'icon-bottom-padding': _vm.iconPosition === 'top',
        }},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-row"},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col self-center center-elements",attrs:{"id":"editor-div"}},[(_vm.hasDescriptiveText)?_c('vue-editor',{staticClass:"flex padding-horizontal-zero",attrs:{"id":_vm.editorId,"editor-toolbar":_vm.customToolbar},on:{"text-change":_vm.autoSaveDescriptiveText,"focus":function($event){return _vm.toolbarShow()},"blur":function($event){return _vm.toolbarHide()}},model:{value:(_vm.descriptiveText),callback:function ($$v) {_vm.descriptiveText=$$v},expression:"descriptiveText"}}):_vm._e()],1),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex self-center center-elements"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{staticClass:"flex self-center center-elements icon-top-padding"},[_c('i',{staticClass:"material-icons button-icon",attrs:{"id":_vm.buttonIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }