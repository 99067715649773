<template>
  <div>
    <modal
      name="app"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header">
        <i
          class="material-icons app-modal__header-icon"
          v-if="headerIcon"
        >
          {{ headerIcon }}
        </i>
        {{ headerText }}
      </div>
      <div class="app-modal__inputs">
        <template v-if="showTitle">
          <div class="app-modal__input-header">
            {{ $t("view.apps.title") }}
          </div>
          <vue-editor
            v-model="title"
            class="app-modal__vue-editor"
            :editor-toolbar="customToolbar"
            @text-change="autoSaveTitle"
          />
        </template>
        <slot />
        <!-- <div class="app-modal__inputs--layout-location">
          <div class="app-modal__inputs--layout-location--options">
            <div class="app-modal__input-header">
              {{ $t("view.apps.row") }}
            </div>
            <select
              v-model.number="row"
              class="app-modal__input app-modal__input--select app-modal__input--small"
            >
              <option
                v-for="orderOption in (numberOfRows + 1)"
                :key="orderOption"
                :value="orderOption"
              >
                {{ orderOption }}
              </option>
            </select>
            <div class="app-modal__input-header">
              {{ $t("view.apps.rowOrder") }}
            </div>
            <select
              v-model.number="orderInRow"
              class="app-modal__input app-modal__input--select app-modal__input--small"
            >
              <option
                v-for="orderOption in maxValidOrder"
                :key="orderOption"
                :value="orderOption"
              >
                {{ orderOption }}
              </option>
            </select>
            <div class="app-modal__input-header">
              {{ $t("view.apps.width") }}
            </div>
            <select
              v-model.number="width"
              class="app-modal__input app-modal__input--select app-modal__input app-modal__input--small"
            >
              <option
                v-if="maxValidWidth === 0"
                disabled
                value=""
              >
                {{ $t("view.apps.doesNotFitMessage") }}
              </option>
              <option
                v-for="widthOption in maxValidWidth"
                :key="widthOption"
                :value="widthOption"
              >
                {{ widthOption }}
              </option>
            </select>
          </div>
          <div class="app-modal__inputs--layout-location--mini-map">
            <div
              class="app-modal__inputs--layout-location--mini-map-cell"
              :class="{ 'app-modal__inputs--layout-location--mini-map-cell--active': selectedRow(r)}"
              :key="r"
              v-for="r in (numberOfRows + 1) * 6"
            />
          </div>
        </div> -->
        <div
          class="app-modal__toggles"
          v-if="showCommentsToggle || showBordersToggle"
        >
          <template v-if="showCommentsToggle">
            {{ $t("view.apps.comments") }}
            <toggle-button
              color="#5157ff"
              class="app-modal__toggle-btn"
              :value="commentsEnabled"
              :sync="true"
              @input="toggleCommentsEnabled"
            />
          </template>
          <template v-if="showBordersToggle">
            {{ $t("view.apps.showBorders") }}
            <toggle-button
              color="#5157ff"
              class="app-modal__toggle-btn"
              :value="showBorders"
              :sync="true"
              @input="showBorders = !showBorders"
            />
          </template>
        </div>
      </div>
      <div
        class="btn app-modal__save-btn"
        :class="{ 'app-modal__save-btn--disabled': !canSave }"
        @click="saveApp"
      >
        {{ $t("view.save") }}
      </div>
    </modal>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { CLOSE_APP_MODAL, SAVE_CONTAINER, REORDER_CONTAINERS, HANDLE_APP_LEAVING_ROW } from '../store/action-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import customToolbarHelper from '../helpers/custom-toolbar';

const DEBOUNCE_TIME = 1000;

export default {
  mixins: [escapeMixinHandler],
  props: {
    headerText: { type: String, required: true },
    headerIcon: { type: String, default: null },
    appFilled: { type: Boolean, required: true },
    appWithType: { type: Object, required: true },
    showCommentsToggle: { type: Boolean, default: true },
    showBordersToggle: { type: Boolean, default: true },
    showTitle: { type: Boolean, default: true },
  },
  components: {
    VueEditor,
  },
  mounted() {
    this.openModal();
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const numberOfRows = this.$store.getters.numberOfRows;
    const maxAppWidth = this.$store.state.views.maxAppWidth;

    return {
      customToolbar: customToolbarHelper.customToolbar,
      title: _.get(container, 'title', ''),
      row: _.get(container, 'row', numberOfRows + 1),
      width: _.get(container, 'width', maxAppWidth),
      orderInRow: _.get(container, 'orderInRow', 1),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      showBorders: _.get(container, 'showBorders', true),
    };
  },
  computed: {
    appWithContainer() {
      return {
        title: this.title,
        row: this.row,
        width: this.width,
        orderInRow: this.orderInRow,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        ...this.appWithType,
      };
    },
    canSave() {
      return !!(
        // _.isInteger(this.row) &&
        // _.isInteger(this.orderInRow) && this.orderInRow > 0 && this.orderInRow <= this.maxValidOrder &&
        // _.isInteger(this.width) && this.width > 0 && this.width <= this.maxValidWidth &&
        this.appFilled
      );
    },
    changedRows() {
      return this.selectedContainer && this.selectedContainer.row !== this.row;
    },
    maxValidOrder() {
      return (!this.selectedContainer || this.changedRows) ? this.rowCount(this.row) + 1 : this.rowCount(this.row);
    },
    availableWidthInRow() {
      if (!this.selectedContainer || this.changedRows) return this.maxWidth - this.rowWidth(this.row);

      const otherContainersInRow = this.otherContainersInRow(this.selectedContainer);

      return this.maxWidth - otherContainersInRow.reduce((sum, container) => sum + container.width, 0);
    },
    maxValidWidth() {
      let value = 0;
      value = (this.orderInRow === this.maxValidOrder ? this.maxWidth : this.availableWidthInRow);

      return value < 0 ? this.maxWidth : value;
    },
    tooWideAndAtTheEndOfRow() {
      return this.width > this.availableWidthInRow && this.orderInRow === this.maxValidOrder;
    },
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
      selectedContainerId: state => state.views.selectedContainerId,
      selectedView: state => state.views.selected,
    }),
    ...mapGetters([
      'selectedContainer',
      'rowCount',
      'rowWidth',
      'otherContainersInRow',
      'containersWithGteRow',
      'numberOfRows',
    ]),
  },
  methods: {
    autoSaveTitle: _.debounce(function () {
      this.saveTitle();
    }, DEBOUNCE_TIME),
    saveTitle() {
      if (this.selectedContainerId === null || this.selectedContainerId === undefined) return;
      const params = { title: this.title };
      const containerId = this.selectedContainerId;
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    openModal() {
      this.$modal.show('app');
    },
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async dispatchReorderContainers(containers, initialOrder) {
      await this.$store.dispatch(REORDER_CONTAINERS, { containers, initialOrder });
    },
    async insertInNewRow() {
      const containersToMove = _.orderBy(this.containersWithGteRow(this.row + 1), 'row', 'desc');
      // console.log(containersToMove.length);
      for (let index = 0; index < containersToMove.length; index++) {
        await this.dispatchSaveContainer({ row: containersToMove[index].row + 1 }, containersToMove[index].id);
      }
      this.row++;
      this.orderInRow = 1;
    },
    async saveChangesAndReorderRows() {
      if (this.tooWideAndAtTheEndOfRow) await this.insertInNewRow();
      const otherContainersInOldRow = this.changedRows ? this.otherContainersInRow(this.selectedContainer) : [];
      const oldRow = _.get(this.selectedContainer, 'row');
      const savedContainer = await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      const containersToTheLeft = this.otherContainersInRow(savedContainer).slice(0, savedContainer.orderInRow - 1);
      const containersToTheRight = this.otherContainersInRow(savedContainer).slice(savedContainer.orderInRow - 1);
      await this.$store.dispatch(HANDLE_APP_LEAVING_ROW, { row: oldRow, otherContainersInOldRow });
      await this.dispatchReorderContainers(containersToTheLeft, 1);
      await this.dispatchReorderContainers(containersToTheRight, 1 + savedContainer.orderInRow);
    },
    async saveApp() {
      if (!this.canSave) return;

      await this.saveChangesAndReorderRows();
      try {
        this.close();
      } catch (error) {
        const errorText = _.flatten(_.values(error.response.errors)).join(', ');
        this.$toasted.error(errorText);
      }
    },
    toggleCommentsEnabled() {
      this.commentsEnabled = !this.commentsEnabled;
    },
    resetWidth() {
      this.width = this.availableWidthInRow === 0 ? this.maxValidWidth : this.availableWidthInRow;
    },
    updateEditorTitle({ target }) {
      this.title = target.value;
    },
    selectedRow(currentCell) {
      if (this.row === Math.ceil(currentCell / this.maxWidth)) {
        const tempPos = currentCell % this.maxWidth;
        const currentPositionInRow = tempPos === 0 ? this.maxWidth : tempPos;
        let startPoint;
        if (this.orderInRow > 1) {
          startPoint = this.maxWidth - this.width + 1;
        } else {
          startPoint = this.orderInRow;
        }
        const endPoint = startPoint + this.width;

        return currentPositionInRow >= startPoint && currentPositionInRow < endPoint;
      }

      return false;
    },
  },
  watch: {
    row() {
      this.orderInRow = this.maxValidOrder;
      this.resetWidth();
    },
    orderInRow() {
      this.resetWidth();
    },
  },
};
</script>
