var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.container && !_vm.editable)?_c('div',{ref:"ImageApp",staticClass:"h-full"},[(!_vm.hasRedirectLink)?_c('div',{staticClass:"flex flex-col",class:{
        'container': _vm.descriptiveTextOverlap
      }},[(_vm.hasDescriptiveText)?_c('div',{staticClass:"self-left ql-editor",class:{
          'top-left': _vm.descriptiveTextOverlap
        },attrs:{"id":_vm.titleID},domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"self-center center-elements",class:{
          'center-adjustment': _vm.contentAdjustment === 'center',
          'expanded-adjustment': _vm.contentAdjustment === 'expanded',
        }},[(_vm.imageName === 'default_placeholder.png')?_c('div',[_c('i',{staticClass:"material-icons icon"},[_vm._v("photo")])]):_c('div',[_c('img',{class:{
              'self-start text-right-padding': _vm.contentAlignment === 'left',
              'self-end text-left-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },attrs:{"id":_vm.imageID,"src":_vm.imageUrl}})])])]):_c('div',[(_vm.redirectType === 'file')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.container.app.redirectUrl,"target":"_blank"}},[_c('div',{staticClass:"h-full",class:{
            'show-bg-transparent': _vm.showTransparency,
          }},[_c('div',{staticClass:"flex flex-col",class:{
              'container': _vm.descriptiveTextOverlap
            }},[(_vm.hasDescriptiveText)?_c('div',{staticClass:"self-left ql-editor",class:{
                'top-left': _vm.descriptiveTextOverlap
              },attrs:{"id":_vm.titleID},domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"self-center center-elements",class:{
                'center-adjustment': _vm.contentAdjustment === 'center',
                'expanded-adjustment': _vm.contentAdjustment === 'expanded',
              }},[(_vm.imageName === 'default_placeholder.png')?_c('div',[_c('i',{staticClass:"material-icons icon"},[_vm._v("photo")])]):_c('div',[_c('img',{class:{
                    'self-start text-right-padding': _vm.contentAlignment === 'left',
                    'self-end text-left-padding': _vm.contentAlignment === 'right',
                    'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
                  },attrs:{"id":_vm.imageID,"src":_vm.imageUrl}})])])])])]):(_vm.redirectType === 'url')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.redirectLink,"target":"_blank","id":"redirect-link-url-mode"}},[_c('div',{staticClass:"h-full",class:{
            'show-bg-transparent': _vm.showTransparency,
          }},[_c('div',{staticClass:"flex flex-col",class:{
              'container': _vm.descriptiveTextOverlap
            }},[(_vm.hasDescriptiveText)?_c('div',{staticClass:"self-left ql-editor",class:{
                'top-left': _vm.descriptiveTextOverlap
              },attrs:{"id":_vm.titleID},domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"self-center center-elements",class:{
                'center-adjustment': _vm.contentAdjustment === 'center',
                'expanded-adjustment': _vm.contentAdjustment === 'expanded',
              }},[(_vm.imageName === 'default_placeholder.png')?_c('div',[_c('i',{staticClass:"material-icons icon"},[_vm._v("photo")])]):_c('div',[_c('img',{class:{
                    'self-start text-right-padding': _vm.contentAlignment === 'left',
                    'self-end text-left-padding': _vm.contentAlignment === 'right',
                    'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
                  },attrs:{"id":_vm.imageID,"src":_vm.imageUrl}})])])])])]):(_vm.redirectType === 'view')?_c('a',{staticClass:"cursor-pointer",attrs:{"href":_vm.getResourceUrl(_vm.redirectLink),"target":_vm.openNewTab ? '_blank' : '_self'}},[(_vm.container && !_vm.editable)?_c('div',{ref:"ButtonApp",staticClass:"h-full",class:{
            'show-bg-transparent': _vm.showTransparency,
          }},[_c('div',{staticClass:"flex flex-col",class:{
              'container': _vm.descriptiveTextOverlap
            }},[(_vm.hasDescriptiveText)?_c('div',{staticClass:"self-left ql-editor",class:{
                'top-left': _vm.descriptiveTextOverlap
              },attrs:{"id":_vm.titleID},domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"self-center center-elements",class:{
                'center-adjustment': _vm.contentAdjustment === 'center',
                'expanded-adjustment': _vm.contentAdjustment === 'expanded',
              }},[(_vm.imageName === 'default_placeholder.png')?_c('div',[_c('i',{staticClass:"material-icons icon"},[_vm._v("photo")])]):_c('div',[_c('img',{class:{
                    'self-start text-right-padding': _vm.contentAlignment === 'left',
                    'self-end text-left-padding': _vm.contentAlignment === 'right',
                    'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
                  },attrs:{"id":_vm.imageID,"src":_vm.imageUrl}})])])])]):_vm._e()]):_vm._e()])]):_c('div',{ref:"ImageApp",staticClass:"h-full"},[_c('div',{staticClass:"flex flex-col h-full overflow-hidden overflow-y-scroll",class:{
        'container': _vm.descriptiveTextOverlap
      }},[(_vm.hasDescriptiveText)?_c('vue-editor',{staticClass:"self-left",class:{
          'top-left': _vm.descriptiveTextOverlap
        },attrs:{"id":_vm.editorId,"editor-toolbar":_vm.customToolbar},on:{"text-change":_vm.autoSaveDescriptiveText,"focus":function($event){return _vm.toolbarShow()},"blur":function($event){return _vm.toolbarHide()}},model:{value:(_vm.descriptiveText),callback:function ($$v) {_vm.descriptiveText=$$v},expression:"descriptiveText"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"self-center center-elements",class:{
          'center-adjustment': _vm.contentAdjustment === 'center',
          'expanded-adjustment': _vm.contentAdjustment === 'expanded',
        }},[(_vm.imageName === 'default_placeholder.png')?_c('div',[_c('i',{staticClass:"material-icons icon"},[_vm._v("photo")])]):_c('div',[_c('img',{key:_vm.imageUpdaterKey,class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },attrs:{"id":_vm.imageID,"src":_vm.imageUrl}})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }