<template>
  <div
    ref="comment"
    class="comment"
  >
    <profile-picture
      :url="userProfilePictureUrl"
      class="comment__user-photo self-center ml-2"
    />
    <div class="comment_user-info">
      <span class="comment__username">{{ userDisplayName }}</span>
      <div
        class="comment__user-info
        comment__user-info-since"
      >
        <span
          :datetime="comment.createdAt"
          ref="timestamp"
        />
      </div>
    </div>
    <template
      v-if="!selectedForEdition(comment)"
    >
      <div class="comment__content">
        <span
          v-autolinker="comment.content"
        />
      </div>
      <!-- <span
        class="comment__content"
        v-autolinker="comment.content"
      /> -->
    </template>
    <at-ta
      v-else
      :tab-select="true"
      :members="members"
      name-key="name"
      class="comment__content"
    >
      <template
        slot="item"
        slot-scope="s"
      >
        <img :src="s.item.avatar">
        <span
          v-text="s.item.name"
        />
      </template>
      <textarea-autoheight
        ref="edit"
        :style="{width: '100%'}"
        v-model="editedContent"
        @keyup.enter.exact.prevent="updateComment"
        @keyup.esc.prevent="unselectCommentForEdition"
        class="comment__input outline-none"
      />
    </at-ta>
    <div
      v-if="currentUserId === comment.user && !selectedForEdition(comment)"
      class="comment__actions-wrapper"
    >
      <div
        @click="showActions = !showActions"
        class="comment__show-actions-btn"
      >
        <i class="material-icons material-icons-inline">more_vert</i>
      </div>
      <div
        class="comment__actions-dropdown"
        v-if="showActions === true"
      >
        <button
          @click="selectCommentForEdition(comment)"
          class="comment__action"
        >
          <i class="material-icons comment__action-icon">edit</i>Editar
        </button>
        <button
          @click="selectCommentForDeletion(comment)"
          class="comment__action"
        >
          <i class="material-icons comment__action-icon">delete</i>Eliminar
        </button>
      </div>
    </div>
    <div class="comment__footer">
      <template v-if="!comment.parentId">
        <a
          v-if="!showThread"
          href="#"
          @click.prevent="openThread"
          class="comment__footer-link"
        >
          Responder
        </a>
        <a
          v-else
          href="#"
          @click.prevent="closeThread"
          class="comment__footer-link"
        >
          Cerrar
        </a>
        •
        <a
          href="#"
          @click.prevent="openThread"
          class="comment__footer-link"
        >
          {{ $t('view.apps.feed.showComments', { number: threadCommentsCount }) }}
        </a>
        •
      </template>
    </div>
    <feed
      v-if="showThread"
      class="comment__thread"
      :comments="threadComments"
      :parent-id="comment.id"
    />
  </div>
</template>

<script>
/* global require */

import _ from 'lodash';
import { mapState } from 'vuex';
import { render, cancel } from 'timeago.js';
import AtTa from './at-textarea';
import { UPDATE_SELECTED_COMMENT, SELECT_COMMENT_FOR_DELETION, GET_THREAD_COMMENTS } from '../store/action-types';
import { SET_SELECTED_COMMENT_ID } from '../store/mutation-types';
import profilePlaceholder from '../images/upload-photo.png';

export default {
  props: {
    comment: { type: Object, required: true },
  },
  components: { AtTa },
  data() {
    return {
      newComment: null,
      editedContent: null,
      showActions: false,
      showThread: false,
    };
  },
  beforeCreate() {
    this.$options.components.Feed = require('./feed').default;
  },
  computed: {
    members() {
      const resource = this.$store.getters.selectedResource;
      const alreadySharedUsers = _.cloneDeepWith(this.$store.getters.selectedResourceSharedUsers(resource.type));

      return alreadySharedUsers.map((user) => {
        if (user.profilePictureUrl === undefined || user.profilePictureUrl === null) {
          user.profilePictureUrl = profilePlaceholder;
        }

        return {
          name: user.displayName,
          avatar: user.profilePictureUrl,
        };
      });
    },
    ...mapState({
      users: state => state.users.list,
      selectedCommentId: state => state.views.selectedCommentId,
      currentUserId: state => state.users.currentId,
    }),
    threadComments() {
      return this.$store.getters.threadComments(this.comment.id);
    },
    threadCommentsCount() {
      const commentCount = this.$store.getters.threadCommentsCount(this.comment.id);
      if (!commentCount) {
        return this.comment.threadCommentsCount;
      }

      return commentCount;
    },
    userDisplayName() {
      return _.get(this.users[this.comment.user], 'displayName');
    },
    userProfilePictureUrl() {
      return _.get(this.users[this.comment.user], 'profilePictureUrl');
    },
  },
  mounted() {
    render(this.$refs.timestamp, 'es', { minInterval: 10 });
  },
  beforeDestroy() {
    cancel();
  },
  methods: {
    selectCommentForEdition(comment) {
      this.showActions = false;
      this.$store.commit(SET_SELECTED_COMMENT_ID, comment.id);
      this.editedContent = comment.content;
      this.$nextTick(() => this.$refs.edit.focus());
    },
    selectCommentForDeletion(comment) {
      this.showActions = false;
      this.$store.dispatch(SELECT_COMMENT_FOR_DELETION, comment.id);
      this.$refs.comment.closest('.droppeable-app').style.minHeight = `${this.$refs.comment.closest('.app-container__content').offsetHeight + 30}px`;
    },
    unselectCommentForEdition() {
      this.$store.commit(SET_SELECTED_COMMENT_ID, null);
      this.editedContent = null;
    },
    updateComment() {
      if (!this.editedContent) return;

      this.editedContent = event.target.value;
      this.$store.dispatch(UPDATE_SELECTED_COMMENT, { content: this.editedContent }).then(() => {
        this.unselectCommentForEdition();
      });
    },
    selectedForEdition(comment) {
      return this.selectedCommentId === comment.id && this.editedContent !== null;
    },
    openThread() {
      if (this.showThread) return;

      this.$store.dispatch(GET_THREAD_COMMENTS, this.comment.id).then(() => {
        this.showThread = true;
      });
    },
    closeThread() {
      this.showThread = false;
    },
  },
};
</script>
