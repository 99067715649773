<template>
  <div
    class="search"
    v-show="false"
  >
    <i class="material-icons material-icons__search">
      search
    </i>
    <input
      type="text"
      class="search__input text-placeholder outline-none"
      v-model="searchTerm"
      @keyup="getSearchResults"
      placeholder="Buscar..."
    >
    <div
      v-if="resultsOpen === true"
      class="search__result-list shadow-card"
    >
      <a
        class="search__item  border-b border-gray-200"
        :href="getLink(resource)"
        :key="resource.id"
        v-for="resource in searchResults"
      >
        <i
          class="material-icons material-icons-outlined material-icons-inline"
          v-if="resource.content !== 'Sin resultados'"
        >
          {{ getIcon(resource.searchableType) }}
        </i>
        <i
          class="material-icons material-icons-inline"
          v-else
        >
          search_off
        </i>
        <span class="pl-4">
          {{ resource.content }}
        </span>
      </a>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import api from '../api';
import escapeMixinHandler from '../mixins/escapeMixinHandler';

const DEBOUNCE_TIMEOUT = 1000;
const RESOURCE_BASE_URL = {
  'View': '/views/',
  'Folder': '/folders/',
  'User': '/users/',
  'Comment': '/views/',
  '#': '/#',
};
const RESOURCE_ICONS = {
  'View': 'preview',
  'Folder': 'folder',
  'User': 'account_circle',
  'Comment': 'comment',
  '#': '/#',

};

export default {
  mixins: [escapeMixinHandler],
  data() {
    return {
      searchResults: [],
      searchTerm: null,
      resultsOpen: false,
    };
  },
  methods: {
    getSearchResults: _.debounce(function () {
      this.search();
    }, DEBOUNCE_TIMEOUT),
    search() {
      if (this.searchTerm === null) return;
      api.search(this.searchTerm).then(results => {
        if (results.search.length === 0) {
          this.searchResults = [{
            searchableType: '#',
            content: 'Sin resultados',
            searchableId: '/' }];
        } else {
          this.searchResults = results.search;
        }

        this.resultsOpen = true;
      });
    },
    getLink(resource) {
      const baseUrl = RESOURCE_BASE_URL[resource.searchableType];

      return `${baseUrl}${resource.searchableId}`;
    },
    getIcon(resourceType) {
      const iconName = RESOURCE_ICONS[resourceType];

      return iconName;
    },
    close() {
      this.searchTerm = null;
      this.resultsOpen = false;
    },
  },

};
</script>
<style scoped>
.search {
  display: flex;
  flex-direction: row;
  position: relative;
  font-family: 'Sarala', sans-serif;
  margin-left: 100px;
}

.search__input {
  padding-left: 1.5rem;
  margin-right: 1.5rem;
  border-radius: 100px;
  border: 1px solid #edebf5;
  font-size: 14px;
  cursor: text;
  transition: all .7s ease 0s;
  width: 231px;
  height: 37px;
  background-color: #fff;
}

.material-icons__search {
  position: absolute;
  top: 7px;
  left: 190px;
  color: #0006;
}

.search__result-list {
  position: absolute;
  background-color: #fff;
  top: 35px;
  z-index: 200;
  width: 500px;
}

.search__item {
  display: block;
  cursor: pointer;
  color: #68778d;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 1rem;
  font-size: 13px;
}

.search__item:hover {
  background-color: #e8eaf6;
  color: #000;
}

</style>
