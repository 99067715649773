import * as mutations from '../../mutation-types';
import { camelize } from 'humps';

export default {
  [mutations.ADD_NORMALIZED_USERS](state, users) {
    state.list = { ...state.list, ...users };
  },

  [mutations.SET_NORMALIZED_USERS](state, users) {
    state.list = { ...users };
  },
  [mutations.ADD_USER](state, user) {
    state.list = { ...state.list, [user.id]: user };
  },
  [mutations.SET_SELECTED_USER_ID](state, id) {
    state.selectedId = id;
  },
  [mutations.REMOVE_USER](state) {
    const usersCopy = { ...state.list };
    delete usersCopy[state.selectedId];
    state.list = { ...usersCopy };
  },
  [mutations.REMOVE_CONSULTANT](state) {
    const consultantCopy = { ...state.consultantsList };
    delete consultantCopy[state.selectedConsultant.id];
    state.consultantsList = { ...consultantCopy };
  },
  [mutations.UPDATE_SELECTED_USER](state, user) {
    state.list = { ...state.list, [state.selectedId]: user };
  },
  [mutations.SET_CURRENT_USER_ID](state, id) {
    state.currentId = id;
  },
  [mutations.SET_CURRENT_COMPANY_NAME](state, currentCompanyName) {
    state.currentCompanyName = currentCompanyName;
  },
  [mutations.ADD_CURRENT_USER_CONNECTIONS](state, connection) {
    state.currentUserConnections = { ...(state.currentUserConnections || {}), ...connection };
  },
  [mutations.ADD_TENANT_TAGS](state, tags) {
    state.tenantTags = [...tags];
  },
  [mutations.SET_USERS_TAGGED](state, usersTagged) {
    state.usersTagged = { ...usersTagged };
  },
  [mutations.SET_TAG_MODAL_EDIT_MODE](state, mode) {
    state.tagModalEditMode = mode;
  },
  [mutations.SET_WORKSPACE_MODAL_EDIT_MODE](state, mode) {
    state.workspaceModalEditMode = mode;
  },
  [mutations.SET_TAG_FOR_EDITION](state, selectedTag) {
    state.selectedTag = selectedTag;
  },

  [mutations.SET_SELECTED_TAG](state, selectedTag) {
    state.selectedTag = selectedTag;
  },

  [mutations.SET_WORKSPACE_FOR_EDITION](state, selectedWorkspace) {
    state.selectedWorkspace = selectedWorkspace;
  },

  [mutations.SET_SELECTED_WORKSPACE](state, selectedWorkspace) {
    state.selectedWorkspace = selectedWorkspace;
  },

  [mutations.SET_SELECTED_PLATFORM](state, selectedPlatform) {
    state.selectedPlatform = selectedPlatform;
  },
  [mutations.SET_CURRENT_COMPANY_ID](state, companyId) {
    state.currentCompanyId = companyId;
  },
  [mutations.SET_WORKSPACES_LIST](state, workspaces) {
    state.workspacesList = workspaces;
  },

  [mutations.UPDATE_WORKSPACES_LIST](state, newWorkspace) {
    state.workspacesList = { ...state.workspacesList, [newWorkspace.id]: newWorkspace };
  },
  [mutations.REMOVE_WORKSPACE](state, workspaceId) {
    delete state.workspacesList[workspaceId];
  },
  [mutations.UPDATE_TAG](state, { index, newValue }) {
    const tenantTagsCopy = [...state.tenantTags];
    tenantTagsCopy.splice(index, 1, newValue);
    state.tenantTags = [...tenantTagsCopy];
  },
  [mutations.REMOVE_TAG](state, index) {
    const tenantTagsCopy = [...state.tenantTags];
    tenantTagsCopy.splice(index, 1);
    state.tenantTags = [...tenantTagsCopy];
  },
  [mutations.SET_TAG_REMOVE_MODE](state, value) {
    state.tagRemoveMode = value;
  },
  [mutations.SET_TAG_EDIT_MODE](state, value) {
    state.tagEditMode = value;
  },
  [mutations.SET_SELECTED_DATA_SOURCE](state, dataSource) {
    state.selectedDataSource = dataSource;
  },
  [mutations.UNSET_SELECTED_DATA_SOURCE](state) {
    state.selectedDataSource = null;
  },
  [mutations.SET_DATA_SOURCE_SERVICE](state, service) {
    state.selectedDataSourceService = service;
  },
  [mutations.SET_CONSULTANTS](state, consultants) {
    state.consultantsList = consultants;
  },
  [mutations.UPDATE_CONSULTANT_LIST](state, updatedConsultant) {
    state.consultantsList = { ...state.consultantsList, [updatedConsultant.id]: updatedConsultant };
  },
  [mutations.SET_SELECTED_CONSULTANT](state, consultant) {
    state.selectedConsultant = consultant;
  },
  [mutations.SET_SELECTED_CUSTOM_COLUMNS](state, value) {
    console.log('CUSTOM COLUMNS', value);
    const camelizedColumns = value.map(column => {
      const columnName = camelize(column.name);
      column.name = columnName;

      return column;
    });
    console.log('CUSTOM COLUMNS', camelizedColumns);
    state.selectedCustomColumn = camelizedColumns;
  },
  [mutations.SET_SELECTED_CUSTOM_TYPE](state, value) {
    state.selectedCustomType = value;
  },
  [mutations.SET_SELECTED_CUSTOM_DATUM](state, value) {
    state.selectedCustomDatum = value;
  },
  [mutations.ADD_SELECTED_DATA](state, value) {
    state.selectedCustomData = [...state.selectedCustomData, value];
  },
  [mutations.UPDATE_SELECTED_DATA](state, { value, replaceIndex }) {
    const selectedCustomDataCopy = [...state.selectedCustomData];
    selectedCustomDataCopy.splice(replaceIndex, 1);
    selectedCustomDataCopy.splice(replaceIndex, 0, value);
    state.selectedCustomData = [...selectedCustomDataCopy];
  },
  [mutations.REMOVE_SELECTED_DATA](state, deletedIndex) {
    const selectedCustomDataCopy = [...state.selectedCustomData];
    selectedCustomDataCopy.splice(deletedIndex, 1);
    state.selectedCustomData = [...selectedCustomDataCopy];
  },
  [mutations.SET_SELECTED_DATA](state, value) {
    state.selectedCustomData = value;
  },

};
