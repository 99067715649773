<template>
  <div class="view-editor">
    <div class="view-editor__content">
      <slot />
      <div
        class="view-editor__workspace-container mb-4"
        style="overflow-x: hidden;"
      >
        <div
          class="view-editor__workspace"
        >
          <div
            class="view-editor__workspace-header"
            style="height: 40px; padding-left: 20px;"
          >
            <div class="view_editor_title">
              {{ view && view.title }}
            </div>
            <div>
              <a
                v-if="(isAdmin && !isTemplate) || isCreator"
                :href="editPath"
                :title="$t('view.edit')"
                class="view-editor__action"
                style="position: absolute; right: 0;"
              >
                <i class="material-icons view-editor__action-icon">edit</i>
              </a>
              <div
                v-if="!this.view.inSharedFolder"
                class="view-editor__action"
                @click="openViewSettingsModal"
                :title="$t('view.settingsButton')"
                style="display: none;"
              >
                <i class="material-icons view-editor__action-icon">settings</i>
              </div>
            </div>
          </div>
        </div>
        <div
          id="view-editor-container"
          class="view-editor__workspace-editor dynamic-height no-padding view-mode-zoom"
        >
          <div
            class="view-editor__workspace-content relative container-min-height"
            id="app-grid-container"
            ref="appgrid"
          >
            <app-grid-3
              :apps-editable="false"
            />
          </div>
        </div>
      </div>
    </div>
    <confirmation-modal v-if="confirmationModalOpened" />
    <share-resource-modal v-if="shareResourceModalOpened" />
    <text-app-modal
      v-if="selectedModalOfType(appTypes.TEXT) && selectedContainer"
      :container="this.selectedContainer"
    />
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import { APP_TYPES } from '../constants';
import {
  SET_NESTED_VIEW,
} from '../store/action-types';
import {
  SET_MAX_APP_WIDTH,
  SET_PERSONAL_FOLDERS,
  SET_MERGE_USER_EMAIL,

} from '../store/mutation-types';
import ConfirmationModal from './confirmation-modal';
import ShareResourceModal from './share-resource-modal';
import TextAppModal from './text-app-modal';
import storeConnectionsMixin from '../mixins/storeConnectionsMixin';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';

export default {
  mixins: [storeConnectionsMixin, dragAndResizeMixin],
  components: {
    ShareResourceModal,
    ConfirmationModal,
    TextAppModal,
  },
  props: {
    viewProp: { type: Object, required: true },
    maxAppWidth: { type: Number, required: true },
    template: { type: Object, default: undefined },
    masterViewProp: { type: Object, default: () => {} },
    personalFolders: {
      type: Array,
      required: false,
      default: () => [],
    },
    sharedFolders: {
      type: Array,
      required: false,
      default: () => [],
    },
    mergeUserEmail: { type: String, default: null },
  },
  data() {
    return {
      masterView: this.masterViewProp,
      appTypes: APP_TYPES,
    };
  },
  async mounted() {
    let currentTitle = document.title.split('|')[0];
    if (this.viewProp.title) {
      document.title = currentTitle + ' | ' + this.viewProp.title;
    }
    this.$store.dispatch(SET_NESTED_VIEW, this.viewProp);
    this.$store.commit(SET_MERGE_USER_EMAIL, this.mergeUserEmail);
    this.$store.commit(SET_MAX_APP_WIDTH, this.maxAppWidth);
    const allFolders = { personal: this.personalFolders, shared: this.sharedFolders };
    this.$store.commit(SET_PERSONAL_FOLDERS, allFolders);
  },
  computed: {
    isTemplate() {
      return !!this.template;
    },
    editPath() {
      return this.isTemplate ?
        `/view_templates/${this.template.id}/edit` : `/views/${this.view && this.view.uuid}/edit`;
    },
    ...mapState({
      view: state => state.views.selected,
      shareResourceModalOpened: state => state.ui.shareResourceModalOpened,
      fullscreenAppModalOpened: state => state.ui.fullscreenAppModalOpened,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
    }),
    ...mapGetters(['isAdmin', 'currentUser', 'selectedContainer', 'selectedModalOfType']),
    isCreator() {
      return this.view && this.currentUser && this.view.creatorId === this.currentUser.id;
    },
  },
  methods: {
    openViewSettingsModal() {
      console.log('gg');
    },
  },
};
</script>
<style scoped>
.container-min-height {
  min-height: 90vh;
}

.dotted-grid {
  background-image: radial-gradient(circle at 1px 1px, #d8d8d8 1px, transparent 0);
  background-size: 12px 12px;

}

.view-editor__workspace-container {
  background-color: #fff;
  flex: 1;
  margin-left: 48px;
}

.h-20px {
  height: 20px;
}

.max-w-1348 {
  max-width: 1348px;
}

.dynamic-height {
  height: 100%;
}

.no-padding {
  padding: 0;
}

.view-mode-zoom {
  transform: scale(1.2);
  transform-origin: left top;
}

</style>
