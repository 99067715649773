<template>
  <div>
    <modal
      name="folder"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header">
        <i class="material-icons app-modal__header-icon">folder</i>
        {{ $t('view.folder.title') }}
      </div>
      <div class="app-modal__inputs">
        <div class="app-modal__input-header">
          {{ $t('view.folder.name') }}
        </div>
        <input
          v-model="titleName"
          type="text"
          class="app-modal__input"
        >
      </div>
      <div
        class="btn app-modal__save-btn"
        :class="{ 'app-modal__save-btn--disabled': !requiredInputsFilled }"
        @click="save"
      >
        {{ $t('view.save') }}
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import { CLOSE_FOLDER_MODAL, SAVE_FOLDER } from '../store/action-types';

export default {
  mixins: [escapeMixinHandler],
  data() {
    const folder = this.$store.state.views.selectedFolder;

    return {
      titleName: _.get(folder, 'name'),
    };
  },
  mounted() {
    this.$modal.show('folder');
  },
  computed: {
    ...mapState({
      currentFolder: state => state.views.currentFolder,
    }),
    requiredInputsFilled() {
      return !!(this.titleName);
    },
  },
  methods: {
    close() {
      this.$store.dispatch(CLOSE_FOLDER_MODAL);
    },
    save() {
      const params = { name: this.titleName, parentId: this.currentFolder.id };
      this.$store.dispatch(SAVE_FOLDER, params).then(() => this.close());
    },
  },
};
</script>
