<template>
  <div>
    <modal
      name="databases-edit-modal"
      :width="498"
      :height="607"
      :scrollable="true"
      :click-to-close="false"
      :resizable="false"
    >
      <div
        class="flex flex-row border-b"
        style="height: 54.51px; position: sticky; top: 0px; background-color: white;"
      >
        <div
          class="w-9/12"
          style="margin-left: 26.38px; margin-top: 15.45px;"
        >
          <i
            class="material-icons material-icons-inline add-style"
          >add</i>
          <span class="new-database-title">Editar base de datos</span>
        </div>
        <div
          class="w-3/12"
        >
          <div
            class="app-modal__close-btn text-right close-box"
            @click="close()"
          >
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      <div
        style="margin-left: 26.38px; margin-top: 33.47px;"
      >
        <span class="database-param-font">
          Nombre
        </span>
        <input
          type="text"
          v-model="name"
          class="database-name-input-param-box input-params-font"
          placeholder="Escribe aquí..."
        >
        <div
          class="flex flex-row"
          style="margin-top: 19.56px;"
        >
          <div class="w-1/12 database-param-font">
            Tipo
          </div>
          <div class="w-6/12 flex items-center flex-row">
            <input
              type="radio"
              id="static"
              value="static"
              v-model="internalType"
            >
            <label
              class="database-param-font ml-2"
              for="static"
            >Carga estática</label>
            <i
              class="material-icons ml-2"
              style="color: #555859; font-size: 1rem;"
            >upload_file</i>
          </div>
          <div class="w-5/12 flex items-center flex-row">
            <input
              type="radio"
              id="dynamic"
              value="dynamic"
              v-model="internalType"
            >
            <label
              class="database-param-font ml-2"
              for="dynamic"
            >Carga dinámica</label>
            <i
              class="material-icons ml-2"
              style="color: #555859; font-size: 1rem;"
            >cached</i>
          </div>
        </div>
        <div
          class="flex flex-row"
        >
          <div class="w-1/12" />
          <span
            class="w-6/12 internal-type-description-font ml-6"
          >Carga manual y/o de archivos desde tu ordenador, como fuente estática.</span>
          <span
            class="w-4/12 internal-type-description-font ml-6"
          >Carga desde una fuente en línea, como fuente dinámica.</span>
          <div class="w-1/12" />
        </div>
        <span class="database-param-font">
          Descripción
        </span>
        <textarea
          type="text"
          v-model="description"
          class="database-name-input-param-box input-params-font text-start textarea"
          placeholder="Escribe aquí..."
          style="height: 66px; padding-top: 8.5px; padding-right: 12.13px;"
        />
        <span class="database-param-font">
          Columnas
        </span>
        <div
          v-for="(column, index) in columnObjects"
          :key="index"
          class="flex flex-row"
          style="padding-right: 32.4px; height: 32px; position: relative; left: -6px; margin-bottom: 14px;"
        >
          <!-- <div
            style="width: 5.4%; margin: auto;"
          >
            <i class="material-icons">drag_indicator</i>
          </div> -->
          <div
            style="width: 52.1%; margin-left: 0.4rem;"
          >
            <input
              type="text"
              v-model="columnObjects[index].columnName"
              class="database-columns-input-param-box input-params-font"
              :placeholder="`Columna ${index + 1}`"
              style="width: 100%;"
            >
          </div>
          <div style="width: 1%;" />
          <div
            style="width: 31.1%; margin: auto;"
          >
            <div
              class="ml-2 internal-type-box internal-type-font"
              style="width: 100% !important; vertical-align: middle; line-height: 32px;"
            >
              {{ columnObjects[index].name }}
            </div>
            <!-- <VueSelectStandard
              class="ml-2 text-center text-sm vue-select-container"
              style="width: 100% !important;"
              :data="types"
              v-model="columnObjects[index]"
              @documentListener="selectType"
              :placeholder="'Texto'"
              :listener="'documentListener'"
              :font-class="'database-param-font'"
              :selected-item-id="`item-${index}`"
            /> -->
          </div>
          <div style="width: 3.4%;" />
          <div
            v-if="internalType === 'static'"
            style="width: 5%; margin: auto;"
          >
            <i
              v-if="columnObjects[index].primaryKey"
              class="material-icons blue-color-delete-icon"
              title="Esta columna no permite duplicados"
            >key</i>
            <i
              v-else
              class="material-icons"
              title="Esta columna permite duplicados"
            >lock_open</i>
          </div>
          <div
            v-else
            style="width: 5%; margin: auto;"
          />
          <div style="width: 2.3%;" />
          <div
            style="width: 5.1%; margin: auto;"
          >
            <i
              class="material-icons"
              style="cursor: pointer;"
              :class="{
                'blue-color-delete-icon': !hasMinimumColumns(),
                'grey-color-delete-icon': hasMinimumColumns(),
              }"
              @click="deleteColumn(index)"
            >delete_outline</i>
          </div>
        </div>
        <div
          class="flex flex-row"
          style="margin-bottom: 33.02px;"
        >
          <div style="width: 50.33%;" />
          <a
            class="add-column cursor-pointer"
            style="text-decoration: none; width: 42.8%;"
            @click="addColumn"
          >+ AGREGAR COLUMNA
          </a>
          <div style="width: 6.87%;" />
        </div>
        <div
          v-if="canUpdate"
          class="create-database-btn create-database-btn-font"
          style="margin-bottom: 32.76px;"
          @click="updateDatabase"
        >
          Guardar cambios
        </div>
        <div
          v-else
          class="create-database-btn create-database-btn-font create-disabled"
          style="margin-bottom: 32.76px;"
        >
          Guardar Cambios
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import humps from 'humps';
import $ from 'jquery';
import showMessageMixin from '../mixins/showMessageMixin';
import { CLOSE_APP_MODAL } from '../store/action-types';

const COLUMNS_MINIMUM = 3;

export default {
  mixins: [showMessageMixin],
  props: {
    internalDatabaseData: { type: Object, default: () => {} },
    listener: { type: String, default: 'updateDatabasesListener' },
  },
  data() {
    return {
      name: this.internalDatabaseData.name,
      internalType: this.internalDatabaseData.internalType,
      description: this.internalDatabaseData.description,
      columnObjects: humps.camelizeKeys(this.internalDatabaseData.customAttrs),
    };
  },
  mounted() {
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
  },
  computed: {
    canUpdate() {
      if (
        this.name !== null && this.name.trim() !== '' &&
        !this.columnObjects
          .map(columnObject => columnObject.columnName)
          .some((name) => name === null || name === '') &&
        this.hasNoDuplicates(this.columnObjects
          .map(columnObject => columnObject.columnName))
      ) {
        return true;
      }

      return false;
    },
    types() {
      const internalTypes = ['Fecha', 'Texto', 'Booleano', 'Número', 'Imagen', 'Archivo'];
      const types = internalTypes.map(
        (type) => ({ id: internalTypes.indexOf(type), name: type }));

      return types;
    },
    constructUpdatedDatabase() {
      return {
        name: this.name,
        internalType: this.internalType,
        description: this.description,
        customAttrs: this.columnObjects,
      };
    },
  },
  methods: {
    addColumn() {
      const newId = Math.max(...this.columnObjects.map(column => column.id)) + 1;
      this.columnObjects.push({ columnsName: null, name: 'Texto', id: newId, primaryKey: false });
    },
    deleteColumn(index) {
      if (this.columnObjects.length === COLUMNS_MINIMUM) {
        this.showMessage(`La cantidad mínima de columnas es ${COLUMNS_MINIMUM}`);
      } else {
        this.columnObjects.splice(index, 1);
      }
    },
    hasNoDuplicates(array) {
      if (array.some(value => value === undefined)) {
        return false;
      }
      const duplicates = array.filter((item, index) => array.indexOf(item) !== index);
      if (duplicates.length > 0) {
        this.showMessage('No puedes tener columnas con el mismo nombre');
      }

      return duplicates.length === 0;
    },
    hasMinimumColumns() {
      return this.columnObjects.length === COLUMNS_MINIMUM;
    },
    selectType(typeSelected, itemIndex) {
      const index = parseInt(itemIndex.split('-')[1], 10);
      this.columnObjects[index].name = typeSelected.name;
    },
    updateDatabase() {
      this.$emit(this.listener, this.constructUpdatedDatabase);
      this.close();
    },
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show('databases-edit-modal');
    },
  },
};
</script>

<style scoped>
.internal-type-font {
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
  padding-left: 12.13px;
}

.internal-type-box {
  background: #C7D8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}
</style>
