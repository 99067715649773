<template>
  <div>
    <modal
      name="viewsettings"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header">
        <i class="material-icons app-modal__header-icon">settings</i>
        {{ isTemplate ? $t("view.settings.titleTemplate") : $t("view.settings.title") }}
      </div>
      <div class="app-modal__inputs">
        <div class="custom-app-template-editor__section">
          <div class="custom-app-template-editor__section-header">
            <span class="custom-app-template-editor__section-label">{{ $t("view.settings.variablesTitle") }}</span>
            <button
              id="new-field-btn"
              @click="addNewField"
              class="btn btn--inverted-colors custom-app-template-editor__new-field-btn"
            >
              <i class="material-icons material-icons--blue">add</i>
              {{ $t("view.settings.addVariableButton") }}
            </button>
          </div>
          <table class="custom-app-template-editor__table">
            <thead>
              <th>Nombre</th>
              <th>Tipo</th>
              <th>Valor</th>
              <th>
                Remover
              </th>
            </thead>
            <tbody>
              <tr
                v-for="(field, index) in fields"
                :key="index"
              >
                <td>
                  <input
                    type="text"
                    :value="field.name"
                    @input="field.name = camelizeName($event.target.value)"
                    class="custom-app-template-editor__input"
                  >
                </td>
                <td>
                  <select
                    v-model="field.type"
                    class="custom-app-template-editor__input"
                    @change="field.default = undefined"
                  >
                    <option value="text">
                      Texto
                    </option>
                    <option value="number">
                      Número
                    </option>
                    <option value="list">
                      Lista
                    </option>
                  </select>
                </td>
                <td>
                  <input-by-type
                    v-model="field.default"
                    :type="field.type"
                  />
                </td>
                <td>
                  <button
                    class="icon-button"
                    @click="removeField(index)"
                  >
                    <i class="material-icons">delete</i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <span
        class="custom-app-template-editor__error"
        v-if="errors.fields"
      >
        {{ errors.fields.join("\n") }}
      </span> -->
        </div>
        <div
          v-if="isTemplate"
          class="custom-app-template-editor__section"
        >
          <div class="custom-app-template-editor__section-header">
            <span class="custom-app-template-editor__section-label">Attributos</span>
          </div>
          <div class="custom-app-template-editor__section-content">
            <div class="custom-app-template-editor__section-industry">
              <span>Industria</span>

              <tags-input
                :style="{paddingTop: '1rem'}"
                v-model="industry"
                :tags="selectedIndustries"
                placeholder="Selecciona una industria"
                :autocomplete-items="filteredTags"
                @tags-changed="newTags => selectedIndustries = newTags"
                class="share-resource-modal__input share-resource-modal__input--tags"
              />
            </div>

            <div class="custom-app-template-editor__section-content-description">
              <span> Descripcion</span>
              <textarea
                type="text"
                name="description"
                :style="{height: '145px', resize: 'none'}"
                id="description"
                v-model="description"
                class="custom-app-template-editor__input"
              />
            </div>

            <div class="custom-app-template-editor__section-content-process">
              <label for="process"> Proceso</label>
              <div>
                <!-- <span
                  class="custom-app-template-editor__section-checkbox"
                  :key="process[1]"
                  v-for="process in processOptions"
                >
                  <input
                    v-model="selectedProcess[process[1]]"
                    type="checkbox"
                    :name="process"
                  >
                  <label :for="process">{{ process[0] }}</label>
                </span> -->

                <tags-input
                  :style="{paddingTop: '1rem'}"
                  v-model="process"
                  :tags="selectedProcess"
                  placeholder="Selecciona procesos"
                  :autocomplete-items="filteredProcessTag"
                  @tags-changed="newTags => selectedProcess = newTags"
                  class="share-resource-modal__input share-resource-modal__input--tags"
                />
              </div>
            </div>

            <div class="custom-app-template-editor__section-content-viewing-level">
              <label for="process"> Viewing Level</label>
              <div>
                <!-- <span
                  class="custom-app-template-editor__section-checkbox"
                  :key="viewLevel[1]"
                  v-for="viewLevel in viewingLevelOptions"
                >
                  <input
                    v-model="selectedViewingLevel[viewLevel[1]]"
                    type="checkbox"
                    :name="viewLevel"
                  >
                  <label :for="viewLevel">{{ viewLevel[0] }}</label>
                </span> -->

                <tags-input
                  :style="{paddingTop: '1rem'}"
                  v-model="viewingLevel"
                  :tags="selectedViewingLevel"
                  placeholder="Selecciona una industria"
                  :autocomplete-items="filteredViewingTag"
                  @tags-changed="newTags => selectedViewingLevel = newTags"
                  class="share-resource-modal__input share-resource-modal__input--tags"
                />
              </div>
            </div>

            <div class="custom-app-template-editor__section-content-company-size">
              <label for="process"> Tamaño de la compañia</label>
              <div>
                <span
                  class="custom-app-template-editor__section-checkbox"
                  :key="companySize[1]"
                  v-for="companySize in companySizeOptions"
                >
                  <input
                    v-model="selectedCompanySize[companySize[1]]"
                    type="checkbox"
                    :name="companySize"
                  >
                  <label :for="companySize">{{ companySize[0] }}</label>
                </span>
              </div>
            </div>
            <div class="custom-app-template-editor__section-content-revenue-type">
              <label for="process"> Modelo de Ingresos </label>
              <div>
                <span
                  class="custom-app-template-editor__section-checkbox"
                  :key="rt[1]"
                  v-for="rt in revenueTypeOptions"
                >
                  <input
                    v-model="selectedRevenueType[rt[1]]"
                    type="checkbox"
                    :name="rt"
                  >
                  <label :for="rt">{{ rt[0] }}</label>
                </span>
              </div>
            </div>
            <div class="custom-app-template-editor__section-content-area">
              <label for="process"> Area </label>
              <div>
                <!-- <span
                  class="custom-app-template-editor__section-checkbox"
                  :key="a[1]"
                  v-for="a in areaOptions"
                >
                  <input
                    v-model="selectedArea[a[1]]"
                    type="checkbox"
                    :name="a"
                  >
                  <label :for="a">{{ a[0] }}</label>
                </span> -->
                <tags-input
                  :style="{paddingTop: '1rem'}"
                  v-model="area"
                  :tags="selectedArea"
                  placeholder="Selecciona Areas"
                  :autocomplete-items="filteredAreasTag"
                  @tags-changed="newTags => selectedArea = newTags"
                  class="share-resource-modal__input share-resource-modal__input--tags"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="btn app-modal__save-btn"
        :class="{ 'app-modal__save-btn--disabled': !requiredInputsFilled }"
        @click="save"
      >
        Guardar
      </div>
    </modal>
  </div>
</template>

<script>
import humps from 'humps';
import _ from 'lodash';
import TagsInput from '@johmun/vue-tags-input';
import { mapGetters, mapState } from 'vuex';
import api from '../api';
import { CLOSE_VIEW_SETTINGS_MODAL, UPDATE_SELECTED_VIEW } from '../store/action-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';

const RELOAD_VIEW_TIMEOUT = 1000;

export default {
  mixins: [escapeMixinHandler],
  components: {
    TagsInput,
  },
  data() {
    const viewTemplate = this.$store.state.views.viewTemplate;
    // const selectedProcess = this.getOptionModel(
    //   this.$store.state.views.processOptions,
    //   _.get(viewTemplate, 'process', []),
    // );
    const selectedRevenueType = this.getOptionModel(
      this.$store.state.views.revenueTypeOptions,
      _.get(viewTemplate, 'revenueType', []),
    );
    // const selectedViewingLevel = this.getOptionModel(
    //   this.$store.state.views.viewingLevelOptions,
    //   _.get(viewTemplate, 'viewingLevel', []),
    // );
    const selectedCompanySize = this.getOptionModel(
      this.$store.state.views.companySizeOptions,
      _.get(viewTemplate, 'companySize', []),
    );
    // const selectedArea = this.getOptionModel(
    //   this.$store.state.views.areaOptions,
    //   _.get(viewTemplate, 'area', []),
    // );

    return {
      fields: [],
      description: _.get(viewTemplate, 'description', ''),
      industry: '',
      process: '',
      viewingLevel: '',
      area: '',
      selectedIndustries: _.get(viewTemplate, 'industryList', []).map(ind => ({ text: ind })),
      // selectedProcess,
      selectedProcess: _.get(viewTemplate, 'processList', []).map(ind => ({ text: ind })),
      selectedRevenueType,
      selectedViewingLevel: _.get(viewTemplate, 'viewingLevelList', []).map(ind => ({ text: ind })),
      selectedCompanySize,
      // selectedArea,
      selectedArea: _.get(viewTemplate, 'areaList', []).map(ind => ({ text: ind })),

    };
  },
  mounted() {
    const emptyField = { name: '', type: 'text', default: undefined };
    if (_.isEmpty(_.get(this.selectedView, 'variables', [{ ...emptyField }]))) {
      this.fields = [{ ...emptyField }];
    } else {
      this.fields = _.cloneDeep(_.get(this.selectedView, 'variables', [{ ...emptyField }]));
    }
    this.$modal.show('viewsettings');
  },
  computed: {
    ...mapGetters(['selectedView']),
    ...mapState({
      viewTemplate: state => state.views.viewTemplate,
      industryTags: state => state.views.industryTags,
      companySizeOptions: state => state.views.companySizeOptions,
      revenueTypeOptions: state => state.views.revenueTypeOptions,
      processOptions: state => state.views.processOptions,
      viewingLevelOptions: state => state.views.viewingLevelOptions,
      areaOptions: state => state.views.areaOptions,
    }),
    requiredInputsFilled() {
      return _.every(this.fields, (value) => this.isPresent(value));
    },
    industries() {
      return this.industryTags.map(tag => ({ text: tag }));
    },
    filteredTags() {
      if (this.industry === null || this.industry === undefined) return [];

      return this.industries.filter(ind => ind.text.toLowerCase().indexOf(this.industry.toLowerCase()) !== -1);
    },
    processes() {
      return this.processOptions.map(p => ({ text: p }));
    },
    filteredProcessTag() {
      return this.processes.filter(ind => ind.text.toLowerCase().indexOf(this.process.toLowerCase()) !== -1);
    },
    viewing() {
      return this.viewingLevelOptions.map(p => ({ text: p }));
    },
    filteredViewingTag() {
      return this.viewing.filter(ind => ind.text.toLowerCase().indexOf(this.viewingLevel.toLowerCase()) !== -1);
    },
    areas() {
      return this.areaOptions.map(p => ({ text: p }));
    },
    filteredAreasTag() {
      return this.areas.filter(ind => ind.text.toLowerCase().indexOf(this.area.toLowerCase()) !== -1);
    },
    isTemplate() {
      return !!this.viewTemplate;
    },
  },
  methods: {
    addNewField() {
      this.fields.push({ ...this.emptyField });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    close() {
      this.$store.dispatch(CLOSE_VIEW_SETTINGS_MODAL);
    },
    getSelectedOptions(selected) {
      return Object.keys(selected).filter((s) => selected[s] === true);
    },
    save() {
      if (this.isTemplate) {
        const params = {
          // areaList: this.getSelectedOptions(this.selectedArea),
          areaList: this.selectedArea.map(v => v.text),
          // viewingLevel: this.getSelectedOptions(this.selectedViewingLevel),
          // process: this.getSelectedOptions(this.selectedProcess),
          viewingLevelList: this.selectedViewingLevel.map(v => v.text),
          processList: this.selectedProcess.map(v => v.text),
          revenueType: this.getSelectedOptions(this.selectedRevenueType),
          companySize: this.getSelectedOptions(this.selectedCompanySize),
          industryList: this.selectedIndustries.map(ind => ind.text),
          description: this.description,
        };
        // this.$store.dispatch(UPDATE_SELECTED_VIEW, params).then(() => this.close());
        api.updateTemplate({ id: this.viewTemplate.id, params }).then(() => {
          this.$store.dispatch(UPDATE_SELECTED_VIEW, { variables: this.fields }).then(() => this.close());
        });
      } else {
        this.$store.dispatch(UPDATE_SELECTED_VIEW, { variables: this.fields }).then(() => this.close());
      }
      setTimeout(() => {
        window.location.reload();
      }, RELOAD_VIEW_TIMEOUT);
    },
    camelizeName(name) {
      return humps.camelize(name);
    },
    isPresent(value) {
      return value === 0 || (value && !_.isEqual(value, []));
    },
    getOptionModel(options, selected) {
      if (options === null) return {};
      const optionModel = options.reduce((accumObj, process) => {
        accumObj[process[1]] = false;

        return accumObj;
      }, {});
      selected.map(select => {
        optionModel[select] = true;

        return null;
      });

      return optionModel;
    },
  },
};
</script>
