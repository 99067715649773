import { render, staticRenderFns } from "./tab-app.vue?vue&type=template&id=6564f626&scoped=true&"
import script from "./tab-app.vue?vue&type=script&lang=js&"
export * from "./tab-app.vue?vue&type=script&lang=js&"
import style0 from "./tab-app.vue?vue&type=style&index=0&id=6564f626&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6564f626",
  null
  
)

/* custom blocks */
import block0 from "./tab-app.vue?vue&type=custom&index=0&blockType=link&href=node_modules%2Fgridstack%2Fdist%2Fgridstack.min.css&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports