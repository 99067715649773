import { normalize, schema } from 'normalizr';
import humps from 'humps';
import _ from 'lodash';
import { formatRelative } from 'date-fns';
import api from '../../../api';
import * as mutations from '../../mutation-types';
import * as actions from '../../action-types';
import getLocales from '../../../helpers/date-locales';

const userEntity = new schema.Entity('users');
const commentEntity = new schema.Entity('comments', {
  user: userEntity,
});
const publicationEntity = new schema.Entity('publications', {
  comments: [commentEntity],
});
const appContainerEntity = new schema.Entity('appContainers', {
  comments: [commentEntity],
});
const viewEntity = new schema.Entity('views', {
  usersWithPermission: [userEntity],
  appContainers: [appContainerEntity],
}, {
  idAttribute: 'uuid' // use 'uuid' instead of 'id' as the identifier
});

const folderEntity = new schema.Entity('folders', {
  usersWithPermission: [userEntity],
});

export default {
  [actions.SELECT_FOLDER_FOR_EDITION]({ commit }, folder) {
    commit(mutations.SET_SELECTED_FOLDER, folder);
    commit(mutations.OPEN_FOLDER_MODAL);
  },
  [actions.CLOSE_FOLDER_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_FOLDER, null);
    commit(mutations.CLOSE_FOLDER_MODAL);
  },
  [actions.SAVE_FOLDER]({ state, commit }, params) {
    if (state.selectedFolder) {
      api.updateFolder({ id: state.selectedFolder.id, params }).then((folder) => {
        commit(mutations.UPDATE_SELECTED_FOLDER, folder);
      });
    } else {
      api.createFolder(params).then((folder) => {
        commit(mutations.ADD_FOLDER, folder);
        commit(mutations.ADD_PERSONAL_FOLDER, folder);
      });
    }
  },
  [actions.SET_NESTED_FOLDERS]({ commit }, folders) {
    const camelizedFolders = humps.camelizeKeys(folders);
    const normalizedFolders = normalize(camelizedFolders, [folderEntity]).entities;
    commit(mutations.SET_FOLDERS, normalizedFolders.folders);
    commit(mutations.SET_SELECTED_FOLDER, null);
    commit(mutations.ADD_NORMALIZED_USERS, normalizedFolders.users);
  },
  [actions.SELECT_FOLDER_FOR_DELETION]({ commit }, folder) {
    commit(mutations.SET_SELECTED_FOLDER, folder);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_FOLDER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_FOLDER_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'view.folder.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_FOLDER_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_FOLDER, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SET_NESTED_VIEWS]({ commit }, views) {
    const now = new Date();
    const locale = getLocales(document.documentElement.lang);
    const camelizedViews = humps.camelizeKeys(views).map(view => {
      view.updatedAt = formatRelative(new Date(view.updatedAt), now, { locale });

      return view;
    });
    const normalizedViews = normalize({ views: camelizedViews }, { views: [viewEntity] }).entities;
    commit(mutations.SET_VIEWS, normalizedViews.views);
    commit(mutations.SET_SELECTED_VIEW, null);
    commit(mutations.ADD_NORMALIZED_USERS, normalizedViews.users);
  },
  [actions.SET_NESTED_VIEW]({ commit }, view) {
    const camelizedView = humps.camelizeKeys(view);
    const normalizedViews = normalize(camelizedView, viewEntity).entities;
    commit(mutations.SET_SELECTED_VIEW, normalizedViews.views[view.uuid]);
    commit(mutations.SET_VIEWS, null);
    commit(mutations.ADD_NORMALIZED_USERS, normalizedViews.users);
    commit(mutations.SET_APP_CONTAINERS, normalizedViews.appContainers);
    commit(mutations.ADD_COMMENTS, normalizedViews.comments);
  },
  [actions.SELECT_VIEW_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_VIEW_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_VIEW);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_VIEW_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'view.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.SELECT_VIEW_FOR_SETTINGS]({ commit }, id) {
    commit(mutations.SET_SELECTED_VIEW_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.UPDATE_SELECTED_VIEW);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'view.save');
    commit(mutations.OPEN_VIEW_SETTINGS_MODAL);
  },
  [actions.CLOSE_VIEW_SETTINGS_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_VIEW_ID, null);
    commit(mutations.CLOSE_VIEW_SETTINGS_MODAL);
  },
  [actions.UNSELECT_VIEW_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_VIEW_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_RESOURCE_FOR_SHARING]({ commit }, { resource, resourceType }) {
    commit(mutations.SET_SELECTED_RESOURCE, { resource, resourceType });
    commit(mutations.OPEN_SHARE_RESOURCE_MODAL);
  },
  [actions.UNSELECT_RESOURCE_FOR_SHARING]({ commit }, resourceType) {
    commit(mutations.UNSET_SELECTED_RESOURCE, resourceType);
    commit(mutations.CLOSE_SHARE_RESOURCE_MODAL);
  },
  [actions.SELECT_RESOURCE_FOR_MOVING]({ commit }, { resource, resourceType }) {
    commit(mutations.SET_SELECTED_RESOURCE, { resource, resourceType });
    commit(mutations.OPEN_MOVE_RESOURCE_MODAL);
  },
  [actions.UNSELECT_RESOURCE_FOR_MOVING]({ commit }, { resourceType }) {
    commit(mutations.UNSET_SELECTED_RESOURCE, resourceType);
    commit(mutations.CLOSE_MOVE_RESOURCE_MODAL);
  },
  [actions.SELECT_CONTAINER_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CONTAINER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CONTAINER_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'Eliminar contenedor');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.SELECT_TAB_CONTAINER_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_TAB_CONTAINER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CONTAINER_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'Eliminar contenedor');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },

  [actions.SELECT_CONTAINER_FOR_DELETION_WITH_LABEL]({ commit }, params) {
    const { id, label } = params;
    commit(mutations.SET_SELECTED_CONTAINER_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CONTAINER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CONTAINER_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, label);
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_CONTAINER_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_CONTAINER_FOR_EDITION]({ commit, dispatch }, container) {
    dispatch(actions.CLOSE_APP_MODAL);
    commit(mutations.SET_SELECTED_CONTAINER_ID, container.id);
    commit(mutations.SET_SELECTED_CONTAINER, container);
    commit(mutations.SET_APP_MODAL_TYPE, container.appType);
    if (container.app.dataSource) {
      commit(mutations.SET_SELECTED_DATA_SOURCE, container.app.dataSource);
    }
    if (typeof container.app.customAppTemplate !== 'undefined') {
      commit(mutations.SET_APP_MODAL_NAME, container.app.customAppTemplate.name);
    }
  },
  [actions.OPEN_SAVE_ALERT]({ commit }) {
    commit(mutations.SET_CONFIRMATION_ACTION, actions.UNSELECT_CONTAINER_FOR_EDITION);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.CLOSE_AND_SAVE_ALERT);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'No guardar cambios en App');
    commit(mutations.OPEN_UNSAVED_CHANGES_MODAL);
  },
  [actions.CLOSE_AND_SAVE_ALERT]({ state, commit }) {
    commit(mutations.SET_SHOULD_SAVE, { containerId: state.selectedContainerId, value: true });
    commit(mutations.CLOSE_UNSAVED_CHANGES_MODAL);
  },
  [actions.SELECT_CHECKED_FILTER_INDEX_FOR_DELETION]({ commit }, index) {
    commit(mutations.SET_SELECTED_CHECKED_FILTER_INDEX, index);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CHECKED_FILTER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CHECKED_FILTER_INDEX_FOR_DELETION);
    commit(mutations.OPEN_DELETE_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_CHECKED_FILTER_INDEX_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_CHECKED_FILTER_INDEX, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.CLOSE_DELETE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_CHECKED_FILTER]({ state, commit }) {
    commit(mutations.REMOVE_FILTER_CHECKED, state.selectedCheckedFilterIndex);
    commit(mutations.CLOSE_DELETE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION]({ commit }, index) {
    commit(mutations.SET_SELECTED_UNCHECKED_FILTER_INDEX, index);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_UNCHECKED_FILTER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION);
    commit(mutations.OPEN_DELETE_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_UNCHECKED_FILTER_INDEX, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.CLOSE_DELETE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_UNCHECKED_FILTER]({ state, commit }) {
    commit(mutations.REMOVE_FILTER_UNCHECKED, state.selectedUncheckedFilterIndex);
    commit(mutations.CLOSE_DELETE_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_CONTAINER_FOR_EDITION]({ commit, dispatch }) {
    commit(mutations.CLOSE_UNSAVED_CHANGES_MODAL);
    dispatch(actions.CLOSE_APP_MODAL);
    commit(mutations.SET_SELECTED_CONTAINER_ID, null);
    commit(mutations.SET_SELECTED_CONTAINER, null);
    commit(mutations.SET_APP_MODAL_TYPE, null);
    commit(mutations.SET_SELECTED_DATA_SOURCE, null);
    commit(mutations.SET_APP_MODAL_NAME, null);
    commit(mutations.SAVE_ALERT, false);
  },
  [actions.SELECT_CONTAINER_FOR_INITIALIZATION]({ commit }, appType) {
    // commit(mutations.SET_SELECTED_CONTAINER_ID, container.id);
    // commit(mutations.SET_SELECTED_CONTAINER, container);
    commit(mutations.SET_APP_MODAL_TYPE, appType);
  },
  [actions.CLOSE_APP_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, null);
    commit(mutations.SET_SELECTED_CONTAINER, null);
    commit(mutations.SET_APP_MODAL_TYPE, null);
    commit(mutations.SET_SELECTED_CUSTOM_APP_TEMPLATE, null);
    // commit(mutations.UNSET_SELECTED_DATA_SOURCE);
    commit(mutations.SET_SELECTED_DATA_SOURCE, null);
  },
  [actions.SELECT_CONTAINER_FOR_FULLSCREEN]({ commit }, containerId) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, containerId);
    commit(mutations.OPEN_FULLSCREEN_APP_MODAL);
  },
  [actions.UNSELECT_CONTAINER_FOR_FULLSCREEN]({ commit }) {
    commit(mutations.SET_SELECTED_CONTAINER_ID, null);
    commit(mutations.CLOSE_FULLSCREEN_APP_MODAL);
  },
  [actions.SELECT_CUSTOM_APP_TEMPLATE_FOR_DELETION]({ commit }, template) {
    commit(mutations.SET_SELECTED_CUSTOM_APP_TEMPLATE, template);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CUSTOM_APP_TEMPLATE);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CUSTOM_APP_TEMPLATE);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'customAppTemplate.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_CUSTOM_APP_TEMPLATE]({ commit }) {
    commit(mutations.SET_SELECTED_CUSTOM_APP_TEMPLATE, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_CUSTOM_APP_TEMPLATE_FOR_DISABLE]({ commit }, template) {
    commit(mutations.SET_SELECTED_CUSTOM_APP_TEMPLATE, template);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DISABLE_SELECTED_CUSTOM_APP_TEMPLATE);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CUSTOM_APP_TEMPLATE);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'customAppTemplate.disable');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.DISABLE_SELECTED_CUSTOM_APP_TEMPLATE]({ dispatch }) {
    dispatch(actions.UPDATE_SELECTED_CUSTOM_APP_TEMPLATE, { params: { disabled: true } });
  },
  async [actions.REORDER_CONTAINERS]({ dispatch }, { containers, initialOrder }) {
    for (let index = 0; index < containers.length; index++) {
      const newOrderInRow = index + initialOrder;
      if (containers[index].orderInRow !== newOrderInRow) {
        await dispatch(
          actions.SAVE_CONTAINER,
          { params: { orderInRow: newOrderInRow }, containerId: containers[index].id },
        );
      }
    }
  },
  async [actions.HANDLE_APP_LEAVING_ROW]({ dispatch, getters }, { row, otherContainersInOldRow }) {
    if (row && getters.rowCount(row) === 0) {
      const containersToMove = _.orderBy(getters.containersWithGteRow(row + 1), 'row', 'asc');
      for (let index = 0; index < containersToMove.length; index++) {
        await dispatch(
          actions.SAVE_CONTAINER,
          { params: { row: containersToMove[index].row - 1 }, containerId: containersToMove[index].id },
        );
      }
    } else {
      await dispatch(actions.REORDER_CONTAINERS, { containers: otherContainersInOldRow, initialOrder: 1 });
    }
  },
  async [actions.CORRECT_APP_GRID_ORDER]({ dispatch, getters }) {
    let newRow = 1;
    let newOrderInRow = 1;
    let lastOldRow;
    const containers = getters.orderedContainers;

    function moveToNextRowIfNeeded(container) {
      if (lastOldRow && lastOldRow !== container.row) {
        newRow++;
        newOrderInRow = 1;
      }
    }

    async function correctContainerOrderIfNeeded(container) {
      const params = {};

      if (newRow !== container.row) params.row = newRow;
      if (newOrderInRow !== container.orderInRow) params.orderInRow = newOrderInRow;
      if (!_.isEmpty(params)) await dispatch(actions.SAVE_CONTAINER, { params, containerId: container.id });
    }

    async function calculatePositionAndCorrectIfNeeded(index) {
      const container = containers[index];

      moveToNextRowIfNeeded(container);
      await correctContainerOrderIfNeeded(container);

      lastOldRow = container.row;
      newOrderInRow++;
    }

    for (let index = 0; index < containers.length; index++) {
      await calculatePositionAndCorrectIfNeeded(index);
    }
  },
  [actions.SELECT_COMMENT_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_COMMENT_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_COMMENT);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_COMMENT_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'view.apps.feed.deleteComment');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_COMMENT_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_COMMENT_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_VIEW]({ state, commit, dispatch }) {
    api.deleteView(state.selectedId).then(() => {
      commit(mutations.REMOVE_VIEW);
      dispatch(actions.UNSELECT_VIEW_FOR_DELETION);
    });
  },
  [actions.DELETE_SELECTED_FOLDER]({ state, commit, dispatch }) {
    api.deleteFolder(state.selectedFolder.id).then(() => {
      commit(mutations.REMOVE_FOLDER);
      dispatch(actions.UNSELECT_FOLDER_FOR_DELETION);
    });
  },
  [actions.UPDATE_SELECTED_VIEW]({ commit, getters }, params) {
    return api.updateView({ id: getters.selectedView.uuid, params }).then((view) => {
      commit(mutations.UPDATE_SELECTED_VIEW, normalize(view, viewEntity).entities.views[view.uuid]);
    });
  },
  [actions.CREATE_RESOURCE_PERMISSIONS]({ commit }, { resource, emails, tagList }) {
    const params = { resourceId: resource.uuid, resourceType: "View", emails, tagList };

    return api.createResourcePermissions(params).then((response) => {
      response.resourcePermissions.filter(permission => permission.user.id)
        .forEach((permission) => {
          commit(mutations.ADD_USER, permission.user);
          const permitParams = { userId: permission.user.id, resourceType: resource.type };
          commit(mutations.PERMIT_USER_IN_SELECTED_RESOURCE, permitParams);
        });
      if (response.resource.type === 'View') {
        commit(mutations.UPDATE_SELECTED_VIEW,
          normalize(response.resource, viewEntity).entities.views[response.resource.uuid]);
      } else {
        // commit(mutations.UPDATE_SELECTED_FOLDER, response.resource);
        window.location.reload();
      }
    });
  },
  [actions.DELETE_RESOURCE_PERMISSIONS]({ commit }, { resource, userIds, tagList }) {
    const params = { resourceId: resource.id, resourceType: resource.type, userIds, tagList };

    return api.deleteResourcePermissions(params).then((response) => {
      response.resourcePermissions.forEach(permission => {
        const unPermitParams = { userId: permission.userId, resourceType: resource.type };
        commit(mutations.UNPERMIT_USER_IN_SELECTED_RESOURCE, unPermitParams);
      });

      if (response.resource.type === 'View') {
        commit(mutations.UPDATE_SELECTED_VIEW,
          normalize(response.resource, viewEntity).entities.views[response.resource.uuid]);
      } else {
        // commit(mutations.UPDATE_SELECTED_FOLDER, response.resource);
        window.location.reload();
      }
    });
  },
  async [actions.DELETE_SELECTED_CONTAINER]({ state, commit, dispatch, getters }) {
    // const row = getters.selectedContainer.row;
    await api.deleteAppContainer(state.selectedContainerId).then(() => {
      commit(mutations.REMOVE_CONTAINER);
      // dispatch(actions.HANDLE_APP_LEAVING_ROW, { row, otherContainersInOldRow: getters.containersInRow(row) });
      dispatch(actions.UNSELECT_CONTAINER_FOR_DELETION);
    });
  },
  async [actions.DELETE_SELECTED_TAB_CONTAINER]({ state, commit, dispatch }) {
    await api.deleteAppContainer(state.selectedContainerId).then(() => {
      commit(mutations.REMOVE_TAB_CONTAINER);
      dispatch(actions.UNSELECT_CONTAINER_FOR_DELETION);
    });
  },
  // eslint-disable-next-line max-statements
  async [actions.SAVE_CONTAINER]({ state, commit }, { params, containerId }) {
    let container;
    if (params.app && params.app.dataSource) {
      let dataSource;
      if (params.app.dataSource.service !== 'custom') {
        const dataSourceId = _.get(params.app.dataSource, 'id', null);
        if (dataSourceId) {
          dataSource = await api.updateDataSource({ id: dataSourceId, params: params.app.dataSource });
        } else {
          dataSource = await api.createDataSource(params.app.dataSource);
        }
        params.app.dataSource = dataSource;
        commit(mutations.SET_SELECTED_DATA_SOURCE, dataSource);
      }
    }
    if (containerId) {
      container = await api.updateAppContainer({ params, id: containerId });
      const normalizedContainer = normalize(container, appContainerEntity).entities;
      commit(mutations.UPDATE_CONTAINER, normalizedContainer.appContainers[container.id]);
    } else {
      container = await api.createAppContainer({ params, viewId: state.selected.uuid });
    }
    const normalizedContainer = normalize(container, appContainerEntity).entities;
    // Basically if this continer is part of of an TabApp
    commit(mutations.ADD_CONTAINER, normalizedContainer.appContainers[container.id]);

    return state.appContainers[container.id];
  },
  [actions.UNDO_CACHE]({ commit }, { container }) {
    const normalizedContainer = normalize(container, appContainerEntity).entities;
    commit(mutations.UPDATE_CONTAINER, normalizedContainer.appContainers[container.id]);
    commit(mutations.UPDATE_APP_FLOATING, container.id);
  },
  [actions.REDO_CACHE]({ commit }, { container }) {
    const normalizedContainer = normalize(container, appContainerEntity).entities;
    commit(mutations.ADD_CONTAINER, normalizedContainer.appContainers[container.id]);
    commit(mutations.UPDATE_APP_FLOATING, container.id);
  },
  [actions.ADD_COPY_CONTAINER]({ commit }, containerCopy) {
    const normalizedContainer = normalize(containerCopy, appContainerEntity).entities;
    commit(mutations.UPDATE_CONTAINER, normalizedContainer.appContainers[containerCopy.id]);
    commit(mutations.UPDATE_APP_FLOATING, containerCopy.id);
  },
  [actions.GET_THREAD_COMMENTS]({ commit }, parentCommentId) {
    return api.getThreadComments(parentCommentId).then(response => {
      const normalizedComments = normalize(response.comments, [commentEntity]).entities;
      commit(mutations.ADD_COMMENTS, normalizedComments.comments);
      commit(mutations.SET_THREAD_COMMENTS_TO_COMMENT, {
        threadCommentIds: _.keys(normalizedComments.comments), parentCommentId,
      });
      commit(mutations.ADD_NORMALIZED_USERS, normalizedComments.users);
    });
  },
  [actions.CREATE_APP_CONTAINER_COMMENT]({ commit }, { content, containerId }) {
    return api.createAppContainerComment({ containerId, content }).then((comment) => {
      const normalizedComment = normalize(comment, commentEntity).entities;
      commit(mutations.ADD_COMMENT, normalizedComment.comments[comment.id]);
      commit(mutations.ADD_COMMENT_TO_CONTAINER, { commentId: comment.id, containerId });
      commit(mutations.ADD_USER, normalizedComment.users[comment.user.id]);
    });
  },
  [actions.CREATE_THREAD_COMMENT]({ commit }, { content, parentCommentId }) {
    return api.createThreadComment({ parentCommentId, content }).then((comment) => {
      const normalizedComment = normalize(comment, commentEntity).entities;
      commit(mutations.ADD_COMMENT, normalizedComment.comments[comment.id]);
      commit(mutations.ADD_THREAD_COMMENT_TO_COMMENT, { threadCommentId: comment.id, parentCommentId });
      commit(mutations.ADD_USER, normalizedComment.users[comment.user.id]);
    });
  },
  [actions.UPDATE_SELECTED_COMMENT]({ state, commit }, params) {
    return api.updateComment({ id: state.selectedCommentId, params }).then((comment) => {
      const normalizedComment = normalize(comment, commentEntity).entities;
      commit(mutations.ADD_COMMENT, normalizedComment.comments[comment.id]);
    });
  },
  [actions.DELETE_SELECTED_COMMENT]({ state, commit, dispatch }) {
    api.deleteComment(state.selectedCommentId).then(() => {
      commit(mutations.REMOVE_COMMENT);
      dispatch(actions.UNSELECT_COMMENT_FOR_DELETION);
    });
  },
  [actions.DELETE_SELECTED_PUBLICATION_COMMENT]({ state, commit, dispatch }) {
    api.deleteComment(state.selectedCommentId).then(() => {
      commit(mutations.REMOVE_PUBLICATION_COMMENT);
      dispatch(actions.UNSELECT_COMMENT_FOR_DELETION);
    });
  },
  [actions.SELECT_PUBLICATION_COMMENT_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_COMMENT_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_PUBLICATION_COMMENT);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_COMMENT_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'Borrar comentario');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.CREATE_APP_CONTAINER_PUBLICATION]({ commit }, { content, containerId, fileUrl, file }) {
    return api.createAppContainerPublication({ containerId, content, fileUrl, file }).then((publication) => {
      const normalizedPublication = normalize(publication, publicationEntity).entities;
      commit(mutations.ADD_PUBLICATION, normalizedPublication.publications[publication.id]);
    });
  },
  [actions.CREATE_PUBLICATION_COMMENT]({ commit }, { content, publicationId }) {
    return api.createPublicationComment({ publicationId, content }).then((comment) => {
      commit(mutations.ADD_PUBLICATION_COMMENT, {
        publicationId, comment,
      });
    });
  },
  [actions.CREATE_LINK_METADATA]({ commit }, { url, publicationId }) {
    return api.createPublicationLinkMetadata({ url }).then((metadata) => {
      commit(mutations.ADD_LINK_PREVIEW_METADATA, { publicationId, metadata });
    });
  },
  [actions.UPDATE_SELECTED_PUBLICATION]({ state, commit }, params) {
    return api.updatePublication({ id: state.selectedPublicationId, params }).then((publication) => {
      commit(mutations.ADD_PUBLICATION, publication);
    });
  },
  [actions.UPDATE_SELECTED_PUBLICATION_COMMENT]({ state, commit }, params) {
    return api.updateComment({ id: state.selectedCommentId, params }).then((comment) => {
      commit(mutations.UPDATE_PUBLICATION_COMMENT, {
        publicationComment: comment,
      });
    });
  },
  [actions.SELECT_PUBLICATION_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_PUBLICATION_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_PUBLICATION);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_PUBLICATION_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'Eliminar publicación');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_PUBLICATION_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_PUBLICATION_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_PUBLICATION]({ state, commit, dispatch }) {
    api.deletePublication(state.selectedPublicationId).then(() => {
      commit(mutations.REMOVE_PUBLICATION);
      dispatch(actions.UNSELECT_PUBLICATION_FOR_DELETION);
    });
  },
  [actions.GET_PUBLICATION_COMMENTS]({ commit }, publicationId) {
    return api.getPublicationComments(publicationId).then(response => {
      const normalizedComments = normalize(response.comments, [commentEntity]).entities;
      commit(mutations.ADD_COMMENTS, normalizedComments.comments);
      commit(mutations.SET_COMMENTS_TO_PUBLICATION, {
        publicationCommentIds: _.keys(normalizedComments.comments), publicationId,
      });
      commit(mutations.ADD_NORMALIZED_USERS, normalizedComments.users);
    });
  },
  [actions.GET_PUBLICATION_THREAD_COMMENTS]({ commit }, parentCommentId) {
    return api.getThreadComments(parentCommentId).then(response => {
      const comments = response.comments;
      if (comments.length > 0) {
        commit(mutations.ADD_PUBLICATION_THREAD_COMMENTS, { comments, commentId: parentCommentId });
      }
    });
  },
  [actions.CREATE_PUBLICATION_THREAD_COMMENT]({ commit }, { content, parentCommentId }) {
    return api.createThreadComment({ parentCommentId, content }).then((comment) => {
      commit(mutations.ADD_PUBLICATION_THREAD_COMMENT, {
        parentCommentId, comment,
      });
    });
  },
  [actions.SELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_PUBLICATION_THREAD_COMMENT);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'Eliminar comentario');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_PUBLICATION_THREAD_COMMENT_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.DELETE_SELECTED_PUBLICATION_THREAD_COMMENT]({ state, commit, dispatch }) {
    api.deleteComment(state.selectedPublicationThreadCommentId).then(() => {
      commit(mutations.REMOVE_PUBLICATION_THREAD_COMMENT);
      dispatch(actions.UNSELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION);
    });
  },
  [actions.UPDATE_SELECTED_PUBLICATION_THREAD_COMMENT]({ state, commit }, params) {
    return api.updateComment({ id: state.selectedPublicationThreadCommentId, params }).then((comment) => {
      commit(mutations.UPDATE_PUBLICATION_THREAD_COMMENT, {
        publicationThreadComment: comment,
      });
    });
  },
  [actions.MORE_PUBLICATIONS]({ commit }, { containerId, clicks }) {
    return api.getMorePublications({ containerId, clicks }).then((publications) => {
      commit(mutations.ADD_MORE_PUBLICATIONS, { publications: publications.publications });
    });
  },
  [actions.DELETE_SELECTED_CUSTOM_APP_TEMPLATE]({ state, commit, dispatch }) {
    api.deleteCustomAppTemplate(state.selectedCustomAppTemplate.id).then(() => {
      commit(mutations.REMOVE_CUSTOM_APP_TEMPLATE);
      dispatch(actions.UNSELECT_CUSTOM_APP_TEMPLATE);
    });
  },
  [actions.UPDATE_SELECTED_CUSTOM_APP_TEMPLATE]({ state, commit, dispatch }, { id, params }) {
    api.updateCustomAppTemplate({ id: id || state.selectedCustomAppTemplate.id, params }).then((customAppTemplate) => {
      commit(mutations.UPDATE_SELECTED_CUSTOM_APP_TEMPLATE, customAppTemplate);
      dispatch(actions.UNSELECT_CUSTOM_APP_TEMPLATE);
    });
  },
  [actions.OPEN_PREVIEW_TEMPLATE_APP]({ commit }, modalStatus) {
    commit(mutations.SET_PREVIEW_TEMPLATE_APP_OPEN, modalStatus);
  },
  [actions.OPEN_BIGSTORE_MODAL]({ commit }) {
    commit(mutations.SET_BIGSTORE_OPEN_MODAL, true);
  },
  [actions.CLOSE_BIGSTORE_MODAL]({ commit }) {
    commit(mutations.SET_BIGSTORE_OPEN_MODAL, false);
  },
  [actions.TOOGLE_TOOLBAR_COLLAPSIBLE]({state, commit}) {
    commit(mutations.SET_TOOGLE_TOOLBAR_COLLAPSIBLE, state.collapsibleIsOpen )
  },
  [actions.UPDATE_FILENAMES]({ commit }, { params }) {
    commit(mutations.SET_FILENAMES, params);
  },
  [actions.UPDATE_FILE_URLS]({ commit }, { params }) {
    commit(mutations.SET_FILE_URLS, params);
  },
  [actions.UPDATE_FILENAMES_IN_APP]({ commit }, { params }) {
    commit(mutations.SET_FILENAMES_IN_APP, params);
  },
  [actions.SET_APP_ATTRIBUTES]({ commit }, { containerId, params }) {
    commit(mutations.SET_APP_ATTRIBUTES, { containerId, params });
  },
  [actions.SET_SHOULD_SAVE]({ commit }, { containerId, value }) {
    commit(mutations.SET_SHOULD_SAVE, { containerId, value });
  },
  async [actions.UPDATE_APP_CONTAINERS_IN_MAIN_GRID_AND_TABS]({ commit }, { tabId, params, containerId }) {
    const response = await api.updateAppContainerFromMainGridToTab(
      { tabId, params, containerId });
    console.log('response a updateAppContainerFromMainGridToTab', response);
  },
  async [actions.UPDATE_APP_CONTAINERS_FROM_TAB_TO_MAIN_GRID]({ commit }, { tabId, params, containerId }) {
    const response = await api.updateAppContainerFromTabToMainGrid(
      { tabId, params, containerId });
    console.log('response a updateAppContainerFromMainGridToTab', response);
  },
};
