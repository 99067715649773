<template>
  <div class="bg-white">
    <app-options
      :header-text="template.name"
      :app-filled="requiredAppInputsFilled"
      :app-with-type="appWithType"
      :modal-width="700"
    >
      <new-data-sources-selector
        :current-datasource="selectedDataSource"
        :columns-checked="fieldValues.columns"
      />
      <div class="p-3">
        <template v-for="(field, index) in template.fields">
          <input-by-type
            :field-name="capitalize(field.name)"
            v-model="fieldValues[field.name]"
            :type="field.type"
            size=""
            @input="autoSaveField"
            :key="`input-${index}`"
          />
        </template>
      </div>
    </app-options>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import NewDataSourcesSelector from './new-data-sources-selector';
import { SAVE_CONTAINER, OPEN_DATA_SOURCE_MODAL } from '../store/action-types';
import { UNSET_SELECTED_DATA_SOURCE } from '../store/mutation-types';
import AppOptions from './app-options.vue';

const START_INDEX = 2;
const END_INDEX = 4;
const NO_VIEW_VAR_SELECTED = 'Ninguna variable de vista seleccionada';
const DEBOUNCE_TIME = 1000;

export default {
  components: {
    NewDataSourcesSelector,
    AppOptions,
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const initialFieldValues = {};
    const initialViewVariablesForSelection = {};
    template.fields.forEach((field) => {
      initialFieldValues[field.name] = container ? container.app.fieldValues[field.name] : null;
      initialViewVariablesForSelection[field.name] = '';
    });

    return {
      fieldValues: initialFieldValues,
      viewVariablesForSelection: initialViewVariablesForSelection,
      template,
      tabs: ['Data Sources', 'Custom Fields'],
      selectedTab: 'DataSourcesTab',
    };
  },
  // mounted() {
  //   this.$store.commit(SET_SELECTED_DATA_SOURCE, this.selectedContainer.app.dataSource);
  //   console.log('selectedDataSource is', this.selectedDataSource);
  // },
  computed: {
    appWithType() {
      return {
        app: {
          fieldValues: this.fieldValues,
          customAppTemplateId: this.template.id,
          dataSource: this.selectedDataSource,
        },
        appType: 'CustomApp',
      };
    },
    requiredAppInputsFilled() {
      return (this.selectedDataSource !== null &&
          _.every(this.fieldValues, (value) => this.isPresent(value))
      );
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      dataSourceModalOpened: state => state.ui.dataSourceModalOpened,
      selectedDataSource: state => state.users.selectedDataSource,
    }),
  },
  methods: {
    capitalize(text) {
      return text[0].toUpperCase() + text.substring(1);
    },
    getDataSourceName(tab) {
      return tab.includes('Sources') ? 'DataSourcesTab' : 'CustomFieldsTab';
    },
    selectTab(tab) {
      this.selectedTab = this.getDataSourceName(tab);
    },
    removeDataSourceFromApp() {
      this.$store.commit(UNSET_SELECTED_DATA_SOURCE);
    },
    autoSaveField: _.debounce(function () {
      this.saveFields();
    }, DEBOUNCE_TIME),
    showDataSourceInputsWhenConnected() {
      if (this.goToConnectionsIfNecessary()) {
        this.$store.dispatch(OPEN_DATA_SOURCE_MODAL);
      }
    },
    goToConnectionsIfNecessary() {
      if (this.connections.length === 0) {
        this.showMessage('No tienes ninguna conexion, primero debes crear una');
        setTimeout(() => {
          window.location.href = '/connections';
        }, 2500);

        return false;
      }

      return true;
    },
    saveFields() {
      if (this.selectedContainerId === null || this.selectedContainerId === undefined) return;
      const fieldValues = this.fieldValues;
      const containerId = this.selectedContainerId;
      const params = { app: { fieldValues } };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    getViewVariableRef(vref) {
      if (vref === null) {
        return vref;
      }
      if (vref.startsWith('{{') && vref.endsWith('}}')) {
        return vref.substr(START_INDEX, vref.length - END_INDEX).trim();
      }

      return vref;
    },
    isPresent(value) {
      return value === 0 || (value && !_.isEqual(value, []));
    },
    getViewVarRefValue(field) {
      const selected = _.find(
        this.selectedView.variables,
        (variable) => variable.name === this.getViewVariableRef(this.fieldValues[field.name]),
      );
      if (selected !== undefined) {
        return selected.default;
      }

      return NO_VIEW_VAR_SELECTED;
    },
    showMistmachTypeAlert(selected, field) {
      // eslint-disable-next-line no-alert
      alert(`El el tipo de la variable de vista(${selected.type}) no coincide con el tipo asignado al custom field (${field.type})`);
    },
    validateTypes(field) {
      const selected = _.find(
        this.selectedView.variables,
        (variable) => variable.name === this.viewVariablesForSelection[field.name]);

      if (selected !== undefined) {
        if (selected.type === field.type) {
          this.fieldValues[field.name] = `{{ ${this.viewVariablesForSelection[field.name]} }}`;
        } else {
          this.showMistmachTypeAlert(selected, field);
        }
      }
    },
  },
};
</script>
