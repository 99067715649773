
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

export default function getLocales(locale) {
  if (locale.startsWith('es')) {
    return es;
  } else if (locale.startsWith('en')) {
    return en;
  }

  return en;
}

