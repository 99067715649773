<template>
  <div
    v-if="container && !editable"
    ref="SourceApp"
  >
    <div
      :id="titleID"
      style="padding-left: 15px;"
      v-if="hasDescriptiveText"
      v-html="descriptiveText"
      :class="{
        'move-down-title': descriptiveTextOverlap,
      }"
    />
  </div>
  <div
    v-else
    ref="SourceApp"
  >
    <vue-editor
      v-if="hasDescriptiveText"
      style="padding-left: 15px;"
      :id="editorId"
      v-model="descriptiveText"
      :editor-toolbar="customToolbar"
      @text-change="autoSaveDescriptiveText"
      @focus="toolbarShow()"
      @blur="toolbarHide()"
      :class="{
        'move-down-title': descriptiveTextOverlap,
      }"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';
import { VueEditor } from 'vue2-editor';
import Quill from 'quill';
import customToolbarHelper from '../helpers/custom-toolbar';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';

const DEBOUNCE_TIME = 1000;
const DEFAULT_ADJUSTMENT = 100;

export default {
  components: {
    VueEditor,
  },
  props: {
    height: { type: String, default: '200px' },
    container: { type: Object, default: null },
    editable: { type: Boolean, default: false },
  },
  data() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
    'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
      'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
      'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
      'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
    ];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = [
      '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
    ];
    Quill.register(Size, true);
    this.defineSourceAppAttrs();

    return {
      sourceContent: _.get(this.container, 'app.content', ''),
      containerID: _.get(this.container, 'id', -1),
      delayTimeout: null,
      intervalId: null,
      pristine: true,
      iframeHeight: '200px',
      customToolbar: customToolbarHelper.customToolbar,
      borderColor: _.get(this.container, 'borderColor', ''),
      showShading: _.get(this.container, 'showShading', false),
      commentsEnabled: _.get(this.container, 'commentsEnabled', false),
      isPrivate: _.get(this.container, 'isPrivate', false),
      title: _.get(this.container, 'title', ''),
      showTransparency: _.get(this.container, 'showTransparency', false),
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#ffffff'),
      initialDescriptiveText: _.get(this.container, 'app.descriptiveText', 'Título...'),
      descriptiveText: _.get(this.container, 'app.descriptiveText', 'Título...'),
    };
  },
  mounted() {
    this.colorSelected();
    $('.ql-toolbar.ql-snow').hide();
    const textQlEditor = `${this.textEditor} .ql-editor`;
    $(textQlEditor).attr('style',
      `min-height: 0px !important; padding-left: 0px; padding-right: 15.4px;
      padding-bottom: 0px; padding-top: 0px;`,
    );
  },
  computed: {
    frame() {
      return this.$refs.preview;
    },
    sourceID() {
      return `source${this.containerID}`;
    },
    titleID() {
      return `source-title${this.containerID}`;
    },
    editorId() {
      return `text-editor${this.containerID}`;
    },
    iframeAppContainer() {
      return `source-iframe${this.containerID}`;
    },
    textEditor() {
      return `#${this.editorId}`;
    },
    appWithType() {
      return {
        app: { content: this.sourceContent },
        appType: 'SourceApp',
      };
    },
    requiredAppInputsFilled() {
      return !!this.sourceContent;
    },
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    hasDescriptiveText() {
      return this.appAttrs[this.container.id].hasDescriptiveText;
    },
    descriptiveTextOverlap() {
      return this.appAttrs[this.container.id].descriptiveTextOverlap;
    },
    contentAlignment() {
      return _.get(this.container, 'app.contentAlignment', 'center');
    },
    contentAdjustment() {
      return _.get(this.container, 'app.contentAdjustment', DEFAULT_ADJUSTMENT);
    },
    HtmlRendererID() {
      return `renderer${this.containerID}`;
    },
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
    }),
  },
  methods: {
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
    },
    applyBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID);
      }
    },
    autoSaveDescriptiveText: _.debounce(function () {
      this.updateDescriptiveTextContent();
    }, DEBOUNCE_TIME),
    updateDescriptiveTextContent() {
      if (this.initialDescriptiveText !== this.descriptiveText) {
        const params = { app: { descriptiveText: this.descriptiveText }, appType: 'SourceApp' };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.containerID });
        const initialDescriptiveText = this.descriptiveText;
        this.initialDescriptiveText = initialDescriptiveText;
      }
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.textEditor).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow() {
      $(this.textEditor).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    defineSourceAppAttrs() {
      const params = {
        hasDescriptiveText: _.get(this.container, 'app.hasDescriptiveText', false),
        descriptiveTextOverlap: _.get(this.container, 'app.descriptiveTextOverlap', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
  },
};
</script>

<style>

.show-bg-transparent {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.app-container__content .ql-editor {
  padding: 0px 0px !important;
}

.ql-tooltip {
  z-index: 100 !important;
}

.move-down-title {
  transform: translateY(50%);
  z-index: 100;
  position: absolute;
}

</style>
