<template>
  <input
    v-if="type === 'number'"
    :disabled="readOnly"
    type="number"
    :value="value"
    :placeholder="fieldName"
    :class="'custom-app-template-editor__input' + size"
    @input="updateValue(Number($event.target.value))"
  >
  <tags-input
    v-else-if="type === 'list'"
    :disabled="readOnly || !type"
    v-model="listTempValue"
    :tags="arrayValueInTagFormat"
    :add-on-key="addTagKeys"
    placeholder="+"
    @tags-changed="newTags => updateValue(newTags.map(email => email.text))"
    :class="'custom-app-template-editor__input' + size"
    :delete-on-backspace="false"
  />
  <input
    v-else
    :disabled="readOnly || !type"
    type="text"
    :value="value"
    :placeholder="fieldName"
    :class="'custom-app-template-editor__input' + size"
    @input="updateValue($event.target.value)"
  >
</template>

<script>

import TagsInput from '@johmun/vue-tags-input';
import { KEY_CODES } from '../../constants';

export default {
  components: {
    TagsInput,
  },
  props: {
    type: { type: String, default: null },
    value: { type: [String, Number, Array], default: null },
    size: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    fieldName: { type: String, defaul: null },
  },
  data() {
    return {
      listTempValue: '',
      addTagKeys: [KEY_CODES.ENTER, KEY_CODES.TAB],
    };
  },
  computed: {
    arrayValueInTagFormat() {
      return Array.isArray(this.value) ? this.value.map(text => ({ text })) : [];
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>
