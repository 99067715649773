<template>
  <div>
    <modal
      name="share-resource"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal share-resource-modal']"
    >
      <div
        class="share-resource-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="share-resource-modal__header">
        <i class="material-icons share-resource-modal__header-icon">share</i>
        {{ this.title ? `"${this.title}"` : $t(resource.noTitle) }}
      </div>
      <div class="share-resource-modal__content">
        <div
          class="share-resource-modal__input-header"
          v-if="resource.type === 'View'"
        >
          {{ $t('view.share.publicLink') }}
          <toggle-button
            color="#5157ff"
            class="share-resource-modal__toggle-btn"
            :value="publicShare"
            :sync="true"
            @input="togglePublicShare"
          />
        </div>
        <div
          class="share-resource-modal__public-shared-url-container"
          v-if="publicShare"
        >
          <input
            ref="publicShareUrlInput"
            type="text"
            class="share-resource-modal__input"
            v-model="publicShareUrl"
            readonly
          >
          <button
            class="share-resource-modal__copy-button"
            @click="copyPublicSharedUrlTag"
          >
            <i class="material-icons"> file_copy </i>
          </button>
        </div>
        <div class="share-resource-modal__input-header">
          {{ $t('resource.share.addEmail') }}
        </div>
        <tags-input
          v-model="email"
          :tags="emails"
          :add-on-key="addTagKeys"
          :autocomplete-items="filteredAutocompleteItems"
          placeholder="Agregar email"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateEmails"
          :delete-on-backspace="false"
        />

        <div class="share-resource-modal__input-header">
          {{ $t('resource.share.addTag') }}
        </div>
        <ul class="tenant-tags">
          <li
            @click="selectTag(t)"
            v-for="(t, index) in companyTags"
            :key="index"
          >
            <p class="tenant-tag">
              {{ t.text }}
            </p>
          </li>
        </ul>
        <br>
        <tags-input
          v-model="tag"
          :tags="tags"
          :add-on-key="addTagKeys"
          :autocomplete-items="filteredAutocompleteItemsTags"
          placeholder="Seleccionar Tags"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateTags"
          :delete-on-backspace="false"
          :validation="tagValidations"
        />
      </div>
      <button
        id="btn__share-resource"
        class="btn share-resource-modal__btn"
        :class="{ 'share-resource-modal__btn--disabled': !canSave }"
        @click="setPermissions"
      >
        {{ $t("generalActions.save") }}
      </button>
    </modal>
  </div>
</template>

<script>

import TagsInput, { createTag } from '@johmun/vue-tags-input';
import api from '../api';
import {
  UNSELECT_RESOURCE_FOR_SHARING,
  CREATE_RESOURCE_PERMISSIONS,
  DELETE_RESOURCE_PERMISSIONS,
  UPDATE_SELECTED_VIEW,
} from '../store/action-types';
import { KEY_CODES } from '../constants';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';

const EMAIL_REGEX = /^[^@\s]+@[^@\s]+$/;

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  components: {
    TagsInput,
  },
  data() {
    const resource = this.$store.getters.selectedResource;
    const alreadySharedUsers = this.$store.getters.selectedResourceSharedUsers(resource.type);
    const alreadySharedTags = resource.tagList;

    return {
      email: '',
      emails: alreadySharedUsers
        .map((user) => ({ text: user.email, classes: 'share-resource-modal__email' })),
      tags: alreadySharedTags.map(tag => ({ text: tag, classes: '.ti-tags' })),
      alreadySharedUsers,
      alreadySharedTags,
      companyUserEmails: [],
      companyTags: [],
      tag: '',
      hasInvalidEmail: false,
      addTagKeys: [KEY_CODES.ENTER, KEY_CODES.TAB, KEY_CODES.SPACE],
      addedFromTagList: true,
      tagValidations: [{
        classes: 'avoid-item',
        rule: () => this.addedFromTagList,
        disableAdd: true,
      }],
    };
  },
  computed: {
    resource() {
      return this.$store.getters.selectedResource;
    },
    publicShare() {
      return this.resource.publicShare;
    },
    publicShareUrl() {
      return this.resource.publicShareUrl;
    },
    currenteUser() {
      return this.$store.getters.currenteUser;
    },
    title() {
      return (this.resource.type === 'View') ? this.resource.title : this.resource.name;
    },
    alreadySharedEmails() {
      return this.alreadySharedUsers.map(user => user.email);
    },
    allInputEmails() {
      return this.emails.map(email => email.text);
    },
    allInputTags() {
      return this.tags.map(tag => tag.text);
    },
    emailsToShare() {
      return this.allInputEmails.filter(email => !this.alreadySharedEmails.includes(email));
    },
    tagsToShare() {
      return this.allInputTags.filter(tag => !this.alreadySharedTags.includes(tag));
    },
    userIdsToUnshare() {
      return this.alreadySharedUsers.filter(user => !this.allInputEmails.includes(user.email)).map(user => user.id);
    },
    tagsToUnshare() {
      return this.alreadySharedTags.filter(tag => !this.allInputTags.includes(tag));
    },
    filteredAutocompleteItems() {
      const filteredResults = this.companyUserEmails.filter(email =>
        email.text.toLowerCase().indexOf(this.email.toLowerCase()) !== -1);

      return filteredResults;
    },
    filteredAutocompleteItemsTags() {
      const filteredResults = this.companyTags.filter(t =>
        t.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1);

      this.setReadOnlyMode(filteredResults);

      return filteredResults;
    },
    incompleteEmail() {
      return (this.email.length !== 0);
    },
    incompleteTags() {
      return (this.tag.length !== 0);
    },
    canSave() {
      return !((this.incompleteTags && this.incompleteEmail) || this.hasInvalidEmail);
      // return !this.hasInvalidEmail;
    },
  },
  mounted() {
    api.getUsers({ companyId: this.resource.companyId }).then((response) => {
      this.companyUserEmails = response.users.map((user) => ({ text: user.email }));
    });
    api.getCompany(this.resource.companyId).then((response) => {
      this.companyTags = response.tagList.map((tag) => ({ text: tag }));
    });
    this.addedFromTagList = false;
    this.openModal();
  },
  methods: {
    openModal() {
      this.$modal.show('share-resource');
    },
    copyPublicSharedUrlTag() {
      let msg;
      const publicShareUrlTag = this.$refs.publicShareUrlInput;
      publicShareUrlTag.select();
      try {
        document.execCommand('copy');
        msg = '¡Link público copiado!';
      } catch (err) {
        msg = '¡Link no se pudo copiar!';
      }
      this.showMessage(msg);
      window.getSelection().removeAllRanges();
    },
    setReadOnlyMode(filteredResults) {
      if (filteredResults.length === 0) {
        this.addedFromTagList = true;
      } else {
        this.addedFromTagList = false;
      }
    },
    selectTag(tag) {
      if (this.companyTags.map(st => st.text).indexOf(tag) === -1) {
        this.tags.push(createTag(tag, this.tags));
      }
    },
    async setPermissions() {
      if (!this.canSave) return;

      if (this.emailsToShare.length > 0 || this.tagsToShare.length > 0) await this.createPermissions();
      if (this.userIdsToUnshare.length > 0 || this.tagsToUnshare.length > 0) await this.deletePermissions();
      this.close();
    },
    createPermissions() {
      const params = { resource: this.resource, emails: this.emailsToShare, tagList: this.allInputTags };

      return this.$store.dispatch(CREATE_RESOURCE_PERMISSIONS, params);
    },
    deletePermissions() {
      const params = { resource: this.resource, userIds: this.userIdsToUnshare, tagList: this.tagsToUnshare };

      return this.$store.dispatch(DELETE_RESOURCE_PERMISSIONS, params);
    },
    close() {
      this.$store.dispatch(UNSELECT_RESOURCE_FOR_SHARING, this.resource.type);
    },
    updateEmails(newTags) {
      let hasInvalidEmailAux = false;
      newTags.forEach(tag => {
        if (!tag.classes) tag.classes = 'share-resource-modal__email';
        if (!EMAIL_REGEX.test(tag.text)) {
          tag.classes += ' share-resource-modal__email--invalid';
          hasInvalidEmailAux = true;
        }
      });
      this.hasInvalidEmail = hasInvalidEmailAux;
      this.emails = newTags;
    },
    updateTags(newTags) {
      this.tags = newTags;
    },
    togglePublicShare() {
      this.$store.dispatch(UPDATE_SELECTED_VIEW, { publicShare: !this.publicShare });
    },
  },
};
</script>
