<template>
  <div class="custom-app-template-editor">
    <div class="custom-app-template-editor__header">
      <span
        class="custom-app-template-editor__title"
      >Nueva App</span>

      <span
        v-if="showStatus()"
        class="label-badge custom-app-template-editor__header-action custom-app-template-editor__label-badge"
      >Pendiente de Aprobacion</span>

      <a
        id="save-btn"
        v-if="readOnly"
        :href="`/custom_app_templates/${this.customAppTemplate.id}/edit`"
        class="custom-app-template-editor__header-action"
      >
        <i class="material-icons">edit</i>
        Editar
      </a>
      <button
        id="save-btn"
        v-else
        @click="saveAndRedirect"
        class="custom-app-template-editor__header-action"
      >
        <i class="material-icons">save</i>
        Guardar
      </button>
    </div>
    <div class="custom-app-template-editor__section--meta">
      <div class="custom-app-template-editor__section--visibility-status">
        <span class="custom-app-template-editor__section">Visibilidad</span>
        <select
          name="visibility"
          :style="{display: 'inlineBlock', width:'10em'}"
          :disabled="readOnly"
          v-model="visibilityStatus"
          @change="publish"
        >
          <option value="private">
            {{ getOptionsName("private") }}
          </option>
          <option
            v-if="visibilityStatus === 'workspace'"
            value="workspace"
          >
            Workspace
          </option>
          <option
            v-else
            value="pending_workspace"
          >
            {{ getOptionsName("pending_workspace") }}
          </option>
          <option
            v-if="visibilityStatus === 'public'"
            value="public"
          >
            Publica
          </option>
          <option
            v-else
            value="pending_public"
          >
            {{ getOptionsName("pending_public") }}
          </option>
        </select>
      </div>
      <div class="custom-app-template-editor__section--thumbnail">
        <thumbnail-upload
          from="custom-app-template-editor"
          @uploaded="hadleThumbnailUpload"
        >
          <i class="material-icons material-icons-inline view-editor__action-icon">backup</i>
          {{ thumbnail }}
        </thumbnail-upload>
      </div>
      <div class="custom-app-template-editor__section custom-app-template-editor__section--name">
        <label
          for="name"
          class="custom-app-template-editor__section-label--block"
        >
          Nombre
        </label>
        <input
          type="text"
          name="name"
          id="name"
          v-model="name"
          :disabled="readOnly"
          class="custom-app-template-editor__input"
        >

        <span
          class="custom-app-template-editor__error"
          v-if="errors.name"
        >
          {{ errors.name.join("\n") }}
        </span>
      </div>

      <div class="custom-app-template-editor__section custom-app-template-editor__section--integrations">
        <label
          for="tags"
          class="custom-app-template-editor__section-label--block"
        >
          Integraciones
        </label>
        <tags-input
          :disabled="readOnly"
          v-model="integration"
          :tags="selectedIntegrations"
          :autocomplete-items="filteredIntegrationsTags"
          @tags-changed="newTags => selectedIntegrations = newTags"
          class="share-resource-modal__input share-resource-modal__input--tags"
        />
      </div>

      <div class="custom-app-template-editor__section custom-app-template-editor__section--display-type">
        <label
          for="tags"
          class="custom-app-template-editor__section-label--block"
        >
          Tipo de Visualizacion
        </label>

        <tags-input
          :disabled="readOnly"
          v-model="visualization"
          :tags="selectedVisualizations"
          :autocomplete-items="filteredVisualizationsTags"
          @tags-changed="newTags => selectedVisualizations= newTags"
          class="share-resource-modal__input share-resource-modal__input--tags"
        />
        <!-- <span
          class="custom-app-template-editor__section-checkbox"
          :key="vtype[1]"
          v-for="vtype in displayTypes"
        >
          <input
            :disabled="readOnly"
            v-model="selectedVisualizations[vtype[1]]"
            type="checkbox"
            :name="vtype"
          >
          <label :for="vtype">{{ displayTypesOptions[vtype[1]] }}</label>
        </span> -->
      </div>

      <div class="custom-app-template-editor__section custom-app-template-editor__section--app-type">
        <label
          for="tags"
          class="custom-app-template-editor__section-label--block"
        >
          Tipo de Permisos
        </label>
        <select
          :disabled="readOnly"
          v-model="selectedPermissionsTypes"
          class="custom-app-template-editor__input"
        >
          <option
            :key="atype[1]"
            v-for="atype in appTypes"
            :value="atype[1]"
          >
            {{ appTypesOptions[atype[1]] }}
          </option>
        </select>
      </div>

      <div class="custom-app-template-editor__section custom-app-template-editor__section--description">
        <label
          for="description"
          class="custom-app-template-editor__section-label--block"
        >
          Descripcion
        </label>
        <textarea
          type="text"
          name="description"
          :style="{height: '145px', resize: 'none'}"
          id="description"
          v-model="description"
          :disabled="readOnly"
          class="custom-app-template-editor__input"
        />

        <span
          class="custom-app-template-editor__error"
          v-if="errors.name"
        >
          {{ errors.name.join("\n") }}
        </span>
      </div>
    </div>

    <div class="custom-app-template-editor__section">
      <div class="custom-app-template-editor__section-header">
        <label class="custom-app-template-editor__section-label">Data Source</label>
        <button
          id="new-field-btn"
          v-if="!readOnly"
          @click="showDataSourceInputsWhenConnected"
          class="btn btn--inverted-colors custom-app-template-editor__new-field-btn"
        >
          <i
            class="material-icons material-icons--blue"
          >add</i>
          DataSource
        </button>
      </div>
      <br>
      <div
        v-if="selectedDataSource"
        class="custom-app-template-editor__section-search"
      >
        <input
          :disabled="true"
          class="custom-app-template-editor__input"
          v-model="selectedDataSource.name"
          placeholder="Selecciona un archivo"
        >
        <select
          v-model="selectedDataSource.sheetName"
          class="custom-app-template-editor__input"
          :disabled="true"
        >
          <option>
            {{ selectedDataSource.sheetName }}
          </option>
        </select>
        <input
          :disabled="true"
          v-model="selectedDataSource.range"
          class="custom-app-template-editor__input"
          placeholder="Rango"
        >
        <button
          v-if="!readOnly"
          class="icon-button"
          @click="removeDataSourceFromApp"
        >
          <i class="material-icons">delete</i>
        </button>
      </div>
      <!-- <button
          id="new-field-btn"
          v-if="!readOnly"
          @click="fileLookup"
          class="btn btn--inverted-colors custom-app-template-editor__new-field-btn"
        >
          <i class="material-icons material-icons--blue">add</i>
          Buscar
        </button> -->
      <!-- </div> -->
    </div>

    <div
      class="custom-app-template-editor__section"
    >
      <div class="custom-app-template-editor__section-header">
        <span class="custom-app-template-editor__section-label">Campos</span>
        <button
          id="new-field-btn"
          v-if="!readOnly"
          @click="addNewField"
          class="btn btn--inverted-colors custom-app-template-editor__new-field-btn"
        >
          <i class="material-icons material-icons--blue">add</i>
          Nuevo Campo
        </button>
      </div>
      <table class="custom-app-template-editor__table">
        <thead>
          <th>Nombre</th>
          <th>Tipo</th>
          <th>Valor por defecto</th>
          <th v-if="!readOnly">
            Remover
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(field, index) in fields"
            :key="index"
          >
            <td>
              <input
                type="text"
                :value="field.name"
                @input="field.name = camelizeName($event.target.value)"
                :disabled="readOnly"
                class="custom-app-template-editor__input"
              >
            </td>
            <td>
              <select
                v-model="field.type"
                :disabled="readOnly"
                class="custom-app-template-editor__input"
                @change="field.default = undefined"
              >
                <option value="text">
                  Texto
                </option>
                <option value="number">
                  Número
                </option>
                <option value="list">
                  Lista
                </option>
              </select>
            </td>
            <td>
              <input-by-type
                v-model="field.default"
                :type="field.type"
                :read-only="readOnly"
              />
            </td>
            <td v-if="!readOnly">
              <button
                class="icon-button"
                @click="removeField(index)"
              >
                <i class="material-icons">delete</i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <span
        class="custom-app-template-editor__error"
        v-if="errors.fields"
      >
        {{ errors.fields.join("\n") }}
      </span>
    </div>
    <div class="custom-app-template-editor__section">
      <label
        for="code"
        class="custom-app-template-editor__section-label"
      >
        Código
      </label>
      <codemirror
        name="code"
        id="code"
        v-model="code"
        class="custom-app-template-editor__input custom-app-template-editor__input--code"
        :options="cmOption"
      />
      <span
        class="custom-app-template-editor__error"
        v-if="errors.code"
      >
        {{ errors.code.join("\n") }}
      </span>
    </div>
    <div class="custom-app-template-editor__section">
      <div class="custom-app-template-editor__section-label">
        Preview
      </div>
      <custom-app-renderer
        :code="code"
        :fields="fieldsHash"
        :data-source="containerDataSource"
        class="custom-app-template-editor__preview"
      />
    </div>

    <data-source-modal v-if="dataSourceModalOpened" />
  </div>
</template>

<script>
import _ from 'lodash';
import humps from 'humps';
import TagsInput from '@johmun/vue-tags-input';
import { codemirror } from 'vue-codemirror';
import { mapGetters, mapState } from 'vuex';
import 'codemirror/mode/xml/xml.js';
import 'codemirror/addon/selection/active-line.js';
import 'codemirror/addon/edit/closetag.js';
import api from '../api';
import CustomAppRenderer from './custom-app-renderer';
import storeConnectionsMixin from '../mixins/storeConnectionsMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import googleApiClientMixin from '../mixins/googleApiClientMixin';

import { OPEN_DATA_SOURCE_MODAL, UPDATE_SELECTED_CUSTOM_APP_TEMPLATE } from '../store/action-types';
import { UNSET_SELECTED_DATA_SOURCE, SET_SELECTED_DATA_SOURCE } from '../store/mutation-types';
import DataSourceModal from '../components/data-source-modal';
import ThumbnailUpload from './utils/thumbnail-upload';

const PENDING_WORKSPACE_STATUS = 'pending_workspace';
const PENDING_PUBLIC_STATUS = 'pending_public';
const PRIVATE_STATUS = 'private';
const MSG_DURATION = 6000;
const PUBLISHED_STATUS_MESSAGES = {};
PUBLISHED_STATUS_MESSAGES[PRIVATE_STATUS] = 'El app ahora es privada y solo puedes verla tu';
PUBLISHED_STATUS_MESSAGES[PENDING_WORKSPACE_STATUS] = `App enviada para revision, 
antes de estar disponible un Admin debera aprobar la publicacion`;
PUBLISHED_STATUS_MESSAGES[PENDING_PUBLIC_STATUS] = `App enviada para revision, 
antes de estar disponible un Admin debera aprobar la publicacion`;
const OPTION_NAMES = {};
OPTION_NAMES[PRIVATE_STATUS] = 'Privada';
OPTION_NAMES[PENDING_WORKSPACE_STATUS] = 'Workspace';
OPTION_NAMES[PENDING_PUBLIC_STATUS] = 'Publica';
const DEBOUNCE_DELAY = 1000;

export default {
  mixins: [storeConnectionsMixin, showMessageMixin, googleApiClientMixin],
  props: {
    readOnly: { type: Boolean, default: false },
    customAppTemplate: { type: Object, default: null },
    integrationsTags: { type: Array, default: () => [] },
    displayTypes: { type: Array, default: () => [] },
    appTypes: { type: Array, default: () => [] },
  },
  components: {
    codemirror,
    CustomAppRenderer,
    TagsInput,
    DataSourceModal,
    ThumbnailUpload,
  },
  data() {
    const emptyField = { name: '', type: 'text', default: undefined };

    // const selectedVisualizations = this.displayTypes.reduce((accumObj, obj) => {
    //   accumObj[obj[1]] = false;

    //   return accumObj;
    // }, {});
    // _.get(this.customAppTemplate, 'dataDisplayType', []).map((displayType) => {
    //   selectedVisualizations[displayType] = true;

    //   return null;
    // });

    return {
      name: _.get(this.customAppTemplate, 'name', ''),
      fields: _.get(this.customAppTemplate, 'fields', [{ ...emptyField }]),
      code: _.get(this.customAppTemplate, 'code', ''),
      thumbnailUrl: _.get(this.customAppTemplate, 'thumbnailUrl', ''),
      visibilityStatus: _.get(this.customAppTemplate, 'visibilityStatus', 'private'),
      description: _.get(this.customAppTemplate, 'description', ''),
      integration: '',
      visualization: '',
      selectedIntegrations: _.get(this.customAppTemplate, 'integrationList', [])
        .map((integration) => ({ text: integration })),

      selectedVisualizations: _.get(this.customAppTemplate, 'dataDisplayTypeList', [])
        .map((viz) => ({ text: viz })),
      selectedPermissionsTypes: _.get(this.customAppTemplate, 'appType', ''),
      // selectedVisualizations,
      errors: {},
      emptyField,
      cmOption: {
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        line: true,
        mode: 'text/html',
        readOnly: this.readOnly ? 'nocursor' : false,
      },
      selectedFile: null,
      resultsOpen: false,
      showDataSourceInputs: false,
      sheetName: null,
      range: null,
      imageName: null,
      // imageUrl: null,
      fileSignedId: null,
    };
  },
  computed: {
    ...mapGetters(['customAppCurrentUserFields']),
    fieldsHash() {
      const hash = {};
      this.fields.forEach(field => {
        hash[field.name] = field.default;
      });

      return hash;
    },
    ...mapState({
      dataSourceModalOpened: state => state.ui.dataSourceModalOpened,
      selectedDataSource: state => state.users.selectedDataSource,
    }),
    thumbnail() {
      const thumbnailName = this.thumbnailUrl ? decodeURIComponent(this.thumbnailUrl).split('/') : [];

      return thumbnailName.length > 0 ? decodeURIComponent(thumbnailName[thumbnailName.length - 1]) :
        'Upload Thumbnail';
    },
    containerDataSource() {
      // return this.customAppTemplate ? this.customAppTemplate.dataSource : this.selectedDataSource;
      return this.selectedDataSource ? this.selectedDataSource : _.get(this.customAppTemplate, 'dataSource', null);
    },
    appTypesOptions() {
      return this.getOptionsObject(this.appTypes);
    },
    displayTypesOptions() {
      return this.getOptionsObject(this.displayTypes);
    },
    integrations() {
      return this.integrationsTags.map((integration) => ({ text: integration }));
    },
    filteredIntegrationsTags() {
      return this.integrations
        .filter((integration) => integration.text.toLowerCase().indexOf(this.integration.toLowerCase()) !== -1);
    },
    visualizations() {
      return this.displayTypes.map((viz) => ({ text: viz }));
    },
    filteredVisualizationsTags() {
      return this.visualizations
        .filter((vis) => vis.text.toLowerCase().indexOf(this.integration.toLowerCase()) !== -1);
    },
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Nueva App';
    if (this.customAppTemplate !== null) {
      this.$store.commit(SET_SELECTED_DATA_SOURCE, _.get(this.customAppTemplate, 'dataSource', null));
      if (this.selectedDataSource !== null) {
        this.showDataSourceInputs = true;
      }
    }
  },
  methods: {
    // getSelectedPermissionsTypes(customAppTemplate) {
    //   customAppTemplate.appType.map()
    // },
    hadleThumbnailUpload({ url, fileSignedId, name }) {
      this.thumbnailUrl = url;
      this.fileSignedId = fileSignedId;
      this.imageName = name;
      this.saveThumbnailImage();
    },
    saveThumbnailImage() {
      if (this.customAppTemplate) {
        this.$store.dispatch(
          UPDATE_SELECTED_CUSTOM_APP_TEMPLATE,
          { id: this.customAppTemplate.id, params: { thumbnail: this.fileSignedId } },
        );
      } else {
        this.showMessage('You need to create the App first');
      }
    },
    removeDataSourceFromApp() {
      this.$store.commit(UNSET_SELECTED_DATA_SOURCE);
    },
    showDataSourceInputsWhenConnected() {
      if (this.goToConnectionsIfNecessary()) {
        this.$store.dispatch(OPEN_DATA_SOURCE_MODAL);
        // this.showDataSourceInputs = ! this.showDataSourceInputs;
      }
    },
    selectFile(file) {
      this.selectedFile = file.name;
      this.resultsOpen = false;
      this.getMetadata(file);
    },
    fileLookup: _.debounce(function () {
      this.findFile();
    }, DEBOUNCE_DELAY),
    findFile() {
      this.getFiles(this.selectedFile);
      this.resultsOpen = true;
    },
    goToConnectionsIfNecessary() {
      if (this.connections.length === 0) {
        this.showMessage('No tienes ninguna conexion, primero debes crear una', MSG_DURATION);
        setTimeout(() => {
          window.location.href = '/connections';
        }, 2500);

        return false;
      }

      return true;
    },
    showStatus() {
      return this.visibilityStatus.startsWith('pending');
    },
    getOptionsName(option) {
      return OPTION_NAMES[option];
    },
    getOptionsObject(options) {
      return options.reduce((accumObj, obj) => {
        accumObj[obj[1]] = obj[0];

        return accumObj;
      }, {});
    },
    addNewField() {
      this.fields.push({ ...this.emptyField });
    },
    removeField(index) {
      this.fields.splice(index, 1);
    },
    save() {
      // const selDisplays = Object.keys(this.selectedVisualizations)
      //   .filter((s) => this.selectedVisualizations[s] === true);
      const params = {
        name: this.name,
        code: this.code,
        fields: this.fields,
        description: this.description,
        integrationList: this.selectedIntegrations.map(integration => integration.text),
        // dataDisplayType: this.selectedVisualizations.map(vis => vis.text),
        dataDisplayTypeList: this.selectedVisualizations.map(vis => vis.text),
        appType: this.selectedPermissionsTypes,
        dataSource: this.selectedDataSource === null ? null : this.selectedDataSource.id,
      };

      if (this.customAppTemplate) {
        return api.updateCustomAppTemplate({ params, id: this.customAppTemplate.id });
      }
      params.visibilityStatus = this.visibilityStatus;

      return api.createCustomAppTemplate(params);
    },
    saveAndRedirect() {
      this.save().then(() => {
        window.location.assign('/custom_app_templates');
      }).catch(error => {
        this.errors = error.response.errors;
      });
    },
    publish() {
      if (this.customAppTemplate === null) return;
      const params = { visibilityStatus: this.visibilityStatus };
      const msg = PUBLISHED_STATUS_MESSAGES[this.visibilityStatus];

      api.updateCustomAppTemplate({ params, id: this.customAppTemplate.id }).then(response => {
        this.visibilityStatus = response.visibilityStatus;
        this.showMessage(msg, MSG_DURATION);
      });
    },
    camelizeName(name) {
      return humps.camelize(name);
    },
  },
};
</script>
