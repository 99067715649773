<template>
  <div class="app-modal__inputs flex flex-col items-center justify-center">
    <div class="mb-4 relative">
      <input
        class="self-center h-10 bg-gray-100 p2 rounded-lg pl-10 search-bar border border-gray-300 outline-none"
        type="text"
        placeholder="Search"
        v-model="searchTerm"
      >
      <i class="material-icons absolute inset-y-0 pl-2 at-middle">search</i>
    </div>
    <div class="mt-4 mb-4 flex flex-row">
      <div
        @click="selectedFilter = 'name' "
        class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
        :class="{
          'bg-indigo-600 text-white': selectedFilter === 'name',
          'text-indigo-500': selectedFilter !== 'name'}"
      >
        Name
      </div>
      <div
        @click="selectedFilter = 'dataDisplayTypeList' "
        class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
        :class="{
          'bg-indigo-600 text-white': selectedFilter === 'dataDisplayTypeList',
          'text-indigo-500': selectedFilter !== 'dataDisplayTypeList'}"
      >
        Display Type
      </div>
      <div
        @click="selectedFilter = 'appType' "
        class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
        :class="{
          'bg-indigo-600 text-white': selectedFilter === 'appType',
          'text-indigo-500': selectedFilter !== 'appType'
        }"
      >
        Type
      </div>
      <div
        @click="selectedFilter = 'integrationList' "
        class="cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm"
        :class="{
          'bg-indigo-600 text-white': selectedFilter === 'integrationList',
          'text-indigo-500': selectedFilter !== 'integrationList'
        }"
      >
        Integrations
      </div>
    </div>
    <div class="grid grid-cols-3 gap-4 items-center">
      <div
        v-for="template in filteredResources"
        :key="template.id"
        class="p-2 border border-gray-300 flex rounded-lg items-center h-full app-height"
      >
        <div class="w-2/6 h-full rounded-lg bg-gray-400 mt-2 mb-2">
          <img
            :src="template.thumbnailUrl || profilePlaceholder"
            class="object-cover w-full h-full rounded-lg"
            alt="app's thumbnail"
          >
        </div>
        <div class="flex flex-col w-4/6 pl-4 h-full">
          <div>
            <p class="text-sm text-gray-500">
              {{ template.name }}
            </p>
            <p class="text-xs text-gray-400 truncate min-description">
              {{ template.description }}
            </p>
          </div>

          <div class="flex flex-row items-center h-full">
            <div class="p-1  w-16 bg-indigo-200 text-indigo-600 rounded-lg text-center text-sm cursor-pointer">
              {{ template.disabled ? 'Use': 'Added' }}
            </div>
            <!-- <div class="m-2 w-10 text-sm text-indigo-600 cursor-pointer">
              View
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import profilePlaceholder from '../images/upload-photo.png';
// import api from '../api';

export default {
  props: {
    customAppTemplatesProp: { type: Array, required: true },
  },
  data() {
    return {
      affectedViews: null,
      confirmMsg: null,
      container: null,
      searchTerm: null,
      resultsOpen: false,
      selectedFilter: 'name',
      profilePlaceholder,
    };
  },
  mounted () {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Apps';
  },
  computed: {
    ...mapState({
      customAppTemplates: state => state.views.customAppTemplates,
    }),
    filteredResources() {
      if (this.searchTerm === null || this.searchTerm === '') return this.customAppTemplatesProp;

      return this.customAppTemplatesProp.filter(template => {
        const term = this.selectedFilter === null ? _.get(template, 'name', '') :
          _.get(template, this.selectedFilter, '');
        const self = this;
        if (Array.isArray(term)) {
          return term.map(res => res.toLowerCase())
            .join('|')
            .includes(self.searchTerm.toLowerCase());
        }

        return term.toLowerCase().includes(self.searchTerm.toLowerCase());
      });
    },
  },
};
</script>
<style scoped>
.app-height {
  height: 104px;
  width: 252px;
}

.search-bar {
  width: 320px;
}

.min-description {
  min-height: 18px;
}

.at-middle {
  top: 8px;
}
</style>
