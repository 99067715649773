<template>
  <!-- <button
    id="btn__add-view"
    class="btn btn--inverted-colors new-btn new-btn--view"
  >
    <i class="material-icons material-icons--blue new-btn__plus">add</i>
    {{ $t('view.newViewButton') }}
  </button> -->
  <div
    @click="createViewAndRedirectToEdit"
    class="flex items-center cursor-pointer hover:bg-menu-hover"
  >
    <i
      class="material-icons material-icons--secondary p-2"
    >
      post_add
    </i>
    <div class="p-2 text-sm text-menu-label">
      {{ $t('view.newViewButton') }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '../api';

export default {
  computed: mapState({
    currentFolder: state => state.views.currentFolder,
  }),
  methods: {
    createViewAndRedirectToEdit() {
      api.createView(this.currentFolder.id).then((view) => {
        window.location.href = `/views/${view.uuid}/edit/`;
      });
    },
  },
};
</script>
