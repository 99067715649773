<template>
  <div class="grid-index">
    <div
      class="flex flex-row mt-10 bg-white h-20 cursor-pointer items-center justify-center
                border border-gray-400 rounded-t-lg text-size-18 mb-2"
    >
      <button
        @click="selectTab('general')"
        class="w-1/4 h-full"
        :class="{
          'text-settings-selected border-b-8 border-settings-selected': generalActive,
          'text-menu-label': !generalActive
        }"
      >
        General
      </button>

      <button
        @click="selectTab('connections')"
        class="w-1/4 h-full"
        :class="{
          'text-settings-selected border-b-8 border-settings-selected': connectionsActive,
          'text-menu-label': !connectionsActive}"
      >
        Conexiones
      </button>
      <button
        @click="selectTab('apps')"
        class="w-1/4 h-full"
        :class="{'text-icon-secondary border-b-4 border-icon-secondary': appsActive, 'text-menu-label': !appsActive}"
      >
        Apps
      </button>

      <button
        @click="selectTab('dns')"
        class="w-1/4 h-full cursor-not-allowed"
        :class="{
          'text-settings-selected border-b-8 border-settings-selected': dnsActive,
          'text-menu-label': !dnsActive}"
      >
        DNS Configuration
      </button>
    </div>
    <!-- Tab content Users-->
    <div
      class="bg-gray-100 min-h-full w-full flex flex-col"
      :class="{ hidden: !generalActive }"
    >
      <div
        class="bg-white mt-1 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
      >
        <p class="text-size-27 mb-8">
          Organización
        </p>
        <div class="flex">
          <div class="w-3/12 flex flex-col">
            <label class="w-2/6 text-label">Logo</label>
            <thumbnail-upload
              title="Change Workspace Logo"
              class="logo bg-action-secondary rounded-md mt-2 flex items-center align-middle"
              @loading-start="loadingImage = true"
              @loading-end="loadingImage = false"
              @uploaded="handleImageUploaded"
            >
              <i
                v-if="logoUrl === null"
                class="material-icons material-icons--blue align-middle my-8"
              >add</i>
              <img
                v-else
                :src="logoUrl"
                class="logo object-scale-down rounded-md h-full"
                alt="logo company"
              >
            </thumbnail-upload>
          </div>
          <div class="justify-end w-9/12 flex flex-col">
            <label class="text-label">Icono a mostrar</label>
            <thumbnail-upload
              title="Change Workspace ISO"
              class="icon-frame logo bg-action-secondary rounded-md mt-2 flex items-center align-middle"
              @loading-start="loadingImage = true"
              @loading-end="loadingImage = false"
              @uploaded="handleIsoImageUploaded"
            >
              <i
                v-if="isoUrl === null"
                class="material-icons material-icons--blue align-middle my-8"
              >add</i>
              <img
                v-else
                :src="isoUrl"
                class="icon-frame object-cover rounded-md h-full"
                alt="Iso company"
              >
            </thumbnail-upload>
          </div>
        </div>
        <div class="flex flex-col mb-12 mt-12">
          <label class="text-label"> Nombre Organización </label>
          <input
            v-model="workspaceName"
            @input="autoSaveWorkspaceName"
            class="input-name border border-input outline-none focus:border-icon-secondary rounded-md"
            type="text"
          >
        </div>
      </div>

      <div
        class="bg-white mt-4 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
      >
        <p class="text-size-27 mb-2">
          Colores
        </p>
        <label class="text-label">Aquí puedes customizar los colores de la plataforma</label>

        <div class="flex mt-8">
          <label class="w-2/6 text-label">Color Topbar (barra superior)</label>
          <div
            class="w-3/6 mb-0 pb-0 form__input"
          >
            <v-swatches
              id="containerBackgroundSwatch"
              class="mr-8"
              :swatches="containerColorsWithTransparent"
              :trigger-style="triggerStyles"
              :swatch-style="swatchStyles"
              :close-on-select="true"
              show-fallback
              fallback-input-type="color"
              v-model="headerColor"
              @input="colorSelected"
              row-length="4"
            />
          </div>
        </div>
      </div>

      <div
        class="bg-white mt-4 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
      >
        <p class="text-size-27 mb-2">
          Menú
        </p>

        <label class="">Aquí puedes customizar el Menú izquierdo Principal</label>
        <div
          v-show="showNewMenu"
        >
          <button
            class="border border-gray-400 rounded-full px-4 py-2 cursor-pointer w-2/12 mt-8 bg-buttom-primary shadow-md"
            @click="addNewMenu"
          >
            <i
              class="material-icons-outlined material-icons-inline material-icons-active"
            >add</i>
            <span
              class="text-settings-selected pl-2"
            >Nuevo</span>
          </button>
        </div>
        <div
          :key="container.id"
          v-for="(container, index) in masterView.appContainers"
          class="flex flex-col mt-8"
        >
          <div class="flex flex-row justify-center items-center text-center">
            <div class="w-1/12" />
            <p class="w-2/12 ">
              <!-- Menu {{ index + 1 }} -->
              Icono
            </p>
            <p class="w-4/12">
              <!-- Position: {{ container.app.position }} -->
              Nombre
            </p>

            <p class="w-4/12">
              <!-- Position: {{ container.app.position }} -->
              Redirección
            </p>
            <div class="w-1/12">
              <button
                class="border border-gray-400 rounded-md px-4 py-2 cursor-pointer"
                @click="selectContainerForEdition(container)"
              >
                <i
                  class="material-icons-outlined material-icons-inline material-icons-active"
                >edit</i>
              </button>

              <button
                class="border border-gray-400 rounded-md px-4 py-2 cursor-move"
                @click="selectContainerForDeletion(container)"
              >
                <i
                  class="material-icons-outlined material-icons-inline material-icons-active"
                >delete_forever</i>
              </button>
            </div>
          </div>

          <div class="flex flex-col mb-4 overflow-auto max-height-200">
            <draggable
              :list="container.app.menuItems"
              @start="dragging = true"
              @end="dragging = false"
            >
              <div
                class="flex flex-row text-center items-center align-center rounded-lg p-2 m-2 bg-main height-60"
                :key="menuIndex"
                v-for="(item, menuIndex) in (menuWithItems!== null ? menuWithItems : container.app.menuItems)"
              >
                <div class="w-1/12">
                  <i
                    class="material-icons material-icons-inline cursor-pointer"
                    alt="material icon "
                  >
                    drag_indicator
                  </i>
                </div>
                <div class="w-2/12">
                  <i
                    class="material-icons material-icons-inline"
                    alt="material icon "
                  >
                    {{ item.icon }}
                  </i>
                  <!-- <span class="">{{ item.icon }}</span> -->
                </div>
                <div class="w-4/12">
                  {{ item.label }}
                </div>
                <div class="w-4/12">
                  {{ item.resource_name || item.resourceName }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </div>
      <div
        class="bg-white mt-4 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
      >
        <p class="text-size-27 mb-2">
          Inicio
        </p>
        <label class="">Aquí puedes customizar el Inicio (Home) a tus preferencias</label>

        <div class="flex pt-4">
          <label class="w-1/6">
            Solo para este workspace
          </label>
          <toggle-button
            color="#5157ff"
            class="w-4/6"
            :value="defaultHomeOnlyForWorkspace"
            :sync="true"
            @input="updateDefaultHomeScope"
          />
        </div>

        <multi-select
          class="mt-8"
          v-model="selectedResource"
          :options="resourcePathNames"
          :multiple="false"
          group-values="pathList"
          group-label="resourceType"
          :group-select="true"
          placeholder="Selecciona una vista de inicio"
          track-by="name"
          label="name"
          @select="updateHomeDefault"
        />
      </div>

      <div
        class="bg-white mt-4 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
      >
        <p class="text-size-27 mb-2">
          Perfil
        </p>
        <label class="">Aquí puedes customizar el la vista de Perfil de tu preferencia</label>

        <multi-select
          class="mt-8"
          v-model="selectedProfileResource"
          :options="profileResourceNames"
          :multiple="false"
          group-values="pathList"
          group-label="resourceType"
          :group-select="true"
          placeholder="Selecciona una vista de inicio"
          track-by="name"
          label="name"
          @select="updateProfileDefault"
        />
      </div>
    </div>
    <!--Apps Tab -->
    <div
      :class="{ hidden: !appsActive }"
      class="min-h-full"
    >
      <div class=" py-2 h-auto items-center">
        <div
          class="bg-white h-full p-4 w-full flex flex-row  text-sm
          text-gray-700 border-gray-400 border rounded-t-lg items-center"
        >
          <custom-app-template-list
            :custom-app-templates-visibility-options="customAppTemplatesVisibilityOptions"
            :custom-app-templates-prop="customAppTemplatesProp"
            :is-workspace-apps="true"
          />
        </div>
      </div>
    </div>
    <!-- Connections Tab -->
    <div
      :class="{hidden: !connectionsActive}"
      class="min-h-full"
    >
      <div class=" py-2 h-auto items-center">
        <div
          class="bg-white h-full p-4 w-full flex flex-row  text-sm
          text-gray-700 border-gray-400 border rounded-t-lg items-center"
        >
          <connection-list
            :connections-prop="connectionsProp"
            :service-options="serviceOptions"
          />
        </div>
      </div>
      <div
        :class="{ hidden: !workspaceActive }"
        class="bg-white min-h-full w-full"
      >
        <div class="px-8 pt-4 pb-1 h-16 flex items-center">
          <div class="w-3/12 text-setting-label text-size-12 self-end">
            Workspace
          </div>
          <div class="w-6/12 text-setting-label text-size-12 self-end">
            Owner
          </div>
          <div class="w-3/12 text-center flex justify-end">
            <button
              @click="openWorkspacePlatformModal"
              class="border border-gray-400  rounded-md px-4 py-2 cursor-pointer "
            >
              <i
                class="material-icons material-icons-inline material-icons-active cursor-pointer"
              >add</i>
              <span class="text-icon-secondary">Add Workspace</span>
            </button>
          </div>
        </div>
        <div class="block">
          <settings-workspace-item
            v-for="(workspace, key, index) in workspacesList"
            :key="index"
            :workspace="workspace"
          />
        </div>
      </div>
      <!-- Apps Tab -->
      <div
        :class="{ hidden: !brandingActive }"
        class="bg-white min-h-full"
      >
        <div class="h-auto items-center">
          <div
            class="mt-1 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
          >
            <p class="text-size-27 mb-8">
              Organización
            </p>
            <div class="flex">
              <div class="w-3/12 flex flex-col">
                <label class="w-2/6">Logo</label>
                <thumbnail-upload
                  title="Change Workspace Logo"
                  class="logo bg-action-secondary rounded-md mt-2 flex items-center align-middle"
                  @loading-start="loadingImage = true"
                  @loading-end="loadingImage = false"
                  @uploaded="handleImageUploaded"
                >
                  <i
                    v-if="logoUrl === null"
                    class="material-icons material-icons--blue align-middle my-8"
                  >add</i>
                  <img
                    v-else
                    :src="logoUrl"
                    class="logo object-scale-down rounded-md h-full"
                    alt="logo company"
                  >
                </thumbnail-upload>
              </div>
              <div class="justify-end w-9/12 flex flex-col">
                <label class="">Icono a mostrar</label>
                <thumbnail-upload
                  title="Change Workspace ISO"
                  class="icon-frame logo bg-action-secondary rounded-md mt-2 flex items-center align-middle"
                  @loading-start="loadingImage = true"
                  @loading-end="loadingImage = false"
                  @uploaded="handleIsoImageUploaded"
                >
                  <i
                    v-if="isoUrl === null"
                    class="material-icons material-icons--blue align-middle my-8"
                  >add</i>
                  <img
                    v-else
                    :src="isoUrl"
                    class="icon-frame object-cover rounded-md h-full"
                    alt="Iso company"
                  >
                </thumbnail-upload>
              </div>
            </div>
            <div class="flex flex-col mb-12 mt-12">
              <label class=""> Nombre Organización </label>
              <input
                v-model="workspaceName"
                @input="autoSaveWorkspaceName"
                class="input-name border border-input outline-none focus:border-icon-secondary rounded-md"
                type="text"
              >
            </div>
          </div>

          <div
            class="mt-4 h-full p-4 w-full flex flex-col  text-sm
          text-gray-700  items-left border border-gray-400 rounded-md"
          >
            <p class="text-size-27 mb-8">
              Colores
            </p>
            <label class="">Aquí puedes customizar los colores de la plataforma</label>

            <div class="flex mt-8">
              <label class="w-2/6">Color Topbar (barra superior)</label>
              <div
                class="w-3/6 mb-0 pb-0 form__input"
              >
                <v-swatches
                  id="containerBackgroundSwatch"
                  class="mr-8"
                  :swatches="containerColorsWithTransparent"
                  :trigger-style="triggerStyles"
                  :swatch-style="swatchStyles"
                  :close-on-select="true"
                  show-fallback
                  fallback-input-type="color"
                  v-model="headerColor"
                  @input="colorSelected"
                  row-length="4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="{hidden: !billingActive}"
        class="bg-white min-h-full"
      />
    </div>

    <member-modal
      v-if="memberModalOpened"
      :workspaces-props="workspacesProps"
      :edit-mode="userModalEditMode"
    />

    <workspace-platform-modal
      v-if="openWorkspaceModalOpened"
      :platform-props="platformProps"
      :edit-mode="workspaceModalEditMode"
    />
    <menu-app-modal v-if="selectedModalOfType(appTypes.MENU)" />
    <confirmation-modal v-if="confirmationModalOpened" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import VSwatches from 'vue-swatches';
import MultiSelect from 'vue-multiselect';
import 'vue-swatches/dist/vue-swatches';
import _ from 'lodash';
import Draggable from 'vuedraggable';
import MemberModal from './member-modal';
import WorkspacePlatformModal from './workspace-platform-no-c-modal';
import SettingsWorkspaceItem from './settings-workspace-item';
import ConfirmationModal from './confirmation-modal';
import ThumbnailUpload from './utils/thumbnail-upload';
import MenuAppModal from './menu-app-modal';
import CustomAppTemplateList from './custom-app-template-list';
import ConnectionList from './connection-list';
import api from '../api';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import {
  OPEN_WORKSPACE_MODAL,
  SET_WORKSPACES_LIST,
  SET_SELECTED_PLATFORM,
  SET_CONSULTANTS,
  SET_APP_MODAL_TYPE,
  SET_PERSONAL_FOLDERS,
  SET_MENU_WITH_ITEMS,
} from '../store/mutation-types';
import {
  SELECT_USER_FOR_DELETION,
  SELECT_USER_FOR_EDITION,
  SET_NESTED_VIEW,
  SELECT_CONTAINER_FOR_EDITION,
  SELECT_CONTAINER_FOR_DELETION_WITH_LABEL,
} from '../store/action-types';

import { BASE_COLORS, APP_TYPES } from '../constants';

import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';

const DEBOUNCE_TIME = 500;
const DEFAULT_DURATION = 3000;
export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  components: {
    MemberModal,
    WorkspacePlatformModal,
    ConfirmationModal,
    VSwatches,
    SettingsWorkspaceItem,
    ThumbnailUpload,
    MenuAppModal,
    Draggable,
    MultiSelect,
    CustomAppTemplateList,
    ConnectionList,
  },
  props: {
    roleOptions: { type: Array, required: true },
    platformProps: { type: Object, required: true },
    workspacesProps: { type: Array, required: true },
    workspaceProps: { type: Object, required: true },
    teammatesProps: { type: Array, required: true },
    personalFolders: { type: Array, default: () => [] },
    personalViews: { type: Array, default: () => [] },
    masterView: { type: Object, default: () => {} },
    currentWorkspace: { type: Object, required: true },
    customAppTemplatesVisibilityOptions: { type: Array, required: true },
    customAppTemplatesProp: { type: Array, required: true },
    connectionsProp: { type: Array, default: () => [] },
    serviceOptions: { type: Array, default: () => [] },
  },
  data() {
    return {
      baseColors: BASE_COLORS,
      selectedTab: 'general',
      platformName: _.get(this.platformProps, 'name', ''),
      selectedViewName: _.get(this.platformProps, 'defaultHome', ''),
      openDropdown: false,
      fileSignedId: null,
      fileIsoSignedId: null,
      defaultHomeOnlyForWorkspace: false,
      //       logoUrl: _.get(this.platformProps, 'logoUrl'),
      //       isoUrl: _.get(this.platformProps, 'isoUrl'),
      logoUrl: _.get(this.workspaceProps, 'logoUrl'),
      isoUrl: _.get(this.workspaceProps, 'isoUrl'),
      loadingImage: false,
      sidebarColor: _.get(this.workspaceProps, 'colors.sidebar_color'),
      headerColor: _.get(this.workspaceProps, 'colors.header_color'),
      fontColor: _.get(this.workspaceProps, 'colors.font_con_color'),
      bgColor: _.get(this.workspaceProps, 'colors.bg_color'),
      appTypes: APP_TYPES,
      selectedResource: this.getSelectedResource(),
      selectedProfileResource: this.getSelectedProfileResource(),
      workspaceName: _.get(this.currentWorkspace, 'name'),
      triggerStyles: {
        width: '42px',
        height: '42px',
        borderRadius: '50%',
        border: '1px solid rgb(185 185 185)',
      },
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      showNewMenu: true,
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Configuración';
    this.getSelectedResource();
    const normalizedWorkspaces = this.workspacesProps.reduce((normalizedWorkspace, currentWorkspace) => {
      normalizedWorkspace[currentWorkspace.id] = currentWorkspace;

      return normalizedWorkspace;
    }, {});
    this.$store.commit(SET_WORKSPACES_LIST, normalizedWorkspaces);
    this.$store.commit(SET_SELECTED_PLATFORM, this.platformProps);
    this.$store.dispatch(SET_NESTED_VIEW, this.masterView);
    const consultants = this.teammatesProps.reduce((usrObj, currentUser) => {
      usrObj[currentUser.id] = currentUser;

      return usrObj;
    }, {});
    this.$store.commit(SET_CONSULTANTS, consultants);
    const personalResources = this.personalFolders.concat(this.personalViews);
    this.$store.commit(SET_PERSONAL_FOLDERS, personalResources);
    this.$store.commit(SET_MENU_WITH_ITEMS, this.masterView.appContainers[0].app.menuItems);
    this.showNewMenu = this.masterView.appContainers.length === 0;
  },
  computed: {
    ...mapState({
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      memberModalOpened: state => state.ui.memberModalOpened,
      openWorkspaceModalOpened: state => state.ui.openWorkspaceModalOpened,
      tagModalEditMode: state => state.users.tagModalEditMode,
      userModalEditMode: state => state.ui.userModalEditMode,
      usersTagged: state => state.users.usersTagged,
      workspaceModalEditMode: state => state.users.workspaceModalEditMode,
      workspacesList: state => state.users.workspacesList,
      menuWithItems: state => state.views.menuWithItems,
    }),
    ...mapGetters(['selectedModalOfType']),
    isDarkSolid() {
      return this.baseColors.reduce((isDark, colors, index) => {
        if (colors.includes(this.headerColor) && index === 1) {
          return true;
        }

        return false;
      }, false);
    },
    generalActive() {
      return this.selectedTab === 'general';
    },
    workspaceActive() {
      return this.selectedTab === 'workspaces';
    },

    brandingActive() {
      return this.selectedTab === 'branding';
    },
    billingActive() {
      return this.selectedTab === 'billing';
    },
    dnsActive() {
      return this.selectedTab === 'dns';
    },
    connectionsActive() {
      return this.selectedTab === 'connections';
    },
    appsActive() {
      return this.selectedTab === 'apps';
    },
    workspaceParams() {
      const params = { company: { colors: {} } };
      if (this.fileSignedId) {
        params.company.logo = this.fileSignedId;
      }
      if (this.fileIsoSignedId) {
        params.company.iso = this.fileIsoSignedId;
      }
      params.company.colors.bgColor = this.bgColor;
      params.company.colors.sidebarColor = this.sidebarColor;
      params.company.colors.headerColor = this.headerColor;
      params.company.colors.fontIconColor = this.fontColor;

      return params;
    },
    platformParams() {
      const params = { platform: { name: this.platformName } };

      return params;
    },
    menuContainers() {
      return this.masterView.appContainers;
    },

    profileResourceNames() {
      const personalViews = this.personalViews.filter(folder => folder.type.toLowerCase() === 'view');

      return [
        {
          resourceType: 'Views',
          pathList: personalViews.map(ele => {
            ele.type = 'view';

            return ele;
          }),
        },
      ];
    },
    resourcePathNames() {
      const personalFolder = this.personalFolders.filter(folder => folder.type.toLowerCase() === 'folder');
      const personalViews = this.personalViews.filter(folder => folder.type.toLowerCase() === 'view');
      const pathList = [];

      function traversePath(fol, parent) {
        fol.forEach(currentFolder => {
          pathList.push({ name: `${parent}/${currentFolder.name}`, folderId: currentFolder.id });
          traversePath(currentFolder.childs || [], `${parent}/${currentFolder.name}`);
        });
      }
      traversePath(personalFolder, '');

      return [
        {
          resourceType: 'Folders',
          pathList: pathList.map(ele => {
            ele.type = 'folder';

            return ele;
          }),
        },
        {
          resourceType: 'Views',
          pathList: personalViews.map(ele => {
            ele.type = 'view';

            return ele;
          }),
        },
      ];

      // return pathList;
    },
    homeDefaulUrl() {
      const resourceId = this.selectedResource.uuid || this.selectedResource.folderId;
      if (this.selectedResource.type === 'view') return `/views/${resourceId}`;

      return `/folders/${resourceId}`;
    },
    defaultHomeParams() {
      return {
        id: this.selectedResource.folderId || this.selectedResource.id,
        type: this.selectedResource.type,
        name: this.selectedResource.name,
        url: this.homeDefaulUrl,
        uuid: this.selectedResource.folderId || this.selectedResource.uuid,
      };
    },
    defaultProfileParams() {
      return {
        id: this.selectedProfileResource.id,
        type: this.selectedProfileResource.type,
        name: this.selectedProfileResource.name,
        url: `/views/${this.selectedProfileResource.uuid}`,
        uuid: this.selectedProfileResource.uuid,
      };
    },

  },

  methods: {
    updateDefaultHomeScope() {
      this.defaultHomeOnlyForWorkspace = !this.defaultHomeOnlyForWorkspace;
      this.updateHomeDefault();
    },
    autoSaveWorkspaceName: _.debounce(async function () {
      await this.saveName();
      this.showMessage('Nombre guardado correctamente');
    }, DEBOUNCE_TIME),
    saveName() {
      api.updateWorkspace(this.currentWorkspace.id, { company: { name: this.workspaceName } });
    },
    getSelectedResource() {
      const defaultHome = _.get(this.platformProps, 'defaultHome');
      const defaultHomeWorkspace = _.get(this.workspaceProps, 'defaultHome');

      this.defaultHomeOnlyForWorkspace = !!defaultHomeWorkspace && !!!defaultHome;
      if (defaultHome === 'Mi Unidad') return this.getSelectedPathName(this.resourcePathNames, 'My Unit');
      if (this.defaultHomeOnlyForWorkspace) return defaultHomeWorkspace;

      return defaultHome;
    },

    getSelectedProfileResource() {
      const defaultProfile = _.get(this.platformProps, 'defaultProfile');

      return defaultProfile === 'Mi Perfil' ? this.getSelectedPathName(this.profileResourceNames, 'Mi Perfil') : defaultProfile;
    },
    getSelectedPathName(resourcePathNames, name) {
      return _.find(resourcePathNames, (path) => path.name === name);
    },
    async updateHomeDefault(event) {
      if (event) {
        this.selectedResource = event;
      }
      if (this.defaultHomeOnlyForWorkspace) {
        await api.updateWorkspace(this.workspaceProps.id, { company: { defaultHome: { ...this.defaultHomeParams } } });
      } else {
        await api.updatePlatform(this.platformProps.id, { platform: { defaultHome: { ...this.defaultHomeParams } } });
      }
      this.showMessage('Inicio Guardado correctamente');
      setTimeout(() => {
        location.reload();
      }, DEFAULT_DURATION);
    },
    async updateProfileDefault(event) {
      this.selectedProfileResource = event;
      await api.updateWorkspace(
        this.workspaceProps.id,
        { company: { defaultProfile: { ...this.defaultProfileParams } } },
      );

      this.showMessage('Perfil por defecto guardado correctamente');
      setTimeout(() => {
        location.reload();
      }, DEFAULT_DURATION);
    },
    autoSavePlatformName: _.debounce(function () {
      this.savePlatformName();
    }, DEBOUNCE_TIME),
    addNewMenu() {
      this.$store.commit(SET_APP_MODAL_TYPE, this.appTypes.MENU);
    },
    savePlatformName() {
      api.updatePlatform(this.platformProps.id, this.platformParams);
    },
    async colorSelected() {
      //       await api.updatePlatform(this.platformProps.id, this.workspaceParams);
      await api.updateWorkspace(this.workspaceProps.id, this.workspaceParams);
      this.showMessage('Color guardado correctamente');
      setTimeout(() => {
        this.reload();
      }, DEFAULT_DURATION);
    },
    close() {
      this.openDropdown = false;
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    openWorkspacePlatformModal() {
      this.$store.commit(OPEN_WORKSPACE_MODAL);
    },
    selectForEdition(userId) {
      this.$store.dispatch(SELECT_USER_FOR_EDITION, userId);
    },
    selectForDeletion(userId) {
      this.$store.dispatch(SELECT_USER_FOR_DELETION, userId);
    },
    handleImageUploaded({ url, fileSignedId }) {
      this.logoUrl = url;
      this.fileSignedId = fileSignedId;
      api.updateWorkspace(this.workspaceProps.id, this.workspaceParams).then(() => this.reload());
    },
    handleIsoImageUploaded({ url, fileSignedId }) {
      this.isoUrl = url;
      this.fileIsoSignedId = fileSignedId;
      api.updatePlatform(this.platformProps.id, this.workspaceParams)
        .then(() => this.reload());
    },
    reload() {
      window.location.reload();
    },
    selectContainerForEdition(container) {
      this.$store.dispatch(SELECT_CONTAINER_FOR_EDITION, container);
    },
    selectContainerForDeletion(container) {
      this.$store.dispatch(SELECT_CONTAINER_FOR_DELETION_WITH_LABEL, { id: container.id, label: 'Delete menu' });
    },
  },
};
</script>
<style>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

.text-size-18 {
  font-size: 18px;
}

.text-size-27 {
  font-size: 27px;
}

.text-size-12 {
  font-size: 12px;
}

.text-size-13 {
  font-size: 13px;
}

.input-name {
  height: 41px;
  width: 251px;
}

.padding-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-23px {
  padding-left: 23px;
  padding-right: 23px;
}

.icon-frame {
  width: 65px;
  height: 65px;

}

.height-60 {
  height: 60px;
}

.cursor-move {
  cursor: move;
}

.vue-swatches__fallback__wrapper {
  margin-left: 65px !important;
}

</style>
