import panzoom from 'panzoom';

export default {
  data() {
    return {
      zoomInstance: null,
    };
  },
  created() {
    window.addEventListener('keyup', this.escapeCommand);
  },
  destroyed() {
    window.removeEventListener('keyup', this.escapeCommand);
  },
  methods: {
    escapeCommand(keyUpEvent) {
      if (keyUpEvent.isTrusted && keyUpEvent.key === 'Escape') {
        const containerElement = document.getElementById('view-editor-container');
        this.zoomInstance = panzoom(containerElement,
          {
            smoothScroll: false,
            maxZoom: 1,
            minZoom: 0.1,
            beforeMouseDown(e) {
              const shouldIgnore = e.altKey || e.metaKey;

              return !shouldIgnore;
            },
            beforeWheel(e) {
              const shouldIgnore = e.altKey || e.metaKey;

              return !shouldIgnore;
            },
            filterKey(/* e, dx, dy, dz */) {
              return true;
            },
          },
        );
        this.close();
      }
    },
  },
};
