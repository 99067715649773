<template>
  <div>
    <modal
      name="fullscreen-custom-app"
      :width="width"
      height="auto"
      :scrollable="true"
      :adaptive="true"
      :click-to-close="false"
      :classes="modalClasses"
    >
      <div
        class="fullscreen-app-modal__app-container"
      >
        <custom-app-renderer
          :code="container.code"
          :fields="container.fields"
          :view_variables="{}"
        />
      </div>
      <div class="fullscreen-app-modal__header">
        <div
          class="fullscreen-app-modal__title ql-editor"
          :style="{width:'45%'}"
          v-if="container.name"
          v-html="container.name"
        />
        <button
          v-if="visibilityStatus === 'pending_workspace'"
          class="btn btn--inverted-colors"
          @click="publishInWorkspace"
        >
          <i class="material-icons material-icons--blue material-icons-inline">publish</i>
          Publicar
        </button>
        <button
          v-if="visibilityStatus === 'workspace'"
          @click="publishInWorkspace"
          class="btn btn--inverted-colors"
        >
          <i class="material-icons material-icons--blue material-icons-inline">rate_review</i>
          Volver a revision
        </button>
        <button
          class="fullscreen-app-modal__close"
          @click="close"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
      <feed
        v-if="commentableOrFeed"
        class="fullscreen-app-modal__feed"
        :comments="comments"
        :container-id="container.id"
      />
      <!-- {{ container.id }} -->
    </modal>
  </div>
</template>

<script>
import CustomAppRenderer from './custom-app-renderer';
import Feed from './feed';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';
import api from '../api';
import { OPEN_PREVIEW_TEMPLATE_APP } from '../store/action-types';
import { UPDATE_SELECTED_CUSTOM_APP_TEMPLATE } from '../store/mutation-types';

const MSG_DURATION = 6000;

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  components: {
    Feed,
    CustomAppRenderer,
  },
  props: {
    container: { type: Object, required: true },
  },
  data() {
    return {
      visibilityStatus: this.container.visibilityStatus,

    };
  },
  computed: {
    commentableOrFeed() {
      return false;
    },
    isTextApp() {
      return false;
    },
    isFeedApp() {
      return false;
    },
    modalClasses() {
      const classes = ['modal', 'fullscreen-app-modal'];
      if (this.isFeedApp) {
        classes.push('fullscreen-app-modal--only-feed');
      } else if (!this.container.commentsEnabled) {
        classes.push('fullscreen-app-modal--no-feed');
      }

      return classes;
    },
    width() {
      if (this.isFeedApp || (!this.container.commentsEnabled && this.isTextApp)) {
        return '50.13%';
      }

      return '90%';
    },
  },
  mounted() {
    this.$modal.show('fullscreen-custom-app');
  },
  methods: {
    close() {
      this.$store.dispatch(OPEN_PREVIEW_TEMPLATE_APP, false);
    },
    publishInWorkspace() {
      let visibility;
      let msg;
      if (this.visibilityStatus === 'workspace') {
        visibility = 'pending_workspace';
        msg = `Pusiste el App ${this.container.name} en revision, y ya no estara disponible en el editor de Vistas`;
      } else if (this.visibilityStatus === 'pending_workspace') {
        visibility = 'workspace';
        msg = `Publicaste el App ${this.container.name}, ahora estara disponible en el editor de Vistas`;
      }
      const params = { visibilityStatus: visibility };
      api.updateCustomAppTemplate({ params, id: this.container.id }).then(response => {
        this.visibilityStatus = response.visibilityStatus;
        this.$store.commit(UPDATE_SELECTED_CUSTOM_APP_TEMPLATE, response);
        this.showMessage(msg, MSG_DURATION);
        this.close();
      });
    },
  },
};
</script>
