<template>
  <div
    ref="publication"
    class="publication-container"
  >
    <div class="flex flex-row publication-box">
      <img
        class="w-1/6 avatar-image2"
        :src="this.userProfilePictureUrl"
      >
      <div class="w-5/6 flex flex-col">
        <div
          class="flex flex-row"
        >
          <span class="username-publication-font">{{ userDisplayName }}</span>
          <span class="datetime-publication-font">
            •
          </span>
          <span
            class="datetime-publication-font"
            :datetime="publication.createdAt"
            ref="timestamp"
          />
        </div>
        <span
          class="role-publication-font"
          style="margin-top: 10.35px;"
        >{{ roleToShow(userRole) }}</span>
      </div>
      <div
        v-if="currentUserId === publication.user.id && !selectedForEdition(publication)"
        class="comment__actions-wrapper"
      >
        <div
          @click="showActions = !showActions"
          class="comment__show-actions-btn"
        >
          <i
            class="material-icons material-icons-inline"
            style="padding-left: 24px;"
          >more_vert</i>
        </div>
        <div
          class="publication-actions-dropdown"
          v-if="showActions === true"
        >
          <button
            @click="selectPublicationForEdition(publication)"
            class="comment__action"
          >
            <i class="material-icons comment__action-icon pr-2">edit</i>Editar
          </button>
          <button
            @click="selectPublicationForDeletion(publication)"
            class="comment__action"
          >
            <i class="material-icons comment__action-icon pr-2">delete</i>Eliminar
          </button>
        </div>
      </div>
    </div>
    <div
      id="publication-box"
      class="w-100 flex flex-col"
      style="padding-left: 81px; padding-right: 15.05px;"
      :class="{
        'inactive-comments-padding' : !commentsEnabled,
      }"
    >
      <div
        v-if="!selectedForEdition(publication)"
      >
        <span
          class="publication-font"
          v-autolinker="publication.content.replace(formattedText, '')"
          style="white-space: pre-line; word-wrap: break-word;"
        />
      </div>
      <div
        v-else
      >
        <textarea-autoheight
          autofocus
          :style="{width: '100%'}"
          v-model="editedContent"
          @keyup.enter.exact.prevent="updatePublication"
          @keyup.esc.prevent="unselectPublicationForEdition"
        />
      </div>
      <div
        v-if="previewType === 'image'"
        id="image-preview"
        class="publication-resource-margin"
      >
        <a
          :href="publication.fileUrl"
          target="_blank"
          class="cursor-pointer"
        >
          <img
            :src="publication.fileUrl"
            class="rounded-lg centered"
            style="max-height: 304px;"
          >
        </a>
      </div>
      <div
        v-else-if="previewType === 'video'"
        id="video-preview"
        class="publication-resource-margin"
      >
        <video
          id="video"
          controls="controls"
          class="rounded-lg centered"
          style="max-height: 304px;"
        >
          <source
            :src="publication.fileUrl"
            class="rounded-lg centered"
          >
        </video>
      </div>
      <div
        v-else-if="previewType === 'document'"
        class="publication-resource-margin"
      >
        <a
          :href="publication.fileUrl"
          target="_blank"
          class="cursor-pointer"
          type="application/pdf"
        >
          <div>
            <embed
              :src="publication.fileUrl"
              class="rounded-lg centered"
              width="100%"
              type="application/pdf"
              style="height: 391px; width: 100%;"
            >
          </div>
        </a>
      </div>
      <div
        v-if="formattedText !== ''"
        class="publication-resource-margin rounded-lg"
      >
        <a
          :href="formattedText"
          target="_blank"
          class="cursor-pointer"
        >
          <link-preview
            :url="formattedText"
            :width="containerWidth"
            :publication-id="publication.id"
            height="100%"
          >
            <template v-slot:loader>
              <div
                name="loading"
                class="spinner"
              />
            </template>
            <template v-slot:default="preview">
              <div
                class="card"
              >
                <img
                  class="card-img-top"
                  :src="preview.img"
                  :alt="preview.title"
                >
                <div class="card-block">
                  <h4 class="card-title">
                    {{ preview.title }}
                  </h4>
                  <p class="card-text">
                    {{ preview.description }}
                  </p>
                </div>
              </div>
            </template>
          </link-preview>
        </a>
      </div>
      <div
        class="mt-3 mb-3"
        v-if="commentsEnabled"
      >
        <template
          v-if="!publication.parentId"
          class="publication_comments"
        >
          <a
            v-if="showThread"
            href="#"
            @click.prevent="closeThread"
            class="publication_comments"
          >
            Cerrar
          </a>
          <a class="publication_comments">
            •
          </a>
          <a
            href="#"
            @click.prevent="openThread"
            class="publication_comments"
          >
            Comentarios ({{ publicationCommentsCount }})
          </a>
          <a class="publication_comments">
            •
          </a>
        </template>
      </div>
    </div>
    <div
      v-if="showThread && commentsEnabled"
      class="feed__comments feed__comments-expanded"
    >
      <publication-comment
        v-for="(comment) in commentSorted.slice(0, 5 * moreCommentsCLickCount)"
        :key="comment.createdAt"
        :comment="comment"
        :publication-id="publication.id"
        :can-comment="canComment"
      />
      <div
        v-show="shouldShowMoreComments"
      >
        <div
          class="flex flex-row place-self-center"
          style="padding-bottom: 17.8px; padding-left: 81px; width: max-content;"
        >
          <button
            class="show-more-comments-font"
            @click="moreComments()"
          >
            Mostrar más comentarios
          </button>
          <i
            class="material-icons cursor-pointer"
            style="color: #2B2B2B; font-size: 1.2em;"
            @click="moreComments()"
          >expand_more</i>
        </div>
      </div>
    </div>
    <div
      class="border-t border-b"
      style="padding-top: 10px; padding-left: 17.31px; padding-bottom: 10px;"
      v-if="commentsEnabled && canComment"
    >
      <div class="flex flex-row">
        <img
          class="w-1/6 comment-image"
          :src="commentImage"
        >
        <textarea-autoheight
          class="w-5/6 comment-box"
          v-model="newComment"
          placeholder="Escribe tu comentario..."
          @keyup.enter.exact.prevent="createCommentPublication()"
        />
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import $ from 'jquery';
import { mapState } from 'vuex';
import { render, cancel } from 'timeago.js';
import {
  UPDATE_SELECTED_PUBLICATION,
  SELECT_PUBLICATION_FOR_DELETION,
  CREATE_PUBLICATION_COMMENT,
  CREATE_LINK_METADATA,
} from '../store/action-types';
import { SET_SELECTED_PUBLICATION_ID } from '../store/mutation-types';
import profilePlaceholder from '../images/upload-photo.png';
import PublicationComment from './publication-comment';
import LinkPreview from './link-prevue-bp.vue';

const PUBLICATION_BATCH_SIZE = 5;

export default {
  props: {
    publication: { type: Object, required: true },
    commentImage: { type: String, default: '' },
    commentsEnabled: { type: Boolean, default: true },
    canComment: { type: Boolean, default: false },
  },
  components: {
    PublicationComment,
    LinkPreview,
  },
  data() {
    return {
      newPublication: null,
      newComment: null,
      editedContent: null,
      showActions: false,
      showThread: false,
      moreCommentsCLickCount: 1,
    };
  },
  computed: {
    members() {
      const resource = this.$store.getters.selectedResource;
      const alreadySharedUsers = _.cloneDeepWith(this.$store.getters.selectedResourceSharedUsers(resource.type));

      return alreadySharedUsers.map((user) => {
        if (user.profilePictureUrl === undefined || user.profilePictureUrl === null) {
          user.profilePictureUrl = profilePlaceholder;
        }

        return {
          name: user.displayName,
          avatar: user.profilePictureUrl,
        };
      });
    },
    ...mapState({
      users: state => state.users.list,
      selectedPublicationId: state => state.views.selectedPublicationId,
      currentUserId: state => state.users.currentId,
    }),
    publicationComments() {
      return this.$store.getters.publicationComments(this.publication.id);
    },
    commentSorted() {
      return _.orderBy(this.publication.comments, ['createdAt'], ['desc']);
    },
    publicationCommentsCount() {
      let commentCount = 0;
      if (this.$store.state.views.publications && this.$store.state.views.publications[this.publication.id]) {
        commentCount = this.$store.state.views.publications[this.publication.id].length;
      }
      if (!commentCount) {
        return this.publication.commentsCount;
      }

      return commentCount;
    },
    userDisplayName() {
      return this.publication.user.displayName;
    },
    userRole() {
      return this.publication.user.role;
    },
    userProfilePictureUrl() {
      return _.get(this.publication.user, 'profilePictureUrl');
    },
    containerWidth() {
      return $('#publication-box').width().toString()
        .concat('px');
    },
    previewType() {
      const contentType = this.publication.contentType;
      if (['image/png', 'image/jpg', 'image/jpeg', 'image/gif'].includes(contentType) &&
        this.publication.fileName !== 'default_placeholder.png') {
        return 'image';
      } else if (['video/mp4'].includes(contentType)) {
        return 'video';
      } else if (['application/pdf'].includes(contentType)) {
        return 'document';
      }

      return null;
    },
    formattedText() {
      return this.urlIdentification(this.publication.content);
    },
    rolesToShow() {
      return {
        'platform_owner': 'Platform Owner',
        'owner': 'Owner',
        'dev': 'Developer',
        'reader': 'Reader',
        'admin': 'Admin',
      };
    },
    dispatchCreateCommentParams() {
      let params;
      if (this.publication.id) {
        params = [CREATE_PUBLICATION_COMMENT,
          { content: this.newComment, publicationId: this.publication.id },
        ];
      }

      return params;
    },
    dispatchCreateMetadata() {
      const params = [CREATE_LINK_METADATA,
        { url: this.formattedText,
          publicationId: this.publication.id,
        },
      ];

      return params;
    },
    shouldShowMoreComments() {
      return this.commentSorted.slice(0,
        PUBLICATION_BATCH_SIZE * this.moreCommentsCLickCount).length < this.commentSorted.length;
    },
  },
  async mounted() {
    render(this.$refs.timestamp, 'es', { minInterval: 10 });
    if (this.formattedText !== '') {
      this.asyncPreviewMetadata();
    }
  },
  beforeDestroy() {
    cancel();
  },
  methods: {
    async asyncPreviewMetadata() {
      this.$store.dispatch(...this.dispatchCreateMetadata).then((response) => response);
    },
    roleToShow(systemRole) {
      return this.rolesToShow[systemRole];
    },
    selectPublicationForEdition(publication) {
      this.showActions = false;
      this.$store.commit(SET_SELECTED_PUBLICATION_ID, publication.id);
      this.editedContent = publication.content;
    },
    selectPublicationForDeletion(publication) {
      this.showActions = false;
      this.$store.dispatch(SELECT_PUBLICATION_FOR_DELETION, publication.id);
    },
    unselectPublicationForEdition() {
      this.$store.commit(SET_SELECTED_PUBLICATION_ID, null);
      this.editedContent = null;
      this.showActions = false;
    },
    updatePublication() {
      if (!this.editedContent) return;

      this.editedContent = event.target.value;
      this.$store.dispatch(UPDATE_SELECTED_PUBLICATION, { content: this.editedContent }).then(() => {
        this.unselectPublicationForEdition();
      });
    },
    selectedForEdition(publication) {
      return this.selectedPublicationId === publication.id && this.editedContent !== null;
    },
    openThread() {
      if (this.showThread) return;
      this.showThread = true;
      // this.$store.dispatch(GET_PUBLICATION_COMMENTS, this.publication.id).then(() => {
      //   this.showThread = true;
      // });
    },
    closeThread() {
      this.showThread = false;
    },
    urlIdentification(text) {
      if (!text) {
        return '';
      }
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const match = text.match(urlRegex);

      return match ? match[0] : '';
    },
    createCommentPublication() {
      if (!this.newComment) return;
      const self = this;
      this.$store.dispatch(...this.dispatchCreateCommentParams).then(() => {
        self.newComment = null;
      });
    },
    moreComments() {
      this.moreCommentsCLickCount += 1;
    },
  },
};
</script>

<style scoped>
.publication-resource-margin {
  padding-top: 22px;
  margin-top: 0;
  margin-left: 0;
  margin-right: .55rem;
}

.centered {
  display:block;
  margin:auto;
}

.publication-box {
  padding: 17.8px;
  width: 100%;
  height: auto;
}

.avatar-image2 {
  height: 51px;
  width: 51px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 13px;
}

.comment-image {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 13px;
}

.comment-box {
  height: 30px !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  padding: 0 12px;
  margin-top: 9px;
}

.publication-text-box {
  margin-left: 16px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
}

.publication-container {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  margin: 17.8px 17.8px 0 17.8px;
  width: inherit;
}

.card-title {
  padding-top: 15px;
  font: normal normal bold 16px/20px Inter;
  text-align: left;
  letter-spacing: 0px;
  width: 80%;
  margin: 0 auto;
}

.card-text {
  padding-top: 6px;
  font: normal normal normal 10px/10px Inter;
  text-align: left;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  margin: 0 auto;
}

.card-block {
  background-color: #DDE6F779;
  padding-bottom: 15px;
  border-radius: 0 0 0.5rem 0.5rem;
}

.card-img-top {
  border-radius: 0.5rem 0.5rem 0 0;
}

/* Loader */
.spinner {
  margin-top: 40%;
  margin-left: 45%;
  height: 28px;
  width: 28px;
  animation: rotate 0.8s infinite linear;
  border: 5px solid #868686;
  border-right-color: transparent;
  border-radius: 50%;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.username-publication-font {
  font: normal normal 800 17px/20px Inter;
  letter-spacing: 0.34px;
  color: #2B2B2B;
  opacity: 1;
}

.datetime-publication-font {
  padding-top: 2.8px;
  padding-left: 7.55px;
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0.28px;
  color: #B3B3B3;
  opacity: 1;
}

.role-publication-font {
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0.28px;
  color: #7A7A7A;
  opacity: 1;
}

.publication_comments {
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #7A7A7A;
  opacity: 1;
}

.content-publication-font {
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #2B2B2B;
}

.comment__action-icon {
  margin-right: 0 !important;
  font-size: 1.2rem;
}

.options-item {
  border: 1px solid #707070;
  border-radius: 10px;
  opacity: 1;
}

textarea {
  border: none;
  outline: none;
}

.inactive-comments-padding {
  padding-bottom: 15px;;
}

.show-more-comments-font {
  font: normal normal 800 13px/16px Inter;
  letter-spacing: 0.34px;
  color: #2B2B2B;
  opacity: 1;
}

.more-comments-margins {
  padding-top: 17.8px;
  padding-bottom: 17.8px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  margin: 17.8px 17.8px 0 17.8px;
}

</style>
