<script>
import {
  closest, getOffset, getPrecedingRange,
  getRange, applyRange,
  scrollIntoView, getAtAndIndex,
} from './atutils/util.js';
import AtTemplate from './at-template';

export default {
  name: 'At',
  mixins: [AtTemplate],
  props: {
    at: {
      type: String,
      default: null,
    },
    ats: {
      type: Array,
      default: () => ['@'],
    },
    suffix: {
      type: String,
      default: ' ',
    },
    loop: {
      type: Boolean,
      default: true,
    },
    allowSpaces: {
      type: Boolean,
      default: true,
    },
    avoidEmail: {
      type: Boolean,
      default: true,
    },
    hoverSelect: {
      type: Boolean,
      default: true,
    },
    tabSelect: {
      type: Boolean,
      default: true,
    },
    members: {
      type: Array,
      default: () => [],
    },
    nameKey: {
      type: String,
      default: '',
    },
    filterMatch: {
      type: Function,
      default: (name, chunk, at) =>
        name.toLowerCase()
          .indexOf(chunk.toLowerCase()) > -1
      ,
    },
    deleteMatch: {
      type: Function,
      default: (name, chunk, suffix) => chunk === name + suffix,
    },
    scrollRef: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      hasComposition: false,
      atwho: null,
    };
  },
  computed: {
    atItems() {
      return this.at ? [this.at] : this.ats;
    },
    /* eslint-disable */
    style() {
      if (this.atwho) {
        const { list, cur, x, y } = this.atwho;
        const { wrap } = this.$refs;
        if (wrap) {
          const offset = getOffset(wrap);
          const scrollLeft = this.scrollRef ? document.querySelector(this.scrollRef).scrollLeft : 0;
          const scrollTop = this.scrollRef ? document.querySelector(this.scrollRef).scrollTop : 0;
          const left = `${x + scrollLeft + window.pageXOffset - offset.left}px`;
          const top = `${y + scrollTop + window.pageYOffset - offset.top }px`;

          return { left, top };
        }
      }

      return null;
    },
  },
  watch: {
    'atwho.cur': function (index) {
      if (index != null) { // cur index exists
        this.$nextTick(() => {
          this.scrollToCur();
        });
      }
    },
    members() {
      this.handleInput(true);
    },
  },

  methods: {
    itemName(v) {
      const { nameKey } = this;

      return nameKey ? v[nameKey] : v;
    },
    isCur(index) {
      return index === this.atwho.cur;
    },

    handleItemHover(e) {
      if (this.hoverSelect) {
        this.selectByMouse(e);
      }
    },
    handleItemClick(e) {
      this.selectByMouse(e);
      this.insertItem();
    },
    handleDelete(e) {
      const range = getPrecedingRange();
      if (range) {
        const { atItems, members, suffix, deleteMatch, itemName } = this;
        const text = range.toString();
        const { at, index } = getAtAndIndex(text, atItems);
        if (index > -1) {
          const chunk = text.slice(index + at.length);
          const has = members.some(v => {
            const name = itemName(v);

            return deleteMatch(name, chunk, suffix);
          });
          if (has) {
            e.preventDefault();
            e.stopPropagation();
            const r = getRange();
            if (r) {
              r.setStart(r.endContainer, index);
              r.deleteContents();
              applyRange(r);
              this.handleInput();
            }
          }
        }
      }
    },
    handleKeyDown(e) {
      const { atwho } = this;
      if (atwho) {
        if (e.keyCode === 38 || e.keyCode === 40) { // ↑/↓
          if (!(e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            this.selectByKeyboard(e);
          }

          return;
        }
        if (e.keyCode === 13 || (this.tabSelect && e.keyCode === 9)) { // enter
          this.insertItem();
          e.preventDefault();
          e.stopPropagation();

          return;
        }
        if (e.keyCode === 27) { // esc
          this.closePanel();

          return;
        }
      }

      const isValid = e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 8;
      if (isValid) {
        setTimeout(() => {
          this.handleInput();
        }, 50);
      }

      if (e.keyCode === 8) {
        this.handleDelete(e);
      }
    },

    handleCompositionStart() {
      this.hasComposition = true;
    },
    handleCompositionEnd() {
      this.hasComposition = false;
      this.handleInput();
    },
    handleInput(keep) {
      if (this.hasComposition) return;
      const range = getPrecedingRange();
      if (range) {
        const { atItems, avoidEmail, allowSpaces } = this;

        let show = true;
        const text = range.toString();

        const { at, index } = getAtAndIndex(text, atItems);

        if (index < 0) show = false;
        const prev = text[index - 1];

        const chunk = text.slice(index + at.length, text.length);

        if (avoidEmail) {
          if (/^[a-z0-9]$/i.test(prev)) show = false;
        }

        if (!allowSpaces && /\s/.test(chunk)) {
          show = false;
        }

        if (/^\s/.test(chunk)) show = false;

        if (!show) {
          this.closePanel();
        } else {
          const { members, filterMatch, itemName } = this;
          if (!keep && chunk.length > 0) {
            this.$emit('at', chunk);
          }
          const matched = members.filter(v => {
            const name = itemName(v);

            return filterMatch(name, chunk, at);
          });
          if (matched.length) {
            this.openPanel(matched, range, index, at);
          } else {
            this.closePanel();
          }
        }
      }
    },

    closePanel() {
      if (this.atwho) {
        this.atwho = null;
      }
    },
    openPanel(list, range, offset, at) {
      const fn = () => {
        const r = range.cloneRange();
        r.setStart(r.endContainer, offset + at.length);
        const rect = r.getClientRects()[0];
        this.atwho = {
          range,
          offset,
          list,
          x: rect.left,
          y: rect.top - 4,
          cur: 0,
        };
      };
      if (this.atwho) {
        fn();
      } else { 
        setTimeout(fn, 10);
      }
    },

    scrollToCur() {
      const curEl = this.$refs.cur[0];
      const scrollParent = curEl.parentElement.parentElement; 
      scrollIntoView(curEl, scrollParent);
    },
    selectByMouse(e) {
      const el = closest(e.target, d => d.getAttribute('data-index'));
      const cur = +el.getAttribute('data-index');
      this.atwho = {
        ...this.atwho,
        cur,
      };
    },
    selectByKeyboard(e) {
      const offset = e.keyCode === 38 ? -1 : 1;
      const { cur, list } = this.atwho;
      const nextCur = this.loop ?
        (cur + offset + list.length) % list.length :
        Math.max(0, Math.min(cur + offset, list.length - 1));
      this.atwho = {
        ...this.atwho,
        cur: nextCur,
      };
    },

    insertText(text, r) {
      r.deleteContents();
      const node = r.endContainer;
      if (node.nodeType === Node.TEXT_NODE) {
        const cut = r.endOffset;
        node.data = node.data.slice(0, cut) +
          text + node.data.slice(cut);
        r.setEnd(node, cut + text.length);
      } else {
        const t = document.createTextNode(text);
        r.insertNode(t);
        r.setEndAfter(t);
      }
      r.collapse(false); 
      applyRange(r);
    },
    insertItem() {
      const { range, offset, list, cur } = this.atwho;
      const { suffix, atItems, itemName } = this;
      const r = range.cloneRange();
      const text = range.toString();
      const { at, index } = getAtAndIndex(text, atItems);
      const start = index + at.length;
      r.setStart(r.endContainer, start);
      applyRange(r);
      applyRange(r);
      const t = itemName(list[cur]) + suffix;
      this.insertText(t, r);
      this.handleInput();
    },
  },
};
</script>
