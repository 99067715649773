<template>
  <div
    id="overlayable"
    class="relative w-full"
    :class="{
      'h-full': (appType === 'SourceApp' && code !== '< Escribe aquí tu código >') ||
        (appType !== 'SourceApp' && dataSource),
    }"
  >
    <slot />
    <iframe
      v-if="code !== '< Escribe aquí tu código >' && dataSourceVerification"
      :id="iframeID"
      ref="preview"
      height="100%"
      width="100%"
      @load="updatePreview"
    />
    <div
      v-else
      class="flex flex-column flex-1 items-center justify-center"
    >
      <i
        v-if="appType === 'SourceApp'"
        class="material-icons icon2"
      >{{ appIcons[appType] }}</i>
      <i
        v-else
        class="material-icons icon2"
      >{{ appIcons[appTypeCustom] }}</i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';

const UPDATE_PREVIEW_TIMEOUT = 1000;
const INFLECTION_POINT_IN_SCALE = 100;

export default {
  props: {
    code: { type: String, required: true },
    height: { type: String, default: '200px' },
    width: { type: String, default: '200px' },
    container: { type: Object, default: null },
    appIcons: { type: Object, default: null },
  },
  data() {
    return {
      delayTimeout: null,
      intervalId: null,
      pristine: true,
      iframeHeight: '200px',
      // iframeWidth: '200px',
      containerID: _.get(this.container, 'id', -1),
      appType: _.get(this.container, 'appType', ''),
      appTypeCustom: _.get(this.container, 'app.customAppTemplate.name', '').concat('App'),
      initialDataSource: _.get(this.container, 'app.dataSource', null),
    };
  },
  computed: {
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
    }),
    frame() {
      return this.$refs.preview;
    },
    iframeID() {
      return `iframe${this.containerID}`;
    },
    polymorphicAppIdJS() {
      return `#polymorphic-app${this.containerID}`;
    },
    draggableIdJS() {
      return `#draggable${this.containerID}`;
    },
    dataSource() {
      return this.appAttrs[this.container.id].dataSource;
    },
    dataSourceVerification() {
      if (this.appType === 'SourceApp') {
        return true;
      }

      return this.dataSource !== null;
    },
  },
  mounted() {
    this.applyGanttAppProperties();
  },
  methods: {
    reloadFrame() {
      this.updateIframeHeight();
      if (this.frame) {
        if (this.frame.contentWindow || this.frame.window) {
          (this.frame.contentWindow || this.frame.window).location.reload();
        }
      }
    },
    updatePreview() {
      const frameDocument = this.getFrameDocument();
      let newCode = this.code;
      if (!this.code.includes('https://docs.google.com/spreadsheets') &&
        !this.code.includes('https://drive.google.com/file') &&
        !this.code.includes('https://docs.google.com/presentation')) {
        newCode = this.code.replace('<iframe',
          '<iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" ');
        const divForContainerAdjustment = '<div style="position: relative; padding-bottom: 50%; height: 0;">';
        newCode = divForContainerAdjustment.concat(newCode, '</div>');
      }
      if (frameDocument) {
        frameDocument.open();
        frameDocument.write(newCode);
        frameDocument.close();
        this.updateIframeHeight();
      }
    },
    getHeightNumberValue() {
      return parseInt(this.iframeHeight.match(/\d+/g)[0], 10);
    },
    getFrameDocument() {
      if (this.frame === undefined || this.frame.contentDocument === null || this.frame.contentWindow === null) {
        return null;
      }

      return this.frame.contentDocument || this.frame.contentWindow.document;
    },
    updateIframeHeight() {
      const frameDocument = this.getFrameDocument();
      if (frameDocument) {
        const scrollHeight = frameDocument.documentElement.scrollHeight;
        this.iframeHeight = `${scrollHeight}px`;
      }
    },
    scaleProperty() {
      const scale = this.container.app.fieldValues.scale;
      const scaleValue = parseInt(scale, 10);
      if (scaleValue < INFLECTION_POINT_IN_SCALE) {
        $(this.draggableIdJS).css({
          backgroundColor: this.containerBackgroundColor,
        });
      }
      const scaleTransform = scaleValue / INFLECTION_POINT_IN_SCALE;

      $(this.polymorphicAppIdJS).css({
        transform: `scale(${scaleTransform})`,
      });
    },
    ganttAppProperties() {
      this.scaleProperty();
    },
    applyGanttAppProperties() {
      if (this.container && this.container.appType !== 'CustomApp') {
        return;
      }

      if (this.container.app && this.container.app.customAppTemplate &&
        this.container.app.customAppTemplate.name === 'Gantt') {
        this.ganttAppProperties();
      }
    },
  },
  watch: {
    code() {
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.reloadFrame, UPDATE_PREVIEW_TIMEOUT);
    },
  },
};
</script>

<style scoped>
.icon2 {
  color: #FAAE56 !important;
  opacity: 1;
  font-size: 70px !important;
}
</style>
