<template>
  <div class="grid-index">
    <div class="grid-index__header">
      <div class="grid-index__title">
        {{ $tc("user.name", 2) }}
      </div>
      <div class="grid-index__controls">
        <button
          class="btn btn--inverted-colors new-btn"
          @click="openUserTagsModal"
        >
          <i class="material-icons material-icons--blue new-btn__plus">label</i>
          {{ $t("user.tags") }}
        </button>
      </div>
      <div class="grid-index__controls">
        <button
          class="btn btn--inverted-colors new-btn"
          @click="openUserModal"
        >
          <i class="material-icons material-icons--blue new-btn__plus">person_add</i>
          {{ $t("user.invite") }}
        </button>
      </div>
    </div>
    <div class="grid-index__grid">
      <div
        v-for="(user, index) in displayableUsers"
        :key="index"
        class="grid-index__item-container--user"
      >
        <div class="grid-index__name--users">
          {{ user.displayName }}
        </div>
        <div class="tenant-tags-container">
          <ul class="tenant-tags">
            <li
              :key="tag"
              v-for="tag in user.tagList"
            >
              <p class="tenant-tag--list">
                {{ tag }}
              </p>
            </li>
          </ul>
        </div>
        <div
          class="grid-index__icons-container"
        >
          <button
            class="grid-index__icon"
            @click="selectForEdition(user.id)"
          >
            <i class="material-icons">edit</i>
          </button>
          <button
            class="grid-index__icon grid-index__icon--first-right"
            @click="selectForDeletion(user.id)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>
    </div>
    <user-modal
      v-if="userModalOpened"
      :role-options="roleOptions"
    />

    <user-tags-modal
      v-if="userTagsModalOpened"
    />
    <confirmation-modal v-if="confirmationModalOpened" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import humps from 'humps';
import UserModal from './user-modal';
import UserTagsModal from './user-tags-modal';
import ConfirmationModal from './confirmation-modal';
import { ADD_NORMALIZED_USERS, OPEN_USER_MODAL, OPEN_USER_TAGS_MODAL, ADD_TENANT_TAGS } from '../store/mutation-types';
import { SELECT_USER_FOR_DELETION, SELECT_USER_FOR_EDITION, SELECT_CURRENT_COMPANY } from '../store/action-types';

export default {
  components: {
    UserModal,
    UserTagsModal,
    ConfirmationModal,
  },
  props: {
    usersProp: { type: Array, required: true },
    roleOptions: { type: Array, required: true },
    tenantTagsProp: { type: Array, required: true },
    companyProp: { type: Object, required: true },
  },
  mounted() {
    const usersObject = this.usersProp.reduce((obj, user) => {
      obj[user.id] = user;

      return obj;
    }, {});
    this.$store.commit(ADD_NORMALIZED_USERS, humps.camelizeKeys(usersObject));
    this.$store.commit(ADD_TENANT_TAGS, this.tenantTagsProp);
    this.$store.dispatch(SELECT_CURRENT_COMPANY, this.companyProp);
  },
  computed: mapState({
    displayableUsers: state => state.users.list,
    confirmationModalOpened: state => state.ui.confirmationModalOpened,
    userModalOpened: state => state.ui.userModalOpened,
    userTagsModalOpened: state => state.ui.userTagsModalOpened,
  }),
  methods: {
    openUserModal() {
      this.$store.commit(OPEN_USER_MODAL);
    },
    openUserTagsModal() {
      this.$store.commit(OPEN_USER_TAGS_MODAL);
    },
    selectForEdition(userId) {
      this.$store.dispatch(SELECT_USER_FOR_EDITION, userId);
    },
    selectForDeletion(userId) {
      this.$store.dispatch(SELECT_USER_FOR_DELETION, userId);
    },
  },
};
</script>
