<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">
          {{ appIcons[appTypes.ARTICLE] }}
        </i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Artículo
        </div>
      </div>
    </div>
    <div>
      <new-data-sources-selector
        :current-datasource="selectedDataSource"
        :custom-app="false"
        :show-from-system-databases="true"
        :tooltip-file-uri="tooltipF"
        :container="container"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex items-center"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Título
          </div>
          <input
            v-model="titleCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: B"
            @input="updateAppAttrs();"
          >
          <div
            class="w-1/6"
          />
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Redirección
          </div>
          <input
            v-model="redirectCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: C"
            @input="updateAppAttrs();"
          >
          <div
            class="w-1/6"
          />
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Imagen
          </div>
          <input
            v-model="imageCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: D"
            @input="updateAppAttrs();"
          >
          <i
            v-if="showImageCell"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="No mostrar columna"
            @click="showImageCell = false; updateAppAttrs();"
          >visibility</i>
          <i
            v-else
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="Mostrar columna"
            @click="showImageCell = true; updateAppAttrs();"
          >visibility_off</i>
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Contenido
          </div>
          <input
            v-model="contentCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: E"
            @input="updateAppAttrs();"
          >
          <i
            v-if="showContentCell"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="No mostrar columna"
            @click="showContentCell = false; updateAppAttrs();"
          >visibility</i>
          <i
            v-else
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="Mostrar columna"
            @click="showContentCell = true; updateAppAttrs();"
          >visibility_off</i>
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Autor
          </div>
          <input
            v-model="authorCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: F"
            @input="updateAppAttrs();"
          >
          <i
            v-if="showAuthorCell"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="No mostrar columna"
            @click="showAuthorCell = false; updateAppAttrs();"
          >visibility</i>
          <i
            v-else
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="Mostrar columna"
            @click="showAuthorCell = true; updateAppAttrs();"
          >visibility_off</i>
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Fecha
          </div>
          <input
            v-model="dateCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: G"
            @input="updateAppAttrs();"
          >
          <i
            v-if="showDateCell"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="No mostrar columna"
            @click="showDateCell = false; updateAppAttrs();"
          >visibility</i>
          <i
            v-else
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="Mostrar columna"
            @click="showDateCell = true; updateAppAttrs();"
          >visibility_off</i>
        </div>
        <div
          class="flex items-center inter-select-title mb-4"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Tiempo lectura
          </div>
          <input
            v-model="lectureTimeCell"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: H"
            @input="updateAppAttrs();"
          >
          <i
            :id="lectureTimeId"
            v-if="showLectureTimeCell"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="No mostrar columna"
            @click="showLectureTimeCell = false; updateAppAttrs();"
          >visibility</i>
          <i
            v-else
            :id="lectureTimeId"
            class="w-1/6 flex material-icons visibility-icon cursor-pointer"
            title="Mostrar columna"
            @click="showLectureTimeCell = true; updateAppAttrs();"
          >visibility_off</i>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="text-left text-menu-label text-sm font-inter-type-1">
            Disposición contenido:
          </div>
          <div class="flex flex-row mt-2 mb-4 ml-2">
            <div class="flex items-center flex-row w-1/2">
              <input
                type="radio"
                id="horizontal"
                value="horizontal"
                v-model="contentOrientation"
              >
              <label
                class="text-menu-label text-sm font-inter-type-1 ml-2"
                for="horizontal"
              >Horizontal</label>
            </div>
            <br>
            <div class="flex items-center flex-row w-1/2">
              <input
                type="radio"
                id="Vertical"
                value="vertical"
                v-model="contentOrientation"
              >
              <label
                class="text-menu-label text-sm font-inter-type-1 ml-2"
                for="vertical"
              >Vertical</label>
            </div>
            <br>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FILTRAR DATOS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FILTRAR DATOS
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div>
            <VueSelectStandard
              class="text-center text-sm vue-select-container"
              :data="filtersColumns"
              v-model="columnFilter"
              @documentListener="onColumnFilterSelect"
              :placeholder="'Columna'"
              :listener="'documentListener'"
            />
          </div>
          <div
            class="flex flex-row inter-select-title"
          >
            <VueSelectStandard
              class="text-center text-sm vue-select-container conditions-filter-container"
              :data="filterConditions"
              v-model="filterCondition"
              @documentListener="onConditionFilterSelect"
              :placeholder="'Condición'"
              :listener="'documentListener'"
            />
            <input
              v-model="valueForCondition"
              class="ml-1 mr-1 text-center text-sm font-inter-type-1 cells value-input value-filter-container"
              placeholder="Valor"
            >
          </div>
          <div
            class="inter-select-title text-left"
          >
            <a
              class="add-filter cursor-pointer"
              style="text-decoration: none;"
              @click="addFilter"
            >+ AGREGAR FILTRO
            </a>
          </div>
          <template>
            <div
              class="flex mt-4"
              :class="{
                'padding-for-conditions-display' : this.$store.state.ui.filtersChecked.length +
                  this.$store.state.ui.filtersUnchecked.length === 0,
                'normal-padding-for-condition': this.$store.state.ui.filtersChecked.length +
                  this.$store.state.ui.filtersUnchecked.length > 0,
              }"
            >
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                Aplicar filtros
              </p>
              <toggle-button
                color="#2c6eeb"
                class="w-2/6"
                :value="applyFilters"
                :sync="true"
                @input="applyFilters= !applyFilters"
              />
            </div>
          </template>
          <div
            v-if="this.$store.state.ui.filtersChecked.length > 0"
          >
            <div
              class="flex flex-col"
              v-for="filterChecked in this.$store.state.ui.filtersChecked"
              :key="filterChecked.column + filterChecked.condition + filterChecked.conditionValue"
            >
              <template>
                <div
                  class="flex flex-row mb-4"
                  :title="fromFilterHashToText(filterChecked)"
                >
                  <label class="flex w-4/6">
                    <span
                      class="new-toggle-filter-checked text-center"
                    >
                      {{ filterTruncation(filterChecked) }}
                    </span>
                  </label>
                  <i
                    class="flex material-icons visibility-icon w-1/6 cursor-pointer"
                    style="padding-left: 6.43px; padding-top: 3px; text-align: left;"
                    title="Desactivar filtro"
                    @click="disableCheckedFilter(filterChecked)"
                  >visibility</i>
                  <i
                    class="flex material-icons delete-outline-icon w-1/6 cursor-pointer"
                    style="padding-top: 3px; text-align: left;"
                    title="Borrar filtro"
                    @click="deleteCheckedFilter(filterChecked)"
                  >delete_outline</i>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="this.$store.state.ui.filtersUnchecked.length > 0"
          >
            <div
              class="flex flex-col"
              v-for="filterUnchecked in this.$store.state.ui.filtersUnchecked"
              :key="filterUnchecked.column + filterUnchecked.condition + filterUnchecked.conditionValue"
            >
              <template>
                <div
                  class="flex flex-row mb-4"
                  :title="fromFilterHashToText(filterUnchecked)"
                >
                  <label class="flex w-4/6">
                    <span
                      class="new-toggle-filter-unchecked text-center"
                    >
                      <span class="unchecked-text">
                        {{ filterTruncation(filterUnchecked) }}
                      </span>
                    </span>
                  </label>
                  <i
                    class="flex material-icons visibility-icon w-1/6 cursor-pointer"
                    style="padding-left: 6.43px; padding-top: 3px; text-align: left;"
                    title="Activar filtro"
                    @click="enableUncheckedFilter(filterUnchecked)"
                  >visibility_off</i>
                  <i
                    class="flex material-icons delete-outline-icon w-1/6 cursor-pointer"
                    style="padding-top: 3px; text-align: left;"
                    title="Borrar filtro"
                    @click="deleteUncheckedFilter(filterUnchecked)"
                  >delete_outline</i>
                </div>
              </template>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import $ from 'jquery';
import Collapsible from 'vue-collapsible-component';
import VueSelectStandard from './vue-select-standard';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import {
  SAVE_CONTAINER,
  SELECT_CHECKED_FILTER_INDEX_FOR_DELETION,
  SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import NewDataSourcesSelector from './new-data-sources-selector';
import {
  SAVE_ALERT,
  SET_FILTERS_CHECKED,
  SET_FILTERS_UNCHECKED,
  REMOVE_FILTER_CHECKED,
  REMOVE_FILTER_UNCHECKED,
} from '../store/mutation-types';
import {
  CONTAINER_COLORS_WITH_TRANSPARENT,
  APP_ICONS,
  APP_TYPES,
} from '../constants';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEFAULT_COLUMN_FILTER = { id: -1, name: 'Columna' };
const DEFAULT_FILTER_CONDITION = { id: -1, name: 'Condición' };
const DEFAULT_VALUE_FOR_CONDITION = '';
const TIME_BETWEEN_CLICKS = 50;

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    Collapsible,
    NewDataSourcesSelector,
    VueSelectStandard,
    AppTitleInOptions,
    ContainerSection,
  },
  props: {
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const currentDataSource = this.$store.state.users.selectedDataSource;
    const columnsData = _.get(container, 'app.columnsData', []);
    const columnsDataProcessed = this.processColumnsData(columnsData);
    const initialFilters = this.defineFilters(container);

    return {
      template,
      container,
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      showShading: _.get(container, 'showShading', false),
      showSearch: _.get(container, 'showSearch', true),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      showTitle: _.get(container, 'showTitle', false),
      showTransparency: _.get(container, 'showTransparency', false),
      showDataSource: _.get(container, 'showDataSource', false),
      contentOrientation: _.get(container, 'app.contentOrientation', 'horizontal'),
      applyFilters: _.get(container, 'app.applyFilters', false),
      columnFilter: DEFAULT_COLUMN_FILTER,
      filterCondition: DEFAULT_FILTER_CONDITION,
      filterConditions: [
        { id: 1, name: '=' }, { id: 2, name: '>' }, { id: 3, name: '<' },
        { id: 4, name: '>=' }, { id: 5, name: '<=' },
        { id: 6, name: 'contiene' }, { id: 7, name: 'no contiene' }, { id: 8, name: 'comienza con' },
        { id: 9, name: 'no comienza con' }, { id: 10, name: 'termina con' }, { id: 11, name: 'no termina con' },
      ],
      valueForCondition: DEFAULT_VALUE_FOR_CONDITION,
      filtersColumns: [
        { id: 1, name: 'Imagen' },
        { id: 2, name: 'Título' },
        { id: 3, name: 'Contenido' },
        { id: 4, name: 'Autor' },
        { id: 5, name: 'Fecha' },
        { id: 6, name: 'Redirección' },
        { id: 7, name: 'Tiempo Lectura' },
      ],
      maxLengthForFilterStatement: 24,
      maxLengthOfColumnPartForFilter: 4,
      currentDataSource,
      containerID: _.get(container, 'id', -1),
      imageCell: columnsDataProcessed.cells.image,
      showImageCell: columnsDataProcessed.shows.image,
      titleCell: columnsDataProcessed.cells.title,
      showTitleCell: columnsDataProcessed.shows.title,
      contentCell: columnsDataProcessed.cells.content,
      showContentCell: columnsDataProcessed.shows.content,
      authorCell: columnsDataProcessed.cells.author,
      showAuthorCell: columnsDataProcessed.shows.author,
      dateCell: columnsDataProcessed.cells.date,
      showDateCell: columnsDataProcessed.shows.date,
      redirectCell: columnsDataProcessed.cells.redirect,
      showRedirectCell: columnsDataProcessed.shows.redirect,
      lectureTimeCell: columnsDataProcessed.cells.lectureTime,
      showLectureTimeCell: columnsDataProcessed.shows.lectureTime,
      triggerStyles: {
        width: '22px',
        height: '22px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
      },
      swatchStyles: {
        width: '23px',
        height: '24px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
        boxSizing: 'content-box',
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '3px',
        marginBottom: '3px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      initialTitle: _.get(container, 'title', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialShowSearch: _.get(container, 'showSearch', true),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialContentOrientation: _.get(container, 'app.contentOrientation', 'horizontal'),
      initialDataSource: currentDataSource,
      initialImageCell: columnsDataProcessed.cells.image,
      initialShowImageCell: columnsDataProcessed.shows.image,
      initialTitleCell: columnsDataProcessed.cells.title,
      initialShowTitleCell: columnsDataProcessed.shows.title,
      initialContentCell: columnsDataProcessed.cells.content,
      initialShowContentCell: columnsDataProcessed.shows.content,
      initialAuthorCell: columnsDataProcessed.cells.author,
      initialShowAuthorCell: columnsDataProcessed.shows.author,
      initialDateCell: columnsDataProcessed.cells.date,
      initialShowDateCell: columnsDataProcessed.shows.date,
      initialRedirectCell: columnsDataProcessed.cells.redirect,
      initialShowRedirectCell: columnsDataProcessed.shows.redirect,
      initialLectureTimeCell: columnsDataProcessed.cells.lectureTime,
      initialShowLectureTimeCell: columnsDataProcessed.shows.lectureTime,
      initialFiltersChecked: initialFilters.checked,
      initialFiltersUnchecked: initialFilters.unchecked,
      initialApplyFilters: _.get(container, 'app.applyFilters', false),
      appTypes: APP_TYPES,
      appIcons: APP_ICONS,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      tooltipF: 'https://docs.google.com/spreadsheets/d/1-uljzGI7qjUCWv-VMNlRglx5zkw4ZCxxmJzUxypQwUY/edit?usp=sharing',
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    polymorphicAppIdJS() {
      return `#${this.polymorphicAppID}`;
    },
    constructColumnsData() {
      return {
        cells: {
          image: this.imageCell,
          title: this.titleCell,
          content: this.contentCell,
          author: this.authorCell,
          date: this.dateCell,
          redirect: this.redirectCell,
          lectureTime: this.lectureTimeCell },
        shows: {
          image: this.showImageCell,
          title: this.showTitleCell,
          content: this.showContentCell,
          author: this.showAuthorCell,
          date: this.showDateCell,
          redirect: this.showRedirectCell,
          lectureTime: this.showLectureTimeCell },
      };
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        showDataSource: this.showDataSource,
        showSearch: this.showSearch,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          dataSource: this.selectedDataSource,
          columnsData: this.constructColumnsData,
          contentOrientation: this.contentOrientation,
          applyFilters: this.applyFilters,
          filtersChecked: this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked),
          filtersUnchecked: this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked),
        },
        appType: 'ArticleApp',
      };
    },
    canSave() {
      const canSave = !!(this.requiredAppInputsFilled);
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    cellsAnalysis() {
      return (this.initialImageCell !== this.imageCell ||
      this.initialShowImageCell !== this.showImageCell ||
      this.initialTitleCell !== this.titleCell ||
      this.initialTitleCell !== this.titleCell ||
      this.initialShowTitleCell !== this.showTitleCell ||
      this.initialContentCell !== this.contentCell ||
      this.initialShowContentCell !== this.showContentCell ||
      this.initialAuthorCell !== this.authorCell ||
      this.initialShowAuthorCell !== this.showAuthorCell ||
      this.initialDateCell !== this.dateCell ||
      this.initialShowDateCell !== this.showDateCell ||
      this.initialRedirectCell !== this.redirectCell ||
      this.initialShowRedirectCell !== this.showRedirectCell ||
      this.initialLectureTimeCell !== this.lectureTimeCell ||
      this.initialShowLectureTimeCell !== this.showLectureTimeCell);
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialShowBorders !== this.showBorders ||
        this.initialContentOrientation !== this.contentOrientation ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) ||
        JSON.stringify(this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked)) !==
          JSON.stringify(this.initialFiltersChecked) ||
        JSON.stringify(this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked)) !==
          JSON.stringify(this.initialFiltersUnchecked) ||
        this.initialApplyFilters !== this.applyFilters ||
        this.cellsAnalysis
      ) {
        return true;
      }

      return false;
    },
    cellsFilled() {
      const fields = [this.imageCell, this.titleCell, this.contentCell,
        this.authorCell, this.dateCell, this.redirectCell, this.lectureTimeCell];

      return !(fields.includes(null) || fields.includes(''));
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (['gsheet', 'excel'].includes(service)) {
          if (dataSourceInAnalysis.name !== null &&
              ['table', 'excel'].includes(dataSourceInAnalysis.dataSourceType) &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'custom') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table_internal' &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
              dataSourceInAnalysis.source_id !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone && this.cellsFilled && this.changesToSend;
    },
    sliderUpdaterCount() {
      return this.appAttrs[this.containerID].sliderUpdaterCount;
    },
    lectureTimeId() {
      return `show-lecture-time-${this.containerID}`;
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      selectedDataSource: state => state.users.selectedDataSource,
      saveAlert: state => state.ui.saveAlert,
      appAttrs: state => state.views.appsAttrs,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    fromFiltersToFilterField(filters) {
      const filtersAsText = filters.map(filter =>
        [filter.column, filter.condition, filter.conditionValue].join('|'),
      );

      return filtersAsText;
    },
    defineFilters(container) {
      const filtersChecked = _.get(container, 'app.filtersChecked', []);
      const filtersUnchecked = _.get(container, 'app.filtersUnchecked', []);
      this.defineFiltersChecked(filtersChecked);
      this.defineFiltersUnchecked(filtersUnchecked);

      return {
        checked: this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked),
        unchecked: this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked),
      };
    },
    defineFiltersChecked(filtersCheckedOrigin) {
      const filtersChecked = [];
      if (![undefined, null].includes(filtersCheckedOrigin) &&
          filtersCheckedOrigin.length !== 0
      ) {
        for (const filterText of filtersCheckedOrigin) {
          const filterApplied = filterText.split('|');
          const oldFilter = { column: filterApplied[0],
            condition: filterApplied[1], conditionValue: filterApplied[2],
          };
          filtersChecked.push(oldFilter);
        }
      }
      this.$store.commit(SET_FILTERS_CHECKED, filtersChecked);
    },
    defineFiltersUnchecked(filtersUncheckedOrigin) {
      const filtersUnchecked = [];
      if (![undefined, null].includes(filtersUncheckedOrigin) &&
          filtersUncheckedOrigin.length !== 0
      ) {
        for (const filterText of filtersUncheckedOrigin) {
          const filterApplied = filterText.split('|');
          const oldFilter = { column: filterApplied[0],
            condition: filterApplied[1], conditionValue: filterApplied[2],
          };
          filtersUnchecked.push(oldFilter);
        }
      }
      this.$store.commit(SET_FILTERS_UNCHECKED, filtersUnchecked);
    },
    filterDefaultState() {
      this.columnFilter = DEFAULT_COLUMN_FILTER;
      this.filterCondition = DEFAULT_FILTER_CONDITION;
      this.valueForCondition = DEFAULT_VALUE_FOR_CONDITION;
    },
    addFilter() {
      if (this.columnFilter.name === 'Columna' ||
          this.filterCondition.name === 'Condición' ||
          this.valueForCondition === '') {
        this.showMessage('Se requiere columna, condición y valor para agregar un nuevo filtro');
      } else {
        const checkedToUpdate = [...this.$store.state.ui.filtersChecked];
        const newFilter = {
          column: this.columnFilter.name,
          condition: this.filterCondition.name,
          conditionValue: this.valueForCondition,
        };
        checkedToUpdate.splice(checkedToUpdate.length, 0, newFilter);
        this.$store.commit(SET_FILTERS_CHECKED, checkedToUpdate);
        this.showMessage('Filtro agregado exitosamente');
        this.filterDefaultState();
      }
    },
    onColumnFilterSelect(value) {
      this.columnFilter = value;
    },
    onConditionFilterSelect(value) {
      this.filterCondition = value;
    },
    resetCheckedAndUncheckedFilters() {
      this.$store.commit(SET_FILTERS_CHECKED, []);
      this.$store.commit(SET_FILTERS_UNCHECKED, []);
    },
    fromFilterHashToText(filterHash) {
      const text = [filterHash.column, filterHash.condition, filterHash.conditionValue].join(' ');

      return text;
    },
    firstPossibleText(filter) {
      const columnValue = filter.column;
      const columnPart = columnValue.slice(0, this.maxLengthOfColumnPartForFilter).toUpperCase();
      let spaceOrDots;
      if (columnValue.length < this.maxLengthOfColumnPartForFilter) {
        spaceOrDots = ' ';
      } else {
        spaceOrDots = '... ';
      }
      const text = columnPart.concat(spaceOrDots, filter.condition, ' ', filter.conditionValue);

      return text;
    },
    secondPossibleText(filter, textAndColumnPart) {
      let text = textAndColumnPart[0];
      let spaceOrDots;
      const delta = this.maxLengthForFilterStatement - text.length;
      const columnPart = filter.column.slice(0, delta + this.maxLengthOfColumnPartForFilter).toUpperCase();
      if (delta + this.maxLengthOfColumnPartForFilter > filter.column.length) {
        spaceOrDots = ' ';
      } else {
        spaceOrDots = '... ';
      }
      text = columnPart.concat(spaceOrDots, filter.condition, ' ', filter.conditionValue);

      return text;
    },
    filterTruncation(filter) {
      let text = this.firstPossibleText(filter);
      if (text.length < this.maxLengthForFilterStatement &&
          filter.column.length >= this.maxLengthOfColumnPartForFilter
      ) {
        text = this.secondPossibleText(filter, text);
      }

      return text;
    },
    disableCheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersChecked.findIndex(filter => filter === filterApplied);
      this.$store.commit(REMOVE_FILTER_CHECKED, index);
      const uncheckedToUpdate = [...this.$store.state.ui.filtersUnchecked];
      uncheckedToUpdate.splice(uncheckedToUpdate.length, 0, filterApplied);
      this.$store.commit(SET_FILTERS_UNCHECKED, uncheckedToUpdate);
    },
    enableUncheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersUnchecked.findIndex(filter => filter === filterApplied);
      this.$store.commit(REMOVE_FILTER_UNCHECKED, index);
      const checkedToUpdate = [...this.$store.state.ui.filtersChecked];
      checkedToUpdate.splice(checkedToUpdate.length, 0, filterApplied);
      this.$store.commit(SET_FILTERS_CHECKED, checkedToUpdate);
    },
    deleteCheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersChecked.findIndex(filter => filter === filterApplied);
      this.$store.dispatch(SELECT_CHECKED_FILTER_INDEX_FOR_DELETION, { index });
      this.deletingFilter = true;
    },
    deleteUncheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersUnchecked.findIndex(filter => filter === filterApplied);
      this.$store.dispatch(SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION, { index });
      this.deletingFilter = true;
    },
    processColumnsData(columnsData) {
      if (columnsData) {
        return columnsData;
      }

      return {
        cells: { title: 'B', redirect: 'C', image: 'D', content: 'E', author: 'F', date: 'G', lectureTime: 'H' },
        shows: { image: true, title: true, content: true, author: true, date: true, redirect: true, lectureTime: true },
      };
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
      this.resetInitialValues();
      this.updateAppAttrs();
      this.simClick();
    },
    resetCells() {
      this.initialImageCell = this.imageCell;
      this.initialTitleCell = this.titleCell;
      this.initialContentCell = this.contentCell;
      this.initialAuthorCell = this.authorCell;
      this.initialDateCell = this.dateCell;
      this.initialRedirectCell = this.redirectCell;
      this.initialLectureTimeCell = this.lectureTimeCell;
    },
    resetShows() {
      this.initialShowImageCell = this.showImageCell;
      this.initialShowTitleCell = this.showTitleCell;
      this.initialShowContentCell = this.showContentCell;
      this.initialShowAuthorCell = this.showAuthorCell;
      this.initialShowDateCell = this.showDateCell;
      this.initialShowRedirectCell = this.showRedirectCell;
      this.initialShowLectureTimeCell = this.showLectureTimeCell;
    },
    resetInitialValues2() {
      this.initialContentOrientation = this.contentOrientation;
      this.resetCells();
      this.resetShows();
      this.initialApplyFilters = this.applyFilters;
      this.initialFiltersChecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked);
      this.initialFiltersUnchecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked);
    },
    resetInitialValues() {
      this.initialDataSource = this.selectedDataSource;
      this.initialTitle = this.title;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.initialShowShading = this.showShading;
      this.initialShowDataSource = this.showDataSource;
      this.initialShowSearch = this.showSearch;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialIsPrivate = this.isPrivate;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    updatePolymorphicApp() {
      console.log('updating polymorphic');
      if (this.contentOrientation === 'vertical') {
        console.log('vertical');
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper').css('justify-content', 'flex-start');
      }
    },
    simClick() {
      const lectureTimeElement = document.getElementById(this.lectureTimeId);
      if (lectureTimeElement) {
        lectureTimeElement.click();
      }
      setTimeout(() => {
        const lectureTimeElement2 = document.getElementById(this.lectureTimeId);
        if (lectureTimeElement2) {
          lectureTimeElement2.click();
        }
      }, TIME_BETWEEN_CLICKS);
    },
    updateAppAttrs() {
      const newSliderUpdaterCount = this.sliderUpdaterCount + 1;
      const params = {
        imageCell: this.imageCell,
        showImageCell: this.showImageCell,
        titleCell: this.titleCell,
        showTitleCell: this.showTitleCell,
        contentCell: this.contentCell,
        showContentCell: this.showContentCell,
        authorCell: this.authorCell,
        showAuthorCell: this.showAuthorCell,
        dateCell: this.dateCell,
        showDateCell: this.showDateCell,
        redirectCell: this.redirectCell,
        showRedirectCell: this.showRedirectCell,
        lectureTimeCell: this.lectureTimeCell,
        showLectureTimeCell: this.showLectureTimeCell,
        contentOrientation: this.contentOrientation,
        sliderUpdaterCount: newSliderUpdaterCount,
        dataSource: this.selectedDataSource,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  width: 61.15px;
  height: 32px;
}

.cells2 {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  color: black;
  border: 0.5px solid #7A7A7A;
  border-right-style: none;
  border-radius: 3px 0 0 3px;
  opacity: 1;
  height: 32px;
  padding-top: 5.4px;
  padding-left: 10px;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}

/* FILTERS SECTION STYLE start*/
.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  /* background-color: rgb(191, 203, 217); */
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  /* background-color: #fff; */
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}
/* FILTERS SECTION STYLE end*/

</style>
