import _ from 'lodash';

export default {
  selectedFolder: state => state.selectedFolder,
  selectedView: state => state.selected || state.list[state.selectedId],
  selectedContainer: state => {
    if (state.appContainers) {
      const container = state.appContainers[state.selectedContainerId];
      if (container) return container;

      return state.selectedContainer;
    }
  },
  containerComments: state => (id) => {
    if (id === null || id === undefined) return [];

    return _.get(state, `appContainers.[${id}].comments`).map(commentId => state.comments[commentId]);
  },
  containerViewVariables: state => id => {
    // const viewVars = _.get(state, 'appContainers')[id].app.fieldViewVarsRefs;

    return {};
  },
  threadComments: state => id =>
    _.get(state, `comments.[${id}].threadComments`).map(commentId => state.comments[commentId]),
  threadCommentsCount: state => id =>
    _.get(state, `comments.[${id}].threadComments.length`),
  selectedContainerComments: (state, getters) => getters.containerComments(state.selectedContainerId),
  selectedModalOfType: state => type => state.appModalType && state.appModalType === type,
  selectedModalToShow: state => id => state.appModalToShow === id,
  selectedModalOfAppName: state => appName => state.appModalName === appName,
  appInstanceSelected: state => () => !!state.appModalType,
  containersByRow: state => _.groupBy(_.sortBy(state.appContainers, 'orderInRow'), 'row'),
  containersInRow: (_state, getters) => row => getters.containersByRow[row] || [],
  otherContainersInRow: (_state, getters) => container =>
    _.filter(getters.containersInRow(container.row), otherContainer => otherContainer.id !== container.id),
  containersWithGteRow: state => row =>
    state.appContainers && _.filter(state.appContainers, container => container.row >= row),
  rowWidth: (_state, getters) => row =>
    getters.containersInRow(row).reduce((sum, container) => sum + container.width, 0),
  rowCount: (_state, getters) => row => getters.containersInRow(row).length,
  numberOfRows: (_state, getters) => _.keys(getters.containersByRow).length,
  orderedContainers: (state) => _.sortBy(state.appContainers, ['row', 'orderInRow']),
  containerPublications: state => (id) => {
    if (id === null || id === undefined) return [];

    return _.get(state, `appContainers.[${id}].publications`).map(publicationId => state.publications[publicationId]);
  },
  publicationComments: state => id => {
    const comments = _.get(state, `publications.[${id}].comments`);
    if (comments === null || comments === undefined) return [];

    return _.get(state, `publications.[${id}].comments`).map(commentId => state.comments[commentId]);
  },
  publicationCommentsCount: state => id =>
    _.get(state, `publications.[${id}].comments.length`),
};
