export default {
  dashboard: {
    title: 'My Unit',
  },
  workspace: {
    title: 'Workspace',
  },
  view: {
    share: {
      title: 'Share view {viewTitle}',
      publicLink: 'Public link',
      addEmail: 'Add Email',
    },
    thumbnail: 'Upload thumbnail',
    save: 'Save',
    listTitle: 'Views',
    newViewButton: 'Bigpicture',
    newFolderButton: 'Folder',
    edit: 'Edit',
    copy: 'Duplicate',
    copyAsTemplate: 'Dup. as Template',
    shareButton: 'Share',
    settingsButton: 'Settings',
    settingsTitle: 'Settings',
    settings: {
      title: 'View Settings',
      titleTemplate: 'Template Settings',
      button: 'Settings',
      variablesTitle: 'Variables',
      addVariableButton: 'New Variable',
    },
    duplicateButton: 'Duplicate',
    show: 'Show',
    goBack: 'Go Back',
    appsTitle: 'Elements',
    customAppsTitle: 'Apps',
    delete: 'Delete View',
    folder: {
      title: 'Folder',
      name: 'Name',
      delete: 'Delete Folder',
      deleteShort: 'Delete',
      move: 'Move',
      edit: 'Edit',
    },
    duplicateSuccessMessage: 'This view has been duplicated successfully!',
    duplicateFailedMessage: '¡An error has ocurred. The view was not duplicated!',
    implementSuccessMessage: 'This view has been duplicated successfully!',
    implementAsTemplateSuccessMessage: 'This view has been duplicated as template successfully!',
    implementFailedMessage: 'An error has ocurred. The view was not duplicated!',
    apps: {
      delete: 'Delete app',
      title: 'Title',
      row: 'Row',
      rowOrder: 'Row Order',
      width: 'Width',
      doesNotFitMessage: 'App does not fit in this position',
      comments: 'Comments',
      title: 'Show Title',
      showBorders: 'Show Borders',
      isPrivate: 'Private',
      text: {
        name: 'Text',
        writeText: '<- Write your text in the Container',
      },
      source: {
        name: 'Source',
        writeYourCode: 'Write your code',
      },
      file: {
        name: 'File',
        chooseImage: 'Choose a File',
        chooseImageMessage: 'Select a file from your computer',
      },
      image: {
        name: 'Image',
        chooseImage: 'Choose an image',
        chooseImageMessage: 'Select an image from your computer',
      },
      tab: {
        name: 'Tabs',
        writeHere: 'Write here...',
        tabColor: 'Tab color',
        fontColor: 'Font color',
      },
      menu: {
        name: 'Menu',
        formName: 'Items',
      },
      feed: {
        name: 'Feed',
        deleteComment: 'Delete Comment',
        respondToComment: 'Reply',
        showComments: 'Comments ({number})',
        closeComments: 'Close',
      },
      space: {
        name: 'Space',
        height: 'Height (only need it when the space uses a full row)',
      },
      duplicateSuccessMessage: 'This app has been duplicated successfully!',
      duplicateFailedMessage: 'An error has ocurred. The app was not duplicated!',
    },
    listTableTitles: {
      name: 'Name',
      tenant: 'Workspace',
      owner: 'Owner',
      updatedAt: 'Last Updated',
    },
  },
  resource: {
    noTitle: 'No title',
    share: {
      addEmail: 'Add email',
      addTag: 'Or Share with tagged Users',
    },
    move: {
      current: 'Current Folder',
      destination: 'Move To:',
      selectDestinationFolder: 'Select Destination Folder',
    },
  },
  template: {
    title: 'Template | Templates',
    name: 'Name',
    newButton: 'New Template',
    save: 'Save',
    delete: 'Delete Template',
    use: 'Use Template',
    password: 'Password',
    verifyPassword: 'Verify Password',
    passwordLengthMessage: 'Password must be at least 6 characters',
    passwordMatchMessage: 'Passwords must match',
    goToLogIn: 'Go to Login',
    duplicateSuccessMessage: 'This template has been used successfuly!',
    duplicateFailedMessage: 'An error has ocurred. This template was not used!',
  },
  generalActions: {
    confirmDeleteMessage: 'This action can not be undone. Do you want to continue?',
    cancel: 'Cancel',
    save: 'Save',
  },
  user: {
    name: 'User | Users',
    invite: 'Invite a new user',
    inviteMember: 'Invite a new Team Member',
    inviteConsultant: 'Invite a new Consultant',
    manage: 'Team',
    nameTag: 'Full Name',
    lastNameTag: 'Last Name',
    email: 'Email',
    rol: 'Rol',
    delete: 'Delete User',
    config: 'My Account',
    logInAgain: 'You must log in again',
    logout: 'Logout',
    tags: 'Tags',
    deleteTag: 'Delete Tag',
    deleteWorkspace: 'Delete Workspace',
    tagsModal: {
      title: 'Add a new group',
      tagPlaceholder: 'Select tags',
      userPlaceholder: 'Selected Users',
    },
  },
  connection: {
    listTitle: 'Connections',
    new: 'New Connection',
    delete: 'Delete Connection',
  },
  toasts: {
    connectionAlreadyExists: 'You are already connected to this service',
    dataSaved: 'Account information updated',
  },
  customAppTemplate: {
    title: 'Apps',
    new: 'New App',
    workspaceTitle: 'Integrated Apps',
    delete: 'Delete App',
    disable: 'Disable App',
    affectedViews: 'The following views would be affected:',
    noAffectedViews: 'There are no views using this app',
    confirmDisable:
      'Disabling an app would remove it from the views in which it was used, permanently. Do you want to continue?',
  },
};
