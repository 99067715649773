export default {
  data() {
    return {
      position: {},
    };
  },
  methods: {
    getPosition(element) {
      const style = element.style.cssText;
      const dataX = element.getAttribute('data-x');
      const dataY = element.getAttribute('data-y');
      const dataStartX = element.getAttribute('data-start-x');
      const dataStartY = element.getAttribute('data-start-Y');

      this.position = { style, dataX, dataY, dataStartX, dataStartY };
    },
    setPosition(element, newPosition) {
      if (newPosition === null) return;
      element.style = newPosition.style;
      element.setAttribute('data-x', newPosition.dataX);
      element.setAttribute('data-y', newPosition.dataY);
      // element.setAttribute('data-start-y', newPosition.dataStartY);
      element.setAttribute('data-start-y', newPosition.dataY);
      // element.setAttribute('data-start-x', newPosition.dataStartX);
      element.setAttribute('data-start-x', newPosition.dataX);
    },
  },
};
