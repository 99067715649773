<template>
  <div
    ref="feed"
    class="feed items-center align-center outline-none"
  >
    <div class="bg-white flex flex-column items-center w-full rounded-lg">
      <at-ta
        :tab-select="true"
        :members="members"
        name-key="name"
      >
        <template
          slot="item"
          slot-scope="s"
        >
          <img :src="s.item.avatar">
          <span
            v-text="s.item.name"
          />
        </template>
        <textarea-autoheight
          :style="{width: '100%'}"
          :ref="dynamicReference"
          v-model="newComment"
          @keyup.enter.exact.prevent="createComment"
          class="comment__input feed__new-comment-input outline-none"
          placeholder="Escribe tu comentario..."
        />
      </at-ta>
    </div>
    <!-- <div class=" bg-white flex">
      <buttom> reply </buttom>
      <buttom> cancel </buttom>
    </div> -->
    <div class="feed__comments feed__comments-expanded overflow-scroll">
      <comment
        v-for="(comment) in commentSorted"
        :key="comment.createdAt"
        :comment="comment"
        class="feed__comment border-b border-gray-400"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import AtTa from './at-textarea';
import { CREATE_APP_CONTAINER_COMMENT, CREATE_THREAD_COMMENT } from '../store/action-types';
import Comment from './comment';
import profilePlaceholder from '../images/upload-photo.png';

const RANDOM_SEED = 100;

export default {
  components: {
    Comment,
    AtTa,
  },
  props: {
    comments: { type: Array, required: true },
    containerId: { type: Number, default: null },
    parentId: { type: Number, default: null },
  },
  data() {
    return {
      newComment: null,
      editedContent: null,
    };
  },
  computed: {
    members() {
      const resource = this.$store.getters.selectedResource;
      const alreadySharedUsers = _.cloneDeepWith(this.$store.getters.selectedResourceSharedUsers(resource.type));

      return alreadySharedUsers.map((user) => {
        if (user.profilePictureUrl === undefined || user.profilePictureUrl === null) {
          user.profilePictureUrl = profilePlaceholder;
        }

        return {
          name: user.displayName,
          avatar: user.profilePictureUrl,
        };
      });
    },
    commentSorted() {
      return _.orderBy(this.comments, ['createdAt'], ['desc']);
    },
    userProfilePictureUrl() {
      return _.get(this.$store.getters.currentUser, 'profilePictureUrl');
    },
    dispatchCreateParams() {
      let params;
      if (this.containerId) {
        params = [CREATE_APP_CONTAINER_COMMENT, { content: this.newComment, containerId: this.containerId }];
      } else if (this.parentId) {
        params = [CREATE_THREAD_COMMENT, { content: this.newComment, parentCommentId: this.parentId }];
      }

      return params;
    },
    dynamicReference() {
      return `interactive${parseInt(Math.random() * RANDOM_SEED, 10)}`;
    },
  },
  methods: {
    show(s) {
      console.log(s);
    },
    createComment() {
      if (!this.newComment) return;
      // to handle the edgecase where the user press enter to select the mention
      this.newComment = event.target.value;
      const self = this;
      this.$store.dispatch(...this.dispatchCreateParams).then(() => {
        self.newComment = null;
        // self.$refs.feed.closest('.droppeable-app').style.minHeight = `${self.$refs.feed.closest('.app-container__content').offsetHeight + 30}px`;
      });
    },
  },
};
</script>
<style scoped>
.atwho-li img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #68778d;;
}

.atwho-li span {
  padding-left: .5em;
}
</style>
