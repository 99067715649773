<template>
  <div
    class="h-20 p-4 w-full flex flex-row text-sm text-indigo-600 border-gray-400 border-b items-center"
  >
    <div class="w-3/12 flex justify-left">
      <div class="bg-menu-hover text-icon-secondary  text-size-13 rounded-full py-2 px-4">
        {{ tag.tag }}
      </div>
    </div>
    <div class="w-8/12 flex items-center overflow-x-auto">
      <div
        class="mr-2"
        :key="index"
        v-for="(user,index) in tag.users"
      >
        <!-- <img
          :title="user.fullName"
          class="bg-gray-500 rounded-full h-10 w-10 object-cover"
          :src="profilePicture(user.profilePictureUrl)"
          alt="profile picture"
        > -->
        <img
          class="rounded-full h-10 w-10 bg-blue-700 object-cover"
          v-if="user.profilePictureUrl"
          :src="profilePicture(user.profilePictureUrl)"
        >
        <div
          v-else
          class="rounded-full h-10 w-10 bg-blue-700 object-cover uppercase text-white font-bold text-xl pl-1 pt-1"
        >
          {{ user.firstName[0] }}{{ user.lastName[0] }}
        </div>
      </div>
    </div>
    <div class="relative w-1/12 text-center">
      <i
        id="dropDown"
        ref="dropDown"
        @click="openDropdown"
        class="material-icons cursor-pointer"
      >more_horiz</i>
      <div
        v-if="dropdownOpen"
        class="shadow absolute bg-white rounded-lg z-10"
      >
        <div
          @click="openUserTagsModal"
          class="flex w-32 items-center align-center cursor-pointer"
        >
          <div class="text-center p-2">
            <i
              class="material-icons material-icons-inline"
            >edit</i>
          </div>
          <div class="text-center p-2 text-gray-600">
            Edit
          </div>
        </div>

        <div
          @click="selectTagForDeletion"
          class="flex w-32 items-center align-center cursor-pointer"
        >
          <div class="text-center p-2">
            <i
              class="material-icons material-icons-inline"
            >delete</i>
          </div>
          <div class="text-center p-2 text-gray-600">
            Delete
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  OPEN_USER_MODAL,
  OPEN_USER_TAGS_MODAL,
  // ADD_TENANT_TAGS,
  SET_TAG_MODAL_EDIT_MODE,
  SET_TAG_FOR_EDITION,
} from '../store/mutation-types';
import { SELECT_USER_FOR_DELETION, SELECT_USER_FOR_EDITION, SELECT_TAG_FOR_DELETION } from '../store/action-types';

import profilePlaceholder from '../images/upload-photo.png';

import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';

export default {
  mixins: [escapeMixinHandler, clickMixinHandler],
  components: {
  },
  props: {
    tag: { type: Object, required: true },
  },
  data() {
    return {
      dropdownOpen: false,
      profilePlaceholder,
    };
  },
  computed: {
    ...mapState({
      displayableUsers: state => state.users.list,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      userModalOpened: state => state.ui.userModalOpened,
      userTagsModalOpened: state => state.ui.userTagsModalOpened,
    }),
    usersActive() {
      return this.selectedTab === 'users';
    },
    tagsActive() {
      return this.selectedTab === 'tags';
    },

    appsActive() {
      return this.selectedTab === 'apps';
    },

    connectionsActive() {
      return this.selectedTab === 'connections';
    },
  },

  methods: {
    close() {
      this.dropdownOpen = false;
    },
    selectTagForDeletion() {
      this.$store.dispatch(SELECT_TAG_FOR_DELETION, this.tag);
    },
    openDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    openUserModal() {
      this.$store.commit(OPEN_USER_MODAL);
    },
    openUserTagsModal() {
      this.$store.commit(SET_TAG_MODAL_EDIT_MODE, true);
      this.$store.commit(SET_TAG_FOR_EDITION, this.tag);
      this.$store.commit(OPEN_USER_TAGS_MODAL);
    },
    selectForEdition(userId) {
      this.$store.dispatch(SELECT_USER_FOR_EDITION, userId);
    },
    selectForDeletion(userId) {
      this.$store.dispatch(SELECT_USER_FOR_DELETION, userId);
    },
    profilePicture(profilePictureUrl) {
      return profilePictureUrl || profilePlaceholder;
    },
  },
};
</script>
<style>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

</style>
