<template>
  <div
    :id="fileAppID"
  >
    <div
      v-if="container && !editable"
    >
      <div
        :id="titleID"
        class="self-left"
        style="margin-top: 20px; margin-bottom: 30px;"
        v-if="hasDescriptiveText"
        v-html="descriptiveText"
      />
    </div>
    <div
      v-if="!(container && !editable)"
    >
      <vue-editor
        v-if="hasDescriptiveText"
        class="self-left"
        style="margin-top: 80px; margin-bottom: 30px"
        :id="fileTitleEditor"
        v-model="descriptiveText"
        :editor-toolbar="customToolbar"
        @text-change="autoSaveDescriptiveText"
        @focus="toolbarShow2()"
        @blur="toolbarHide2()"
      />
    </div>
    <div
      class="flex flex-column flex-1 items-center justify-center"
      v-if="fileNames[containerID].filter(n => n !== '').length === 0"
    >
      <i class="material-icons icon">note_add</i>
    </div>
    <div
      class="flex flex-col father"
      v-for="(fileName, index) in fileNames[containerID]"
      :key="'file-' + index"
    >
      <a
        :href="fileUrls[containerID][index]"
        target="_blank"
        class="cursor-pointer"
      >
        <div
          v-if="container && !editable"
          ref="ButtonApp"
          class="h-full flex flex-row child"
          style="height: 25px;"
          :class="{
            'show-bg-transparent': showTransparency,
            'initial-margin': index === 0,
          }"
        >
          <div class="mr-1">
            <i
              v-if="['image/png', 'image/jpg', 'image/jpeg'].includes(contentTypes[index])"
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >image</i>
            <i
              v-else-if="contentTypes[index] === 'image/gif'"
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >gif_box</i>
            <i
              v-else-if="contentTypes[index] === 'application/pdf'"
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >picture_as_pdf</i>
            <i
              v-else-if="contentTypes[index] === 'application/zip'"
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >folder_zip</i>
            <i
              v-else-if="contentTypes[index] === 'audio/mpeg'"
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >audio_file</i>
            <i
              v-else
              class="material-icons button-icon2"
              style="font-size: 1.3em;"
            >file_download</i>
          </div>
          <div
            class="fileText underline-file"
            v-html="filenamesInAppCopy[index]"
          />
        </div>
      </a>
      <div
        v-if="!(container && !editable) && fileNames[containerID].filter(n => n !== '').length !== 0"
        class="h-full :overflow-hidden overflow-y-scroll child"
        style="height: 35px;"
        :class="{
          'show-bg-transparent': showTransparency,
          'initial-margin': index === 0,
        }"
        ref="fileApp"
      >
        <div
          class="flex flex-row"
          style="align-items: center;"
        >
          <i
            class="material-icons button-icon"
            style="font-size: 1.3em;"
          >attach_file</i>

          <div
            v-if="indexClick === index"
          >
            <vue-editor
              class="flex padding-horizontal-zero underline-file"
              :id="filenameEditor"
              v-model="filenamesInAppCopy[index]"
              :editor-toolbar="customToolbar"
              @text-change="autoSaveFilenamesInApp(index, filenamesInAppCopy[index])"
              @focus="toolbarShow()"
              @blur="toolbarHide()"
            />
          </div>
          <div
            v-else
          >
            <div
              class="fileText underline-file"
              v-html="filenamesInAppCopy[index]"
              @click="setIndexClick(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { VueEditor } from 'vue2-editor';
import $ from 'jquery';
import Quill from 'quill';
import { APP_TYPES } from '../constants';
import {
  SAVE_CONTAINER,
  UPDATE_FILENAMES,
  UPDATE_FILE_URLS,
  UPDATE_FILENAMES_IN_APP,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';

const DEBOUNCE_TIME = 1000;

export default {
  mixins: [positionMixin],
  components: {
    VueEditor,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
  },
  async mounted() {
    $('.ql-toolbar.ql-snow').hide();
    $('.ql-editor').attr('style',
      `min-height: 0px !important; padding-left: 0px; padding-right: 0px;
      padding-bottom: 0px; padding-top: 0px;`,
    );
    this.setContentCenterAlignment();
    this.colorSelected();
    this.initialFilenamesInApp = this.filenamesInApp[this.container.id];
  },
  data() {
    this.quillInit();
    const fileNames = _.get(this.container, 'app.fileNames', []).map(fileName => (fileName.replace(/<(.|\n)*?>/g, '')));
    const fileUrls = JSON.parse(JSON.stringify(_.get(this.container, 'app.fileUrls', [])));
    let filenamesInApp = JSON.parse(JSON.stringify(_.get(this.container, 'app.filenamesInApp', [])));
    if (filenamesInApp.length === 0) {
      filenamesInApp = Object.assign([], fileNames);
    }
    this.setFilenamesAndUrls(fileNames, fileUrls, filenamesInApp);
    this.defineFileAppAttrs();

    return {
      appTypes: APP_TYPES,
      customToolbar: customToolbarHelper.customToolbar,
      descriptiveText: _.get(this.container, 'app.descriptiveText', 'Texto Descriptivo'),
      initialDescriptiveText: _.get(this.container, 'app.descriptiveText', 'Texto Descriptivo'),
      containerID: _.get(this.container, 'id', -1),
      contentTypes: _.get(this.container, 'app.contentTypes', []),
      filenamesModels: Object.assign([], filenamesInApp),
      indexClick: -1,
      initialFilenamesInApp: null,
    };
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      fileNames: state => state.ui.fileNames,
      fileUrls: state => state.ui.fileUrls,
      filenamesInApp: state => state.ui.filenamesInApp,
      appAttrs: state => state.views.appsAttrs,
    }),
    filenamesInAppCopy() {
      const containerID = _.get(this.container, 'id', -1);
      if (containerID in this.filenamesInApp) {
        return [...this.filenamesInApp[containerID]];
      }

      return [];
    },
    filenames() {
      return _.get(this.container, 'app.fileNames', []);
    },
    editorId() {
      return `text-editor${this.containerID}`;
    },
    textEditor() {
      return '#text-editor'.concat(this.containerID.toString());
    },
    containerBackgroundColor() {
      return _.get(this.container, 'backgroundColor', '#FFFFFF');
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    showTransparency() {
      return _.get(this.container, 'showTransparency', false);
    },
    fileAppID() {
      return `file-app${this.containerID}`;
    },
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    titleID() {
      return `file-title${this.containerID}`;
    },
    hasDescriptiveText() {
      return this.appAttrs[this.container.id].hasDescriptiveText;
    },
    filenameEditor() {
      return `filename-editor${this.containerID}`;
    },
    filenameEditorJS() {
      return `#${this.filenameEditor}`;
    },
    fileTitleEditor() {
      return `file-title-editor${this.containerID}`;
    },
    fileTitleEditorJS() {
      return `#${this.fileTitleEditor}`;
    },
  },
  methods: {
    quillInit() {
      const Font = Quill.import('formats/font');
      Font.whitelist = [
        'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
        'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
        'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
        'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
      ];
      Quill.register(Font, true);
      const Size = Quill.import('attributors/style/size');
      Size.whitelist = [
        '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
      ];
      Quill.register(Size, true);
    },
    setIndexClick(index) {
      this.indexClick = index;
    },
    setFilenamesAndUrls(initialFileNames, initialFileUrls, filenamesInApp) {
      const containerID = _.get(this.container, 'id', -1);
      const params = { containerId: containerID, value: initialFileNames };
      this.$store.dispatch(UPDATE_FILENAMES, { params });
      params.value = initialFileUrls;
      this.$store.dispatch(UPDATE_FILE_URLS, { params });
      params.value = filenamesInApp;
      this.$store.dispatch(UPDATE_FILENAMES_IN_APP, { params });
    },
    setContentCenterAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content:', 'center');
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.filenameEditorJS).prev().hide();
    },
    toolbarShow() {
      $(this.filenameEditorJS).prev().show();
    },
    toolbarHide2() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.fileTitleEditorJS).prev().hide();
    },
    toolbarShow2() {
      $(this.fileTitleEditorJS).prev().show();
    },
    hasContainer() {
      return !_.isEmpty(this.container);
    },
    autoSaveFilenamesInApp: _.debounce(function (index, filenameInApp) {
      this.addFilenameInApp(index, filenameInApp);
      this.updateFilenamesInApp();
    }, DEBOUNCE_TIME),
    autoSaveDescriptiveText: _.debounce(function () {
      this.updateDescriptiveTextContent();
    }, DEBOUNCE_TIME),
    addFilenameInApp(index, filenameInApp) {
      const newFilenamesInApp = Object.assign([], this.filenamesInApp[this.containerID]);
      newFilenamesInApp.splice(index, 1, filenameInApp);
      const params = { containerId: this.containerID, value: newFilenamesInApp };
      this.$store.dispatch(UPDATE_FILENAMES_IN_APP, { params });
    },
    updateFilenamesInApp() {
      if (this.initialFilenamesInApp !== this.filenamesInApp[this.containerID]) {
        const params = { app: { filenamesInApp: this.filenamesInApp[this.containerID] } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        this.initialFilenamesInApp = Object.assign([], this.filenamesInApp[this.containerID]);
      }
    },
    updateDescriptiveTextContent() {
      if (this.initialDescriptiveText !== this.descriptiveText) {
        const params = { app: { descriptiveText: this.descriptiveText } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        const initialDescriptiveText = this.descriptiveText;
        this.initialDescriptiveText = initialDescriptiveText;
      }
    },
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
    },
    applyBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID);
      }
    },
    defineFileAppAttrs() {
      const params = {
        hasDescriptiveText: _.get(this.container, 'app.hasDescriptiveText', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
  },
};
</script>
<style scoped>
.button-icon {
  font-size: 26px;
  width: 20px;
  color: #0031FF;
  padding-left: 0px !important;
  opacity: 1;
  border-radius: 50px;
}

.button-icon2 {
  font-size: 26px;
  width: 20px;
  height: 62px;
  color: #0031FF;
  padding-top: 4px;
  padding-left: 0px !important;
  opacity: 1;
  border-radius: 50px;
}

.show-bg-transparent {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.padding-horizontal-zero {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.app-container__content .ql-editor {
  padding: 0px 0px !important;
}

.icon-bottom-padding {
  padding-bottom: 10px;
}

.icon-top-padding {
  padding-top: 10px;
}

.center-elements {
  justify-content: center;
  align-items: center;
}

.transparent-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.underline-file {
  text-decoration: underline;
  color: #0031FF;
}

.ql-editor-edited {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.fileText {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.father .child {
  margin-bottom: 15%;
  text-align: center;
}

.initial-margin {
  margin-top: 5px;
}

.show-text-editor-toolbar {
  display: all !important;
}

.hide-text-editor-toolbar {
  display: none !important;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  font-size: 70px;
}

</style>
