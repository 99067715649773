<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">call_to_action_icon</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Valor Único
        </div>
      </div>
    </div>
    <div>
      <single-value-data-sources-selector
        :current-datasource="selectedDataSource"
        :tooltip-file-uri="tooltipF"
        :container="container"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Texto descriptivo
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasDescriptiveText"
              :sync="true"
              @input="hasDescriptiveText = !hasDescriptiveText; updateAppAttrs();"
            />
          </div>
          <div class="flex flex-row mt-4">
            <p class="text-left text-menu-label text-sm font-inter-type-1 mt-1">
              Alinear
            </p>
            <div
              class="flex flex-row mt-1"
              style="margin-left: 9px;"
            >
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'right' }"
                @click="changeContentAlignment('right')"
              >align_horizontal_right_icon</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'left' }"
                @click="changeContentAlignment('left')"
              >align_horizontal_left_icon</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'center' }"
                @click="changeContentAlignment('center')"
              >filter_center_focus_icon</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'bottom' }"
                @click="changeContentAlignment('bottom')"
              >align_vertical_bottom_icon</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'top' }"
                @click="changeContentAlignment('top')"
              >align_vertical_top_icon</i>
            </div>
          </div>
          <div
            class="border-t mt-4"
            style="margin-right: 14.14px"
          />
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasIcon"
              :sync="true"
              @input="hasIcon = !hasIcon; updateAppAttrs();"
            />
          </div>
          <div class="pl-5 flex flex-col mt-5">
            <div class="flex flex-row">
              <p
                class="text-left text-menu-label text-sm font-inter-type-1"
                style="margin-top: 5px;"
              >
                Elegir
              </p>
              <ToolbarIconSelector
                class="ml-5 text-center text-sm vue-select-container icon-selection-container"
                :data="iconList"
                v-model="iconObject"
                @documentListener="updateIconOnContainer"
                :placeholder="'ícono...'"
                :listener="'documentListener'"
              />
            </div>
          </div>
          <div class="pl-5 flex flex-col mt-2">
            <div class="flex flex-row">
              <p
                class="text-left text-menu-label text-sm font-inter-type-1 mt-1"
              >
                Posición
              </p>
              <div
                class="pl-2 flex flex-row"
              >
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': iconPosition == 'right' }"
                  @click="changeIconPosition('right')"
                >align_horizontal_right_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': iconPosition == 'left' }"
                  @click="changeIconPosition('left')"
                >align_horizontal_left_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': iconPosition == 'bottom' }"
                  @click="changeIconPosition('bottom')"
                >align_vertical_bottom_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': iconPosition == 'top' }"
                  @click="changeIconPosition('top')"
                >align_vertical_top_icon</i>
              </div>
            </div>
            <div class="flex flex-row mt-5">
              <p
                class="text-left text-menu-label text-sm font-inter-type-1 mt-1"
              >
                Tamaño
              </p>
              <VueSelectStandard
                class="ml-1 text-center text-sm vue-select-container icon-selection-container"
                :data="sizesList"
                v-model="sizeObject"
                @documentListener="updateIconSizeOnContainer"
                :placeholder="'Elegir tamaño...'"
                :listener="'documentListener'"
              />
            </div>
            <div class="mt-2">
              <div
                class="flex form__field"
                style="margin-bottom: 176px"
              >
                <p class="w-4/6 text-left text-menu-label text-sm font-inter-type-1 mt-1">
                  Color
                </p>
                <div
                  class="w-2/6 mb-0 pb-0 form__input"
                  @click="changeSize()"
                >
                  <v-swatches
                    class="mr-14"
                    :swatches="iconColors"
                    :trigger-style="triggerStyles"
                    :swatch-style="swatchStyles"
                    show-fallback
                    fallback-input-type="color"
                    v-model="iconColor"
                    @input="updateIconColor"
                    row-length="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import $ from 'jquery';
import Collapsible from 'vue-collapsible-component';
import VSwatches from 'vue-swatches';
import VueSelectStandard from './vue-select-standard';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import SingleValueDataSourcesSelector from './single-value-data-sources-selector';
import ToolbarIconSelector from './toolbar-icon-selector.vue';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import iconNames from '../helpers/material-icon-names';
import { CONTAINER_COLORS } from '../constants';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DECIMAL_BASE = 10;
const DEFAULT_ICON_FONT_SIZE = 26;
const DEFAULT_ICON_SIZE = 62;
const DEFAULT_ICON_PADDING = 18;

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    Collapsible,
    VueSelectStandard,
    SingleValueDataSourcesSelector,
    VSwatches,
    ToolbarIconSelector,
    AppTitleInOptions,
    ContainerSection,
  },
  props: {
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const currentDataSource = this.$store.state.users.selectedDataSource;
    const sizes = ['0.5X', '1X', '1.5X', '2X', '2.5X', '3X'];
    let initialIconSize = _.get(container, 'app.iconSize', '1X');
    if (typeof initialIconSize === 'number') {
      initialIconSize = initialIconSize.toString().concat('X');
    }

    return {
      template,
      container,
      showBorders: _.get(container, 'showBorders', false),
      showShading: _.get(container, 'showShading', false),
      showDataSource: _.get(container, 'showDataSource', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      showTitle: _.get(container, 'showTitle', false),
      showTransparency: _.get(container, 'showTransparency', false),
      currentDataSource,
      hasDescriptiveText: _.get(container, 'app.hasDescriptiveText', true),
      descriptiveText: _.get(container, 'app.descriptiveText', 'Texto Descriptivo'),
      descriptiveTextHistory: _.get(container, 'app.DescriptiveTextHistory', []),
      contentAlignment: _.get(container, 'app.contentAlignment', 'left'),
      hasIcon: _.get(container, 'app.hasIcon', false),
      icon: _.get(container, 'app.icon', 'group_icon'),
      iconPosition: _.get(container, 'app.iconPosition', 'top'),
      iconColor: _.get(container, 'app.iconColor', '#0031FF'),
      containerID: _.get(container, 'id', -1),
      iconList: iconNames.iconsList.map(
        (icon) => ({ id: iconNames.iconsList.indexOf(icon), name: icon })),
      sizesList: sizes.map(
        (iconSize) => ({ id: sizes.indexOf(iconSize), name: iconSize })),
      iconColors: CONTAINER_COLORS,
      triggerStyles: {
        width: '22px',
        height: '22px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
      },
      swatchStyles: {
        width: '23px',
        height: '24px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
        boxSizing: 'content-box',
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '3px',
        marginBottom: '3px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      iconSizeUpdater: null,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialShowBorders: _.get(container, 'showBorders', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTitle: _.get(container, 'showTitle', false),
      initialShowTransparency: _.get(container, 'showTransparency', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialDataSource: currentDataSource,
      initialHasDescriptiveText: _.get(container, 'app.hasDescriptiveText', true),
      initialDescriptiveText: _.get(container, 'app.descriptiveText', 'Texto Descriptivo'),
      initialContentAlignment: _.get(container, 'app.contentAlignment', 'left'),
      initialHasIcon: _.get(container, 'app.hasIcon', false),
      initialIcon: _.get(container, 'app.icon', 'group_icon'),
      initialIconPosition: _.get(container, 'app.iconPosition', 'top'),
      initialIconSize,
      initialIconColor: _.get(container, 'app.iconColor', '#0031FF'),
      tooltipF: 'https://docs.google.com/spreadsheets/d/1BbG6Fu42FgLVpvPz6vb1WSZT-kmIBZaL5m26qNqCwxo/edit?usp=sharing',
      // singleValue: this.singleValue,
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      borderColor: _.get(container, 'borderColor', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialBorderColor: _.get(container, 'borderColor', ''),
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    iconSize: {
      get() {
        let iconSize = this.iconSizeUpdater || _.get(this.container, 'app.iconSize', '1X');
        if (typeof iconSize === 'number') {
          iconSize = iconSize.toString().concat('X');
        }

        return iconSize;
      },
      set(newIconSize) {
        this.iconSizeUpdater = newIconSize;
      },
    },
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    iconObject() {
      return { name: this.icon, id: this.iconList.indexOf(this.icon) || 0 };
    },
    sizeObject() {
      return { name: this.iconSize, id: this.sizesList.indexOf(this.iconSize) || 0 };
    },
    singleValueIconID() {
      return `#single-icon${this.containerID}`;
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        showTitle: this.showTitle,
        showTransparency: this.showTransparency,
        showShading: this.showShading,
        showDataSource: this.showDataSource,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          dataSource: this.selectedDataSource,
          hasDescriptiveText: this.hasDescriptiveText,
          descriptiveText: this.descriptiveText,
          singleValue: this.singleValue,
          descriptiveTextHistory: { history: this.descriptiveTextHistory },
          contentAlignment: this.contentAlignment,
          hasIcon: this.hasIcon,
          icon: this.icon,
          iconPosition: this.iconPosition,
          iconSize: this.iconSize.split('X')[0],
          iconColor: this.iconColor,
        },
        appType: 'SingleValueApp',
      };
    },
    canSave() {
      const canSave = !!(this.requiredAppInputsFilled);
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTitle !== this.showTitle ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) ||
        this.initialHasDescriptiveText !== this.hasDescriptiveText ||
        this.initialDescriptiveText !== this.descriptiveText ||
        this.initialContentAlignment !== this.contentAlignment ||
        this.initialHasIcon !== this.hasIcon ||
        this.initialIcon !== this.icon ||
        this.initialIconPosition !== this.iconPosition ||
        this.initialIconSize !== this.iconSize ||
        this.initialIconColor !== this.iconColor ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase()
      ) {
        return true;
      }

      return false;
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (service === 'gsheet') {
          dataSourceDone = this.gsheetValidation();
        } else if (service === 'custom') {
          dataSourceDone = this.customValidation();
        } else if (service === 'excel') {
          dataSourceDone = this.excelValidation();
        }
      }

      return dataSourceDone && this.changesToSend;
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      selectedDataSource: state => state.users.selectedDataSource,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    applyRightAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).attr(
        'style',
        'align-items: center;',
      );
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '15px');
    },
    applyLeftAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).attr(
        'style',
        'align-items: center;',
      );
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '15px');
    },
    applyCenterAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).attr(
        'style',
        'align-items: center;',
      );
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content:', 'center');
    },
    applyBottomAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).attr(
        'style',
        'align-items: center;',
      );
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-bottom', '15px');
    },
    applyTopAlignmentStyle() {
      $(this.containerIdJS.concat('.app-container__content')).attr(
        'style',
        'align-items: center;',
      );
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-top', '15px');
    },
    excelValidation() {
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis.name !== null &&
          dataSourceInAnalysis.dataSourceType === 'excel' &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.sheetName) &&
          dataSourceInAnalysis.sourceId !== null) {
        return true;
      }

      return false;
    },

    gsheetValidation() {
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis.name !== null &&
          dataSourceInAnalysis.dataSourceType === 'table' &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
          dataSourceInAnalysis.sourceId !== null) {
        return true;
      }

      return false;
    },

    customValidation() {
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis.name !== null &&
          dataSourceInAnalysis.dataSourceType === 'table_internal' &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
          !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
          dataSourceInAnalysis.sourceId !== null) {
        return true;
      }

      return false;
    },

    changeContentAlignment(alignment) {
      if (alignment === 'right') {
        this.applyRightAlignmentStyle();
      } else if (alignment === 'left') {
        this.applyLeftAlignmentStyle();
      } else if (alignment === 'center') {
        this.applyCenterAlignmentStyle();
      } else if (alignment === 'bottom') {
        this.applyBottomAlignmentStyle();
      } else if (alignment === 'top') {
        this.applyTopAlignmentStyle();
      }
      this.contentAlignment = alignment;
      this.updateAppAttrs();
    },
    changeIconPosition(iconPosition) {
      this.iconPosition = iconPosition;
      this.updateAppAttrs();
    },
    updateIconOnContainer(iconObject) {
      this.icon = iconObject.name;
      this.updateAppAttrs();
    },
    updateIconSizeOnContainer(iconSize) {
      this.iconSize = iconSize.name;
      const factor = parseFloat(this.iconSize.split('X')[0], DECIMAL_BASE);
      $(this.singleValueIconID).css({
        'font-size': `${factor * DEFAULT_ICON_FONT_SIZE}px`,
        'width': `${factor * DEFAULT_ICON_SIZE}px`,
        'height': `${factor * DEFAULT_ICON_SIZE}px`,
        'padding-top': `${factor * DEFAULT_ICON_PADDING}px`,
        'padding-left': `${factor * DEFAULT_ICON_PADDING}px`,
      });
      this.updateAppAttrs();
    },
    changeSize() {
      $('.vue-swatches__container').attr(
        'style',
        `width: 126px; height: 164px; inset: 30px 0px 0px -100px; background-color: rgb(255, 255, 255);
        border: 0.5px solid #707070; border-radius: 2px; opacity: 1; box-shadow: none;`,
      );
      $('.vue-swatches__wrapper').attr('style', 'width: 126px; margin-bottom: 5px; margin-top: 6px;');
      $('.vue-swatches__fallback__wrapper').attr(
        'style', 'width: 126px; padding-top: 20px !important; display: inline;',
      );
      $('.vue-swatches__fallback__button').attr(
        'style',
        `width: 29px; height: 20px; font: normal normal bold 9px/37px Inter; text-align: center; letter-spacing: 0px;
        opacity: 1; background-color: #2C6EEB; border-radius: 2px; align-items: center; justify-content: center;
        margin-right: 0px; color: white; width: 20%; display: inline-flex; align-items: center;
        justify-content: center; position: absolute; transform: translate(26px, 0px);`,
      );
      $('.vue-swatches__fallback__button').removeClass('vue-swatches__fallback__button');
      $('.vue-swatches__fallback__input').attr(
        'style',
        `height: 13px; width: 80px; position: absolute; transform: translate(-52px, -13px);
        padding-top: 1.5px; padding-bottom: 1.5px; background-color: white; 
        border: 0.5px solid #707070; border-radius: 2px;`,
      );
      $('.vue-swatches__fallback__input').removeClass('vue-swatches__fallback__input');
    },
    updateIconColor() {
      $(this.singleValueIconID).css('color', this.iconColor);
      $(this.singleValueIconID).css('border', '1px '.concat('solid ', this.iconColor));
      this.updateAppAttrs();
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      const factor = parseFloat(this.iconSize.split('X')[0], DECIMAL_BASE);
      $(this.singleValueIconID).css({
        'font-size': `${factor * DEFAULT_ICON_FONT_SIZE}px`,
        'width': `${factor * DEFAULT_ICON_SIZE}px`,
        'height': `${factor * DEFAULT_ICON_SIZE}px`,
        'padding-top': `${factor * DEFAULT_ICON_PADDING}px`,
        'padding-left': `${factor * DEFAULT_ICON_PADDING}px`,
        'border': '1px '.concat('solid ', this.iconColor),
        'color': this.iconColor,
        'border-radius': '50%',
      });
      $('.ql-toolbar.ql-snow').hide();
      this.saveMessage();
      this.resetInitialValues();
    },
    resetInitialValues3() {
      this.initialBorderColor = this.borderColor;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
    },
    resetInitialValues2() {
      this.initialHasDescriptiveText = this.hasDescriptiveText;
      this.initialDescriptiveText = this.descriptiveText;
      this.initialContentAlignment = this.contentAlignment;
      this.initialHasIcon = this.hasIcon;
      this.initialIcon = this.icon;
      this.initialIconPosition = this.iconPosition;
      this.initialIconSize = this.iconSize;
      this.initialIconColor = this.iconColor;
      this.resetInitialValues3();
    },
    resetInitialValues() {
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialShowBorders = this.showBorders;
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTitle = this.showTitle;
      this.initialShowTransparency = this.showTransparency;
      this.initialShowShading = this.showShading;
      this.initialShowDataSource = this.showDataSource;
      this.initialDataSource = this.selectedDataSource;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    updateAppAttrs() {
      const params = {
        hasDescriptiveText: this.hasDescriptiveText,
        contentAlignment: this.contentAlignment,
        hasIcon: this.hasIcon,
        icon: this.icon,
        iconPosition: this.iconPosition,
        iconSize: this.iconSize,
        iconColor: this.iconColor,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.scrollable-toolbar-item-option {
  padding-bottom: 90px;
}

</style>
