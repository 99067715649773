<template>
  <div>
    <modal
      name="user-tags"
      :width="670"
      :min-height="534"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal share-resource-modal']"
    >
      <div
        class="share-resource-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="share-resource-modal__header">
        {{ editMode ? 'Edit Group' : $t('user.tagsModal.title') }}
      </div>
      <div class="share-resource-modal__content">
        <div class="app-modal__inputs--tags">
          <input
            ref="taginput"
            placeholder="Write a name for your group"
            type="text"
            autofocus
            :disabled="editMode"
            v-model="newTagName"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16 leading-snug"
          >
          <!-- <span>
            <button
              @click="addTag"
              class="btn btn--inverted-colors new-btn"
              v-if="!tagEditMode"
            >
              Agregar
              <i class="material-icons material-icons--blue new-btn__plus">add</i>
            </button>
            <button
              @click="saveEditedTag"
              class="btn btn--inverted-colors new-btn"
              v-else
            >
              Editar
              <i class="material-icons material-icons--blue new-btn__plus">save</i>
            </button>
          </span> -->
        </div>
        <div class="w-full relative margin-bottom-16">
          <input
            ref="taginput"
            placeholder="Asign collaborators"
            type="text"
            v-model="writingUser"
            @click.stop="toggleList = true"
            @focus="toggleList = !toggleList"
            class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
          >
          <i
            @click.stop="toggleList = !toggleList"
            class="material-icons-outlined material-icons--secondary absolute right-0 center-top cursor-pointer rotate"
            :class="{'rotate-iso': toggleList}"
          >
            add_circle_outline
          </i>
          <div
            ref="dropDown"
            id="dropDown"
            v-if="toggleList"
            class="w-full bg-white height-232 shadow absolute top-48 overflow-y-auto z-10"
          >
            <div
              v-if="filteredAutocompleteUsers.length === 0"
              class="border-b border-input flex items-center"
            >
              <p class="text-menu-label font-size-13">
                No matches
              </p>
            </div>
            <div
              v-else
              :key="user.id"
              v-for="user in filteredAutocompleteUsers"
              class="height-56 border-b border-input flex items-center"
            >
              <div class="w-1/12 ml-4">
                <img
                  :src="user.profilePictureUrl || profilePlaceholder"
                  alt="pp"
                  class="rounded-full bg-gray-500 profile-square"
                >
              </div>

              <div class="w-5/12">
                <p
                  :title="user.fullName"
                  class="truncate text-menu-label font-size-13"
                >
                  {{ user.fullName }}
                </p>
                <p class="font-size-13 text-setting-sublabel">
                  {{ user.role }}
                </p>
              </div>

              <div class="w-4/12">
                <p
                  :title="user.email"
                  class="truncate font-size-13 text-setting-sublabel"
                >
                  {{ user.email }}
                </p>
              </div>

              <div
                v-if="isAdded(user)"
                class="w-2/12 self-center text-center"
              >
                <i class="material-icons material-icons-inline material-icons-inline--light-green">done</i>
              </div>
              <div
                v-else
                @click.stop="addUser(user)"
                class="w-2/12 cursor-pointer"
              >
                <i class="material-icons material-icons-inline">add</i>
                <span class="text-icon-secondary font-size-13"> Add</span>
              </div>
            </div>

            <!-- <tags-input
          ref="tags"
          v-model="writingTag"
          :tags="selectedTags"
          :add-on-key="addTagKeys"
          :autocomplete-items="filteredAutocompleteItems"
          :avoid-adding-duplicates="true"
          :is-duplicate="isDuplicated"
          :autocomplete-filter-duplicates="true"
          :validation="tagValidations"
          :placeholder="$t('user.tagsModal.tagPlaceholder')"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateSelectedTags"
          :delete-on-backspace="false"
        />

        <tags-input
          ref="users"
          v-model="writingUser"
          :tags="selectedUsers"
          :add-on-key="addTagKeys"
          :autocomplete-items="filteredAutocompleteUsers"
          :avoid-adding-duplicates="true"
          :is-duplicate="isDuplicated"
          :autocomplete-filter-duplicates="true"
          :validation="tagValidations"
          :placeholder="$t('user.tagsModal.userPlaceholder')"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateSelectedUsers"
          :delete-on-backspace="false"
        />
      </div> -->
          </div>
        </div>

        <tags-input
          ref="users"
          value=""
          :tags="selectedUsers"
          :avoid-adding-duplicates="true"
          :is-duplicate="isDuplicated"
          :add-on-blur="false"
          :autocomplete-filter-duplicates="true"
          :validation="tagValidations"
          :placeholder="placeholderText"
          :add-only-from-autocomplete="true"
          class="share-resource-modal__input share-resource-modal__input--tags"
          @tags-changed="updateSelectedUsers"
          :delete-on-backspace="false"
        />

        <button
          v-if="!editMode"
          class="height-48 width-306 margin-ontop"
          :class="{
            'share-resource-modal__btn--disabled': !canSave,
            'bg-icon-secondary text-white rounded-lg': canSave }"
          @click="setUserTags"
        >
          Create Group
        </button>
        <button
          v-else
          class="height-48 width-306 margin-ontop"
          :class="{
            'share-resource-modal__btn--disabled': !canSave,
            'bg-icon-secondary text-white rounded-lg': canSave }"
          @click="setUserTags"
        >
          Edit Group
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TagsInput, { createTag } from '@johmun/vue-tags-input';
import profilePlaceholder from '../images/upload-photo.png';

import {
  ADD_TAG,
  UPDATE_EDITED_TAG,
  REMOVE_SELECTED_TAG,
  SAVE_TAG_ASSIGNATIONS,
} from '../store/action-types';
import {
  CLOSE_USER_TAGS_MODAL,
  SET_TAG_REMOVE_MODE,
  SET_TAG_EDIT_MODE,
} from '../store/mutation-types';
import { KEY_CODES } from '../constants';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';
import clickMixinHandler from '../mixins/clickMixinHandler';

export default {
  mixins: [escapeMixinHandler, showMessageMixin, clickMixinHandler],
  components: {
    TagsInput,
  },
  props: {
    editMode: { type: Boolean, default: false },
  },
  data() {
    let alreadySelectedUsers = [];
    let newTagName = '';
    if (this.editMode) {
      const selectedTag = this.$store.state.users.selectedTag.tag;
      alreadySelectedUsers = this.$store.state.users.usersTagged[selectedTag].map((selected) =>
        ({ text: selected.fullName, id: selected.id }),
      );
      newTagName = selectedTag;
    }

    return {
      addTagKeys: [KEY_CODES.ENTER, KEY_CODES.TAB, KEY_CODES.SPACE],
      toggleList: false,
      profilePlaceholder,
      writingTag: '',
      writingUser: '',
      selectedTags: [],
      selectedUsers: alreadySelectedUsers,
      selectedUserNormalized: {},
      newTagName,
      oldTag: newTagName,
      addedFromTagList: false,
      inputActive: false,
      showTagState: null,
      tagValidations: [{
        classes: 'avoid-item',
        rule: () => this.addedFromTagList,
        disableAdd: true,
      }],
    };
  },
  computed: {
    ...mapState({
      tenantTags: state => state.users.tenantTags,
      usersTagged: state => state.users.usersTagged,
      currentCompanyId: state => state.users.currentCompanyId,
      tagEditMode: state => state.users.tagEditMode,
      tagRemoveMode: state => state.users.tagRemoveMode,
      selectedTag: state => state.users.selectedTag,
      userList: state => state.users.list,
    }),
    placeholderText() {
      if (this.selectedUsers.length > 0) {
        return '';
      }

      return 'Selected Collaborators';
    },
    filteredAutocompleteItems() {
      const self = this;
      const filteredResults = this.tenantTags.filter((tag) => (
        tag.toLowerCase().indexOf(self.writingTag.toLowerCase()) !== -1))
        .map(t => ({ text: t }));
      this.setReadOnlyMode(filteredResults);

      return filteredResults;
    },
    filteredAutocompleteUsers() {
      const self = this;
      const filteredUsers = Object.keys(this.userList).filter((key) => self.userList[key].email.toLowerCase().indexOf(self.writingUser.toLowerCase()) !== -1 ||
        self.userList[key].fullName.toLowerCase().indexOf(self.writingUser.toLowerCase()) !== -1).map(key => (self.userList[key]));
      this.setReadOnlyMode(filteredUsers);

      return filteredUsers;
    },
    canSave() {
      // return this.selectedTags.length > 0 && this.selectedUsers.length > 0;
      return this.newTagName.length > 0 && this.selectedUsers.length > 0;
    },
    // This computed properties will be used in future implementations
    // where the tags actions will appears on demand when the user hovers the tag
    // selectedUserList() {
    //   this.userListAndSelected.map();
    // },

    hoverList() {
      if (this.showTagState === null) {
        return this.tenantTags.reduce((tagOjb, tag) => {
          tagOjb[tag] = false;

          return tagOjb;
        }, {});
      }

      return this.tenantTags.reduce((tagOjb, tag) => {
        if (this.showTagState === tag) {
          tagOjb[tag] = true;
        } else {
          tagOjb[tag] = false;
        }

        return tagOjb;
      }, {});
    },
  },
  mounted() {
    this.openModal();
    document.activeElement.blur();
  },
  methods: {
    addUser(user) {
      this.selectedUsers.push({ text: user.fullName, id: user.id });
      this.userList[user.id].added = true;
    },
    isAdded(user) {
      return this.selectedUsers.map(u => u.id).includes(user.id);
    },
    setReadOnlyMode(filteredResults) {
      if (filteredResults.length === 0) {
        this.addedFromTagList = true;
      } else {
        this.addedFromTagList = false;
      }
    },
    onMouseHover(tag) {
      this.showTagState = tag;
    },
    onMouseLeave() {
      this.showTagState = null;
    },
    addTag() {
      const params = { id: this.currentCompanyId, newTag: this.newTagName };
      this.$store.dispatch(ADD_TAG, params);
      this.newTagName = '';
    },
    saveEditedTag() {
      this.$store.dispatch(UPDATE_EDITED_TAG, { oldValue: this.oldTag, newValue: this.newTagName });
      this.$store.commit(SET_TAG_EDIT_MODE, false);
      this.newTagName = '';
      this.inputActive = false;
    },
    openModal() {
      this.$modal.show('user-tags');
    },
    editTag(tag) {
      this.$store.commit(SET_TAG_EDIT_MODE, true);
      this.oldTag = tag;
      this.newTagName = tag;
      this.$refs.taginput.focus();
    },
    deleteTag(tag) {
      this.$store.commit(SET_TAG_REMOVE_MODE, true);
      this.$store.dispatch(REMOVE_SELECTED_TAG, tag);
    },
    selectTag(tag) {
      if (this.tagEditMode) {
        if (this.inputActive) {
          this.showMessage('Primero debes terminar de editar la etiqueta seleccionada');
        } else {
          this.$refs.taginput.focus();
          this.inputActive = true;
        }
      } else if (this.tagRemoveMode) {
        console.log('do nothing');
      } else {
        if (this.selectedTags.map(st => st.text).indexOf(tag) === -1) {
          this.selectedTags.push(createTag(tag, this.tenantTags));
        }
        this.writingTag = '';
      }
    },
    isDuplicated(tags, tag) {
      return tags.map(t => t.text).indexOf(tag.text) !== -1;
    },
    async setUserTags() {
      if (!this.canSave) return;
      const tagList = [this.newTagName];
      const userList = this.selectedUsers.map(user => user.id);

      this.addTag();
      this.$store.dispatch(SAVE_TAG_ASSIGNATIONS, { companyId: this.currentCompanyId, tagList, userList });
    },
    close() {
      if (this.toggleList) {
        this.toggleList = false;
      } else {
        this.$store.commit(CLOSE_USER_TAGS_MODAL);
      }
    },
    updateSelectedTags(newTags) {
      this.selectedTags = newTags;
    },
    updateSelectedUsers(newUsers) {
      this.selectedUsers = newUsers;
    },
  },
};
</script>
<style scoped>
h3 {
  font-size: 1.17em;
  font-weight: 200;
}

.height-48 {
  height: 48px;
}

.height-56 {
  height: 56px;
}

.height-232 {
  max-height: 232px;
}

.text-size-16 {
  font-size: 16px;
}

.center-top {
  top: 12px;
  right: 12px;
}

.top-48 {
  top: 48px;
}

.profile-square {
  height: 40px;
  width: 40px;
}

.font-size-13 {
  font-size: 13px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.width-306 {
  width: 306px;
}

.margin-ontop {
  margin-top: 70px;
}

.rotate {
  transition: all .1s linear;
}

.rotate-iso {
  transform: rotate(45deg);
}

</style>

