var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-col",attrs:{"id":_vm.positionProp,"v-if":_vm.positionProp}},[_c('div',{staticClass:"flex items-center height-40 cursor-pointer",class:_vm.styleSelectedClass(_vm.defaultHome.url),style:(_vm.fontIconColor)},[_c('a',{attrs:{"href":_vm.defaultHome.url}},[_c('i',{staticClass:"p-2 material-icons material-icons-rounded material-icons-menu-custom",class:{
          'material-icons-menu-custom--selected': _vm.styleSelectedClass(_vm.defaultHome.url),
          'material-icons-menu-custom': !_vm.styleSelectedClass(_vm.defaultHome.url)
        }},[_vm._v("\n        home\n      ")])]),_vm._v(" "),_c('a',{staticClass:"inline-block",attrs:{"href":_vm.defaultHome.url}},[_c('div',{staticClass:"pl-2 text-menu-custom",class:{'text-menu-selected': _vm.styleSelectedClass(_vm.defaultHome.url)}},[_vm._v("\n        Home\n      ")])])]),_vm._v(" "),_vm._l((_vm.menuItems),function(item){return _c('div',{key:item.resource_id,staticClass:"flex items-center height-40 cursor-pointer",class:_vm.styleSelectedClass(_vm.getResourceUrl(item)),style:(_vm.fontIconColor)},[_c('a',{attrs:{"href":_vm.getResourceUrl(item)}},[_c('i',{staticClass:"p-2 material-icons material-icons-menu-custom",class:{
          'material-icons-menu-custom--selected': _vm.styleSelectedClass(_vm.getResourceUrl(item)),
          'material-icons-menu-custom': !_vm.styleSelectedClass(_vm.getResourceUrl(item))
        }},[_vm._v(_vm._s(item.icon))])]),_vm._v(" "),_c('a',{staticClass:"inline-block",attrs:{"href":_vm.getResourceUrl(item)}},[_c('div',{staticClass:"pl-2 text-menu-custom",class:{'text-menu-selected': _vm.styleSelectedClass(_vm.getResourceUrl(item))}},[_vm._v("\n        "+_vm._s(item.label)+"\n      ")])])])}),_vm._v(" "),_c('div',{staticClass:"flex items-center height-40 cursor-pointer",class:_vm.styleSelectedClass('/users'),style:(_vm.fontIconColor)},[(_vm.isAdminProp)?_c('a',{attrs:{"href":"/users"}},[_c('i',{staticClass:"p-2 material-icons material-icons-rounded material-icons-menu-custom",class:{
          'material-icons-menu-custom--selected': _vm.styleSelectedClass('/users'),
          'material-icons-menu-custom': !_vm.styleSelectedClass('/users')
        }},[_vm._v("\n        group_work\n      ")])]):_vm._e(),_vm._v(" "),(_vm.isAdminProp)?_c('a',{staticClass:"inline-block",attrs:{"href":"/users"}},[_c('div',{staticClass:"pl-2 text-menu-custom",class:{'text-menu-selected': _vm.styleSelectedClass('/users')}},[_vm._v("\n        Equipo\n      ")])]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }