<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">assignment_ind</i>
        <div class="header-text">
          Directorio
        </div>
      </div>
    </div>
    <div>
      <new-data-sources-selector
        :key="newDataSourceSelectorKey"
        :current-datasource="selectedDataSource"
        :columns-checked="[]"
        :tooltip-file-uri="tooltipF"
        :container="container"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex items-center"
        >
          <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Imagen perfil
          </div>
          <input
            v-model="profilePicCell"
            @input="updateProfilePicColumnField"
            class="w-2/6 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: B"
          >
        </div>
        <Collapsible
          class="width-collapsible"
          :is-open="nameCollapseOpen"
          :on-collapse="changeNameCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-sm font-inter-type-1 cells2">
                Nombre
              </div>
              <input
                v-model="nameCell"
                @input="updateNameColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: C"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-sm font-inter-type-1 cells2">
                Nombre
              </div>
              <input
                v-model="nameCell"
                @input="updateNameColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: C"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div
            class="flex mt-4"
          >
            <p
              class="text-left text-menu-label w-4/6 text-sm font-inter-type-1"
            >
              Descripción
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showNameDescription"
              :sync="true"
              @input="updateShowNameDescriptionAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <p
              class="text-left text-menu-label w-4/6 text-sm font-inter-type-1"
            >
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showNameIcon"
              :sync="true"
              @input="updateShowNameIconAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="nameIconList"
              v-model="nameIconObject"
              @documentListener="updateNameIcon"
              :placeholder="'Ícono del nombre...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showName"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="No mostrar columna"
              @click="changeAndUpdateShowNameField"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="Mostrar columna"
              @click="changeAndUpdateShowNameField"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="professionCollapseOpen"
          :on-collapse="changeProfessionCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cargo
              </div>
              <input
                v-model="professionCell"
                @input="updateProfessionColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: D"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cargo
              </div>
              <input
                v-model="professionCell"
                @input="updateProfessionColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: D"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Descripción
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showProfessionDescription"
              :sync="true"
              @input="updateShowProfessionDescriptionAndField"
            />
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showProfessionIcon"
              :sync="true"
              @input="updateShowProfessionIconAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="professionIconList"
              v-model="professionIconObject"
              @documentListener="updateProfessionIcon"
              :placeholder="'Ícono del cargo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showProfession"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowProfessionField"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowProfessionField"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="emailCollapseOpen"
          :on-collapse="changeEmailCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Correo
              </div>
              <input
                v-model="emailCell"
                @input="updateEmailColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: E"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Correo
              </div>
              <input
                v-model="emailCell"
                @input="updateEmailColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: E"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Descripción
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showEmailDescription"
              :sync="true"
              @input="updateShowEmailDescriptionAndField"
            />
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showEmailIcon"
              :sync="true"
              @input="updateShowEmailIconAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="emailIconList"
              v-model="emailIconObject"
              @documentListener="updateEmailIcon"
              :placeholder="'Ícono del correo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showEmail"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowEmailField"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowEmailField"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="phoneCollapseOpen"
          :on-collapse="changePhoneCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Teléfono
              </div>
              <input
                v-model="phoneCell"
                @input="updatePhoneColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: F"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Teléfono
              </div>
              <input
                v-model="phoneCell"
                @input="updatePhoneColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: F"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Descripción
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showPhoneDescription"
              :sync="true"
              @input="updateShowPhoneDescriptionAndField"
            />
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showPhoneIcon"
              :sync="true"
              @input="updateShowPhoneIconAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="phoneIconList"
              v-model="phoneIconObject"
              @documentListener="updatePhoneIcon"
              :placeholder="'Ícono del cargo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showPhone"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowPhoneField"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowPhoneField"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          style="margin-bottom: 20px;"
          :is-open="birthdayCollapseOpen"
          :on-collapse="changeBirthdayCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cumpleaños
              </div>
              <input
                v-model="birthdayCell"
                @input="updateBirthdayColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: G"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cumpleaños
              </div>
              <input
                v-model="birthdayCell"
                @input="updateBirthdayColumnField"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: G"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Descripción
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showBirthdayDescription"
              :sync="true"
              @input="updateShowBirthdayDescriptionAndField"
            />
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showBirthdayIcon"
              :sync="true"
              @input="updateShowBirthdayIconAndField"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="birthdayIconList"
              v-model="birthdayIconObject"
              @documentListener="updateBirthdayIcon"
              :placeholder="'Ícono del cumpleaños...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showBirthday"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowBirthdayField"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowBirthdayField"
            >visibility_off</i>
          </div>
        </Collapsible>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
          :has-show-search="true"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave()"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import api from '../api';
import NewDataSourcesSelector from './new-data-sources-selector';
import ToolbarIconSelector from './toolbar-icon-selector.vue';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import {
  SAVE_CONTAINER, CLOSE_APP_MODAL,
  SELECT_CONTAINER_FOR_EDITION,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import {
  SET_SELECTED_DATA_SOURCE,
  SET_TOOGLE_TOOLBAR_COLLAPSIBLE,
  SAVE_ALERT,
} from '../store/mutation-types';
import iconNames from '../helpers/material-icon-names';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEFAULT_VALUE_FOR_CONDITION = '';

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    NewDataSourcesSelector,
    Collapsible,
    ToolbarIconSelector,
    AppTitleInOptions,
    ContainerSection,
  },
  props: {
    showBordersToggle: { type: Boolean, default: true },
    showShadingToggle: { type: Boolean, default: true },
    showSearchToggle: { type: Boolean, default: true },
    showCommentsToggle: { type: Boolean, default: true },
    showPrivateToggle: { type: Boolean, default: true },
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  data() {
    const container = this.$store.getters.selectedContainer || this.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const initialFieldValues = this.setInitialFieldValues(container, template);
    const currentDataSource = this.selectedDataSourceHandler(container);

    return {
      container,
      template,
      fieldValues: initialFieldValues,
      containerID: _.get(container, 'id', null),
      showBorders: _.get(container, 'showBorders', false),
      showShading: _.get(container, 'showShading', false),
      showSearch: _.get(container, 'showSearch', true),
      showDataSource: _.get(container, 'showDataSource', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      valueForCondition: DEFAULT_VALUE_FOR_CONDITION,
      columnsReady: false,
      maxLengthForFilterStatement: 24,
      maxLengthOfColumnPartForFilter: 4,
      currentDataSource,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialShowBorders: _.get(container, 'showBorders', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowSearch: _.get(container, 'showSearch', false),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialFieldValues: Object.assign({}, initialFieldValues),
      initialDataSource: currentDataSource,
      componentKey: 0,
      newDataSourceSelectorKey: 0,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      profilePicCell: initialFieldValues.profilePicColumn ? initialFieldValues.profilePicColumn : 'B',
      nameCell: initialFieldValues.fullnameColumn ? initialFieldValues.fullnameColumn : 'C',
      professionCell: initialFieldValues.professionColumn ? initialFieldValues.professionColumn : 'D',
      emailCell: initialFieldValues.emailColumn ? initialFieldValues.emailColumn : 'E',
      phoneCell: initialFieldValues.phoneColumn ? initialFieldValues.phoneColumn : 'F',
      birthdayCell: initialFieldValues.birthdayColumn ? initialFieldValues.birthdayColumn : 'G',
      nameIconList: iconNames.nameIconList.map(
        (icon) => ({ id: iconNames.nameIconList.indexOf(icon), name: icon })),
      professionIconList: iconNames.professionIconList.map(
        (icon) => ({ id: iconNames.professionIconList.indexOf(icon), name: icon })),
      emailIconList: iconNames.emailIconList.map(
        (icon) => ({ id: iconNames.emailIconList.indexOf(icon), name: icon })),
      phoneIconList: iconNames.phoneIconList.map(
        (icon) => ({ id: iconNames.phoneIconList.indexOf(icon), name: icon })),
      birthdayIconList: iconNames.birthdayIconList.map(
        (icon) => ({ id: iconNames.birthdayIconList.indexOf(icon), name: icon })),
      nameIcon: initialFieldValues.nameIcon || 'person',
      professionIcon: initialFieldValues.professionIcon || 'work',
      emailIcon: initialFieldValues.emailIcon || 'email',
      phoneIcon: initialFieldValues.phoneIcon || 'phone',
      birthdayIcon: initialFieldValues.birthdayIcon || 'cake',
      nameCollapseOpen: false,
      professionCollapseOpen: false,
      emailCollapseOpen: false,
      phoneCollapseOpen: false,
      birthdayCollapseOpen: false,
      showNameDescription: _.get(initialFieldValues, 'showNameDescription', false),
      showProfessionDescription: _.get(initialFieldValues, 'showProfessionDescription', false),
      showEmailDescription: _.get(initialFieldValues, 'showEmailDescription', false),
      showPhoneDescription: _.get(initialFieldValues, 'showPhoneDescription', false),
      showBirthdayDescription: _.get(initialFieldValues, 'showBirthdayDescription', false),
      showNameIcon: _.get(initialFieldValues, 'showNameIcon', true),
      showProfessionIcon: _.get(initialFieldValues, 'showProfessionIcon', true),
      showEmailIcon: _.get(initialFieldValues, 'showEmailIcon', true),
      showPhoneIcon: _.get(initialFieldValues, 'showPhoneIcon', true),
      showBirthdayIcon: _.get(initialFieldValues, 'showBirthdayIcon', true),
      showName: _.get(initialFieldValues, 'showName', true),
      showProfession: _.get(initialFieldValues, 'showProfession', true),
      showEmail: _.get(initialFieldValues, 'showEmail', true),
      showPhone: _.get(initialFieldValues, 'showPhone', true),
      showBirthday: _.get(initialFieldValues, 'showBirthday', true),
      tooltipF: 'https://docs.google.com/spreadsheets/d/1Ab7m2Hz3SGIujMTPMccvnQZs7LxSqEe0Qfi12_X6OA4/edit?usp=sharing',
    };
  },
  mounted() {
    this.updateFieldValuesInAutofill();
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    nameIconObject() {
      return { name: this.nameIcon, id: this.nameIconList.indexOf(this.nameIcon) || 0 };
    },
    professionIconObject() {
      return { name: this.professionIcon, id: this.professionIconList.indexOf(this.professionIcon) || 0 };
    },
    emailIconObject() {
      return { name: this.emailIcon, id: this.emailIconList.indexOf(this.emailIcon) || 0 };
    },
    phoneIconObject() {
      return { name: this.phoneIcon, id: this.phoneIconList.indexOf(this.phoneIcon) || 0 };
    },
    birthdayIconObject() {
      return { name: this.birthdayIcon, id: this.birthdayIconList.indexOf(this.birthdayIcon) || 0 };
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        showSearch: this.showSearch,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        showTransparency: this.showTransparency,
        showDataSource: this.showDataSource,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          fieldValues: this.fieldValues,
          customAppTemplateId: this.template.id,
          dataSource: this.selectedDataSource,
        },
        appType: 'CustomApp',
      };
    },
    appWithDataSourceAndType() {
      return {
        app: {
          customAppTemplateId: this.template.id,
          dataSource: this.selectedDataSource,
        },
        appType: 'CustomApp',
      };
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      dataSourceModalOpened: state => state.ui.dataSourceModalOpened,
      selectedDataSource: state => state.users.selectedDataSource,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    headersReadyHandler() {
      if (this.selectedDataSource.id === null) {
        return true;
      }

      return this.headersReady;
    },
    setInitialFieldValues(container, template) {
      const initialFieldValues = {};
      template.fields.forEach((field) => {
        initialFieldValues[field.name] = container ? container.app.fieldValues[field.name] : null;
      });

      return initialFieldValues;
    },
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    reopen() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_EDITION, this.container);
      this.$store.commit(SET_TOOGLE_TOOLBAR_COLLAPSIBLE, false);
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    resetInitialValues2() {
      this.initialIsPrivate = this.isPrivate;
      this.initialShowShading = this.showShading;
      this.initialShowDataSource = this.showDataSource;
      this.initialShowSearch = this.showSearch;
    },
    resetInitialValues() {
      this.initialFieldValues = Object.assign({}, this.fieldValues);
      this.initialDataSource = this.selectedDataSource;
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialShowBorders = this.showBorders;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.resetInitialValues2();
    },
    async saveApp() {
      if (!this.canSave()) return;

      const fieldsSaved = await this.saveFields();
      const containerSaved = await this.dispatchSaveContainer(this.appWithContainer, this.containerID);
      console.log(fieldsSaved);
      console.log(containerSaved);
      this.saveMessage();
      this.newDataSourceSelectorKey += 1;
      this.componentKey += 1;
      this.resetInitialValues();
      this.appAttrs();
    },
    appAttrs() {
      const params = {
        dataSource: this.selectedDataSource,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    canSave() {
      const canSave = this.requiredAppInputsFilled();
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    sortedObject(unordered) {
      return Object.keys(unordered).sort().reduce(
        (obj, key) => {
          obj[key] = unordered[key];

          return obj;
        }, {});
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialShowBorders !== this.showBorders ||
        this.initialShowSearch !== this.showSearch ||
        JSON.stringify(this.sortedObject(this.initialFieldValues)) !==
          JSON.stringify(this.sortedObject(this.fieldValues)) ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId'))
      ) {
        return true;
      }

      return false;
    },
    correctDataRange(dataSourceInAnalysis) {
      if (!['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange)) {
        const rangeAnalysis = dataSourceInAnalysis.dataRange.split('!');
        const fromAndToCells = rangeAnalysis[1].split(':');
        if (fromAndToCells[0] === '' || fromAndToCells[1] === '') {
          return false;
        }
      }

      return true;
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== undefined && dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (['gsheet', 'excel'].includes(service)) {
          if (dataSourceInAnalysis.name !== null &&
              ['table', 'excel'].includes(dataSourceInAnalysis.dataSourceType) &&
              this.correctDataRange(dataSourceInAnalysis) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'custom') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table_internal' &&
              dataSourceInAnalysis.source_id !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone && this.changesToSend();
    },
    async createDataSource() {
      const containerSaved = await this.dispatchSaveContainer(this.appWithDataSourceAndContainer,
        this.selectedContainerId);
      console.log(containerSaved);
      const response = await api.getDataSourceValues(this.selectedDataSource.id);

      return response;
    },
    saveFields2() {
      this.fieldValues.showSearch = this.showSearch;
      this.fieldValues.backgroundColor = this.containerBackgroundColor;
    },
    async saveFields() {
      this.saveFields2();
    },
    selectedDataSourceHandler(container) {
      const currentDataSource = this.$store.state.users.selectedDataSource || container.app.dataSource;
      this.$store.commit(SET_SELECTED_DATA_SOURCE, currentDataSource);

      return currentDataSource;
    },
    updateNameColumnField() {
      this.fieldValues.fullnameColumn = this.nameCell;
    },
    updateProfessionColumnField() {
      this.fieldValues.professionColumn = this.professionCell;
    },
    updateEmailColumnField() {
      this.fieldValues.emailColumn = this.emailCell;
    },
    updatePhoneColumnField() {
      this.fieldValues.phoneColumn = this.phoneCell;
    },
    updateBirthdayColumnField() {
      this.fieldValues.birthdayColumn = this.birthdayCell;
    },
    updateProfilePicColumnField() {
      this.fieldValues.profilePicColumn = this.profilePicCell;
    },
    updateNameIcon(iconObject) {
      this.nameIcon = iconObject.name;
      this.fieldValues.nameIcon = this.nameIcon;
    },
    updateProfessionIcon(iconObject) {
      this.professionIcon = iconObject.name;
      this.fieldValues.professionIcon = this.professionIcon;
    },
    updateEmailIcon(iconObject) {
      this.emailIcon = iconObject.name;
      this.fieldValues.emailIcon = this.emailIcon;
    },
    updatePhoneIcon(iconObject) {
      this.phoneIcon = iconObject.name;
      this.fieldValues.phoneIcon = this.phoneIcon;
    },
    updateBirthdayIcon(iconObject) {
      this.birthdayIcon = iconObject.name;
      this.fieldValues.birthdayIcon = this.birthdayIcon;
    },
    changeNameCollapseOpen() {
      if (!this.nameCollapseOpen) {
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
      }
      this.nameCollapseOpen = !this.nameCollapseOpen;
    },
    changeProfessionCollapseOpen() {
      if (!this.professionCollapseOpen) {
        this.nameCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
      }
      this.professionCollapseOpen = !this.professionCollapseOpen;
    },
    changeEmailCollapseOpen() {
      if (!this.emailCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
      }
      this.emailCollapseOpen = !this.emailCollapseOpen;
    },
    changePhoneCollapseOpen() {
      if (!this.phoneCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.birthdayCollapseOpen = false;
      }
      this.phoneCollapseOpen = !this.phoneCollapseOpen;
    },
    changeBirthdayCollapseOpen() {
      if (!this.birthdayCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
      }
      this.birthdayCollapseOpen = !this.birthdayCollapseOpen;
    },
    changeAndUpdateShowNameField() {
      this.showName = !this.showName;
      this.fieldValues.showName = this.showName;
    },
    changeAndUpdateShowProfessionField() {
      this.showProfession = !this.showProfession;
      this.fieldValues.showProfession = this.showProfession;
    },
    changeAndUpdateShowEmailField() {
      this.showEmail = !this.showEmail;
      this.fieldValues.showEmail = this.showEmail;
    },
    changeAndUpdateShowPhoneField() {
      this.showPhone = !this.showPhone;
      this.fieldValues.showPhone = this.showPhone;
    },
    changeAndUpdateShowBirthdayField() {
      this.showBirthday = !this.showBirthday;
      this.fieldValues.showBirthday = this.showBirthday;
    },
    updateShowNameIconAndField() {
      this.showNameIcon = !this.showNameIcon;
      this.fieldValues.showNameIcon = this.showNameIcon;
    },
    updateShowProfessionIconAndField() {
      this.showProfessionIcon = !this.showProfessionIcon;
      this.fieldValues.showProfessionIcon = this.showProfessionIcon;
    },
    updateShowEmailIconAndField() {
      this.showEmailIcon = !this.showEmailIcon;
      this.fieldValues.showEmailIcon = this.showEmailIcon;
    },
    updateShowPhoneIconAndField() {
      this.showPhoneIcon = !this.showPhoneIcon;
      this.fieldValues.showPhoneIcon = this.showPhoneIcon;
    },
    updateShowBirthdayIconAndField() {
      this.showBirthdayIcon = !this.showBirthdayIcon;
      this.fieldValues.showBirthdayIcon = this.showBirthdayIcon;
    },
    updateShowNameDescriptionAndField() {
      this.showNameDescription = !this.showNameDescription;
      this.fieldValues.showNameDescription = this.showNameDescription;
    },
    updateShowProfessionDescriptionAndField() {
      this.showProfessionDescription = !this.showProfessionDescription;
      this.fieldValues.showProfessionDescription = this.showProfessionDescription;
    },
    updateShowEmailDescriptionAndField() {
      this.showEmailDescription = !this.showEmailDescription;
      this.fieldValues.showEmailDescription = this.showEmailDescription;
    },
    updateShowPhoneDescriptionAndField() {
      this.showPhoneDescription = !this.showPhoneDescription;
      this.fieldValues.showPhoneDescription = this.showPhoneDescription;
    },
    updateShowBirthdayDescriptionAndField() {
      this.showBirthdayDescription = !this.showBirthdayDescription;
      this.fieldValues.showBirthdayDescription = this.showBirthdayDescription;
    },
    updateFieldValuesInAutofill() {
      this.updateNameColumnField();
      this.updateProfessionColumnField();
      this.updateEmailColumnField();
      this.updatePhoneColumnField();
      this.updateBirthdayColumnField();
      this.updateProfilePicColumnField();
    },
    updateAppAttrs() {
      const params = {
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
      this.showSearch = params.showSearch;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.container-section {
  padding-left: 14.14px;
  height: 370px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.min-h-300px {
  min-height: 300px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  width: 61.15px;
  height: 32px;
}

.cells2 {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  color: black;
  border: 0.5px solid #7A7A7A;
  border-right-style: none;
  border-radius: 3px 0 0 3px;
  opacity: 1;
  height: 32px;
  padding-top: 5.4px;
  padding-left: 10px;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.visibility-icon {
  color: #7A7A7A;
  opacity: 1;
}

.delete-outline-icon {
  color: #2C6EEB;
  opacity: 1;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}
/* TITLE SECTION STYLE end */

/* FILTERS SECTION STYLE start*/
.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 100px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
/* FILTERS SECTION STYLE end*/

.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}

.icon-selection-container {
  margin-bottom: 120px;
  padding-right: 14px;
}

.right-padding-icon-selection {
  padding-right: 0px;
}

</style>
