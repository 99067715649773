<template>
  <div
    :class="{
      'come-up': isOpen,
      'come-down': !isOpen
    }"
  >
    <label
      id="listbox-label"
      class="block text-sm font-medium"
    >
      <slot name="SelectLabel" />
    </label>
    <div
      class="relative"
      v-click-outside="closeDropdown"
    >
      <span class="inline-block w-full rounded-md shadow-sm btn-dimensions">
        <button
          type="button"
          @click="openDropdown"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-1 text-left
            focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150
            sm:text-sm sm:leading-5 btn-dimensions select"
        >
          <div class="flex items-center space-x-3">
            <img
              v-if="value == 'GSheets' || dataSourceService === 'gsheet'"
              :src="newGoogleSheetIcon"
              alt="sheet icon"
              class="images"
            >
            <img
              v-else-if="value == 'Microsoft 365' || dataSourceService === 'office'"
              :src="spreadsheetOfficeIcon"
              alt="sheet icon"
              class="images"
            >
            <i
              v-else-if="isFromSystem(value) || dataSourceService === 'custom'"
              class="material-icons icons"
            >account_tree</i>
            <i
              v-else-if="isRecentlyUsed(value)"
              class="material-icons icons"
            >change_circle</i>
            <span
              class="block truncate text-left"
              v-if="value != ''"
            >
              {{ value }}
            </span>
            <span
              class="block truncate text-left"
              v-else
            >
              Seleccionar fuente...
            </span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </span>
        </button>
      </span>
      <!-- Select popover, show/hide based on select state. -->
      <div
        id="datasource-list-container"
        v-show="isOpen"
        class="absolute mt-1 w-full rounded-md shadow-lg scrollable-option ul-style"
      >
        <ul
          class=""
        >
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
            Highlighted: "text-white bg-selector", Not Highlighted: "text-gray-900"
          -->
          <li
            tabindex="0"
            @click="select(dataSource)"
            id="listbox-item-0"
            role="option"
            v-for="dataSource in dataSources"
            :key="dataSource"
            class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer
              hover:text-white hover:bg-selector focus:outline-none focus:text-white focus:bg-selector"
          >
            <div class="flex items-center space-x-3">
              <img
                v-if="dataSource == 'GSheets'"
                :src="newGoogleSheetIcon"
                alt="sheet icon"
                class="images"
              >
              <img
                v-if="dataSource == 'Microsoft 365'"
                :src="spreadsheetOfficeIcon"
                alt="sheet icon"
                class="images"
              >
              <span
                class="block truncate"
                :class="{ 'font-normal' : !isSelected(dataSource) , 'font-semibold' : isSelected(dataSource)}"
              >
                {{ dataSource }}
              </span>
            </div>
          </li>
          <div
            v-if="showFromSystemDatabases"
          >
            <li>
              <Collapsible
                name="fromSystem"
                :is-open="false"
              >
                <div
                  slot="trigger"
                >
                  <div class="flex items-center space-x-2 py-2 pl-3 pr-9 ">
                    <i
                      class="w-1/6 material-icons icons"
                    >account_tree</i>
                    <span class="w-3/6 block truncate text-left">
                      Del Sistema
                    </span>
                    <i
                      class="w-2/6 material-icons material-icons-round cursor-pointer"
                    >expand_more</i>
                  </div>
                </div>
                <div slot="closedTrigger">
                  <div class="flex items-center space-x-2 py-2 pl-3 pr-9 ">
                    <i
                      class="w-1/6 material-icons icons"
                    >account_tree</i>
                    <span class="w-3/6 block truncate text-left">
                      Del Sistema
                    </span>
                    <i
                      class="w-2/6 material-icons material-icons-round cursor-pointer"
                    >chevron_right</i>
                  </div>
                </div>
                <li
                  tabindex="0"
                  @click="select2(dataSource)"
                  id="listbox-item-0"
                  role="option"
                  v-for="dataSource in sheetFiles"
                  :key="dataSource.id"
                  class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9  cursor-pointer
                    hover:text-white hover:bg-selector focus:outline-none focus:text-white focus:bg-selector"
                >
                  <div
                    class="flex items-center space-x-3 pl-6"
                  >
                    <span
                      class="block truncate sm:text-sm sm:leading-5"
                      :class="{
                        'font-normal' : !isSelected(dataSource.name),
                        'font-semibold' : isSelected(dataSource.name)}"
                    >
                      {{ dataSource.name }}
                    </span>
                  </div>
                </li>
              </Collapsible>
            </li>
          </div>
          <li v-if="showSaved">
            <Collapsible
              name="recentlyUsed"
              :is-open="false"
            >
              <div
                slot="trigger"
              >
                <div class="flex items-center space-x-2 py-2 pl-3">
                  <i
                    class="w-1/6 material-icons icons"
                  >change_circle</i>
                  <span class="w-3/6 block truncate text-left">
                    Guardadas
                  </span>
                  <i
                    class="w-2/6 material-icons material-icons-round cursor-pointer"
                  >expand_more</i>
                </div>
              </div>
              <div slot="closedTrigger">
                <div class="flex items-center space-x-2 py-2 pl-3">
                  <i
                    class="w-1/6 material-icons icons"
                  >change_circle</i>
                  <span class="w-3/6 block truncate text-left">
                    Guardadas
                  </span>
                  <i
                    class="w-2/6 material-icons material-icons-round cursor-pointer"
                  >chevron_right</i>
                </div>
              </div>
              <li
                tabindex="0"
                @click="select2(dataSource)"
                id="listbox-item-0"
                role="option"
                v-for="dataSource in usedBeforeObjectsWithoutEmptyValues"
                :key="dataSource.id"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer
                  hover:text-white hover:bg-selector focus:outline-none focus:text-white focus:bg-selector"
              >
                <div class="flex items-center space-x-3 pl-6">
                  <span
                    class="block truncate sm:text-sm sm:leading-5"
                    :class="{
                      'font-normal' : !isSelected(dataSource.dataSourceFilename),
                      'font-semibold' : isSelected(dataSource.dataSourceFilename)}"
                  >
                    {{ dataSource.dataSourceFilename }}
                  </span>
                </div>
              </li>
            </Collapsible>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import ClickOutside from 'vue-click-outside';
import Collapsible from 'vue-collapsible-component';
import spreadsheetOfficeIcon from '../images/icon_microsoftoffice.svg';
import newGoogleSheetIcon from '../images/icons8-google-sheets.svg';
import googleApiClientMixin from '../mixins/googleApiClientMixin';

Vue.directive('click-outside', {
  bin(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

export default {
  mixins: [googleApiClientMixin],
  name: 'VueSelectCustomDataSource',
  props: {
    value: { type: String, default: '' },
    dataSources: { type: Array, default: () => ['GSheets', 'Microsoft 365'] },
    fromSystemObjects: { type: Array, default: () => [
      { dataSourceFilename: 'BD interna usuarios 2022', id: 13 },
      { dataSourceFilename: 'BD interna rendimientos 2022', id: 13 },
    ],
    },
    usedBeforeObjects: { type: Array, default: () => [
      { dataSourceFilename: 'Gsheet datasource name 1', id: 13 },
      { dataSourceFilename: '365 datasource name 1', id: 13 },
    ],
    },
    listener: { type: String, default: 'valueSelect' },
    showFromSystemDatabases: { type: Boolean, default: true },
    showSaved: { type: Boolean, default: true },
  },
  components: {
    Collapsible,
  },
  data() {
    return {
      isOpen: false,
      spreadsheetOfficeIcon,
      newGoogleSheetIcon,
    };
  },
  mounted() {
    this.getCustomTables('');
    if (!this.showFromSystemDatabases) {
      if (this.showSaved) {
        $('#datasource-list-container').css('height', '121.5px');
      } else {
        $('#datasource-list-container').css('height', '81px');
      }
    }
  },
  methods: {
    isSelected(value) {
      return this.value === value;
    },
    closeDropdown() {
      this.isOpen = false;
      this.$emit('tightnessListener', this.value, 'close');
    },
    openDropdown() {
      if (this.isOpen === false) {
        this.$emit('tightnessListener', this.value, 'open');
      }
      this.isOpen = true;
    },
    select(value) {
      this.isOpen = false;
      let service = null;
      if (value === 'GSheets') {
        service = 'gsheet';
      } else if (value === 'Microsoft 365') {
        service = 'excel';
      } else if (this.isFromSystem(value)) {
        service = 'custom';
      }
      this.$emit(this.listener, value, service, null);
      this.$emit('tightnessListener', value, 'close');
    },
    identifyWhichNameUse(dataSource) {
      let value;
      let service;
      if (this.isFromSystem(dataSource.name)) {
        if ('dataSourceFilename' in dataSource) {
          value = dataSource.dataSourceFilename;
        } else {
          value = dataSource.name;
        }
        service = { 'dynamic': 'custom_dynamic', 'static': 'custom' }[dataSource.internalType];
      } else {
        value = dataSource.dataSourceFilename;
        service = dataSource.service;
      }

      return [value, service];
    },
    select2(dataSource) {
      this.isOpen = false;
      let service = null;
      let value = null;
      const valueAndService = this.identifyWhichNameUse(dataSource);
      value = valueAndService[0];
      service = valueAndService[1];
      this.$emit(this.listener, value, service, dataSource);
      this.$emit('tightnessListener', dataSource.name, 'close');
    },
    isFromSystem(name) {
      return this.fromSystemNames.includes(name);
    },
    isRecentlyUsed(name) {
      return this.recentlyUsedNames.filter(recentlyUsedName => recentlyUsedName !== null).includes(name);
    },
  },
  computed: {
    ...mapState({
      selectedDataSource: state => state.users.selectedDataSource,
    }),
    fromSystemNames() {
      const names = [];
      // this returns all internal databases created by the current user
      const fromSystemTables = this.sheetFiles;
      Object.keys(fromSystemTables).forEach(key => {
        names.push(fromSystemTables[key].name);
      });

      return names;
    },
    recentlyUsedNames() {
      const names = [];
      Object.keys(this.usedBeforeObjects).forEach(key => {
        names.push(this.usedBeforeObjects[key].dataSourceFilename);
      });

      return names;
    },
    usedBeforeObjectsWithoutEmptyValues() {
      return this.usedBeforeObjects.filter(dataSource =>
        !['', null, undefined].includes(dataSource.dataSourceFilename));
    },
    dataSourceService() {
      if (![null, undefined].includes(this.$store.state.users.selectedDataSource)) {
        const service = this.$store.state.users.selectedDataSource.service;

        return service;
      }

      return null;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.icons {
  color: #000000;
  height: 20px;
  width: 20px;
}
.images {
  filter: brightness(0%);
  height: 20px;
  width: 20px;
}

.btn-dimensions {
  height: 32px;
}

.come-up {
  z-index: 100;
  position: relative;
}

.come-down {
  z-index: 0;
  position: relative;
}

.arrows-padding {
  padding-left: 18px;
}

.from-sys-and-rec-used-data-source-letter {
  font-style: normal;
  font-variant: normal;
  font-size: 9px;
  line-height: 22px;
  font-family: Inter;
}

.select {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.ul-style {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 0.5px solid #7A7A7A;
  opacity: 1;
}

.scrollable-option {
  height: 162px;
  overflow: auto;
  position: relative;
  z-index: 100;
}

</style>
