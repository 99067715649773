<template>
  <div>
    <modal
      name="user"
      width="600px"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div class="app-modal__header">
        {{ $t('user.logInAgain') }}
        <div />
      </div>
      <div
        class="btn app-modal__save-btn"
        @click="signOut"
      >
        {{ $t('template.goToLogIn') }}
      </div>
    </modal>
  </div>
</template>

<script>
import api from '../api';

export default {
  mounted() {
    this.$modal.show('user');
  },
  methods: {
    signOut() {
      api.signOut().then(() => {
        window.location.href = '/users/sign_in';
      });
    },
  },

};
</script>
