<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">code</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Fuente
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FUENTE
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FUENTE
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col mb-4"
        >
          <div class="flex flex-row">
            <div
              class="flex flex-row"
            >
              <textarea
                style="height: 32px; width: 193px; min-height: 32px; max-height: 200px;
                  padding-left: 12px; padding-right: 12px; padding-top: 6.3px;"
                id="stylized-text"
                type="text"
                class="text-left text-sm font-inter-type-1 cells"
                v-model="sourceContent"
                placeholder="<Escribe aquí tu código>"
              />
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Título
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasDescriptiveText"
              :sync="true"
              @input="hasDescriptiveText = !hasDescriptiveText; updateAppAttributes();"
            />
          </div>
          <div class="flex mt-4 pl-3 mb-4">
            <p
              class="text-left text-menu-label w-3/6 text-sm font-inter-type-1"
              style="margin-top: 5px;"
            >
              Superponer
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-3/6"
              style="padding-left: 31px;"
              :value="descriptiveTextOverlap"
              :sync="true"
              @input="descriptiveTextOverlap = !descriptiveTextOverlap; updateAppAttributes();"
            />
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import showMessageMixin from '../mixins/showMessageMixin';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

export default {
  mixins: [showMessageMixin],
  components: {
    Collapsible,
    ContainerSection,
    AppTitleInOptions,
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    let sourceContent = _.get(container, 'app.content', '');
    if (['', '< Escribe aquí tu código >'].includes(sourceContent)) {
      sourceContent = null;
    }

    return {
      container,
      sourceContent,
      borderColor: _.get(container, 'borderColor', ''),
      showShading: _.get(container, 'showShading', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      showTransparency: _.get(container, 'showTransparency', false),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      hasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false),
      descriptiveText: _.get(container, 'app.descriptiveText', 'Título...'),
      descriptiveTextOverlap: _.get(container, 'app.descriptiveTextOverlap', false),
      containerID: _.get(container, 'id', -1),
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTransparency: _.get(container, 'showTransparency', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialDescriptiveText: _.get(container, 'app.descriptiveText', 'Título...'),
      initialHasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false) === true,
      initialDescriptiveTextOverlap: _.get(container, 'app.descriptiveTextOverlap', false),
      initialSourceContent: sourceContent,
      initialIframeWidth: null,
      initialIframeHeight: null,
      iframeProportion: null,
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    ...mapState({
      shouldSaves: state => state.views.shouldSaves,
      selectedContainerId: state => state.views.selectedContainerId,
      saveAlert: state => state.ui.saveAlert,
    }),
    appWithType() {
      const appAndType = {
        app: {
          hasDescriptiveText: this.hasDescriptiveText,
          descriptiveText: this.descriptiveText,
          descriptiveTextOverlap: this.descriptiveTextOverlap,
        },
        appType: 'SourceApp',
      };
      if (this.sourceContent !== null) {
        appAndType.app.content = this.sourceContent;
      }

      return appAndType;
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        borderColor: this.borderColor,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        backgroundColor: this.containerBackgroundColor,
        ...this.appWithType,
      };
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialHasDescriptiveText !== this.hasDescriptiveText ||
        this.initialDescriptiveText !== this.descriptiveText ||
        this.initialDescriptiveTextOverlap !== this.descriptiveTextOverlap ||
        (this.sourceContent !== this.initialSourceContent && this.sourceContent !== '')
      ) {
        return true;
      }

      return false;
    },
    canSave() {
      const canSave = this.changesToSend;
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    HtmlRendererID() {
      return `#renderer${this.containerID}`;
    },
    iframeID() {
      return `#iframe${this.containerID}`;
    },
  },
  methods: {
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
      this.resetInitialValues();
    },
    resetInitialValues2() {
      this.initialShowShading = this.showShading;
      this.initialContainerBackgroundColor = this.containerBackgroundColor.toLowerCase();
      this.initialImageUrl = this.imageUrl;
      this.initialDescriptiveText = this.descriptiveText;
      this.initialHasDescriptiveText = this.hasDescriptiveText === true;
      this.initialDescriptiveTextOverlap = this.descriptiveTextOverlap;
      this.initialSourceContent = this.sourceContent;
    },
    resetInitialValues() {
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialBorderColor = this.borderColor.toLowerCase();
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTransparency = this.showTransparency;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    updateAppAttributes() {
      const params = {
        descriptiveText: this.descriptiveText,
        hasDescriptiveText: this.hasDescriptiveText,
        descriptiveTextOverlap: this.descriptiveTextOverlap,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>

<style scoped>

.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.radio-btn-size {
  height: 16px;
  width: 16px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 195px;
  height: 32px;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.width-icon {
  transform: rotate(90deg);
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.inter-select-title {
  padding-top: 10px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}

.range-input {
  background: #555859 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #555859;
  opacity: 1;
}

input[type="range"]{
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  border: 1px solid #555859;
  border-radius: 30px;
  background: #B3B3B3 0% 0% no-repeat padding-box;
}
input[type="range"]::-moz-focus-outer {
  border: 0;
}
input[type="range"]:hover {
  outline:none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 13px;
  height: 13px;
  background: #555859 0% 0% no-repeat padding-box;
  cursor: pointer;
  border-radius: 30px;
  outline:none;
}

.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.tooltip .tooltip-text {
  font-size: 12px;
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: justify;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  transform: translate(-205px, -10px);
  z-index: 1000;
  padding-left: 3px;
  padding-right: 3px;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

</style>
