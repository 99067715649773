<template>
  <div
    class="p-2 border border-gray-300 flex rounded-lg items-center h-full app-height"
  >
    <!-- <a :href="showPath">
      <div
        class="grid-index__name"
        :class="{'grid-index__name--no-icons': !isAdminUser}"
      >
        <i class="material-icons">layers</i>
        {{ template.name || "Sin título" }}
      </div>
    </a> -->
    <div
      class="w-2/6 h-full rounded-lg bg-gray-400 mt-2 mb-2 object-cover"
    >
      <img
        @click="goToShow"
        class="object-cover w-full h-full"
        :src="template.view.thumbnailUrl"
      >
    </div>
    <div class="flex flex-col w-4/6 pl-4 h-full">
      <div>
        <p class="text-sm text-gray-500">
          {{ template.name }}
        </p>
        <span>{{template.visibilityStatus}}</span>
<!--         <span v-if="template.visibilityStatus === 'public'"><i class="material-icons" title="Publica">visibility</i></span>
        <span v-else-if="template.visibilityStatus === 'workspace"><i class="material-icons" title="Privada(solo visible para el creador)">visibility_off</i></span>
        <span v-else><i class="material-icons" title="Privada(solo visible para el creador)">visibility_off</i></span>
 -->        <p class="text-xs text-gray-400 truncate min-description">
          {{ template.description }}
        </p>
      </div>

      <div
        class="flex flex-row items-center h-full"
      >
        <div
          v-if="(isAdminUser || !isCreator)"
          @click="implement"
          class="p-1  w-16 bg-indigo-200 text-indigo-600 rounded-lg text-center text-sm cursor-pointer"
        >
          <!-- {{ template.disabled ? 'Use': 'Added' }} -->
          Use
        </div>
        <div
          @click="goToShow"
          class="m-2 w-10 text-sm text-indigo-600 cursor-pointer"
        >
          View
        </div>

        <div
          class="flex flex-row flex-wrap items-center"
          v-if="isAdminUser || isCreator"
        >
          <a
            @click="selectForEdition()"
            class="pl-1 cursor-pointer"
            title="Editar Metadatos del Template"
          >
            <i class="material-icons">edit</i>
          </a>

          <a
            :href="editPath"
            class="cursor-pointer"
            title="Editar Template"
          >
            <i class="material-icons">edit_calendar</i>
          </a>
          <button
            title="Eliminar"
            class="cursor-pointer"
            @click="openConfirmationModal"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { SELECT_TEMPLATE_FOR_EDITION, SELECT_TEMPLATE_FOR_DELETION } from '../store/action-types';
import showMessageMixin from '../mixins/showMessageMixin';
import api from '../api';

export default {
  mixins: [showMessageMixin],
  props: {
    template: {
      type: Object,
      required: true,
    },
    isAdminUser: {
      type: Boolean,
      default: false,
    },
    currentUserId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    showPath() {
      return `/view_templates/${this.template.id}`;
    },
    editPath() {
      return `/view_templates/${this.template.id}/edit`;
    },
    isCreator() {
      return this.template.creatorId === this.currentUserId;
    },
  },
  methods: {
    selectForEdition() {
      this.$store.dispatch(SELECT_TEMPLATE_FOR_EDITION, this.template);
    },
    openConfirmationModal() {
      this.$store.dispatch(SELECT_TEMPLATE_FOR_DELETION, this.template);
    },
    goToShow() {
      window.location.href = this.showPath;
    },
    implement() {
      let message;
      api.implementViewTemplate(this.template.id).then((newView) => {
        message = this.$t('template.implementSuccessMessage');
        this.showMessage(message);
        window.location.href = `/views/${newView.uuid}/edit/`;
      }).catch(() => {
        message = 'template.implementFailedMessage';
        this.showMessage(message);
      });
    },
  },
};
</script>
