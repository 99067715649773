<template>
  <div>
    <app-modal
      :header-text="$t('view.apps.space.name')"
      header-icon="border_clear"
      :app-filled="true"
      :app-with-type="appWithType"
      :show-title="false"
      :show-comments-toggle="false"
      :show-borders-toggle="false"
    >
      <div class="app-modal__input-header">
        {{ $t('view.apps.space.height') }}
      </div>
      <input
        v-model.number="height"
        type="number"
        class="app-modal__input app-modal__input--small"
      >
      <span>px</span>
    </app-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import AppModal from './app-modal';
import { APP_TYPES } from '../constants';

export default {
  components: {
    AppModal,
  },
  data() {
    const container = this.$store.getters.selectedContainer;

    return {
      height: _.get(container, 'app.height', 0),
    };
  },
  computed: {
    appWithType() {
      return {
        app: { height: this.height },
        appType: APP_TYPES.SPACE,
      };
    },
  },
};
</script>
