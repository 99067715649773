<template>
  <div>
    <div
      ref="textApp"
      class="h-full"
      v-if="container && !editable"
      style="padding-left: 15px; padding-right: 15px;"
    >
      <div
        v-if="visualizationOption === 'Ver más' && showSeeMore"
        class="inline"
      >
        <div
          :id="showMoreContainerId"
          class="polymorphic-app--text ql-editor inline"
          :style="editorStyle"
          v-html="analyzingContent()"
        />
        <p
          v-show="shouldShowSeeMore"
          class="see-more inline"
          :id="seeMoreID"
          @click="seeMore()"
          style="position: relative; left: -15px;"
          :style="seeMoreColorStyle"
        >
          ...(Ver más)
        </p>
      </div>
      <div
        v-else
        :id="showMoreContainerId"
        class="polymorphic-app--text ql-editor"
        :style="editorStyle"
        v-html="content"
      />
    </div>
    <div
      class="h-full :overflow-hidden overflow-y-scroll"
      ref="textApp"
      id="text-app"
      v-else
    >
      <vue-editor
        :id="editorId"
        v-model="content"
        :editor-toolbar="customToolbar"
        @text-change="autoSave"
        @focus="toolbarShow(); activateTextAppBeenEdited();"
        @blur="toolbarHide(); deactivateTextAppBeenEdited();"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { VueEditor } from 'vue2-editor';
import $ from 'jquery';
import Quill from 'quill';
import { APP_TYPES } from '../constants';
import {
  SAVE_CONTAINER,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';
import {
  SET_APP_MODAL_TYPE,
  SET_APP_MODAL_TO_SHOW,
  SET_SELECTED_CONTAINER,
  SET_TEXT_APP_BEEN_EDITED,
} from '../store/mutation-types';

const DEBOUNCE_TIME = 1000;
const DEFAULT_FORMAT_LINE_SPACING = 10;
const GAP_IN_LINE_SPACING = 5;
const LAST_HEIGHT_PIXELS_HIDDEN = 20;
const FIRST_PARAGRAPH_CHAR_LIMIT = 170;

export default {
  mixins: [positionMixin],
  components: {
    VueEditor,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
    showSeeMore: { type: Boolean, default: true },
  },
  mounted() {
    $('.ql-toolbar.ql-snow').hide();
    this.addSpaceBetween();
    if (this.content !== null) {
      this.addFontStyleToShowMore();
    }
  },
  data() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
      'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
      'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
      'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
    ];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = [
      '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
    ];
    Quill.register(Size, true);
    const justTitle = this.defineJustTitle();
    const shouldShowSeeMore = this.defineShouldShowSeeMore(justTitle);

    return {
      appTypes: APP_TYPES,
      content: _.get(this.container, 'app.content', null),
      editorOptions: {
        scrollingContainer: '#text-app',
      },
      customToolbar: customToolbarHelper.customToolbar,
      visualizationOption: _.get(this.container, 'app.visualizationOption', 'Scroll'),
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      showSeeMoreEdited: true,
      hasTitle: false,
      shouldShowSeeMore,
      addBr: false,
      justTitle,
      initialContent: _.get(this.container, 'app.content', null),
      seeMoreColor: _.get(this.container, 'app.seeMoreColor', "#0031FF"),
    };
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    seeMoreColorStyle() {
      return `color: ${this.seeMoreColor};`;
    },
    appContainerID() {
      return `#app-container${this.containerID}`;
    },
    editorId() {
      return `text-editor${this.container.id}`;
    },
    textEditor() {
      return '#text-editor'.concat(this.container.id.toString());
    },
    formatLineSpacing() {
      return _.get(this.container, 'app.formatLineSpacing', DEFAULT_FORMAT_LINE_SPACING);
    },
    containerHeight() {
      const position = this.container.position.style;
      if (position && position.includes('height')) {
        const positionArray = position.split(';');
        const height = $.grep(positionArray, (element) =>
          element.includes('height'))[0].split(':')[1].replace(/ /g, '');

        return height;
      }

      return '';
    },
    containerWidth() {
      const position = this.container.position.style;
      if (position && position.includes('width')) {
        const positionArray = position.split(';');
        const width = $.grep(positionArray, (element) => element.includes('width'))[0].split(':')[1].replace(/ /g, '');

        return width;
      }

      return '';
    },
    seeMoreID() {
      return `see-more${this.container.id}`;
    },
    showMoreContainerId() {
      return `show-more-container-${this.container.id}`;
    },
    editorStyle() {
      if (this.showSeeMoreEdited === false || this.showSeeMore === false || this.containerHeight === '') {
        return '';
      }
      const minAndMaxHeight = 'min-height: 0; max-height: '.concat(
        (parseInt(this.containerHeight.split('px')[0], 10) - LAST_HEIGHT_PIXELS_HIDDEN).toString()
          .concat('px;', ' height: ', this.containerHeight, ' !important;'),
      );

      return minAndMaxHeight;
    },
  },
  methods: {
    seeMore() {
      this.$store.commit(SET_APP_MODAL_TYPE, this.appTypes.TEXT);
      this.$store.commit(SET_APP_MODAL_TO_SHOW, this.container.id);
      this.$store.commit(SET_SELECTED_CONTAINER, this.container);
    },
    addSpaceBetween() {
      const spaceBetweenLines = (this.formatLineSpacing - GAP_IN_LINE_SPACING).toString().concat('px');
      if (this.container && !this.editable) {
        $('#'.concat(this.showMoreContainerId))
          .children()
          .css('margin-bottom', spaceBetweenLines);
      } else {
        $('#'.concat(this.editorId)).children().first()
          .children()
          .css('margin-bottom', spaceBetweenLines);
      }
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.textEditor).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow() {
      $(this.textEditor).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    hasContainer() {
      return !_.isEmpty(this.container);
    },
    autoSave: _.debounce(function () {
      this.updateAppTextContent();
    }, DEBOUNCE_TIME),
    updateAppTextContent() {
      if (this.initialContent !== this.content) {
        const params = { app: { content: this.content } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        const initialContent = this.content;
        this.initialContent = initialContent;
      }
    },
    activateTextAppBeenEdited() {
      this.$store.commit(SET_TEXT_APP_BEEN_EDITED, true);
    },
    deactivateTextAppBeenEdited() {
      this.$store.commit(SET_TEXT_APP_BEEN_EDITED, false);
    },
    extractFirstParagraph() {
      const initialArray = this.content.split('<p');
      let firstParagraph;
      // eslint-disable-next-line no-magic-numbers
      if (initialArray[0].substring(1, 3) === 'p') {
        firstParagraph = initialArray[0];
      } else {
        this.hasTitle = true;
        if (initialArray[1]) {
          if (initialArray[1].includes('<br>')) {
            // eslint-disable-next-line no-magic-numbers
            this.addBr = true;
            firstParagraph = initialArray[2];
          } else {
            firstParagraph = initialArray[1];
          }
        }
      }

      return firstParagraph;
    },
    extractContentInsideFirstParagraph(firstParagraph) {
      let begining;
      for (let i = 0; i < firstParagraph.length; i++) {
        if (firstParagraph.charAt(i) === '>') {
          begining = i;
          break;
        }
      }

      return firstParagraph.substring(begining + 1, firstParagraph.indexOf('</p>'));
    },
    countTags(contentInsideFirstParagraph) {
      return (contentInsideFirstParagraph.match(new RegExp('</', 'g')) || []).length;
    },
    getBeginingOfPlainText(contentInsideFirstParagraph, tagsCountInside) {
      let tagsEndCount = 0;
      let beginingOfPlainText;
      for (let i = 0; i < contentInsideFirstParagraph.length; i++) {
        if (contentInsideFirstParagraph.charAt(i) === '>') {
          tagsEndCount++;
        }
        if (tagsEndCount === tagsCountInside) {
          beginingOfPlainText = i + 1;
          break;
        }
      }

      return beginingOfPlainText;
    },
    extractThreeChunks(contentInsideFirstParagraph, tagsCountInside) {
      // the first chunk are the tags init, the second is plain text between and third are the tags end
      const beginingOfPlainText = this.getBeginingOfPlainText(contentInsideFirstParagraph, tagsCountInside);
      const startOfThirdChunk = contentInsideFirstParagraph.indexOf('</');
      if (startOfThirdChunk === -1) {
        return ['', contentInsideFirstParagraph, ''];
      }
      const firstChunk = contentInsideFirstParagraph.substring(0, beginingOfPlainText);
      const secondChunk = contentInsideFirstParagraph.substring(beginingOfPlainText, startOfThirdChunk);
      const thirdChunk = contentInsideFirstParagraph.substring(startOfThirdChunk, contentInsideFirstParagraph.length);

      return [firstChunk, secondChunk, thirdChunk];
    },
    analyzingContent() {
      if (this.justTitle || this.content === null) {
        return this.content;
      }

      const firstParagraph = this.extractFirstParagraph();
      const contentInsideFirstParagraph = this.extractContentInsideFirstParagraph(firstParagraph);
      const tagsCountInside = this.countTags(contentInsideFirstParagraph);

      const threeChunks = this.extractThreeChunks(contentInsideFirstParagraph, tagsCountInside);
      console.log('threeChunks');
      console.log(threeChunks);

      return this.generateViewMoreContent(threeChunks);
    },
    generateViewMoreContent(threeChunks) {
      const tagsInit = threeChunks[0];
      if (this.content.length < FIRST_PARAGRAPH_CHAR_LIMIT) {
        this.shouldShowSeeMore = false;
      }
      let plainTextSubstring = threeChunks[1].substring(0, FIRST_PARAGRAPH_CHAR_LIMIT);
      const tagsEnd = threeChunks[2];
      if (this.addBr) plainTextSubstring = '<br>'.concat(plainTextSubstring);
      if (this.hasTitle) {
        const titleHtml = this.content.split('<p')[0];

        return titleHtml.concat(tagsInit, plainTextSubstring, tagsEnd);
      }

      return tagsInit.concat(plainTextSubstring, tagsEnd);
    },
    addFontStyleToShowMore() {
      const firstParagraph = this.extractFirstParagraph();
      if (firstParagraph && firstParagraph.includes('font-size')) {
        const fontSize = firstParagraph.split('font-size: ')[1].split(';')[0];
        const seeMoreIdJs = `#${this.seeMoreID}`;
        $(seeMoreIdJs).css({ fontSize });
      }
      if (firstParagraph && firstParagraph.includes('class="ql-font-')) {
        let font = firstParagraph.split('class="ql-font-')[1].split('"')[0];
        font = 'ql-font-'.concat(font);
        const seeMoreIdJs = `#${this.seeMoreID}`;
        $(seeMoreIdJs).addClass(font);
      }
    },
    defineJustTitle() {
      let justTitle = false;
      const content = _.get(this.container, 'app.content', null);
      if (content !== null && content.split('<p').length === 1) {
        justTitle = true;
      }

      return justTitle;
    },
    defineShouldShowSeeMore(justTitle) {
      let shouldShowSeeMore = true;
      if (justTitle) {
        shouldShowSeeMore = false;
      }

      return shouldShowSeeMore;
    },
  },
};
</script>
<style>

/* SIZES end */

.see-more {
  color: #0031FF;
  cursor: pointer;
}

.hide-last-pixels {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hideScroll {
  overflow: hidden !important;
}

</style>
