<template>
  <div class="flex items-center">
    <i
      ref="dropDown"
      id="dropDown"
      @click.stop="openDropdown"
      class="material-icons material-icons--primary cursor-pointer float-right"
    >more_horiz</i>
    <div
      class="list__folder-view--dropdown text-sm"
      v-if="open"
    >
      <div class="list__folder-view--dropdown-content shadow-card rounded-md text-left">
        <a
          class="list__folder-view--dropdown-item h-12 rounded-md"
          :href="viewPath(rowData)"
        >
          <i class="material-icons-outlined material-icons-inline pr-3">preview</i>
          <span class="align-middle">View</span>
        </a>

        <a
          class="list__folder-view--dropdown-item h-12 rounded-md"
          :href="editPath(rowData)"
        >
          <i
            :href="editPath(rowData)"
            class="material-icons material-icons-inline pr-3"
          >edit</i>
          <span class="align-middle">{{ $t("view.edit") }}</span>
        </a>
        <div
          @click="openMoveResourceModal(rowData)"
          class=" list__folder-view--dropdown-item"
        >
          <i class="material-icons-outlined material-icons-inline pr-3">snippet_folder</i>
          <span class="align-middle">{{ $t("view.folder.move") }}</span>
        </div>

        <div
          class="list__folder-view--dropdown-item h-12"
          @click="onActionClicked('copy-item', rowData)"
        >
          <i class="material-icons-outlined material-icons-inline pr-3">file_copy</i>
          <span class="align-middle">{{ $t("view.copy") }}</span>
        </div>
        <div
          class=" list__folder-view--dropdown-item h-12"
          @click="onActionClicked('share-item', rowData)"
        >
          <i class="material-icons-outlined material-icons-inline pr-3">share</i>
          <span class="align-middle">{{ $t("view.shareButton") }}</span>
        </div>

        <div
          class="list__folder-view--dropdown-item h-12"
          @click="onActionClicked('delete-item', rowData)"
        >
          <i class="material-icons-outlined material-icons-inline pr-3">delete</i>
          <span class="align-middle">{{ $t("view.folder.deleteShort") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapState } from 'vuex';
import {
  SELECT_VIEW_FOR_DELETION,
  SELECT_RESOURCE_FOR_SHARING,
  SELECT_RESOURCE_FOR_MOVING,
} from '../store/action-types';
import {
  OPEN_FOLDER_MODAL,
  UPDATE_VIEWS,
} from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';
import escapeMixin from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';
import api from '../api';

const MIN_WIDTH = 1600;
const MAX_RECENTS_BP = 4;

export default {
  mixins: [showMessageMixin, escapeMixin, clickMixinHandler],
  props: {
    rowData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
      createDropdownOpen: false,
      open: false,
    };
  },
  mounted() {
  },
  computed: {
    ...mapState({
      displayableViews: state => state.views.list,
      displayableFolders: state => state.views.folderList,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      shareResourceModalOpened: state => state.ui.shareResourceModalOpened,
      moveResourceModalOpened: state => state.ui.moveResourceModalOpened,
      folderModalOpened: state => state.ui.folderModalOpened,
      currentFolder: state => state.views.currentFolder,
    }),
    latestBp() {
      return _.orderBy(this.displayableViews, 'updatedAt', ['desc']).slice(0, MAX_RECENTS_BP);
    },

  },
  methods: {
    openDropdown() {
      this.open = !this.open;
    },
    openMoveResourceModal(view) {
      const options = { resource: view, resourceType: 'View' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
    },
    onResize() {
      if (window.outerWidth <= MIN_WIDTH) {
        this.isCollapsed = true;
      }
    },
    openFolderModal() {
      this.$store.commit(OPEN_FOLDER_MODAL);
      this.createDropdownOpen = false;
    },
    showPath(folderId) {
      if (this.inSharedFolder) return `/shared/${folderId}`;

      return `/folders/${folderId}`;
    },
    viewPath(view) {
      return `/views/${view.id}`;
    },
    editPath(view) {
      return `/views/${view.id}/edit`;
    },
    onActionClicked(action, data) {
      if (action === 'delete-item') {
        this.$store.dispatch(SELECT_VIEW_FOR_DELETION, data.id);
      } else if (action === 'share-item') {
        const options = { resource: data, resourceType: data.type };
        this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
      } else if (action === 'move-item') {
        const options = { resource: data, resourceType: data.type };
        this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
      } else if (action === 'copy-item') {
        this.duplicate(data);
      }
    },
    duplicate(selectedView) {
      let msg;
      api.duplicateView(selectedView.id, this.currentFolder.id).then((view) => {
        this.$store.commit(UPDATE_VIEWS, view);
        msg = this.$t('view.duplicateSuccessMessage');
        this.showMessage(msg);
      }).catch(() => {
        msg = this.$t('view.duplicateFailedMessage');
        this.showMessage(msg);
      });
    },
    close() {
      this.open = false;
    },
  },
};
</script>
<style scoped>

.list__folder-view--dropdown {
  position: relative;
  display: inline-block;
  left: -1.5em;
}

.list__folder-view--dropdown-content {
  display: block;
  position: absolute;
  min-width: 160px;
  border: 1px solid #e2e5e9;
  z-index: 1;
  left: -80px;
  top: -2px;
  padding-left: 1em;
  background-color: #fff;
}

.list__folder-view--dropdown-item {
  cursor: pointer;
  color: #7a7a7a;
  display: block;
  background-color: #fff;
  padding-bottom: .5em;
  padding-top: .7em;
}

.w-148 {
  width: 148px;
}

</style>

