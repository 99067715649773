<template>
  <div
    class="bg-white data-source-container-section border-t flex flex-col mt-4 relative"
  >
    <Collapsible
      class="width-collapsible"
      :is-open="true"
    >
      <div
        slot="trigger"
      >
        <div class="flex items-center">
          <p class="w-8/12 container-section-title text-base">
            FUENTE DE DATOS
          </p>
          <div
            v-if="tooltipFileUri"
            class="w-1/12 tooltip"
          >
            <i
              class="material-icons ml-1 cursor-pointer tooltip-color"
              style="font-size: 0.95em;"
            >help_outline</i>
            <span class="tooltip-text flex flex-row">
              <div>
                Revisa la plantilla de ejemplo
                <a
                  :href="tooltipFileUri"
                  target="_blank"
                  class="cursor-pointer tooltip-redirect"
                >aquí</a>
              </div>
            </span>
          </div>
          <i
            class="w-3/12 material-icons material-icons-round cursor-pointer"
          >expand_more</i>
        </div>
      </div>
      <div slot="closedTrigger">
        <div class="flex items-center ">
          <p class="w-4/6 container-section-title text-base">
            FUENTE DE DATOS
          </p>
          <i
            class="w-2/6 material-icons material-icons-round cursor-pointer"
          >chevron_right</i>
        </div>
      </div>
      <div
        :class="{
          'tightness-solver': this.tightnessSolver === true,
        }"
      >
        <div>
          <VueSelectCustomDataSource
            class="vue-select-container"
            :data-sources="externalDataSources"
            v-model="selectedDataSourceName"
            @dataSourceListener="onDataSourceSelect"
            @tightnessListener="updateTightDiv"
            :from-system-objects="[]"
            :show-from-system-databases="false"
            :used-before-objects="usedBefore"
            :listener="'dataSourceListener'"
          />
        </div>
        <div
          v-show="showSelectionSection"
        >
          <div class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 inter-sub-section">
            Selección
          </div>
          <div
            class="inter-select-title vue-select-container"
            style="margin-bottom: 10px;"
          >
            <input
              v-model="documentSearchParam"
              type="text"
              class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
              placeholder="Palabras clave documento"
              @keyup="fileLookup"
            >
          </div>
          <div class="inter-select-title">
            <VueSelectStandard
              class="vue-select-container"
              :data="documentsInFunctionOfSelectedDataSourceName"
              v-model="selectedDocument"
              @documentListener="onDocumentSelect"
              :placeholder="'Documentos'"
              :listener="'documentListener'"
              :btn-id="documentBtnId"
            />
          </div>
          <div class="inter-select-title">
            <VueSelectStandard
              class="vue-select-container"
              :data="sheetsInFunctionOfSelectedDocument"
              v-model="selectedSheet"
              @sheetListener="onSheetSelect"
              :placeholder="'Hojas'"
              :listener="'sheetListener'"
              :btn-id="sheetBtnId"
            />
          </div>
          <div class="flex items-center inter-select-title">
            <div class="w-4/6 text-left text-menu-label text-sm font-inter-type-1">
              Celda a mostrar:
            </div>
            <input
              v-model="startRange"
              @input="updateDataSourceWithAllData"
              class="w-2/6 text-center text-sm font-inter-type-1 cells"
              placeholder="Ej: B3"
            >
          </div>
        </div>
        <div v-if="true">
          <div class="text-left text-menu-label text-sm font-inter-type-1 inter-sub-section">
            Recordar fuente como:
          </div>
          <div
            class="inter-select-title vue-select-container"
            style="margin-bottom: 20px;"
          >
            <input
              v-model="dataSourceFilename"
              @input="updateDataSourceWithAllData"
              class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
              placeholder="Nombre..."
            >
          </div>
        </div>
        <div v-else>
          <div style="padding-bottom: 8px;" />
        </div>
      </div>
      <div
        v-if="container"
        class="flex mt-4 mb-4"
      >
        <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
          Mostrar fuente
        </p>
        <toggle-button
          color="#2c6eeb"
          class="w-2/6"
          :value="showDataSource"
          :sync="true"
          @input="sendShowDataSource"
        />
      </div>
    </Collapsible>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import VueSelectCustomDataSource from './vue-select-custom-data-source';
import VueSelectStandard from './vue-select-standard';
import api from '../api';
import { SET_SELECTED_DATA_SOURCE } from '../store/action-types';
import {
  UNSET_SELECTED_DATA_SOURCE,
} from '../store/mutation-types';

import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import spreadsheetIcon from '../images/icon-spreadsheet.svg';
import spreadsheetOfficeIcon from '../images/icon_microsoftoffice.svg';

const DEBOUNCE_DELAY = 1000;
const DEBOUNCE_DELAY_ON_SELECTION = 500;
const DEFAULT_SELECTED_DOCUMENT = { name: 'Documentos', id: -2 };
const DEFAULT_SELECTED_SHEET = { name: 'Hojas', id: -2 };

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  props: {
    currentDatasource: { type: Object, default: null },
    tooltipFileUri: { type: String, default: null },
    container: { type: Object, default: null },
  },
  components: {
    Collapsible,
    VueSelectCustomDataSource,
    VueSelectStandard,
  },
  // eslint-disable-next-line max-statements
  data() {
    let ranges = ['', ''];
    let selectedDataSourceService = null;
    let sheetName = null;
    let allData = true;
    let sheets = [];
    const currentDatasource = this.$store.state.users.selectedDataSource;

    if (currentDatasource !== null) {
      if (currentDatasource.dataSourceType === 'table_internal') {
        ranges = ['', ''];
        selectedDataSourceService = 'custom';
        sheetName = this.getSelectedColumns(currentDatasource);
        sheets = currentDatasource.typeColumns;
        if (currentDatasource.conditions.length > 1) {
          allData = false;
        }
      } else {
        ranges = _.get(currentDatasource, 'range', '').split(':');
        sheetName = _.get(currentDatasource, 'sheetName', null);
        allData = true;
      }
    }

    return {
      excelFiles: [],
      dataSourceValues: null,
      dataSources: [],
      selectedFile: _.get(currentDatasource, 'name', null),
      selectedSourceId: _.get(currentDatasource, 'sourceId', null),
      selectedId: null,
      selectedDataSourceService,
      resultsOpen: false,
      resultsPageOpen: false,
      nameFile: null,
      sheets,
      sheetName,
      range: null,
      title: 'New ',
      dataSourceFilename: _.get(currentDatasource, 'dataSourceFilename', null),
      startRange: ranges[0],
      spreadsheetIcon,
      spreadsheetOfficeIcon,
      columnCondition: null,
      operation: null,
      valueCondition: null,
      dataSourceConditions: _.clone(_.get(currentDatasource, 'conditions', [])),
      allData,
      //  LO NUEVO
      selectedDataSourceName: '',
      selectedDocument: DEFAULT_SELECTED_DOCUMENT,
      selectedSheet: DEFAULT_SELECTED_SHEET,
      externalDataSources: [
        'GSheets',
        'Microsoft 365',
      ],
      tightnessSolver: false,
      documentSearchParam: '',
      selectedColumns: '', // this apply only for internal databases
      internalDatabaseColumns: [],
      showDataSource: _.get(this.selectedContainer, 'showDataSource', false),
    };
  },
  mounted() {
    api.getDataSources().then(response => {
      const normalizedDataSources = response.dataSources.reduce((objAccum, ds) => {
        objAccum[ds.id] = ds;

        return objAccum;
      }, {});
      this.dataSources = normalizedDataSources;
    });
    if (this.currentDatasource === null) {
      this.newDataSource = true;
    } else {
      this.onDataSourceSelect(
        this.currentDatasource.dataSourceFilename, this.currentDatasource.service, this.currentDatasource,
      );
    }
    if (this.selectedContainer) {
      this.showDataSource = this.selectedContainer.showDataSource;
    }
  },
  computed: {
    ...mapState({
      selectedDataSource: state => state.users.selectedDataSource,
    }),
    ...mapGetters({
      selectedContainer: 'selectedContainer',
    }),
    selectedDataSourceFilename() {
      return _.get(this.selectedDataSource, 'dataSourceFilename', null);
    },
    recentlyUsed() {
      return _.orderBy(this.dataSources, ['createdAt'], ['desc']);
    },

    isFilled() {
      // Only used when creating a new Data Source
      if (
        this.selectedDocument !== null &&
        this.dataSourceFilename !== null &&
        this.selectedSourceId !== null &&
        this.startRange !== null &&
        this.selectedSheet !== null
      ) {
        return true;
      }

      return false;
    },
    customDataSourceParams() {
      const dataSourceId = _.get(this.selectedDataSource, 'id', null);
      const params = {
        name: this.selectedFile,
        dataSourceType: 'table_internal',
        sourceId: this.selectedSourceId,
        service: this.selectedDataSourceService,
        dataSourceFilename: this.dataSourceFilename,
        id: dataSourceId,
      };

      return params;
    },
    fromSystemNames() {
      const names = [];
      // this returns all internal databases created by the current user
      this.getCustomTables(name);
      const fromSystemTables = this.sheetFiles;
      Object.keys(fromSystemTables).forEach(key => {
        names.push(fromSystemTables[key].name);
      });

      return names;
    },
    fromSystem() {
      const dataSources = [];
      for (let index = 0; index < Object.keys(this.dataSources).length; index++) {
        const dataSource = this.dataSources[Object.keys(this.dataSources)[index]];
        if (dataSource.dataSourceType === 'table_internal') {
          dataSources.push(dataSource);
        }
      }

      return dataSources;
    },
    usedBefore() {
      return this.recentlyUsed;
    },
    showSelectionSection() {
      const value = this.selectedDataSourceName;
      if (this.externalDataSources.includes(value) || value === '' ||
          ['gsheet', 'excel'].includes(this.selectedDataSource.service)
      ) {
        return true;
      }

      return false;
    },
    showColumnsSelection() {
      // Because companyId is present only when the user selected a new internal database
      if ('companyId' in this.selectedDataSource) {
        return true;
      }

      return false;
    },
    documentsInFunctionOfSelectedDataSourceName() {
      const documents = [];
      Object.keys(this.googleSheetDocuments).forEach(key => {
        documents.push({ name: this.googleSheetDocuments[key].name, id: this.googleSheetDocuments[key].id });
      });
      Object.keys(this.excelFiles).forEach(key => {
        documents.push({ name: this.excelFiles[key].name, id: this.excelFiles[key].id });
      });

      if (documents.length === 0) {
        documents.push({ name: 'No hay resultados', id: 0 });
      }

      return documents;
    },
    sheetsInFunctionOfSelectedDocument() {
      const sheets = [];
      if (typeof this.sheets[0] !== 'string') {
        Object.keys(this.sheets).forEach(key => {
          sheets.push({ name: this.sheets[key].properties.title, id: this.sheets[key].properties.SheetId });
        });
      }
      if (sheets.length === 0) {
        sheets.push({ name: 'No hay resultados', id: 0 });
      }

      return sheets;
    },
    optionsForInternalDatabases() {
      if ('customAttrs' in this.selectedDataSource) {
        return this.$store.state.users.selectedDataSource.customAttrs.map(column => column.name);
      }

      return [];
    },
    documentBtnId() {
      return 'documents-btn-selection';
    },
    sheetBtnId() {
      return 'sheets-btn-selection';
    },
  },
  methods: {
    isFromSystem(name) {
      return this.fromSystemNames.includes(name);
    },
    newDataSourceToUpdateGSheet(objectWithInfo) {
      return {
        name: this.selectedDocument.name,
        dataSourceFilename: objectWithInfo.rememberMeAs,
        dataSourceType: objectWithInfo.dataSourceType,
        dataRange: objectWithInfo.dataRange,
        sourceId: this.selectedDocument.id,
        id: objectWithInfo.dataSourceInAnalysis.id || null,
        service: objectWithInfo.dataSourceInAnalysis.service,
      };
    },
    newFromSystemDataSource(dataSource, newOrUpdate) {
      let fileName;
      let sourceId;
      if (newOrUpdate === 'new') {
        fileName = null;
        sourceId = dataSource.id;
      } else {
        fileName = this.dataSourceFilename;
        sourceId = dataSource.sourceId;
      }

      return {
        name: dataSource.name,
        dataSourceFilename: fileName,
        dataSourceType: 'table_internal',
        dataRange: null,
        sourceId,
        id: null, // not created yet
        service: 'custom',
      };
    },
    async gSheetDataSourceTreatment() {
      const newDataSource = this.newDataSourceToUpdateGSheet(
        {
          rememberMeAs: this.dataSourceFilename,
          dataSourceType: 'table',
          dataRange: `${this.selectedSheet.name}!${this.startRange}:${this.startRange}`,
          dataSourceInAnalysis: this.selectedDataSource },
      );
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async excelDataSourceTreatment() {
      const newDataSource = this.newDataSourceToUpdateGSheet(
        {
          rememberMeAs: this.dataSourceFilename,
          dataSourceType: 'excel',
          dataRange: `${this.selectedSheet.name}!${this.startRange}`,
          dataSourceInAnalysis: this.selectedDataSource },
      );
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async updateCustomDataSourceTreatment() {
      const newDataSource = this.newFromSystemDataSource(this.selectedDataSource, 'update');
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async newCustomDataSourceTreatment() {
      const newDataSource = this.newFromSystemDataSource(this.selectedDataSource, 'new');
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async updateDataSourceWithAllData() {
      const dataSourceInAnalysis = this.selectedDataSource;
      if ('service' in dataSourceInAnalysis) {
        if (dataSourceInAnalysis.service === 'gsheet') {
          this.gSheetDataSourceTreatment();
        } else if (dataSourceInAnalysis.service === 'custom') {
          this.updateCustomDataSourceTreatment();
        } else if (dataSourceInAnalysis.service === 'excel') {
          this.excelDataSourceTreatment();
        }
      } else { // this means is a new from System db
        this.newCustomDataSourceTreatment();
      }
    },
    updateTightDiv(value, openOrCloseSelect) {
      if (this.externalDataSources.includes(value) || value === '' ||
          ['gsheet', 'excel'].includes(this.$store.state.users.selectedDataSource.service)
      ) {
        this.tightnessSolver = false;
      } else {
        if (openOrCloseSelect === 'open') {
          this.tightnessSolver = true;
        } else {
          this.tightnessSolver = false;
        }
      }
    },
    fileLookup: _.debounce(function () {
      if (this.selectedId) {
        return;
      }
      this.findFile();
    }, DEBOUNCE_DELAY),

    findFile() {
      if ('service' in this.$store.state.users.selectedDataSource) {
        if (this.$store.state.users.selectedDataSource.service === 'gsheet') {
          this.getAllGSheetDocuments(this.documentSearchParam);
          document.getElementById(this.documentBtnId).click();
        } else if (this.$store.state.users.selectedDataSource.service === 'excel') {
          this.getAllMicrosoftExcelFiles(this.documentSearchParam);
          document.getElementById(this.documentBtnId).click();
        }
      } else {
        console.log(':( coming soon!');
      }
    },
    selectFile(file) {
      this.selectedFile = file.name;
      this.selectedSourceId = file.id;
      this.showResultPanel(false);
      this.sheets = [];
      this.sheetName = null;
      if (this.selectedDataSourceService === 'gsheet') {
        this.getMetadata(file);
      } else if (this.selectedDataSourceService === 'excel') {
        this.getMetadata(file);
      } else {
        this.sheets = file.customAttrs.map(attr => attr.name);
      }
      this.showResultPagePanel(true);
    },
    showResultPagePanel(value) {
      this.resultsPageOpen = value;
    },
    selectPage(page) {
      this.sheetName = page.properties.title;
      this.showResultPagePanel(false);
    },
    getSelectedRange() {
      this.range = `${this.startRange}:${this.startRange}`;
    },
    async selectDataSource(dataSource) {
      await this.$store.commit(SET_SELECTED_DATA_SOURCE, dataSource);
    },
    unselectDataSource() {
      this.$store.commit(UNSET_SELECTED_DATA_SOURCE);
    },
    // eslint-disable-next-line max-statements
    clearDataSourceForm() {
      this.selectedSheet = null;
      this.range = null;
      this.startRange = null;
      this.selectedDocument = null;
      this.selectedId = null;
      this.dataSourceFilename = null;
      this.selectedSourceId = null;
      this.dataRange = null;
      this.columnCondition = null;
      this.operation = null;
      this.valueCondition = null;
      this.selectedDocument = DEFAULT_SELECTED_DOCUMENT;
      this.selectedSheet = DEFAULT_SELECTED_SHEET;
    },
    getSelectedColumns(dataSource) {
      if (dataSource.conditions.length > 0) {
        return dataSource.conditions[0].valueColumns;
      }

      return dataSource.typeColumns;
    },
    validate() {
      const msg = [];
      if (this.selectedFile === null || this.selectedFile === '') {
        msg.push('Selecciona un archivo valido');
      } else if (this.sheetName === null) {
        msg.push('Selecciona un hoja o columnas valida');
      } else if (this.range === null || this.sheetName === '' && this.selectedDataSourceService !== 'custom') {
        msg.push('Selecciona un rango valido');
      }

      return msg;
    },
    async setPreviousDataSourceAttributes(dataSource) {
      if ('service' in dataSource && dataSource.service !== 'custom') {
        this.selectedDocument = { id: dataSource.sourceId, name: dataSource.name };
        this.selectedSheet = { id: -1, name: dataSource.sheetName };
        const range = dataSource.range.split(':');
        this.startRange = range[0];
      }
      this.dataSourceFilename = dataSource.dataSourceFilename;
      await this.updateDataSourceWithAllData();
    },
    async gsheetOrExcelSelected(value, service, dataSource) {
      if (service === 'gsheet' && value === 'GSheets') {
        this.getAllGSheetDocuments(this.documentSearchParam);
        // here we can't set the datasource yet because document and sheet is missing
        await this.selectDataSource({ service });
        this.dataSourceFilename = null;
      } else if (service === 'excel' && value === 'Microsoft 365') {
        await this.selectDataSource({ service });
        this.dataSourceFilename = null;
        this.getAllMicrosoftExcelFiles(this.documentSearchParam);
        this.clearDataSourceForm();
      } else { // service === 'excel' && value !== 'Microsoft 365' || service === 'gsheet' && value !== 'GSheets' --USED
        await this.selectDataSource(dataSource);
        this.setPreviousDataSourceAttributes(dataSource);
      }
    },
    async onDataSourceSelect(value, service, dataSource) {
      this.selectedDataSourceName = value;
      if (['gsheet', 'excel'].includes(service)) {
        this.gsheetOrExcelSelected(value, service, dataSource);
      } else {
        await this.selectDataSource(dataSource);
        this.setPreviousDataSourceAttributes(dataSource);
      }
    },
    async onDocumentSelect(value) {
      this.selectedDocument = value;
      if (this.$store.state.users.selectedDataSource.service === 'gsheet') {
        this.getMetadata(this.selectedDocument);
      } else if (this.$store.state.users.selectedDataSource.service === 'excel') {
        this.getExcelMetadata(this.selectedDocument);
      }
      await this.updateDataSourceWithAllData();
      if (this.selectedDataSource.id !== null) {
        const dataSource = await api.updateDataSource(
          { id: this.selectedDataSource.id, params: this.selectedDataSource },
        );
        await this.selectDataSource(dataSource);
      }
      this.showSheets();
    },
    async onSheetSelect(value) {
      this.selectedSheet = value;
      await this.updateDataSourceWithAllData();
      if (this.selectedDataSource.id !== null) {
        const dataSource = await api.updateDataSource(
          { id: this.selectedDataSource.id, params: this.selectedDataSource },
        );
        await this.selectDataSource(dataSource);
      }
    },
    showSheets: _.debounce(function () {
      document.getElementById(this.sheetBtnId).click();
    }, DEBOUNCE_DELAY_ON_SELECTION),
    sendShowDataSource() {
      this.showDataSource = !this.showDataSource;
      this.$emit('sendShowDataSourceToOptions', this.showDataSource);
    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style  scoped>

.selected {
  color: #5157ff;
  fill: #5157ff;
}

.pages-panel {
  position: absolute;
  top: 47px;
  width: 281px ;
}

.icon-middle {
  top: calc(50% - 10px);
  right: 0;
}

.search-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
}

.data-source__list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.data-source__list > li {
  border-radius: 7px;
  border: .5px solid black;
  align-self: flex-start;
  margin-bottom: 5px;
}

.data-source__list > li > label {

  padding: 7px;
  font-size: 1rem;
}

.data-source__list-control {
  padding: 5px;
  display: inline;
  border-radius: 0px 7px 7px 0px;
  border: .5px solid black;
}

.icon-button {
  background-color: rgba(0, 0, 0, 0);
  vertical-align: middle;
  left: 5px;
}

.source-box {
  width: 108px;
  height: 96px;
}

.custom-app-template-editor__input-auto {
  width: 280px;
  background: #f8f8f8;
  border: 0;
  border-radius: 4px;
  color: #3a434f;
  font-size: 16px;
  padding: 7px 16px;
  min-height: auto;
}

.data-source-container-section {
  padding-left: 14.14px;
}

.container-section-title {
  text-align: left;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-family: Inter;
  padding-top: 26.88px;
  padding-bottom: 26.88px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.inter-sub-section {
  padding-top: 21.73px;
}

.inter-select-title {
  padding-top: 10px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 61.15px;
  height: 32px;
  margin-right: 17.61px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}

.relative {
  position: relative;
}

.tightness-solver {
  height: 222px;
}

.come-up {
  z-index: 100;
  position: relative;
}

.come-down {
  z-index: 0;
  position: relative;
}

.ul-style {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 0.5px solid #7A7A7A;
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 24px;
  margin-top: 5.7px;
}

.tooltip-color {
  color: #0031FF;
  opacity: 1;
}

.tooltip .tooltip-text {
  font-size: 12px;
  visibility: hidden;
  width: 200px;
  background-color: #FFFFFF;
  color: #555859;
  text-align: justify;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  transform: translate(-145px, 4px);
  z-index: 1000;
  padding-left: 12px;
  padding-right: 3px;
  text-align: left;
  font: normal normal 300 11px/12px Inter;
  letter-spacing: 0px;
  border: 0.5px solid #707070;
  opacity: 1;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 81.9%;
  left: 77%;
  height: 7px;
  width: 7px;
  background: inherit;
  transform: rotate(45deg);
  border-top:inherit;
  border-left:inherit;
  box-shadow:inherit;
}

.tooltip-redirect {
  text-decoration: underline;
  font: normal normal 300 11px/12px Inter;
  letter-spacing: 0px;
  color: #0031FF;
}

</style>
