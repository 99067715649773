<template>
  <div
    ref="publication-thread-comment"
  >
    <div
      class="feed__comments feed__comments-expanded"
    >
      <p-comment
        v-for="(comment) in commentSorted"
        :key="comment.createdAt"
        :comment="comment"
        :publication-id="publicationId"
        :parent-id="parentId"
        :can-comment="canComment"
      />
    </div>
    <div>
      <textarea-autoheight
        :style="{width: '100%'}"
        :ref="dynamicReference"
        v-model="newComment"
        @keyup.enter.exact.prevent="createComment"
        class="comment__input outline-none thread-comment-paddings"
        placeholder="Escribe tu respuesta al comentario..."
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import AtTa from './at-textarea';
import {
  CREATE_PUBLICATION_THREAD_COMMENT,
} from '../store/action-types';
import PComment from './publication-comment.vue';
import profilePlaceholder from '../images/upload-photo.png';

const RANDOM_SEED = 100;

export default {
  components: {
    PComment,
    AtTa,
    PComment: () => import('./publication-comment.vue'),
  },
  props: {
    comments: { type: Array, required: true },
    containerId: { type: Number, default: null },
    parentId: { type: Number, default: null },
    publicationId: { type: Number, default: 0 },
    canComment: { type: Boolean, default: false },
  },
  data() {
    return {
      newComment: null,
      editedContent: null,
    };
  },
  computed: {
    members() {
      const resource = this.$store.getters.selectedResource;
      const alreadySharedUsers = _.cloneDeepWith(this.$store.getters.selectedResourceSharedUsers(resource.type));

      return alreadySharedUsers.map((user) => {
        if (user.profilePictureUrl === undefined || user.profilePictureUrl === null) {
          user.profilePictureUrl = profilePlaceholder;
        }

        return {
          name: user.displayName,
          avatar: user.profilePictureUrl,
        };
      });
    },
    commentSorted() {
      return _.orderBy(this.comments, ['createdAt'], ['desc']);
    },
    userProfilePictureUrl() {
      return this.comment.user.profilePictureUrl;
    },
    dispatchCreateParams() {
      return [CREATE_PUBLICATION_THREAD_COMMENT, { content: this.newComment, parentCommentId: this.parentId }];
    },
    dynamicReference() {
      return `interactive${parseInt(Math.random() * RANDOM_SEED, 10)}`;
    },
  },
  methods: {
    show(s) {
      console.log(s);
    },
    createComment() {
      if (!this.newComment) return;
      this.newComment = event.target.value;
      const self = this;
      this.$store.dispatch(...this.dispatchCreateParams).then(() => {
        self.newComment = null;
      });
    },
  },
};
</script>
<style scoped>
.atwho-li img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #68778d;;
}

.atwho-li span {
  padding-left: .5em;
}

.thread-comment-paddings {
  padding: 9px 12px 9px 0;
}

textarea {
  height: 35px !important;
}
</style>
