import _ from 'lodash';

export default {
  selectedUser: state => state.list[state.selectedId],
  currentUser: state => state.list && state.list[state.currentId],
  isAdmin: (_state, getters) => !!getters.currentUser && getters.currentUser.role === 'admin',
  customAppCurrentUserFields: (state, getters) => ({
    companyName: state.currentCompanyName,
    name: _.get(getters.currentUser, 'displayName'),
    ..._.pick(getters.currentUser, ['firstName', 'lastName', 'email']),
    connections: state.currentUserConnections,
  }),
};
