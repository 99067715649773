<template>
  <div
    @mouseover="expandSidebar()"
    @mouseleave="collapseSidebar()"
    :class="isClosed()"
  >
    <div>
      <slot name="html-erb-sidebar" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapsed: false,
    };
  },
  props: {
    elementId: { type: String, default: 'sidebar' },
  },
  mounted() {
    this.collapseSidebar();
  },
  methods: {
    expandSidebar() {
      if (this.isCollapsed) {
        this.isCollapsed = false;
        const sidebar = document.getElementById(this.elementId);
        sidebar.style.width = '';
      }
    },
    collapseSidebar() {
      if (!this.isCollapsed) {
        this.isCollapsed = true;
        const sidebar = document.getElementById(this.elementId);
        sidebar.setAttribute('style', `${sidebar.getAttribute('style')}; width: 56px;`);
      }
    },
    isClosed() {
      if (!this.isCollapsed) return 'left-sidebar-width';

      return 'collapse-sidebar';
    },
  },

};
</script>

<style>

.left-sidebar-width {
  width: 254px;
  transition: width .5s;
}

.collapse-sidebar {
  width: 23px;
  transition: width .5s;
}

</style>
