var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataSource === null)?_c('div',[_c('i',{staticClass:"material-icons icon2"},[_vm._v(_vm._s(_vm.appIcons[_vm.appType]))])]):(_vm.searchDone && _vm.dataSource && _vm.dataFound)?_c('div',[_c('div',{staticClass:"flex flex-col"},[(_vm.imageAlignment === 'top')?_c('div',{class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': _vm.contentAlignment === 'center',
        }},[(_vm.showImage)?_c('img',{staticClass:"rounded-circle profile-image",class:{
            'image-round' : _vm.imageShape === 'round',
            'image-square': _vm.imageShape === 'square'
          },staticStyle:{"margin-bottom":"20px"},attrs:{"src":_vm.currentUserData.image,"alt":"Profile pic"}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{class:{
          'flex flex-row': ['right', 'left'].includes(_vm.imageAlignment),
        }},[(_vm.imageAlignment === 'left')?_c('div',{staticClass:"flex"},[(_vm.showImage)?_c('img',{staticClass:"rounded-circle profile-image",class:{
              'image-round' : _vm.imageShape === 'round',
              'image-square': _vm.imageShape === 'square'
            },staticStyle:{"margin-right":"20px"},attrs:{"src":_vm.currentUserData.image,"alt":"Profile pic"}}):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col",staticStyle:{"justify-content":"center","align-items":"center"}},[(_vm.showName)?_c('div',{staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            },staticStyle:{"align-items":"center"}},[(_vm.showNameIcon)?_c('i',{staticClass:"material-icons icon name"},[_vm._v("\n              "+_vm._s(_vm.nameIcon)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"name-font"},[_vm._v("\n              "+_vm._s(_vm.currentUserData.name)+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.showProfession)?_c('div',{staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            },staticStyle:{"align-items":"center"}},[(_vm.showProfessionIcon)?_c('i',{staticClass:"material-icons icon"},[_vm._v("\n              "+_vm._s(_vm.professionIcon)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"data-font"},[_vm._v("\n              "+_vm._s(_vm.currentUserData.profession)+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.showEmail)?_c('div',{staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            },staticStyle:{"align-items":"center"}},[(_vm.showEmailIcon)?_c('i',{staticClass:"material-icons icon"},[_vm._v("\n              "+_vm._s(_vm.emailIcon)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"data-font"},[_vm._v("\n              "+_vm._s(_vm.currentUserData.email)+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.showPhone)?_c('div',{staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            },staticStyle:{"align-items":"center"}},[(_vm.showPhoneIcon)?_c('i',{staticClass:"material-icons icon"},[_vm._v("\n              "+_vm._s(_vm.phoneIcon)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"data-font"},[_vm._v("\n              "+_vm._s(_vm.currentUserData.phone)+"\n            ")])]):_vm._e(),_vm._v(" "),(_vm.showBirthday)?_c('div',{staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            },staticStyle:{"align-items":"center"}},[(_vm.showBirthdayIcon)?_c('i',{staticClass:"material-icons icon"},[_vm._v("\n              "+_vm._s(_vm.birthdayIcon)+"\n            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"data-font"},[_vm._v("\n              "+_vm._s(_vm.currentUserData.birthday)+"\n            ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.currentUserData.extraColumns),function(extraColumn,index){return _c('div',{key:("extra-column-" + index),staticClass:"flex flex-row",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': _vm.contentAlignment === 'center',
            }},[(extraColumn.show)?_c('div',{staticClass:"flex flex-row",staticStyle:{"align-items":"center"}},[(extraColumn.showIcon)?_c('i',{staticClass:"material-icons icon"},[_vm._v("\n                "+_vm._s(extraColumn.icon)+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.detectURL(extraColumn.value))?_c('div',{staticClass:"flex flex-row",staticStyle:{"align-items":"center"}},[_c('a',{staticClass:"data-font link-style",attrs:{"target":"_blank","href":extraColumn.value}},[_vm._v("\n                  "+_vm._s(extraColumn.name)+"\n                ")])]):_c('div',[_c('div',{staticClass:"data-font"},[_vm._v("\n                  "+_vm._s(extraColumn.value)+"\n                ")])])]):_vm._e()])})],2),_vm._v(" "),(_vm.imageAlignment === 'right')?_c('div',{staticClass:"flex"},[(_vm.showImage)?_c('img',{staticClass:"rounded-circle profile-image",class:{
              'image-round' : _vm.imageShape === 'round',
              'image-square': _vm.imageShape === 'square'
            },staticStyle:{"margin-left":"20px"},attrs:{"src":_vm.currentUserData.image,"alt":"Profile pic"}}):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.imageAlignment === 'bottom')?_c('div',{class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': _vm.contentAlignment === 'center',
        }},[(_vm.showImage)?_c('img',{staticClass:"rounded-circle profile-image",class:{
            'image-round' : _vm.imageShape === 'round',
            'image-square': _vm.imageShape === 'square'
          },staticStyle:{"margin-top":"20px"},attrs:{"src":_vm.currentUserData.image,"alt":"Profile pic"}}):_vm._e()]):_vm._e()])]):_c('div')])}
var staticRenderFns = []

export { render, staticRenderFns }