<template>
  <div>
    <modal
      name="dynamic-databases-import-modal"
      :width="498"
      :height="432.27"
      :scrollable="true"
      :click-to-close="false"
      :resizable="false"
    >
      <div
        class="flex flex-row border-b"
        style="height: 54.51px; position: sticky; top: 0px; z-index: 100; background-color: white;"
      >
        <div
          class="w-9/12"
          style="margin-left: 26.38px; margin-top: 15.45px;"
        >
          <i
            class="material-icons material-icons-inline add-style"
            style="color: #555859 !important;"
          >cached</i>
          <span
            class="new-database-title"
          >
            Carga dinámica
          </span>
        </div>
        <div
          class="w-3/12"
        >
          <div
            class="app-modal__close-btn text-right close-box"
            @click="close()"
          >
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <new-data-sources-selector
          :current-datasource="selectedDataSource"
          :custom-app="false"
          :show-from-system-databases="false"
          :databases-import="true"
          :show-saved="false"
        />
      </div>
      <div
        class="example-download-font cursor-pointer"
        style="margin-right: 1.9rem;"
      >
        <button
          class="example-download-font"
          type="button"
          @click="exportToExcel"
        >
          Descarga archivo tipo aquí
        </button>
      </div>
      <div
        v-if="canSave()"
        class="create-database-btn create-database-btn-font"
        style="margin-top: 25.9px; margin-bottom: 25.9px;"
        @click="save()"
      >
        <span>
          Conectar Fuente
        </span>
      </div>
      <div
        v-else
        class="create-database-btn create-database-btn-font create-disabled"
        style="margin-top: 25.9px; margin-bottom: 25.9px;"
      >
        <span>
          Conectar Fuente
        </span>
      </div>
    </modal>
  </div>
</template>
<script src="xlsx.full.min.js"></script>
<script>

import $ from 'jquery';
import _ from 'lodash';
import { mapState } from 'vuex';
import humps from 'humps';
import 'vue2-datepicker/index.css';
import * as XLSX from 'xlsx';
import showMessageMixin from '../mixins/showMessageMixin';
import FileUpload from '../components/utils/file-upload.vue';
import NewDataSourcesSelector from './new-data-sources-selector';
import { CLOSE_APP_MODAL } from '../store/action-types';
import {
  UPDATE_SELECTED_DATA,
  ADD_SELECTED_DATA,
  SET_SELECTED_CUSTOM_DATUM,
  SET_CONNECTIONS,
  SET_SELECTED_DATA,
} from '../store/mutation-types';
import api from '../api';
import storeConnectionsMixin from '../mixins/storeConnectionsMixin';
import googleApiClientMixin from '../mixins/googleApiClientMixin';

const EXAMPLE_DATES = [
  '01-03-2023',
  '13-11-2022',
  '14-12-2022',
];

const EXAMPLE_TEXTS = [
  'Texto 1',
  'Texto 2',
  'Texto 3',
];

const EXAMPLE_BOOLEANS = [
  'Si',
  'Si',
  'No',
];

const EXAMPLE_NUMBERS = [
  '1',
  '2',
  '3',
];

const EXAMPLE_FILES = [
  'https://drive.google.com/file/d/1IE19DkBuadrax9xPRfIit4d3dTvJlG2d/view?usp=sharing',
  'https://drive.google.com/file/d/1hs-5RthoE3albm63CJhtSzU8PtBgqjBK/view?usp=sharing',
  'https://drive.google.com/file/d/1vfvNssSMyTrKETrkXOHUXM93qIpsjDCF/view?usp=sharing',
];

const EXAMPLE_IMAGES = [
  'https://drive.google.com/file/d/1wEYtJtxkG6r3d4fVDxM_8nKe4vwxNuDZ/view?usp=sharing',
  'https://drive.google.com/file/d/1ONIi5XZ_uwOgakFRmShixdOo8UaeRPVe/view?usp=sharing',
  'https://drive.google.com/file/d/1snhTY4296200qYnER8zmETv_SMTCuyzy/view?usp=sharing',
];

export default {
  mixins: [googleApiClientMixin, showMessageMixin, storeConnectionsMixin],
  props: {
    listener: { type: String, default: 'staticImportDatabasesListener' },
    userDefinedTypeProp: { type: Object, required: true },
    customAttrs: { type: Array, default: () => [] },
    connections: { type: Array, default: () => [] },
  },
  components: {
    FileUpload,
    XLSX,
    NewDataSourcesSelector,
  },
  data() {
    return {
      values: {},
      imagesHash: {},
      filesHash: {},
      loadingFile: false,
      documentKey: 0,
      importFile: null,
      jsonFields: this.constructJsonFields(),
      jsonData: this.constructJsonData(),
      jsonMeta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
      fileUrl: null,
      fileSignedId: null,
    };
  },
  mounted() {
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
    this.$store.commit(SET_CONNECTIONS, this.connections);
  },
  computed: {
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
      mergeUserEmail: state => state.views.mergeUserEmail,
      saveAlert: state => state.ui.saveAlert,
      booleanSelected: state => state.ui.booleanSelected,
      selectedCustomColumn: state => state.users.selectedCustomColumn,
      selectedCustomType: state => state.users.selectedCustomType,
      selectedCustomDatum: state => state.users.selectedCustomDatum,
      selectedCustomData: state => state.users.selectedCustomData,
      selectedDataSource: state => state.users.selectedDataSource,
    }),
    documentUploadKey() {
      return this.documentKey;
    },
    requiredInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (service === 'gsheet') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table' &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
              dataSourceInAnalysis.dataRange.split('!')[1].split(':').filter(item => item).length === 2 &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone;
    },
    dataSourceParams() {
      return {
        dataSource: this.selectedDataSource,
      };
    },
  },
  methods: {
    datumParam() {
      const params = {
        userTypeDatum: {
          files: this.getSignedIds(this.filesHash),
          images: this.getSignedIds(this.imagesHash),
          dataRow: this.valuesProcessed(),
        },
      };

      return params;
    },
    valuesProcessed() {
      const columnNames = Object.keys(this.values);

      const processedData = columnNames.map(columnName => ({ columnName, value: this.values[columnName] }));

      return processedData;
    },
    close() {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, null);
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show('dynamic-databases-import-modal');
    },
    handleFileUploaded({ url, fileSignedId, name }) {
      this.importFile = name;
      this.fileSignedId = fileSignedId;
      this.fileUrl = url;
      this.fileSignedId = fileSignedId;
      this.documentKey += 1;
    },
    getSignedIds(objectList) {
      return Object.keys(objectList).reduce((imgList, imgKey) => {
        imgList.push(objectList[imgKey].fileSignedId);

        return imgList;
      }, []);
    },
    async save() {
      const dataSource = await api.createDataSource(this.selectedDataSource);
      const params = { dataSourceId: dataSource.id };
      api.createDataSourceInUserType({ id: this.userDefinedTypeProp.id, params: params })
        .then(result => {
          console.log('result: ', result);
          this.extractDataFromDataSource(result.dataSource);
          this.showMessage(
            'Proceso de importación finalizado con éxito!'
          );

          this.close();
        });
    },
    canSave() {
      if (
        this.requiredInputsFilled
      ) {
        return true;
      }

      return false;
    },
    constructJsonFields() {
      const jsonFields = { };

      this.customAttrs.forEach((column, index) => {
        jsonFields[column.columnName] = column.columnName;
      });

      return jsonFields;
    },
    constructJsonData() {
      const jsonData = [];
      const rowIndexes = [0, 1, 2];
      rowIndexes.forEach((rowIndex) => {
        const row = {};
        this.customAttrs.forEach((column, index) => {
          if (column.name === 'Fecha') {
            row[column.columnName] = EXAMPLE_DATES[rowIndex];
          } else if (column.name === 'Texto') {
            row[column.columnName] = EXAMPLE_TEXTS[rowIndex];
          } else if (column.name === 'Booleano') {
            row[column.columnName] = EXAMPLE_BOOLEANS[rowIndex];
          } else if (column.name === 'Número') {
            row[column.columnName] = EXAMPLE_NUMBERS[rowIndex];
          } else if (column.name === 'Imagen') {
            row[column.columnName] = EXAMPLE_IMAGES[rowIndex];
          } else if (column.name === 'Archivo') {
            row[column.columnName] = EXAMPLE_FILES[rowIndex];
          }
        });
        jsonData.push(row);
      });

      return jsonData;
    },
    exportToExcel() {
      const jsonData = this.jsonData;
      const dataSheet = XLSX.utils.json_to_sheet(this.jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataSheet, this.userDefinedTypeProp.name.substring(0, 31));
      XLSX.writeFile(wb, `Archivo ejemplo - Base de datos ${this.userDefinedTypeProp.name}.xlsx`);
    },
    async extractDataFromDataSource(dataSource) {
      if (!dataSource) {
        return null;
      }
      const response = await api.getDataSourceValues(dataSource.id);
      const dataSourceValues = response.values;
      const header = dataSourceValues.shift();
      const difference = this.customAttrs.map(customAttr => customAttr.columnName).filter(x => !header.includes(x));
      if (difference.length === 0) {
        this.updateSelectedDataWithDataSourceValues(header, dataSourceValues);
      } else {
        this.badHeadersInDataSource = true;
      }

      return dataSourceValues;
    },
    updateSelectedDataWithDataSourceValues(header, dataSourceValues) {
      const orderedData = this.orderDataSourceValuesInFunctionOfHeader(header, dataSourceValues);
      this.$store.commit(SET_SELECTED_DATA, orderedData);
    },
    orderDataSourceValuesInFunctionOfHeader(header, dataSourceValues) {
      const orderedRows = [];
      dataSourceValues.forEach((row, index) => {
        const orderedRow = [];
        this.customAttrs.forEach((customAttr) => {
          const columnName = customAttr.columnName;
          const indexInDataSourceRow = header.indexOf(columnName);
          const newRow = { name: columnName, value: row[indexInDataSourceRow] };
          orderedRow.push(newRow);
        });
        const newDataRow = { id: index, dataRow: orderedRow };
        orderedRows.push(newDataRow);
      });

      return orderedRows;
    },
  },
};
</script>

<style scoped>

.database-value-input-param-box {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.internal-type-font {
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
  padding-left: 12.13px;
}

.internal-type-box {
  background: #C7D8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.database-value-input-param-box-datepicker {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 53.87%;
  padding-top: 0.5%;
}

.input-param-datepicker-width {
  width: 100% !important;
}

.mx-datepicker {
  width: 100%;
}

.mx-date-row {
  text-align: center;
}

th {
  text-align: center !important;
}

.mx-calendar-content .cell.active {
  border-radius: 50%;
}

.mx-calendar-content .cell.hover {
  border-radius: 50% !important;
}

table tr {
  height: 32px;
}

.mx-calendar {
  width: 252px;
}

.cell {
  border-radius: 50%;
}

.mx-datepicker-popup {
  top: 426.255px ;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.file-uploads {
  display: inline-flex !important;
  align-items: center;
  height: 32px;
  position: none !important;
  text-align: none !important;
}

.example-download-font {
  text-align: right;
  text-decoration: underline;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

</style>
