export default {
  list: null,
  selectedId: null,
  currentId: null,
  currentCompanyName: null,
  currentCompanyId: null,
  currentUserConnections: null,
  tenantTags: null,
  usersTagged: null,
  tagEditMode: false,
  tagRemoveMode: false,
  selectedDataSource: null,
  selectedDataSourceService: null,
  tagModalEditMode: false,
  workspaceModalEditMode: false,
  selectedTag: null,
  selectedWorkspace: null,
  selectedPlatform: null,
  workspacesList: [],
  consultantsList: [],
  selectedConsultant: null,
  selectedCustomColumns: null,
  selectedCustomType: null,
  selectedCustomDatum: null,
  selectedCustomData: [],
};
