<link href="node_modules/gridstack/dist/gridstack.min.css" rel="stylesheet"/>
<script src="node_modules/gridstack/dist/gridstack-all.js"></script>
<template>
  <!-- tabs header -->
  <div
    class="flex flex-col h-full w-full"
    :class="{
      'flex-col': orientation === 'horizontal',
      'items-center': orientation === 'horizontal',
      'items-start': orientation === 'vertical',

    }"
  >
    <tabs
      :colors="colors"
      :orientation="orientation"
      :container-id="container.id"
    >
      <tab
        class="relative h-full pb-6 overflow-x-hidden"
        :key="index"
        :title="tab.title"
        :id="tab.id"
        v-for="(tab, index) in customTabs"
      >
        <!-- {{ tab }} -->
        <multi-select
          v-show="editable && container.viewVersion < 3"
          @input="appSelected(tab.id)"
          :multiple="false"
          :options="appTypes"
          v-model="selectedAppType"
          open-direction="bottom"
          class="mx-6"
          style="margin-top: 4px; margin-bottom: 4px; width: 200px;"
        />
        <div
          v-if="container.viewVersion < 3"
        >
          <div
            :id="'tabs-container'"
            class="app-grid"
            style="height: 70px;"
            :class="{
              'grid-border': !editable,
            }"
          >
            <app-container-floating
              :key="tabContainer.id"
              v-for="tabContainer in tab.appContainers"
              :container="tabContainer"
              :app-type="tabContainer.appType"
              :apps-editable="editable"
              :is-new="false"
            />
          </div>
          <div
            v-if="editable"
            id="wrapper-grid"
            :class="{
              'wrapper-2': editable && container.viewVersion === 2,
            }"
            style="margin-top: -70px; z-index: 0;"
          >
            <div
              :id="`matrix-element-${square}`"
              v-for="(square) in squares"
              :key="`matrix-element-${square}`"
              :class="{
                'item': editable,
              }"
            />
          </div>
        </div>
        <div
          v-else
        >
          <div>
            <div
              v-if="editable"
              style="position: relative; top: 0; z-index: 0; height: 0; padding-left: 9.5px; margin-top: 33px;"
              class="wrapper-3"
              id="wrapper-grid"
            >
              <div
                :id="`matrix-element-${square}`"
                v-for="(square) in squares"
                :key="`matrix-element-${square}`"
                :class="{
                  'item': editable,
                }"
              />
            </div>
            <div
              style="top: 0; z-index: 1; transform: translateY(-10px);"
            >
              <section
                :class="nestedClass(tab)"
                :id="`sub-grid-${tab.id}`"
              >
                <div
                  v-for="(container, index2) in tab.appContainers"
                  :key="index2"
                  :gs-x="container.position.x"
                  :gs-y="container.position.y"
                  :gs-w="container.position.w"
                  :gs-h="container.position.h"
                  :gs-min-w="minWidthByApp(container)"
                  :gs-min-h="minHeightByApp(container)"
                  :gs-max-w="maxWidthByApp(container)"
                  :gs-max-h="maxHeightByApp(container)"
                  :gs-no-resize="editable ? false : true"
                  :gs-no-move="editable ? false : true"
                  :id="`${container.id}`"
                  class="grid-stack-item"
                >
                  <app-floating
                    :id="`subgrid-component-${container.id}`"
                    :container="container"
                    :app-type="container.appType"
                    :apps-editable="editable"
                    class="grid-stack-item-content menu-conflict-solution"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>
<script>
import _ from 'lodash';
import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';
import MultiSelect from 'vue-multiselect';
import { APP_TYPES, APP_DIMENSIONS_BY_TYPE } from '../constants';
import Tab from './tab';
import Tabs from './tabs';
import api from '../api';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import {
  SET_CUSTOM_TABS,
  SET_SELECTED_CONTAINER,
} from '../store/mutation-types';
import {
  UPDATE_APP_CONTAINERS_IN_MAIN_GRID_AND_TABS,
  SAVE_CONTAINER,
 } from '../store/action-types';
import NewAppButton from './new-app-button';

const COLUMNS_NUMBER = 12;
const ROWS_NUMBER = 50;
const CELL_HEIGHT = 89;
const GAP_BETWEEN_CELLS = 9.5;

export default {
  components: {
    MultiSelect,
    Tab,
    Tabs,
    NewAppButton,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedAppType: null,
      appTypes: Object.keys(APP_TYPES).filter(appType =>
        (!['TABS', 'CUSTOM', 'MENU', 'WALL', 'FEED'].includes(appType))),
      selectedTabId: null,
      customApps: null,
      customAppNames: null,
      grid: undefined,
      gridOptions: {
        cellHeight: CELL_HEIGHT,
        margin: GAP_BETWEEN_CELLS,
        column: COLUMNS_NUMBER,
        acceptWidgets: true,
        disableDrag: false,
        disableResize: false,
        float: true,
        minRow: this.editable ? 10 : 0,
        resizable: {
          handles: 'all',
        },
        disableOneColumnMode: true,
        marginTop: GAP_BETWEEN_CELLS,
        marginBottom: GAP_BETWEEN_CELLS,
        marginRight: GAP_BETWEEN_CELLS,
        marginLeft: GAP_BETWEEN_CELLS,
      },
      appDimensionsByType: APP_DIMENSIONS_BY_TYPE,
      elementIdToExcludeUpdateOnChange: null,
      gridStackInstances: [],
    };
  },
  async mounted() {
    const res = await api.getCustomAppTemplates({ disabled: false }).then((response) => {
      this.customApps = response.customAppTemplates;
    });
    console.log('response', res);
    this.customAppNames = this.customApps.map(customApp => customApp.name.toUpperCase());
    this.appTypes = this.appTypes.concat(this.customAppNames.filter(appType =>
      (!['GANTT'].includes(appType))));
    const newGridOptions = this.gridOptions;
    newGridOptions['disableDrag'] = this.editable ? false : true;
    newGridOptions['disableResize'] = this.editable ? false : true;
    this.customTabs.forEach(tab => {
      const gridStackInstance = GridStack.init(newGridOptions, `.nested-${tab.id}`);
      this.gridStackInstances.push(gridStackInstance);
    });
    this.gridStackInstances.forEach((gridStackInstance, index) => {
      gridStackInstance.on('dragstop', (event, element) => {
        this.elementIdToExcludeUpdateOnChange = element.id;
        if (!window.withZoom && this.appsDraggable) {
            this.updateContainerPosition(element, index);
          }
      });
      gridStackInstance.on('resizestop', (event, element) => {
        this.elementIdToExcludeUpdateOnChange = element.id;
        if (!window.withZoom && this.appsDraggable) {
          this.updateContainerPosition(element, index);
        }
      });
      gridStackInstance.on('change', (event, items) => {
        if (!window.withZoom && this.appsDraggable) {
          items.forEach(item => {
            if (this.elementIdToExcludeUpdateOnChange !== item.el.id) {
              this.updateContainerPosition(item.el, index);
            }
          });
          this.elementIdToExcludeUpdateOnChange = null;
        }
      });
      gridStackInstance.on('dropped', (event, previousWidget, newWidget) => {
        const tabIdArray = event.target.id.split('-');
        const tabId = tabIdArray[tabIdArray.length - 1];
        this.updatePositionAndGrid(newWidget, tabId);
      });
    });
  },
  methods: {
    async appSelected(tabId) {
      this.$store.commit(SET_SELECTED_CONTAINER, this.container);
      this.selectedTabId = tabId;
      let response;
      if (this.customAppNames.includes(this.selectedAppType)) {
        const templateID = this.customApps.find(customApp => customApp.name.toUpperCase() === this.selectedAppType)
          .id;
        response = await api.createAppContainerForTab({ tabId, params: this.containerCustomParams(templateID) });
      } else {
        response = await api.createAppContainerForTab({ tabId, params: this.containerParams });
      }

      this.$store.commit(SET_CUSTOM_TABS, { containerId: this.container.id, value: response.customTabs });
    },
    containerCustomParams(templateID) {
      return {
        appType: 'CustomApp',
        commentsEnabled: false,
        showBorders: false,
        isPrivate: false,
        row: 0,
        viewId: this.container.viewId,
        app: {
          customAppTemplateId: templateID,
        },
      };
    },
    findLastContainerVertically(tab) {
      const lastContainer = tab.appContainers.reduce((a, b) =>
        (a.position.y > b.position.y ? a : b));
      return lastContainer;
    },
    maxWidthByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.maxWidth.ArticleAppVertical;
        }

        return this.appDimensionsByType.maxWidth.ArticleAppHorizontal;
      }

      return 12;
    },
    maxHeightByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.maxHeight.ArticleAppVertical;
        }

        return this.appDimensionsByType.maxHeight.ArticleAppHorizontal;
      }

      return 95;
    },
    minWidthByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.minWidth.ArticleAppVertical;
        }

        return this.appDimensionsByType.minWidth.ArticleAppHorizontal;
      }

      return this.appDimensionsByType.minWidth[container.appType];
    },
    minHeightByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.minHeight.ArticleAppVertical;
        }

        return this.appDimensionsByType.minHeight.ArticleAppHorizontal;
      }

      return this.appDimensionsByType.minHeight[container.appType];
    },
    positionContainerParams(newWidget) {
      return {
        position: {
          x: newWidget.x,
          y: newWidget.y
        }
      }
    },
    async updatePositionAndGrid(newWidget, tabId) {
      const containersInTab = await this.$store.dispatch(UPDATE_APP_CONTAINERS_IN_MAIN_GRID_AND_TABS,
        { tabId, params: this.positionContainerParams(newWidget), containerId: newWidget.el.id });
    },
    updateContainerPosition(element, tabIndex) {
      const node = element.gridstackNode;
      const containerId = node.el.id;
      const newPosition = {
        x: node.x,
        y: node.y,
        w: node.w,
        h: node.h,
      };
      const params = {
        position: newPosition,
      };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId: containerId });
    },
    nestedClass(tab) {
      let subGridClass = `grid-stack grid-stack-nested nested-${tab.id}`;
      if (!this.editable) {
        subGridClass += ' tab-margin'
      }
      return subGridClass;
    },
  },
  computed: {
    ...mapGetters({
      selectedContainer: 'selectedContainer',
    }),
    ...mapState({
      tabSelectedId: state => state.views.tabSelectedId,
      appsDraggable: state => state.views.appsDraggable,
    }),
    customTabs() {
      return this.container.app.customTabs;
    },
    colors() {
      return this.container.app.colors;
    },
    orientation() {
      return this.container.app.orientation;
    },
    containerParams() {
      return {
        appType: APP_TYPES[this.selectedAppType],
        commentsEnabled: false,
        showBorders: false,
        isPrivate: false,
        row: 0,
        viewId: this.container.viewId,
      };
    },
    squares() {
      const squares = [];
      for (let rowIndex = 1; rowIndex <= ROWS_NUMBER; rowIndex++) {
        for (let columnIndex = 1; columnIndex <= COLUMNS_NUMBER; columnIndex++) {
          const elementDenomination = `${rowIndex}-${columnIndex}`;
          squares.push(elementDenomination);
        }
      }

      return squares;
    },
  },

};
</script>

<style scoped>

.tab-margin {
  margin-top: 22.8px;
  margin-left: 11.4px;
}

.wrapper-2 {
  display: grid;
  grid-template-columns: 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px;
  grid-auto-rows: 70px;
  grid-gap: 35px;
}

.wrapper-3 {
  display: grid;
  grid-template-columns: 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px;
  grid-auto-rows: 70px;
  grid-gap: 19px;
}

.item {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.grid-border {
  margin-top: 35px;
}

.multiselect >>> .multiselect__tags {
  min-height: 35px !important;
  height: 35px !important;
  padding: 4px 35px 0 10.5px !important;
}

.menu-conflict-solution {
  overflow-x: visible !important;
  overflow-y: visible !important;
}
</style>
