<script>
import getCaretCoordinates from 'textarea-caret';
import At from './at';
import { getAtAndIndex } from './atutils/util.js';

export default {
  extends: At,
  name: 'AtTextarea',

  computed: {
    style() {
      if (this.atwho) {
        const { list, cur, x, y } = this.atwho;
        const { wrap } = this.$refs;
        const el = this.$el.querySelector('textarea');
        if (wrap) {
          const left = `${x + el.offsetLeft - el.scrollLeft}px`;
          const top = `${y + el.offsetTop - el.scrollTop}px`;

          return { left, top };
        }
      }

      return null;
    },
  },

  methods: {
    handleDelete(e) {
      const el = this.$el.querySelector('textarea');
      const text = el.value.slice(0, el.selectionEnd);
      if (text) {
        const { atItems, members, suffix, deleteMatch, itemName } = this;
        const { at, index } = getAtAndIndex(text, atItems);
        if (index > -1) {
          const chunk = text.slice(index + at.length);
          const has = this.hasElement(members, deleteMatch, itemName, chunk, suffix);
          if (has) {
            this.getValueAndHandle(el, index);
          }
        }
      }
    },
    getValueAndHandle(el, index) {
      el.value = el.value.slice(0, index) +
            el.value.slice(el.selectionEnd - 1);
      el.selectionStart = index + 1;
      el.selectionEnd = index + 1;
      this.handleInput();
    },
    hasElement(members, deleteMatch, itemName, chunk, suffix) {
      return members.some(v => {
        const name = itemName(v);

        return deleteMatch(name, chunk, suffix);
      });
    },
    /* eslint-disable */
    handleInput(keep) {
      if (this.hasComposition) return;
      const el = this.$el.querySelector('textarea');
      const text = el.value.slice(0, el.selectionEnd);
      if (text) {
        const { atItems, avoidEmail, allowSpaces } = this;
        let show = true;
        const { at, index } = getAtAndIndex(text, atItems);
        if (index < 0) show = false;
        const prev = text[index - 1];
        const chunk = text.slice(index + at.length, text.length);
        if (avoidEmail) {
          if (/^[a-z0-9]$/i.test(prev)) show = false;
        }
        if (!allowSpaces && /\s/.test(chunk)) {
          show = false;
        }
        if (/^\s/.test(chunk)) show = false;
        if (!show) {
          this.closePanel();
        } else {
          const { members, filterMatch, itemName } = this;
          if (!keep) {
            this.$emit('at', chunk);
          }
          const matched = members.filter(v => {
            const name = itemName(v);

            return filterMatch(name, chunk, at);
          });
          if (matched.length) {
            this.openPanel(matched, chunk, index, at, keep);
          } else {
            this.closePanel();
          }
        }
      }
    },
    /* eslint-enable */
    openPanel(list, chunk, offset, at) {
      const fn = () => {
        const el = this.$el.querySelector('textarea');
        const atEnd = offset + at.length; // 从@后第一位开始
        const rect = getCaretCoordinates(el, atEnd);
        this.atwho = {
          chunk,
          offset,
          list,
          atEnd,
          x: rect.left,
          y: rect.top - 4,
          cur: 0,
        };
      };
      if (this.atwho) {
        fn();
      } else {
        setTimeout(fn, 10);
      }
    },
    insertText(text, ta) {
      const start = ta.selectionStart;
      const end = ta.selectionEnd;
      ta.value = ta.value.slice(0, start) +
        text + ta.value.slice(end);
      const newEnd = start + text.length;
      ta.selectionStart = newEnd;
      ta.selectionEnd = newEnd;
    },
    /* eslint-disable */
    insertItem() {
      const { chunk, offset, list, cur, atEnd } = this.atwho;
      const { suffix, atItems, itemName } = this;
      const el = this.$el.querySelector('textarea');
      const text = el.value.slice(0, atEnd);
      const { at, index } = getAtAndIndex(text, atItems);
      const start = index + at.length; // 从@后第一位开始
      el.selectionStart = start;
      el.focus(); // textarea必须focus回来
      const t = itemName(list[cur]) + suffix;
      this.insertText(t, el);
      this.handleInput();
    },
    /* eslint-enable */
  },
};
</script>
