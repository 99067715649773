<template>
  <div class="grid-internal-databases-show p-8">
    <div
      class="back-button"
      style="cursor: pointer;"
      @click="goBack()"
    >
      <div class="flex flex-row">
        <i
          class="material-icons back-text-position"
          style="width: 30%; color: #FFFFFF; padding-left: 13px; font-size: 1.3em;"
        >arrow_back</i>
        <div
          class="back-font back-text-position"
          style="width: 70%; padding-left: 10px;"
        >
          Volver
        </div>
      </div>
    </div>
    <div
      class="flex flex-row"
      style="margin-left: 30px; margin-bottom: 28.37px;"
    >
      <div
        class="database-title-font"
        style="width: 100%;"
      >
        Historial de importación
      </div>
    </div>
    <div
      class="internal-database-table-bg flex flex-col"
      style="padding-left: 12.63px; width: 100%; height: auto;"
    >
      <div
        class="flex flex-row"
        style="margin-top: 19px;"
      >
        <div
          class="internal-database-name-and-description-box flex flex-col"
          style="padding-left: 18px; width: 100%; margin-right: 12.63px;"
        >
          <div class="internal-database-name-font">
            {{ name }}
          </div>
          <div
            class="internal-database-description-font"
            style="transform: translate(0, -10px);"
          >
            {{ description }}
          </div>
        </div>
      </div>
      <div
        class="flex flex-row internal-database-table-bg"
        style="width: 100%; margin-top: 21px; height: 38px; position: sticky; top: 0;"
      >
        <div
          style="width: 25%;"
        >
          <div
            class="header-font"
            style="text-align: center;"
          >
            Fecha - hora
          </div>
        </div>
        <div
          style="width: 25%;"
        >
          <div
            class="header-font"
            style="text-align: center;"
          >
            Archivo
          </div>
        </div>
        <div
          style="width: 25%;"
        >
          <div
            class="header-font"
            style="text-align: center;"
          >
            Estado
          </div>
        </div>
        <div
          style="width: 25%;"
        >
          <div
            class="header-font"
            style="text-align: center;"
          >
            Mensaje
          </div>
        </div>
      </div>
      <div
        class="header-border mb-4"
        style="height: 1px; margin-right: 12.63px;"
      />
      <div
        class="flex flex-col"
      >
        <div
          :key="index"
          v-for="(excelUpload, index) in excelUploads"
          class="flex databases-table-entries-font databases-table-entries-bg mb-4"
          style="height: 59.73px; align-items: center; margin-right: 12.63px;"
        >
          <div
            style="width: 25%; text-align: center; margin-left: 17.57px;"
          >
            {{ formatDate(excelUpload.createdAt) }}
          </div>
          <div
            style="width: 25%; text-align: center; margin-left: 17.57px;"
          >
            <a
              :href="excelUpload.fileUrl"
              target="_blank"
              class="cursor-pointer"
            >
              <div
                class="fileText underline-file long-text-treatment"
                v-html="excelUpload.fileName"
                :title="excelUpload.fileName"
              />
            </a>
          </div>
          <div
            style="width: 25%; text-align: center; margin-left: 17.57px;"
          >
            {{ excelUpload.imported ? 'Importado' : 'No importado' }}
          </div>
          <div
            style="width: 25%; text-align: center; margin-left: 17.57px;"
            class="long-text-treatment"
            :title="excelUpload.error"
          >
            {{ excelUploadMessage(excelUpload) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import {
  SET_APP_MODAL_TYPE,
  SET_SELECTED_DATA,
  REMOVE_SELECTED_DATA,
  SET_SELECTED_CUSTOM_DATUM,
} from '../store/mutation-types';
import api from '../api';

export const TOTAL_WIDTH_PERCENTAGE = 100;
export const DECIMAL_BASE = 10;

export default {
  props: {
    excelUploads: { type: Array, required: true },
    excelUploadsOwner: { type: Object, required: true },
    type: { type: String, required: true },
  },
  components: {
  },
  data() {
    return {
      name: _.get(this.excelUploadsOwner, 'name', ''),
      description: _.get(this.excelUploadsOwner, 'description', ''),
      orderedSelectedCustomData: [],
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | Historial de importación';
    document.body.classList.add('internal-databases-bg');
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    internalDatabaseData() {
      return {
        name: this.name,
        internalType: this.internalType,
        description: this.description,
        customAttrs: this.customAttrs,
      };
    },
  },
  methods: {
    orderedWithCustomAttr(customAttrs) {
      const newUserDataProp = [];
      this.userDataProp.forEach((userProp) => {
        const newDataRow = [];
        const dataRow = userProp.dataRow;
        customAttrs.forEach((customAttr) => {
          const orderElement = dataRow.find(object => object.name === customAttr.columnName);
          newDataRow.push(orderElement);
        });
        newUserDataProp.push(newDataRow);
      });

      return newUserDataProp;
    },
    goBack() {
      if (this.type === 'user_type') {
        window.location = '/user_types';
      } else if (this.type === 'company') {
        window.location = '/users';
      }
    },
    editDatabase() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'EditInternalDatabase');
    },
    shareDatabase() {
      console.log('coming soon');
    },
    updateDatabase(newDatabase) {
      api.updateUserDefinedType(this.userDefinedTypeProp.id, {
        userType: newDatabase,
      })
        .then((result) => {
          console.log('result', result);
          this.customAttrs = newDatabase.customAttrs;
          this.name = newDatabase.name;
          this.internalType = newDatabase.internalType;
          this.description = newDatabase.description;
          const orderedNewRows = this.orderRowsInFunctionOfHeader(result.userTypeDatum);
          this.$store.commit(SET_SELECTED_DATA, orderedNewRows);
        });
    },
    addRow() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'AddRowModal');
    },
    addUserTypeRow(dataRow) {
      console.log('dataRows', dataRow);
      console.log('selectedCustomData', this.selectedCustomData);
    },
    editRow(row) {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, row);
      this.$store.commit(SET_APP_MODAL_TYPE, 'AddRowModal');
    },
    removeRow(row) {
      api.removeUserDefinedTypeData(this.userDefinedTypeProp.id, row.id)
        .then(() => {
          const indexToRemove = this.selectedCustomData.findIndex(v => v.id === row.id);
          this.$store.commit(REMOVE_SELECTED_DATA, indexToRemove);
        });
    },
    orderRowsInFunctionOfHeader(rows) {
      const orderedRows = [];
      rows.forEach((row) => {
        const unorderedDataRow = row.dataRow;
        const orderedDataRow = [];
        this.customAttrs.forEach((customAttr) => {
          const columnName = customAttr.columnName;
          const element = unorderedDataRow.find(data => data.name === columnName);
          orderedDataRow.push(element);
        });
        const newDataRow = { id: row.id, dataRow: orderedDataRow };
        orderedRows.push(newDataRow);
      });

      return orderedRows;
    },
    massiveImport() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'StaticImportModal');
    },
    redefineDateChunk(number) {
      if (number < DECIMAL_BASE) {
        return `0${number}`;
      }

      return number;
    },
    formatDate(datetime) {
      const date = new Date(datetime);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDay();
      const hour = date.getHours();
      const minute = date.getMinutes();

      return `${this.redefineDateChunk(day)}-${this.redefineDateChunk(month)}-${this.redefineDateChunk(year)} -
        ${this.redefineDateChunk(hour)}:${this.redefineDateChunk(minute)} hrs`;
    },
    excelUploadMessage(excelUpload) {
      if (excelUpload.error) {
        return excelUpload.error;
      }

      if (excelUpload.imported) {
        return 'Filas agregadas exitosamente';
      }

      return 'Sin mensaje';
    },
  },
};

</script>

<style scoped>

.grid-internal-databases-show {
  max-width: 1133;
  margin: auto;
  margin-left: 56px;
}

.database-title-font {
  text-align: left;
  font: normal normal 300 27px/37px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.back-button {
  height: 31.63px;
  width: 107.25px;
  background: #0239F5 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
  margin-bottom: 60px;
}

.back-font {
  text-align: left;
  font: normal normal 600 14px/17px Inter;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  opacity: 1;
}

.back-text-position {
  vertical-align: middle;
  line-height: 31.63px;
}

.edit-button {
  height: 35px;
  background: #0239F5 0% 0% no-repeat padding-box;
  border-radius: 17px;
  opacity: 1;
}

.edit-text-position {
  vertical-align: middle;
  line-height: 35px;
}

.share-button {
  width: 142px;
  height: 35px;
  background: #4ECA85 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
}

.internal-database-name-and-description-box {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.internal-database-name-font {
  text-align: left;
  font: normal normal bold 24px/37px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.internal-database-description-font {
  text-align: left;
  font: normal normal 300 14px/37px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.internal-database-table-bg {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
}

.massive-import-button {
  height: 38.92px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0239F5;
  border-radius: 6px;
  opacity: 1;
}

.massive-import-font {
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  line-height: 38.92px;
}

.add-entry-button {
  height: 38.92px;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
}

.add-entry-font {
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  line-height: 38.92px;
}

.add-entry-text-position {
  vertical-align: middle;
  line-height: 38.92px;
}

.header-font {
  text-align: left;
  font: normal normal bold 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  text-transform: capitalize;
}

.header-border {
  border-bottom: 1px solid black;
  margin-right: 25.91px;
}

.add-entry-in-table {
  height: 24.31px;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
}

.add-entry-in-table-text-position {
  vertical-align: middle;
  line-height: 24.31px;
}
.databases-table-entries-font {
  text-align: left;
  font: normal normal normal 15px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
}

.databases-table-entries-bg {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
}

.underline-file {
  text-decoration: underline;
  color: #0031FF;
}

.long-text-treatment {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

</style>
