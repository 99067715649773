<template>
  <div
    ref="menuApp"
  >
    <div
      v-if="position === 'left' || position === 'right'"
    >
      <div
        class="flex items-start height-40 cursor-pointer"
        :key="item.resource_id"
        v-for="item in menuItems"
      >
        <a :href="item.resource_id">
          <i class="p-2 material-icons material-icons-menu">{{ item.icon }}</i>
          {{ item.label }}
        </a>
      </div>
    </div>
    <div
      class="flex flex-row"
      v-else
    >
      <div
        class="flex items-start height-40 cursor-pointer"
        :key="item.resource_id"
        v-for="item in menuItems"
      >
        <!-- <div class="flex"> -->
        <a
          class="flex items-center"
          :href="getResourceUrl(item)"
        >
          <i class="p-2 material-icons material-icons-menu">{{ item.icon }}</i>
          {{ item.label }}
        </a>
        <!-- </div> -->
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import _ from 'lodash';

import { APP_TYPES } from '../constants';
import {
  SAVE_CONTAINER,
} from '../store/action-types';
import positionMixin from '../mixins/positionMixin';

export default {
  mixins: [positionMixin],
  components: {

  },
  props: {
    container: { type: Object, default: null },
    containerElement: { type: String, default: '#draggable' },
    editable: { type: Boolean, default: false },
  },
  data() {
    return {
      appTypes: APP_TYPES,
      position: _.get(this.container, 'app.position', 'left'),
      menuItems: _.get(this.container, 'app.menuItems', []),
    };
  },
  computed: {
  },
  mounted() {
    const appContainerElement = this.$refs.menuApp.closest(this.containerElement);

    if (this.position === 'left') {
      this.positionElementOnLeft(appContainerElement);
      // this.positionElementOnLeft(this.$refs.menuApp);
    } else if (this.position === 'right') {
      this.positionElementOnRight(appContainerElement);
      // this.positionElementOnRight(this.$refs.menuApp);
    } else if (this.position === 'top') {
      this.positionElementOnTop(appContainerElement);
      // this.positionElementOnTop(this.$refs.menuApp);
    }
  },
  methods: {
    getResourceUrl(item) {
      if (item.type === 'folder') return `/folders/${item.resource_id}`;
      if (item.type === 'view') return `/views/${item.resource_id}`;

      return item.resource_id;
    },
    hasContainer() {
      return !_.isEmpty(this.container);
    },
    positionElementOnLeft(element) {
      element.style.left = '0px';
      element.style.top = '5px';
      element.style.bottom = '5px';
      element.style.height = 'auto';
      element.style.width = 'auto';
      element.style.minWidth = '130px';
      element.style.transform = '';
      element.style.zIndex = 20;
    },
    positionElementOnRight(element) {
      element.style.right = '0px';
      element.style.top = '5px';
      element.style.bottom = '5px';
      element.style.height = 'auto';
      element.style.minWidth = '130px';
      element.style.width = 'auto';
      element.style.zIndex = 20;
    },
    positionElementOnTop(element) {
      element.style.right = '0px';
      element.style.left = '0px';
      element.style.top = '5px';
      element.style.height = '60px';
      element.style.width = '100%';
    },
    // autoSave: _.debounce(function () {
    //   this.updateAppTextContent();
    // }, DEBOUNCE_TIME),
    updateAppTextContent() {
      const params = { app: { content: this.content } };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
    },
  },
};
</script>
<style>

</style>
