export const APP_TYPES = {
  TEXT: 'StylizedTextApp',
  SOURCE: 'SourceApp',
  IMAGE: 'ImageApp',
  FEED: 'FeedApp',
  CUSTOM: 'CustomApp',
  FILE: 'FileApp',
  MENU: 'MenuApp',
  TABS: 'AppTab',
  SINGLE_VALUE: 'SingleValueApp',
  BUTTON: 'ButtonApp',
  ARTICLE: 'ArticleApp',
  WALL: 'WallApp',
  PROFILE: 'ProfileApp',
  TABLE: 'TableApp',
  GANTT: 'GanttApp',
  DECK: 'DeckApp',
};

export const APP_CATEGORIES = {
  BASIC: 'basic',
  DATA_DISPLAYS: 'data_displays',
  CONTAINERS: 'containers',
  CHARTS: 'charts',
  OTHER: 'other',
};

export const APP_ICONS = {};
APP_ICONS[APP_TYPES.TEXT] = 'text_fields';
APP_ICONS[APP_TYPES.SOURCE] = 'code';
APP_ICONS[APP_TYPES.IMAGE] = 'photo';
APP_ICONS[APP_TYPES.FEED] = 'comment';
APP_ICONS[APP_TYPES.CUSTOM] = '';
APP_ICONS[APP_TYPES.MAP] = 'place';
APP_ICONS[APP_TYPES.CHART] = 'add_chart';
APP_ICONS[APP_TYPES.FILE] = 'note_add';
APP_ICONS[APP_TYPES.TABLE] = 'table_chart';
APP_ICONS[APP_TYPES.CARD] = 'view_quilt';
APP_ICONS[APP_TYPES.SIDEBAR] = 'view_sidebar';
APP_ICONS[APP_TYPES.TABS] = 'tab';
APP_ICONS[APP_TYPES.TABLE] = 'table_chart';
APP_ICONS[APP_TYPES.GANTT] = 'event_note';
APP_ICONS[APP_TYPES.SINGLE_VALUE] = 'call_to_action_icon';
APP_ICONS[APP_TYPES.BUTTON] = 'add_box';
APP_ICONS[APP_TYPES.ARTICLE] = 'vertical_split';
APP_ICONS[APP_TYPES.WALL] = 'feed';
APP_ICONS[APP_TYPES.PROFILE] = 'person';
APP_ICONS[APP_TYPES.DECK] = 'assignment_ind';

export const KEY_CODES = {
  SPACE: 32,
  ENTER: 13,
  TAB: 9,
};

export const BASE_COLORS = [
  ['#ffffff', '#c7d8f8', '#dde6f7', '#d2c2fd', '#efe5ff', '#abe6f2', '#dff7fc', '#ffd4a3', '#f7ece1', '#f7ece1'],
  ['#000000', '#0031ff', '#724cfe', '#28c6da', '#4eca85', '#faae56', '#f2c512', '#ff5252', '#555859'],
];

export const CONTAINER_COLORS = [
  ['#724CFE', '#D2C2FD', '#2C6EEB', '#96BBFF'],
  ['#28C6DA', '#ABE6F2', '#4ECA85', '#99F4C2'],
  ['#FAAE56', '#FFD4A3', '#F2C512', '#FFEA98'],
  ['#FF5252', '#555859', '#E5E9EC', '#FAFAFA'],
];

export const CONTAINER_COLORS_WITH_TRANSPARENT = [
  ['#724CFE', '#D2C2FD', '#2C6EEB', '#96BBFF'],
  ['#28C6DA', '#ABE6F2', '#4ECA85', '#99F4C2'],
  ['#FAAE56', '#FFD4A3', '#F2C512', '#FF5252'],
  ['#555859', '#E5E9EC', '#FAFAFA', ''],
];

export const VUE_SWATCHES_ATTRIBUTES = {
  triggerStyles: {
    width: '22px', height: '22px', border: '0.1px solid #707070', borderRadius: '3px', opacity: '1',
  },
  swatchStyles: {
    width: '23px', height: '24px', border: '0.1px solid #707070', borderRadius: '3px', opacity: '1',
    boxSizing: 'content-box', marginLeft: '3px', marginRight: '3px', paddingLeft: '0px',
    paddingRight: '0px', marginTop: '3px', marginBottom: '3px', paddingTop: '0px', paddingBottom: '0px',
  },
};

export const APP_DIMENSIONS_BY_TYPE = {
  defaultWidth: {
    'StylizedTextApp': 5,
    'SourceApp': 3,
    'ImageApp': 3,
    'FileApp': 3,
    'AppTab': 9,
    'SingleValueApp': 3,
    'ButtonApp': 3,
    'ArticleApp': 9,
    'WallApp': 7,
    'CustomApp': 7,
    'ProfileApp': 4,
  },
  defaultHeight: {
    'StylizedTextApp': 3,
    'SourceApp': 3,
    'ImageApp': 3,
    'FileApp': 2,
    'AppTab': 2,
    'SingleValueApp': 2,
    'ButtonApp': 2,
    'ArticleApp': 4,
    'WallApp': 5,
    'CustomApp': 3,
    'ProfileApp': 2,
  },
  minWidth: {
    'StylizedTextApp': 2,
    'SourceApp': 2,
    'ImageApp': 1,
    'FileApp': 2,
    'AppTab': 4,
    'SingleValueApp': 2,
    'ButtonApp': 1,
    'ArticleAppHorizontal': 9,
    'ArticleAppVertical': 4,
    'WallApp': 6,
    'CustomApp': 4,
    'ProfileApp': 2,
  },
  minHeight: {
    'StylizedTextApp': 1,
    'SourceApp': 2,
    'ImageApp': 1,
    'FileApp': 1,
    'AppTab': 3,
    'SingleValueApp': 1,
    'ButtonApp': 1,
    'ArticleApp': 4,
    'ArticleAppHorizontal': 4,
    'ArticleAppVertical': 9,
    'WallApp': 5,
    'CustomApp': 2,
    'ProfileApp': 2,
  },
  maxWidth: {
    'ArticleAppVertical': 4,
    'ArticleAppHorizontal': 12,
  },
  maxHeight: {
    'ArticleAppVertical': 12,
    'ArticleAppHorizontal': 4,
  },
};
