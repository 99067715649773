<template>
  <div
    class="notifications outline-none"
    v-show="false"
  >
    <button
      ref="dropDown"
      id="dropDown"
      class="notifications__dropdown-btn outline-none"
      @click="toggle"
    >
      <i
        id="dropDown"
        :class="{
          'material-icons material-icons--white': isDarkSolid,
          'material-icons material-icons--dark': !isDarkSolid
        }"
        :style="{color: iconColor}"
      >notifications_none</i>
      <span
        v-if="unopenedCount > 0"
        class="notifications__counter"
      >
        {{ unopenedCount }}
      </span>
    </button>
    <div
      class="notifications__list"
      v-if="open"
    >
      <a
        v-for="(notification) in notifications"
        :key="notification.id"
        class="notifications__item flex flex-row items-center hover:bg-notification"
        :href="notification.notifiablePath"
      >
        <img
          class="h-8 w-8 rounded-full object-cover ml-2 bg-workspace"
          :src="getProfilePicture(notification.notifier)"
          alt="profile_picture"
        >
        <span class="notifications__item--profile-content pl-3">
          {{ notification.notifier.firstName }} {{ notification.text }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import humps from 'humps';
import { formatRelative } from 'date-fns';
import api from '../api';
import getLocales from '../helpers/date-locales';
import profilePlaceholder from '../images/upload-photo.png';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';

const MAX_TEXT_NOTIFICATION_LENGHT = 40;

export default {
  mixins: [escapeMixinHandler, clickMixinHandler],
  props: {
    notificationsProp: { type: Array, required: true },
    iconColor: { type: String, default: '#7a7a7a' },
    isDarkSolid: { type: Boolean, default: false },
  },
  data() {
    const now = new Date();
    const locale = getLocales(document.documentElement.lang);
    const formattedNotifications = humps.camelizeKeys(this.notificationsProp).map((notification) => {
      notification.createdAt = formatRelative(new Date(notification.createdAt), now, { locale });

      return notification;
    });

    return {
      notifications: formattedNotifications,
      open: false,
    };
  },
  computed: {
    unopenedCount() {
      return this.notifications.filter((notification) => !notification.opened).length;
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    toggle() {
      this.open = !this.open;
      if (this.open && this.unopenedCount > 0) this.markNotificationsAsOpened();
    },
    markNotificationsAsOpened() {
      api.openNotifications().then(() =>
        this.notifications.forEach(notification => { notification.opened = true; }),
      );
    },
    getFullName(notifier) {
      return `${notifier.firstName} ${notifier.lastName}`;
    },
    getProfilePicture(notifier) {
      if (notifier.profilePictureUrl === undefined || notifier.profilePictureUrl === null) {
        return profilePlaceholder;
      }

      return notifier.profilePictureUrl;
    },
    getNotificationContent(text) {
      if (text.length < MAX_TEXT_NOTIFICATION_LENGHT) return text;
      const truncatedText = text.substring(0, MAX_TEXT_NOTIFICATION_LENGHT);

      return `${truncatedText}...(ver mas)`;
    },
  },
};
</script>

