<template>
  <div class="grid-index">
    <navigation-bar />
    <div class="grid-index__header">
      <div class="grid-index__title">
        {{ this.pageTitle }}
      </div>
    </div>
    <div class="grid-index__grid grid-index__grid--two-cols">
      <folder-list-item
        :folder="personalFolder"
        :is-admin="isAdmin"
      />
      <folder-list-item
        :folder="sharedFolder"
        :is-admin="isAdmin"
      />
    </div>
  </div>
</template>

<script>
import FolderListItem from './folder-list-item';
import { SET_PERSONAL_FOLDERS } from '../store/mutation-types';

export default {
  components: {
    FolderListItem,
  },
  props: {
    pageTitle: {
      type: String,
      required: true,
    },
    sharedFolder: {
      type: Object,
      default: null,
    },
    personalFolder: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    personalFolders: {
      type: Array,
      default: null,
    },
  },

  mounted() {
    this.$store.commit(SET_PERSONAL_FOLDERS, this.personalFolders);
  },
};
</script>
