import * as mutations from '../../mutation-types';

export default {
  [mutations.SET_TEMPLATES](state, templates) {
    state.list = templates;
  },
  [mutations.SET_SELECTED_TEMPLATE](state, template) {
    state.selected = template;
  },
  [mutations.UPDATE_SELECTED_TEMPLATES](state, template) {
    state.list = { ...state.list, [template.id]: template };
  },
  [mutations.REMOVE_TEMPLATE](state) {
    const listCopy = { ...state.list };
    delete listCopy[state.selected.id];
    state.list = { ...listCopy };
  },
};
