<template>
  <div class="grid-index w-full">
    <div class="flex">
      <div class="w-9/12 text-size-27">
        {{ $t("connection.listTitle") }}
      </div>
      <div class="w-3/12">
        <button
          class="border border-gray-400 rounded-full px-4 py-2 cursor-pointer mt-8 bg-buttom-primary shadow-md flex text-settings-selected"
          @click="openConnectionModal"
        >
          <i class="material-icons material-icons--blue new-btn__plus text-settings-selected">add</i>
          {{ $t("connection.new") }}
        </button>
      </div>
    </div>
    <div class="grid grid-cols-2 mt-4">
      <div
        v-for="(connection, index) in displayableConnections"
        :key="index"
        class="flex items-center justify-center h-57px border-b border-gray-400"
      >
        <span class="w-2/12 flex items-center">
          <div
            class="conn-icon rounded-md"
          >
          <!-- <img
              class="sheet-icon"
              :src="spreadsheetIcon"
              alt="sheetIcon"
            ></div> -->
          </div></span>
        <span class="w-8/12 grid-index__name grid-index__name--no-icons">
          {{ connection.serviceText }}
        </span>
        <!-- <span
          v-if="connection.alias!=null"
          class="gri-index__name grid-index__name--sub"
        >
          {{ connection.alias }}
        </span> -->
        <!-- <form
          v-else
          class="grid-index__form"
          id="aliasForm"
        >
          <input
            class="grid-index__form-input"
            type="text"
            placeholder="Ingresar alias..."
            name="alias"
            @keydown.enter.prevent="selectForEdition(connection.id); save();"
          >
        </form> -->
        <div
          class="w-2/12 grid-index__icons-container"
        >
          <button
            class="grid-index__icon grid-index__icon--first-right"
            @click="selectForDeletion(connection.id)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>
      </div>
    </div>
    <connection-modal
      v-if="connectionModalOpened"
      :service-options="serviceOptions"
    />
    <confirmation-modal v-if="confirmationModalOpened" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import humps from 'humps';
import ConnectionModal from './connection-modal';
import ConfirmationModal from './confirmation-modal';
import spreadsheetIcon from '../images/icon-spreadsheet.svg';
import spreadsheetOfficeIcon from '../images/icon_microsoftoffice.svg';
import { ADD_NORMALIZED_CONNECTION, OPEN_CONNECTION_MODAL } from '../store/mutation-types';
import { SELECT_CONNECTION_FOR_DELETION, SELECT_CONNECTION_FOR_EDITION,
  UPDATE_CONNECTION } from '../store/action-types';

export default {
  components: {
    ConnectionModal,
    ConfirmationModal,
  },
  props: {
    connectionsProp: { type: Array, required: true },
    serviceOptions: { type: Array, required: true },
  },
  data() {
    return {
      spreadsheetIcon,
      spreadsheetOfficeIcon,
    };
  },
  mounted() {
    const connectionsObject = this.connectionsProp.reduce((obj, connection) => {
      obj[connection.id] = connection;

      return obj;
    }, {});
    this.$store.commit(ADD_NORMALIZED_CONNECTION, humps.camelizeKeys(connectionsObject));
  },
  computed: mapState({
    displayableConnections: state => state.connections.list,
    connectionModalOpened: state => state.ui.connectionModalOpened,
    confirmationModalOpened: state => state.ui.confirmationModalOpened,
  }),
  methods: {
    openConnectionModal() {
      this.$store.commit(OPEN_CONNECTION_MODAL);
    },
    selectForEdition(connectionId) {
      this.$store.dispatch(SELECT_CONNECTION_FOR_EDITION, connectionId);
    },
    selectForDeletion(connectionId) {
      this.$store.dispatch(SELECT_CONNECTION_FOR_DELETION, connectionId);
    },
    connectionParams() {
      const alias = document.getElementById('aliasForm');
      const params = { alias: alias[0].value };

      return params;
    },
    save() {
      this.$store.dispatch(UPDATE_CONNECTION, this.connectionParams());
    },
  },
};

</script>
<style>
.h-57px {
  height: 57px;
}

.conn-icon {
  height: 44px;
  width: 44px;
  background-color: #52b986;
}

.sheet-icon {
  width: 19px;
  height: 27px;
}
</style>
