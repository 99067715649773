<template>
  <div
    class="flex flex-col"
  >
    <template>
      <div class="flex p-0 form__field">
        <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 mb-0 pb-0">
          Color Fondo
        </p>
        <div
          class="w-2/6 mb-0 pb-0 form__input"
        >
          <v-swatches
            id="containerBackgroundSwatch"
            class="mr-8"
            :swatches="containerColorsWithTransparent"
            :trigger-style="triggerStyles"
            :swatch-style="swatchStyles"
            :close-on-select="true"
            show-fallback
            fallback-input-type="color"
            v-model="containerBackgroundColor"
            @input="colorSelectedPath"
            row-length="4"
          />
        </div>
      </div>
    </template>
    <template>
      <div class="flex mt-4 p-0 form__field">
        <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 mb-0 pb-0">
          Color Borde
        </p>
        <div
          class="w-2/6 mb-0 pb-0 form__input"
        >
          <v-swatches
            id="borderColorSwatch"
            class="mr-8"
            :swatches="containerColorsWithTransparent"
            :trigger-style="triggerStyles"
            :swatch-style="swatchStyles"
            :close-on-select="true"
            show-fallback
            fallback-input-type="color"
            v-model="borderColor"
            @input="borderColorSelectedPath"
            row-length="4"
          />
        </div>
      </div>
    </template>
    <div
      class="flex mt-4"
      v-if="hasShowSearch"
    >
      <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
        Búsqueda
      </p>
      <toggle-button
        color="#2c6eeb"
        class="w-2/6"
        :value="showSearch"
        :sync="true"
        @input="changeShowSearch()"
      />
    </div>
    <div class="flex mt-4">
      <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
        Sombreado
      </p>
      <toggle-button
        color="#2c6eeb"
        class="w-2/6"
        :value="showShading"
        :sync="true"
        @input="changeShowShading()"
      />
    </div>
    <div
      class="flex mt-4"
      v-if="hasCommentsEnabled"
    >
      <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
        Comentarios
      </p>
      <toggle-button
        color="#2c6eeb"
        class="w-2/6"
        :value="commentsEnabled"
        :sync="true"
        @input="changeCommentsEnabled()"
      />
    </div>
    <div class="flex mt-4 mb-16">
      <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
        Privado
      </p>
      <toggle-button
        color="#2c6eeb"
        class="w-2/6"
        :value="isPrivate"
        :sync="true"
        @input="changeIsPrivate()"
      />
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import $ from 'jquery';
import VSwatches from 'vue-swatches';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';

export default {
  components: {
    VSwatches,
  },
  props: {
    container: { type: Object, required: true },
    hasShowSearch: { type: Boolean, default: false },
    hasCommentsEnabled: { type: Boolean, default: true },
  },
  data() {
    return {
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#ffffff'),
      borderColor: _.get(this.container, 'borderColor', ''),
      showShading: _.get(this.container, 'showShading', false),
      commentsEnabled: this.hasCommentsEnabled ? _.get(this.container, 'commentsEnabled', false) : null,
      showSearch: this.hasShowSearch ? _.get(this.container, 'showSearch', true) : null,
      isPrivate: _.get(this.container, 'isPrivate', false),
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      showTransparency: _.get(this.container, 'showTransparency', false),
    };
  },
  computed: {
    polymorphicAppID() {
      return `polymorphic-app${this.container.id}`;
    },
    draggableID() {
      return `draggable${this.container.id}`;
    },
    containerIdJS() {
      return `#app-container${this.container.id}`;
    },
    appContainerID() {
      return `app-container${this.container.id}`;
    },
    polymorphicAppIdJS() {
      return `#${this.polymorphicAppID}`;
    },
  },
  methods: {
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
      if (this.container.appType === 'ArticleApp') {
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper').addClass('transparent-bg');
      }
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
      if (this.container.appType === 'ArticleApp') {
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper').removeClass('transparent-bg');
      }
    },
    applyBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
      if (this.container.appType === 'ArticleApp') {
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper')
          .css('background-color', this.containerBackgroundColor);
      }
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
      if (this.container.appType === 'ArticleApp') {
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper')
          .css('background-color', 'transparent');
      }
    },
    colorSelectedPath() {
      this.colorSelected();
      this.sendToAppOptions();
    },
    borderColorSelectedPath() {
      this.borderColorSelected();
      this.sendToAppOptions();
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID);
      }
    },
    borderColorSelected() {
      if (this.borderColor === '') {
        return;
      }
      const draggableID = `#${this.draggableID}`;
      const appContainerID = `#${this.appContainerID}`;
      const borderValue = `0.5px solid ${this.borderColor}`;
      if (this.roundShape) {
        $(draggableID).css({
          borderRadius: '80px',
        });
        $(appContainerID).css({
          borderRadius: '80px',
          border: borderValue,
        });
      } else {
        $(draggableID).css({
          borderRadius: '6px',
        });
        $(appContainerID).css({
          borderRadius: '6px',
          border: borderValue,
        });
      }
    },
    sendToAppOptions() {
      const params = {
        containerBackgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        showShading: this.showShading,
        commentsEnabled: this.commentsEnabled,
        isPrivate: this.isPrivate,
      };
      if (this.hasShowSearch) {
        params.showSearch = this.showSearch;
      }
      this.$emit('sendParamsToAppOptions', { params });
    },
    changeCommentsEnabled() {
      this.commentsEnabled = !this.commentsEnabled;
      this.sendToAppOptions();
    },
    changeShowShading() {
      this.showShading = !this.showShading;
      this.sendToAppOptions();
    },
    changeIsPrivate() {
      this.isPrivate = !this.isPrivate;
      this.sendToAppOptions();
    },
    changeShowSearch() {
      this.showSearch = !this.showSearch;
      this.sendToAppOptions();
    },
  },
};
</script>
