import humps from 'humps';
import queryHelpers from './helpers/query-helpers';

const NO_CONTENT_STATUS = 204;
const MIN_SUCCESS_STATUS = 200;
const MAX_SUCCESS_STATUS = 300;

function checkStatus(response) {
  if (response.status === NO_CONTENT_STATUS) {
    return null;
  } else if (response.status >= MIN_SUCCESS_STATUS && response.status < MAX_SUCCESS_STATUS) {
    return response;
  }

  return response.json().then((err) => {
    const error = new Error(response.statusText);
    error.response = err;
    throw error;
  });
}

function parseJSON(response) {
  return response && response.json();
}

function camelize(response) {
  return response && humps.camelizeKeys(response);
}

const headers = {
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]') &&
    document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'credentials': 'same-origin',
};

export default {
  createTemplate(params) {
    const body = JSON.stringify(humps.decamelizeKeys({ viewTemplate: params }));

    return fetch('/api/v1/view_templates', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateTemplate({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ viewTemplate: params }));

    return fetch(`/api/v1/view_templates/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteTemplate(id) {
    return fetch(`/api/v1/view_templates/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  createFolder(params) {
    const body = JSON.stringify(humps.decamelizeKeys({ folder: params }));

    return fetch('/api/v1/folders', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateFolder({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ folder: params }));

    return fetch(`/api/v1/folders/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteFolder(id) {
    return fetch(`/api/v1/folders/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  createView(folderId) {
    return fetch(`/api/v1/folders/${folderId}/views`, {
      method: 'POST',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateView({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ view: params }));

    return fetch(`/api/v1/views/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteView(id) {
    return fetch(`/api/v1/views/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  getDriveFiles(searchTerm, service) {
    return fetch(`/api/v1/files?search_term=${searchTerm}&service=${service}`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  getDriveFileSheets(fileId, service) {
    return fetch(`/api/v1/files/${fileId}/sheets?service=${service}`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  getCustomAppTemplateViews(id) {
    return fetch(`/api/v1/custom_app_templates/${id}/views`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  createResourcePermissions({ resourceId, resourceType, emails, tagList }) {
    const body = JSON.stringify(humps.decamelizeKeys({
      resourcePermission: { resourceId, emails, tagList } },
    ));

    return fetch(`/api/v1/resource_permissions/bulk_create/${resourceType}`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteResourcePermissions({ resourceId, resourceType, userIds, tagList }) {
    const body = JSON.stringify(humps.decamelizeKeys({
      resourcePermission: { resourceId, userIds, tagList } },
    ));

    return fetch(`/api/v1/resource_permissions/bulk_destroy/${resourceType}`, {
      method: 'DELETE',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createAppContainer({ viewId, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appContainer: params }));

    return fetch(`/api/v1/views/${viewId}/app_containers`, {
      method: 'POST',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  createAppContainerForTab({ tabId, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appContainer: params }));

    return fetch(`/api/v1/custom_tabs/${tabId}/app_containers`, {
      method: 'POST',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  updateAppContainerFromMainGridToTab({ tabId, params, containerId }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appContainer: params }));

    return fetch(`/api/v1/custom_tabs/${tabId}/app_containers/${containerId}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  updateAppContainerFromTabToMainGrid({ tabId, params, containerId }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appContainer: params, containerId }));

    return fetch(`/api/v1/custom_tabs/${tabId}/to_main_grid`, {
      method: 'POST',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  updateAppContainer({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appContainer: params }));

    return fetch(`/api/v1/app_containers/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  duplicateAppContainer(viewId, id) {
    return fetch(`/api/v1/views/${viewId}/app_containers/${id}/duplicate`, {
      method: 'POST',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  deleteAppContainer(id) {
    return fetch(`/api/v1/app_containers/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  getThreadComments(parentId) {
    return fetch(`/api/v1/comments/${parentId}/thread_comments`, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createAppContainerComment({ containerId, content }) {
    const body = JSON.stringify({ content });

    return fetch(`/api/v1/app_containers/${containerId}/comments`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createThreadComment({ parentCommentId, content }) {
    const body = JSON.stringify({ content });

    return fetch(`/api/v1/comments/${parentCommentId}/thread_comments`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateComment({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ comment: params }));

    return fetch(`/api/v1/comments/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteComment(id) {
    return fetch(`/api/v1/comments/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  createAppContainerPublication({ containerId, content, fileUrl, file }) {
    const bodyObjects = { content };
    if (file !== null) {
      bodyObjects.fileUrl = fileUrl;
      bodyObjects.file = file;
    }
    const body = JSON.stringify(bodyObjects);

    return fetch(`/api/v1/app_containers/${containerId}/publications`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createPublicationComment({ publicationId, content }) {
    const bodyObjects = { content };
    const body = JSON.stringify(bodyObjects);

    return fetch(`/api/v1/publications/${publicationId}/comments`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updatePublication({ id, params }) {
    const body = JSON.stringify(params);

    return fetch(`/api/v1/publications/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deletePublication(id) {
    return fetch(`/api/v1/publications/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  getPublicationComments(publicationId) {
    return fetch(`/api/v1/publications/${publicationId}/comments`, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createPublicationLinkMetadata({ url }) {
    const body = JSON.stringify({ url });

    return fetch('/api/v1/publications/link_preview_metadata', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getMorePublications({ containerId, clicks }) {
    let url = '/api/v1/publications/more_publications';
    url = queryHelpers.addQueryParams(url, { id: containerId, clicks });

    return fetch(url, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteFileInFileApp(params) {
    return fetch(`/api/v1/file_apps/${params.fileAppId}/${params.signedId}`, {
      method: 'DELETE',
      headers,
    });
  },
  createUser(params) {
    const body = JSON.stringify(humps.decamelizeKeys({ user: params }));

    return fetch('/api/v1/users', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateUser({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ user: params }));

    return fetch(`/api/v1/users/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteUser(id) {
    return fetch(`/api/v1/users/${id}`, {
      method: 'DELETE',
      headers,
    })
      .then(checkStatus);
  },
  getUsers({ companyId, role = null }) {
    let url = `/api/v1/companies/${companyId}/users`;
    if (role) {
      url = queryHelpers.addQueryParams(url, { role });
    }

    return fetch(url, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateUsersTag(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/users/tags', {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createPlatformMemberUser(platformId, params) {
    const body = JSON.stringify(humps.decamelizeKeys({ user: params }));

    return fetch(`/api/v1/platforms/${platformId}/members`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  openNotifications() {
    return fetch('/api/v1/notifications/open_all', {
      method: 'POST',
      headers,
    });
  },
  duplicateView(id, folderId) {
    return fetch(`/api/v1/folders/${folderId}/views/${id}/duplicate`, {
      method: 'POST',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  implementViewTemplate(id) {
    return fetch(`/api/v1/view_templates/${id}/implement`, {
      method: 'POST',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  implementView(id) {
    return fetch(`/api/v1/views/${id}/implement_to_template_view`, {
      method: 'POST',
      headers,
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(camelize)
  },
  createCustomAppTemplate(params) {
    const body = JSON.stringify(humps.decamelizeKeys({ customAppTemplate: params }));

    return fetch('/api/v1/custom_app_templates', {
      method: 'POST',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  updateCustomAppTemplate({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ customAppTemplate: params }));

    return fetch(`/api/v1/custom_app_templates/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  deleteCustomAppTemplate(id) {
    return fetch(`/api/v1/custom_app_templates/${id}`, {
      method: 'DELETE',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  getCustomAppTemplates(filters) {
    const path = queryHelpers.addQueryParams('/api/v1/custom_app_templates', filters);

    return fetch(path, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  createConnection(params) {
    const body = JSON.stringify(humps.decamelizeKeys({ connection: params }));

    return fetch('/api/v1/connections', {
      method: 'POST',
      headers,
      body,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  updateConnection({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ connection: params }));

    return fetch(`/api/v1/connections/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteConnection(id) {
    return fetch(`/api/v1/connections/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  getGoogleAccessToken(connectionId) {
    return fetch(`/api/v1/connections/${connectionId}/google_access_token`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  createDataSource(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/data_sources', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  deleteDataSource(id) {
    return fetch(`/api/v1/data_sources/${id}`, {
      method: 'DELETE',
      headers,
    });
  },
  updateDataSource({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/data_sources/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getDataSources() {
    return fetch('/api/v1/data_sources', {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  getDataSourceValues(id) {
    return fetch(`/api/v1/data_sources/${id}/values`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  getCompany(id) {
    return fetch(`/api/v1/companies/${id}`, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateCompany(id) {
    return fetch(`/api/v1/companies/${id}`, {
      method: 'PATCH',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateWorkspace(id, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/companies/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createCompanyTags(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/companies/tag', {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateCompanyTags(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/companies/tags', {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updatePlatform(id, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/platforms/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createWorkspaceForPlatform(platformId, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/platforms/${platformId}/workspaces`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateWorkspaceForPlatform(platformId, workspaceId, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/platforms/${platformId}/workspaces/${workspaceId}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  removeWorkspacePlatform(platformId, workspaceId) {
    return fetch(`/api/v1/platforms/${platformId}/workspaces/${workspaceId}`, {
      method: 'DELETE',
      headers,
    });
  },
  createUserDefinedType(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/user_types/', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createExcelUpload(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/excel_uploads/', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createUserExcelUpload(params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch('/api/v1/excel_uploads/users', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createDataSourceInUserType({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/user_types/${id}/data_source`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateUserDefinedType(userDefnedId, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/user_types/${userDefnedId}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  removeUserDefinedType(userDefnedId) {
    return fetch(`/api/v1/user_types/${userDefnedId}`, {
      method: 'DELETE',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getUserDefinedTypeData(userDefinedId) {
    return fetch(`/api/v1/user_types/${userDefinedId}/data`, {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  createUserDefinedTypeData(userDefinedId, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/user_types/${userDefinedId}/data`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateUserDefinedTypeData(userDefinedId, datumId, params) {
    const body = JSON.stringify(humps.decamelizeKeys(params));

    return fetch(`/api/v1/user_types/${userDefinedId}/data/${datumId}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  removeUserDefinedTypeData(userDefinedId, datumId) {
    return fetch(`/api/v1/user_types/${userDefinedId}/data/${datumId}`, {
      method: 'DELETE',
      headers,
    });
  },
  signOut() {
    return fetch('/users/sign_out', {
      method: 'DELETE',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  search(queryTerm) {
    return fetch(`/api/v1/search?query=${queryTerm}`)
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
  addToFavorites({ favoritedId, favoritedType }) {
    const body = JSON.stringify(humps.decamelizeKeys({
      favorite: { id: favoritedId, favoritedType } },
    ));

    return fetch('/api/v1/favorites', {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  removeFromFavorites({ favoritedId, favoritedType }) {
    const body = JSON.stringify(humps.decamelizeKeys({
      favorite: { id: favoritedId, favoritedType } },
    ));

    return fetch(`/api/v1/favorites/${favoritedId}`, {
      method: 'DELETE',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateTab({ id, tabId, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appTab: params }));

    // return fetch(`/api/v1/custom_tabs/${id}`, {
    return fetch(`/api/v1/app_tabs/${id}/tabs/${tabId}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  updateAppTab({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ appTab: params }));

    // return fetch(`/api/v1/custom_tabs/${id}`, {
    return fetch(`/api/v1/app_tabs/${id}`, {
      method: 'PATCH',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },
  createTab({ id, params }) {
    const body = JSON.stringify(humps.decamelizeKeys({ tab: params }));

    // return fetch('/api/v1/custom_tabs', {
    return fetch(`/api/v1/app_tabs/${id}/tabs`, {
      method: 'POST',
      headers,
      body,
    })
      .then(parseJSON)
      .then(camelize);
  },

  deleteTab({ appTabId, tabId }) {
    // const body = JSON.stringify(humps.decamelizeKeys({ tab: params }));

    // return fetch('/api/v1/custom_tabs', {
    return fetch(`/api/v1/app_tabs/${appTabId}/tabs/${tabId}`, {
      method: 'DELETE',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getUndoViewCache(id) {
    return fetch(`/api/v1/views/${id}/undo_cache`, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getRedoViewCache(id) {
    return fetch(`/api/v1/views/${id}/redo_cache`, {
      method: 'GET',
      headers,
    })
      .then(parseJSON)
      .then(camelize);
  },
  getExcelFiles() {
    return fetch('/api/v1/excel_files', {
      method: 'GET',
      headers,
    })
      .then(checkStatus)
      .then(parseJSON)
      .then(camelize);
  },
};
