<template>
  <div
    class="bg-white data-source-container-section flex flex-col mt-4 relative"
    :class="{
      'border-t': !databasesImport
    }"
  >
    <Collapsible
      class="width-collapsible"
      :is-open="true"
    >
      <div
        v-if="databasesImport"
        class="text-left text-menu-label w-4/6 text-sm font-inter-type-1"
        :class="{
          'inter-sub-section': !databasesImport,
          'first-section-import': databasesImport,
        }"
        style="padding-bottom: 10px;"
      >
        Fuente de datos
      </div>
      <div
        v-show="!databasesImport"
        slot="trigger"
      >
        <div class="flex items-center">
          <p class="w-8/12 container-section-title text-base">
            FUENTE DE DATOS
          </p>
          <div
            v-if="tooltipFileUri"
            class="w-1/12 tooltip"
          >
            <i
              class="material-icons ml-1 cursor-pointer tooltip-color"
              style="font-size: 0.95em;"
            >help_outline</i>
            <span class="tooltip-text flex flex-row">
              <div>
                Revisa la plantilla de ejemplo
                <a
                  :href="tooltipFileUri"
                  target="_blank"
                  class="cursor-pointer tooltip-redirect"
                >aquí</a>
              </div>
            </span>
          </div>
          <i
            class="w-3/12 material-icons material-icons-round cursor-pointer"
          >expand_more</i>
        </div>
      </div>
      <div
        slot="closedTrigger"
        v-show="!databasesImport"
      >
        <div class="flex items-center ">
          <p class="w-4/6 container-section-title text-base">
            FUENTE DE DATOS
          </p>
          <i
            class="w-2/6 material-icons material-icons-round cursor-pointer"
          >chevron_right</i>
        </div>
      </div>
      <div
        :class="{
          'tightness-solver': this.tightnessSolver === true,
        }"
      >
        <div>
          <VueSelectCustomDataSource
            class=""
            :class="{
              'vue-select-container': !databasesImport,
              'vue-select-container-import': databasesImport,
            }"
            :data-sources="externalDataSources"
            v-model="selectedDataSourceName"
            @dataSourceListener="onDataSourceSelect"
            @tightnessListener="updateTightDiv"
            :from-system-objects="fromSystem"
            :used-before-objects="usedBefore"
            :listener="'dataSourceListener'"
            :show-from-system-databases="showFromSystemDatabases"
            :show-saved="showSaved"
          />
        </div>
        <div
          v-show="showSelectionSection"
        >
          <div
            :class="{
              'inter-sub-section': !databasesImport,
              'inter-sub-section-import': databasesImport,
            }"
            class="text-left text-menu-label w-4/6 text-sm font-inter-type-1"
          >
            Selección
          </div>
          <div
            class="inter-select-title"
            :class="{
              'vue-select-container': !databasesImport,
              'vue-select-container-import': databasesImport,
            }"
            style="margin-bottom: 10px;"
          >
            <input
              v-model="documentSearchParam"
              type="text"
              class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
              placeholder="Palabras clave documento"
              @keyup="fileLookup"
            >
          </div>
          <div class="inter-select-title">
            <VueSelectStandard
              class=""
              :class="{
                'vue-select-container': !databasesImport,
                'vue-select-container-import': databasesImport,
              }"
              :data="documentsInFunctionOfSelectedDataSourceName"
              v-model="selectedDocument"
              @documentListener="onDocumentSelect"
              :placeholder="'Documentos'"
              :listener="'documentListener'"
              :btn-id="documentBtnId"
            />
          </div>
          <div class="inter-select-title">
            <VueSelectStandard
              class=""
              :class="{
                'vue-select-container': !databasesImport,
                'vue-select-container-import': databasesImport,
              }"
              :data="sheetsInFunctionOfSelectedDocument"
              v-model="selectedSheet"
              @sheetListener="onSheetSelect"
              :placeholder="'Hojas'"
              :listener="'sheetListener'"
              :btn-id="sheetBtnId"
            />
          </div>
          <div
            class="flex items-center inter-select-title"
            :class="{
              'databases-import-cells': databasesImport
            }"
          >
            <div class="w-4/6 text-left text-menu-label text-sm font-inter-type-1">
              De la celda:
            </div>
            <input
              v-model="startRange"
              @input="updateDataSourceWithAllData"
              class="w-2/6 text-center text-sm font-inter-type-1 cells"
              :class="{
                'databases-import-cell-input': databasesImport
              }"
              placeholder="Ej: A1"
            >
          </div>
          <div
            class="flex items-center inter-select-title"
            :class="{
              'databases-import-cells': databasesImport
            }"
          >
            <div class="w-4/6 text-left text-menu-label text-sm font-inter-type-1">
              A la celda:
            </div>
            <input
              v-model="endRange"
              @input="updateDataSourceWithAllData"
              class="w-2/6 text-center text-sm font-inter-type-1 cells"
              :class="{
                'databases-import-cell-input': databasesImport
              }"
              placeholder="Ej: J9"
            >
          </div>
        </div>
        <div v-if="!databasesImport">
          <div
            class="text-left text-menu-label text-sm font-inter-type-1"
            :class="{
              'inter-sub-section': !databasesImport,
              'inter-sub-section-import': databasesImport,
            }"
          >
            Guardar fuente como:
          </div>
          <div
            class="inter-select-title"
            :class="{
              'vue-select-container': !databasesImport,
              'vue-select-container-import': databasesImport,
            }"
            style="margin-bottom: 20px;"
          >
            <input
              v-model="dataSourceFilename"
              @input="updateDataSourceWithAllData"
              class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
              placeholder="Nombre..."
            >
          </div>
        </div>
        <div v-else>
          <div style="padding-bottom: 8px;" />
        </div>
      </div>
      <div
        v-if="container && haveShowDataSource"
        class="flex mt-4 mb-4"
      >
        <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
          Mostrar fuente
        </p>
        <toggle-button
          color="#2c6eeb"
          class="w-2/6"
          :value="showDataSource"
          :sync="true"
          @input="sendShowDataSource"
        />
      </div>
    </Collapsible>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import VueSelectCustomDataSource from './vue-select-custom-data-source';
import VueSelectStandard from './vue-select-standard';
import api from '../api';
import { SET_SELECTED_DATA_SOURCE } from '../store/action-types';
import {
  UNSET_SELECTED_DATA_SOURCE,
  SET_HEADERS_READY,
  SET_COLUMNS_FROM_DOCUMENT,
  SET_FILTERS_FROM_DOCUMENT,
  SET_COLUMN_FIELD,
} from '../store/mutation-types';

import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import spreadsheetIcon from '../images/icon-spreadsheet.svg';
import spreadsheetOfficeIcon from '../images/icon_microsoftoffice.svg';

const DEBOUNCE_DELAY = 1000;
const DEBOUNCE_DELAY_ON_SELECTION = 500;
const DEFAULT_SELECTED_DOCUMENT = { name: 'Documentos', id: -2 };
const DEFAULT_SELECTED_SHEET = { name: 'Hojas', id: -2 };

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  props: {
    currentDatasource: { type: Object, default: null },
    columnsChecked: { type: Array, default: () => [] },
    customApp: { type: Boolean, default: true },
    showFromSystemDatabases: { type: Boolean, default: true },
    tooltipFileUri: { type: String, default: null },
    databasesImport: { type: Boolean, default: false },
    showSaved: { type: Boolean, default: true },
    container: { type: Object, default: null },
    haveShowDataSource: { type: Boolean, default: true },
  },
  components: {
    Collapsible,
    VueSelectCustomDataSource,
    VueSelectStandard,
  },
  // eslint-disable-next-line max-statements
  data() {
    let ranges = ['', ''];
    let selectedDataSourceService = null;
    let sheetName = null;
    let selectedDocument = DEFAULT_SELECTED_DOCUMENT;
    let selectedSheet = DEFAULT_SELECTED_SHEET;
    let allData = true;
    let sheets = [];
    const currentDatasource = this.$store.state.users.selectedDataSource;

    if (currentDatasource !== null) {
      if (currentDatasource.dataSourceType === 'table_internal') {
        ranges = ['', ''];
        selectedDataSourceService = 'custom';
        sheetName = this.getSelectedColumns(currentDatasource);
        sheets = currentDatasource.typeColumns;
        if (currentDatasource.conditions && currentDatasource.conditions.length > 1) {
          allData = false;
        }
      } else {
        ranges = _.get(currentDatasource, 'range', '').split(':');
        sheetName = _.get(currentDatasource, 'sheetName', null);
        allData = true;
      }
      selectedDocument = _.get(currentDatasource, 'name', null) === null ?
        DEFAULT_SELECTED_DOCUMENT : { name: _.get(currentDatasource, 'name'), id: 0 };
      selectedSheet = _.get(currentDatasource, 'sheetName', null) === null ?
        DEFAULT_SELECTED_SHEET : { name: _.get(currentDatasource, 'sheetName'), id: 0 };
    }

    return {
      excelFiles: [],
      dataSourceValues: null,
      dataSources: [],
      selectedFile: _.get(currentDatasource, 'name', null),
      selectedSourceId: _.get(currentDatasource, 'sourceId', null),
      selectedId: null,
      selectedDataSourceService,
      resultsOpen: false,
      resultsPageOpen: false,
      nameFile: null,
      sheets,
      sheetName,
      range: null,
      title: 'New ',
      dataSourceFilename: _.get(currentDatasource, 'dataSourceFilename', null),
      startRange: ranges[0],
      endRange: ranges[1],
      spreadsheetIcon,
      spreadsheetOfficeIcon,
      columnCondition: null,
      operation: null,
      valueCondition: null,
      dataSourceConditions: _.clone(_.get(currentDatasource, 'conditions', [])),
      allData,
      //  LO NUEVO
      selectedDataSourceName: _.get(currentDatasource, 'dataSourceFilename', ''),
      selectedDocument,
      selectedSheet,
      externalDataSources: [
        'GSheets',
        'Microsoft 365',
      ],
      tightnessSolver: false,
      documentSearchParam: '',
      selectedColumns: '', // this apply only for internal databases
      internalDatabaseColumns: [],
      isUserTypeRelated: _.get(currentDatasource, 'userTypeRelated', false),
      showDataSource: _.get(this.selectedContainer, 'showDataSource', false),
    };
  },
  mounted() {
    api.getDataSources().then(response => {
      const normalizedDataSources = response.dataSources.reduce((objAccum, ds) => {
        objAccum[ds.id] = ds;

        return objAccum;
      }, {});
      this.dataSources = normalizedDataSources;
    });
    if (this.currentDatasource === null) {
      this.newDataSource = true;
      this.isUserTypeRelated = false;
    }
    if (this.selectedContainer) {
      this.showDataSource = this.selectedContainer.showDataSource;
    }
  },
  computed: {
    ...mapState({
      selectedDataSource: state => state.users.selectedDataSource,
      columnsToShow: state => state.ui.columnsToShow,
      filtersToShow: state => state.ui.filtersToShow,
      headersReady: state => state.ui.headersReady,
    }),
    ...mapGetters({
      selectedContainer: 'selectedContainer',
    }),
    displayableConditions() {
      return this.dataSourceConditions.filter((condition, index) => index !== 0);
    },
    selectedDataSourceFilename() {
      return _.get(this.selectedDataSource, 'dataSourceFilename', null);
    },
    recentlyUsed() {
      return _.orderBy(this.dataSources, ['createdAt'], ['desc']);
    },

    isFilled() {
      // Only used when creating a new Data Source
      if (
        this.selectedDocument !== null &&
        this.dataSourceFilename !== null &&
        this.selectedSourceId !== null &&
        this.startRange !== null &&
        this.endRange !== null &&
        this.selectedSheet !== null
      ) {
        return true;
      }

      return false;
    },
    customDataSourceParams() {
      const conditions = this.dataSourceConditions;
      const dataSourceId = _.get(this.selectedDataSource, 'id', null);
      const params = {
        name: this.selectedFile,
        dataSourceType: 'table_internal',
        conditions,
        sourceId: this.selectedSourceId,
        service: this.selectedDataSourceService,
        dataSourceFilename: this.dataSourceFilename,
        id: dataSourceId,
      };

      return params;
    },
    fromSystemNames() {
      const names = [];
      // this returns all internal databases created by the current user
      this.getCustomTables(name);
      const fromSystemTables = this.sheetFiles;
      Object.keys(fromSystemTables).forEach(key => {
        names.push(fromSystemTables[key].name);
      });

      return names;
    },
    fromSystem() {
      const dataSources = [];
      for (let index = 0; index < Object.keys(this.dataSources).length; index++) {
        const dataSource = this.dataSources[Object.keys(this.dataSources)[index]];
        if (dataSource.dataSourceType === 'table_internal') {
          dataSources.push(dataSource);
        }
      }

      return dataSources;
    },
    usedBefore() {
      return this.recentlyUsed;
    },
    showSelectionSection() {
      if (this.isUserTypeRelated) {
        return false;
      }
      const value = this.selectedDataSourceName;
      if (this.externalDataSources.includes(value) || value === '' ||
          ['gsheet', 'excel'].includes(this.selectedDataSource.service)
      ) {
        return true;
      }

      return false;
    },
    showColumnsSelection() {
      // Because companyId is present only when the user selected a new internal database
      if ('companyId' in this.selectedDataSource) {
        return true;
      }

      return false;
    },
    documentsInFunctionOfSelectedDataSourceName() {
      const documents = [];
      Object.keys(this.googleSheetDocuments).forEach(key => {
        documents.push({ name: this.googleSheetDocuments[key].name, id: this.googleSheetDocuments[key].id });
      });
      Object.keys(this.excelFiles).forEach(key => {
        documents.push({ name: this.excelFiles[key].name, id: this.excelFiles[key].id });
      });

      if (documents.length === 0) {
        documents.push({ name: 'No hay resultados', id: 0 });
      }

      return documents;
    },
    sheetsInFunctionOfSelectedDocument() {
      const sheets = [];
      if (this.sheets && (typeof this.sheets[0] !== 'string')) {
        Object.keys(this.sheets).forEach(key => {
          sheets.push({ name: this.sheets[key].properties.title, id: this.sheets[key].properties.SheetId });
        });
      }
      if (sheets.length === 0) {
        sheets.push({ name: 'No hay resultados', id: 0 });
      }

      return sheets;
    },
    optionsForInternalDatabases() {
      if ('customAttrs' in this.selectedDataSource) {
        return this.$store.state.users.selectedDataSource.customAttrs.map(column => column.name);
      }

      return [];
    },
    documentBtnId() {
      return 'documents-btn-selection';
    },
    sheetBtnId() {
      return 'sheets-btn-selection';
    },
  },
  methods: {
    async columnsAndFiltersFromCustom(dataSource) {
      const response = await api.getUserDefinedTypeData(dataSource.id)
        .then((data) => {
          const columnsFilter = data[0].dataRow
            .map((column, index) => ({ id: index, name: column.name.toUpperCase() }));
          const columns = columnsFilter.map(column => column.name);

          return [columns, columnsFilter];
        });

      return response;
    },
    columnsAndFiltersFromSystemDataSource(dataSource) {
      const columnsFilter = dataSource.customAttrs.map((column, index) =>
        ({ id: index, name: column.columnName.toUpperCase() }),
      );
      const columns = columnsFilter.map(column => column.name);

      return [columns, columnsFilter];
    },
    async columnsAndFiltersFromGSheetOrExcel(dataSource) {
      const response = await api.getDataSourceValues(dataSource.id);
      const columns = response.values[0];
      const columnsFilter = columns.map((header, index) => ({ id: index, name: header.toUpperCase() }));

      return [columns, columnsFilter];
    },
    async getColumnsAndFilterFromDataSource(dataSource) {
      let result;
      if (dataSource.service === 'custom') { // this is when is a previously used DataSource
        result = await this.columnsAndFiltersFromCustom(dataSource);
      } else if ('customAttrs' in dataSource && dataSource.customAttrs !== null) { // when is a system DataSource
        result = this.columnsAndFiltersFromSystemDataSource(dataSource);
      } else { // this is when is a GSheet or excel DataSource
        if (dataSource.id === null) {
          return [[], []];
        }
        result = this.columnsAndFiltersFromGSheetOrExcel(dataSource);
      }

      return result;
    },
    getColumnField(columns) {
      const container = this.$store.getters.selectedContainer;
      const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
      const fieldValues = {};
      if (template && template.fields) {
        template.fields.forEach((field) => {
          fieldValues[field.name] = container ? container.app.fieldValues[field.name] : null;
        });
      }
      const columnField = {};
      if (this.currentDataSource === null || fieldValues.columns === undefined || fieldValues.columns.length === 0) {
        columns.forEach(column => {
          columnField[column] = { show: true };
        });
      } else {
        columns.forEach(column => {
          if (fieldValues.columns === undefined) {
            columnField[column] = { show: true };
          } else if (fieldValues.columns.includes(column)) {
            columnField[column] = { show: true };
          } else {
            columnField[column] = { show: false };
          }
        });
      }

      return columnField;
    },
    getColumnsCheckedFromColumnField(columnField) {
      const arrayColumnField = Object.entries(columnField).map((e) => ({ [e[0]]: e[1] }));
      const columnsChecked = [];
      arrayColumnField.forEach(columnWithShow => {
        const column = Object.keys(columnWithShow);
        if (columnWithShow[column].show) {
          columnsChecked.push(column[0].toUpperCase());
        }
      });

      return columnsChecked;
    },
    updateStore(columnField, columns, columnsFilter) {
      this.$store.commit(SET_COLUMN_FIELD, columnField);
      this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, columns);
      let columnsChecked = this.columnsChecked.map(columnChecked => columnChecked.toUpperCase());
      if (columnsChecked.length === 0) {
        columnsChecked = this.getColumnsCheckedFromColumnField(columnField);
      }
      const newColumnsFilter = columnsFilter.filter(columnFilter => columnFilter.name);
      this.$store.commit(SET_FILTERS_FROM_DOCUMENT, newColumnsFilter);
      this.$store.commit(SET_HEADERS_READY, true);
    },
    async getHeaderFromDataSource(dataSource) {
      if (dataSource === null || !('id' in dataSource) || !this.customApp) {
        return;
      }
      this.$store.commit(SET_HEADERS_READY, false);
      const result = await this.getColumnsAndFilterFromDataSource(dataSource);
      const columns = result[0];
      const columnsFilter = result[1];
      const columnField = this.getColumnField(columns);
      this.updateStore(columnField, columns, columnsFilter);
    },
    isFromSystem(name) {
      return this.fromSystemNames.includes(name);
    },
    newDataSourceToUpdateGSheet(objectWithInfo) {
      return {
        name: this.selectedDocument.name,
        dataSourceFilename: objectWithInfo.rememberMeAs,
        dataSourceType: objectWithInfo.dataSourceType,
        dataRange: objectWithInfo.dataRange,
        sourceId: this.selectedDocument.id || this.selectedSourceId,
        id: objectWithInfo.dataSourceInAnalysis.id || null,
        service: objectWithInfo.dataSourceInAnalysis.service,
      };
    },
    newFromSystemDataSource(dataSource, newOrUpdate) {
      let fileName;
      let sourceId;
      if (newOrUpdate === 'new') {
        fileName = null;
        if (this.isUserTypeRelated) {
          sourceId = dataSource.sourceId;
        } else {
          sourceId = dataSource.id;
        }
      } else {
        fileName = this.dataSourceFilename;
        sourceId = dataSource.sourceId;
      }

      return {
        name: dataSource.name,
        dataSourceFilename: fileName,
        dataSourceType: 'table_internal',
        dataRange: null,
        sourceId,
        id: dataSource.id || null, // not created yet
        service: 'custom',
      };
    },
    async gSheetDataSourceTreatment() {
      const newDataSource = this.newDataSourceToUpdateGSheet(
        {
          rememberMeAs: this.dataSourceFilename,
          dataSourceType: 'table',
          dataRange: `${this.selectedSheet.name}!${this.startRange}:${this.endRange}`,
          dataSourceInAnalysis: this.selectedDataSource },
      );
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
      this.$store.commit(SET_COLUMN_FIELD, [{}]);
      this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, []);
    },
    async excelDataSourceTreatment() {
      const newDataSource = this.newDataSourceToUpdateGSheet(
        {
          rememberMeAs: this.dataSourceFilename,
          dataSourceType: 'excel',
          dataRange: `${this.selectedSheet.name}!${this.startRange}:${this.endRange}`,
          dataSourceInAnalysis: this.selectedDataSource },
      );
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
      this.$store.commit(SET_COLUMN_FIELD, [{}]);
      this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, []);
    },
    async updateCustomDataSourceTreatment() {
      const newDataSource = this.newFromSystemDataSource(this.selectedDataSource, 'update');
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async newCustomDataSourceTreatment() {
      const newDataSource = this.newFromSystemDataSource(this.selectedDataSource, 'new');
      await this.$store.dispatch(SET_SELECTED_DATA_SOURCE, newDataSource);
    },
    async updateDataSourceWithAllData() {
      const dataSourceInAnalysis = this.selectedDataSource;
      if ('service' in dataSourceInAnalysis) {
        if (dataSourceInAnalysis.service === 'gsheet') {
          this.gSheetDataSourceTreatment();
        } else if (dataSourceInAnalysis.service === 'custom' ||
          dataSourceInAnalysis.service === 'custom_dynamic') {
          this.updateCustomDataSourceTreatment();
        } else if (dataSourceInAnalysis.service === 'excel') {
          this.excelDataSourceTreatment();
        }
      } else { // this means is a new from System db
        this.newCustomDataSourceTreatment();
      }
    },
    updateTightDiv(value, openOrCloseSelect) {
      if (this.externalDataSources.includes(value) || value === '' ||
          ['gsheet', 'excel'].includes(this.$store.state.users.selectedDataSource.service)
      ) {
        this.tightnessSolver = false;
      } else {
        if (openOrCloseSelect === 'open') {
          this.tightnessSolver = true;
        } else {
          this.tightnessSolver = false;
        }
      }
    },
    updateColumnsConditions(selectedColumn) {
      if (_.isArray(selectedColumn)) {
        this.sheetName = selectedColumn;
      } else {
        this.sheetName = [selectedColumn];
      }
      this.dataSourceConditions[0] = { operator: '', columnName: '', valueColumns: this.sheetName };
    },
    addNewCondition() {
      this.dataSourceConditions.push({
        columnName: this.columnCondition,
        operator: this.operation,
        value: this.valueCondition,
      });
    },
    removeSelectedCondition(condition) {
      const index = _.findIndex(this.dataSourceConditions, {
        'columnName': condition.columnName,
        'operator': condition.operator,
        'value': condition.value });

      this.dataSourceConditions.splice(index, 1);
    },
    fileLookup: _.debounce(function () {
      if (this.selectedId) {
        return;
      }
      this.findFile();
    }, DEBOUNCE_DELAY),

    findFile() {
      if (this.$store.state.users.selectedDataSource) {
        if (this.$store.state.users.selectedDataSource.service === 'gsheet') {
          this.getAllGSheetDocuments(this.documentSearchParam);
          document.getElementById(this.documentBtnId).click();
        } else if (this.$store.state.users.selectedDataSource.service === 'excel') {
          this.getAllMicrosoftExcelFiles(this.documentSearchParam);
          document.getElementById(this.documentBtnId).click();
        }
      } else {
        console.log(':( coming soon!');
      }
    },
    selectFile(file) {
      this.selectedFile = file.name;
      this.selectedSourceId = file.id;
      this.showResultPanel(false);
      this.sheets = [];
      this.sheetName = null;
      if (this.selectedDataSourceService === 'gsheet') {
        this.getMetadata(file);
      } else if (this.selectedDataSourceService === 'excel') {
        this.getExcelMetadata(file);
      } else {
        this.sheets = file.customAttrs.map(attr => attr.name);
      }
      this.showResultPagePanel(true);
    },
    showResultPagePanel(value) {
      this.resultsPageOpen = value;
    },
    selectPage(page) {
      this.sheetName = page.properties.title;
      this.showResultPagePanel(false);
    },
    getSelectedRange() {
      this.range = `${this.startRange}:${this.endRange}`;
    },
    fillNameIfNecessary() {
      if (this.selectedFile === null || this.sheetName === null) return;
      if (this.dataSourceFilename === null || this.dataSourceFilename === '') {
        this.dataSourceFilename = `${this.selectedFile}_${this.sheetName}!${this.range}`;
      }
    },
    async selectDataSource(dataSource) {
      await this.$store.commit(SET_SELECTED_DATA_SOURCE, dataSource);
    },
    unselectDataSource() {
      this.$store.commit(UNSET_SELECTED_DATA_SOURCE);
    },
    // eslint-disable-next-line max-statements
    clearDataSourceForm() {
      this.selectedSheet = null;
      this.range = null;
      this.startRange = null;
      this.endRange = null;
      this.selectedDocument = null;
      this.selectedId = null;
      this.dataSourceFilename = null;
      this.selectedSourceId = null;
      this.dataRange = null;
      this.columnCondition = null;
      this.operation = null;
      this.valueCondition = null;
      this.dataSourceConditions = [{ operator: '', columnName: '', valueColumns: this.sheetName }];
      this.selectedDocument = DEFAULT_SELECTED_DOCUMENT;
      this.selectedSheet = DEFAULT_SELECTED_SHEET;
    },
    getSelectedColumns(dataSource) {
      if (dataSource.conditions && dataSource.conditions.length > 0) {
        return dataSource.conditions[0].valueColumns;
      }

      return dataSource.typeColumns;
    },
    // eslint-disable-next-line max-statements
    startEditing(dataSource) {
      if (dataSource.dataSourceType === 'table_internal') {
        this.selectedDataSourceService = 'custom';
        this.selectedFile = dataSource.name;
        this.sheetName = this.getSelectedColumns(dataSource);
        this.sheets = dataSource.typeColumns;
        this.selectedSourceId = dataSource.sourceId;
        this.dataSourceFilename = dataSource.dataSourceFilename;
        if (dataSource.conditions.length > 1) {
          this.allData = false;
          this.dataSourceConditions = _.clone(dataSource.conditions);
        } else {
          this.allData = true;
        }
      } else {
        const ranges = dataSource.range.split(':');
        this.title = 'Editar ';
        this.getMetadata({ id: dataSource.sourceId });
        this.selectedId = dataSource.id;
        this.selectedFile = dataSource.name;
        this.dataSourceFilename = dataSource.dataSourceFilename;
        this.selectedSourceId = dataSource.sourceId;
        this.range = dataSource.range;
        this.startRange = ranges[0];
        this.endRange = ranges[1];
        this.sheetName = dataSource.sheetName;
        this.dataRange = dataSource.dataRange;
      }
    },
    validate() {
      const msg = [];
      if (this.selectedFile === null || this.selectedFile === '') {
        msg.push('Selecciona un archivo valido');
      } else if (this.sheetName === null) {
        msg.push('Selecciona un hoja o columnas valida');
      } else if (this.range === null || this.sheetName === '' && this.selectedDataSourceService !== 'custom') {
        msg.push('Selecciona un rango valido');
      }

      return msg;
    },
    async setPreviousDataSourceAttributes(dataSource) {
      if ('service' in dataSource && dataSource.service !== 'custom') {
        this.selectedDocument = { id: dataSource.sourceId, name: dataSource.name };
        this.selectedSheet = { id: -1, name: dataSource.sheetName };
        const range = dataSource.range.split(':');
        this.startRange = range[0];
        this.endRange = range[1];
      }
      this.dataSourceFilename = dataSource.dataSourceFilename;
    },
    async gsheetOrExcelSelected(value, service, dataSource) {
      if (service === 'gsheet' && value === 'GSheets') {
        this.getAllGSheetDocuments(this.documentSearchParam);
        // here we can't set the datasource yet because document and sheet is missing
        await this.selectDataSource({ service });
        this.dataSourceFilename = null;
      } else if (service === 'excel' && value === 'Microsoft 365') {
        //this.getExcelFiles(this.documentSearchParam);
        // here we can't set the datasource yet because document and sheet is missing
        await this.selectDataSource({ service });
        this.dataSourceFilename = null;
        this.getAllMicrosoftExcelFiles(this.documentSearchParam);
        this.clearDataSourceForm();
      } else { // service === 'excel' && value !== 'Microsoft 365' || service === 'gsheet' && value !== 'GSheets' --USED
        await this.selectDataSource(dataSource);
        this.setPreviousDataSourceAttributes(dataSource);
      }
    },
    async onDataSourceSelect(value, service, dataSource) {
      this.selectedDataSourceName = value;
      this.isUserTypeRelated = false;
      if (['gsheet', 'excel'].includes(service)) {
        this.gsheetOrExcelSelected(value, service, dataSource);
      } else {
        if (service === 'custom') {
          await this.selectDataSource(dataSource);
          await this.getHeaderFromDataSource(this.selectedDataSource);
          this.setPreviousDataSourceAttributes(dataSource);
          this.isUserTypeRelated = true;
        } else { // custom_dynamic
          this.isUserTypeRelated = dataSource.dataSource.userTypeRelated;
          await this.selectDataSource(dataSource.dataSource);
          await this.getHeaderFromDataSource(this.selectedDataSource);
          this.setPreviousDataSourceAttributes(dataSource.dataSource);
        }
        this.updateDataSourceWithAllData();
      }
      await this.getHeaderFromDataSource(this.selectedDataSource);
    },
    async onDocumentSelect(value) {
      this.selectedDocument = value;
      if (this.selectedDataSource.service === 'gsheet') {
        this.getMetadata(this.selectedDocument);
      } else if (this.selectedDataSource.service === 'excel') {
        this.getExcelMetadata(this.selectedDocument);
      }
      await this.updateDataSourceWithAllData();
      if (this.selectedDataSource.id !== null) {
        const dataSource = await api.updateDataSource(
          { id: this.selectedDataSource.id, params: this.selectedDataSource },
        );
        await this.selectDataSource(dataSource);
        await this.getHeaderFromDataSource(this.selectedDataSource);
      }
      this.showSheets();
    },
    async onSheetSelect(value) {
      this.selectedSheet = value;
      await this.updateDataSourceWithAllData();
      if (this.selectedDataSource.id !== null) {
        const dataSource = await api.updateDataSource(
          { id: this.selectedDataSource.id, params: this.selectedDataSource },
        );
        await this.selectDataSource(dataSource);
        await this.getHeaderFromDataSource(this.selectedDataSource);
      }
    },
    showSheets: _.debounce(function () {
      document.getElementById(this.sheetBtnId).click();
    }, DEBOUNCE_DELAY_ON_SELECTION),
    sendShowDataSource() {
      this.showDataSource = !this.showDataSource;
      this.$emit('sendShowDataSourceToOptions', this.showDataSource);
    },
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style  scoped>

.selected {
  color: #5157ff;
  fill: #5157ff;
}

.pages-panel {
  position: absolute;
  top: 47px;
  width: 281px ;
}

.icon-middle {
  top: calc(50% - 10px);
  right: 0;
}

.search-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
}

.data-source__list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.data-source__list > li {
  border-radius: 7px;
  border: .5px solid black;
  align-self: flex-start;
  margin-bottom: 5px;
}

.data-source__list > li > label {

  padding: 7px;
  font-size: 1rem;
}

.data-source__list-control {
  padding: 5px;
  display: inline;
  border-radius: 0px 7px 7px 0px;
  border: .5px solid black;
}

.icon-button {
  background-color: rgba(0, 0, 0, 0);
  vertical-align: middle;
  left: 5px;
}

.source-box {
  width: 108px;
  height: 96px;
}

.custom-app-template-editor__input-auto {
  width: 280px;
  background: #f8f8f8;
  border: 0;
  border-radius: 4px;
  color: #3a434f;
  font-size: 16px;
  padding: 7px 16px;
  min-height: auto;
}

.data-source-container-section {
  padding-left: 14.14px;
}

.container-section-title {
  text-align: left;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-family: Inter;
  padding-top: 26.88px;
  padding-bottom: 26.88px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.vue-select-container-import {
  width: 100%;
  height: 42px;
  margin-left: 18px;
  padding-right: 48px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.inter-sub-section {
  padding-top: 21.73px;
}

.inter-sub-section-import {
  padding-top: 21.73px;
  margin-left: 18px;
}

.first-section-import {
  margin-left: 18px;
}

.inter-select-title {
  padding-top: 10px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 61.15px;
  height: 32px;
  margin-right: 17.61px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}

.relative {
  position: relative;
}

.tightness-solver {
  height: 222px;
}

.come-up {
  z-index: 100;
  position: relative;
}

.come-down {
  z-index: 0;
  position: relative;
}

.ul-style {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 0.5px solid #7A7A7A;
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 24px;
  margin-top: 5.7px;
}

.tooltip-color {
  color: #0031FF;
  opacity: 1;
}

.tooltip .tooltip-text {
  font-size: 12px;
  visibility: hidden;
  width: 200px;
  background-color: #FFFFFF;
  color: #555859;
  text-align: justify;
  border-radius: 6px;
  padding: 3px 0;
  position: absolute;
  transform: translate(-145px, 4px);
  z-index: 1000;
  padding-left: 12px;
  padding-right: 3px;
  text-align: left;
  font: normal normal 300 11px/12px Inter;
  letter-spacing: 0px;
  border: 0.5px solid #707070;
  opacity: 1;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 81.9%;
  left: 77%;
  height: 7px;
  width: 7px;
  background: inherit;
  transform: rotate(45deg);
  border-top:inherit;
  border-left:inherit;
  box-shadow:inherit;
}

.tooltip-redirect {
  text-decoration: underline;
  font: normal normal 300 11px/12px Inter;
  letter-spacing: 0px;
  color: #0031FF;
}

.databases-import-cells {
  margin-left: 18px;
}

.databases-import-cell-input {
  margin-left: 64px !important;
}

</style>
