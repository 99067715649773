var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full w-full",class:{
    'flex-col': _vm.orientation === 'horizontal',
    'items-center': _vm.orientation === 'horizontal',
    'items-start': _vm.orientation === 'vertical',

  }},[_c('tabs',{attrs:{"colors":_vm.colors,"orientation":_vm.orientation,"container-id":_vm.container.id}},_vm._l((_vm.customTabs),function(tab,index){return _c('tab',{key:index,staticClass:"relative h-full pb-6 overflow-x-hidden",attrs:{"title":tab.title,"id":tab.id}},[_c('multi-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.editable && _vm.container.viewVersion < 3),expression:"editable && container.viewVersion < 3"}],staticClass:"mx-6",staticStyle:{"margin-top":"4px","margin-bottom":"4px","width":"200px"},attrs:{"multiple":false,"options":_vm.appTypes,"open-direction":"bottom"},on:{"input":function($event){return _vm.appSelected(tab.id)}},model:{value:(_vm.selectedAppType),callback:function ($$v) {_vm.selectedAppType=$$v},expression:"selectedAppType"}}),_vm._v(" "),(_vm.container.viewVersion < 3)?_c('div',[_c('div',{staticClass:"app-grid",class:{
            'grid-border': !_vm.editable,
          },staticStyle:{"height":"70px"},attrs:{"id":'tabs-container'}},_vm._l((tab.appContainers),function(tabContainer){return _c('app-container-floating',{key:tabContainer.id,attrs:{"container":tabContainer,"app-type":tabContainer.appType,"apps-editable":_vm.editable,"is-new":false}})}),1),_vm._v(" "),(_vm.editable)?_c('div',{class:{
            'wrapper-2': _vm.editable && _vm.container.viewVersion === 2,
          },staticStyle:{"margin-top":"-70px","z-index":"0"},attrs:{"id":"wrapper-grid"}},_vm._l((_vm.squares),function(square){return _c('div',{key:("matrix-element-" + square),class:{
              'item': _vm.editable,
            },attrs:{"id":("matrix-element-" + square)}})}),0):_vm._e()]):_c('div',[_c('div',[(_vm.editable)?_c('div',{staticClass:"wrapper-3",staticStyle:{"position":"relative","top":"0","z-index":"0","height":"0","padding-left":"9.5px","margin-top":"33px"},attrs:{"id":"wrapper-grid"}},_vm._l((_vm.squares),function(square){return _c('div',{key:("matrix-element-" + square),class:{
                'item': _vm.editable,
              },attrs:{"id":("matrix-element-" + square)}})}),0):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"top":"0","z-index":"1","transform":"translateY(-10px)"}},[_c('section',{class:_vm.nestedClass(tab),attrs:{"id":("sub-grid-" + (tab.id))}},_vm._l((tab.appContainers),function(container,index2){return _c('div',{key:index2,staticClass:"grid-stack-item",attrs:{"gs-x":container.position.x,"gs-y":container.position.y,"gs-w":container.position.w,"gs-h":container.position.h,"gs-min-w":_vm.minWidthByApp(container),"gs-min-h":_vm.minHeightByApp(container),"gs-max-w":_vm.maxWidthByApp(container),"gs-max-h":_vm.maxHeightByApp(container),"gs-no-resize":_vm.editable ? false : true,"gs-no-move":_vm.editable ? false : true,"id":("" + (container.id))}},[_c('app-floating',{staticClass:"grid-stack-item-content menu-conflict-solution",attrs:{"id":("subgrid-component-" + (container.id)),"container":container,"app-type":container.appType,"apps-editable":_vm.editable}})],1)}),0)])])])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }