<template>
  <div
    style="padding-bottom: 7.6vh;"
  >
    <div
      name="app"
      class="app"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">text_fields</i>
        <div class="header-text">
          Text&nbsp;&nbsp;
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div
            class="flex flex-col"
          >
            <div class="flex flex-row">
              <i
                class="material-icons format-icon w-4/6"
                style="padding-top: 3px; text-align: left"
                title="Interlineado: espacio entre párrafos."
              >format_line_spacing</i>
              <input
                type="number"
                v-model="formatLineSpacing"
                class="text-center text-sm font-inter-type-1 cells format-input w-2/6"
                placeholder="10"
                min="0"
              >
            </div>
            <div class="text-left text-menu-label text-sm font-inter-type-1 mt-4">
              Opción de visualización:
            </div>
            <div class="flex flex-row mt-2 mb-4">
              <div class="flex items-center flex-row w-1/2">
                <input
                  type="radio"
                  id="Ver más"
                  value="Ver más"
                  v-model="visualizationOption"
                >
                <label
                  class="text-menu-label text-sm font-inter-type-1 ml-2"
                  for="Ver más"
                >Ver más</label>
              </div>
              <br>
              <div class="flex items-center flex-row w-1/2">
                <input
                  type="radio"
                  id="Scroll"
                  value="Scroll"
                  v-model="visualizationOption"
                >
                <label
                  class="text-menu-label text-sm font-inter-type-1 ml-2"
                  for="Scroll"
                >Scroll</label>
              </div>
              <br>
            </div>
            <div
              v-if="visualizationOption === 'Ver más'"
              class="flex form__field"
              style="padding-bottom: 24vh;"
            >
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 mb-0 pb-0">
                Color Ver más
              </p>
              <div
                class="w-2/6 mb-0 pb-0 form__input"
              >
                <v-swatches
                  id="containerBackgroundSwatch"
                  class="mr-8"
                  :swatches="containerColorsWithTransparent"
                  :trigger-style="triggerStyles"
                  :swatch-style="swatchStyles"
                  :close-on-select="true"
                  show-fallback
                  fallback-input-type="color"
                  v-model="seeMoreColor"
                  row-length="4"
                />
              </div>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave()"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import VSwatches from 'vue-swatches';
import { mapGetters, mapState } from 'vuex';
import $ from 'jquery';
import Collapsible from 'vue-collapsible-component';
import { CONTAINER_COLORS, CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import {
  SAVE_CONTAINER,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import showMessageMixin from '../mixins/showMessageMixin';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEBOUNCE_TIME = 1000;
const DEFAULT_FORMAT_LINE_SPACING = 10;
const GAP_IN_LINE_SPACING = 5;
const DECIMAL_BASE = 10;

export default {
  mixins: [showMessageMixin],
  components: {
    Collapsible,
    AppTitleInOptions,
    ContainerSection,
    VSwatches,
  },
  data() {
    const container = this.$store.getters.selectedContainer;

    return {
      container,
      stylizedText: _.get(container, 'app.content', ''),
      customToolbar: customToolbarHelper.customToolbar,
      viewVariableSelected: null,
      title: _.get(container, 'title', ''),
      containerID: _.get(container, 'id', -1),
      containerColors: CONTAINER_COLORS,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialShowBorders: _.get(container, 'showBorders', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowTransparency: _.get(container, 'showTransparency', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#FFFFFF'),
      initialFormatLineSpacing: _.get(container, 'app.formatLineSpacing', DEFAULT_FORMAT_LINE_SPACING),
      initialVisualizationOption: _.get(container, 'app.visualizationOption', 'Ver más'),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      showBorders: _.get(container, 'showBorders', false),
      showShading: _.get(container, 'showShading', false),
      showTransparency: _.get(container, 'showTransparency', false),
      isPrivate: _.get(container, 'isPrivate', false),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#FFFFFF'),
      formatLineSpacing: _.get(container, 'app.formatLineSpacing', DEFAULT_FORMAT_LINE_SPACING),
      visualizationOption: _.get(container, 'app.visualizationOption', 'Ver más'),
      borderColor: _.get(container, 'borderColor', ''),
      initialBorderColor: _.get(container, 'borderColor', ''),
      seeMoreColor: _.get(container, 'app.seeMoreColor', '#0031FF'),
      initialSeeMoreColor: _.get(container, 'app.seeMoreColor', '#0031FF'),
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
    appWithContainer() {
      return {
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        showTransparency: this.showTransparency,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          formatLineSpacing: this.formatLineSpacing,
          visualizationOption: this.visualizationOption,
          seeMoreColor: this.seeMoreColor,
        },
        appType: 'StylizedTextApp',
      };
    },
    editorId() {
      return `text-editor${this.containerID}`;
    },
  },
  methods: {
    autoSaveContent: _.debounce(function () {
      this.saveContent();
    }, DEBOUNCE_TIME),
    saveContent() {
      if (this.selectedContainerId === null || this.selectedContainerId === undefined) return;
      const containerId = this.selectedContainerId;
      const content = this.stylizedText;
      const params = { app: { content } };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    validateTypes() {
      this.stylizedText = this.viewVariableSelected;
    },
    updateEditorText({ target }) {
      this.stylizedText = target.value;
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    requiredAppInputsFilledAndWithChanges() {
      if (
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialShowBorders !== this.showBorders ||
        this.initialShowShading !== this.showShading ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialContainerBackgroundColor !== this.containerBackgroundColor ||
        this.initialBorderColor !== this.borderColor ||
        this.initialFormatLineSpacing !== parseInt(this.formatLineSpacing, DECIMAL_BASE) ||
        this.initialVisualizationOption !== this.visualizationOption ||
        this.initialSeeMoreColor !== this.seeMoreColor
      ) {
        return true;
      }

      return false;
    },
    canSave() {
      const canSave = this.requiredAppInputsFilledAndWithChanges();
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    async saveApp() {
      if (!this.canSave()) return;

      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.addSpaceBetween();
      this.saveMessage();
      this.resetInitialValues();
    },
    resetInitialValues() {
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialShowBorders = this.showBorders;
      this.initialShowShading = this.showShading;
      this.initialShowTransparency = this.showTransparency;
      this.initialIsPrivate = this.isPrivate;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.initialSeeMoreColor = this.seeMoreColor;
      this.initialFormatLineSpacing = parseInt(this.formatLineSpacing, DECIMAL_BASE);
      this.initialVisualizationOption = this.visualizationOption;
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    addSpaceBetween() {
      const spaceBetweenLines = (this.formatLineSpacing - GAP_IN_LINE_SPACING).toString().concat('px');
      $('#'.concat(this.editorId)).children().first()
        .children()
        .css('margin-bottom', spaceBetweenLines);
    },
    changeSize() {
      $('.vue-swatches__container').attr(
        'style',
        `width: 126px; height: 164px; inset: 30px 0px 0px -100px; background-color: rgb(255, 255, 255);
        border: 0.5px solid #707070; border-radius: 2px; opacity: 1; box-shadow: none;`,
      );
      $('.vue-swatches__wrapper').attr('style', 'width: 126px; margin-bottom: 5px; margin-top: 6px;');
      $('.vue-swatches__fallback__wrapper').attr(
        'style', 'width: 126px; padding-top: 20px !important; display: inline;',
      );
      $('.vue-swatches__fallback__button').attr(
        'style',
        `width: 29px; height: 20px; font: normal normal bold 9px/37px Inter; text-align: center; letter-spacing: 0px;
        opacity: 1; background-color: #2C6EEB; border-radius: 2px; align-items: center; justify-content: center;
        margin-right: 0px; color: white; width: 20%; display: inline-flex; align-items: center;
        justify-content: center; position: absolute; transform: translate(26px, 0px);`,
      );
      $('.vue-swatches__fallback__button').removeClass('vue-swatches__fallback__button');
      $('.vue-swatches__fallback__input').attr(
        'style',
        `height: 13px; width: 80px; position: absolute; transform: translate(-52px, -13px);
        padding-top: 1.5px; padding-bottom: 1.5px; background-color: white; 
        border: 0.5px solid #707070; border-radius: 2px;`,
      );
      $('.vue-swatches__fallback__input').removeClass('vue-swatches__fallback__input');
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
  margin-bottom: 12px;
}

.icon {
  color: #28C6DA;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.container-section {
  padding-left: 14.14px;
  height: 370px;
}

.container-section-title {
  text-align: left;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-family: Inter;
  padding-top: 26.88px;
  padding-bottom: 26.88px;
  white-space: nowrap;
}

.arrows-padding {
  padding-top: 26.88px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 61.15px;
  height: 32px;
  margin-right: 17.61px;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 10px;
  padding-bottom: 1.5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

/* TITLE SECTION STYLE start */
.inter-select-title {
  padding-top: 10px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}
/* TITLE SECTION STYLE end */

.last-section-title {
  text-align: left;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-family: Inter;
  padding-top: 26.88px;
}

.container-colors-displayed {
  width: 126px !important;
  height: 164px !important;
  inset: 2px 2px 2px 2px !important;
}

.new-background {
  background-color: var(--container-background-color) !important;
}

</style>
