<template>
  <div
    class="h-20 p-8 w-full flex flex-row text-sm text-indigo-600 border-gray-300  border-b items-center"
    :class="{'bg-pending': user.notAccepted}"
  >
    <div
      class="flex flex-row w-full items-center justify-center text-center height-96px"
    >
      <div class="w-4/12 flex flex-row text-gray-600">
        <img
          class="rounded-full h-10 w-10 bg-blue-700 object-cover"
          v-if="user.profilePictureUrl"
          :src="user.profilePictureUrl"
        >
        <div
          v-else
          class="rounded-full h-10 w-10 bg-blue-700 object-cover uppercase text-white font-bold text-xl pt-1"
        >
          {{ user.firstName[0] }}{{ user.lastName[0] }}
        </div>

        <div class="pl-4 text-left text-menu-label">
          <div>
            {{ user.displayName }}
          </div>
          <div class="text-xs text-setting-sublabel">
            {{ user.email }}
          </div>
        </div>
      </div>
      <div class="w-7/12">
        <ul class="flex items-center overflow-x-auto h-20">
          <li
            class="mr-2"
            :key="tag"
            v-for="tag in user.tagList"
          >
            <p class="text-icon-secondary  text-size-13 rounded-full py-2 px-4 bg-buttom-primary shadow-lg">
              {{ tag }}
            </p>
          </li>
        </ul>
      </div>
      <div class="w-1/12 relative flex justify-end">
        <i
          v-if="user.notAccepted"
          class="material-icons material-icons--yellow cursor-pointer mr-2"
        >history</i>
        <i
          v-if="user.accepted"
          class="material-icons material-icons--green cursor-pointer mr-2"
        >task_alt</i>

        <i
          id="dropDown"
          ref="dropDown"
          @click="openDropdown"
          class="material-icons material-icons cursor-pointer"
        >more_horiz</i>
        <div
          id="dropDown"
          v-if="dropdownOpen && isOwner"
          class="shadow absolute bg-white border border-gray-400 rounded-lg z-10"
        >
          <div
            @click="selectForView()"
            class="flex w-32 items-center align-center cursor-pointer"
          >
            <div class="text-center p-2">
              <i class="material-icons material-icons-inline">visibility</i>
            </div>
            <div class="text-center p-2">
              Ver
            </div>
          </div>
          <div
            @click="selectForEdition(user.id)"
            class="flex w-32 items-center align-center cursor-pointer"
          >
            <div class="text-center p-2">
              <i class="material-icons material-icons-inline">edit</i>
            </div>
            <div class="text-center p-2">
              Editar
            </div>
          </div>
          <div
            @click="selectForDeletion(user.id)"
            class="flex w-32 items-center align-center cursor-pointer"
          >
            <div class="text-center p-2">
              <i class="material-icons-outlined material-icons material-icons-inline">delete</i>
            </div>
            <div class="text-center p-2">
              Eliminar
            </div>
          </div>
        </div>
        <div
          id="drowDown"
          v-else-if="dropdownOpen && !isOwner"
          class="shadow absolute bg-white border border-gray-400 rounded-lg z-10"
        >
          <div
            @click="selectForView()"
            class="flex w-32 items-center align-center cursor-pointer"
          >
            <div class="text-center p-2">
              <i class="material-icons material-icons-inline">visibility</i>
            </div>
            <div class="text-center p-2">
              Ver
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { OPEN_USER_MODAL, OPEN_USER_TAGS_MODAL, SET_USER_MODAL_EDIT_MODE } from '../store/mutation-types';
import { SELECT_USER_FOR_DELETION, SELECT_USER_FOR_EDITION } from '../store/action-types';

import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';
import profilePlaceholder from '../images/upload-photo.png';

export default {
  mixins: [escapeMixinHandler, clickMixinHandler],
  props: {
    user: { type: Object, required: true },
    isOwner: { type: Boolean, default: false },
    defaultProfile: { type: Object, required: true },
  },
  data() {
    return {
      dropdownOpen: false,
      profilePlaceholder,
    };
  },
  mounted() {
    console.log('isOwner', this.isOwner);
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | Equipo';
  },
  computed: {
    ...mapState({
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      userModalOpened: state => state.ui.userModalOpened,
      userTagsModalOpened: state => state.ui.userTagsModalOpened,
    }),
    imgPlaceholder() {
      return this.user.profilePictureUrl || this.profilePlaceholder;
    },
    doestNotHaveDefaultProfileConfigured() {
      return this.defaultProfile.url === 'account';
    },
    accountParams() {
      return `?email=${this.user.email}&company_id=${this.user.company.id}`;
    },
    defaultProfileParams() {
      return `?merge_user_email=${this.user.email}`;
    },
  },

  methods: {
    close() {
      this.dropdownOpen = false;
    },
    openDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    openUserModal() {
      this.$store.commit(OPEN_USER_MODAL);
    },
    openUserTagsModal() {
      this.$store.commit(OPEN_USER_TAGS_MODAL);
    },
    selectForEdition(userId) {
      this.$store.commit(SET_USER_MODAL_EDIT_MODE, true);
      this.$store.dispatch(SELECT_USER_FOR_EDITION, userId);
    },
    selectForDeletion(userId) {
      this.$store.dispatch(SELECT_USER_FOR_DELETION, userId);
    },
    selectForView() {
      if (this.doestNotHaveDefaultProfileConfigured) {
        window.location.href = `${this.defaultProfile.url}/${this.accountParams}`;
      } else {
        window.location.href = `${this.defaultProfile.url}${this.defaultProfileParams}`;
      }
      // call defaultProfile for this platform workspace
    },
  },
};
</script>
<style>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

.text-size-13 {
  font-size: 13px;
}

.height-96px {
  height: 96px;
}

</style>
