<template>
  <div
    v-if="!showContainer"
    :ref="appType"
    class="menu-app-container flex flex-col justify-center align-items-center cursor-grab"
    :class="buttomBorder"
    :title="appTitle"
  >
    <i
      class="material-icons  material-icons-apps cursor-grab"
      :class="iconColor"
    >{{ icon }}</i>
    <p class="text-app-title">
      {{ appTitleToShow }}
    </p>
  </div>

  <div
    v-else
    :ref="appType"
    class="w-16"
    title="Add more apps from the Bigstore"
    @click="openBigstoreModal"
  >
    <i class="material-icons cursor-grab">{{ icon }}</i>
  </div>
</template>
<script>

import { mapState } from 'vuex';
import 'quill/dist/quill.core.css';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';
import objectIdMixin from '../mixins/objectIdMixin';
import positionMixin from '../mixins/positionMixin';
import { APP_TYPES, APP_CATEGORIES } from '../constants';
import {
  OPEN_BIGSTORE_MODAL,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
// import AppContainerFloating from './app-container-floating';
import api from '../api';

export default {
  mixins: [dragAndResizeMixin, objectIdMixin, positionMixin],
  components: {
  //   VueEditor,
    // AppContainerFloating,
  },
  props: {
    appType: { type: String, default: 'app_table' },
    icon: { type: String, default: 'table_chart' },
    showContainer: { type: Boolean, default: false },
    name: { type: String, default: null },
    category: { type: String, default: null },
  },
  data() {
    return {
      title: '',
      content: null,
      appTypes: APP_TYPES,
      appCategories: APP_CATEGORIES,
      customToolbar: customToolbarHelper.customToolbar,
      container: null,
    };
  },
  computed: {
    ...mapState({
      selectedView: state => state.views.selected,
    }),
    appTitle() {
      if (!this.appType.startsWith('Custom')) {
        return this.appType.replace(/([A-Z])/g, ' $1').replace('App', '');
      }

      return this.name;
    },
    appTitleToShow() {
      if (!this.appType.startsWith('Custom')) {
        if (this.appType === 'WallApp') {
          return 'Feed';
        }

        return this.appType.replace(/([A-Z])/g, ' $1').replace('App', '');
      }

      return this.name;
    },
    iconColor() {
      if (this.category) {
        switch (this.category) {
        case this.appCategories.BASIC:
          return 'material-icons-apps--vivel';
        case this.appCategories.DATA_DISPLAYS:
          return 'material-icons-apps--yellow';
        case this.appCategories.OTHER:
          return 'material-icons-apps--green';
        default:
          return 'material-icons-apps--black';
        }
      }

      return null;
    },
    buttomBorder() {
      if (this.iconColor) {
        if (this.iconColor.includes('vivel')) return 'app-border--vivel';
        if (this.iconColor.includes('yellow')) return 'app-border--yellow';
        if (this.iconColor.includes('green')) return 'app-border--green';

        return 'app-border';
      }

      return null;
    },

  },
  mounted() {
    this.makeManuallyDraggable(this.$refs[this.appType]);
  },
  methods: {
    openBigstoreModal() {
      this.$store.dispatch(OPEN_BIGSTORE_MODAL);
    },
    resizeContainer() {
      // const containerEditor = document.querySelector('.quillWrapper');
      const containerEditor = this.$refs[this.appType].querySelector('.quillWrapper');
      containerEditor.closest('.droppeable-app').style.height = `${containerEditor.clientHeight}px`;
    },
    handleDragEvent(event) {
      this.$emit('start-dragging', this, event);
    },
    handleDragEventManually(event) {
      this.$emit('start-dragging-manually', this, event);
    },
    interactivize() {
      this.makeDraggableAndResizable(this.$refs[this.appType].$el);
    },
    floatinize() {
      this.makeFloating(this.$refs[this.appType]);
    },
    handleDropEvent() {
      if (this.content === null || this.content === '') return;
      console.log(`calling API for container ${this.selectedView.uuid}`);
      const position = this.getPosition(this.$refs[this.appType]);
      const params = {
        title: 'Sin Titulo',
        appType: this.appType,
        position,
        row: 0,
        app: {
          content: this.content,
        },
      };
      console.log(params);
      api.createAppContainer({ viewId: this.selectedView.uuid, params });
    },
    updateContainer() {
      console.log('calling API');
    },
  },

};
</script>
<style>

.floating-container {
  width: 547px;
  height: 328px;
}

.floating-container-text {
  width: 547px;
  min-height: 179px;
}

.unset-input {
  background: unset;
  border: unset;
}

.cursor-grab {
  cursor: grab;
}

.menu-app-container {
  width: 73px;
  height: 73px;
  margin-right: 5px;
  background-color: #fff;
}

.text-app-title {
  font-size: 10px;
  color: #555859;
  opacity: 1;
  letter-spacing: 0;
}
</style>
