<template>
  <div class="view-editor">
    <div class="view-editor__content">
      <slot />
      <div
        class="view-editor__workspace-container mb-4"
        :class="{
          'dotted-grid': viewProp.version === 1.0
        }"
      >
        <div class="m-auto view-editor__workspace-header">
          <input
            ref="editableTitle"
            class="view-editor__title"
            style="padding-left: 20px;"
            placeholder="Vista sin título"
            v-model="titleInput"
            @keyup="autoSaveTitleName"
          >
          <select
            v-if="template"
            class=""
            v-model="visibilityStatus"
            @change="updateVisibilityStatus"
          >
            <option>private</option>
            <option>workspace</option>
            <option>public</option>
          </select>
          <div
            v-if="!isTemplate"
            class="view-editor__edit-title-btn"
            @click="focusOnTitle"
          >
            <i class="material-icons">edit</i>
          </div>
          <div class="view-editor__actions">
            <div
              v-if="!this.view.inSharedFolder"
              class="view-editor__action"
              @click="openViewSettingsModal"
              :title="$t('view.settingsButton')"
            >
              <i class="material-icons view-editor__action-icon">settings</i>
            </div>

            <a
              :title="imageName || $t('view.thumbnail')"
              class="view-editor__action"
            >

              <thumbnail-upload
                from="view-editor"
                @uploaded="hadleThumbnailUpload"
              >
                <i class="material-icons view-editor__action-icon">backup</i>
              </thumbnail-upload>
            </a>

            <a
              :href="showPath"
              :title="$t('view.show')"
              class="view-editor__action"
            >
              <i class="material-icons view-editor__action-icon">remove_red_eye</i>
            </a>
            <div
              v-if="isAdmin && !isTemplate && !this.view.inSharedFolder"
              class="view-editor__action"
              :title="$t('view.shareButton')"
              @click="openShareViewModal"
            >
              <i class="material-icons view-editor__action-icon">share</i>
            </div>
          </div>
        </div>
        <div
          id="view-editor-container"
          class="view-editor__workspace-editor"
          :class="{
            'view-editor__red-edge': viewProp.version === 1.0,
            'dynamic-height no-padding': viewProp.version === 3.0
          }"
        >
          <div
            class="view-editor__workspace-content relative container-min-height h-screen"
            id="app-grid-container"
            ref="appgrid"
          >
            <app-grid
              v-if="viewProp.version === 1.0"
              :apps-editable="true"
            />
            <app-grid-2
              v-if="viewProp.version === 2.0"
              :apps-editable="true"
            />
            <app-grid-3
              v-if="viewProp.version === 3.0"
              :apps-editable="true"
            />
          </div>
        </div>
      </div>
    </div>
    <space-app-modal v-if="selectedModalOfType(appTypes.SPACE)" />
    <menu-app-modal v-if="selectedModalOfType(appTypes.MENU)" />
    <confirmation-modal v-if="confirmationModalOpened" />
    <share-resource-modal v-if="shareResourceModalOpened" />
    <view-settings-modal v-if="viewSettingsModalOpened" />
    <bigstore-app-template-modal v-if="bigstoreAppTemplateModalOpened" />
    <unsaved-changes-modal v-if="unsavedChangesModalOpened" />
    <delete-confirmation-modal v-if="deleteConfirmationModalOpened" />
  </div>
</template>

<script>

import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { APP_TYPES } from '../constants';
import {
  SET_NESTED_VIEW,
  SELECT_RESOURCE_FOR_SHARING,
  UPDATE_SELECTED_VIEW,
  CORRECT_APP_GRID_ORDER,
  SELECT_VIEW_FOR_SETTINGS,
} from '../store/action-types';
import {
  SET_MAX_APP_WIDTH,
  SET_VIEW_TEMPLATE,
  SET_INDUSTRY_TAGS,
  SET_COMPANY_SIZE_OPTIONS,
  REVENUE_TYPE_OPTIONS,
  PROCESS_OPTIONS,
  VIEWING_LEVEL_OPTIONS,
  AREA_OPTIONS,
  SET_CONNECTIONS,
  SET_PERSONAL_FOLDERS,
  SET_APPS_DRAGGABLE,
} from '../store/mutation-types';
import ConfirmationModal from './confirmation-modal';
import SpaceAppModal from './space-app-modal';
import MenuAppModal from './menu-app-modal';
import UnsavedChangesModal from './unsaved-changes-modal';
import DeleteConfirmationModal from './delete-confirmation-modal';
import BigstoreAppTemplateModal from './bigstore-app-template-modal';
import ShareResourceModal from './share-resource-modal';
import ViewSettingsModal from './view-settings-modal';
import ThumbnailUpload from './utils/thumbnail-upload';
import storeConnectionsMixin from '../mixins/storeConnectionsMixin';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';
import api from '../api';

const DEBOUNCE_TIME = 1000;

export default {
  mixins: [storeConnectionsMixin, dragAndResizeMixin],
  components: {
    SpaceAppModal,
    MenuAppModal,
    ConfirmationModal,
    ShareResourceModal,
    ThumbnailUpload,
    ViewSettingsModal,
    BigstoreAppTemplateModal,
    UnsavedChangesModal,
    DeleteConfirmationModal,
  },
  props: {
    viewProp: { type: Object, required: true },
    maxAppWidth: { type: Number, required: true },
    template: { type: Object, default: undefined },
    industryTags: { type: Array, default: () => [] },
    companySizeOptions: { type: Array, default: () => [] },
    revenueTypeOptions: { type: Array, default: () => [] },
    processOptions: { type: Array, default: () => [] },
    viewingLevelOptions: { type: Array, default: () => [] },
    areaOptions: { type: Array, default: () => [] },
    connections: { type: Array, default: () => [] },
    personalFolders: { type: Array, default: () => [] },
    personalViews: { type: Array, default: () => [] },
  },
  data() {
    return {
      titleInput: this.viewProp && this.viewProp.title,
      visibilityStatus: this.template ? this.template.visibilityStatus : null,
      appTypes: APP_TYPES,
      imageName: null,
      imageUrl: null,
      fileSignedId: null,
    };
  },
  // eslint-disable-next-line max-statements
  async mounted() {
    let currentTitle = document.title.split('|')[0];
    if (this.viewProp.title) {
      document.title = currentTitle + ' | ' + this.viewProp.title;
    }
    window.withZoom = false;
    console.log('VISIBILITY STATUS', this.visibilityStatus)
    const personalResources = this.personalFolders.concat(this.personalViews);
    this.$store.commit(SET_MAX_APP_WIDTH, this.maxAppWidth);
    this.$store.commit(SET_VIEW_TEMPLATE, this.template);
    this.$store.commit(SET_INDUSTRY_TAGS, this.industryTags);
    this.$store.commit(SET_COMPANY_SIZE_OPTIONS, this.companySizeOptions);
    this.$store.commit(REVENUE_TYPE_OPTIONS, this.revenueTypeOptions);
    this.$store.commit(PROCESS_OPTIONS, this.processOptions);
    this.$store.commit(VIEWING_LEVEL_OPTIONS, this.viewingLevelOptions);
    this.$store.commit(AREA_OPTIONS, this.areaOptions);
    this.$store.commit(SET_CONNECTIONS, this.connections);
    this.$store.dispatch(SET_NESTED_VIEW, this.viewProp);
    this.$store.commit(SET_PERSONAL_FOLDERS, this.resourcePathNames);
    await this.$store.dispatch(CORRECT_APP_GRID_ORDER);
    this.defineDropZoneContainer(this.$refs.appgrid);
    this.setObservers();
  },
  computed: {
    contRef() {
      return this.$refs.appgrid;
    },
    isTemplate() {
      return !!this.template;
    },
    showPath() {
      return this.isTemplate ?
        `/view_templates/${this.template.id}` : `/views/${this.view && this.view.uuid}`;
    },
    resourcePathNames() {
      const personalFolder = this.personalFolders.filter(folder => folder.type.toLowerCase() === 'folder');
      const personalViews = this.personalViews.filter(folder => folder.type.toLowerCase() === 'view');
      const pathList = [];

      function traversePath(fol, parent) {
        fol.forEach(currentFolder => {
          pathList.push({ name: `${parent}/${currentFolder.name}`, folderId: currentFolder.id });
          traversePath(currentFolder.childs || [], `${parent}/${currentFolder.name}`);
        });
      }
      traversePath(personalFolder, '');

      return [
        {
          resourceType: 'Folders',
          pathList: pathList.map(ele => {
            ele.type = 'folder';

            return ele;
          }),
        },
        {
          resourceType: 'Views',
          pathList: personalViews.map(ele => {
            ele.type = 'view';

            return ele;
          }),
        },
      ];
    },
    ...mapState({
      view: state => state.views.selected,
      shareResourceModalOpened: state => state.ui.shareResourceModalOpened,
      viewSettingsModalOpened: state => state.ui.viewSettingsModalOpened,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      fullscreenAppModalOpened: state => state.ui.fullscreenAppModalOpened,
      bigstoreAppTemplateModalOpened: state => state.ui.bigstoreAppTemplateModalOpened,
      unsavedChangesModalOpened: state => state.ui.unsavedChangesModalOpened,
      deleteConfirmationModalOpened: state => state.ui.deleteConfirmationModalOpened,
    }),
    ...mapGetters(['selectedModalOfType', 'isAdmin', 'selectedView']),
  },
  methods: {
    focusOnTitle() {
      this.$refs.editableTitle.focus();
    },
    saveTitleName() {
      this.$store.dispatch(UPDATE_SELECTED_VIEW, { title: this.titleInput });
    },
    saveThumbnailImage() {
      this.$store.dispatch(UPDATE_SELECTED_VIEW, { thumbnail: this.fileSignedId });
    },
    autoSaveTitleName: _.debounce(function () {
      this.saveTitleName();
    }, DEBOUNCE_TIME),
    openShareViewModal() {
      const options = { resource: this.viewProp, resourceType: 'View' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
    },
    openViewSettingsModal() {
      this.$store.dispatch(SELECT_VIEW_FOR_SETTINGS, this.viewProp);
    },
    hadleThumbnailUpload({ url, fileSignedId, name }) {
      this.imageUrl = url;
      this.fileSignedId = fileSignedId;
      this.imageName = name;
      this.saveThumbnailImage();
    },
    updateVisibilityStatus() {
      api.updateTemplate({id: this.template.id, params: {
        name: this.template.name,
        description: this.template.description,
        visibilityStatus: this.visibilityStatus,
      }});
    },
    callback(mutationList) {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          if (mutation.target.style.transform === 'matrix(1, 0, 0, 1, 0, 0)') {
            window.grid.enable();
            window.withZoom = false;
            this.$store.commit(SET_APPS_DRAGGABLE, true);
          } else {
            window.grid.disable();
            window.withZoom = true;
            this.$store.commit(SET_APPS_DRAGGABLE, false);
          }
        }
      }
    },
    setObservers() {
      // Select the node that will be observed for mutations
      const targetNode = document.getElementById('view-editor-container');

      // Options for the observer (which mutations to observe)
      const config = { attributes: true };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(this.callback);

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);
    },
  },
};
</script>
<style scoped>
.container-min-height {
  min-height: 90vh;
}

.dotted-grid {
  background-image: radial-gradient(circle at 1px 1px, #d8d8d8 1px, transparent 0);
  background-size: 12px 12px;

}

.view-editor__workspace-container {
  background-color: #fff;
  flex: 1;
  margin-left: 48px;
}

.h-20px {
  height: 20px;
}

.max-w-1348 {
  max-width: 1348px;
}

.dynamic-height {
  height: 100%;
}

.no-padding {
  padding: 0;
}

</style>
