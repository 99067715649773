import Vue from 'vue';
import Vuex from 'vuex';

import views from './modules/views';
import users from './modules/users';
import ui from './modules/ui';
import templates from './modules/templates';
import connections from './modules/connections';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    views,
    users,
    ui,
    templates,
    connections,
  },
  getters: {
    selectedViewSharedUsers: (state, getters) =>
      getters.selectedView && getters.selectedView.usersWithPermission.map((id) => state.users.list[id]),

    selectedFolderSharedUsers: (state, getters) =>
      getters.selectedFolder && getters.selectedFolder.usersWithPermission.map((id) => state.users.list[id]),
    selectedResource: (_, getters) => (
      (getters.selectedFolder) ? getters.selectedFolder : getters.selectedView
    ),
    selectedResourceSharedUsers: (_, getters) => (resourceType) => (
      (resourceType === 'Folder') ? getters.selectedFolderSharedUsers : getters.selectedViewSharedUsers
    ),
  },
  strict: true,
});
