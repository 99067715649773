<template>
  <div>
    <div
      v-if="appsEditable"
      id="wrapper-grid"
      :class="{
        'wrapper': appsEditable,
      }"
      style="margin-top: 35px;"
    >
      <div
        :id="`matrix-element-${square}`"
        v-for="(square) in squares"
        :key="`matrix-element-${square}`"
        :class="{
          'item': appsEditable,
        }"
      />
    </div>
    <div
      class="app-grid"
      v-if="appContainers"
      style="margin-top: 35px;"
    >
      <app-container-floating
        v-for="(container, index) in appContainersFiltered"
        :key="index"
        :container="container"
        :app-type="container.appType"
        :apps-editable="appsEditable"
        :is-new="container.app ? container.app.isNew : false"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

const COLUMNS_NUMBER = 12;
const ROWS_NUMBER = 95;

export default {
  props: {
    appsEditable: { type: Boolean, default: false },
  },
  mounted() {
    console.log('App Containers', this.appContainers);
  },
  computed: {
    ...mapState({
      appContainers: state => state.views.appContainers,
    }),
    appContainersFiltered() {
      const filtered = _.pickBy(this.appContainers, (container) => container.appType !== undefined);

      return filtered;
    },
    squares() {
      const squares = [];
      for (let rowIndex = 1; rowIndex <= ROWS_NUMBER; rowIndex++) {
        for (let columnIndex = 1; columnIndex <= COLUMNS_NUMBER; columnIndex++) {
          const elementDenomination = `${rowIndex}-${columnIndex}`;
          squares.push(elementDenomination);
        }
      }

      return squares;
    },
  },
};
</script>
<style scoped>
.wrapper {
  display: grid;
  grid-template-columns: 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px;
  grid-auto-rows: 70px;
  grid-gap: 35px;
}

.item {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
</style>
