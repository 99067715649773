export const SET_NESTED_VIEWS = 'SET_NESTED_VIEWS';
export const SET_NESTED_VIEW = 'SET_NESTED_VIEW';
export const UPDATE_SELECTED_VIEW = 'UPDATE_SELECTED_VIEW';
export const SELECT_VIEW_FOR_DELETION = 'SELECT_VIEW_FOR_DELETION';
export const SELECT_VIEW_FOR_SETTINGS = 'SELECT_VIEW_FOR_SETTINGS';
export const CLOSE_VIEW_SETTINGS_MODAL = 'CLOSE_VIEW_SETTINGS_MODAL';
export const SAVE_VIEW_SETTINGS = 'SAVE_VIEW_SETTINGS';
export const UNSELECT_VIEW_FOR_DELETION = 'UNSELECT_VIEW_FOR_DELETION';
export const DELETE_SELECTED_VIEW = 'DELETE_SELECTED_VIEW';
export const SELECT_CONTAINER_FOR_DELETION = 'SELECT_CONTAINER_FOR_DELETION';
export const UNSELECT_CONTAINER_FOR_DELETION = 'UNSELECT_CONTAINER_FOR_DELETION';
export const DELETE_SELECTED_CONTAINER = 'DELETE_SELECTED_CONTAINER';
export const SELECT_CONTAINER_FOR_EDITION = 'SELECT_CONTAINER_FOR_EDITION';
export const UNSELECT_CONTAINER_FOR_EDITION = 'UNSELECT_CONTAINER_FOR_EDITION';
export const SELECT_CONTAINER_FOR_INITIALIZATION = 'SELECT_CONTAINER_FOR_INITIALIZATION';
export const SELECT_CONTAINER_FOR_FULLSCREEN = 'SELECT_CONTAINER_FOR_FULLSCREEN';
export const UNSELECT_CONTAINER_FOR_FULLSCREEN = 'UNSELECT_CONTAINER_FOR_FULLSCREEN';
export const REORDER_CONTAINERS = 'REORDER_CONTAINERS';
export const HANDLE_APP_LEAVING_ROW = 'HANDLE_APP_LEAVING_ROW';
export const SAVE_CONTAINER = 'SAVE_CONTAINER';
export const ADD_COPY_CONTAINER = 'ADD_COPY_CONTAINER';
export const CLOSE_APP_MODAL = 'CLOSE_APP_MODAL';
export const SELECT_USER_FOR_DELETION = 'SELECT_USER_FOR_DELETION';
export const UNSELECT_USER_FOR_DELETION = 'UNSELECT_USER_FOR_DELETION';
export const DELETE_SELECTED_USER = 'DELETE_SELECTED_USER';
export const SELECT_USER_FOR_EDITION = 'SELECT_USER_FOR_EDITION';
export const SAVE_USER = 'SAVE_USER';
export const CLOSE_USER_MODAL = 'CLOSE_USER_MODAL';
export const SET_NESTED_FOLDERS = 'SET_NESTED_FOLDERS';
export const SELECT_FOLDER_FOR_EDITION = 'SELECT_FOLDER_FOR_EDITION';
export const UNSELECT_FOLDER_FOR_EDITION = 'UNSELECT_FOLDER_FOR_EDITION';
export const DELETE_SELECTED_FOLDER = 'DELETE_SELECTED_FOLDER';
export const SELECT_FOLDER_FOR_DELETION = 'SELECT_FOLDER_FOR_DELETION';
export const UNSELECT_FOLDER_FOR_DELETION = 'UNSELECT_FOLDER_FOR_DELETION';
export const SAVE_FOLDER = 'SAVE_FOLDER';
export const CLOSE_FOLDER_MODAL = 'CLOSE_FOLDER_MODAL';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_THREAD_COMMENTS = 'GET_THREAD_COMMENTS';
export const CREATE_APP_CONTAINER_COMMENT = 'CREATE_APP_CONTAINER_COMMENT';
export const CREATE_THREAD_COMMENT = 'CREATE_THREAD_COMMENT';
export const UPDATE_SELECTED_COMMENT = 'UPDATE_SELECTED_COMMENT';
export const SELECT_COMMENT_FOR_DELETION = 'SELECT_COMMENT_FOR_DELETION';
export const UNSELECT_COMMENT_FOR_DELETION = 'UNSELECT_COMMENT_FOR_DELETION';
export const DELETE_SELECTED_COMMENT = 'DELETE_SELECTED_COMMENT';
export const FETCH_GOOGLE_ACCESS_TOKEN = 'FETCH_GOOGLE_ACCESS_TOKEN';
export const SELECT_CUSTOM_APP_TEMPLATE_FOR_DELETION = 'SELECT_CUSTOM_APP_TEMPLATE_FOR_DELETION';
export const SELECT_CUSTOM_APP_TEMPLATE_FOR_DISABLE = 'SELECT_CUSTOM_APP_TEMPLATE_FOR_DISABLE';
export const UNSELECT_CUSTOM_APP_TEMPLATE = 'UNSELECT_CUSTOM_APP_TEMPLATE';
export const DELETE_SELECTED_CUSTOM_APP_TEMPLATE = 'DELETE_SELECTED_CUSTOM_APP_TEMPLATE';
export const UPDATE_SELECTED_CUSTOM_APP_TEMPLATE = 'UPDATE_SELECTED_CUSTOM_APP_TEMPLATE';
export const DISABLE_SELECTED_CUSTOM_APP_TEMPLATE = 'DISABLE_SELECTED_CUSTOM_APP_TEMPLATE';
export const CORRECT_APP_GRID_ORDER = 'CORRECT_APP_GRID_ORDER';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SELECT_TEMPLATE_FOR_EDITION = 'SELECT_TEMPLATE_FOR_EDITION';
export const SELECT_TEMPLATE_FOR_DELETION = 'SELECT_TEMPLATE_FOR_DELETION';
export const UNSELECT_TEMPLATE_FOR_DELETION = 'UNSELECT_TEMPLATE_FOR_DELETION';
export const DELETE_SELECTED_TEMPLATE = 'DELETE_SELECTED_TEMPLATE';
export const CLOSE_TEMPLATE_MODAL = 'CLOSE_TEMPLATE_MODAL';
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE';
export const CREATE_RESOURCE_PERMISSIONS = 'CREATE_RESOURCE_PERMISSIONS';
export const DELETE_RESOURCE_PERMISSIONS = 'DELETE_RESOURCE_PERMISSIONS';
export const SELECT_RESOURCE_FOR_SHARING = 'SELECT_RESOURCE_FOR_SHARING';
export const UNSELECT_RESOURCE_FOR_SHARING = 'UNSELECT_RESOURCE_FOR_SHARING';
export const UPDATE_CONNECTION = 'UPDATE_CONNECTION';
export const SELECT_CONNECTION_FOR_EDITION = 'SELECT_CONNECTION_FOR_EDITION';
export const SELECT_CONNECTION_FOR_DELETION = 'SELECT_CONNECTION_FOR_DELETION';
export const DELETE_SELECTED_CONNECTION = 'DELETE_SELECTED_CONNECTION';
export const UNSELECT_CONNECTION_FOR_DELETION = 'UNSELECT_CONNECTION_FOR_DELETION';
export const CLOSE_CONNECTION_MODAL = 'CLOSE_CONNECTION_MODAL';
export const SELECT_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const ADD_TAG = 'ADD_TAG';
export const UPDATE_EDITED_TAG = 'UPDATE_EDITED_TAG';
export const REMOVE_SELECTED_TAG = 'REMOVE_SELECTED_TAG';
export const SAVE_TAG_ASSIGNATIONS = 'SAVE_TAG_ASSIGNATIONS';
export const SELECT_RESOURCE_FOR_MOVING = 'SELECT_RESOURCE_FOR_MOVING';
export const UNSELECT_RESOURCE_FOR_MOVING = 'UNSELECT_RESOURCE_FOR_MOVING';
export const MOVE_RESOURCE_FOLDER = 'MOVE_RESOURCE_FOLDER ';
export const OPEN_PREVIEW_TEMPLATE_APP = 'OPEN_PREVIEW_TEMPLATE_APP';
export const INITIALIZE_GAPI_SERVICE = 'INITIALIZE_GAPI_SERVICE';
export const SET_SELECTED_DATA_SOURCE_SERVICE = 'SET_SELECTED_DATA_SOURCE_SERVICE';
export const SET_SELECTED_DATA_SOURCE = 'SET_SELECTED_DATA_SOURCE';
export const CREATE_DATA_SOURCE = 'CREATE_DATA_SOURCE';
export const OPEN_DATA_SOURCE_MODAL = 'OPEN_DATA_SOURCE_MODAL';
export const CLOSE_DATA_SOURCE_MODAL = 'CLOSE_DATA_SOURCE_MODAL';
export const CLOSE_BIGSTORE_MODAL = 'CLOSE_BIGSTORE_MODAL';
export const OPEN_BIGSTORE_MODAL = 'OPEN_BIGSTORE_MODAL';
export const SELECT_TAG_FOR_DELETION = 'SELECT_TAG_FOR_DELETION';
export const UNSELECT_TAG_FOR_DELETION = 'UNSELECT_TAG_FOR_DELETION';
export const SELECT_WORKSPACE_FOR_DELETION = 'SELECT_WORKSPACE_FOR_DELETION';
export const REMOVE_SELECTED_WORKSPACE = 'REMOVE_SELECTED_WORKSPACE';
export const UNSELECT_WORKSPACE_FOR_DELETION = 'UNSELECT_WORKSPACE_FOR_DELETION';
export const SAVE_CONSULTANT = 'SAVE_CONSULTANT';
export const SELECT_COSULTANT_FOR_DELETION = 'SELECT_COSULTANT_FOR_DELETION';
export const DELETE_SELECTED_CONSULTANT = 'DELETE_SELECTED_CONSULTANT';
export const UNSELECT_CONSULTANT_FOR_DELETION = 'UNSELECT_CONSULTANT_FOR_DELETION';
export const SELECT_CONTAINER_FOR_DELETION_WITH_LABEL = 'SELECT_CONTAINER_FOR_DELETION_WITH_LABEL';
export const TOOGLE_TOOLBAR_COLLAPSIBLE = 'TOOGLE_TOOLBAR_COLLAPSIBLE';
export const OPEN_SAVE_ALERT = 'OPEN_SAVE_ALERT';
export const CLOSE_AND_SAVE_ALERT = 'CLOSE_AND_SAVE_ALERT';
export const SELECT_CHECKED_FILTER_INDEX_FOR_DELETION = 'SELECT_CHECKED_FILTER_INDEX_FOR_DELETION';
export const SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION = 'SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION';
export const UNSELECT_CHECKED_FILTER_INDEX_FOR_DELETION = 'UNSELECT_CHECKED_FILTER_INDEX_FOR_DELETION';
export const UNSELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION = 'UNSELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION';
export const DELETE_SELECTED_CHECKED_FILTER = 'DELETE_SELECTED_CHECKED_FILTER';
export const DELETE_SELECTED_UNCHECKED_FILTER = 'DELETE_SELECTED_UNCHECKED_FILTER';
export const UPDATE_FILENAMES = 'UPDATE_FILENAMES';
export const UPDATE_FILE_URLS = 'UPDATE_FILE_URLS';
export const UPDATE_FILENAMES_IN_APP = 'UPDATE_FILENAMES_IN_APP';
export const CREATE_APP_CONTAINER_PUBLICATION = 'CREATE_APP_CONTAINER_PUBLICATION';
export const UPDATE_SELECTED_PUBLICATION = 'UPDATE_SELECTED_PUBLICATION';
export const SELECT_PUBLICATION_FOR_DELETION = 'SELECT_PUBLICATION_FOR_DELETION';
export const GET_PUBLICATION_COMMENTS = 'GET_PUBLICATION_COMMENTS';
export const DELETE_SELECTED_PUBLICATION = 'DELETE_SELECTED_PUBLICATION';
export const UNSELECT_PUBLICATION_FOR_DELETION = 'UNSELECT_PUBLICATION_FOR_DELETION';
export const CREATE_LINK_METADATA = 'CREATE_LINK_METADATA';
export const CREATE_PUBLICATION_COMMENT = 'CREATE_PUBLICATION_COMMENT';
export const UPDATE_SELECTED_PUBLICATION_COMMENT = 'UPDATE_SELECTED_PUBLICATION_COMMENT';
export const SELECT_PUBLICATION_COMMENT_FOR_DELETION = 'SELECT_PUBLICATION_COMMENT_FOR_DELETION';
export const DELETE_SELECTED_PUBLICATION_COMMENT = 'DELETE_SELECTED_PUBLICATION_COMMENT';
export const GET_PUBLICATION_THREAD_COMMENTS = 'GET_PUBLICATION_THREAD_COMMENTS';
export const CREATE_PUBLICATION_THREAD_COMMENT = 'CREATE_PUBLICATION_THREAD_COMMENT';
export const SELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION = 'SELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION';
export const DELETE_SELECTED_PUBLICATION_THREAD_COMMENT = 'DELETE_SELECTED_PUBLICATION_THREAD_COMMENT';
export const UNSELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION = 'UNSELECT_PUBLICATION_THREAD_COMMENT_FOR_DELETION';
export const UPDATE_SELECTED_PUBLICATION_THREAD_COMMENT = 'UPDATE_SELECTED_PUBLICATION_THREAD_COMMENT';
export const MORE_PUBLICATIONS = 'MORE_PUBLICATIONS';
export const SET_APP_ATTRIBUTES = 'SET_APP_ATTRIBUTES';
export const SELECT_TAB_CONTAINER_FOR_DELETION = 'SELECT_TAB_CONTAINER_FOR_DELETION';
export const DELETE_SELECTED_TAB_CONTAINER = 'DELETE_SELECTED_TAB_CONTAINER';
export const SET_SHOULD_SAVE = 'SET_SHOULD_SAVE';
export const UNDO_CACHE = 'UNDO_CACHE';
export const REDO_CACHE = 'REDO_CACHE';
export const UPDATE_APP_CONTAINERS_IN_MAIN_GRID_AND_TABS = 'UPDATE_APP_CONTAINERS_IN_MAIN_GRID_AND_TABS';
export const UPDATE_APP_CONTAINERS_FROM_TAB_TO_MAIN_GRID = 'UPDATE_APP_CONTAINERS_FROM_TAB_TO_MAIN_GRID';
