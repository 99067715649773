const DEFAULT_DURATION = 3000;
export default {
  methods: {
    showMessage(message, duration = DEFAULT_DURATION) {
      this.$toasted.show(message, {
        theme: 'outline',
        position: 'top-right',
        duration,
      });
    },
  },
};
