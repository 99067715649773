<template>
  <div class="grid-index__item-container--folder bg-white pr-2 pl-3 py-2">
    <a
      class="w-4/6"
      :href="showPath"
    >
      <div
        class="grid-index__name text-gray-400 overflow-hidden truncate"
        :class="{'grid-index__name--no-icons': !isAdmin}"
      >
        <i
          v-if="wasShared"
          class="material-icons-outlined material-icons-black pr-2"
        >folder_shared</i>
        <i
          v-else
          class="material-icons-outlined material-icons-black pr-2"
        >folder</i>
        <span
          :title="folder.name"
          class="truncate"
        >{{ folder.name === "General" ? "Compartidas Conmigo": folder.name || "Sin título" }}</span>
      </div>
    </a>

    <div class="w-1/6">
      <i
        v-if="isFavorited"
        @click="checkFavorite(false)"
        title="Remove from favorites"
        class="material-icons material-icons--primary cursor-pointer"
      >star</i>
      <i
        v-else
        @click="checkFavorite(true)"
        title="Add to favorites"
        class="material-icons material-icons--primary cursor-pointer"
      >star_border</i>
    </div>
    <div
      class="w-1/6"
      v-if="isAdmin && !folder.protected"
    >
      <i
        ref="dropDown"
        id="dropDown"
        @click="open =!open"
        class="material-icons material-icons--primary float-right cursor-pointer"
      >more_vert</i>

      <div
        class="list__folder-view--dropdown text-sm"
        v-if="open"
      >
        <div class="list__folder-view--dropdown-content shadow-card rounded-md">
          <a
            @click="selectForEdition()"
            class="list__folder-view--dropdown-item rounded-t-md"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">edit</i>
            <span class="align-middle">{{ $t("view.edit") }}</span>
          </a>
          <div
            class="list__folder-view--dropdown-item"
            @click="openMoveResourceModal"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">snippet_folder</i>
            <span class="align-middle">{{ $t("view.folder.move") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item"
            @click="openShareResourceModal"
            v-if="isShareable"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">share</i>
            <span class="align-middle">{{ $t("view.shareButton") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item rounded-md border-t-1 border-gray-400"
            @click="openConfirmationModal"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">delete_forever</i>
            <span class="align-middle">{{ $t("view.folder.deleteShort") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SELECT_FOLDER_FOR_EDITION,
  SELECT_FOLDER_FOR_DELETION,
  SELECT_RESOURCE_FOR_SHARING,
  SELECT_RESOURCE_FOR_MOVING,
} from '../store/action-types';

import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';
import api from '../api';

export default {
  mixins: [escapeMixinHandler, clickMixinHandler],
  props: {
    folder: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isShareable: {
      type: Boolean,
      default: false,
    },
    wasShared: {
      type: Boolean,
      required: true,
    },
    inSharedFolder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showPath() {
      if (this.inSharedFolder) return `/shared/${this.folder.id}`;

      return `/folders/${this.folder.id}`;
    },
    favoritedParams() {
      return {
        favoritedId: this.folder.id,
        favoritedType: 'Folder',
      };
    },
  },
  data() {
    return {
      open: false,
      isFavorited: this.folder.favoritedByCurrentUser,
    };
  },
  methods: {
    selectForEdition() {
      this.$store.dispatch(SELECT_FOLDER_FOR_EDITION, this.folder);
    },
    openConfirmationModal() {
      this.$store.dispatch(SELECT_FOLDER_FOR_DELETION, this.folder);
      this.open = false;
    },
    openShareResourceModal() {
      const options = { resource: this.folder, resourceType: 'Folder' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
    },
    openMoveResourceModal() {
      const options = { resource: this.folder, resourceType: 'Folder' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
      this.open = false;
    },
    close() {
      this.open = false;
    },
    checkFavorite(checkStatus) {
      if (checkStatus) {
        api.addToFavorites(this.favoritedParams)
          .then((_) => {
            this.isFavorited = checkStatus;
          });
      } else {
        api.removeFromFavorites(this.favoritedParams)
          .then((_) => {
            this.isFavorited = checkStatus;
          });
      }
    },
  },
};
</script>
<style scoped>

.list__folder-view--dropdown {
  position: relative;
  display: inline-block;
  left: -1.5em;
}

.list__folder-view--dropdown-content {
  display: block;
  position: absolute;
  min-width: 160px;
  border: 1px solid #e2e5e9;
  z-index: 1;
  top: 6px;
  left: 36px;
  padding-left: 1em;
  background-color: #fff;
}

.list__folder-view--dropdown-item {
  cursor: pointer;
  color: #68778d;
  display: block;
  background-color: #fff;
  padding-bottom: .5em;
  padding-top: .7em;
}

.list__folder-view--dropdown-item:hover {
  color: #000;
}

.grid-index__name {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #4a5464;
  /* line-height: 28px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 22px;
  padding-top: 12px; */
}

.grid-index__item-container--folder {
  border-radius: 6px;
  width: 208px;
  height: 64px;
  display: flex;
  align-items: center;
}

</style>
