<template>
  <html-renderer
    v-if="container.appType == appTypes.SOURCE"
    :code="container.app.content"
    :container="container"
    :app-icons="appIcons"
    style="z-index: 10;"
  />
  <single-value-app
    :app-type="appTypes.SINGLE_VALUE"
    :container="container"
    :editable="editable"
    v-else-if="container.appType === appTypes.SINGLE_VALUE"
  />
  <button-app
    :app-type="appTypes.BUTTON"
    :container="container"
    :editable="editable"
    v-else-if="container.appType === appTypes.BUTTON"
  />
  <stylized-text-app
    :app-type="appTypes.TEXT"
    :container="container"
    :editable="editable"
    v-else-if="container.appType == appTypes.TEXT || appTypes.TEXT === newApp"
    @sendTitleTextConflictToPoly="updateTitleTextConflict"
  />
  <image-app
    :app-type="appTypes.IMAGE"
    :container="container"
    :editable="editable"
    v-else-if="container.appType == appTypes.IMAGE || appTypes.IMAGE === newApp"
    @sendTitleTextConflictToPoly="updateTitleTextConflict"
  />
  <file-app
    v-else-if="container.appType == appTypes.FILE"
    :app-type="appTypes.FILE"
    :editable="editable"
    :container="container"
  />
  <menu-app
    v-else-if="container.appType == appTypes.MENU || appTypes.MENU === newApp"
    :container="container"
  />
  <tab-app
    :app-type="appTypes.TABS"
    :editable="editable"
    :container="container"
    v-else-if="container.appType == appTypes.TABS || appTypes.TABS === newApp"
  />

  <feed
    :comments="containerComments(container.id)"
    :container-id="container.id"
    :is-new="isNew"
    v-else-if="container.appType == appTypes.FEED || appTypes.FEED === newApp"
    class="polymorphic-app--feed"
  />
  <custom-app-renderer
    v-else-if="container.appType == appTypes.CUSTOM || appTypes.CUSTOM === newApp"
    :code="container.app.customAppTemplate.code"
    :fields="container.app.fieldValuesWithDefaults"
    :view_variables="containerViewVariables(container.id)"
    :data-source="container.app.dataSource"
    :container="container"
  />
  <article-app
    v-else-if="container.appType === appTypes.ARTICLE"
    :app-type="appTypes.ARTICLE"
    :container="container"
    :editable="editable"
  />
  <Wall
    :comments="containerComments(container.id)"
    :container="container"
    v-else-if="container.appType == appTypes.WALL"
  />
  <profile-app
    v-else-if="container.appType == appTypes.PROFILE"
    :container="container"
    :editable="editable"
  />
</template>

<script>
// :view_variables="container.app.fieldViewVarsRefs"
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { APP_TYPES, APP_ICONS } from '../constants';
import Feed from './feed';
import CustomAppRenderer from './custom-app-renderer';
import HtmlRenderer from './utils/html-renderer';
import StylizedTextApp from './stylized-text-app';
import SingleValueApp from './single-value-app';
import ArticleApp from './article-app';
import ButtonApp from './button-app';
import MenuApp from './menu-app';
import TabApp from './tab-app';
import ImageApp from './image-app.vue';
import FileApp from './file-app.vue';
import Wall from './wall.vue';
import ProfileApp from './profile-app.vue';
// import {
//   SAVE_CONTAINER,
// } from '../store/action-types';

export default {
  components: {
    Feed,
    CustomAppRenderer,
    HtmlRenderer,
    StylizedTextApp,
    SingleValueApp,
    ButtonApp,
    MenuApp,
    TabApp,
    ImageApp,
    FileApp,
    ArticleApp,
    Wall,
    ProfileApp,
  },
  props: {
    // container: { type: Object, required: true },
    container: { type: Object, default: () => ({}) },
    editable: { type: Boolean, default: false },
    newApp: { type: String, default: null },
  },
  data() {
    return {
      appTypes: APP_TYPES,
      appIcons: APP_ICONS,
    };
  },
  computed: {
    ...mapGetters(['containerComments', 'containerViewVariables']),
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
    }),
    height() {
      return this.container.width === this.maxWidth ? `${this.container.app.height}px` : 'auto';
    },
    isNew() {
      return (_.isEmpty(this.container) && this.newApp);
    },
  },
  methods: {
    updateTitleTextConflict(titleTextConflict) {
      this.$emit('sendTitleTextConflictToApp', titleTextConflict);
    },
  },
};
</script>
