<template>
  <div>
    <modal
      name="editcolumn"
      :width="670"
      :min-height="534"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header w-full">
        Edit Columns for Database: {{ this.selectedCustomType.name }}
      </div>
      <div class="app-modal__inputs">
        <div class="w-full pl-2">
          <div
            class="flex flex-col"
          >
            <div
              class="flex h-14"
            >
              <div class="p-8 w-2/6">
                Name
              </div>
              <div class="p-8 w-2/6">
                Type
              </div>
            </div>
            <div
              class="flex h-20"
              :key="index"
              v-for="(customCol, index) in selectedCustomType.customAttrs"
            >
              <div class="p-8 w-2/6 text-sm text-menu-label">
                {{ customCol.name }}
              </div>
              <div class="p-8 w-2/6 text-sm text-menu-label">
                {{ customCol.type }}
              </div>
              <div
                @click="removeColumnToType(customCol.name)"
                title="Remove Column"
                class="p-8 w-2/6 text-sm text-menu-label cursor-pointer"
              >
                <i class="material-icons ">delete_forever</i>
              </div>
            </div>
            <div
              class="flex h-20 border-t border-menu-label rounded-md"
            >
              <div class="p-8 w-2/6">
                <input
                  class="outline-none"
                  type="text"
                  placeholder="Write a Name"
                  v-model="newColumnName"
                >
              </div>
              <div class="p-8 w-2/6">
                <select
                  name=""
                  id=""
                  v-model="newColumnType"
                >
                  <option value="date">
                    Date
                  </option>
                  <option value="text">
                    Text
                  </option>
                  <option value="boolean">
                    Boolean
                  </option>
                  <option value="number">
                    Number
                  </option>
                  <option value="image">
                    Image
                  </option>
                  <option value="file">
                    File
                  </option>
                  <option value="category">
                    Category
                  </option>
                </select>
              </div>
              <div class="w-2/6 flex items-center">
                <button
                  @click="addColumnToType"
                  class="border border-gray-400 rounded-md px-4 py-2 cursor-pointer "
                >
                  Add new Column
                  <button />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  CLOSE_USER_DEFINED_TYPE_COLUMN_MODAL,
} from '../store/mutation-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import api from '../api';

export default {
  mixins: [escapeMixinHandler],
  components: {
  },
  data() {
    return {
      newColumnName: null,
      newColumnType: null,
    };
  },
  mounted() {
    this.$modal.show('editcolumn');
  },
  computed: {
    ...mapState({
      selectedCustomColumn: state => state.users.selectedCustomColumn,
      selectedCustomType: state => state.users.selectedCustomType,
      selectedCustomDatum: state => state.users.selectedCustomDatum,
    }),
    newInputFilled() {
      return this.newColumnName && this.newColumnType;
    },
  },
  methods: {
    removeColumnToType(removeColumnName) {
      const copyCustomAttrs = [...this.selectedCustomType.customAttrs];
      const indexFor = this.selectedCustomType.customAttrs.map(cols => cols.name)
        .indexOf(removeColumnName);
      if (indexFor > -1) {
        copyCustomAttrs.splice(indexFor, 1);
        api.updateUserDefinedType(this.selectedCustomType.id, {
          userType: { name: this.selectedCustomType.name, customAttrs: copyCustomAttrs },
        })
          .then(() => {
            this.selectedCustomType.customAttrs.splice(indexFor, 1);
          });
      }
    },
    addColumnToType() {
      if (!this.newInputFilled) return;
      const copyCustomAttrs = [...this.selectedCustomType.customAttrs];
      copyCustomAttrs.push({ name: this.newColumnName.toLowerCase(), type: this.newColumnType });
      api.updateUserDefinedType(this.selectedCustomType.id, {
        userType: { name: this.selectedCustomType.name, customAttrs: copyCustomAttrs },
      })
        .then(() => {
          this.selectedCustomType.customAttrs.push({
            name: this.newColumnName.toLowerCase(),
            type: this.newColumnType,
          });
          this.newColumnName = null;
          this.newColumnType = null;
        });
    },
    close() {
      this.$store.commit(CLOSE_USER_DEFINED_TYPE_COLUMN_MODAL);
    },
  },
};
</script>

<style>

.height-48 {
  height: 48px;
}

.height-56 {
  height: 56px;
}

.text-size-16 {
  font-size: 16px;
}

.text-size-14 {
  font-size: 14px;
}

.center-top {
  top: 12px;
  right: 12px;
}

.height-38 {
  height: 38px;
}

.rotated {
  transform: rotate(90deg);
  transition: all .1s linear;
}

.rotate-down {
  transform: rotate(0);
}

input:disabled {
  background-color: #fff;
}

.width-306 {
  width: 306px;
}

.margin-ontop {
  margin-top: 70px;
}
</style>
