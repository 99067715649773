<template>
  <div
    @click.stop="toggleDropdown"
    class="header__user"
  >
    <div
      class="header__user-dropdown-btn"
      id="dropDown"
      ref="dropDown"
    >
      <profile-picture
        :url="profilePictureUrl"
        class="header__user-profile-picture"
        :icon-color="iconColor"
      />
      <!-- <i class="material-icons">keyboard_arrow_down</i> -->
      <div
        class="header__user-info"
      >
        <div
          class="header__user-name"
          :style="{color: iconColor}"
        >
          {{ fullName }}
        </div>
        <div
          class="header__user-company"
          :style="{color: iconColor}"
        >
          {{ companyName }}
        </div>
      </div>
    </div>
    <div
      class="header__user-dropdown bg-white pt-2 pb-2 rounded-lg"
      v-if="open"
    >
      <div
        class="header__user-dropdown-item rounded-b-lg border-b hover:bg-menu-hover"
        @click="usersPath"
        v-if="this.currentUserAdminProp"
      >
        {{ this.$tc('user.manage',2) }}
      </div>
      <div
        class="header__user-dropdown-item border-b hover:bg-menu-hover"
        @click="accountConfigPath()"
      >
        {{ $t("user.config") }}
      </div>
      <div
        v-if="!doestNotHaveDefaultProfileConfigured"
        class="header__user-dropdown-item border-b hover:bg-menu-hover"
        @click="customProfilPath()"
      >
        Mi Perfil
      </div>
      <div
        class="header__user-dropdown-item rounded-b-lg hover:bg-menu-hover"
        @click="signOut"
      >
        {{ $t("user.logout") }}
        <!-- <i class="material-icons">logout</i> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { SET_CURRENT_USER } from '../store/action-types';
import { SET_CURRENT_COMPANY_NAME } from '../store/mutation-types';
import api from '../api';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';

export default {
  mixins: [escapeMixinHandler, clickMixinHandler],
  props: {
    companyName: { type: String, required: true },
    currentUserProp: { type: Object, default: null },
    currentUserAdminProp: { type: Boolean, default: false },
    defaultProfile: { type: Object, required: true },
    isDarkSolid: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    if (this.currentUserProp) this.$store.dispatch(SET_CURRENT_USER, this.currentUserProp);
    this.$store.commit(SET_CURRENT_COMPANY_NAME, this.companyName);
  },
  computed: {
    ...mapGetters(['currentUser']),
    name() {
      return _.get(this.currentUser, 'firstName');
    },
    iconColor() {
      if (this.isDarkSolid) return '#fff';

      return '#000';
    },
    fullName() {
      return _.get(this.currentUser, 'fullName');
    },
    profilePictureUrl() {
      return _.get(this.currentUser, 'profilePictureUrl');
    },
    doestNotHaveDefaultProfileConfigured() {
      return this.defaultProfile.url === 'account';
    },
    defaultProfileParams() {
      return `?merge_user_email=${this.currentUserProp.email}`;
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    usersPath() {
      window.location.href = '/users';
    },
    accountConfigPath() {
      window.location.href = '/account-config';
    },
    customProfilPath() {
      window.location.href = `${this.defaultProfile.url}${this.defaultProfileParams}`;
    },
    signOut() {
      api.signOut().then(() => {
        window.location.href = '/users/sign_in';
      });
    },
  },
};
</script>
