export default {
  folderModalOpened: false,
  shareResourceModalOpened: false,
  moveResourceModalOpened: false,
  confirmationModalOpened: false,
  userModalOpened: false,
  userDefinedTypeModalOpened: false,
  userDefinedTypeColumnsModalOpened: false,
  memberModalOpened: false,
  openWorkspaceModalOpened: false,
  userTagsModalOpened: false,
  connectionModalOpened: false,
  fullscreenAppModalOpened: false,
  confirmationAction: null,
  deconfirmationAction: null,
  confirmationBtnText: null,
  templateModalOpened: false,
  viewSettingsModalOpened: false,
  dataSourceModalOpened: false,
  bigstoreAppTemplateModalOpened: false,
  userModalEditMode: false,
  columnsFromDocument: null,
  filtersFromDocument: null,
  headersReady: true,
  columnField: {},
  filtersChecked: [],
  filtersUnchecked: [],
  saveAlert: false,
  unsavedChangesModalOpened: false,
  deleteConfirmationModalOpened: false,
  fileNames: {},
  fileUrls: [],
  filenamesInApp: {},
  blockAppTabContainerSelected: false,
  textAppBeenEdited: false,
  booleanSelected: null,
  selectedItemId: null,
};
