/* eslint-disable max-statements */

import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueSidebarMenu from 'vue-sidebar-menu';
import { ToggleButton } from 'vue-js-toggle-button';
import { register } from 'timeago.js';
import VTooltip from 'v-tooltip';
import es from 'timeago.js/esm/lang/es';
import Toasted from 'vue-toasted';
import VueI18n from 'vue-i18n';
import autolinker, { AUTOLINKER_NAME } from 'vue-autolinker';
import HeaderUserInfo from '../components/header-user-info.vue';
import ViewEditor from '../components/view-editor.vue';
import ViewFolderList from '../components/view-folder-list.vue';
import TemplateList from '../components/template-list.vue';
import ViewShow from '../components/view-show.vue';
import ViewShowGridstack from '../components/view-show-gridstack.vue';
import UserList from '../components/user-list.vue';
import Settings from '../components/settings.vue';
import PlatformSettings from '../components/platform-settings.vue';
import Workspace from '../components/workspace';
import AccountConfig from '../components/account-config.vue';
import NavigationBar from '../components/navigation-bar.vue';
import Feedback from '../components/feedback.vue';
import TextareaAutoheight from '../components/utils/textarea-autoheight.vue';
import ImageUpload from '../components/utils/image-upload.vue';
import ProfilePicture from '../components/profile-picture.vue';
import AppGrid from '../components/app-grid.vue';
import AppGrid2 from '../components/app-grid-2.vue';
import AppGrid3 from '../components/app-grid-3.vue';
import Notifications from '../components/notifications.vue';
import SearchInput from '../components/search-input.vue';
import CustomAppTemplateEditor from '../components/custom-app-template-editor.vue';
import CustomAppTemplateList from '../components/custom-app-template-list.vue';
import InputByType from '../components/utils/input-by-type.vue';
import ConnectionList from '../components/connection-list.vue';
import FolderList from '../components/folder-list.vue';
import BigstoreAppTemplateList from '../components/bigstore-app-template-list.vue';
import WorkspacesSelector from '../components/workspaces-selector';
import LeftSidebarContainer from '../components/left-sidebar-container';
import UserDefinedDatabases from '../components/user-defined-databases';
import UserDefinedDatabasesEditor from '../components/user-defined-databases-editor';
import ExcelUploads from '../components/excel-uploads';
import InternalDatabasesViewMode from '../components/internal-databases-view-mode';
import CustomMenu from '../components/custom-menu';
import AppContainerFloating from '../components/app-container-floating';
import AppFloating from '../components/app-floating';
import EditorFloatingBar from '../components/editor-floating-bar';
import store from '../store';
import locales from '../locales/index';
import './stylesheets/application.scss';

Vue.directive(AUTOLINKER_NAME, autolinker);

// eslint-disable-next-line import/prefer-default-export
export const eventBus = new Vue();

document.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('app') !== null) {
    register('es', es);
    Vue.use(VModal);
    Vue.use(VueI18n);
    Vue.use(VTooltip);
    Vue.use(VueSidebarMenu);
    Vue.use(Toasted, {
      action: {
        icon: 'close',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });

    Vue.component('ToggleButton', ToggleButton);
    Vue.component('TextareaAutoheight', TextareaAutoheight);
    Vue.component('ImageUpload', ImageUpload);
    Vue.component('AppGrid', AppGrid);
    Vue.component('AppGrid2', AppGrid2);
    Vue.component('AppGrid3', AppGrid3);
    Vue.component('AppFloating', AppFloating);
    Vue.component('ProfilePicture', ProfilePicture);
    Vue.component('InputByType', InputByType);
    Vue.component('NavigationBar', NavigationBar);
    Vue.component('AppContainerFloating', AppContainerFloating);
    Vue.component('EditorFloatingBar', EditorFloatingBar);

    return new Vue({
      el: '#app',
      store,
      i18n: new VueI18n({
        locale: document.documentElement.lang,
        messages: locales.messages,
      }),
      components: {
        HeaderUserInfo,
        ViewEditor,
        FolderList,
        ViewFolderList,
        TemplateList,
        ViewShow,
        ViewShowGridstack,
        UserList,
        Settings,
        Workspace,
        PlatformSettings,
        AccountConfig,
        Notifications,
        SearchInput,
        Feedback,
        CustomAppTemplateEditor,
        CustomAppTemplateList,
        ConnectionList,
        BigstoreAppTemplateList,
        WorkspacesSelector,
        LeftSidebarContainer,
        UserDefinedDatabases,
        UserDefinedDatabasesEditor,
        ExcelUploads,
        InternalDatabasesViewMode,
        CustomMenu,
      },
      mounted() {
        const errorMessage = this.$el.attributes.error.value;
        if (errorMessage) this.$toasted.error(errorMessage);
      },
    });
  }

  return null;
});
