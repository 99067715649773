<template>
  <div>
    <div
      v-if="container && !editable"
      ref="ImageApp"
      class="h-full"
    >
      <div
        v-if="!hasRedirectLink"
        class="flex flex-col"
        :class="{
          'container': descriptiveTextOverlap
        }"
      >
        <div
          :id="titleID"
          class="self-left ql-editor"
          v-if="hasDescriptiveText"
          v-html="descriptiveText"
          :class="{
            'top-left': descriptiveTextOverlap
          }"
        />
        <div
          class="self-center center-elements"
          :class="{
            'center-adjustment': contentAdjustment === 'center',
            'expanded-adjustment': contentAdjustment === 'expanded',
          }"
        >
          <div
            v-if="imageName === 'default_placeholder.png'"
          >
            <i class="material-icons icon">photo</i>
          </div>
          <div
            v-else
          >
            <img
              :id="imageID"
              :src="imageUrl"
              :class="{
                'self-start text-right-padding': contentAlignment === 'left',
                'self-end text-left-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            >
          </div>
        </div>
      </div>
      <div
        v-else
      >
        <a
          v-if="redirectType === 'file'"
          :href="container.app.redirectUrl"
          target="_blank"
          class="cursor-pointer"
        >
          <div
            class="h-full"
            :class="{
              'show-bg-transparent': showTransparency,
            }"
          >
            <div
              class="flex flex-col"
              :class="{
                'container': descriptiveTextOverlap
              }"
            >
              <div
                :id="titleID"
                class="self-left ql-editor"
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
                :class="{
                  'top-left': descriptiveTextOverlap
                }"
              />
              <div
                class="self-center center-elements"
                :class="{
                  'center-adjustment': contentAdjustment === 'center',
                  'expanded-adjustment': contentAdjustment === 'expanded',
                }"
              >
                <div
                  v-if="imageName === 'default_placeholder.png'"
                >
                  <i class="material-icons icon">photo</i>
                </div>
                <div
                  v-else
                >
                  <img
                    :id="imageID"
                    :src="imageUrl"
                    :class="{
                      'self-start text-right-padding': contentAlignment === 'left',
                      'self-end text-left-padding': contentAlignment === 'right',
                      'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
                    }"
                  >
                </div>
              </div>
            </div>
          </div>
        </a>
        <a
          v-else-if="redirectType === 'url'"
          :href="redirectLink"
          target="_blank"
          class="cursor-pointer"
          id="redirect-link-url-mode"
        >
          <div
            class="h-full"
            :class="{
              'show-bg-transparent': showTransparency,
            }"
          >
            <div
              class="flex flex-col"
              :class="{
                'container': descriptiveTextOverlap
              }"
            >
              <div
                :id="titleID"
                class="self-left ql-editor"
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
                :class="{
                  'top-left': descriptiveTextOverlap
                }"
              />
              <div
                class="self-center center-elements"
                :class="{
                  'center-adjustment': contentAdjustment === 'center',
                  'expanded-adjustment': contentAdjustment === 'expanded',
                }"
              >
                <div
                  v-if="imageName === 'default_placeholder.png'"
                >
                  <i class="material-icons icon">photo</i>
                </div>
                <div
                  v-else
                >
                  <img
                    :id="imageID"
                    :src="imageUrl"
                    :class="{
                      'self-start text-right-padding': contentAlignment === 'left',
                      'self-end text-left-padding': contentAlignment === 'right',
                      'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
                    }"
                  >
                </div>
              </div>
            </div>
          </div>
        </a>
        <a
          v-else-if="redirectType === 'view'"
          :href="getResourceUrl(redirectLink)"
          :target="openNewTab ? '_blank' : '_self'"
          class="cursor-pointer"
        >
          <div
            v-if="container && !editable"
            ref="ButtonApp"
            class="h-full"
            :class="{
              'show-bg-transparent': showTransparency,
            }"
          >
            <div
              class="flex flex-col"
              :class="{
                'container': descriptiveTextOverlap
              }"
            >
              <div
                :id="titleID"
                class="self-left ql-editor"
                v-if="hasDescriptiveText"
                v-html="descriptiveText"
                :class="{
                  'top-left': descriptiveTextOverlap
                }"
              />
              <div
                class="self-center center-elements"
                :class="{
                  'center-adjustment': contentAdjustment === 'center',
                  'expanded-adjustment': contentAdjustment === 'expanded',
                }"
              >
                <div
                  v-if="imageName === 'default_placeholder.png'"
                >
                  <i class="material-icons icon">photo</i>
                </div>
                <div
                  v-else
                >
                  <img
                    :id="imageID"
                    :src="imageUrl"
                    :class="{
                      'self-start text-right-padding': contentAlignment === 'left',
                      'self-end text-left-padding': contentAlignment === 'right',
                      'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
                    }"
                  >
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div
      v-else
      ref="ImageApp"
      class="h-full"
    >
      <div
        class="flex flex-col h-full overflow-hidden overflow-y-scroll"
        :class="{
          'container': descriptiveTextOverlap
        }"
      >
        <vue-editor
          v-if="hasDescriptiveText"
          class="self-left"
          :id="editorId"
          v-model="descriptiveText"
          :editor-toolbar="customToolbar"
          @text-change="autoSaveDescriptiveText"
          @focus="toolbarShow()"
          @blur="toolbarHide()"
          :class="{
            'top-left': descriptiveTextOverlap
          }"
        />
        <div
          class="self-center center-elements"
          :class="{
            'center-adjustment': contentAdjustment === 'center',
            'expanded-adjustment': contentAdjustment === 'expanded',
          }"
        >
          <div
            v-if="imageName === 'default_placeholder.png'"
          >
            <i class="material-icons icon">photo</i>
          </div>
          <div
            v-else
          >
            <img
              :key="imageUpdaterKey"
              :id="imageID"
              :src="imageUrl"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import { VueEditor } from 'vue2-editor';
import $ from 'jquery';
import Quill from 'quill';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';
import showMessageMixin from '../mixins/showMessageMixin';

const DEBOUNCE_TIME = 1000;

export default {
  mixins: [positionMixin, showMessageMixin],
  components: {
    VueEditor,
  },
  props: {
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
    container: { type: Object, default: null },
  },
  async mounted() {
    this.applyContentAlignment();
    this.colorSelected();
    $('.ql-toolbar.ql-snow').hide();
    const textQlEditor = `${this.textEditor} .ql-editor`;
    $(textQlEditor).attr('style',
      `min-height: 0px !important; padding-left: 0px; padding-right: 15.4px;
      padding-bottom: 0px; padding-top: 0px;`,
    );
  },
  updated() {
    this.applyContentAlignment();
  },
  data() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
      'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
      'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
      'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
    ];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = [
      '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
    ];
    Quill.register(Size, true);
    this.defineImageAppAttrs();

    return {
      fileSignedId: null,
      loadingImage: false,
      editorOptions: {
        scrollingContainer: '#single-value-app',
      },
      customToolbar: customToolbarHelper.customToolbar,
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      descriptiveText: _.get(this.container, 'app.descriptiveText', 'Título...'),
      containerID: _.get(this.container, 'id', -1),
      initialDescriptiveText: _.get(this.container, 'app.descriptiveText', 'Título...'),
      redirectType: _.get(this.container, 'app.redirectType', 'view'),
      redirectLink: _.get(this.container, 'app.redirectLink', 'https://bigpictures.co/'),
      hasRedirectLink: _.get(this.container, 'app.hasRedirectLink', false),
      openNewTab: _.get(this.container, 'app.openNewTab', false),
    };
  },
  computed: {
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      appAttrs: state => state.views.appsAttrs,
    }),
    showTransparency() {
      return _.get(this.container, 'showTransparency', false);
    },
    editorId() {
      return `text-editor${this.containerID}`;
    },
    textEditor() {
      return `#${this.editorId}`;
    },
    containerIdJS() {
      return `#app-container${this.containerID}`;
    },
    imageID() {
      return `image${this.containerID}`;
    },
    titleID() {
      return `image-title${this.containerID}`;
    },
    titleIdJS() {
      return `#${this.titleID}`;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    hasDescriptiveText() {
      return this.appAttrs[this.container.id].hasDescriptiveText;
    },
    descriptiveTextOverlap() {
      return this.appAttrs[this.container.id].descriptiveTextOverlap;
    },
    contentAlignment() {
      return this.appAttrs[this.container.id].contentAlignment;
    },
    contentAdjustment() {
      return this.appAttrs[this.container.id].contentAdjustment;
    },
    imageUrl() {
      return this.appAttrs[this.container.id].imageUrl;
    },
    imageName() {
      return this.appAttrs[this.container.id].imageName;
    },
    imageUpdaterKey() {
      return `image-updater-${this.appAttrs[this.container.id].imageUpdater}`;
    },
  },
  methods: {
    applyBottomAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-bottom', '15px');
    },
    applyTopAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-top', '15px');
    },
    applyContentAlignment() {
      if (this.contentAlignment === 'right') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-end');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '15px');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '0');
      } else if (this.contentAlignment === 'left') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-start');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '15px');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '0');
      } else if (this.contentAlignment === 'center') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
        $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'center');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-bottom', '0');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '0');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '0');
      } else if (this.contentAlignment === 'bottom') {
        this.applyBottomAlignment();
      } else if (this.contentAlignment === 'top') {
        this.applyTopAlignment();
      }
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.textEditor).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow() {
      $(this.textEditor).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    autoSaveDescriptiveText: _.debounce(function () {
      this.updateDescriptiveTextContent();
    }, DEBOUNCE_TIME),
    updateDescriptiveTextContent() {
      if (this.descriptiveText === this.initialDescriptiveText) {
        return;
      }
      const params = { app: { descriptiveText: this.descriptiveText }, appType: 'ImageApp' };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.selectedContainerId });
      this.initialDescriptiveText = this.descriptiveText;
    },
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
    },
    applyBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID);
      }
    },
    defineImageAppAttrs() {
      const params = {
        hasDescriptiveText: _.get(this.container, 'app.hasDescriptiveText', false),
        descriptiveTextOverlap: _.get(this.container, 'app.descriptiveTextOverlap', false),
        contentAlignment: _.get(this.container, 'app.contentAlignment', 'center'),
        contentAdjustment: _.get(this.container, 'app.contentAdjustment', 'none'),
        imageUrl: _.get(this.container, 'app.imageUrl', null),
        imageName: _.get(this.container, 'app.imageName', null),
        imageUpdater: 0,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    getResourceUrl(viewID) {
      return `/views/${viewID}`;
    },
  },
};
</script>
<style scoped>
.show-bg-transparent {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.app-container__content .ql-editor {
  padding: 0px 0px !important;
}

.center-elements {
  justify-content: center;
  align-items: center;
}

.top-position {
  margin-top: 12px;
  position: absolute;
  top: 0;
}

.center-adjustment {
  width: 75%;
  height: 75%;
}

.expanded-adjustment {
  width: auto;
  height: auto;
  object-fit: contain;
}

.container {
  position: relative;
}

.top-left {
  position: absolute;
  top: 0px;
  left: 0px;
}

.icon {
  color: #28C6DA;
  opacity: 1;
  font-size: 70px;
}

</style>
