<template>
  <div
    class="grid-index__item-container shadow-card rounded-md"
  >
    <div
      @click="goToShow"
      class="thumbnail-container bg-card rounded-t-md"
    >
      <img

        class="grid-index__item-img--thumbnail"
        :src="view.thumbnailUrl"
      >
    </div>
    <div
      class="grid-index__icons-container bg-white pt-3 rounded-b-lg"
    >
      <div
        :title="view.title || 'Sin título'"
        @click="goToShow"
        class="w-4/6 text-sm text-gray-700 overflow-hidden truncate"
      >
        {{ view.title || "Sin título" }}
      </div>
      <div class="w-1/6">
        <i
          v-if="isFavorited"
          @click="checkFavorite(false)"
          title="Remove from favorites"
          class="material-icons material-icons--primary cursor-pointer"
        >star</i>
        <i
          v-else
          @click="checkFavorite(true)"
          title="Add to favorites"
          class="material-icons material-icons--primary cursor-pointer"
        >star_border</i>
      </div>
      <div class="w-1/6">
        <i
          ref="dropDown"
          :id="view.uuid"
          v-if="isAdmin"
          @click="open = !open"
          class="material-icons material-icons--primary cursor-pointer float-right"
        >more_vert</i>
      </div>
      <div
        class="list__folder-view--dropdown text-sm"
        v-if="open"
      >
        <div class="list__folder-view--dropdown-content shadow-card rounded-md">
          <a
            :href="editPath"
            class="list__folder-view--dropdown-item h-12 rounded-md"
          >
            <i class="material-icons material-icons-inline pr-3">edit</i>
            <span class="align-middle">{{ $t("view.edit") }}</span>
          </a>
          <div
            class="list__folder-view--dropdown-item h-12"
            @click.stop="openMoveResourceModal"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">snippet_folder</i>
            <span class="align-middle">{{ $t("view.folder.move") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item h-12"
            @click.stop="openShareViewModal"
            v-if="!currentFolder.inteamfolder"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">share</i>
            <span class="align-middle">{{ $t("view.shareButton") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item h-12"
            @click.stop="duplicate"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">file_copy</i>
            <span class="align-middle">{{ $t("view.copy") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item h-12"
            @click.stop="implementView"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">copy_all</i>
            <span class="align-middle">{{ $t("view.copyAsTemplate") }}</span>
          </div>

          <div
            class="list__folder-view--dropdown-item h-12 rounded-b-md border-t-1 border-gray-400"
            @click.stop="openConfirmationModal"
          >
            <i class="material-icons-outlined material-icons material-icons-inline pr-3">delete</i>
            <span class="align-middle">{{ $t("view.folder.deleteShort") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  SELECT_VIEW_FOR_DELETION,
  SELECT_RESOURCE_FOR_SHARING,
  SELECT_RESOURCE_FOR_MOVING } from '../store/action-types';
import { UPDATE_SELECTED_VIEW } from '../store/mutation-types';
import showMessageMixin from '../mixins/showMessageMixin';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickMixinHandler from '../mixins/clickMixinHandler';
import api from '../api';
import imgPlaceholder from '../images/image_placeholder.svg';

export default {
  mixins: [showMessageMixin, escapeMixinHandler, clickMixinHandler],
  props: {
    view: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    currentFolder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgPlace: imgPlaceholder,
      open: false,
      isFavorited: this.view.favoritedByCurrentUser,
    };
  },
  computed: {
    showPath() {
      return `/views/${this.view.uuid}`;
    },
    editPath() {
      return `/views/${this.view.uuid}/edit`;
    },
    favoritedParams() {
      return {
        favoritedId: this.view.uuid,
        favoritedType: 'View',
      };
    },
  },
  methods: {
    checkFavorite(checkStatus) {
      if (checkStatus) {
        api.addToFavorites(this.favoritedParams)
          .then((_) => {
            this.isFavorited = checkStatus;
          });
      } else {
        api.removeFromFavorites(this.favoritedParams)
          .then((_) => {
            this.isFavorited = checkStatus;
          });
      }
    },
    openConfirmationModal() {
      this.$store.dispatch(SELECT_VIEW_FOR_DELETION, this.view.uuid);
      this.close();
    },
    openShareViewModal() {
      const options = { resource: this.view, resourceType: this.view.type };
      this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
      this.close();
    },
    openMoveResourceModal() {
      const options = { resource: this.view, resourceType: 'View' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_MOVING, options);
      this.close();
    },
    duplicate() {
      let msg;
      api.duplicateView(this.view.uuid, this.currentFolder.id).then((view) => {
        this.$store.commit(UPDATE_SELECTED_VIEW, view);
        msg = this.$t('view.duplicateSuccessMessage');
        this.showMessage(msg);
      }).catch(() => {
        msg = this.$t('view.duplicateFailedMessage');
        this.showMessage(msg);
      });
    },
    implementView() {
      let msg;
      api.implementView(this.view.uuid).then((newTemplateView) => {
        console.log('newTemplateView', newTemplateView);
        msg = this.$t('view.implementAsTemplateSuccessMessage');
        this.showMessage(msg);
      }).catch(() => {
        msg = this.$t('view.duplicateFailedMessage');
        this.showMessage(msg);
      })
    },
    goToShow() {
      window.location.href = this.showPath;
    },
    close() {
      this.open = false;
    },
  },
};
</script>
<style scoped>

.list__folder-view--dropdown {
  position: relative;
  display: inline-block;
  left: -1.5em;
}

.list__folder-view--dropdown-content {
  display: block;
  position: absolute;
  min-width: 160px;
  border: 1px solid #e2e5e9;
  /* border-radius: 0 0 4px 4px; */
  z-index: 1;
  left: 10px;
  padding-left: 1em;
  background-color: #fff;
}

.list__folder-view--dropdown-item {
  cursor: pointer;
  color: #7a7a7a;
  display: block;
  background-color: #fff;
  padding-bottom: .5em;
  padding-top: .7em;
}

.list__folder-view--dropdown-item:hover {
  color: #000;
}

.thumbnail-container {
  display: flex;
  align-items: center;
  flex: 3;
  justify-content: center;
  max-height: 168px;
  /* max-width: 206px; */
  overflow: hidden;
  object-fit: cover;
  /* background-color: #e2e5e9; */
}

</style>
