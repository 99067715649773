<template>
  <div>
    <modal
      name="users-import-modal"
      :width="498"
      :height="280"
      :scrollable="true"
      :click-to-close="false"
      :resizable="false"
    >
      <div
        class="flex flex-row border-b"
        style="height: 54.51px; margin-bottom: 20.88px;"
      >
        <div
          class="w-9/12"
          style="margin-left: 26.38px; margin-top: 15.45px;"
        >
          <i
            class="material-icons material-icons-inline add-style"
            style="color: #555859 !important;"
          >upload_file</i>
          <span
            class="new-database-title"
          >
            Carga masiva de usuarios
          </span>
        </div>
        <div
          class="w-3/12"
        >
          <div
            class="app-modal__close-btn text-right close-box"
            @click="close()"
          >
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      <div
        style="margin-left: 26.38px; margin-bottom: 9.29px;"
        class="flex flex-col"
      >
        <span
          class="database-param-font"
          style="height: 32px;"
        >
          Cargar archivo
        </span>
        <file-upload
          :key="documentUploadKey"
          class="database-value-input-param-box input-params-font"
          @loading-start="loadingFile = true"
          @loading-end="loadingFile = false"
          @uploaded="handleFileUploaded"
          :from="'file-upload'"
          :accept="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
          style="width: 100%%; margin-right: 24.15px;"
        >
          <div
            v-if="importFile"
          >
            {{ importFile }}
          </div>
          <div
            v-else
            style="color: #B3B3B3;"
          >
            Selecciona el documento a cargar...
          </div>
        </file-upload>
      </div>
      <div
        class="example-download-font cursor-pointer"
        style="margin-right: 1.56rem; margin-bottom: 9.29px;"
      >
        <button
          class="example-download-font"
          type="button"
          @click="exportToExcel"
        >
          Descarga archivo tipo aquí
        </button>
      </div>
      <div
        style="margin-left: 26.38px; margin-bottom: 9.29px;"
        class="flex flex-row"
      >
        <p class="database-param-font w-5/6">
          Enviar invitaciones por correo
        </p>
        <toggle-button
          color="#2c6eeb"
          class="w-1/6 disabled-toggle"
          :value="sendInvitations"
          :sync="true"
          @input="sendInvitations = !sendInvitations"
          :disabled="true"
        />
      </div>
      <div
        v-if="canSave()"
        class="create-database-btn create-database-btn-font"
        style="margin-top: 25.9px;"
        @click="save()"
      >
        <span>
          Cargar
        </span>
      </div>
      <div
        v-else
        class="create-database-btn create-database-btn-font create-disabled"
        style="margin-top: 25.9px;"
      >
        <span>
          Cargar
        </span>
      </div>
    </modal>
  </div>
</template>
<script src="xlsx.full.min.js"></script>
<script>

import $ from 'jquery';
import _ from 'lodash';
import { mapState } from 'vuex';
import humps from 'humps';
import 'vue2-datepicker/index.css';
import * as XLSX from 'xlsx';
import showMessageMixin from '../mixins/showMessageMixin';
import FileUpload from '../components/utils/file-upload.vue';
import {
  CLOSE_APP_MODAL,
  SELECT_CURRENT_COMPANY
} from '../store/action-types';
import {
  SET_SELECTED_CUSTOM_DATUM,
  ADD_NORMALIZED_USERS,
  ADD_TENANT_TAGS,
  SET_USERS_TAGGED
} from '../store/mutation-types';
import api from '../api';

const EXAMPLE_FIRST_NAMES = [
  'Juan',
  'Pedro',
  'Luis',
  'Carlos',
  'José'
];

const EXAMPLE_LAST_NAMES = [
  'Pérez',
  'González',
  'López',
  'García',
  'Martínez'
];

const EXAMPLE_EMAILS = [
  'juan.perez@gmail.com',
  'pedro.gonzalez@gmail.com',
  'luis.lopez@gmail.com',
  'carlos.garcia@gmail.com',
  'jose.martinez@gmail.com'
];

const EXAMPLE_PASSWORDS = [
  '13480995',
  '17543223',
  '19365184',
  '15324712',
  '12341234'
];

const EXAMPLE_ROLES = [
  'admin',
  'viewer',
  'dev',
  'owner',
  'platform_owner'
];

const EXAMPLE_TEAMS = [
  'Grupo 1, Grupo 3',
  'Grupo 1',
  'Grupo 2, Grupo 3',
  'Grupo 2',
  ''
];

export default {
  mixins: [showMessageMixin],
  components: {
    FileUpload,
    XLSX,
  },
  data() {
    return {
      values: {},
      imagesHash: {},
      filesHash: {},
      loadingFile: false,
      documentKey: 0,
      importFile: null,
      jsonData: this.constructJsonData(),
      jsonMeta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
      fileUrl: null,
      fileSignedId: null,
      sendInvitations: false,
    };
  },
  mounted() {
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
  },
  computed: {
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
      mergeUserEmail: state => state.views.mergeUserEmail,
      saveAlert: state => state.ui.saveAlert,
      booleanSelected: state => state.ui.booleanSelected,
      selectedCustomColumn: state => state.users.selectedCustomColumn,
      selectedCustomType: state => state.users.selectedCustomType,
      selectedCustomDatum: state => state.users.selectedCustomDatum,
      selectedCustomData: state => state.users.selectedCustomData,
    }),
    documentUploadKey() {
      return this.documentKey;
    },
    excelUploadParams() {
      return {
        excelUpload: {
          fileUrl: this.fileUrl,
          file: this.fileSignedId,
          name: this.importFile,
        },
        sendInvitations: this.sendInvitations,
      };
    },
  },
  methods: {
    datumParam() {
      const params = {
        userTypeDatum: {
          files: this.getSignedIds(this.filesHash),
          images: this.getSignedIds(this.imagesHash),
          dataRow: this.valuesProcessed(),
        },
      };

      return params;
    },
    valuesProcessed() {
      const columnNames = Object.keys(this.values);

      const processedData = columnNames.map(columnName => ({ columnName, value: this.values[columnName] }));

      return processedData;
    },
    close() {
      this.$store.commit(SET_SELECTED_CUSTOM_DATUM, null);
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show('users-import-modal');
    },
    handleFileUploaded({ url, fileSignedId, name }) {
      this.importFile = name;
      this.fileSignedId = fileSignedId;
      this.fileUrl = url;
      this.fileSignedId = fileSignedId;
      this.documentKey += 1;
    },
    getSignedIds(objectList) {
      return Object.keys(objectList).reduce((imgList, imgKey) => {
        imgList.push(objectList[imgKey].fileSignedId);

        return imgList;
      }, []);
    },
    save() {
      api.createUserExcelUpload(this.excelUploadParams)
        .then(result => {
          console.log('result: ', result);
          if ('users' in result) {
            const newUsers = result.users;
            const usersObject = newUsers.reduce((obj, user) => {
              obj[user.id] = user;

              return obj;
            }, {});
            if ('tags' in result && result.tags.length > 0) {
              this.$store.commit(ADD_TENANT_TAGS, result.tags);
            }
            this.$store.dispatch(SELECT_CURRENT_COMPANY, result.company);
            const normalized = result.company.usersTagged.reduce((normalObject, tagged) => {
              normalObject[tagged.tag] = tagged.users;

              return normalObject;
            }, {});
            this.$store.commit(SET_USERS_TAGGED, normalized);
            this.$store.commit(ADD_NORMALIZED_USERS, humps.camelizeKeys(usersObject));
            this.showMessage(
              'Proceso de importación finalizado con éxito!'
            );
          } else {
            this.showMessage(
              'Proceso de importación finalizado con errores, para más detalles revisa el historial de importación'
            );
          }

          this.close();
        });
    },
    canSave() {
      if (
        this.importFile
      ) {
        return true;
      }

      return false;
    },
    constructJsonData() {
      const jsonData = [];
      EXAMPLE_FIRST_NAMES.forEach((firstName, rowIndex) => {
        const row = {};
        row['Nombre'] = firstName;
        row['Apellido'] = EXAMPLE_LAST_NAMES[rowIndex];
        row['Email'] = EXAMPLE_EMAILS[rowIndex];
        row['Contraseña'] = EXAMPLE_PASSWORDS[rowIndex];
        row['Rol'] = EXAMPLE_ROLES[rowIndex];
        row['Grupos'] = EXAMPLE_TEAMS[rowIndex];
        jsonData.push(row);
      });

      return jsonData;
    },
    exportToExcel() {
      const jsonData = this.jsonData;
      const dataSheet = XLSX.utils.json_to_sheet(this.jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataSheet, 'Nuevos usuarios');
      XLSX.writeFile(wb, `Archivo ejemplo - Carga masiva de usuarios.xlsx`);
    },
  },
};
</script>

<style>

.database-value-input-param-box {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.internal-type-font {
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
  padding-left: 12.13px;
}

.internal-type-box {
  background: #C7D8F8 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.database-value-input-param-box-datepicker {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 53.87%;
  padding-top: 0.5%;
}

.input-param-datepicker-width {
  width: 100% !important;
}

/* input.add-row-datepicker[type="date"] {
  width: 100% !important;
} */

/* input {
  box-sizing     : border-box;
  vertical-align : top;
  border         : #0f0 2px solid;
  border-radius  : 5px;
  font-size      : 12px;
  height         : 32px;
  background     : #0000;
} */

.mx-datepicker {
  width: 100%;
}

.mx-datepicker-popup {
  /* height: 100%; */
}

.mx-date-row {
  text-align: center;
}

th {
  text-align: center !important;
}

.mx-calendar-content .cell.active {
  border-radius: 50%;
}

.mx-calendar-content .cell.hover {
  border-radius: 50% !important;
}

table tr {
  height: 32px;
}

.mx-calendar {
  width: 252px;
}

.cell {
  border-radius: 50%;
}

.mx-datepicker-popup {
  top: 426.255px ;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.file-uploads {
  display: inline-flex !important;
  align-items: center;
  height: 32px;
  position: none !important;
  text-align: none !important;
}

.example-download-font {
  text-align: right;
  text-decoration: underline;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.disabled-toggle {
  opacity: 0.5;
  pointer-events: none;
}
</style>
