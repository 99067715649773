var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataSource && _vm.dataSource.service ==='gsheet' && _vm.showDataSource)?_c('div',[_c('a',{staticClass:"cursor-pointer data-source-link",attrs:{"href":_vm.googleSheetUrl,"target":"_blank"}},[_vm._v("\n      fuente\n    ")])]):_vm._e(),_vm._v(" "),(_vm.container && !_vm.editable)?_c('div',{ref:"SingleValueApp",staticClass:"h-full",class:{
      'show-bg-transparent': _vm.showTransparency,
    }},[_c('div',{staticClass:"flex flex-col"},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{staticClass:"icon-bottom-padding",class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
        }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{class:{
          'flex flex-row': ['right', 'left'].includes(_vm.iconPosition),
        }},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex",class:{
            'icon-right-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(_vm.contentAlignment),
          }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col",staticStyle:{"justify-content":"center","align-items":"center"}},[(_vm.hasDescriptiveText)?_c('div',{class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },domProps:{"innerHTML":_vm._s(_vm.descriptiveText)}}):_vm._e(),_vm._v(" "),(_vm.singleValue)?_c('div',{class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },domProps:{"innerHTML":_vm._s(_vm.singleValue)}}):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex",class:{
            'icon-left-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(_vm.contentAlignment),
          }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{staticClass:"icon-top-padding",class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment)
        }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])]):_c('div',{ref:"singleValueApp",staticClass:"h-full :overflow-hidden overflow-y-scroll",class:{
      'show-bg-transparent': _vm.showTransparency,
    },attrs:{"id":"single-value-app"}},[_c('div',{staticClass:"flex flex-col",staticStyle:{"justify-content":"center","align-items":"center"}},[(_vm.hasIcon && _vm.iconPosition === 'top')?_c('div',{class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': _vm.contentAlignment === 'center',
          'icon-bottom-padding': _vm.iconPosition === 'top',
          'icon-top-padding': _vm.iconPosition === 'bottom'
        }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-row"},[(_vm.hasIcon && _vm.iconPosition === 'left')?_c('div',{staticClass:"flex",class:{
            'icon-right-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(_vm.contentAlignment),
          }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col",staticStyle:{"justify-content":"center","align-items":"center"},attrs:{"id":"editor-div"}},[(_vm.hasDescriptiveText)?_c('vue-editor',{staticClass:"flex padding-horizontal-zero",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },attrs:{"id":_vm.editorId,"editor-toolbar":_vm.customToolbar},on:{"text-change":_vm.autoSaveDescriptiveText,"focus":function($event){return _vm.toolbarShow()},"blur":function($event){return _vm.toolbarHide()}},model:{value:(_vm.descriptiveText),callback:function ($$v) {_vm.descriptiveText=$$v},expression:"descriptiveText"}}):_vm._e(),_vm._v(" "),(true)?_c('vue-editor',{staticClass:"flex padding-horizontal-zero",class:{
              'self-start text-left-padding': _vm.contentAlignment === 'left',
              'self-end text-right-padding': _vm.contentAlignment === 'right',
              'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
            },attrs:{"id":_vm.editorId2,"editor-toolbar":_vm.customToolbar},on:{"text-change":_vm.autoSaveSingleValue,"focus":function($event){return _vm.toolbarShow2()},"blur":function($event){return _vm.toolbarHide2()}},model:{value:(_vm.singleValue),callback:function ($$v) {_vm.singleValue=$$v},expression:"singleValue"}}):_vm._e()],1),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'right')?_c('div',{staticClass:"flex"},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()]),_vm._v(" "),(_vm.hasIcon && _vm.iconPosition === 'bottom')?_c('div',{class:{
          'self-start icon-left-padding': _vm.contentAlignment === 'left',
          'self-end icon-right-padding': _vm.contentAlignment === 'right',
          'self-center center-elements': ['center', 'top', 'bottom'].includes(_vm.contentAlignment),
        }},[_c('i',{staticClass:"material-icons single-value-icon",attrs:{"id":_vm.singleValueIconID}},[_vm._v(_vm._s(_vm.icon))])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }