<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">event_note</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Gantt
        </div>
      </div>
    </div>
    <div>
      <new-data-sources-selector
        :current-datasource="selectedDataSource"
        :custom-app="false"
        :show-from-system-databases="true"
        :tooltip-file-uri="tooltipF"
        :container="container"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex items-center"
        >
          <div class="w-7/12 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Actividad
          </div>
          <input
            v-model="activityCell"
            class="w-2/12 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: B"
          >
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-7/12 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Fecha Comienzo
          </div>
          <input
            v-model="startDateCell"
            class="w-2/12 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: C"
          >
        </div>
        <div
          class="flex items-center inter-select-title"
        >
          <div class="w-7/12 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Fecha Término
          </div>
          <input
            v-model="endDateCell"
            class="w-2/12 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: D"
          >
        </div>
        <div
          class="flex items-center inter-select-title mb-4"
        >
          <div class="w-7/12 text-left text-menu-label text-sm font-inter-type-1 cells2">
            Progreso
          </div>
          <input
            v-model="progressCell"
            class="w-2/12 text-center text-sm font-inter-type-1 cells"
            placeholder="Ej: E"
          >
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex flex-row">
            <div class="text-left text-menu-label text-sm font-inter-type-1 inter-sub-section">
              Escala
            </div>
            <input
              class="scale"
              type="range"
              v-model="scale"
              name="scale"
              min="75"
              max="100"
              @input="scaleApp"
            >
          </div>
          <div
            v-show="true"
          >
            <div class="text-left text-menu-label text-sm font-inter-type-1 inter-sub-section mt-4">
              Título
            </div>
            <div
              class="inter-select-title vue-select-container"
              style="margin-bottom: 10px;"
            >
              <input
                v-model="ganttTitle"
                type="text"
                class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
                placeholder="Título de la app..."
              >
            </div>
          </div>
          <template>
            <div
              class="flex mt-4"
              style="padding-bottom: 20px;"
            >
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                Mostrar título
              </p>
              <toggle-button
                color="#2c6eeb"
                class="w-2/6"
                :value="showTitle"
                :sync="true"
                @input="showTitle = !showTitle"
              />
            </div>
          </template>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
          :has-show-search="true"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import $ from 'jquery';
import Collapsible from 'vue-collapsible-component';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import {
  SAVE_CONTAINER,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import NewDataSourcesSelector from './new-data-sources-selector';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import {
  CONTAINER_COLORS_WITH_TRANSPARENT,
  APP_ICONS,
  APP_TYPES,
} from '../constants';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const INFLECTION_POINT_IN_SCALE = 100;

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    Collapsible,
    NewDataSourcesSelector,
    ContainerSection,
    AppTitleInOptions,
  },
  props: {
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const currentDataSource = this.$store.state.users.selectedDataSource;
    const columns = this.processColumnsData(_.get(container, 'app.fieldValues', null));

    return {
      template,
      container,
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      showShading: _.get(container, 'showShading', false),
      showSearch: _.get(container, 'showSearch', true),
      showDataSource: _.get(container, 'showDataSource', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      maxLengthForFilterStatement: 24,
      maxLengthOfColumnPartForFilter: 4,
      currentDataSource,
      containerID: _.get(container, 'id', -1),
      activityCell: columns.taskColumn,
      progressCell: columns.progressColumn,
      startDateCell: columns.startDateColumn,
      endDateCell: columns.endDateColumn,
      showTitle: columns.showTitle,
      ganttTitle: columns.ganttTitle,
      scale: columns.scale,
      triggerStyles: {
        width: '22px',
        height: '22px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
      },
      swatchStyles: {
        width: '23px',
        height: '24px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
        boxSizing: 'content-box',
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '3px',
        marginBottom: '3px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      initialTitle: _.get(container, 'title', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowSearch: _.get(container, 'showSearch', true),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTitle: columns.showTitle,
      initialActivityCell: columns.taskColumn,
      initialProgressCell: columns.progressColumn,
      initialStartDateCell: columns.startDateColumn,
      initialEndDateCell: columns.endDateColumn,
      initialGanttTitle: columns.ganttTitle,
      initialScale: columns.scale,
      initialDataSource: currentDataSource,
      appTypes: APP_TYPES,
      appIcons: APP_ICONS,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      tooltipF: 'https://docs.google.com/spreadsheets/d/1NiuRh6MewSiWN1EN_hEzHPaLQza7MTEe_MNXfbCQp5g/edit?usp=sharing',
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    polymorphicAppIdJS() {
      return `#${this.polymorphicAppID}`;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    appContainerID() {
      return `app-container${this.containerID}`;
    },
    draggableIdJS() {
      return `#${this.draggableID}`;
    },
    constructColumnsData() {
      return {
        taskColumn: this.activityCell,
        progressColumn: this.progressCell,
        startDateColumn: this.startDateCell,
        endDateColumn: this.endDateCell,
        showSearch: this.showSearch,
        backgroundColor: this.containerBackgroundColor,
        showTitle: this.showTitle,
        ganttTitle: this.ganttTitle,
        scale: this.scale };
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        showDataSource: this.showDataSource,
        showSearch: this.showSearch,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          dataSource: this.selectedDataSource,
          customAppTemplateId: this.template.id,
          fieldValues: this.constructColumnsData,
        },
        appType: 'CustomApp',
      };
    },
    canSave() {
      const canSave = !!(this.requiredAppInputsFilled);
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    cellsAnalysis() {
      return (this.initialActivityCell !== this.activityCell ||
        this.initialProgressCell !== this.progressCell ||
        this.initialStartDateCell !== this.startDateCell ||
        this.initialEndDateCell !== this.endDateCell);
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTitle !== this.showTitle ||
        this.initialGanttTitle !== this.ganttTitle ||
        this.initialScale !== this.scale ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        this.initialShowSearch !== this.showSearch ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialShowBorders !== this.showBorders ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) ||
        this.cellsAnalysis
      ) {
        return true;
      }

      return false;
    },
    cellsFilled() {
      const fields = [this.activityCell, this.progressCell, this.startDateCell, this.endDateCell];

      return !(fields.includes(null) || fields.includes(''));
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (['gsheet', 'excel'].includes(service)) {
          if (dataSourceInAnalysis.name !== null &&
              ['table', 'excel'].includes(dataSourceInAnalysis.dataSourceType) &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'custom') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table_internal' &&
              !['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataSourceFilename) &&
              dataSourceInAnalysis.source_id !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone && this.cellsFilled && this.changesToSend;
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      selectedDataSource: state => state.users.selectedDataSource,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    processColumnsData(columnsData) {
      if (columnsData && 'scale' in columnsData) {
        return columnsData;
      }

      return {
        taskColumn: 'B', startDateColumn: 'C', endDateColumn: 'D', progressColumn: 'E',
        showTitle: false, ganttTitle: null, scale: '100' };
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
      this.resetInitialValues();
      const params = {
        dataSource: this.selectedDataSource,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    resetCells() {
      this.initialActivityCell = this.activityCell;
      this.initialProgressCell = this.progressCell;
      this.initialStartDateCell = this.startDateCell;
      this.initialEndDateCell = this.endDateCell;
    },
    resetInitialValues2() {
      this.initialShowTitle = this.showTitle;
      this.initialGanttTitle = this.ganttTitle;
      this.initialScale = this.scale;
      this.initialShowDataSource = this.showDataSource;
      this.resetCells();
    },
    resetInitialValues() {
      this.initialDataSource = this.selectedDataSource;
      this.initialTitle = this.title;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.initialShowShading = this.showShading;
      this.initialShowSearch = this.showSearch;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTitle = this.showTitle;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    scaleApp() {
      const scaleValue = parseInt(this.scale, 10);
      if (scaleValue < INFLECTION_POINT_IN_SCALE) {
        $(this.draggableIdJS).css({
          backgroundColor: this.containerBackgroundColor,
        });
      }
      const scaleTransform = scaleValue / INFLECTION_POINT_IN_SCALE;

      $(this.polymorphicAppIdJS).css({
        transform: `scale(${scaleTransform})`,
      });
    },
    updateAppAttrs() {
      const params = {
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
      this.showSearch = params.showSearch;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  width: 61.15px;
  height: 32px;
}

.cells2 {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  color: black;
  border: 0.5px solid #7A7A7A;
  border-right-style: none;
  border-radius: 3px 0 0 3px;
  opacity: 1;
  height: 32px;
  padding-top: 5.4px;
  padding-left: 10px;
  letter-spacing: 0px;
  color: #555859;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}

/* FILTERS SECTION STYLE start*/
.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}
/* FILTERS SECTION STYLE end*/

.scrollable-toolbar-item-option {
  padding-bottom: 90px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}

.scale {
  background: #B3B3B3 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

input[type=range]:focus {
  outline: none;
}

input[type=range] {
  width: 138px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: 0;
  margin: 10px;
  padding: 0;
  height: 3.62px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 9.74px;
  width: 9.74px;
  border-radius: 50%;
  background: #555859;
}

</style>
