<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">add_box</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Archivo
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              DOCUMENTOS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              DOCUMENTOS
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div
            class="flex flex-col"
            v-for="(fileName, index) in fileNames[containerID]"
            :key="'file-' + index"
          >
            <div class="flex">
              <p
                class="add-file"
                style="text-decoration: none;"
              >
                {{ 'ARCHIVO ' + (index + 1).toString() }}
              </p>
              <div
                class="flex flex-row"
                style="margin-left: 113px;"
              >
                <i
                  class="w-1/6 material-icons delete-outline-icon cursor-pointer"
                  style="font-size: 1.3em; padding-top: 2px;"
                  @click="deleteFileElementsByIndex(index)"
                >delete_outline</i>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="flex flex-col pr-6">
                <div>
                  <file-upload
                    class="text-left text-sm font-inter-type-1 cells"
                    style="padding-top: 7px; padding-left: 12px;"
                    @loading-start="loadingFile = true"
                    @loading-end="loadingFile = false"
                    @uploaded="handleFileUploaded"
                    from="file-app"
                  >
                    <div class="text-left text-sm font-inter-type-1">
                      {{ fileName || 'Carga documento...' }}
                    </div>
                  </file-upload>
                </div>
              </div>
            </div>
          </div>
          <div
            class="inter-select-title text-left"
            style="margin-bottom: 13.86px;"
          >
            <a
              class="add-file cursor-pointer"
              style="text-decoration: none;"
              @click="addFile"
            >+ AGREGAR ARCHIVO
            </a>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex mb-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Título
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="hasDescriptiveText"
              :sync="true"
              @input="hasDescriptiveText = !hasDescriptiveText; updateAppAttributes();"
            />
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';
import Collapsible from 'vue-collapsible-component';
import api from '../api';
import FileUpload from '../components/utils/file-upload.vue';
import showMessageMixin from '../mixins/showMessageMixin';
import { CONTAINER_COLORS, CONTAINER_COLORS_WITH_TRANSPARENT } from '../constants';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import {
  SAVE_CONTAINER,
  UPDATE_FILENAMES,
  UPDATE_FILE_URLS,
  UPDATE_FILENAMES_IN_APP,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

export default {
  mixins: [showMessageMixin],
  components: {
    FileUpload,
    Collapsible,
    ContainerSection,
    AppTitleInOptions,
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    const fileNames = _.get(container, 'app.fileNames', []).map(fileName => (fileName.replace(/<(.|\n)*?>/g, '')));
    const fileUrls = _.get(container, 'app.fileUrls', []);
    const fileSignedIds = _.get(container, 'app.fileSignedIds', []);
    const filenamesInApp = JSON.parse(JSON.stringify(_.get(container, 'app.filenamesInApp', [])));
    const initialFileNames = Object.assign([], fileNames);
    const initialFileUrls = Object.assign([], fileUrls);
    const initialFileSignedIds = Object.assign([], fileSignedIds);
    this.setFilenamesAndUrls(fileNames, fileUrls, filenamesInApp, container);

    return {
      container,
      fileSignedIds,
      borderColor: _.get(container, 'borderColor', ''),
      showShading: _.get(container, 'showShading', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      showTransparency: _.get(container, 'showTransparency', false),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      hasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false),
      files: _.get(container, 'app.files', []),
      containerID: _.get(container, 'id', -1),
      loadingFile: false,
      iconColors: CONTAINER_COLORS,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: {
        width: '22px',
        height: '22px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
      },
      swatchStyles: {
        width: '23px',
        height: '24px',
        border: '0.1px solid #707070',
        borderRadius: '3px',
        opacity: '1',
        boxSizing: 'content-box',
        marginLeft: '3px',
        marginRight: '3px',
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '3px',
        marginBottom: '3px',
        paddingTop: '0px',
        paddingBottom: '0px',
      },
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTransparency: _.get(container, 'showTransparency', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialHasDescriptiveText: _.get(container, 'app.hasDescriptiveText', false),
      initialFileUrls,
      initialFileNames,
      initialFileSignedIds,
    };
  },
  mounted() {
    if (this.fileNames[this.containerID].length === 0) {
      this.addFile();
    }
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      fileNames: state => state.ui.fileNames,
      fileUrls: state => state.ui.fileUrls,
      filenamesInApp: state => state.ui.filenamesInApp,
      shouldSaves: state => state.views.shouldSaves,
    }),
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        borderColor: this.borderColor,
        isPrivate: this.isPrivate,
        showTransparency: this.showTransparency,
        showShading: this.showShading,
        backgroundColor: this.containerBackgroundColor,
        ...this.appWithType,
      };
    },
    appWithType() {
      const fileUrls = this.fileUrls[this.containerID].filter(x => !this.initialFileUrls.includes(x) && x !== '');

      const uniqueFileUrls = [...new Set(fileUrls)];
      const container = {
        app: {
          hasDescriptiveText: this.hasDescriptiveText,
          fileUrls: uniqueFileUrls,
          filenamesInApp: this.filenamesInApp[this.containerID],
        },
        appType: 'FileApp',
      };
      if (this.fileSignedIds) {
        const fileSignedIds = this.fileSignedIds.filter(x => !this.initialFileSignedIds.includes(x) && x !== '');
        const uniqueFileSignedIds = [...new Set(fileSignedIds)];
        container.app.files = uniqueFileSignedIds;
      }

      return container;
    },
    appInputsReady() {
      return !!(this.fileUrl && !this.loadingFile);
    },
    requiredAppInputsFilled() {
      return this.changesToSend() && !this.loadingFile;
    },
    canSave() {
      const canSave = this.requiredAppInputsFilled;
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
  },
  methods: {
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialHasDescriptiveText !== this.hasDescriptiveText ||
        JSON.stringify(this.initialFileUrls) !== JSON.stringify(this.fileUrls[this.containerID]) ||
        JSON.stringify(this.initialFileNames) !== JSON.stringify(this.fileNames[this.containerID]) ||
        JSON.stringify(this.initialFileSignedIds) !== JSON.stringify(this.fileSignedIds)
      ) {
        return true;
      }

      return false;
    },
    resetInitialValues2() {
      this.initialFileNames = Object.assign([], this.fileNames[this.containerID]);
      this.initialFileUrls = Object.assign([], this.fileUrls[this.containerID]);
      this.initialFileSignedIds = Object.assign([], this.fileSignedIds);
    },
    resetInitialValues() {
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialBorderColor = this.borderColor.toLowerCase();
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTransparency = this.showTransparency;
      this.initialShowShading = this.showShading;
      this.initialContainerBackgroundColor = this.containerBackgroundColor.toLowerCase();
      this.initialHasDescriptiveText = this.hasDescriptiveText;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
      this.resetInitialValues();
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      const response = await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      console.log(response);
      this.saveMessage();
    },
    setFilenamesAndUrls(initialFileNames, initialFileUrls, filenamesInApp, container) {
      const params = { containerId: container.id, value: initialFileNames };
      this.$store.dispatch(UPDATE_FILENAMES, { params });
      params.value = initialFileUrls;
      this.$store.dispatch(UPDATE_FILE_URLS, { params });
      params.value = filenamesInApp;
      this.$store.dispatch(UPDATE_FILENAMES_IN_APP, { params });
    },
    addFilename(index, filename) {
      const newFilenames = Object.assign([], this.fileNames[this.containerID]);
      newFilenames.splice(index, 0, filename);
      const params = { containerId: this.containerID, value: newFilenames };
      this.$store.dispatch(UPDATE_FILENAMES, { params });
    },
    addFilenameInApp(index, filenameInApp) {
      const newFilenamesInApp = Object.assign([], this.filenamesInApp[this.containerID]);
      newFilenamesInApp.splice(index, 0, filenameInApp);
      const params = { containerId: this.containerID, value: newFilenamesInApp };
      this.$store.dispatch(UPDATE_FILENAMES_IN_APP, { params });
    },
    addFileUrl(index, url) {
      const newFileUrls = Object.assign([], this.fileUrls[this.containerID]);
      newFileUrls.splice(index, 0, url);
      const params = { containerId: this.containerID, value: newFileUrls };
      this.$store.dispatch(UPDATE_FILE_URLS, { params });
    },
    deleteFilenameByIndex(index) {
      const newFilenames = Object.assign([], this.fileNames[this.containerID]);
      newFilenames.splice(index, 1);
      const params = { containerId: this.containerID, value: newFilenames };
      this.$store.dispatch(UPDATE_FILENAMES, { params });
    },
    deleteFilenameInAppByIndex(index) {
      const newFilenamesInApp = Object.assign([], this.filenamesInApp[this.containerID]);
      newFilenamesInApp.splice(index, 1);
      const params = { containerId: this.containerID, value: newFilenamesInApp };
      this.$store.dispatch(UPDATE_FILENAMES_IN_APP, { params });
    },
    deleteFileUrlByIndex(index) {
      const newFileUrls = Object.assign([], this.fileUrls[this.containerID]);
      newFileUrls.splice(index, 1);
      const params = { containerId: this.containerID, value: newFileUrls };
      this.$store.dispatch(UPDATE_FILE_URLS, { params });
    },
    async deleteFileElementsByIndex(index) {
      const signedId = this.fileSignedIds[index];
      if (this.initialFileSignedIds.includes(signedId)) {
        const params = {
          fileAppId: _.get(this.container, 'app.id', -1),
          signedId,
        };
        await api.deleteFileInFileApp(params).then(() => {
          this.showMessage('Archivo eliminado exitosamente');
        });
      }
      const fileSignedIdsCopy = Object.assign([], this.fileSignedIds);
      fileSignedIdsCopy.splice(index, 1);
      this.fileSignedIds = fileSignedIdsCopy;
      this.deleteFilenameByIndex(index);
      this.deleteFileUrlByIndex(index);
      this.deleteFilenameInAppByIndex(index);
    },
    handleFileUploaded({ url, fileSignedId, name }) {
      const index = this.fileNames[this.containerID].indexOf('');
      this.deleteFileElementsByIndex(index);
      this.addFilename(index, name);
      this.addFilenameInApp(index, name);
      this.addFileUrl(index, url);
      const fileSignedIdsCopy = Object.assign([], this.fileSignedIds);
      fileSignedIdsCopy.splice(index, 0, fileSignedId);
      this.fileSignedIds = fileSignedIdsCopy;
    },
    addFile() {
      if (this.fileNames[this.containerID].includes('')) {
        this.showMessage('Por favor, adjunta todos los archivos que tienes agregados antes de continuar');

        return;
      }
      const index = this.fileNames[this.containerID].length;
      this.addFilename(index, '');
      this.addFileUrl(index, '');
      this.addFilenameInApp(index, '');
      const fileSignedIdsCopy = Object.assign([], this.fileSignedIds);
      fileSignedIdsCopy.splice(index, 0, '');
      this.fileSignedIds = fileSignedIdsCopy;
    },
    changeSwatchesContainer(elementID) {
      const element1 = `${elementID} .vue-swatches__container`;
      $(element1).attr(
        'style',
        `width: 126px !important; height: 164px !important; inset: 30px 0px 0px -100px !important; 
        background-color: rgb(255, 255, 255) !important;
        border: 0.5px solid #707070 !important; border-radius: 2px !important;
        opacity: 1 !important; box-shadow: none !important;
        padding: 0px !important; margin-bottom: 0px !important;`,
      );
    },
    changeSwatchesWrapper(elementID) {
      const element2 = `${elementID} .vue-swatches__wrapper`;
      $(element2).attr('style',
        'width: 126px !important; margin-bottom: 5px !important; margin-top: 6px !important;');
      const element3 = `${elementID} .vue-swatches__fallback__wrapper`;
      $(element3).attr(
        'style',
        'width: 126px !important; padding-top: 20px !important; display: inline !important;',
      );
    },
    changeSwatchesFallbackButton(elementID) {
      const element4 = `${elementID} .vue-swatches__fallback__button`;
      $(element4).attr(
        'style',
        `width: 29px !important; height: 20px !important; font: normal normal bold 9px/37px Inter!important;
        text-align: center !important; letter-spacing: 0px !important;
        opacity: 1 !important; background-color: #2C6EEB !important; border-radius: 2px !important; 
        align-items: center !important; justify-content: center !important;
        margin-right: 0px !important; color: white !important; width: 20% !important; 
        display: inline-flex !important; align-items: center !important;
        justify-content: center !important; position: absolute !important; 
        transform: translate(26px, 0px) !important;`,
      );
      $(element4).removeClass('vue-swatches__fallback__button');
    },
    changeSwatchesFallbackInput(elementID) {
      const element5 = `${elementID} .vue-swatches__fallback__input`;
      $(element5).attr(
        'style',
        `height: 13px !important; width: 80px !important; position: absolute !important; 
        transform: translate(-52px, -13px) !important;
        padding-top: 1.5px !important; padding-bottom: 1.5px !important; 
        background-color: white !important; 
        border: 0.5px solid #707070 !important; border-radius: 2px !important;`,
      );
      $(element5).removeClass('vue-swatches__fallback__input');
    },
    changeSize(elementID) {
      this.changeSwatchesContainer(elementID);
      this.changeSwatchesWrapper(elementID);
      this.changeSwatchesFallbackButton(elementID);
      this.changeSwatchesFallbackInput(elementID);
    },
    updateAppAttributes() {
      const params = {
        hasDescriptiveText: this.hasDescriptiveText,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.arrows-padding {
  padding-top: 26.88px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.shape-box {
  background: #2C6EEB 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  font: normal normal bold 10px/24px Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  height: 23px;
  width: 98.2px;
}

.straight-shape-box {
  border-radius: 3px;
}

.round-shape-box {
  border-radius: 12px;
}

.radio-btn-size {
  height: 16px;
  width: 16px;
}

.transparent-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 193px;
  height: 32px;
}

.vue-select-container {
  height: 42px;
}

.add-file {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.delete-outline-icon {
  color: #2C6EEB;
  opacity: 1;
  width: 30px;
  height: 30px;
}

.scrollable-toolbar-item-option {
  padding-bottom: 60px;
}

</style>
