const CHECK_DELAY = 100;
const URL = 'https://apis.google.com/js/api.js';
function loadGAPIScript(gapiUrl) {
  return new Promise(((resolve, reject) => {
    const script = document.createElement('script');
    script.src = gapiUrl;
    script.onreadystatechange = script.onload = function () {
      const interval = setInterval(() => {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          clearInterval(interval);
          resolve();
        }
      }, CHECK_DELAY);
    };
    script.onerror = function (message, url, line, error) {
      reject({ message, url, line, error });
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }));
}
const gapiPromise = loadGAPIScript(URL);
export default gapiPromise;
