import interact from 'interactjs';
import panzoom from 'panzoom';
import { eventBus } from '../packs/application';

const HALF_RECT = 2;
const EDGE_SIZE = 5;
const DEFAULT_CONTAINER_MIN_WIDTH = 70;
const DEFAULT_CONTAINER_MIN_HEIGHT = 70;

export default {
  data() {
    return {
      zoomInstance: null,
    };
  },
  mounted() {
    const containerElement = document.getElementById('view-editor-container');
    if (containerElement) {
      this.zoomInstance = panzoom(containerElement, {
        smoothScroll: false,
        maxZoom: 1,
        minZoom: 0.1,
        beforeMouseDown(e) {
          const shouldIgnore = e.altKey || e.metaKey;

          return !shouldIgnore;
        },
        beforeWheel(e) {
          // allow wheel-zoom only if altKey is down. Otherwise - ignore
          const shouldIgnore = e.altKey || e.metaKey;

          return !shouldIgnore;
        },
        filterKey(/* e, dx, dy, dz */) {
          // don't let panzoom handle this event:
          return true;
        },
      });
    }
  },
  destroyed() {
    this.zoomInstance.dispose();
  },
  methods: {
    isInViewport(bounds) {
      const innerHeight = (window.innerHeight || window.document.clientHeight);
      const innerWidth = (window.innerWidth || window.document.clientWidth);

      return (bounds.top >= 0 && bounds.left >= 0 &&
              bounds.bottom <= innerHeight && bounds.right <= innerWidth);
    },
    outsideBottomViewport(element) {
      const bounds = element.getBoundingClientRect();
      const innerWidth = (window.innerWidth || window.document.clientWidth);
      if (!this.isInViewport(bounds) && (bounds.top >= 0 && bounds.right <= innerWidth && bounds.left >= 0)) {
        return true;
      }

      return false;
    },
    makeFloating(element) {
      interact(element)
        .on('dragstart', this.dragStartListener)
        .draggable({
          modifiers: [
          ],
          onmove: this.dragMoveListener,
        });
    },
    makeDraggableAndResizable(element) {
      interact(element).unset();
      interact.dynamicDrop(true);
      interact(element)
        .draggable({
          origin: 'parent',
          ignoreFrom: '#text-editor',
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.snappers.grid({ x: 30, y: 30 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
            interact.modifiers.restrict({
              restriction: document.getElementById('view-editor-container'),
              elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
              endOnly: true,
            }),
          ],
          onmove: this.dragMoveListener,
        })
        .on('dragstart', this.dragStartListenerOverlay)
        .on('dragend', this.handleDropEventListener)
        .resizable({
          origin: 'parent',
          modifiers: [
            interact.modifiers.snapSize({
              targets: [
                interact.snappers.grid({ x: 30, y: 30 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),

            interact.modifiers.restrictEdges({
              outer: document.getElementById('view-editor-container'),
            }),

            interact.modifiers.restrictSize({
              min: { width: DEFAULT_CONTAINER_MIN_WIDTH, height: DEFAULT_CONTAINER_MIN_HEIGHT },
            }),
          ],
          preserveAspectRatio: true,
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('resizemove', this.resizeMoveListener)
        .on('resizeend', this.handleDropEventListener);
    },
    makeDraggableAndResizable2(element) {
      interact(element).unset();
      interact.dynamicDrop(true);
      interact(element)
        .draggable({
          origin: 'parent',
          ignoreFrom: '#text-editor',
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.snappers.grid({ x: 105, y: 105 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
            interact.modifiers.restrict({
              restriction: document.getElementById('view-editor-container'),
              elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
              endOnly: true,
            }),
          ],
          onmove: this.dragMoveListener,
        })
        .on('dragstart', this.dragStartListenerOverlay)
        .on('dragend', this.handleDropEventListener)
        .resizable({
          origin: 'parent',
          modifiers: [
            interact.modifiers.snapSize({
              targets: [
                interact.snappers.grid({ x: 35, y: 35 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),

            interact.modifiers.restrictEdges({
              outer: document.getElementById('view-editor-container'),
            }),

            interact.modifiers.restrictSize({
              min: { width: DEFAULT_CONTAINER_MIN_WIDTH, height: DEFAULT_CONTAINER_MIN_HEIGHT },
            }),
          ],
          preserveAspectRatio: true,
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('resizemove', this.resizeMoveListener)
        .on('resizeend', this.handleDropEventListener);
    },

    makeManuallyDraggable(element) {
      interact(element)
        .on('dragstart', this.dragStartListener)
        .draggable({
          manualStart: true,
          modifiers: [
            interact.modifiers.snap({
              targets: [
              ],
              range: Infinity,
            }),
          ],
          onmove: this.dragMoveListener,
        })
        .on('move', this.dragManuallyStartedListener)
        .on('dragend', this.handleDropEventListener);
    },
    makeResizable(element) {
      interact(element)
        .resizable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
              ],
              range: Infinity,
            }),
            interact.modifiers.restrictSize({
              min: { width: 70, height: 70 },
            }),
          ],
          preserveAspectRatio: true,
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('resizemove', this.resizeMoveListener);
    },
    makeInteractive(element) {
      interact(element)
        .draggable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: 30, y: 30 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0.5, y: 0.5 }],
            }),
          ],
          onmove: this.dragMoveListener,
        })
        .resizable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: 30, y: 30 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
            interact.modifiers.restrictSize({
              min: { width: 200, height: 150 },
            }),
          ],
          preserveAspectRatio: true,
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('resizemove', this.resizeMoveListener);
    },
    makeInteractive2(element) {
      interact(element)
        .draggable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: 105, y: 105 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0.5, y: 0.5 }],
            }),
          ],
          onmove: this.dragMoveListener,
        })
        .resizable({
          modifiers: [
            interact.modifiers.snap({
              targets: [
                interact.createSnapGrid({ x: 105, y: 105 }),
              ],
              range: Infinity,
              relativePoints: [{ x: 0, y: 0 }],
            }),
            interact.modifiers.restrictSize({
              min: { width: 105, height: 105 },
            }),
          ],
          preserveAspectRatio: true,
          edges: { left: true, right: true, bottom: true, top: true },
        })
        .on('resizemove', this.resizeMoveListener);
    },

    defineDropZoneContainer(element) {
      interact(element).dropzone({
        accept: '.droppeable-app ',
        overlap: 0.75,
        ondrop: this.handleDropEventListener,
      });
    },
    otherPosition(element) {
      const style = window.getComputedStyle(element);
      const top = parseFloat(style.top.split('px')[0]);
      const left = parseFloat(style.left.split('px')[0]);
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.webkitTransform);
      const translateX = matrix.m41;
      const translateY = matrix.m42;

      return {
        x: left + translateX,
        y: top + translateY,
        width: element.offsetWidth,
        height: element.offsetHeight,
      };
    },
    dragTargetPosition(event) {
      const style = window.getComputedStyle(event.target);
      const top = parseFloat(style.top.split('px')[0]);
      const left = parseFloat(style.left.split('px')[0]);
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.webkitTransform);
      const translateX = matrix.m41;
      const translateY = matrix.m42;

      return {
        x: left + translateX + event.dx,
        y: top + translateY + event.dy,
        width: event.target.offsetWidth,
        height: event.target.offsetHeight,
      };
    },
    resizeTargetPosition(event) {
      const style = window.getComputedStyle(event.target);
      const top = parseFloat(style.top.split('px')[0]);
      const left = parseFloat(style.left.split('px')[0]);
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.webkitTransform);
      const translateX = matrix.m41;
      const translateY = matrix.m42;

      return {
        x: left + translateX + event.deltaRect.left,
        y: top + translateY + event.deltaRect.top,
        width: event.rect.width,
        height: event.rect.height,
      };
    },

    collides(target, event) {
      const boxes = document.getElementsByClassName('droppeable-app');
      for (const box of boxes) {
        if (box === event.target) {
          continue;
        }
        const other = this.otherPosition(box);

        const collisionX = Math.max(target.x + target.width, other.x + other.width) -
          Math.min(target.x, other.x) < target.width + other.width;
        const collisionY = Math.max(target.y + target.height, other.y + other.height) -
          Math.min(target.y, other.y) < target.height + other.height;

        if (collisionX && collisionY) {
          return true;
        }
      }

      return false;
    },
    dragMoveListener(event) {
      if (this.$store.state.ui.textAppBeenEdited && !this.selected) {
        return;
      }
      const targetPos = this.dragTargetPosition(event);

      const targets = document.getElementsByClassName('selected');

      Array.prototype.forEach.call(targets, (target) => {
        if (!this.collides(targetPos, event)) {
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          target.style.webkitTransform =
          target.style.transform =
            `translate(${x}px, ${y}px)`;

          // update the position attributes
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
      });
    },
    resizeMoveListener(event) {
      // update element's style
      let { target, x, y } = this.getTargetAndCoordinates(event);
      const targetPos = this.resizeTargetPosition(event);
      if (!this.collides(targetPos, event)) {
        target.style.width = `${event.rect.width}px`;
        target.style.height = `${event.rect.height}px`;
        // translate when resizing from top to left edges
        x += event.deltaRect.left;
        y += event.deltaRect.top;
        target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`;
        target.setAttribute('data-x', x);
        target.setAttribute('data-y', y);
      }
    },
    getTargetAndCoordinates(event) {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-x')) || 0);
      const y = (parseFloat(target.getAttribute('data-y')) || 0);

      return { target, x, y };
    },
    getStartCoordinates(event) {
      const target = event.target;
      const x = (parseFloat(target.getAttribute('data-start-x')) || 0);
      const y = (parseFloat(target.getAttribute('data-start-y')) || 0);
      const container = document.querySelector('.view-editor__workspace-editor');
      const box = container.getBoundingClientRect();

      return { x: x - Math.abs(box.x), y };
    },
    getAbsoluteCoordinates(elem) {
      const box = elem.getBoundingClientRect();

      return {
        x1: box.left + window.pageXOffset,
        y1: box.top + window.pageYOffset,
      };
    },
    dragStartListener(event) {
      if (!event.target.hasAttribute('data-start-x')) {
        const rect = interact.getElementRect(event.target);
        console.log(event.screenX);
        console.log(event.screenY);
        event.target.setAttribute('data-start-x', rect.left + rect.width / HALF_RECT);
        event.target.setAttribute('data-start-y', rect.top + rect.height / HALF_RECT);
      }
      if (typeof this.handleDragEvent === 'function') this.handleDragEvent(event);
    },
    dragStartListenerOverlay(event) {
      const overlayableElement = event.target.querySelector('#overlayable');
      if (overlayableElement !== null) {
        const invisibleOverLay = document.createElement('div');
        invisibleOverLay.classList.add('absolute', 'inset-0', 'opacity-0');
        invisibleOverLay.setAttribute('id', 'renderer-overlay');
        overlayableElement.appendChild(invisibleOverLay);
      }
    },
    dragManuallyStartedListener(event) {
      const rect = interact.getElementRect(event.target);
      event.target.setAttribute('data-start-x', rect.left + rect.width / HALF_RECT);
      event.target.setAttribute('data-start-y', rect.top + rect.height / HALF_RECT);

      if (typeof this.handleDragEventManually === 'function') this.handleDragEventManually(event);
    },
    handleDropEventListener(event) {
      const overlayableElement = event.target.querySelector('#overlayable');
      if (overlayableElement !== null) {
        const invisibleOverLay = overlayableElement.querySelector('#renderer-overlay');
        if (invisibleOverLay !== null) overlayableElement.removeChild(invisibleOverLay);
      }
      eventBus.$emit('updatePosition', event);
    },

    // eslint-disable-next-line max-statements
    handleMouseMove(event) {
      const viewPortX = event.clientX;
      const viewPortY = event.clientY;
      console.log('viewPortX', viewPortX);
      console.log('viewPortY', viewPortY);

      const viewportWidth = document.documentElement.clientWidth;
      const viewportHeight = document.documentElement.clientHeight;

      console.log('viewportWidth', viewportWidth);
      console.log('viewportHeight', viewportHeight);

      const edgeTop = EDGE_SIZE;
      const edgeLeft = EDGE_SIZE;
      const edgeBottom = (viewportHeight - EDGE_SIZE);
      const edgeRight = (viewportWidth - EDGE_SIZE);

      const isInLeftEdge = (viewPortX < edgeLeft);
      const isInRightEdge = (viewPortX > edgeRight);
      const isInTopEdge = (viewPortY > edgeTop);
      const isInBottomEdge = (viewPortY < edgeBottom);
      console.log('isInLeftEdge', isInLeftEdge);
      console.log('isInRightEdge', isInRightEdge);
      console.log('isInTopEdge', isInTopEdge);
      console.log('isInBottomEdge', isInBottomEdge);

      if (!(isInLeftEdge || isInRightEdge || isInTopEdge || isInBottomEdge)) {

        return;
      }
      const documentWidth = this.documentWidth();
      const documentHeight = this.documentHeight();

      const maxScrollX = (documentWidth - viewportWidth);
      const maxScrollY = (documentHeight - viewportHeight);

      this.adjustWindowScroll({
        maxScrollX,
        maxScrollY,
        isInLeftEdge,
        isInRightEdge,
        isInTopEdge,
        isInBottomEdge,
        viewPortX,
        viewPortY,
        edgeLeft,
        edgeTop,
        edgeRight,
        edgeBottom,
      },
      )
      ;
    },
    // eslint-disable-next-line max-params
    // eslint-disable-next-line max-statements
    adjustWindowScroll({
      maxScrollX,
      maxScrollY,
      isInLeftEdge,
      isInRightEdge,
      isInTopEdge,
      isInBottomEdge,
      viewPortX,
      viewPortY,
      edgeLeft,
      edgeTop,
      edgeRight,
      edgeBottom,
    },
    ) {
      const currentScrollX = window.pageXOffset;
      const currentScrollY = window.pageYOffset;

      const canScrollUp = (currentScrollY > 0);
      const canScrollDown = (currentScrollY < maxScrollY);
      const canScrollLeft = (currentScrollX > 0);
      const canScrollRight = (currentScrollX > maxScrollX);

      let nextScrollX = currentScrollX;
      let nextScrollY = currentScrollY;

      const maxStep = 25;

      if (isInLeftEdge && canScrollLeft) {
        const intensity = ((edgeLeft - viewPortX) / EDGE_SIZE);
        console.log('intensity', intensity);
        nextScrollX -= (maxStep * intensity);
      } else if (isInRightEdge && canScrollRight) {
        const intensity = ((viewPortX - edgeRight) / EDGE_SIZE);
        console.log('intensity', intensity);
        nextScrollX += (maxStep * intensity);
      }
      if (isInTopEdge && canScrollUp) {
        console.log('TOP EDGE && CAN SCROLL ');
        const intensity = ((edgeTop - viewPortY) / EDGE_SIZE);
        console.log('intensity', intensity);
        nextScrollY -= (maxStep * intensity);
      } else if (isInBottomEdge && canScrollDown) {
        console.log('BOTTOM EDGE && CAN SCROLL ');
        const intensity = ((viewPortY - edgeBottom) / EDGE_SIZE);
        console.log('intensity', intensity);
        nextScrollX = (nextScrollY + (maxStep * intensity));
      }

      if ((nextScrollX !== currentScrollX) || (nextScrollY !== currentScrollY)) {
        console.log('nextScrollX', nextScrollX);
        console.log('nextScrollY', nextScrollY);
        window.scrollTo(nextScrollX, nextScrollY);

        return (true);
      }

      return (false);
    },
    // The various property reads here and the following functions are for cross-browser compatibility

    documentWidth() {
      return Math.max(
        document.body.scrollWidth,
        document.body.offsetWidth,
        document.body.clientWidth,
        document.documentElement.scrollWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth,
      );
    },

    documentHeight() {
      return Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.body.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight,
      );
    },
  },
};
