<template>
  <div>
    <div
      v-if="publicationId"
    >
      <div
        id="loader-container"
        v-if="!hasKey"
        :style="{width:cardWidth}"
      >
        <slot name="loading">
          <div
            class="spinner"
          />
        </slot>
      </div>
      <div
        v-else
      >
        <slot
          :img="linkPreviewMetadataByPublicationId[publicationId].image"
          :title="linkPreviewMetadataByPublicationId[publicationId].title"
          :description="linkPreviewMetadataByPublicationId[publicationId].description"
          :url="url"
        >
          <div
            class="wrapper"
            :style="{width:cardWidth}"
          >
            <div class="card-img">
              <img
                :src="linkPreviewMetadataByPublicationId[publicationId].image"
              >
            </div>
            <div class="card-info">
              <div class="card-text">
                <h1>{{ linkPreviewMetadataByPublicationId[publicationId].title }}</h1>
                <p>{{ linkPreviewMetadataByPublicationId[publicationId].description }}</p>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
    <div v-else>
      <div
        id="loader-container"
        v-if="!linkPreviewMetadata"
        :style="{width:cardWidth}"
      >
        <slot name="loading">
          <div
            class="spinner"
          />
        </slot>
      </div>
      <div v-if="linkPreviewMetadata">
        <slot
          :img="linkPreviewMetadata.image"
          :title="linkPreviewMetadata.title"
          :description="linkPreviewMetadata.description"
          :url="url"
        >
          <div
            class="wrapper"
            :style="{width:cardWidth}"
          >
            <div class="card-img">
              <img
                :src="linkPreviewMetadata.image"
              >
            </div>
            <div class="card-info">
              <div class="card-text">
                <h1>{{ linkPreviewMetadata.title }}</h1>
                <p>{{ linkPreviewMetadata.description }}</p>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LinkPrevue',
  props: {
    url: {
      type: String,
      default: '',
    },
    cardWidth: {
      type: String,
      default: '400px',
    },
    publicationId: {
      type: Number,
      default: null,
    },
  },
  watch: {
    url() {
      this.response = null;
    },
  },
  data() {
    return {
      response: null,
      validUrl: false,
    };
  },
  computed: {
    ...mapState({
      appContainers: state => state.views.appContainers,
      linkPreviewMetadata: state => state.views.linkPreviewMetadata,
      linkPreviewMetadataByPublicationId: state => state.views.linkPreviewMetadataByPublicationId,
    }),
    hasKey() {
      return this.linkPreviewMetadataByPublicationId.hasOwnProperty(this.publicationId);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Hind+Siliguri:400,600");
.wrapper {
  overflow: auto;
  border-radius: 7px 7px 7px 7px;
  background-color: #fff;
  -webkit-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.15);
}
.card-img {
  width: 100%;
}
.card-img img {
  width: 100%;
  border-radius: 7px 7px 0 0;
}
img {
  vertical-align: middle;
  border-style: none;
}
.card-info {
  border-radius: 0 0 7px 7px;
  background-color: #ffffff;
}
.card-text {
  width: 80%;
  margin: 0 auto;
  text-align: justify;
}
.card-text h1 {
  text-align: center;
  font-size: 24px;
  color: #474747;
  margin: 5px 0 5px 0;
  font-family: "Hind Siliguri", sans-serif;
}
.card-text p {
  font-family: "Hind Siliguri", sans-serif;
  color: #8d8d8d;
  font-size: 15px;
  overflow: hidden;
  margin: 0;
  text-align: center;
}
.card-btn {
  margin: 1em 0 1em 0;
  position: relative;
  text-align: center;
}
.card-btn a {
  border-radius: 2em;
  font-family: "Hind Siliguri", sans-serif;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #ffffff;
  background-color: #ffa9be;
  padding: 10px 20px 10px 20px;
  text-align: center;
  display: inline-block;
  text-decoration: none !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.card-btn a:hover {
  background-color: #ff8fab;
}

</style>
