<template>
  <div class="">
    <modal
      :id="modalID"
      :name="modalName"
      :width="427"
      :height="472"
      :scrollable="true"
      :click-to-close="false"
      :resizable="true"
      :classes="{
        'container-background-color': !showTransparency,
        'center-horizontal-vertical': true,
      }"
    >
      <div
        class="flex flex-row-reverse"
        style="position: sticky; top: 0; transform: translate(17px, -12px);"
      >
        <div
          class="app-modal__close-btn text-right close-box"
          @click="close"
        >
          <i
            class="material-icons"
            style="color: #000000;"
          >close</i>
        </div>
      </div>
      <stylized-text-app
        class=""
        :app-type="appTypes.TEXT"
        :container="container"
        :editable="editable"
        :show-see-more="false"
      />
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';
import { APP_TYPES } from '../constants';
import { CLOSE_APP_MODAL } from '../store/action-types';
import StylizedTextApp from './stylized-text-app';
import customToolbarHelper from '../helpers/custom-toolbar';

export default {
  components: {
    StylizedTextApp,
  },
  props: {
    editable: { type: Boolean, default: false },
    container: { type: Object, default: null },
  },
  data() {
    const root = document.querySelector(':root');
    root.style.setProperty('--container-background-color', _.get(this.container, 'backgroundColor', '#FFFFFF'));

    return {
      stylizedText: _.get(this.container, 'app.content', ''),
      customToolbar: customToolbarHelper.customToolbar,
      viewVariableSelected: null,
      appTypes: APP_TYPES,
      modalID: _.get(this.container, 'id', ''),
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      showTransparency: _.get(this.container, 'showTransparency', false),
    };
  },
  mounted() {
    if (!this.selectedModalToShow(this.container.id)) {
      return;
    }
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
  },
  computed: {
    ...mapGetters(['selectedView', 'selectedModalToShow']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
    }),
    appWithType() {
      return {
        app: { content: this.stylizedText },
        appType: 'StylizedTextApp',
      };
    },
    requiredAppInputsFilled() {
      return !!this.stylizedText;
    },
    heightInteger() {
      const position = this.container.position.style;
      const positionArray = position.split(';');
      const height = $.grep(positionArray, (element) => element.includes('height'))[0].split(':')[1].replace(/ /g, '');

      return parseInt(height.split('px')[0], 10);
    },
    widthInteger() {
      const position = this.container.position.style;
      const positionArray = position.split(';');
      const width = $.grep(positionArray, (element) => element.includes('width'))[0].split(':')[1].replace(/ /g, '');

      return parseInt(width.split('px')[0], 10);
    },
    modalName() {
      return `text-modal${this.modalID}`;
    },
  },
  methods: {
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show(this.modalName);
    },
  },
};
</script>

<style>
.v--modal-overlay {
  background: #828282DB 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 90;
}

.v--modal-box .v--modal {
  z-index: 90;
}

.vm--modal {
  overflow-y: auto;
}

#header-container {
  z-index: 0 !important;
}

.see-more {
  z-index: 0 !important;
}

.center-horizontal-vertical {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
}

.container-background-color {
  background-color: var(--container-background-color);
}

.close-box {
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

</style>
