<template>
  <div>
    <modal
      name="connection"
      height="auto"
      width="400px"
      :scrollable="true"
      :click-to-close="false"
      class="connection-modal"
    >
      <div
        class="connection-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <button
        v-for="(serviceOption, index) in serviceOptions.slice(0, 2)"
        :key="index"
        @click="createConnection(serviceOption[1])"
        class="connection-modal__service-btn"
      >
        {{ serviceOption[0] }}
      </button>
    </modal>
  </div>
</template>

<script>
import { CLOSE_CONNECTION_MODAL } from '../store/mutation-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';
import api from '../api';

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  props: {
    serviceOptions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$modal.show('connection');
  },
  methods: {
    close() {
      this.$store.commit(CLOSE_CONNECTION_MODAL);
    },
    generateOauth2PostRequest(service, authLink) {
      if (service === 'excel') {
        const form = document.createElement('form');
        form.action = 'POST';
        form.action = authLink;
        document.body.appendChild(form);
        form.submit();
      } else {
        window.location.assign(authLink);
      }
    },
    createConnection(service) {
      api.createConnection({ service }).then((response) => {
        if (response.authLink) {
          this.generateOauth2PostRequest(service, response.authLink);
        } else {
          this.showMessage(this.$i18n.t('toasts.connectionAlreadyExists'));
        }
      });
    },
  },
};
</script>
