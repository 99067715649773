<template>
  <div>
    <div
      v-if="dataSource && dataSource.service ==='gsheet' && showDataSource"
    >
      <a
        :href="googleSheetUrl"
        target="_blank"
        class="cursor-pointer data-source-link"
      >
        fuente
      </a>
    </div>
    <div
      v-if="container && !editable"
      ref="SingleValueApp"
      class="h-full"
      :class="{
        'show-bg-transparent': showTransparency,
      }"
    >
      <div class="flex flex-col">
        <div
          v-if="hasIcon && iconPosition === 'top'"
          class="icon-bottom-padding"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
          }"
        >
          <i
            :id="singleValueIconID"
            class="material-icons single-value-icon"
          >{{ icon }}</i>
        </div>
        <div
          :class="{
            'flex flex-row': ['right', 'left'].includes(iconPosition),
          }"
        >
          <div
            class="flex"
            v-if="hasIcon && iconPosition === 'left'"
            :class="{
              'icon-right-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(contentAlignment),
            }"
          >
            <i
              :id="singleValueIconID"
              class="material-icons single-value-icon"
            >{{ icon }}</i>
          </div>
          <div
            class="flex flex-col"
            style="justify-content: center; align-items: center;"
          >
            <div
              v-if="hasDescriptiveText"
              v-html="descriptiveText"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            />
            <div
              v-if="singleValue"
              v-html="singleValue"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            />
          </div>
          <div
            class="flex"
            v-if="hasIcon && iconPosition === 'right'"
            :class="{
              'icon-left-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(contentAlignment),
            }"
          >
            <i
              :id="singleValueIconID"
              class="material-icons single-value-icon"
            >{{ icon }}</i>
          </div>
        </div>
        <div
          v-if="hasIcon && iconPosition === 'bottom'"
          class="icon-top-padding"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment)
          }"
        >
          <i
            :id="singleValueIconID"
            class="material-icons single-value-icon"
          >{{ icon }}</i>
        </div>
      </div>
    </div>
    <div
      v-else
      class="h-full :overflow-hidden overflow-y-scroll"
      :class="{
        'show-bg-transparent': showTransparency,
      }"
      ref="singleValueApp"
      id="single-value-app"
    >
      <div
        class="flex flex-col"
        style="justify-content: center; align-items: center;"
      >
        <div
          v-if="hasIcon && iconPosition === 'top'"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': contentAlignment === 'center',
            'icon-bottom-padding': iconPosition === 'top',
            'icon-top-padding': iconPosition === 'bottom'
          }"
        >
          <i
            :id="singleValueIconID"
            class="material-icons single-value-icon"
          >{{ icon }}</i>
        </div>
        <div
          class="flex flex-row"
        >
          <div
            class="flex"
            v-if="hasIcon && iconPosition === 'left'"
            :class="{
              'icon-right-padding': ['top', 'bottom', 'center', 'right', 'left'].includes(contentAlignment),
            }"
          >
            <i
              :id="singleValueIconID"
              class="material-icons single-value-icon"
            >{{ icon }}</i>
          </div>
          <div
            id="editor-div"
            class="flex flex-col"
            style="justify-content: center; align-items: center;"
          >
            <vue-editor
              v-if="hasDescriptiveText"
              class="flex padding-horizontal-zero"
              :id="editorId"
              v-model="descriptiveText"
              :editor-toolbar="customToolbar"
              @text-change="autoSaveDescriptiveText"
              @focus="toolbarShow()"
              @blur="toolbarHide()"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            />
            <vue-editor
              v-if="true"
              class="flex padding-horizontal-zero"
              :id="editorId2"
              v-model="singleValue"
              :editor-toolbar="customToolbar"
              @text-change="autoSaveSingleValue"
              @focus="toolbarShow2()"
              @blur="toolbarHide2()"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
              }"
            />
          </div>
          <div
            class="flex"
            v-if="hasIcon && iconPosition === 'right'"
          >
            <i
              :id="singleValueIconID"
              class="material-icons single-value-icon"
            >{{ icon }}</i>
          </div>
        </div>
        <div
          v-if="hasIcon && iconPosition === 'bottom'"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': ['center', 'top', 'bottom'].includes(contentAlignment),
          }"
        >
          <i
            :id="singleValueIconID"
            class="material-icons single-value-icon"
          >{{ icon }}</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { VueEditor } from 'vue2-editor';
import $ from 'jquery';
import Quill from 'quill';
import api from '../api';
import { APP_TYPES } from '../constants';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';
import showMessageMixin from '../mixins/showMessageMixin';

const DEBOUNCE_TIME = 1000;
const LAST_HEIGHT_PIXELS_HIDDEN = 20;
const SEE_MORE_LEFT_FROM_BOTTOM_CORNER = 60;
const DECIMAL_BASE = 10;
const DEFAULT_ICON_FONT_SIZE = 26;
const DEFAULT_ICON_SIZE = 62;
const DEFAULT_ICON_PADDING = 18;

export default {
  mixins: [positionMixin, showMessageMixin],
  components: {
    VueEditor,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
  },
  async mounted() {
    this.setIconSize();
    $('.ql-toolbar.ql-snow').hide();
    $('.ql-editor').attr('style',
      'min-height: 0px !important; padding-left: 0px; padding-bottom: 0px; padding-top: 0px; padding-right: 15.4px;',
    );
    this.updateIconColor();
    this.applyContentAlignment();
    if (this.dataSource) {
      await this.updateSingleValueWithDataSourceValue(this.dataSource, this.singleValue);
    }
  },
  async updated() {
    $('.ql-toolbar.ql-snow').hide();
    this.setIconSize();
    this.updateIconColor();
    if (this.dataSource) {
      await this.updateSingleValueWithDataSourceValue(this.dataSource, this.singleValue);
    }
  },
  data() {
    const Font = Quill.import('formats/font');
    Font.whitelist = [
      'anton', 'arial', 'caveat', 'comfortaa', 'ebgaramond', 'inconsolata',
      'inter', 'lato', 'lobster', 'lora', 'merriweather', 'mirza', 'montserrat',
      'nunito', 'opensans', 'oswald', 'pacifico', 'roboto', 'robotoserif', 'robotoslab',
      'sansserif', 'shizuru', 'spectral', 'staatliches', 'worksans',
    ];
    Quill.register(Font, true);
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = [
      '8px', '9px', '10px', '11px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px', '96px',
    ];
    Quill.register(Size, true);
    const singleValue = _.get(this.container,
      'app.singleValue', '<p><strong style="font-size: 30px;">Valor</strong></p>');
    this.defineSingleValueAppAttrs();

    return {
      appTypes: APP_TYPES,
      editorOptions: {
        scrollingContainer: '#single-value-app',
      },
      customToolbar: customToolbarHelper.customToolbar,
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      showSeeMoreEdited: true,
      descriptiveText: _.get(this.container, 'app.descriptiveText', '<p>Texto Descriptivo</p>'),
      descriptiveTextHistory: _.get(this.container, 'app.DescriptiveTextHistory', []),
      containerID: _.get(this.container, 'id', -1),
      singleValue,
      singleValueContent: this.extractedSingleValueContent(singleValue),
      singleValueWithTags: singleValue,
      initialDescriptiveText: _.get(this.container, 'app.descriptiveText', '<p>Texto Descriptivo</p>'),
      initialSingleValue: singleValue,
    };
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
    }),
    editorId2JS() {
      return `#text-editor2${this.containerID}`;
    },
    editorId() {
      return `text-editor${this.containerID}`;
    },
    editorId2() {
      return `text-editor2${this.containerID}`;
    },
    textEditor() {
      return '#text-editor'.concat(this.containerID.toString());
    },
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    containerHeight() {
      const position = this.container.position.style;
      const positionArray = position.split(';');
      const height = $.grep(positionArray, (element) => element.includes('height'))[0].split(':')[1].replace(/ /g, '');

      return height;
    },
    containerWidth() {
      const position = this.container.position.style;
      const positionArray = position.split(';');
      const width = $.grep(positionArray, (element) => element.includes('width'))[0].split(':')[1].replace(/ /g, '');

      return width;
    },
    hasDescriptiveText() {
      return this.appAttrs[this.container.id].hasDescriptiveText;
    },
    hasIcon() {
      return this.appAttrs[this.container.id].hasIcon;
    },
    icon() {
      return this.appAttrs[this.container.id].icon;
    },
    iconPosition() {
      return this.appAttrs[this.container.id].iconPosition;
    },
    iconColor() {
      return this.appAttrs[this.container.id].iconColor;
    },
    iconSize() {
      return this.appAttrs[this.container.id].iconSize;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    showTransparency() {
      return _.get(this.container, 'showTransparency', false);
    },
    contentAlignment() {
      return this.appAttrs[this.container.id].contentAlignment;
    },
    showDataSource() {
      return this.appAttrs[this.container.id].showDataSource;
    },
    singleValueIconID() {
      return `single-icon${this.containerID}`;
    },
    dataSource() {
      return _.get(this.container, 'app.dataSource', null);
    },
    googleSheetUrl() {
      return `https://docs.google.com/spreadsheets/d/${this.dataSource.sourceId}`;
    },
  },
  methods: {
    setIconSize() {
      const factor = parseFloat(this.iconSize, DECIMAL_BASE);
      const iconIDSearch = '#'.concat(this.singleValueIconID);
      $(iconIDSearch).css({
        'font-size': `${factor * DEFAULT_ICON_FONT_SIZE}px`,
        'width': `${factor * DEFAULT_ICON_SIZE}px`,
        'height': `${factor * DEFAULT_ICON_SIZE}px`,
        'padding-top': `${factor * DEFAULT_ICON_PADDING}px`,
        'padding-left': `${factor * DEFAULT_ICON_PADDING}px`,
      });
    },
    async getDataSourceValue(dataSource) {
      const response = await api.getDataSourceValues(dataSource.id);
      const DatSourceValue = response.values[0][0];

      return DatSourceValue;
    },
    changeSingleValueVars(newSingleValue) {
      const oldSingleValueContent = this.singleValueContent;
      this.singleValue = this.singleValueWithTags.replace(oldSingleValueContent, newSingleValue);
      this.singleValueWithTags = this.singleValueWithTags.replace(oldSingleValueContent, newSingleValue);
      this.singleValueContent = newSingleValue;
    },
    async updateSingleValueWithDataSourceValue(dataSource, singleValue) {
      const tagsNumber = (singleValue.match(/</g) || []).length;
      let singleValueToCompare = singleValue;
      if (tagsNumber > 0) {
        singleValueToCompare = this.extractedSingleValueContent(singleValue);
      }
      const DatSourceValue = await this.getDataSourceValue(dataSource);
      if (dataSource && dataSource.id && (singleValueToCompare === 'Valor' || singleValueToCompare === '' ||
        singleValueToCompare !== DatSourceValue)) {
        this.changeSingleValueVars(DatSourceValue);
        this.updateSingleValueContent();
      }

      return singleValue;
    },
    applyBottomAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-end');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-bottom', '15px');
    },
    applyTopAlignment() {
      $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
      $(this.containerIdJS.concat('.app-container__content')).css('justify-content', 'flex-start');
      $(this.containerIdJS.concat('.app-container__content')).css('padding-top', '15px');
    },
    applyContentAlignment() {
      if (this.contentAlignment === 'right') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-end');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-right', '15px');
      } else if (this.contentAlignment === 'left') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'flex-start');
        $(this.containerIdJS.concat('.app-container__content')).css('padding-left', '15px');
      } else if (this.contentAlignment === 'center') {
        $(this.containerIdJS.concat('.app-container__content')).css('align-items', 'center');
        $(this.containerIdJS.concat('.app-container__content')).css('justify-content:', 'center');
      } else if (this.contentAlignment === 'bottom') {
        this.applyBottomAlignment();
      } else if (this.contentAlignment === 'top') {
        this.applyTopAlignment();
      }
    },
    updateIconColor() {
      const iconIDSearch = '#'.concat(this.singleValueIconID);
      $(iconIDSearch).css('color', this.iconColor);
      $(iconIDSearch).css('border', '1px '.concat('solid ', this.iconColor));
    },
    applyOverflowHidden(containerID) {
      const container = $('#'.concat(containerID)).children().first()
        .children()
        .first()
        .children()
        .first();
      container.addClass('hideScroll');

      const seeMoreSearch = `#${this.seeMoreID}`;
      $(seeMoreSearch).css('display', 'inline');
      const seeMoreTop = (parseInt(this.containerHeight.split('px')[0], DECIMAL_BASE) -
        LAST_HEIGHT_PIXELS_HIDDEN).toString().concat('px');
      const seeMoreLeft = (parseInt(this.containerWidth.split('px')[0], DECIMAL_BASE) -
        SEE_MORE_LEFT_FROM_BOTTOM_CORNER).toString().concat('px');
      $(seeMoreSearch).css({ top: seeMoreTop, left: seeMoreLeft });
    },
    colorListExpanded() {
      return $('[class^="ql-color ql-picker"]').hasClass('ql-expanded');
    },
    fontListExpanded() {
      return $('[class^="ql-font ql-picker"]').hasClass('ql-expanded');
    },
    sizeListExpanded() {
      return $('[class^="ql-size ql-picker"]').hasClass('ql-expanded');
    },
    backgroundListExpanded() {
      return $('[class^="ql-background ql-picker"]').hasClass('ql-expanded');
    },
    toolbarHide() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.textEditor).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow() {
      $(this.textEditor).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    toolbarHide2() {
      if (this.fontListExpanded() || this.sizeListExpanded() ||
        this.colorListExpanded() || this.backgroundListExpanded()) {
        return;
      }
      $(this.editorId2JS).prev().hide();
      this.$emit('sendTitleTextConflictToPoly', false);
    },
    toolbarShow2() {
      $(this.editorId2JS).prev().show();
      this.$emit('sendTitleTextConflictToPoly', true);
    },
    hasContainer() {
      return !_.isEmpty(this.container);
    },
    autoSaveDescriptiveText: _.debounce(function () {
      this.updateDescriptiveTextContent();
    }, DEBOUNCE_TIME),
    autoSaveSingleValue: _.debounce(function () {
      const extractedSingleValueContent = this.extractedSingleValueContent(this.singleValue);
      if (extractedSingleValueContent === this.singleValueContent) {
        this.updateSingleValueContent();
      } else {
        this.singleValue = this.singleValueWithTags;
        // this.showMessage(
        //   `No debes editar el valor proveniente de la fuente de datos, 
        //   sólo el estilo como la tipografía o el tamaño de letra.`,
        // );
        // this.showMessage(
        //   'Estos cambios no se verán reflejados. Compruébalo recargando la página.',
        // );
      }
    }, DEBOUNCE_TIME),
    updateDescriptiveTextContent() {
      if (this.initialDescriptiveText !== this.descriptiveText) {
        const params = { app: { descriptiveText: this.descriptiveText } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        const initialDescriptiveText = this.descriptiveText;
        this.initialDescriptiveText = initialDescriptiveText;
      }
    },
    updateSingleValueContent() {
      if (this.initialSingleValue !== this.singleValue) {
        const params = { app: { singleValue: this.singleValue } };
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
        const initialSingleValue = this.singleValue;
        this.initialSingleValue = initialSingleValue;
      }
    },
    extractedSingleValueContent(singleValue) {
      const tagsNumber = (singleValue.match(/</g) || []).length;
      if (tagsNumber > 0) {
        let closeBracketsCount = 0;
        let initialIndex = null;
        let finalIndex = null;
        const closeBracketTarget = (tagsNumber / 2);
        for (let i = 0; i < singleValue.length; i++) {
          const character = singleValue.charAt(i);
          if (character === '>') {
            closeBracketsCount += 1;
          }
          if (initialIndex === null && closeBracketsCount === closeBracketTarget) {
            initialIndex = i;
          }
          if (initialIndex !== null && finalIndex === null && character === '<') {
            finalIndex = i;
          }
        }

        return singleValue.substring(initialIndex + 1, finalIndex);
      }

      return singleValue;
    },
    defineSingleValueAppAttrs() {
      const params = {
        hasDescriptiveText: _.get(this.container, 'app.hasDescriptiveText', true),
        contentAlignment: _.get(this.container, 'app.contentAlignment', 'left'),
        hasIcon: _.get(this.container, 'app.hasIcon', false),
        icon: _.get(this.container, 'app.icon', ''),
        iconPosition: _.get(this.container, 'app.iconPosition', 'top'),
        iconSize: _.get(this.container, 'app.iconSize', '1X'),
        iconColor: _.get(this.container, 'app.iconColor', '#0031FF'),
        showDataSource: _.get(this.container, 'showDataSource', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
  },
};
</script>
<style scoped>
.single-value-icon {
  font-size: 26px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 1px solid '#0031FF';
  padding-top: 18px;
  padding-left: 18px;
  opacity: 1;
}

.show-bg-transparent {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.bottom-padding {
  margin-bottom: 15px;
}

.top-padding {
  margin-top: 15px;
}

.padding-horizontal-zero {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.padding-vertical-zero {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.app-container__content .ql-editor {
  padding: 0px 0px !important;
}

.icon-bottom-padding {
  padding-bottom: 10px;
}

.icon-top-padding {
  padding-top: 10px;
}

.icon-right-padding {
  padding-right: 13px;
}

.icon-left-padding {
  padding-left: 13px;
}

.center-elements {
  justify-content: center;
  align-items: center;
}

.text-right-padding {
  padding-right: 13px;
}

.text-left-padding {
  padding-left: 13px;
}

.data-source-link {
  text-align: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  position: absolute;
  top: 5px;
  right: 0;
  margin-right: 1rem;
}

</style>
