<template>
  <div>
    <div
      v-if="dataSource && dataSource.service ==='gsheet' && showDataSource"
    >
      <a
        :href="googleSheetUrl"
        target="_blank"
        class="cursor-pointer data-source-link"
      >
        fuente
      </a>
    </div>
    <div
      class="flex flex-column flex-1 items-center justify-center"
      style="align-items: none !important;"
      v-if="imageCell === null"
      :id="'myArticleApp'"
      :key="sliderUpdaterCount"
    >
      <i class="material-icons icon2">vertical_split</i>
    </div>
    <div
      v-else-if="container && !editable"
      class="overscroll-none"
      :class="{
        'top-margin-horizontal': contentOrientation === 'horizontal',
        'top-margin-vertical': contentOrientation === 'vertical',
      }"
    >
      <vueper-slides
        v-if="contentOrientation === 'horizontal'"
        :autoplay="filteredDataLength > 1"
        class="no-shadow"
        arrows-outside
        bullets-outside
        transition-speed="1000"
        duration="10000"
        ref="myVueperSlides"
        :pause-on-hover="pauseOnHover"
        :fixed-height="verticalContentHeight"
        :style="vueperSlidesStyle"
      >
        <vueper-slide
          v-for="(article, index) in articles"
          :key="`article-${index}`"
          :content="constructContent(article)"
          class="h-full"
        />
      </vueper-slides>
      <vueper-slides
        v-else
        :autoplay="filteredDataLength > 1"
        class="no-shadow"
        arrows-outside
        bullets-outside
        transition-speed="1000"
        duration="10000"
        ref="myVueperSlides"
        :pause-on-hover="pauseOnHover"
        :fixed-height="verticalContentHeight"
        :style="verticalStyle"
      >
        <vueper-slide
          v-for="(article, index) in articles"
          :key="`article-${index}`"
          :content="constructContent(article)"
          class="h-full"
        />
      </vueper-slides>
    </div>
    <div
      v-else
      class=""
      style="padding-left: 50px; margin-right: 50px;"
      :class="{
        'top-margin-horizontal': contentOrientation === 'horizontal',
        'top-margin-vertical': contentOrientation === 'vertical',
      }"
      :id="'myArticleApp'"
      :key="sliderUpdaterCount"
    >
      <vueper-slides
        v-if="contentOrientation === 'horizontal'"
        :autoplay="filteredDataLength > 1"
        class="no-shadow"
        arrows-outside
        bullets-outside
        transition-speed="1000"
        duration="10000"
        ref="myVueperSlides"
        :pause-on-hover="pauseOnHover"
        :fixed-height="verticalContentHeight"
        :style="vueperSlidesStyle"
        :key="vueperSlidesID"
      >
        <vueper-slide
          v-for="(article, index) in articles"
          :content="constructContent(article)"
          class="h-full"
          :key="vueperSlideID(index)"
        />
      </vueper-slides>
      <vueper-slides
        v-else
        :autoplay="filteredDataLength > 1"
        class="no-shadow"
        arrows-outside
        bullets-outside
        transition-speed="1000"
        duration="10000"
        ref="myVueperSlides"
        :pause-on-hover="pauseOnHover"
        :fixed-height="verticalContentHeight"
        :style="verticalStyle"
        :key="vueperSlidesID"
      >
        <vueper-slide
          v-for="(article, index) in articles"
          :content="constructContent(article)"
          class="h-full"
          :key="vueperSlideID(index)"
        />
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import api from '../api';
import { APP_TYPES } from '../constants';
import customToolbarHelper from '../helpers/custom-toolbar';
import positionMixin from '../mixins/positionMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import {
  SET_APP_ATTRIBUTES,
} from '../store/action-types';

const CONTENT_CHARS_SMALL_LENGTH_LIMIT = 200;
const CONTENT_CHARS_MIDDLE_LENGTH_LIMIT = 400;
const CONTENT_CHARS_BIG_LENGTH_LIMIT = 600;
const FIRST_CONTAINER_WIDTH_DIMENSION = 1000;
const SECOND_CONTAINER_WIDTH_DIMENSION = 1300;
const FIRST_CONTAINER_HEIGHT_DIMENSION = 790;
const SECOND_CONTAINER_HEIGHT_DIMENSION = 950;
const TITLE_CHARS_LIMIT = 100;
const AUTHOR_CHARS_LIMIT = 20;
const CONTAINER_HEIGHT_LIMIT = 350;
const CONTAINER_WIDTH_LIMIT = 750;
const CONTAINER_HEIGHT_DELTA = 200;
const CONTAINER_WIDTH_DELTA = 150;
const HEIGHT_SOLVER = 50;
const WIDTH_SOLVER = 100;
const MAX_HEIGHT_HORIZONTAL_ORIENTATION = 510;
const MAX_HEIGHT_VERTICAL_ORIENTATION = 650;
const DECIMAL_BASE = 10;
const MINIMUM_DATASET_LENGTH = 2;

export default {
  mixins: [positionMixin, showMessageMixin],
  components: {
    VueperSlides,
    VueperSlide,
  },
  props: {
    container: { type: Object, default: null },
    appType: { type: String, required: true },
    editable: { type: Boolean, default: false },
  },
  async mounted() {
    const dataSet = await this.updateWithDataSourceValues();
    $(this.containerIdJS).removeClass('overflow-y-auto');
    $(this.polymorphicAppID).find('.vueperslide__content-wrapper')
      .css('background-color', this.containerBackgroundColor);
    if (dataSet && dataSet.length > 0) {
      this.resumeSlider(dataSet);
      if (this.contentOrientation === 'vertical') {
        $(this.polymorphicAppID).find('.vueperslide__content-wrapper').css('justify-content', 'flex-start');
      }
      this.filteredDataLength = dataSet.length;
    }
  },
  async updated() {
    if (this.previousDataSource !== this.dataSource) {
      const dataSet = await this.updateWithDataSourceValues();
      if (dataSet && dataSet.length > 0) {
        this.resumeSlider(dataSet);
        this.filteredDataLength = dataSet.length;
      }
      this.previousDataSource = this.dataSource;
    }
  },
  data() {
    const container = this.container;
    const columnsData = _.get(container, 'app.columnsData', []);
    const columnsDataProcessed = this.processColumnsData(columnsData);
    this.defineArticleAppAttrs(columnsDataProcessed);

    return {
      filteredDataLength: 0,
      appTypes: APP_TYPES,
      customToolbar: customToolbarHelper.customToolbar,
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      containerID: _.get(this.container, 'id', -1),
      dataLoaded: false,
      borderColor: _.get(container, 'borderColor', ''),
      showShading: _.get(container, 'showShading', false),
      showSearch: _.get(container, 'showSearch', true),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      applyFilters: _.get(container, 'app.applyFilters', false),
      articles: [],
      pauseOnHover: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      hashFilterIndex: null,
      filters: _.get(container, 'app.filtersChecked', []),
      previousDataSource: _.get(this.container, 'app.dataSource', null),
    };
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
    }),
    containerIdJS() {
      return '#app-container'.concat(this.containerID.toString());
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    polymorphicAppID() {
      return `#polymorphic-app${this.containerID}`;
    },
    sheetColumns() {
      const firstAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
      const secondAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
      const extendedAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

      for (const firstLetter of firstAlphabet) {
        if (firstLetter === 'd') {
          break;
        }
        for (const secondLetter of secondAlphabet) {
          extendedAlphabet.push(firstLetter.concat(secondLetter));
        }
      }

      return extendedAlphabet;
    },
    containerHeight() {
      if (this.container.position.style === undefined ||
        !this.container.position.style.split(';').some((attr) => attr.includes('height'))) {
        return CONTAINER_HEIGHT_LIMIT;
      }
      const arrayWithDims = this.container.position.style.split(';');
      const height = arrayWithDims.filter(attr => attr.includes('height'))[0].split(':')[1].replace(/ /g, '');

      return height.split('px')[0];
    },
    containerWidth() {
      if (this.container.position.style === undefined ||
        !this.container.position.style.split(';').some((attr) => attr.includes('width'))) {
        return CONTAINER_WIDTH_LIMIT;
      }
      const arrayWithDims = this.container.position.style.split(';');
      const width = arrayWithDims.filter(attr => attr.includes('width'))[0].split(':')[1].replace(/ /g, '');

      return width.split('px')[0];
    },
    vueperSlidesStyle() {
      let width = this.containerWidth;
      let height = this.containerHeight;
      if (width - WIDTH_SOLVER < CONTAINER_WIDTH_LIMIT - CONTAINER_WIDTH_DELTA) {
        width = CONTAINER_WIDTH_LIMIT - CONTAINER_WIDTH_DELTA;
      } else {
        width -= WIDTH_SOLVER;
      }
      if (height - HEIGHT_SOLVER < CONTAINER_HEIGHT_LIMIT - CONTAINER_HEIGHT_DELTA) {
        height = CONTAINER_HEIGHT_LIMIT - CONTAINER_HEIGHT_DELTA;
      } else {
        height -= HEIGHT_SOLVER;
      }
      height = Math.min(...[MAX_HEIGHT_HORIZONTAL_ORIENTATION, height]);

      return `z-index: 0;
        width: ${width}px; height: 230px;`;
    },
    verticalStyle() {
      return 'z-index: 0; width: 230px;';
    },
    horizontalStyle() {
      return 'z-index: 0; height: 230px;';
    },
    verticalContentHeight() {
      return `${Math.max(...[MAX_HEIGHT_VERTICAL_ORIENTATION, this.containerHeight - 100])}px;`;
    },
    monthByString() {
      return {
        'enero': '01',
        'febrero': '02',
        'marzo': '03',
        'abril': '04',
        'mayo': '05',
        'junio': '06',
        'julio': '07',
        'agosto': '08',
        'septiembre': '09',
        'octubre': '10',
        'noviembre': '11',
        'diciembre': '12',
      };
    },
    imageCell() {
      return this.appAttrs[this.container.id].imageCell;
    },
    showImageCell() {
      return this.appAttrs[this.container.id].showImageCell;
    },
    titleCell() {
      return this.appAttrs[this.container.id].titleCell;
    },
    showTitleCell() {
      return this.appAttrs[this.container.id].showTitleCell;
    },
    contentCell() {
      return this.appAttrs[this.container.id].contentCell;
    },
    showContentCell() {
      return this.appAttrs[this.container.id].showContentCell;
    },
    authorCell() {
      return this.appAttrs[this.container.id].authorCell;
    },
    showAuthorCell() {
      return this.appAttrs[this.container.id].showAuthorCell;
    },
    dateCell() {
      return this.appAttrs[this.container.id].dateCell;
    },
    showDateCell() {
      return this.appAttrs[this.container.id].showDateCell;
    },
    redirectCell() {
      return this.appAttrs[this.container.id].redirectCell;
    },
    showRedirectCell() {
      return this.appAttrs[this.container.id].showRedirectCell;
    },
    lectureTimeCell() {
      return this.appAttrs[this.container.id].lectureTimeCell;
    },
    showLectureTimeCell() {
      return this.appAttrs[this.container.id].showLectureTimeCell;
    },
    contentOrientation() {
      return this.appAttrs[this.container.id].contentOrientation;
    },
    sliderUpdaterCount() {
      return this.appAttrs[this.container.id].sliderUpdaterCount;
    },
    dataSource() {
      return this.appAttrs[this.container.id].dataSource;
    },
    showDataSource() {
      return this.appAttrs[this.container.id].showDataSource;
    },
    vueperSlidesID() {
      return `vueper-slides-${this.sliderUpdaterCount}`;
    },
    googleSheetUrl() {
      return `https://docs.google.com/spreadsheets/d/${this.dataSource.sourceId}`;
    },
  },
  methods: {
    vueperSlideID(index) {
      return `vueper-slide-${this.sliderUpdaterCount}-${index}`;
    },
    resumeSlider(dataSet) {
      if (dataSet === null || dataSet.length < MINIMUM_DATASET_LENGTH) {
        return;
      }
      this.$refs.myVueperSlides.resumeAutoplay();
    },
    smallApp(content) {
      if (content.length < CONTENT_CHARS_SMALL_LENGTH_LIMIT) {
        return content;
      }

      return content.substring(0, CONTENT_CHARS_SMALL_LENGTH_LIMIT).concat('...');
    },
    middleApp(content) {
      if (content.length < CONTENT_CHARS_MIDDLE_LENGTH_LIMIT) {
        return content;
      }

      return content.substring(0, CONTENT_CHARS_MIDDLE_LENGTH_LIMIT).concat('...');
    },
    bigApp(content) {
      if (content.length < CONTENT_CHARS_BIG_LENGTH_LIMIT) {
        return content;
      }

      return content.substring(0, CONTENT_CHARS_BIG_LENGTH_LIMIT).concat('...');
    },
    articleContentHorizontalOrientation(content, width) {
      if (width < FIRST_CONTAINER_WIDTH_DIMENSION) {
        return this.smallApp(content);
      } else if (width >= FIRST_CONTAINER_WIDTH_DIMENSION &&
        width < SECOND_CONTAINER_WIDTH_DIMENSION) {
        return this.middleApp(content);
      }

      return this.bigApp(content);
    },
    articleContentVerticalOrientation(content, height) {
      if (height < FIRST_CONTAINER_HEIGHT_DIMENSION) {
        return this.smallApp(content);
      } else if (height >= FIRST_CONTAINER_HEIGHT_DIMENSION &&
        height < SECOND_CONTAINER_HEIGHT_DIMENSION) {
        return this.middleApp(content);
      }

      return this.bigApp(content);
    },
    articleContentProcessed(content) {
      if (this.contentOrientation === 'horizontal') {
        return this.articleContentHorizontalOrientation(content, this.containerWidth);
      }

      return this.articleContentVerticalOrientation(content, this.containerHeight);
    },
    articleTitleProcessed(title) {
      if (title.length < TITLE_CHARS_LIMIT) {
        return title;
      }

      return title.substring(0, TITLE_CHARS_LIMIT).concat('...');
    },
    articleAuthorProcessed(author) {
      if (author.length < AUTHOR_CHARS_LIMIT) {
        return author;
      }

      return author.substring(0, AUTHOR_CHARS_LIMIT).concat('...');
    },
    getIdFromUrl(url) {
      return url.match(/[-\w]{20,}/); // some Drive files have 22 chars IDs !
    },
    getImgSrcUrlFromDriveUrl(url) {
      const id = this.getIdFromUrl(url);
      const prefix = 'https://drive.google.com/uc?export=view&id=';

      return prefix + id;
    },
    isTheValueAnURL(url) {
      const parts = (url).toString().split('.');
      if (parts[0].indexOf('http') >= 0 || parts[0].includes('active_storage')) return true;

      return false;
    },
    detectURL(url) {
      let theUrl;
      const regex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
      const subdomain = url.match(regex);
      const domain = url.split('.');

      if (subdomain === null) {
        theUrl = url;
      } else {
        if (subdomain[1] === 'drive' && domain[1] === 'google') {
          theUrl = this.getImgSrcUrlFromDriveUrl(url);
        } else {
          theUrl = url;
        }
      }

      return theUrl;
    },
    getPIC(picValue) {
      let url;
      if (picValue === undefined || picValue === null) {
        return 'https://www.onap.org/wp-content/uploads/sites/20/2017/05/Blank_woman_placeholder.svg_-500x500.png';
      }
      if (this.isTheValueAnURL(picValue)) {
        url = this.detectURL(picValue);
      }

      return url;
    },
    imageTag(article) {
      if (this.showImageCell === false) {
        return '';
      }

      const url = this.getPIC(article.image);

      return `
        <div
          class="overscroll-contain"
        >
          <div
            class="list-group-item"
          >
            <img
              src="${url}"
              class="w-full"
              style="object-fit: cover; width: 221px; height: 221px; margin-right: 221px; border-radius: 10px 0 0 10px;"
            >
          </div>
        </div>`;
    },
    imageVerticalTag(article) {
      if (this.showImageCell === false) {
        return '';
      }

      const url = this.getPIC(article.image);

      return `
        <img
          src="${url}"
          class="w-full"
          style="object-fit: cover; width: 220px; height: 220px; margin: auto; border-radius: 10px 10px 0 0;"
        >`;
    },
    titleTag(article) {
      if (this.showTitleCell === false) {
        return '';
      }

      return `
        <div title="${article.title}">
          <p class="title-style border-b">${this.articleTitleProcessed(article.title)}</p>
        </div>`;
    },
    contentTag(article) {
      if (this.showContentCell === false) {
        return '';
      }

      return `
        <p class="content-style" style="text-overflow: ellipsis;">
          ${this.articleContentProcessed(article.content)}</p>`;
    },
    authorTag(article) {
      if (this.showAuthorCell === false) {
        return '';
      }

      return `
        <div title="${article.author}">
          <p class="bottom-details">Autor: ${this.articleAuthorProcessed(article.author)}</p>
        </div><p class="bottom-details">|</p>`;
    },
    dateTag(article) {
      if (this.showDateCell === false) {
        return '';
      }

      return `
        <p class="bottom-details">${article.date}</p>
        <p class="bottom-details">|</p>`;
    },
    dateVerticalTag(article) {
      if (this.showDateCell === false) {
        return '';
      }

      const dateArray = article.date.split(' ');

      return `
        <p class="bottom-details">${dateArray[0].slice(0, 7).concat(' ', dateArray[1])}</p>
        <p class="bottom-details">|</p>`;
    },
    lectureTimeTag(article) {
      if (this.showLectureTimeCell === false) {
        return '';
      }

      return `
        <p class="bottom-details">  ${article.lectureTime} min</p>`;
    },
    linkTag(article) {
      if (this.showRedirectCell === false) {
        return '';
      }

      return `
        <div class="self-end flex flex-row">
          <a target="_blank" class="link-style"
            style="margin-top: 2.5px;" href="${article.redirect}">Ver artículo completo</a>
          <i
            class="material-icons go-link"
            style="color: #0031FF; padding-left: 3px;"
          >arrow_forward</i>
        </div>`;
    },
    linkVerticalTag(article) {
      if (this.showRedirectCell === false) {
        return '';
      }

      return `
        <div class="self-center flex flex-row">
          <a target="_blank" class="link-style"
            style="margin-top: 5px;" href="${article.redirect}">Ver artículo completo</a>
          <i
            class="material-icons go-link"
            style="color: #0031FF; margin-top: 2.5px;"
          >arrow_forward</i>
        </div>`;
    },
    horizontalContent(article) {
      return `
        <div class="h-full flex flex-row">
          ${this.imageTag(article)}
          <div class="flex flex-col" style="margin-left: 35px;">
            ${this.titleTag(article)}
            ${this.contentTag(article)}
            <div class="h-full flex flex-row" style="margin-top: 10px;">
              ${this.authorTag(article)}
              ${this.dateTag(article)}
              ${this.lectureTimeTag(article)}
            </div>
            ${this.linkTag(article)}
          </div>
        </div>
        `;
    },
    verticalContent(article) {
      return `
        <div style="position: sticky; top: 0px;">
          ${this.imageVerticalTag(article)}
          <div class="h-full flex flex-row" style="margin-top: 35px; margin-right: 30px;">
            <div class="flex flex-col" style="margin-left: 35px;">
              ${this.titleTag(article)}
              ${this.contentTag(article)}
              <div class="flex flex-row" style="margin-top: 10px; margin-bottom: 20px;">
                ${this.authorTag(article)}
                ${this.dateVerticalTag(article)}
                ${this.lectureTimeTag(article)}
              </div>
              ${this.linkVerticalTag(article)}
            </div>
          </div>
        </div>  
        `;
    },
    constructContent(article) {
      if (this.contentOrientation === 'vertical') {
        return this.verticalContent(article);
      }

      return this.horizontalContent(article);
    },
    processColumnsData(columnsData) {
      if (columnsData) {
        return columnsData;
      }

      return {
        cells: { image: null, title: null, content: null, author: null, date: null, redirect: null, lectureTime: null },
        shows: { image: true, title: true, content: true, author: true, date: true, redirect: true, lectureTime: true },
      };
    },
    allCellsPresent() {
      return ![this.imageCell, this.titleCell, this.contentCell, this.authorCell, this.dateCell,
        this.redirectCell, this.lectureTimeCell].includes(null);
    },
    rangeIndexesDefinition() {
      const sheetColumns = this.sheetColumns;
      const indexOfImage = sheetColumns.indexOf(this.imageCell.toLowerCase());
      const indexOfTitle = sheetColumns.indexOf(this.titleCell.toLowerCase());
      const indexOfContent = sheetColumns.indexOf(this.contentCell.toLowerCase());
      const indexOfAuthor = sheetColumns.indexOf(this.authorCell.toLowerCase());
      const indexOfDate = sheetColumns.indexOf(this.dateCell.toLowerCase());
      const indexOfRedirect = sheetColumns.indexOf(this.redirectCell.toLowerCase());
      const indexOfLectureTime = sheetColumns.indexOf(this.lectureTimeCell.toLowerCase());

      const min = Math.min(...[indexOfImage, indexOfTitle, indexOfContent,
        indexOfAuthor, indexOfDate, indexOfRedirect, indexOfLectureTime]);

      return {
        image: indexOfImage - min,
        title: indexOfTitle - min,
        content: indexOfContent - min,
        author: indexOfAuthor - min,
        date: indexOfDate - min,
        redirect: indexOfRedirect - min,
        lectureTime: indexOfLectureTime - min,
      };
    },
    addArticles(dataSourceValue, rangeIndexes) {
      for (const row of dataSourceValue) {
        const article = {
          image: row[rangeIndexes.image],
          title: row[rangeIndexes.title],
          content: row[rangeIndexes.content],
          author: row[rangeIndexes.author],
          date: row[rangeIndexes.date],
          redirect: row[rangeIndexes.redirect],
          lectureTime: row[rangeIndexes.lectureTime],
        };
        this.articles.push(article);
      }
    },
    updateArticles(dataSourceValue, rangeIndexes) {
      if (rangeIndexes === null) {
        return;
      }
      if (this.articles.length === 0) {
        this.addArticles(dataSourceValue, rangeIndexes);
      } else {
        this.articles = [];
        this.addArticles(dataSourceValue, rangeIndexes);
      }
    },
    async getDataSourceValue() {
      if (!this.dataSource) {
        return null;
      }
      const response = await api.getDataSourceValues(this.dataSource.id);
      let dataSourceValue = response.values;
      dataSourceValue.shift();
      const rangeIndexes = this.rangeIndexesDefinition();
      if (rangeIndexes && this.applyFilters) {
        dataSourceValue = this.applyFiltersToDataset(dataSourceValue, rangeIndexes);
      }
      this.updateArticles(dataSourceValue, rangeIndexes);

      return dataSourceValue;
    },
    async updateWithDataSourceValues() {
      let DatSourceValue = null;
      if (this.allCellsPresent()) {
        DatSourceValue = await this.getDataSourceValue();
      }

      return DatSourceValue;
    },
    hashFilterIndexDefinition(rangeIndexes) {
      return {
        'Imagen': rangeIndexes.image,
        'Título': rangeIndexes.title,
        'Contenido': rangeIndexes.content,
        'Autor': rangeIndexes.author,
        'Fecha': rangeIndexes.date,
        'Redirección': rangeIndexes.redirect,
        'Tiempo Lectura': rangeIndexes.lectureTime,
      };
    },
    dateFormat(monthAndYear) {
      const month = this.monthByString[monthAndYear[0].toLowerCase()];
      const year = monthAndYear[1];

      return new Date(year.concat('-', month, '-', '01'));
    },
    applyNumericalFilters(dataset, condition, column, limitDate) {
      let filteredDataset = dataset;
      if (condition === '>') {
        filteredDataset = filteredDataset.filter(array =>
          this.dateFormat(array[this.hashFilterIndex[column]].split(' ')) > limitDate);
      } else if (condition === '<') {
        filteredDataset = filteredDataset.filter(array =>
          this.dateFormat(array[this.hashFilterIndex[column]].split(' ')) < limitDate);
      } else if (condition === '<=') {
        filteredDataset = filteredDataset.filter(array =>
          this.dateFormat(array[this.hashFilterIndex[column]].split(' ')) <= limitDate);
      } else if (condition === '>=') {
        filteredDataset = filteredDataset.filter(array =>
          this.dateFormat(array[this.hashFilterIndex[column]].split(' ')) >= limitDate);
      } else if (condition === '=') {
        filteredDataset = filteredDataset.filter(array =>
          +this.dateFormat(array[this.hashFilterIndex[column]].split(' ')) === +limitDate);
      } else {
        return dataset;
      }

      return filteredDataset;
    },
    dateFilter(dataset, condition, column, conditionValue) {
      const monthAndYear = conditionValue.split(' ');
      if (monthAndYear.length !== 2) {
        return dataset;
      }

      const limitDate = this.dateFormat(monthAndYear);

      return this.applyNumericalFilters(dataset, condition, column, limitDate);
    },
    filtersBatches(dataset, condition, column, conditionValue) {
      let filteredDataset = dataset;
      if (column === 'Fecha' && ['>', '<', '<=', '>=', '='].includes(condition)) {
        filteredDataset = this.dateFilter(dataset, condition, column, conditionValue);
      } else if (condition === '>') {
        filteredDataset = filteredDataset.filter(array =>
          parseInt(array[this.hashFilterIndex[column]], DECIMAL_BASE) > parseInt(conditionValue, DECIMAL_BASE));
      } else if (condition === '<') {
        filteredDataset = filteredDataset.filter(array =>
          parseInt(array[this.hashFilterIndex[column]], DECIMAL_BASE) < parseInt(conditionValue, DECIMAL_BASE));
      } else if (condition === '<=') {
        filteredDataset = filteredDataset.filter(array =>
          parseInt(array[this.hashFilterIndex[column]], DECIMAL_BASE) <= parseInt(conditionValue, DECIMAL_BASE));
      } else if (condition === '>=') {
        filteredDataset = filteredDataset.filter(array =>
          parseInt(array[this.hashFilterIndex[column]], DECIMAL_BASE) >= parseInt(conditionValue, DECIMAL_BASE));
      } else if (condition === '=') {
        filteredDataset = filteredDataset.filter(array =>
          parseInt(array[this.hashFilterIndex[column]], DECIMAL_BASE) === parseInt(conditionValue, DECIMAL_BASE) ||
          array[this.hashFilterIndex[column]] === conditionValue);
      } else {
        filteredDataset = this.secondFiltersBatch(dataset, condition, column, conditionValue);
      }

      return filteredDataset;
    },
    secondFiltersBatch(dataSet, condition, column, conditionValue) {
      let filteredDataset = dataSet;
      if (condition === 'contiene') {
        filteredDataset = filteredDataset.filter(array =>
          array[this.hashFilterIndex[column]].toString().includes(conditionValue));
      } else if (condition === 'comienza con') {
        filteredDataset = filteredDataset.filter(array =>
          array[this.hashFilterIndex[column]].toString().startsWith(conditionValue));
      } else if (condition === 'no comienza con') {
        filteredDataset = filteredDataset.filter(array =>
          !array[this.hashFilterIndex[column]].toString().startsWith(conditionValue));
      } else if (condition === 'termina con') {
        filteredDataset = filteredDataset.filter(array =>
          array[this.hashFilterIndex[column]].toString().endsWith(conditionValue));
      } else if (condition === 'no termina con') {
        filteredDataset = filteredDataset.filter(array =>
          !array[this.hashFilterIndex[column]].toString().endsWith(conditionValue));
      } else if (condition === 'no contiene') {
        filteredDataset = filteredDataset.filter(array =>
          !array[this.hashFilterIndex[column]].toString().includes(conditionValue));
      }

      return filteredDataset;
    },
    applyFiltersToDataset(dataset, rangeIndexes) {
      this.hashFilterIndex = this.hashFilterIndexDefinition(rangeIndexes);
      let filteredDataset = dataset;
      for (const filter of this.filters) {
        const allParams = filter.split('|');
        const column = allParams[0];
        const condition = allParams[1];
        const conditionValue = allParams[2];
        filteredDataset = this.filtersBatches(filteredDataset, condition, column, conditionValue);
      }

      return filteredDataset;
    },
    defineArticleAppAttrs(columnsDataProcessed) {
      const params = {
        imageCell: columnsDataProcessed.cells.image,
        showImageCell: columnsDataProcessed.shows.image,
        titleCell: columnsDataProcessed.cells.title,
        showTitleCell: columnsDataProcessed.shows.title,
        contentCell: columnsDataProcessed.cells.content,
        showContentCell: columnsDataProcessed.shows.content,
        authorCell: columnsDataProcessed.cells.author,
        showAuthorCell: columnsDataProcessed.shows.author,
        dateCell: columnsDataProcessed.cells.date,
        showDateCell: columnsDataProcessed.shows.date,
        redirectCell: columnsDataProcessed.cells.redirect,
        showRedirectCell: columnsDataProcessed.shows.redirect,
        lectureTimeCell: columnsDataProcessed.cells.lectureTime,
        showLectureTimeCell: columnsDataProcessed.shows.lectureTime,
        contentOrientation: _.get(this.container, 'app.contentOrientation', 'horizontal'),
        sliderUpdaterCount: 0,
        dataSource: _.get(this.container, 'app.dataSource', null),
        showDataSource: _.get(this.container, 'showDataSource', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
  },
};
</script>
<style>

.go-link {
  transform: scale(0.9);
}

.list-group-item {
  display: flex;
  width: 100%;
  height: 200px;
}

.vueperslides__arrows {
  color: black;
}

.vueperslides__bullet--active {
  color: red !important;
}

.default {
  color: black !important;
}

.title-style {
  text-align: left;
  font: normal normal 600 16px/18px Inter;
  letter-spacing: 0px;
  color: #313131;
  opacity: 1;
  margin-bottom: 22.21px;
  border-bottom-color: black;
  padding-bottom:5px;
}

.content-style {
  text-align: left;
  font: normal normal 300 13px/19px Inter;
  letter-spacing: 0px;
  color: #313131;
  opacity: 1;
}

.bottom-details {
  text-align: left;
  font: normal normal 200 13px/19px Inter;
  letter-spacing: 0px;
  color: #7A7A7A;
  opacity: 1;
  margin-right: 20px;
}

.app-container__content {
  align-items: center !important;
}

.link-style {
  text-align: left;
  font: normal normal normal 14px/19px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
}

.top-margin-horizontal {
  margin-top: 50px;
}

.top-margin-vertical {
  margin-top: 70px;
}

.scrollable-toolbar-item-option {
  padding-bottom: 90px;
}

.data-source-link {
  text-align: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  position: absolute;
  top: 5px;
  right: 0;
  margin-right: 1rem;
}

.icon2 {
  color: #FAAE56 !important;
  opacity: 1;
  font-size: 70px !important;
}

</style>
