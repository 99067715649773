
import humps from 'humps';
import api from '../../../api';
import gapiPromise from '../../../helpers/gapi';
import * as mutations from '../../mutation-types';
import * as actions from '../../action-types';

const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  'https://sheets.googleapis.com/$discovery/rest?version=v4'];

export default {
  [actions.SELECT_USER_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_USER_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_USER);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_USER_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'user.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.SELECT_COSULTANT_FOR_DELETION]({ commit }, consultant) {
    commit(mutations.SET_SELECTED_CONSULTANT, consultant);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CONSULTANT);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CONSULTANT_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'user.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  async [actions.DELETE_SELECTED_CONSULTANT]({ state, commit, dispatch }) {
    return api.deleteUser(state.selectedConsultant.id).then(() => {
      commit(mutations.REMOVE_CONSULTANT);
      dispatch(actions.UNSELECT_CONSULTANT_FOR_DELETION);
    });
  },
  [actions.UNSELECT_CONSULTANT_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_CONSULTANT, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },

  [actions.UNSELECT_USER_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_USER_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_USER_FOR_EDITION]({ commit }, id) {
    commit(mutations.SET_SELECTED_USER_ID, id);
    commit(mutations.OPEN_USER_MODAL);
  },
  [actions.CLOSE_USER_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_USER_ID, null);
    commit(mutations.CLOSE_USER_MODAL);
  },
  [actions.SET_CURRENT_USER]({ commit }, user) {
    commit(mutations.SET_CURRENT_USER_ID, user.id);
    commit(mutations.ADD_USER, humps.camelizeKeys(user));
  },
  async [actions.DELETE_SELECTED_USER]({ state, commit, dispatch }) {
    return api.deleteUser(state.selectedId).then(() => {
      commit(mutations.REMOVE_USER);
      dispatch(actions.UNSELECT_USER_FOR_DELETION);
    });
  },
  [actions.SELECT_CURRENT_COMPANY]({ commit }, company) {
    commit(mutations.SET_CURRENT_COMPANY_ID, company.id);
  },
  [actions.SAVE_USER]({ state, commit }, params) {
    if (state.selectedId) {
      return api.updateUser({ id: state.selectedId, params }).then((user) =>
        commit(mutations.UPDATE_SELECTED_USER, user),
      );
    }

    return api.createUser(params).then((user) =>
      commit(mutations.ADD_USER, user),
    );
  },
  [actions.SAVE_CONSULTANT]({ state, commit }, { platformId, params }) {
    if (state.selectedConsultant) {
      return api.updateUser({ id: state.selectedConsultant.id, params }).then((user) =>
        commit(mutations.UPDATE_CONSULTANT_LIST, user),
      );
    }

    return api.createPlatformMemberUser(platformId, params).then((user) =>
      commit(mutations.UPDATE_CONSULTANT_LIST, user),
    );
  },
  [actions.FETCH_GOOGLE_ACCESS_TOKEN]({ commit, dispatch }, connectionId) {
    return api.getGoogleAccessToken(connectionId).then((token) => {
      commit(mutations.ADD_CURRENT_USER_CONNECTIONS, { gsheet: token });
      dispatch(actions.INITIALIZE_GAPI_SERVICE, token);
    });
  },
  [actions.INITIALIZE_GAPI_SERVICE]({ commit }, token) {
    gapiPromise.then((_) => {
      window.gapi.load('client', () => {
        window.gapi.client.init({ discoveryDocs: DISCOVERY_DOCS });
        window.gapi.client.setToken({ 'access_token': token.accessToken });
      });
    });
  },
  [actions.SELECT_TAG_FOR_DELETION]({ commit }, tag) {
    commit(mutations.SET_SELECTED_TAG, tag);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.REMOVE_SELECTED_TAG);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_TAG_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'user.deleteTag');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_TAG_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_TAG, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.SELECT_WORKSPACE_FOR_DELETION]({ commit }, workspace) {
    commit(mutations.SET_SELECTED_WORKSPACE, workspace);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.REMOVE_SELECTED_WORKSPACE);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_WORKSPACE_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'user.deleteWorkspace');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_WORKSPACE_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_WORKPACE, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.REMOVE_SELECTED_WORKSPACE]({ state, commit, dispatch }) {
    const platformId = state.selectedPlatform.id;
    const workspaceId = state.selectedWorkspace.id;

    return api.removeWorkspacePlatform(platformId, workspaceId)
      .then(() => {
        commit(mutations.REMOVE_WORKSPACE, workspaceId);
        dispatch(actions.UNSELECT_WORKSPACE_FOR_DELETION);
      });
  },
  [actions.ADD_TAG]({ commit }, params) {
    return api.createCompanyTags(params).then((companyWithTags) => {
      const tagList = companyWithTags.tagList;
      commit(mutations.ADD_TENANT_TAGS, tagList);
    });
  },
  [actions.UPDATE_EDITED_TAG]({ state, commit }, { oldValue, newValue }) {
    const indexToUpdate = state.tenantTags.indexOf(oldValue);
    commit(mutations.UPDATE_TAG, { index: indexToUpdate, newValue });
    const tagList = state.tenantTags.join(',');
    const params = { id: state.currentCompanyId, tagList };

    return api.updateCompanyTags(params).then((companyWithTags) => {
      const returnedTagList = companyWithTags.tagList;
      commit(mutations.ADD_TENANT_TAGS, returnedTagList);
      commit(mutations.SET_TAG_EDIT_MODE, false);
    });
  },
  // [actions.REMOVE_SELECTED_TAG]({ state, commit }, tag) {
  [actions.REMOVE_SELECTED_TAG]({ state, commit }) {
    const indexToBeRemoved = state.tenantTags.indexOf(state.selectedTag.tag);
    commit(mutations.REMOVE_TAG, indexToBeRemoved);
    const tagList = state.tenantTags.join(',');
    const params = { id: state.currentCompanyId, tagList };

    return api.updateCompanyTags(params).then((companyWithTags) => {
      const returnedTagList = companyWithTags.tagList;
      commit(mutations.ADD_TENANT_TAGS, returnedTagList);
      commit(mutations.SET_TAG_REMOVE_MODE, false);
    });
  },
  [actions.SAVE_TAG_ASSIGNATIONS]({ commit }, { companyId, tagList, userList }) {
    const params = { companyId, tagList, userList };

    return api.updateUsersTag(params).then((response) => {
      const normalizedUser = response.users.reduce((userObj, user) => {
        userObj[user.id] = user;

        return userObj;
      }, {});
      commit(mutations.SET_NORMALIZED_USERS, normalizedUser);

      return api.getCompany(companyId);
    }).then(company => {
      const normalized = company.usersTagged.reduce((normalObject, tagged) => {
        normalObject[tagged.tag] = tagged.users;

        return normalObject;
      }, {});
      commit(mutations.SET_USERS_TAGGED, normalized);

      commit(mutations.CLOSE_USER_TAGS_MODAL);
    });
  },
  [actions.CREATE_DATA_SOURCE]({ commit }, params) {
    return api.createDataSource(params).then((response) => {
      console.log(response);
      commit(mutations.SET_SELECTED_DATA_SOURCE, response.dataSource);
    });
  },
  [actions.OPEN_DATA_SOURCE_MODAL]({ commit }) {
    commit(mutations.SET_DATA_SOURCE_OPEN_MODAL, true);
  },

  [actions.CLOSE_DATA_SOURCE_MODAL]({ commit }) {
    commit(mutations.SET_DATA_SOURCE_OPEN_MODAL, false);
  },
  [actions.SET_SELECTED_DATA_SOURCE_SERVICE]({ commit }, service) {
    commit(mutations.SET_DATA_SOURCE_SERVICE, service);
  },
  [actions.SET_SELECTED_DATA_SOURCE]({ commit }, dataSource) {
    commit(mutations.SET_SELECTED_DATA_SOURCE, dataSource);
  },
};
