<template>
  <div class="grid-index">
    <div class=" mb-8">
      <span
        v-if="isWorkspaceApps"
        class="text-size-27"
      >
        {{ $t('customAppTemplate.workspaceTitle') }}
      </span>
      <span
        v-else
        class="grid-index__title"
      >
        {{ $t('customAppTemplate.title') }}
      </span>
      <a
        v-if="!isWorkspaceApps"
        class="btn btn--inverted-colors new-btn"
        href="/custom_app_templates/new"
      >
        <i class="material-icons material-icons--blue new-btn__plus">add</i>
        {{ $t('customAppTemplate.new') }}
      </a>
    </div>
    <div class="grid grid-cols-2 gap-4 items-center">
      <div v-if="Object.keys(customAppTemplates).length === 0">
        No existen Apps para revision
      </div>
      <div
        v-else
        v-for="(template, index) in customAppTemplates"
        :key="index"
        @click="openPreviewApTemplateModal(template)"
        class="p-2 border-b border-gray-300 flex rounded-md items-center h-full app-height cursor-pointer"
      >
        <a
          v-if="!isWorkspaceApps"
          :href="`/custom_app_templates/${template.id}`"
        >
          <div class="grid-index__name--lil">
            {{ template.name }}
          </div>
          <span class="grid-index__item-container--visibility-status">
            {{ visibilityStates[template.visibilityStatus] }}
          </span>
        </a>
        <template
          v-else
        >
          <div
            class="w-2/12 max-h-68 rounded-lg  mt-2 mb-2"
          >
            <img
              :src="template.thumbnailUrl || imgPlaceholder"
              class="object-cover app-image-type rounded-lg bg-gray-400"
              alt="app's thumbnail"
            >
          </div>
          <div class="flex flex-col w-7/12 pl-4 h-full">
            <div>
              <p class="text-lg text-gray-500">
                Visualización de Datos
              </p>
              <p class="text-md text-gray-400 truncate min-description">
                {{ template.name }} / {{ template.description }}
              </p>
            </div>

            <!-- <div
              class="flex flex-row items-center h-full"
            >
              <div class="p-1 truncate w-full bg-indigo-200 text-indigo-600 rounded-lg text-center text-sm cursor-pointer">
                {{ visibilityStates[template.visibilityStatus] }}
              </div>
            </div> -->
          </div>
          <div class="w-3/12 text-sm text-center">
            {{ visibilityStates[template.visibilityStatus] }}
          </div>
        </template>
        <div
          v-if="!isWorkspaceApps"
          class="grid-index__icons-container--lil"
        >
          <a
            :href="`/custom_app_templates/${template.id}/edit`"
            class="grid-index__icon"
          >
            <i class="material-icons">edit</i>
          </a>
          <toggle-button
            color="#5157ff"
            class="grid-index__icon"
            :value="!template.disabled"
            :sync="true"
            @input="toggleTemplateDisabled(template)"
          />
          <button
            class="grid-index__icon grid-index__icon--first-right"
            @click="deleteCustomAppTemplate(template)"
          >
            <i class="material-icons">delete</i>
          </button>
        </div>

        <preview-custom-app-modal
          v-if="openPreviewModal"
          :container="container"
        />
      </div>
    </div>
    <confirmation-modal
      v-if="confirmationModalOpened"
      :msg="confirmMsg"
    >
      <template v-if="affectedViews && affectedViews.length > 0">
        <span>{{ $t('customAppTemplate.affectedViews') }}</span>
        <ul>
          <li
            v-for="view in affectedViews"
            :key="view.id"
          >
            {{ view.title }}
          </li>
        </ul>
      </template>
      <span v-else>{{ $t('customAppTemplate.noAffectedViews') }}</span>
    </confirmation-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import api from '../api';
import { SET_CUSTOM_APP_TEMPLATES } from '../store/mutation-types';
import {
  SELECT_CUSTOM_APP_TEMPLATE_FOR_DELETION,
  SELECT_CUSTOM_APP_TEMPLATE_FOR_DISABLE,
  UPDATE_SELECTED_CUSTOM_APP_TEMPLATE,
  OPEN_PREVIEW_TEMPLATE_APP,
} from '../store/action-types';
import ConfirmationModal from './confirmation-modal';
import PreviewCustomAppModal from './preview-custom-app-modal';
import imgPlaceholder from '../images/image_placeholder.svg';

export default {
  components: {
    ConfirmationModal,
    PreviewCustomAppModal,
  },
  props: {
    customAppTemplatesVisibilityOptions: { type: Array, required: true },
    customAppTemplatesProp: { type: Array, required: true },
    isWorkspaceApps: { type: Boolean, required: true, default: false },
  },
  data() {
    return {
      affectedViews: null,
      confirmMsg: null,
      container: null,
      imgPlaceholder,
    };
  },
  mounted() {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | ' + 'Mis Apps';
    const templateObject = this.customAppTemplatesProp.reduce((obj, template) => {
      obj[template.id] = template;

      return obj;
    }, {});
    this.$store.commit(SET_CUSTOM_APP_TEMPLATES, templateObject);
  },
  computed: {
    ...mapState({
      customAppTemplates: state => state.views.customAppTemplates,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      openPreviewModal: state => state.views.openPreviewModal,
    }),
    visibilityStates() {
      return this.customAppTemplatesVisibilityOptions.reduce((states, currentState) => {
        states[currentState[1]] = currentState[0];

        return states;
      }, {});
    },
  },
  methods: {
    async fetchAffectedViews(template) {
      this.affectedViews = (await api.getCustomAppTemplateViews(template.id)).views;
    },
    async deleteCustomAppTemplate(template) {
      this.confirmMsg = null;
      await this.fetchAffectedViews(template);
      this.$store.dispatch(SELECT_CUSTOM_APP_TEMPLATE_FOR_DELETION, template);
    },
    async toggleTemplateDisabled(template) {
      if (template.disabled) {
        this.$store.dispatch(UPDATE_SELECTED_CUSTOM_APP_TEMPLATE, { id: template.id, params: { disabled: false } });
      } else {
        this.confirmMsg = this.$i18n.t('customAppTemplate.confirmDisable');
        await this.fetchAffectedViews(template);
        this.$store.dispatch(SELECT_CUSTOM_APP_TEMPLATE_FOR_DISABLE, template);
      }
    },
    openPreviewApTemplateModal(template) {
      this.container = this.getContainer(template);
      this.$store.dispatch(OPEN_PREVIEW_TEMPLATE_APP, true);
    },
    getContainer(template) {
      const cloneTemplate = _.cloneDeep(template);
      const fields = cloneTemplate.fields.reduce((objAcc, field) => {
        objAcc[field.name] = field.default;

        return objAcc;
      }, {});
      cloneTemplate.fields = fields;

      return cloneTemplate;
    },
  },
};
</script>
<style >

.max-h-68 {
  max-height: 68px;;
}

.app-image-type {
  height: 44px;
  width: 44px;
}
</style>
