<template>
  <div>
    <modal
      name="template"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal app-modal']"
    >
      <div
        class="app-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="app-modal__header">
        <i class="material-icons app-modal__header-icon">open_in_new</i>
        {{ $t('template.title') }}
      </div>
      <div class="app-modal__inputs">
        <div class="app-modal__input-header">
          {{ $t('template.name') }}
        </div>
        <input
          v-model="titleName"
          type="text"
          class="app-modal__input"
        >
        <select
          class="app-modal__input"
          v-model="visibilityStatus"
          @change="updateVisibilityStatus"
        >
          <option>private</option>
          <option>workspace</option>
          <option>public</option>
        </select>
        <div class="custom-app-template-editor__section-content">
          <div class="custom-app-template-editor__section-industry">
            <span>Industria</span>

            <tags-input
              :add-on-key="keyCodes"
              :style="{paddingTop: '1rem'}"
              v-model="industry"
              :tags="selectedIndustries"
              placeholder="Industry"
              :autocomplete-items="filteredTags"
              @tags-changed="newTags => selectedIndustries = newTags"
              class="share-resource-modal__input share-resource-modal__input--tags"
            />
          </div>

          <div class="custom-app-template-editor__section-content-description">
            <span> Descripcion</span>
            <textarea
              type="text"
              name="description"
              :style="{height: '145px', resize: 'none'}"
              id="description"
              v-model="description"
              class="custom-app-template-editor__input"
            />
          </div>

          <div class="custom-app-template-editor__section-content-process">
            <label for="process"> Proceso</label>
            <div>
              <!-- <span
                class="custom-app-template-editor__section-checkbox"
                :key="process[1]"
                v-for="process in processOptions"
              >
                <input
                  v-model="selectedProcess[process[1]]"
                  type="checkbox"
                  :name="process"
                >
                <label :for="process">{{ process[0] }}</label>
              </span> -->

              <tags-input
                :add-on-key="keyCodes"
                :style="{paddingTop: '1rem'}"
                v-model="process"
                :tags="selectedProcess"
                placeholder="Choose a process"
                :autocomplete-items="filteredProcessTag"
                @tags-changed="newTags => selectedProcess = newTags"
                class="share-resource-modal__input share-resource-modal__input--tags"
              />
            </div>
          </div>

          <div class="custom-app-template-editor__section-content-viewing-level">
            <label for="process"> Viewing Level</label>
            <div>
              <!-- <span
                class="custom-app-template-editor__section-checkbox"
                :key="viewLevel[1]"
                v-for="viewLevel in viewingLevelOptions"
              >
                <input
                  v-model="selectedViewingLevel[viewLevel[1]]"
                  type="checkbox"
                  :name="viewLevel"
                >
                <label :for="viewLevel">{{ viewLevel[0] }}</label>
              </span> -->

              <tags-input
                :add-on-key="keyCodes"
                :style="{paddingTop: '1rem'}"
                v-model="viewingLevel"
                :tags="selectedViewingLevel"
                placeholder="Industry"
                :autocomplete-items="filteredViewingTag"
                @tags-changed="newTags => selectedViewingLevel = newTags"
                class="share-resource-modal__input share-resource-modal__input--tags"
              />
            </div>
          </div>

          <div class="custom-app-template-editor__section-content-company-size">
            <label for="process"> Tamaño de la compañia</label>
            <div>
              <span
                class="custom-app-template-editor__section-checkbox"
                :key="companySize[1]"
                v-for="companySize in companySizeOptions"
              >
                <input
                  v-model="selectedCompanySize[companySize[1]]"
                  type="checkbox"
                  :name="companySize"
                >
                <label :for="companySize">{{ companySize[0] }}</label>
              </span>
            </div>
          </div>
          <div class="custom-app-template-editor__section-content-revenue-type">
            <label for="process"> Modelo de Ingresos </label>
            <div>
              <span
                class="custom-app-template-editor__section-checkbox"
                :key="rt[1]"
                v-for="rt in revenueTypeOptions"
              >
                <input
                  v-model="selectedRevenueType[rt[1]]"
                  type="checkbox"
                  :name="rt"
                >
                <label :for="rt">{{ rt[0] }}</label>
              </span>
            </div>
          </div>
          <div class="custom-app-template-editor__section-content-area">
            <label for="process"> Area </label>
            <div>
              <!-- <span
                class="custom-app-template-editor__section-checkbox"
                :key="a[1]"
                v-for="a in areaOptions"
              >
                <input
                  v-model="selectedArea[a[1]]"
                  type="checkbox"
                  :name="a"
                >
                <label :for="a">{{ a[0] }}</label>
              </span> -->

              <tags-input
                :add-on-key="keyCodes"
                :style="{paddingTop: '1rem'}"
                v-model="area"
                :tags="selectedArea"
                placeholder="Areas"
                :autocomplete-items="filteredAreasTag"
                @tags-changed="newTags => selectedArea = newTags"
                class="share-resource-modal__input share-resource-modal__input--tags"
              />
            </div>
          </div>
        </div>

        <button
          class="btn app-modal__save-btn"
          :class="{ 'app-modal__save-btn--disabled': !requiredInputsFilled }"
          :disabled="!requiredInputsFilled"
          @click="save"
        >
          {{ $t('template.save') }}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash';
import TagsInput from '@johmun/vue-tags-input';
import { mapState } from 'vuex';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import { CLOSE_TEMPLATE_MODAL, SAVE_TEMPLATE } from '../store/action-types';
import { KEY_CODES } from '../constants';
import api from '../api'

export default {
  mixins: [escapeMixinHandler],
  components: {
    TagsInput,
  },
  data() {
    const template = this.$store.state.templates.selected;
    // const selectedProcess = this.getOptionModel(
    //   this.$store.state.views.processOptions,
    //   _.get(template, 'process', []),
    // );
    const selectedRevenueType = this.getOptionModel(
      this.$store.state.views.revenueTypeOptions,
      _.get(template, 'revenueType', []),
    );
    // const selectedViewingLevel = this.getOptionModel(
    //   this.$store.state.views.viewingLevelOptions,
    //   _.get(template, 'viewingLevel', []),
    // );
    const selectedCompanySize = this.getOptionModel(
      this.$store.state.views.companySizeOptions,
      _.get(template, 'companySize', []),
    );
    // const selectedArea = this.getOptionModel(
    //   this.$store.state.views.areaOptions,
    //   _.get(template, 'area', []),
    // );

    return {
      titleName: _.get(template, 'name'),
      visibilityStatus: _.get(template, 'visibilityStatus'),
      description: _.get(template, 'description', ''),
      industry: '',
      process: '',
      viewingLevel: '',
      area: '',
      selectedIndustries: _.get(template, 'industryList', []).map(ind => ({ text: ind })),
      // selectedProcess,
      selectedProcess: _.get(template, 'processList', []).map(ind => ({ text: ind })),
      selectedRevenueType,
      // selectedViewingLevel,
      selectedViewingLevel: _.get(template, 'viewingLevelList', []).map(ind => ({ text: ind })),
      selectedCompanySize,
      // selectedArea,
      selectedArea: _.get(template, 'areaList', []).map(ind => ({ text: ind })),
      keyCodes: [KEY_CODES.SPACE, KEY_CODES.TAB, KEY_CODES.ENTER],
    };
  },
  mounted() {
    this.$modal.show('template');
  },
  computed: {
    requiredInputsFilled() {
      return !!(this.titleName);
    },
    ...mapState({
      industryTags: state => state.views.industryTags,
      companySizeOptions: state => state.views.companySizeOptions,
      revenueTypeOptions: state => state.views.revenueTypeOptions,
      processOptions: state => state.views.processOptions,
      viewingLevelOptions: state => state.views.viewingLevelOptions,
      areaOptions: state => state.views.areaOptions,
    }),
    industries() {
      return this.industryTags.map(tag => ({ text: tag }));
    },
    filteredTags() {
      if (this.industry === null || this.industry === undefined) return [];

      return this.industries.filter(ind => ind.text.toLowerCase().indexOf(this.industry.toLowerCase()) !== -1);
    },

    processes() {
      return this.processOptions.map(p => ({ text: p }));
    },
    filteredProcessTag() {
      return this.processes.filter(ind => ind.text.toLowerCase().indexOf(this.process.toLowerCase()) !== -1);
    },
    viewing() {
      return this.viewingLevelOptions.map(p => ({ text: p }));
    },
    filteredViewingTag() {
      return this.viewing.filter(ind => ind.text.toLowerCase().indexOf(this.viewingLevel.toLowerCase()) !== -1);
    },
    areas() {
      return this.areaOptions.map(p => ({ text: p }));
    },
    filteredAreasTag() {
      return this.areas.filter(ind => ind.text.toLowerCase().indexOf(this.area.toLowerCase()) !== -1);
    },
  },
  methods: {
    updateVisibilityStatus() {
      // const template = this.$store.state.templates.selected;
      // api.updateTemplate({id: template.id, params: {
      //   name: this.titleName,
      //   description: this.description,
      //   visibilityStatus: this.visibilityStatus,
      // }});
      const params = {
        name: this.titleName,
        description: this.description,
        visibilityStatus: this.visibilityStatus,
      } 
      this.$store.dispatch(SAVE_TEMPLATE, params).then(() => this.close());
    },
    close() {
      this.$store.dispatch(CLOSE_TEMPLATE_MODAL);
    },
    save() {
      const params = {
        name: this.titleName,
        // viewingLevel: this.getSelectedOptions(this.selectedViewingLevel),
        // process: this.getSelectedOptions(this.selectedProcess),
        // revenueType: this.getSelectedOptions(this.selectedRevenueType),
        // companySize: this.getSelectedOptions(this.selectedCompanySize),
        viewingLevelList: this.selectedViewingLevel.map(v => v.text),
        processList: this.selectedProcess.map(v => v.text),
        revenueType: this.getSelectedOptions(this.selectedRevenueType),
        companySize: this.getSelectedOptions(this.selectedCompanySize),
        industryList: this.selectedIndustries.map(ind => ind.text),
        areaList: this.selectedArea.map(v => v.text),
        description: this.description,
      };
      this.$store.dispatch(SAVE_TEMPLATE, params).then(() => this.close());
    },
    getSelectedOptions(selected) {
      return Object.keys(selected).filter((s) => selected[s] === true);
    },
    getOptionModel(options, selected) {
      if (options === null) return {};
      const optionModel = options.reduce((accumObj, process) => {
        accumObj[process[1]] = false;

        return accumObj;
      }, {});
      selected.map(select => {
        optionModel[select] = true;

        return null;
      });

      return optionModel;
    },
  },
};
</script>
