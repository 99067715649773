<template>
  <div class="view-editor">
    <text-app-modal
      v-if="selectedModalOfType(appTypes.TEXT) && this.selectedContainer"
      :container="this.selectedContainer"
    />
    <div class="view-editor__content bg-white">
      <div
        id="main-container"
        class="view-editor__workspace-container mb-4 relative"
      >
        <div class="view-editor__workspace">
          <div class="view-editor__workspace-header">
            <div class="view_editor_title">
              {{ view && view.title }}
            </div>
            <template>
              <button
                v-if="(isAdmin && isTemplate)"
                class="view__action view__action--first-right"
                @click="implement"
              >
                <i class="material-icons view__action-icon">file_copy</i>
                {{ $t('template.use') }}
              </button>
              <a
                v-if="(isAdmin && !isTemplate) || isCreator"
                :href="editPath"
                :title="$t('view.edit')"
                class="view__action view__action--first-right"
              >
                <i class="material-icons view__action-icon">edit</i>
              </a>
              <button
                v-if="isAdmin && !isTemplate && !this.view.inSharedFolder"
                class="view__action"
                :title="$t('view.shareButton')"
                @click="openShareViewModal"
              >
                <i class="material-icons view__action-icon">share</i>
              </button>
            </template>
          </div>

          <div
            class="view-editor__workspace-content relative container-min-height h-screen"
            id="app-grid-container"
            ref="appgrid"
          >
            <app-grid
              v-if="viewProp.version === 1.0"
            />
            <app-grid-2
              v-if="viewProp.version === 2.0"
            />
          </div>
        </div>
        <confirmation-modal v-if="confirmationModalOpened" />
        <share-resource-modal v-if="shareResourceModalOpened" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ShareResourceModal from './share-resource-modal';
import ConfirmationModal from './confirmation-modal';
import { APP_TYPES } from '../constants';
import { SET_NESTED_VIEW, SELECT_RESOURCE_FOR_SHARING } from '../store/action-types';
import { SET_MAX_APP_WIDTH, SET_PERSONAL_FOLDERS, SET_MERGE_USER_EMAIL } from '../store/mutation-types';
import storeConnectionsMixin from '../mixins/storeConnectionsMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import api from '../api';
import TextAppModal from './text-app-modal';

export default {
  mixins: [storeConnectionsMixin, showMessageMixin],
  components: {
    ShareResourceModal,
    ConfirmationModal,
    TextAppModal,
  },
  props: {
    viewProp: { type: Object, required: true },
    maxAppWidth: { type: Number, required: true },
    template: { type: Object, default: undefined },
    masterViewProp: { type: Object, default: () => {} },
    personalFolders: {
      type: Array,
      required: false,
      default: () => [],
    },
    sharedFolders: {
      type: Array,
      required: false,
      default: () => [],
    },
    mergeUserEmail: { type: String, default: null },
  },
  data() {
    return {
      masterView: this.masterViewProp,
      appTypes: APP_TYPES,
    };
  },
  mounted() {
    this.$store.dispatch(SET_NESTED_VIEW, this.viewProp);
    this.$store.commit(SET_MERGE_USER_EMAIL, this.mergeUserEmail);
    this.$store.commit(SET_MAX_APP_WIDTH, this.maxAppWidth);
    const allFolders = { personal: this.personalFolders, shared: this.sharedFolders };
    this.$store.commit(SET_PERSONAL_FOLDERS, allFolders);
  },
  computed: {
    isTemplate() {
      return !!this.template;
    },
    editPath() {
      return this.isTemplate ?
        `/view_templates/${this.template.id}/edit` : `/views/${this.view && this.view.id}/edit`;
    },
    ...mapState({
      view: state => state.views.selected,
      shareResourceModalOpened: state => state.ui.shareResourceModalOpened,
      fullscreenAppModalOpened: state => state.ui.fullscreenAppModalOpened,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
    }),
    ...mapGetters(['isAdmin', 'currentUser', 'selectedContainer', 'selectedModalOfType']),
    isCreator() {
      return this.view && this.currentUser && this.view.creatorId === this.currentUser.id;
    },
  },
  methods: {
    openShareViewModal() {
      const options = { resource: this.viewProp, resourceType: 'View' };
      this.$store.dispatch(SELECT_RESOURCE_FOR_SHARING, options);
    },
    implement() {
      let message;
      api.implementViewTemplate(this.template.id).then((newView) => {
        message = this.$t('template.implementSuccessMessage');
        this.showMessage(message);
        window.location.href = `/views/${newView.id}/edit/`;
      }).catch(() => {
        message = 'template.implementFailedMessage';
        this.showMessage(message);
      });
    },
  },
};
</script>
<style scoped>
.container-min-height {
  min-height: 90vh;
}

</style>

