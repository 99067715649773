<template>
  <div
    :id="draggableID"
    v-if="show"
    :ref="interactiveRef"
    @click="selectContainerForDrag()"
    @mouseover="focused = true"
    @mouseleave="focused = false"
    class="droppeable-app rounded-lg
           flex flex-col height-container"
    :class="{
      'article-app-vertical-orientation': (appType === appTypes.ARTICLE && articleAppOrientation === 'vertical'),
      'article-app-horizontal-orientation': (appType === appTypes.ARTICLE && articleAppOrientation === 'horizontal'),
      'wall-app': (appType === appTypes.WALL),
      'selected': selected && containerWithIdOrSelectedContainerId && appsEditable,
      'absolute' : !isContained,
      'h-full w-full' : isContained,
      'floating-container': (appType === appTypes.CUSTOM && !existingCustomTypes()),
      'file-app ': (appType === appTypes.FILE),
      'single-value-app': (appType === appTypes.SINGLE_VALUE),
      'button-app': (appType === appTypes.BUTTON),
      'image-app': (appType === appTypes.IMAGE),
      'source-app': (appType === appTypes.SOURCE),
      'tabs-app': (appType === appTypes.TABS),
      'text-app': (appType === appTypes.TEXT),
      'z-20': focused, 'z-10': !focused,
      ['max-h-screen']: (
        appType === appTypes.FEED ||
        appType === appTypes.CUSTOM ||
        appType === appTypes.IMAGE),
      'shadow-md': showShading,
      'bg-white': containerBackgroundColor === '#FFFFFF' && !showTransparency,
      'transparent-bg': showTransparency,
      'round-shape': roundShape,
    }"
    style="border: 0 !important;"
  >
    <div
      v-if="menuOpen || (shouldShowHeader && focused && !selected) || titleEditing"
      class="w-full p-2 container-toolbar flow-root"
      :class="{
        'hover-effect': !isContained,
      }"
    >
      <div
        class="w-5/6 float-left overflow-hidden"
        style="max-width: 55%;"
      >
        <input
          v-if="appsEditable && titleEditing"
          type="text"
          id="text-editor"
          class="pl-4 unset-input text-gray-500 outline-none w-full app-container-title"
          placeholder="Título del Contenedor"
          @input="autoSaveTitle"
          v-model="title"
          @focus="titleEditing = true"
          @blur="titleEditing = false"
        >
        <span
          v-else
          class="pl-4 unset-input text-gray-500 outline-none w-full app-container-title cursor-text"
          @click="titleEditing = true"
        >
          {{ this.titleFromContainer }}
        </span>
      </div>
      <div
        style="position: absolute; right: 0px;"
      >
        <i
          v-if="appsEditable"
          ref="dropDown"
          id="dropDown"
          @click="openMenu"
          class="material-icons cursor-pointer pr-4"
          style="color: #FFFFFF;"
        >more_vert</i>
      </div>
      <div
        class="bg-white w-32 rounded-lg  absolute dropdown-position shadow z-10"
        v-if="menuOpen"
      >
        <div
          @click="selectContainerForEdition()"
          class="flex  p-2"
        >
          <i
            class="material-icons material-icons-inline app-container__header-icon flex-2"
          >
            edit
          </i>
          <p class="flex-1 text-left text-sm text-gray-600 pl-2">
            Editar
          </p>
        </div>
        <div
          v-if="appType !== appTypes.WALL"
          @click="duplicate()"
          class="flex  p-2"
        >
          <i
            class="material-icons material-icons-inline app-container__header-icon flex-2"
          >
            file_copy
          </i>
          <p class="flex-1 text-left text-sm text-gray-600 pl-2">
            Copiar
          </p>
        </div>

        <div
          @click="openConfirmationModal()"
          class="flex  p-2"
        >
          <i
            class="material-icons material-icons-inline app-container__header-icon flex-2"
          >
            delete
          </i>
          <p class="flex-1 text-left text-sm text-gray-600 pl-2">
            Borrar
          </p>
        </div>
      </div>
    </div>
    <template
      v-if="!configurable"
    >
      <div
        class="rounded-lg overflow-hidden"
        :id="appContainerID"
        :class="{
          'overflow-y-auto': appType !== appTypes.IMAGE && textAppCase(),
          'app-container__content': !(appType === appTypes.TEXT) && !(appType === appTypes.WALL),
          'wall-app-position overflow-x-hidden': appType === appTypes.WALL,
          'p-4' : appType === appTypes.SOURCE || appType === appTypes.TABLE ||
            appType === appTypes.CARD || appType === appTypes.CHART ||
            appType === appTypes.CUSTOM,
          'round-shape': roundShape,
        }"
        :style="bg"
        @scroll="onScroll"
      >
        <source-app
          v-if="container && container.appType == appTypes.SOURCE"
          :container="container"
          :editable="appsEditable"
          @sendTitleTextConflictToPoly="updateTitleTextConflict"
        />
        <polymorphic-app
          :id="polymorphicAppID"
          :container="container"
          :editable="appsEditable"
          :new-app="appType"
          @sendTitleTextConflictToApp="updateTitleTextConflict"
        />

        <div
          ref="dropDown"
          id="dropDown"
          v-if="commentSectionOpened"
          class="bg-white absolute shadow rounded-md msg-feed-box overflow-y-scroll"
        >
          <feed
            ref="feed"
            class="app-container__comments-feed"
            tabindex="-1"
            :comments="comments"
            :container-id="container.id"
          />
        </div>
      </div>
      <div
        @click="openCommentSection()"
        class="app-container__comments-msg-box"
      >
        <div
          v-if="commentable"
          class="absolute right-0 z-30 rounded-md text-white text-center msg-counter-box"
        >
          {{ container.comments.length }}
        </div>
        <span
          v-if="commentable"
          class="app-container__comments-msg z-20 rounded-md"
        >
          <i class="material-icons material-icons-outlined app-container__comments-icon comment-icon">mode_comment</i>
        </span>
      </div>
    </template>
    <div
      class="flex flex-column flex-1 items-center justify-center"
      v-else
    >
      <button
        v-if="appsEditable"
        @click="configureContainer"
        class="rounded-full bg-gray-400 w-16 h-16"
        style="height: 42.34px; width: 42.34px; background: #DDE6F7 0% 0% no-repeat padding-box;"
      >
        <i
          class="material-icons"
          style="color: #0031FF;"
        >add</i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import $ from 'jquery';
import SourceApp from './source-app.vue';
import { APP_TYPES, APP_ICONS } from '../constants';
import PolymorphicApp from './polymorphic-app';
import showMessageMixin from '../mixins/showMessageMixin';
import dragAndResizeMixin from '../mixins/dragAndResizeMixin';
import clickMixinHandler from '../mixins/clickMixinHandler';
import positionMixin from '../mixins/positionMixin';
import objectIdMixin from '../mixins/objectIdMixin';
import { eventBus } from '../packs/application';
import {
  SELECT_CONTAINER_FOR_DELETION,
  SELECT_TAB_CONTAINER_FOR_DELETION,
  SELECT_CONTAINER_FOR_EDITION,
  UNSELECT_CONTAINER_FOR_EDITION,
  SELECT_CONTAINER_FOR_INITIALIZATION,
  SELECT_CONTAINER_FOR_FULLSCREEN,
  ADD_COPY_CONTAINER,
  SAVE_CONTAINER,
  OPEN_SAVE_ALERT,
} from '../store/action-types';
import {
  SET_SELECTED_DATA_SOURCE,
  SET_TOOGLE_TOOLBAR_COLLAPSIBLE,
  SET_BLOCK_APP_TAB_SELECTION,
  SET_CUSTOM_TABS,
  SET_TAB_CONTAINER_ID,
} from '../store/mutation-types';
import api from '../api';
import Feed from './feed';
import customToolbarHelper from '../helpers/custom-toolbar';

const DEBOUNCE_TIME = 1000;
const MAX_PERCENTAGE = 100;
const DEFAULT_APP_TITLE = '';

export default {
  mixins: [
    showMessageMixin,
    dragAndResizeMixin,
    clickMixinHandler,
    positionMixin,
    objectIdMixin,
  ],
  components: {
    Feed,
    PolymorphicApp,
    SourceApp,
  },
  props: {
    container: { type: Object, default: null },
    appsEditable: { type: Boolean, default: false },
    appType: { type: String, required: true },
    icon: { type: String, default: 'apps' },
    showHeader: { type: Boolean, default: true },
    isNew: { type: Boolean, default: false },
    template: { type: Object, default: null },
  },
  data() {
    return {
      commentSectionOpened: false,
      appTypes: APP_TYPES,
      appIcons: APP_ICONS,
      customToolbar: customToolbarHelper.customToolbar,
      menuOpen: false,
      content: null,
      show: true,
      showContainerTitle: _.get(this.container, 'showTitle', false),
      title: _.get(this.container, 'title', DEFAULT_APP_TITLE),
      containerID: _.get(this.container, 'id', -1),
      containerBackgroundColor: _.get(this.container, 'backgroundColor', '#FFFFFF'),
      focused: false,
      selected: false,
      timer: null,
      titleTextConflict: false,
      titleEditing: false,
    };
  },
  created() {
    eventBus.$on('updatePosition', (event) => {
      setTimeout(() => {
        this.handleDropEvent(event);
      }, DEBOUNCE_TIME);
    });
    eventBus.$on('unselectedContainers', (event) => {
      this.deselect();
    });
  },
  // eslint-disable-next-line max-statements
  mounted() {
    this.colorSelected();
    this.applyBorderColor();
    if (this.appsEditable && !this.isFixedApp && this.container) {
      if (this.container.viewVersion === 1.0) {
        this.makeDraggableAndResizable(this.$refs[this.interactiveRef]);
      } else if (this.container.viewVersion === 2.0) {
        this.makeDraggableAndResizable2(this.$refs[this.interactiveRef]);
      }
    }
    if (this.container) {
      if (this.container.app && this.container.app.dataSource) {
        this.$store.commit(SET_SELECTED_DATA_SOURCE, this.container.app.dataSource);
      }
      if (!this.isFixedApp || !this.isContained) {
        this.setPosition(this.$refs[this.interactiveRef], this.container.position);
      }
      if (this.isNew && !this.appsEditable) {
        this.show = false;
      }
      if (this.container.isPrivate &&
      this.container.app.dataSource.useReferenceCondition &&
      this.container.appType === 'CustomApp' &&
      !this.appsEditable &&
      this.mergeUserEmail !== null &&
      this.customAppCurrentUserFields.email !== this.mergeUserEmail
      ) {
        console.log('currentUser', this.customAppCurrentUserFields);
        console.log('mergeUserEmail', this.mergeUserEmail);
        this.show = false;
      } else {
        this.show = true;
      }
    } else {
      const self = this;
      this.selected = true;
      const loadPositionInterval = setInterval(() => {
        self.getPosition(self.$refs[self.interactiveRef]);
        if (self.position.dataX === null) return;
        if (self.show) {
          self.handleDropEvent();
          clearInterval(loadPositionInterval);
        }
      }, DEBOUNCE_TIME);
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'selectedView',
      'customAppCurrentUserFields',
    ]),
    articleAppOrientation() {
      if (this.container && this.container.app) {
        return this.container.app.contentOrientation;
      }

      return 'horizontal';
    },
    bg() {
      return {
        backgroundColor: this.containerBackgroundColor,
      };
    },
    isFixedApp() {
      return this.appType === 'MenuApp' || _.get(this.container, 'appType', false) === 'MenuApp';
    },
    isContained() {
      return this.container ? this.container.contained : false;
    },
    shouldShowHeader() {
      return this.appsEditable;
    },
    shouldShowTitleAndIcon() {
      if ('customAppTemplate' in this.container.app && this.container.app.customAppTemplate.name === 'Table') {
        return false;
      }

      return true;
    },
    ...mapState({
      maxWidth: state => state.views.maxAppWidth,
      mergeUserEmail: state => state.views.mergeUserEmail,
      saveAlert: state => state.ui.saveAlert,
      selectedContainerId: state => state.views.selectedContainerId,
      appAttrs: state => state.views.appsAttrs,
      appContainers: state => state.views.appContainers,
    }),
    comments() {
      return this.$store.getters.containerComments(this.container.id);
    },
    commentable() {
      if (this.hasContainer) {
        return this.container.commentsEnabled && this.container.appType !== APP_TYPES.FEED;
      }

      return false;
    },
    showBorders() {
      return _.get(this.container, 'showBorders', false);
    },
    borderColor() {
      return _.get(this.container, 'borderColor', '');
    },
    showShading() {
      return _.get(this.container, 'showShading', false);
    },
    showTransparency() {
      return _.get(this.container, 'showTransparency', false);
    },
    defaultTitle() {
      if (this.hasContainer) {
        return this.container.title || DEFAULT_APP_TITLE;
      }

      return DEFAULT_APP_TITLE;
    },
    interactiveRef() {
      if (this.hasContainer) return `interactive${this.container.id}`;

      return `interactive${parseInt(Math.random() * MAX_PERCENTAGE, 10)}`;
    },
    hasContainer() {
      return !!this.container;
    },
    configurable() {
      return this.isNew && (
        this.appType === this.appTypes.IMAGE ||
        this.appType === this.appTypes.FILE ||
        this.appType === this.appTypes.SOURCE ||
        this.appType === this.appTypes.TABLE ||
        this.appType === this.appTypes.CHART ||
        this.appType === this.appTypes.CUSTOM ||
        this.appType === this.appTypes.CARD ||
        this.appType === this.appTypes.MENU
      );
    },
    defaultIcon() {
      return this.appIcons[this.appType] || this.icon;
    },
    appContainerID() {
      return `app-container${this.containerID}`;
    },
    polymorphicAppID() {
      return `polymorphic-app${this.containerID}`;
    },
    draggableID() {
      return `draggable${this.containerID}`;
    },
    shape() {
      return _.get(this.container, 'app.shape', 'straight');
    },
    roundShape() {
      return this.shape === 'round';
    },
    containerWithIdOrSelectedContainerId() {
      if (this.container === undefined) {
        return true;
      }

      return this.selectedContainerId;
    },
    titleFromContainer() {
      return this.appContainers[this.container.id].title;
    },
  },
  methods: {
    existingCustomTypes() {
      if (this.container && this.container.app && this.container.app.customAppTemplate) {
        return ['Table', 'Deck', 'Gantt'].includes(this.container.app.customAppTemplate.name);
      }

      return false;
    },
    shouldReturn() {
      let answer = false;
      if (this.container.appTab === null) {
        if (this.menuOpen || this.$store.state.ui.blockAppTabContainerSelected) {
          answer = true;
        }
        if (!this.appsEditable) {
          this.selected = false;
          answer = true;
        }
      }

      return answer;
    },
    selectContainerForDrag() {
      if (this.shouldReturn()) {
        return;
      }
      if (this.menuOpen === true) {
        return;
      }
      this.selected = !this.selected;
      if (this.selected) {
        if (this.container.appTab !== null) { // with this we identify that the selected one is an app inside tab app
          this.$store.commit(SET_BLOCK_APP_TAB_SELECTION, true); // and with this we prevent select app tab also
        }
        this.selectContainerForEdition();
      }
    },
    autoSaveTitle: _.debounce(function () {
      this.saveTitle();
    }, DEBOUNCE_TIME),
    saveTitle() {
      this.handleDropEvent();
    },
    openMenu() {
      this.menuOpen = !this.menuOpen;
    },
    close() {
      this.menuOpen = false;
    },
    openFullscreenAppModal() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_FULLSCREEN, this.container.id);
    },
    selectContainerForEdition() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_EDITION, this.container);
      this.$store.commit(SET_TOOGLE_TOOLBAR_COLLAPSIBLE, false);
      this.selected = true;
    },
    unSelectContainerForEdition() {
      if (this.saveAlert) {
        this.$store.dispatch(OPEN_SAVE_ALERT);
      } else {
        this.selected = false;
        this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
      }
    },
    selectContainerForInitialization() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_INITIALIZATION, this.appType);
    },
    openConfirmationModal() {
      if (this.container.appTab === null) {
        this.$store.dispatch(SELECT_CONTAINER_FOR_DELETION, this.container.id);
      } else {
        console.log('wowowowowo: ', this.container);
        this.$store.commit(SET_TAB_CONTAINER_ID, { value: this.container.tabAppContainerId });
        this.$store.dispatch(SELECT_TAB_CONTAINER_FOR_DELETION, this.container.id);
      }
    },
    openCommentSection() {
      this.commentSectionOpened = !this.commentSectionOpened;
      this.$nextTick(() => {
        if (this.commentSectionOpened) {
          this.focusFeed();
        }
      });
    },
    duplicate() {
      let msg;
      api.duplicateAppContainer(this.selectedView.uuid, this.container.id).then((container) => {
        if (this.container.appTab === null) {
          this.$store.dispatch(ADD_COPY_CONTAINER, container);
        } else {
          this.$store.commit(SET_CUSTOM_TABS,
            { containerId: container.tabAppContainerId, value: container.customTabs });
        }
        msg = this.$t('view.apps.duplicateSuccessMessage');
        this.showMessage(msg);
      }).catch(() => {
        msg = this.$t('view.apps.duplicateFailedMessage');
        this.showMessage(msg);
      });
    },
    focusFeed() {
      this.$refs.feed.$el.focus();
    },
    resizeContainer() {
      const containerEditor = this.$refs[this.interactiveRef].querySelector('.quillWrapper');
      containerEditor.closest('.droppeable-app').style.height = `${containerEditor.clientHeight}px`;
    },
    configureContainer() {
      this.selectContainerForEdition();
    },
    async handleDropEvent() {
      this.getPosition(this.$refs[this.interactiveRef]);
      const params = {
        title: this.title,
        appType: this.appType,
        position: this.position,
        row: 0,
      };
      if (this.hasContainer) {
        if (this.appType === 'MenuApp') {
          params.position = {};
        }
        this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
      } else {
        if (this.template) {
          params.app = { 'custom_app_template_id': this.template.id };
        }
        if (this.isNew) {
          this.show = false;
          await this.$store.dispatch(SAVE_CONTAINER, { params });
        }
      }
    },
    deselect() {
      this.unSelectContainerForEdition();
      this.$store.commit(SET_BLOCK_APP_TAB_SELECTION, false);
    },
    updateTitleTextConflict(titleTextConflict) {
      this.titleTextConflict = titleTextConflict;
    },
    titleTextConflictValue() {
      return this.titleTextConflict;
    },
    colorSelected() {
      const draggableID = `#${this.draggableID}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        $(polymorphicAppID).addClass('transparent-bg');
      } else {
        $(draggableID).css('background-color', this.containerBackgroundColor);
        $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      }
    },
    applyBorderColor() {
      const draggableID = `#${this.draggableID}`;
      const appContainerID = `#${this.appContainerID}`;
      if (this.borderColor === '') {
        return;
      }
      const borderValue = `0.5px solid ${this.borderColor}`;
      if (this.roundShape) {
        $(draggableID).css({
          borderRadius: '80px',
        });
        $(appContainerID).css({
          borderRadius: '80px',
          border: borderValue,
        });
      } else {
        $(draggableID).css({
          borderRadius: '6px',
        });
        $(appContainerID).css({
          borderRadius: '6px',
          border: borderValue,
        });
      }
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.container !== null && this.container.appType === 'WallApp') {
          document.getElementById('give-me-more-publications').click();
        }
      }
    },
    textAppCase() {
      if (this.appType !== this.appTypes.TEXT) {
        return true;
      }

      if (this.container && this.container.app && this.container.app.visualizationOption) {
        const visualizationOption = this.container.app.visualizationOption;
        if (visualizationOption === 'Scroll') {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
<style scoped>
.dropdown-position {
  right: -25px;
  top: 25px;
}

.comment-icon {
  color: #fff;
}

.file-app {
  min-width: 175px;
  min-height: 70px;
  width: 280px;
  height: 175px;
}

.single-value-app {
  min-width: 175px;
  min-height: 70px;
  width: 280px;
  height: 175px;
}

.button-app {
  min-width: 70px;
  min-height: 70px;
  width: 280px;
  height: 175px;
}

.image-app {
  min-width: 70px;
  min-height: 70px;
  width: 280px;
  height: 280px;
}

.article-app-vertical-orientation {
  min-width: 385px !important;
  max-width: 385px !important;
  min-height: 805px !important;
  width: 385px !important;
  height: 805px;
}

.article-app-horizontal-orientation {
  min-width: 805px !important;
  min-height: 385px !important;
  max-height: 385px !important;
  width: 805px;
  height: 385px;
}

.source-app {
  min-width: 175px;
  min-height: 175px;
  width: 280px;
  height: 280px;
}

.tabs-app {
  width: 900px;
  height: 250px;
  min-width: 385px;
  min-height: 280px;
}

.wall-app {
  height: 490px;
  min-height: 490px;
  max-height: 10000px;
  width: 700px;
  min-width: 280px;
}

.text-app {
  width: 490px;
  height: 280px;
  min-width: 175px;
  min-height: 70px;
}

.table-app {
  min-width: 385px;
  min-height: 175px;
  width: 700px;
  height: 280px;
}

.deck-app {
  min-width: 385px;
  min-height: 175px;
  width: 700px;
  height: 280px;
}

.gantt-app {
  min-width: 385px;
  min-height: 175px;
  height: 385px;
  width: 700px;
  max-height: 20000px;
}

.wall-app-position {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.msg-counter-box {
  padding: 8px;
  height: 56px;
  width: 56px;
  bottom: -12px;
  font-size: 8px;
}

.msg-feed-box {
  padding: 8px;
  min-height: 174px;
  max-height: 500px;
  width: 321px;
  bottom: calc(50% - 250px);
  left: calc(50% - 160px);
  font-size: 8px;
}

.selected {
  outline: solid 1px #002CE5;
}

.container-toolbar {
  height: 43px;
  background: #7A7A7A 0% 0% no-repeat padding-box;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
}

.app-container-title {
  font: normal normal bold 12px/22px Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.hover-effect {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
}

.transparent-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%);
  background: -webkit-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 75%); /* For Firefox 3.6 to 15 */
}

.round-shape {
  border-radius: 80px;
}

</style>
