import api from '../../../api';
import * as mutations from '../../mutation-types';
import * as actions from '../../action-types';

export default {
  [actions.SELECT_CONNECTION_FOR_EDITION]({ commit }, id) {
    commit(mutations.SET_SELECTED_CONNECTION_ID, id);
  },
  [actions.SELECT_CONNECTION_FOR_DELETION]({ commit }, id) {
    commit(mutations.SET_SELECTED_CONNECTION_ID, id);
    commit(mutations.SET_CONFIRMATION_ACTION, actions.DELETE_SELECTED_CONNECTION);
    commit(mutations.SET_DECONFIRMATION_ACTION, actions.UNSELECT_CONNECTION_FOR_DELETION);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, 'connection.delete');
    commit(mutations.OPEN_CONFIRMATION_MODAL);
  },
  [actions.UNSELECT_CONNECTION_FOR_DELETION]({ commit }) {
    commit(mutations.SET_SELECTED_CONNECTION_ID, null);
    commit(mutations.SET_CONFIRMATION_ACTION, null);
    commit(mutations.SET_DECONFIRMATION_ACTION, null);
    commit(mutations.SET_CONFIRMATION_BTN_TEXT, null);
    commit(mutations.CLOSE_CONFIRMATION_MODAL);
  },
  [actions.CLOSE_CONNECTION_MODAL]({ commit }) {
    commit(mutations.SET_SELECTED_CONNECTION_ID, null);
    commit(mutations.CLOSE_CONNECTION_MODAL);
  },
  [actions.DELETE_SELECTED_CONNECTION]({ state, commit, dispatch }) {
    api.deleteConnection(state.selectedId).then(() => {
      commit(mutations.REMOVE_CONNECTION);
      dispatch(actions.UNSELECT_CONNECTION_FOR_DELETION);
    });
  },
  [actions.UPDATE_CONNECTION]({ state, commit }, params) {
    if (state.selectedId) {
      return api.updateConnection({ id: state.selectedId, params }).then((connection) =>
        commit(mutations.UPDATE_SELECTED_CONNECTION, connection),
      );
    }
  },

};
