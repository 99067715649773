<template>
  <div>
    <input
      v-if="titleEditing"
      type="text"
      id="text-editor"
      class="outline-none title w-full"
      placeholder="Título del Contenedor"
      @input="autoSaveTitle"
      v-model="title"
      @focus="titleEditing = true"
      @blur="titleEditing = false"
    >
    <span
      v-else
      class="outline-none w-full cursor-text inline-block align-middle title"
      style="padding-top: 0.4rem;"
      @click="titleEditing = true"
    >
      {{ titleFromContainer }}
    </span>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';
import {
  SAVE_CONTAINER,
} from '../store/action-types';

const DEBOUNCE_TIME = 1000;

export default {
  name: 'AppTitleInOptions',
  props: {
    container: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleEditing: false,
      title: _.get(this.container, 'title', ''),
    };
  },
  computed: {
    ...mapState({
      appContainers: state => state.views.appContainers,
    }),
    titleFromContainer() {
      if (this.container.id in this.appContainers) {
        return this.appContainers[this.container.id].title;
      }

      // TODO: resolver con un state para las apps en el caso app tabs
      return this.container.title;
    },
  },
  methods: {
    autoSaveTitle: _.debounce(function () {
      this.saveTitle();
    }, DEBOUNCE_TIME),
    saveTitle() {
      this.handleSaveTitle();
    },
    async handleSaveTitle() {
      const params = {
        title: this.title,
      };
      await this.$store.dispatch(SAVE_CONTAINER, { params, containerId: this.container.id });
    },
  },
};
</script>

<style scoped>

.title {
  text-align: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
}

.title-bg {
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
}
</style>
