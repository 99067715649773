<template>
  <div class="grid-index  grid-index__bg-white rounded-lg margin-top-56">
    <div class="grid-index__header">
      <div class="grid-index__title">
        {{ $t("user.config") }}
      </div>
    </div>
    <div class="app-modal__inputs app-modal__inputs--tight">
      <div class="relative w-full">
        <div class="bg-action-secondary height-80" />
        <div class="bg-white height-60" />
        <image-upload
          v-if="!readOnly"
          title="Cambiar Imagen de Perfil"
          class="app-modal__profile-picture-input absolute border-4 border-white picture-top"
          :style="{ backgroundImage: profilePictureUrl ? 'url('+ profilePictureUrl +')' : false }"
          @loading-start="loadingImage = true"
          @loading-end="loadingImage = false"
          @uploaded="handleImageUploaded"
        />
        <div
          v-else
          class="app-modal__profile-picture-input absolute border-4 border-white picture-top"
          :style="{ backgroundImage: profilePictureUrl ? 'url('+ profilePictureUrl +')' : false }"
        />
      </div>
      <div class="app-modal__input-header text-title">
        {{ $t("user.nameTag") }}
      </div>
      <div class="mx-4">
        <input
          v-model="firstName"
          type="text"
          :disabled="readOnly"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
        >
      </div>
      <div class="app-modal__input-header text-title">
        {{ $t("user.lastNameTag") }}
      </div>
      <div class="mx-4">
        <input
          v-model="lastName"
          :disabled="readOnly"
          type="text"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
        >
      </div>
      <div class="app-modal__input-header text-title">
        {{ $t("user.email") }}
      </div>
      <div class="mx-4">
        <input
          v-model="email"
          :disabled="readOnly"
          type="email"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
        >
      </div>
      <div
        v-if="!readOnly"
        class="app-modal__input-header text-title"
      >
        {{ $t("template.password") }}
      </div>
      <div
        v-if="!readOnly"
        class="mx-4"
      >
        <input
          type="password"
          v-model="password"
          id="password"
          name="password"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
        >
      </div>
      <div v-if="!validatePassword()">
        <span class="app-modal__error-message"> {{ $t("template.passwordLengthMessage") }} </span>
      </div>
      <div
        v-if="!readOnly"
        class="app-modal__input-header text-title"
      >
        {{ $t("template.verifyPassword") }}
      </div>
      <div
        v-if="!readOnly"
        class="mx-4"
      >
        <input
          type="password"
          v-model="confirmPassword"
          id="confirmPassword"
          name="confirmPassword"
          class="w-full height-48 border border-input rounded-md pl-4 outline-none
                  focus:border-icon-secondary text-menu-label text-size-16"
        >
      </div>
      <div v-if=" !validateConfirmationPassword() ">
        <span class="app-modal__error-message">{{ $t("template.passwordMatchMessage") }}</span>
      </div>
    </div>
    <div
      v-if="!readOnly"
      class="btn app-modal__save-btn"
      :class="{ 'app-modal__save-btn--disabled': !requiredInputsFilled }"
      @click="save"
    >
      {{ $t("template.save") }}
    </div>
    <logout-modal v-if="logoutModalOPened" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LogoutModal from './logout-modal';
import { SELECT_USER_FOR_EDITION, SAVE_USER } from '../store/action-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';

const MIN_LENGTH_PSSWD = 6;

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  components: {
    LogoutModal,
  },
  props: {
    currentUserProp: { type: Object, default: null },
    readOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      firstName: this.currentUserProp.firstName,
      lastName: this.currentUserProp.lastName,
      email: this.currentUserProp.email,
      password: '',
      confirmPassword: '',
      profilePictureUrl: this.currentUserProp.profilePictureUrl,
      loadingImage: false,
      fileSignedId: null,
      logoutModalOPened: false,
    };
  },
  mounted () {
    let currentTitle = document.title.split('|')[0];
    document.title = currentTitle + ' | Mi Cuenta';
  },
  computed: {
    ...mapGetters(['currentUser']),
    userParams() {
      const params = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      };
      if (this.fileSignedId) params.profilePicture = this.fileSignedId;
      if (this.confirmPassword && this.password) params.password = this.confirmPassword;

      return params;
    },
    requiredInputsFilled() {
      if (this.firstName && this.lastName && this.email) {
        if (!(this.password || this.confirmPassword)) {
          return true;
        } else if (this.password && this.confirmPassword) {
          if (this.validatePassword() && this.validateConfirmationPassword()) {
            return true;
          }

          return false;
        }
      }

      return false;
    },
  },
  methods: {
    validatePassword() {
      if (this.password.length < MIN_LENGTH_PSSWD && this.password !== '') {
        return false;
      }

      return true;
    },
    validateConfirmationPassword() {
      if (
        this.confirmPassword !== this.password &&
        this.confirmPassword !== ''
      ) {
        return false;
      }

      return true;
    },
    save() {
      this.selectForEdition();
      this.$store.dispatch(SAVE_USER, this.userParams);
      if (this.requiredInputsFilled) {
        if (this.canDisplayLogoutModal()) {
          this.logoutModalOPened = true;
        }
        this.showMessage(this.$i18n.t('toasts.dataSaved'));
      }
    },
    selectForEdition() {
      this.$store.dispatch(SELECT_USER_FOR_EDITION, this.currentUserProp.id);
    },
    handleImageUploaded({ url, fileSignedId }) {
      this.profilePictureUrl = url;
      this.fileSignedId = fileSignedId;
    },
    canDisplayLogoutModal() {
      if (this.password !== '' && this.confirmPassword !== '') {
        return true;
      }

      return false;
    },
  },
};
</script>
<style scoped>
.height-80 {
  height: 80px;
}

.height-60 {
  height: 60px;
}

.file-uploads {
  position: absolute;
}

.picture-top {
  top: calc(50% - 30px);
}

.app-modal__input {
  margin-left: 0;
}

.margin-top-56 {
  margin-top: 56px;
}

.grid-index__header {
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding-left: 16px;
}
</style>
