<template>
  <textarea
    ref="textarea"
    v-bind="$attrs"
    :value="value"
    v-on="textareaListeners"
  />
</template>

<script>
import autosize from 'autosize';

export default {
  props: { value: { type: String, default: '' } },
  mounted() {
    autosize(this.$refs.textarea);
  },
  methods: {
    focus() {
      this.$refs.textarea.focus();
    },
  },
  computed: {
    textareaListeners() {
      const self = this;

      return {
        ...this.$listeners,
        input: (event) => self.$emit('input', event.target.value),
      };
    },
  },
  watch: {
    value(asd) {
      if (!asd) {
        this.$nextTick(() => autosize.update(this.$refs.textarea));
      }
    },
  },
};
</script>
