<template>
  <div>
    <modal
      name="new-internal-database-modal"
      :width="498"
      :height="607"
      :scrollable="true"
      :click-to-close="false"
      :resizable="false"
    >
      <div
        class="flex flex-row border-b"
        style="height: 54.51px; position: sticky; top: 0px; background-color: white; z-index: 10;"
      >
        <div
          class="w-9/12"
          style="margin-left: 26.38px; margin-top: 15.45px;"
        >
          <i
            class="material-icons material-icons-inline"
            style="color: #565859;"
          >add</i>
          <span class="new-database-title">Nueva base de datos</span>
        </div>
        <div
          class="w-3/12"
        >
          <div
            class="app-modal__close-btn text-right close-box"
            @click="close()"
          >
            <i class="material-icons">close</i>
          </div>
        </div>
      </div>
      <div
        style="margin-left: 26.38px; margin-top: 33.47px; z-index: 1;"
      >
        <span class="database-param-font">
          Nombre
        </span>
        <input
          type="text"
          v-model="name"
          class="database-name-input-param-box input-params-font"
          placeholder="Escribe aquí..."
        >
        <div
          class="flex flex-row"
          style="margin-top: 19.56px;"
        >
          <div class="w-1/12 database-param-font">
            Tipo
          </div>
          <div class="w-6/12 flex items-center flex-row">
            <input
              type="radio"
              id="static"
              value="static"
              v-model="internalType"
            >
            <label
              class="database-param-font ml-2"
              for="static"
            >Carga estática</label>
            <i
              class="material-icons ml-2"
              style="color: #555859; font-size: 1rem;"
            >upload_file</i>
          </div>
          <div class="w-5/12 flex items-center flex-row">
            <input
              type="radio"
              id="dynamic"
              value="dynamic"
              v-model="internalType"
            >
            <label
              class="database-param-font ml-2"
              for="dynamic"
            >Carga dinámica</label>
            <i
              class="material-icons ml-2"
              style="color: #555859; font-size: 1rem;"
            >cached</i>
          </div>
        </div>
        <div
          class="flex flex-row"
        >
          <div class="w-1/12" />
          <span
            class="w-6/12 internal-type-description-font ml-6"
          >Carga manual y/o de archivos desde tu ordenador, como fuente estática.</span>
          <span
            class="w-4/12 internal-type-description-font ml-6"
          >Carga desde una fuente en línea, como fuente dinámica.</span>
          <div class="w-1/12" />
        </div>
        <span class="database-param-font">
          Descripción
        </span>
        <textarea
          type="text"
          v-model="description"
          class="database-name-input-param-box input-params-font text-start textarea"
          placeholder="Escribe aquí..."
          style="height: 66px; padding-top: 8.5px; padding-right: 12.13px;"
        />
        <span class="database-param-font">
          Columnas
        </span>
        <div
          v-for="(column, index) in columnObjects"
          :key="index"
          class="flex flex-row"
          style="padding-right: 32.4px; height: 32px; position: relative; left: -6px; margin-bottom: 14px;"
        >
          <!-- <div
            style="width: 5.4%; margin: auto;"
          >
            <i class="material-icons">drag_indicator</i>
          </div> -->
          <div
            style="width: 52.1%; margin-left: 0.4rem;"
          >
            <input
              type="text"
              v-model="columnObjects[index].columnName"
              class="database-columns-input-param-box input-params-font"
              :placeholder="`Columna ${index + 1}`"
              style="width: 100%;"
            >
          </div>
          <div style="width: 1%;" />
          <div
            style="width: 31.1%; margin: auto;"
          >
            <VueSelectStandard
              class="ml-2 text-center text-sm vue-select-container"
              style="width: 100% !important;"
              :data="types"
              v-model="columnObjects[index]"
              @documentListener="selectType"
              :placeholder="'Texto'"
              :listener="'documentListener'"
              :font-class="'database-param-font'"
              :selected-item-id="`item-${index}`"
              :data-type-case="true"
            />
          </div>
          <div style="width: 3.4%;" />
          <div
            v-if="internalType === 'static'"
            style="width: 5%; margin: auto;"
          >
            <i
              v-if="columnObjects[index].primaryKey"
              class="material-icons blue-color-delete-icon"
              style="cursor: pointer;"
              title="Con la opción actual NO permites duplicados"
              @click="columnObjects[index].primaryKey = false;"
            >key</i>
            <i
              v-else
              class="material-icons"
              style="cursor: pointer;"
              title="Con la opción actual permites duplicados"
              @click="columnObjects[index].primaryKey = true;"
            >lock_open</i>
          </div>
          <div
            v-else
            style="width: 5%; margin: auto;"
          />
          <div style="width: 2.3%;" />
          <div
            style="width: 5.1%; margin: auto;"
          >
            <i
              class="material-icons"
              style="cursor: pointer;"
              :class="{
                'blue-color-delete-icon': !hasMinimumColumns(),
                'grey-color-delete-icon': hasMinimumColumns(),
              }"
              @click="deleteColumn(index)"
            >delete_outline</i>
          </div>
        </div>
        <div
          class="flex flex-row"
          style="margin-bottom: 33.02px;"
        >
          <div style="width: 50.33%;" />
          <a
            class="add-column cursor-pointer"
            style="text-decoration: none; width: 42.8%;"
            @click="addColumn"
          >+ AGREGAR COLUMNA
          </a>
          <div style="width: 6.87%;" />
        </div>
        <div
          v-if="canCreate"
          class="create-database-btn create-database-btn-font"
          style="margin-bottom: 32.76px;"
          @click="createDatabase"
        >
          Crear
        </div>
        <div
          v-else
          class="create-database-btn create-database-btn-font create-disabled"
          style="margin-bottom: 32.76px;"
        >
          Crear
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';
import { CLOSE_APP_MODAL } from '../store/action-types';
import customToolbarHelper from '../helpers/custom-toolbar';
import VueSelectStandard from './vue-select-standard';
import showMessageMixin from '../mixins/showMessageMixin';
import api from '../api';

const COLUMNS_MINIMUM = 3;

export default {
  mixins: [showMessageMixin],
  props: {
    listener: { type: String, default: 'updateDatabasesListener' },
  },
  components: {
    VueSelectStandard,
  },
  data() {
    return {
      customToolbar: customToolbarHelper.customToolbar,
      name: null,
      internalType: 'static',
      description: null,
      columnsQuantity: 3,
      columnObjects: [
        { columnName: null, name: 'Texto', id: 0, primaryKey: false },
        { columnName: null, name: 'Texto', id: 1, primaryKey: false },
        { columnName: null, name: 'Texto', id: 2, primaryKey: false },
      ],
    };
  },
  mounted() {
    $('#header-container').children().first()
      .css('z-index', '0');
    this.openModal();
  },
  computed: {
    ...mapGetters(['selectedView', 'selectedModalToShow']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedItemId: state => state.ui.selectedItemId,
    }),
    modalName() {
      return 'new-internal-database-modal';
    },
    types() {
      const internalTypes = ['Fecha', 'Texto', 'Booleano', 'Número', 'Imagen', 'Archivo'];
      const types = internalTypes.map(
        (type) => ({ id: internalTypes.indexOf(type), name: type }));

      return types;
    },
    canCreate() {
      if (
        this.name !== null && this.name.trim() !== '' &&
        !this.columnObjects
          .map(columnObject => columnObject.columnName)
          .some((name) => name === null || name === '') &&
        this.hasNoDuplicates(this.columnObjects
          .map(columnObject => columnObject.columnName))
      ) {
        return true;
      }

      return false;
    },
    userTypeParams() {
      const params = {
        name: this.name,
        customAttrs: this.columnObjects,
        internalType: this.internalType,
        description: this.description,
      };

      return params;
    },
  },
  methods: {
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    openModal() {
      this.$modal.show(this.modalName);
    },
    selectType(typeSelected) {
      const index = parseInt(this.selectedItemId.split('-')[1], 10);
      this.columnObjects[index].name = typeSelected.name;
    },
    deleteColumn(index) {
      if (this.columnObjects.length === COLUMNS_MINIMUM) {
        this.showMessage(`La cantidad mínima de columnas es ${COLUMNS_MINIMUM}`);
      } else {
        this.columnObjects.splice(index, 1);
      }
    },
    addColumn() {
      this.columnObjects.push({ columnsName: null, name: 'Texto', id: this.columnObjects.length, primaryKey: false });
    },
    hasNoDuplicates(array) {
      const duplicates = array.filter((item, index) => array.indexOf(item) !== index);
      if (duplicates.length > 0) {
        this.showMessage('No puedes tener columnas con el mismo nombre');
      }

      return duplicates.length === 0;
    },
    createDatabase() {
      api.createUserDefinedType(this.userTypeParams)
        .then(result => {
          this.showMessage(`Base de datos '${this.name}' creada exitosamente`);
          console.log('result: ', result);
          this.$emit(this.listener, result);
          this.close();
        });
    },
    hasMinimumColumns() {
      return this.columnObjects.length === COLUMNS_MINIMUM;
    },
  },
};
</script>

<style>
.v--modal-overlay {
  background: #828282DB 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 90;
}

.v--modal-box .v--modal {
  z-index: 90;
}

.vm--modal {
  overflow-y: auto;
}

#header-container {
  z-index: 0 !important;
}

.see-more {
  z-index: 0 !important;
}

.center-horizontal-vertical {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
}

.container-background-color {
  background-color: var(--container-background-color);
}

.close-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 15.45px;
  margin-right: 22px;
}

.new-database-title {
  text-align: left;
  font: normal normal bold 15px/22px Inter;
  letter-spacing: 0px;
  color: #565859;
  opacity: 1;
}

.add-style {
  text-align: left;
  font: normal normal 600 22px/22px Inter;
  letter-spacing: 0px;
  color: #565859;
  opacity: 1;
}

.database-param-font {
  text-align: left;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.database-name-input-param-box {
  height: 32px;
  width: 441px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.database-columns-input-param-box {
  height: 32px;
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.input-params-font {
  text-align: left;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #000000;;
  opacity: 1;
  padding-left: 12.13px;
}

.internal-type-description-font {
  text-align: left;
  font: normal normal 200 10px/13px Inter;
  letter-spacing: 0px;
  color: #7A7A7A;
  opacity: 1;
}

.textarea {
  resize: none;
  overflow: hidden;
}

.add-column {
  text-align: right;
  font: normal normal 600 14px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.create-database-btn {
  background: #0031FF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  height: 34px;
  width: 266px;
  margin: auto;
  cursor: pointer;
}

.create-database-btn-font {
  text-align: center;
  font: normal normal medium 14px/24px Inter;
  letter-spacing: 0.35px;
  color: #FFFFFF;
  opacity: 1;
  line-height: 34px;
}

.create-disabled {
  background: #68778d;
  cursor: not-allowed;
}

.blue-color-delete-icon {
  color: #0031FF !important;
}

.grey-color-delete-icon {
  color: #B3B3B3 !important;
}

</style>
