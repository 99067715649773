<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">person</i>
        <div class="header-text">
          Perfil
        </div>
      </div>
    </div>
    <div>
      <new-data-sources-selector
        :key="newDataSourceSelectorKey"
        :current-datasource="selectedDataSource"
        :columns-checked="[]"
        :tooltip-file-uri="tooltipF"
        :container="container"
        :have-show-data-source="false"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              COLUMNAS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <Collapsible
          class="width-collapsible"
          :is-open="profileImageCollapseOpen"
          :on-collapse="changeProfileImageCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Imagen perfil
              </div>
              <input
                v-model="profilePicCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: B"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Imagen perfil
              </div>
              <input
                v-model="profilePicCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: B"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex flex-col mt-4">
            <div class="flex flex-row">
              <p
                class="text-left text-menu-label text-sm font-inter-type-1 mt-1 w-2/6"
              >
                Posición
              </p>
              <div
                class="pl-2 flex flex-row w-4/6"
              >
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': imageAlignment == 'right' }"
                  @click="changeIconPosition('right')"
                >align_horizontal_right_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': imageAlignment == 'left' }"
                  @click="changeIconPosition('left')"
                >align_horizontal_left_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': imageAlignment == 'bottom' }"
                  @click="changeIconPosition('bottom')"
                >align_vertical_bottom_icon</i>
                <i
                  class="material-icons format-icon cursor-pointer icon-limits"
                  :class="{ 'selected-alignment': imageAlignment == 'top' }"
                  @click="changeIconPosition('top')"
                >align_vertical_top_icon</i>
              </div>
            </div>
          </div>
          <div class="flex mt-1">
            <div class="flex flex-row">
              <div
                class="text-left text-menu-label text-sm font-inter-type-1 inter-sub-section"
                style="margin-right: 2.8rem;"
              >
                Escala
              </div>
              <input
                class="scale"
                type="range"
                v-model="iconsScale"
                name="scale"
                min="75"
                max="100"
                @input="scaleApp"
              >
            </div>
          </div>
          <div
            class="flex mt-1"
            style="padding-right: 14px;"
          >
            <div class="w-4/5" />
            <i
              v-if="showProfileImage"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="No mostrar columna"
              @click="changeAndUpdateShowProfileImage"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="Mostrar columna"
              @click="changeAndUpdateShowProfileImage"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="nameCollapseOpen"
          :on-collapse="changeNameCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-sm font-inter-type-1 cells2">
                Nombre
              </div>
              <input
                v-model="nameCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: C"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-sm font-inter-type-1 cells2">
                Nombre
              </div>
              <input
                v-model="nameCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: C"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div
            class="flex mt-4"
          >
            <p
              class="text-left text-menu-label w-4/6 text-sm font-inter-type-1"
            >
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showNameIcon"
              :sync="true"
              @input="updateShowNameIcon"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="nameIconList"
              v-model="nameIconObject"
              @documentListener="updateNameIcon"
              :placeholder="'Ícono del nombre...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showName"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="No mostrar columna"
              @click="changeAndUpdateShowName"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              title="Mostrar columna"
              @click="changeAndUpdateShowName"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="professionCollapseOpen"
          :on-collapse="changeProfessionCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cargo
              </div>
              <input
                v-model="professionCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: D"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cargo
              </div>
              <input
                v-model="professionCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: D"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showProfessionIcon"
              :sync="true"
              @input="updateShowProfessionIcon"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="professionIconList"
              v-model="professionIconObject"
              @documentListener="updateProfessionIcon"
              :placeholder="'Ícono del cargo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showProfession"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowProfession"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowProfession"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="emailCollapseOpen"
          :on-collapse="changeEmailCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Correo
              </div>
              <input
                v-model="emailCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: E"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Correo
              </div>
              <input
                v-model="emailCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: E"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showEmailIcon"
              :sync="true"
              @input="updateShowEmailIcon"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="emailIconList"
              v-model="emailIconObject"
              @documentListener="updateEmailIcon"
              :placeholder="'Ícono del correo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showEmail"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowEmail"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowEmail"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="phoneCollapseOpen"
          :on-collapse="changePhoneCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Teléfono
              </div>
              <input
                v-model="phoneCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: F"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Teléfono
              </div>
              <input
                v-model="phoneCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: F"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showPhoneIcon"
              :sync="true"
              @input="updateShowPhoneIcon"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="phoneIconList"
              v-model="phoneIconObject"
              @documentListener="updatePhoneIcon"
              :placeholder="'Ícono del cargo...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showPhone"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowPhone"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowPhone"
            >visibility_off</i>
          </div>
        </Collapsible>
        <Collapsible
          class="width-collapsible"
          :is-open="birthdayCollapseOpen"
          :on-collapse="changeBirthdayCollapseOpen"
        >
          <div slot="trigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cumpleaños
              </div>
              <input
                v-model="birthdayCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: G"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >expand_more</i>
            </div>
          </div>
          <div slot="closedTrigger">
            <div class="flex items-center inter-select-title">
              <div class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2">
                Cumpleaños
              </div>
              <input
                v-model="birthdayCell"
                class="w-2/6 text-center text-sm font-inter-type-1 cells"
                placeholder="Ej: G"
              >
              <i
                class="w-1/6 material-icons material-icons-round cursor-pointer"
              >chevron_right</i>
            </div>
          </div>
          <div class="flex mt-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Ícono
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="showBirthdayIcon"
              :sync="true"
              @input="updateShowBirthdayIcon"
            />
          </div>
          <div
            class="flex mt-4"
          >
            <div class="w-1/2" />
            <ToolbarIconSelector
              class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
              :data="birthdayIconList"
              v-model="birthdayIconObject"
              @documentListener="updateBirthdayIcon"
              :placeholder="'Ícono del cumpleaños...'"
              :listener="'documentListener'"
            />
          </div>
          <div class="flex mt-1 icon-selection-container">
            <div class="w-4/5" />
            <i
              v-if="showBirthday"
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="No mostrar columna"
              @click="changeAndUpdateShowBirthday"
            >visibility</i>
            <i
              v-else
              class="w-1/5 flex material-icons visibility-icon cursor-pointer"
              style="padding-bottom: 0.5px;"
              title="Mostrar columna"
              @click="changeAndUpdateShowBirthday"
            >visibility_off</i>
          </div>
        </Collapsible>
        <div
          v-for="(extraColumn, index) in extraColumns"
          :key="`${updateExtraColumnKey}-${index}`"
        >
          <Collapsible
            class="width-collapsible"
            :key="`collapse-${updateExtraColumnKey}-${index}`"
            :is-open="extraColumnIsOpen(index)"
            :on-collapse="() => changeExtraColumnCollapseOpen(index)"
          >
            <div slot="trigger">
              <div class="flex items-center inter-select-title">
                <input
                  v-model="extraColumn.name"
                  class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2"
                  placeholder="Ej: Sueldo"
                >
                <input
                  v-model="extraColumn.cell"
                  class="w-2/6 text-center text-sm font-inter-type-1 cells"
                  placeholder="Ej: G"
                >
                <i
                  class="w-1/6 material-icons material-icons-round cursor-pointer"
                >expand_more</i>
              </div>
            </div>
            <div slot="closedTrigger">
              <div class="flex items-center inter-select-title">
                <input
                  v-model="extraColumn.name"
                  class="w-3/6 text-left text-menu-label text-sm font-inter-type-1 cells2"
                  placeholder="Ej: Sueldo"
                >
                <input
                  v-model="extraColumn.cell"
                  class="w-2/6 text-center text-sm font-inter-type-1 cells"
                  placeholder="Ej: G"
                >
                <i
                  class="w-1/6 material-icons material-icons-round cursor-pointer"
                >chevron_right</i>
              </div>
            </div>
            <div class="flex mt-4">
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                Ícono
              </p>
              <toggle-button
                color="#2c6eeb"
                class="w-2/6"
                :value="extraColumn.showIcon"
                :sync="true"
                @input="updateShowExtraColumnIcon(index)"
              />
            </div>
            <div
              class="flex mt-4"
            >
              <div class="w-1/2" />
              <ToolbarIconSelector
                class="w-1/2 mr-2 text-center text-sm vue-select-container right-padding-icon-selection"
                :data="extraColumnsIconList"
                v-model="extraColumnIconObject"
                @documentListener="updateExtraColumnIcon"
                :placeholder="'Ícono del cumpleaños...'"
                :listener="'documentListener'"
              />
            </div>
            <div class="flex mt-1 icon-selection-container">
              <div class="w-4/5" />
              <i
                v-if="extraColumn.show"
                class="w-1/5 flex material-icons visibility-icon cursor-pointer"
                style="padding-bottom: 0.5px;"
                title="No mostrar columna"
                @click="changeAndUpdateShowExtraColumn(index)"
              >visibility</i>
              <i
                v-else
                class="w-1/5 flex material-icons visibility-icon cursor-pointer"
                style="padding-bottom: 0.5px;"
                title="Mostrar columna"
                @click="changeAndUpdateShowExtraColumn(index)"
              >visibility_off</i>
            </div>
          </Collapsible>
        </div>
        <div
          class="inter-select-title text-left"
          style="margin-bottom: 10px;"
        >
          <a
            class="add-column cursor-pointer"
            style="text-decoration: none;"
            @click="addColumn"
          >+ AGREGAR COLUMNA
          </a>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FILTRO SISTEMA
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FILTRO SISTEMA
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div>
            <VueSelectStandard
              class="text-center text-sm vue-select-container2"
              :data="filtersColumns"
              v-model="columnFilter"
              @documentListener="onColumnFilterSelect"
              :placeholder="'Columna'"
              :listener="'documentListener'"
            />
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex flex-row mb-4">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Imagen
            </p>
            <div class="flex flex-row pr-6">
              <div class="flex flex-row">
                <input
                  class="radio-btn-size mt-1"
                  type="radio"
                  id="round"
                  value="round"
                  v-model="imageShape"
                  @input="updateShape('round')"
                >
                <label
                  class="ml-2 shape-box round-shape-box"
                  for="round"
                ><i
                  class="material-icons icons"
                >person</i>
                </label>
              </div>
              <div class="flex flex-row ml-2">
                <input
                  class="radio-btn-size mt-1"
                  type="radio"
                  id="square"
                  value="square"
                  v-model="imageShape"
                  @input="updateShape('square')"
                >
                <label
                  class="ml-2 shape-box square-shape-box"
                  for="square"
                ><i
                  class="material-icons icons"
                >person</i>
                </label>
              </div>
            </div>
          </div>
          <div class="flex flex-row mb-4">
            <p
              class="text-left text-menu-label text-sm font-inter-type-1 mt-1 w-2/6"
            >
              Contenido
            </p>
            <div
              class="pl-2 flex flex-row w-4/6"
            >
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'left' }"
                @click="changeContentAlignment('left')"
                style="margin-left: 15.2px;"
              >format_align_left</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'center' }"
                @click="changeContentAlignment('center')"
                style="margin-left: 15.2px;"
              >format_align_center</i>
              <i
                class="material-icons format-icon cursor-pointer icon-limits"
                :class="{ 'selected-alignment': contentAlignment == 'right' }"
                @click="changeContentAlignment('right')"
                style="margin-left: 15.2px;"
              >format_align_right</i>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave()"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import api from '../api';
import NewDataSourcesSelector from './new-data-sources-selector';
import ToolbarIconSelector from './toolbar-icon-selector.vue';
import VueSelectStandard from './vue-select-standard';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES } from '../constants';
import {
  SAVE_CONTAINER, CLOSE_APP_MODAL,
  SELECT_CONTAINER_FOR_EDITION,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import {
  SET_SELECTED_DATA_SOURCE,
  SET_TOOGLE_TOOLBAR_COLLAPSIBLE,
  SAVE_ALERT,
  SET_DATA_SOURCE_UPDATED,
} from '../store/mutation-types';
import iconNames from '../helpers/material-icon-names';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEFAULT_VALUE_FOR_CONDITION = '';
const DEFAULT_COLUMN_FILTER = { id: 1, name: 'Correo' };

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    NewDataSourcesSelector,
    Collapsible,
    ContainerSection,
    ToolbarIconSelector,
    AppTitleInOptions,
    VueSelectStandard,
  },
  props: {
    showBordersToggle: { type: Boolean, default: true },
    showShadingToggle: { type: Boolean, default: true },
    showCommentsToggle: { type: Boolean, default: true },
    showPrivateToggle: { type: Boolean, default: true },
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  data() {
    const container = this.$store.getters.selectedContainer || this.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const currentDataSource = this.selectedDataSourceHandler(container);

    return {
      container,
      template,
      containerID: _.get(container, 'id', null),
      showBorders: _.get(container, 'showBorders', false),
      showShading: _.get(container, 'showShading', false),
      showDataSource: _.get(container, 'showDataSource', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      valueForCondition: DEFAULT_VALUE_FOR_CONDITION,
      columnsReady: false,
      maxLengthForFilterStatement: 24,
      maxLengthOfColumnPartForFilter: 4,
      currentDataSource,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialShowBorders: _.get(container, 'showBorders', false),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialDataSource: currentDataSource,
      componentKey: 0,
      newDataSourceSelectorKey: 0,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      profilePicCell: _.get(container, 'app.columnsData.cells.image', 'B'),
      initialProfilePicCell: _.get(container, 'app.columnsData.cells.image', 'B'),
      nameCell: _.get(container, 'app.columnsData.cells.name', 'C'),
      initialNameCell: _.get(container, 'app.columnsData.cells.name', 'C'),
      professionCell: _.get(container, 'app.columnsData.cells.profession', 'D'),
      initialProfessionCell: _.get(container, 'app.columnsData.cells.profession', 'D'),
      emailCell: _.get(container, 'app.columnsData.cells.email', 'E'),
      initialEmailCell: _.get(container, 'app.columnsData.cells.email', 'E'),
      phoneCell: _.get(container, 'app.columnsData.cells.phone', 'F'),
      initialPhoneCell: _.get(container, 'app.columnsData.cells.phone', 'F'),
      birthdayCell: _.get(container, 'app.columnsData.cells.birthday', 'G'),
      initialBirthdayCell: _.get(container, 'app.columnsData.cells.birthday', 'G'),
      nameIconList: iconNames.nameIconList.map(
        (icon) => ({ id: iconNames.nameIconList.indexOf(icon), name: icon })),
      professionIconList: iconNames.professionIconList.map(
        (icon) => ({ id: iconNames.professionIconList.indexOf(icon), name: icon })),
      emailIconList: iconNames.emailIconList.map(
        (icon) => ({ id: iconNames.emailIconList.indexOf(icon), name: icon })),
      phoneIconList: iconNames.phoneIconList.map(
        (icon) => ({ id: iconNames.phoneIconList.indexOf(icon), name: icon })),
      birthdayIconList: iconNames.birthdayIconList.map(
        (icon) => ({ id: iconNames.birthdayIconList.indexOf(icon), name: icon })),
      extraColumnsIconList: iconNames.iconsList.map(
        (icon) => ({ id: iconNames.iconsList.indexOf(icon), name: icon })),
      nameIcon: _.get(container, 'app.columnsData.icons.name', 'person'),
      initialNameIcon: _.get(container, 'app.columnsData.icons.name', 'person'),
      professionIcon: _.get(container, 'app.columnsData.icons.profession', 'work'),
      initialProfessionIcon: _.get(container, 'app.columnsData.icons.profession', 'work'),
      emailIcon: _.get(container, 'app.columnsData.icons.email', 'email'),
      initialEmailIcon: _.get(container, 'app.columnsData.icons.email', 'email'),
      phoneIcon: _.get(container, 'app.columnsData.icons.phone', 'phone'),
      initialPhoneIcon: _.get(container, 'app.columnsData.icons.phone', 'phone'),
      birthdayIcon: _.get(container, 'app.columnsData.icons.birthday', 'cake'),
      initialBirthdayIcon: _.get(container, 'app.columnsData.icons.birthday', 'cake'),
      profileImageCollapseOpen: false,
      nameCollapseOpen: false,
      professionCollapseOpen: false,
      emailCollapseOpen: false,
      phoneCollapseOpen: false,
      birthdayCollapseOpen: false,
      showNameIcon: _.get(container, 'app.columnsData.showIcons.name', true),
      initialShowNameIcon: _.get(container, 'app.columnsData.showIcons.name', true),
      showProfessionIcon: _.get(container, 'app.columnsData.showIcons.profession', true),
      initialShowProfessionIcon: _.get(container, 'app.columnsData.showIcons.profession', true),
      showEmailIcon: _.get(container, 'app.columnsData.showIcons.email', true),
      initialShowEmailIcon: _.get(container, 'app.columnsData.showIcons.email', true),
      showPhoneIcon: _.get(container, 'app.columnsData.showIcons.phone', true),
      initialShowPhoneIcon: _.get(container, 'app.columnsData.showIcons.phone', true),
      showBirthdayIcon: _.get(container, 'app.columnsData.showIcons.birthday', true),
      initialShowBirthdayIcon: _.get(container, 'app.columnsData.showIcons.birthday', true),
      showProfileImage: _.get(container, 'app.columnsData.shows.image', true),
      initialShowProfileImage: _.get(container, 'app.columnsData.shows.image', true),
      showName: _.get(container, 'app.columnsData.shows.name', true),
      initialShowName: _.get(container, 'app.columnsData.shows.name', true),
      showProfession: _.get(container, 'app.columnsData.shows.profession', true),
      initialShowProfession: _.get(container, 'app.columnsData.shows.profession', true),
      showEmail: _.get(container, 'app.columnsData.shows.email', true),
      initialShowEmail: _.get(container, 'app.columnsData.shows.email', true),
      showPhone: _.get(container, 'app.columnsData.shows.phone', true),
      initialShowPhone: _.get(container, 'app.columnsData.shows.phone', true),
      showBirthday: _.get(container, 'app.columnsData.shows.birthday', true),
      initialShowBirthday: _.get(container, 'app.columnsData.shows.birthday', true),
      tooltipF: 'https://docs.google.com/spreadsheets/d/1UHpEIyH8jxmbIfv_MIyfcxW5bCmoNj6msLxEkUvcK8E/edit?usp=sharing',
      imageAlignment: _.get(container, 'app.imageAlignment', 'top'),
      initialImageAlignment: _.get(container, 'app.imageAlignment', 'top'),
      imageShape: _.get(container, 'app.imageShape', 'round'),
      initialImageShape: _.get(container, 'app.imageShape', 'round'),
      contentAlignment: _.get(container, 'app.contentAlignment', 'center'),
      initialContentAlignment: _.get(container, 'app.contentAlignment', 'center'),
      iconsScale: _.get(container, 'app.iconsScale', '100'),
      initialIconsScale: _.get(container, 'app.iconsScale', '100'),
      filtersColumns: [
        { id: 1, name: 'Correo' },
      ],
      columnFilter: DEFAULT_COLUMN_FILTER,
      extraColumns: _.get(container, 'app.columnsData.extraColumns', []),
      extraColumnsCollapseOpen: new Array(_.get(container, 'app.columnsData.extraColumns', []).length).fill(false),
      updateExtraColumnKey: 0,
      currentExtraColumnIndex: null,
      initialExtraColumns: JSON.parse(JSON.stringify(_.get(container, 'app.columnsData.extraColumns', []))),
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    nameIconObject() {
      return { name: this.nameIcon, id: this.nameIconList.indexOf(this.nameIcon) || 0 };
    },
    professionIconObject() {
      return { name: this.professionIcon, id: this.professionIconList.indexOf(this.professionIcon) || 0 };
    },
    emailIconObject() {
      return { name: this.emailIcon, id: this.emailIconList.indexOf(this.emailIcon) || 0 };
    },
    phoneIconObject() {
      return { name: this.phoneIcon, id: this.phoneIconList.indexOf(this.phoneIcon) || 0 };
    },
    birthdayIconObject() {
      return { name: this.birthdayIcon, id: this.birthdayIconList.indexOf(this.birthdayIcon) || 0 };
    },
    extraColumnIconObject() {
      return {
        name: this.currentExtraColumnIndex === null ?
          'add_circle' : this.extraColumns[this.currentExtraColumnIndex].icon,
        id: this.currentExtraColumnIndex === null ?
          0 : this.extraColumnsIconList.indexOf(this.extraColumns[this.currentExtraColumnIndex].icon),
      };
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        showDataSource: this.showDataSource,
        ...this.appWithType,
      };
    },
    appWithType() {
      return {
        app: {
          dataSource: this.selectedDataSource,
          columnsData: this.constructColumnsData(),
          systemFilter: 'user_email',
          imageShape: this.imageShape,
          imageAlignment: this.imageAlignment,
          contentAlignment: this.contentAlignment,
          iconsScale: this.iconsScale,
        },
        appType: 'ProfileApp',
      };
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      dataSourceModalOpened: state => state.ui.dataSourceModalOpened,
      selectedDataSource: state => state.users.selectedDataSource,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    constructColumnsData() {
      return {
        cells: {
          image: this.profilePicCell,
          name: this.nameCell,
          profession: this.professionCell,
          email: this.emailCell,
          phone: this.phoneCell,
          birthday: this.birthdayCell,
        },
        icons: {
          name: this.nameIcon,
          profession: this.professionIcon,
          email: this.emailIcon,
          phone: this.phoneIcon,
          birthday: this.birthdayIcon,
        },
        shows: {
          image: this.showProfileImage,
          name: this.showName,
          profession: this.showProfession,
          email: this.showEmail,
          phone: this.showPhone,
          birthday: this.showBirthday,
        },
        showIcons: {
          profession: this.showProfessionIcon,
          email: this.showEmailIcon,
          phone: this.showPhoneIcon,
          birthday: this.showBirthdayIcon,
        },
        extraColumns: this.extraColumns,
      };
    },
    headersReadyHandler() {
      if (this.selectedDataSource.id === null) {
        return true;
      }

      return this.headersReady;
    },
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    reopen() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_EDITION, this.container);
      this.$store.commit(SET_TOOGLE_TOOLBAR_COLLAPSIBLE, false);
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    resetInitialValues6() {
      this.initialProfilePicCell = this.profilePicCell;
      this.initialNameCell = this.nameCell;
      this.initialProfessionCell = this.professionCell;
      this.initialEmailCell = this.emailCell;
      this.initialPhoneCell = this.phoneCell;
      this.initialBirthdayCell = this.birthdayCell;
      this.initialExtraColumns = JSON.parse(JSON.stringify(this.extraColumns));
    },
    resetInitialValues5() {
      this.initialNameIcon = this.nameIcon;
      this.initialProfessionIcon = this.professionIcon;
      this.initialEmailIcon = this.emailIcon;
      this.initialPhoneIcon = this.phoneIcon;
      this.initialBirthdayIcon = this.birthdayIcon;
      this.resetInitialValues6();
    },
    resetInitialValues4() {
      this.initialShowNameIcon = this.showNameIcon;
      this.initialShowProfessionIcon = this.showProfessionIcon;
      this.initialShowEmailIcon = this.showEmailIcon;
      this.initialShowPhoneIcon = this.showPhoneIcon;
      this.initialShowBirthdayIcon = this.showBirthdayIcon;
      this.resetInitialValues5();
    },
    resetInitialValues3() {
      this.initialShowProfileImage = this.showProfileImage;
      this.initialShowName = this.showName;
      this.initialShowProfession = this.showProfession;
      this.initialShowEmail = this.showEmail;
      this.initialShowPhone = this.showPhone;
      this.initialShowBirthday = this.showBirthday;
      this.resetInitialValues4();
    },
    resetInitialValues2() {
      this.initialIsPrivate = this.isPrivate;
      this.initialShowShading = this.showShading;
      this.initialShowDataSource = this.showDataSource;
      this.initialImageAlignment = this.imageAlignment;
      this.initialContentAlignment = this.contentAlignment;
      this.initialImageShape = this.imageShape;
      this.initialIconsScale = this.iconsScale;
      this.resetInitialValues3();
    },
    resetInitialValues() {
      this.initialDataSource = this.selectedDataSource;
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialShowBorders = this.showBorders;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.resetInitialValues2();
    },
    async saveApp() {
      if (!this.canSave()) return;
      if (JSON.stringify(_.pick(this.initialDataSource,
        'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
      JSON.stringify(_.pick(this.selectedDataSource,
        'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId'))) {
        this.$store.commit(SET_DATA_SOURCE_UPDATED, true);
      }
      if (JSON.stringify(this.initialExtraColumns) !== JSON.stringify(this.extraColumns)) {
        this.$store.commit(SET_DATA_SOURCE_UPDATED, true);
      }
      const containerSaved = await this.dispatchSaveContainer(this.appWithContainer, this.containerID);
      console.log(containerSaved);
      this.saveMessage();
      this.newDataSourceSelectorKey += 1;
      this.componentKey += 1;
      this.resetInitialValues();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    canSave() {
      const canSave = this.requiredAppInputsFilled();
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    sortedObject(unordered) {
      return Object.keys(unordered).sort().reduce(
        (obj, key) => {
          obj[key] = unordered[key];

          return obj;
        }, {});
    },
    columnsAnalysis2() {
      return this.initialNameIcon !== this.nameIcon ||
        this.initialProfessionIcon !== this.professionIcon ||
        this.initialEmailIcon !== this.emailIcon ||
        this.initialPhoneIcon !== this.phoneIcon ||
        this.initialBirthdayIcon !== this.birthdayIcon ||
        this.initialProfilePicCell !== this.profilePicCell ||
        this.initialNameCell !== this.nameCell ||
        this.initialProfessionCell !== this.professionCell ||
        this.initialEmailCell !== this.emailCell ||
        this.initialPhoneCell !== this.phoneCell ||
        this.initialBirthdayCell !== this.birthdayCell;
    },
    columnsAnalysis3() {
      return JSON.stringify(this.initialExtraColumns) !== JSON.stringify(this.extraColumns);
    },
    columnsAnalysis() {
      return this.initialShowProfileImage !== this.showProfileImage ||
        this.initialShowNameIcon !== this.showNameIcon ||
        this.initialShowProfessionIcon !== this.showProfessionIcon ||
        this.initialShowEmailIcon !== this.showEmailIcon ||
        this.initialShowPhoneIcon !== this.showPhoneIcon ||
        this.initialShowBirthdayIcon !== this.showBirthdayIcon ||
        this.initialShowProfileImage !== this.showProfileImage ||
        this.initialShowName !== this.showName ||
        this.initialShowProfession !== this.showProfession ||
        this.initialShowEmail !== this.showEmail ||
        this.initialShowPhone !== this.showPhone ||
        this.initialShowBirthday !== this.showBirthday ||
        this.columnsAnalysis2() || this.columnsAnalysis3();
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTransparency !== this.showTransparency ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialShowBorders !== this.showBorders ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) ||
        this.initialImageAlignment !== this.imageAlignment ||
        this.initialImageShape !== this.imageShape ||
        this.initialContentAlignment !== this.contentAlignment ||
        parseInt(this.initialIconsScale, 10) !== parseInt(this.iconsScale, 10) ||
        this.columnsAnalysis()
      ) {
        return true;
      }

      return false;
    },
    correctDataRange(dataSourceInAnalysis) {
      if (!['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange)) {
        const rangeAnalysis = dataSourceInAnalysis.dataRange.split('!');
        const fromAndToCells = rangeAnalysis[1].split(':');
        if (fromAndToCells[0] === '' || fromAndToCells[1] === '') {
          return false;
        }
      }

      return true;
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== undefined && dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (service === 'gsheet') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table' &&
              this.correctDataRange(dataSourceInAnalysis) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'custom') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table_internal' &&
              dataSourceInAnalysis.source_id !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'excel') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'excel' &&
              this.correctDataRange(dataSourceInAnalysis) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone && this.changesToSend();
    },
    async createDataSource() {
      const containerSaved = await this.dispatchSaveContainer(this.appWithDataSourceAndContainer,
        this.selectedContainerId);
      console.log(containerSaved);
      const response = await api.getDataSourceValues(this.selectedDataSource.id);

      return response;
    },
    selectedDataSourceHandler(container) {
      const currentDataSource = this.$store.state.users.selectedDataSource || container.app.dataSource;
      this.$store.commit(SET_SELECTED_DATA_SOURCE, currentDataSource);

      return currentDataSource;
    },
    updateNameIcon(iconObject) {
      this.nameIcon = iconObject.name;
      this.updateAppAttrs();
    },
    updateProfessionIcon(iconObject) {
      this.professionIcon = iconObject.name;
      this.updateAppAttrs();
    },
    updateEmailIcon(iconObject) {
      this.emailIcon = iconObject.name;
      this.updateAppAttrs();
    },
    updatePhoneIcon(iconObject) {
      this.phoneIcon = iconObject.name;
      this.updateAppAttrs();
    },
    updateBirthdayIcon(iconObject) {
      this.birthdayIcon = iconObject.name;
      this.updateAppAttrs();
    },
    updateExtraColumnIcon(iconObject) {
      this.extraColumns[this.currentExtraColumnIndex].icon = iconObject.name;
      this.updateAppAttrs();
    },
    changeProfileImageCollapseOpen() {
      if (!this.profileImageCollapseOpen) {
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
        this.nameCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.profileImageCollapseOpen = !this.profileImageCollapseOpen;
    },
    changeNameCollapseOpen() {
      if (!this.nameCollapseOpen) {
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
        this.profileImageCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.nameCollapseOpen = !this.nameCollapseOpen;
    },
    changeProfessionCollapseOpen() {
      if (!this.professionCollapseOpen) {
        this.nameCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
        this.profileImageCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.professionCollapseOpen = !this.professionCollapseOpen;
    },
    changeEmailCollapseOpen() {
      if (!this.emailCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.birthdayCollapseOpen = false;
        this.profileImageCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.emailCollapseOpen = !this.emailCollapseOpen;
    },
    changePhoneCollapseOpen() {
      if (!this.phoneCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.birthdayCollapseOpen = false;
        this.profileImageCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.phoneCollapseOpen = !this.phoneCollapseOpen;
    },
    changeBirthdayCollapseOpen() {
      if (!this.birthdayCollapseOpen) {
        this.nameCollapseOpen = false;
        this.professionCollapseOpen = false;
        this.emailCollapseOpen = false;
        this.phoneCollapseOpen = false;
        this.profileImageCollapseOpen = false;
        this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      }
      this.birthdayCollapseOpen = !this.birthdayCollapseOpen;
    },
    changeExtraColumnCollapseOpen(index) {
      this.extraColumnsCollapseOpen = new Array(this.extraColumnsCollapseOpen.length).fill(false);
      this.extraColumnsCollapseOpen[index] = !this.extraColumnsCollapseOpen[index];
      this.nameCollapseOpen = false;
      this.professionCollapseOpen = false;
      this.emailCollapseOpen = false;
      this.phoneCollapseOpen = false;
      this.profileImageCollapseOpen = false;
      this.birthdayCollapseOpen = false;
      if (this.extraColumnsCollapseOpen[index]) {
        this.currentExtraColumnIndex = index;
      }
    },
    changeAndUpdateShowProfileImage() {
      this.showProfileImage = !this.showProfileImage;
      this.updateAppAttrs();
    },
    changeAndUpdateShowExtraColumn(index) {
      this.extraColumns[index].show = !this.extraColumns[index].show;
    },
    changeAndUpdateShowName() {
      this.showName = !this.showName;
      this.updateAppAttrs();
    },
    changeAndUpdateShowProfession() {
      this.showProfession = !this.showProfession;
      this.updateAppAttrs();
    },
    changeAndUpdateShowEmail() {
      this.showEmail = !this.showEmail;
      this.updateAppAttrs();
    },
    changeAndUpdateShowPhone() {
      this.showPhone = !this.showPhone;
      this.updateAppAttrs();
    },
    changeAndUpdateShowBirthday() {
      this.showBirthday = !this.showBirthday;
      this.updateAppAttrs();
    },
    updateShowNameIcon() {
      this.showNameIcon = !this.showNameIcon;
      this.updateAppAttrs();
    },
    updateShowProfessionIcon() {
      this.showProfessionIcon = !this.showProfessionIcon;
      this.updateAppAttrs();
    },
    updateShowEmailIcon() {
      this.showEmailIcon = !this.showEmailIcon;
      this.updateAppAttrs();
    },
    updateShowPhoneIcon() {
      this.showPhoneIcon = !this.showPhoneIcon;
      this.updateAppAttrs();
    },
    updateShowBirthdayIcon() {
      this.showBirthdayIcon = !this.showBirthdayIcon;
      this.updateAppAttrs();
    },
    updateShowExtraColumnIcon(index) {
      this.extraColumns[index].showIcon = !this.extraColumns[index].showIcon;
      this.updateAppAttrs();
    },
    updateAppAttrs() {
      const columnsData = this.constructColumnsData();
      const params = {
        imageShape: this.imageShape,
        imageAlignment: this.imageAlignment,
        contentAlignment: this.contentAlignment,
        iconsScale: this.iconsScale,
        showDataSource: this.showDataSource,
        nameIcon: columnsData.icons.name,
        professionIcon: columnsData.icons.profession,
        emailIcon: columnsData.icons.email,
        phoneIcon: columnsData.icons.phone,
        birthdayIcon: columnsData.icons.birthday,
        showNameIcon: this.showNameIcon,
        showProfessionIcon: this.showProfessionIcon,
        showEmailIcon: this.showEmailIcon,
        showPhoneIcon: this.showPhoneIcon,
        showBirthdayIcon: this.showBirthdayIcon,
        showImage: columnsData.shows.image,
        showName: columnsData.shows.name,
        showProfession: columnsData.shows.profession,
        showEmail: columnsData.shows.email,
        showPhone: columnsData.shows.phone,
        showBirthday: columnsData.shows.birthday,
        imageCell: columnsData.cells.image,
        nameCell: columnsData.cells.name,
        professionCell: columnsData.cells.profession,
        emailCell: columnsData.cells.email,
        phoneCell: columnsData.cells.phone,
        birthdayCell: columnsData.cells.birthday,
        dataSource: this.selectedDataSource,
        extraColumns: this.extraColumns,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    changeIconPosition(imageAlignment) {
      this.imageAlignment = imageAlignment;
      this.updateAppAttrs();
    },
    onColumnFilterSelect(value) {
      this.columnFilter = value;
    },
    updateShape(newShape) {
      this.imageShape = newShape;
      this.updateAppAttrs();
    },
    changeContentAlignment(contentAlignment) {
      this.contentAlignment = contentAlignment;
      this.updateAppAttrs();
    },
    scaleApp() {
      this.updateAppAttrs();
    },
    addColumn() {
      const extraColumnsCopy = JSON.parse(JSON.stringify(this.extraColumns));
      extraColumnsCopy.push({
        name: 'Columna '.concat(this.extraColumnsCollapseOpen.length + 1),
        cell: 'X',
        show: true,
        showIcon: true,
        icon: 'add_circle',
      });
      this.extraColumns = extraColumnsCopy;
      this.extraColumnsCollapseOpen.push(false);
      this.currentExtraColumnIndex = this.extraColumnsCollapseOpen.length - 1;
      this.updateExtraColumnKey += 1;
    },
    extraColumnIsOpen(index) {
      return this.extraColumnsCollapseOpen[index];
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.container-section {
  padding-left: 14.14px;
  height: 370px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.min-h-300px {
  min-height: 300px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  width: 61.15px;
  height: 32px;
}

.cells2 {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  color: black;
  border: 0.5px solid #7A7A7A;
  border-right-style: none;
  border-radius: 3px 0 0 3px;
  opacity: 1;
  height: 32px;
  padding-top: 5.4px;
  padding-left: 10px;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.visibility-icon {
  color: #7A7A7A;
  opacity: 1;
}

.delete-outline-icon {
  color: #2C6EEB;
  opacity: 1;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}
/* TITLE SECTION STYLE end */

/* FILTERS SECTION STYLE start*/
.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 100px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
/* FILTERS SECTION STYLE end*/

.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}

.icon-selection-container {
  margin-bottom: 120px;
  padding-right: 14px;
}

.right-padding-icon-selection {
  padding-right: 0px;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.vue-select-container2 {
  width: 206.27px;
  height: 42px;
  margin-bottom: 50px;
}

.shape-box {
  opacity: 1;
  text-align: center;
  font: normal normal bold 10px/24px Inter;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  height: 23px;
  width: 23px;
  background: grey;
}

.square-shape-box {
  border-radius: 3px;
}

.round-shape-box {
  border-radius: 50%;
}

.icons {
  color: white;
  display: inline-block;
  font-size: 1.8em;
  margin-top: 0.15em;
}

.scale {
  background: #B3B3B3 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

input[type=range]:focus {
  outline: none;
}

input[type=range] {
  width: 100px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: 0;
  margin: 10px;
  padding: 0;
  height: 3.62px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 9.74px;
  width: 9.74px;
  border-radius: 50%;
  background: #555859;
}

.add-column {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

</style>
