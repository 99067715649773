<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.13/vue.js"></script>
<template>
  <div
    class="container-top"
  >
    <div
      id="my-personal-id"
      class="sticky-publication-box"
      v-if="currentUserCanPublish"
    >
      <div class="flex flex-row publication-box">
        <div style="width: 3.4%;" />
        <img
          class="avatar-image"
          :src="userProfilePictureUrl"
        >
        <div style="width: 3.2%;" />
        <div
          id="publication-box"
          class="publication-text-box flex flex-col"
          style="width: 82.4%;"
        >
          <textarea-autoheight
            style="width: 100%;"
            v-model="newPublication"
            class="comment__input feed__new-comment-input outline-none publication-font"
            placeholder="Escribe tu publicación..."
          />
          <div
            v-if="previewType === 'image' && !this.loadingFile"
            id="image-preview"
            class="publication-resource-margin"
          >
            <a
              :href="fileUrl"
              target="_blank"
              class="cursor-pointer"
            >
              <img
                :src="fileUrl"
                class="rounded-lg centered"
                style="max-height: 304px;"
              >
            </a>
          </div>
          <div
            v-else-if="previewType === 'video' && !this.loadingFile"
            id="video-preview"
            class="publication-resource-margin"
          >
            <video
              id="video"
              controls="controls"
              class="rounded-lg centered"
              style="max-height: 304px;"
            >
              <source
                :src="fileUrl"
                class="rounded-lg centered"
              >
            </video>
          </div>
          <div
            v-else-if="previewType === 'document' && !this.loadingFile"
            class="publication-resource-margin"
          >
            <a
              :href="fileUrl"
              target="_blank"
              class="cursor-pointer"
              type="application/pdf"
            >
              <div>
                <embed
                  :src="fileUrl"
                  class="rounded-lg centered"
                  width="100%"
                  type="application/pdf"
                  style="height: 391px; margin-right: 26px; width: 95%;"
                >
              </div>
            </a>
          </div>
          <div
            v-if="formattedText !== '' && fileName === null"
            class="publication-resource-margin rounded-lg"
          >
            <a
              :href="formattedText"
              target="_blank"
              class="cursor-pointer"
            >
              <LinkPreview
                id="link-preview"
                :card-width="containerWidth"
                :show-button="false"
                width="100%"
                height="100%"
                :url="formattedText"
              >
                <template slot-scope="props">
                  <div class="card">
                    <img
                      class="card-img-top"
                      :src="props.img"
                      :alt="props.title"
                    >
                    <div class="card-block">
                      <h4 class="card-title">
                        {{ props.title }}
                      </h4>
                      <p class="card-text">
                        {{ props.description }}
                      </p>
                    </div>
                  </div>
                </template>
              </LinkPreview>
            </a>
          </div>
          <div
            v-if="this.loadingFile"
          >
            <div
              :style="{width:containerWidth}"
            >
              <slot name="loading">
                <div
                  class="spinner"
                />
              </slot>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="publish-button-container">
              <div
                v-if="newPublication"
                class="publish-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
                @click="createPublication()"
              >
                Publicar
              </div>
              <div
                v-else
                class="publish-disable border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
                  app-modal__save-btn--disabled"
              >
                Publicar
              </div>
            </div>
            <div class="flex flex-row">
              <div
                class="flex flex-row"
                style="padding-top: 10px;"
              >
                <button
                  :id="imageIconID"
                  @click="imageClick()"
                  :disabled="resourcesNotLoaded"
                >
                  <i
                    class="material-icons icon"
                  >photo</i>
                </button>
                <button
                  :id="videoIconID"
                  @click="videoClick()"
                  :disabled="resourcesNotLoaded"
                >
                  <i
                    class="material-icons icon"
                  >smart_display</i>
                </button>
                <button
                  :id="documentIconID"
                  @click="documentClick()"
                  :disabled="resourcesNotLoaded"
                >
                  <i
                    class="material-icons icon"
                  >insert_drive_file</i>
                </button>
                <div
                  id="hidden-inputs"
                >
                  <input
                    style="display: none;"
                    type="file"
                    :id="imageInputID"
                    :accept="imageTypeAccept"
                    @change="onImageChange"
                  >
                  <input
                    style="display: none;"
                    type="file"
                    :id="videoInputID"
                    :accept="videoTypeAccept"
                    @change="onVideoChange"
                  >
                  <input
                    style="display: none;"
                    type="file"
                    :id="documentInputID"
                    :accept="documentTypeAccept"
                    @change="onDocumentChange"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 3.1%;" />
      </div>
    </div>
    <div
      class="publications-background"
      id="publicationsInFeed"
    >
      <publication
        v-for="(publication) in publicationsSorted"
        :key="publication.id"
        :publication="publication"
        :comment-image="userProfilePictureUrl"
        :comments-enabled="commentsEnabled"
        :can-comment="currentUserCanPublish"
      />
    </div>
    <div
      class="more-publications-margins"
      v-show="shouldShowMorePublications"
    >
      <div
        class="flex flex-row place-self-center"
        style="margin: auto; width: max-content;"
      >
        <button
          id="give-me-more-publications"
          class="show-more-publications-font"
          @click="morePublications()"
        >
          Mostrar más publicaciones
        </button>
        <i
          class="material-icons cursor-pointer"
          style="color: #2B2B2B;"
          @click="morePublications()"
        >expand_more</i>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import { mapState } from 'vuex';
import { DirectUpload } from 'activestorage';
import {
  CREATE_APP_CONTAINER_PUBLICATION,
  CREATE_THREAD_COMMENT,
  CREATE_LINK_METADATA,
  MORE_PUBLICATIONS,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import { ADD_PUBLICATIONS } from '../store/mutation-types';
import FileUpload from 'vue-upload-component';
import Publication from './publication';
import LinkPreview from './link-prevue-bp.vue';

export default {
  props: {
    comments: { type: Array, required: true },
    container: { type: Object, default: null },
  },
  components: {
    FileUpload,
    Publication,
    LinkPreview,
  },
  data() {
    const container = this.container;
    let publishersRoles = _.get(container, 'app.publishersRoles', []);
    if (publishersRoles === null || publishersRoles === undefined) {
      publishersRoles = [
        'Todos',
        'Admins',
        'Readers',
        'Devs',
        'Owners',
        'Platform Owners',
      ];
    }
    const fileName = this.defineFileName(container);
    this.defineWallAppAttrs();

    return {
      containerID: _.get(container, 'id', -1),
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      showShading: _.get(container, 'showShading', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      visualizationOption: _.get(container, 'app.visualizationOption', 'Click en botón'),
      publicationsTotal: _.get(container, 'app.publicationsTotal', 0),
      toggleList: false,
      publishersRoles: JSON.parse(JSON.stringify(publishersRoles)),
      newPublication: null,
      loadingFile: false,
      fileUrl: _.get(container, 'app.fileUrl', null),
      fileSignedId: null,
      fileName,
      previewType: null,
      updatedID: 0,
      initialPublications: _.get(container, 'app.publications', null),
      lastFormattedText: null,
      morePublicationsClicks: 0,
    };
  },
  mounted() {
    this.changeBorderRadiusInContainer();
    // this.changeContainerBackground();
    this.colorSelected();
    this.$store.commit(ADD_PUBLICATIONS, this.initialPublications);
    this.scroll();
  },
  updated() {
    if (this.formattedText !== '' && this.newPublication !== null) {
      const currentWidth = $(this.draggableID).width();
      const linkMargin = 0.4455 * currentWidth + 273;
      $('#publicationsInFeed').css('margin-top', linkMargin);
    } else {
      $('#publicationsInFeed').css('margin-top', '0px');
    }
    const firstUrlInPublication = this.formattedText;
    if (firstUrlInPublication !== '' &&
      (this.lastFormattedText === null || firstUrlInPublication !== this.lastFormattedText)) {
      this.lastFormattedText = firstUrlInPublication;
      this.asyncPreviewMetadata();
    }
    this.colorSelected();
  },
  computed: {
    ...mapState({
      appContainers: state => state.views.appContainers,
      linkPreviewMetadata: state => state.views.linkPreviewMetadata,
      publications: state => state.views.publications,
      appAttrs: state => state.views.appsAttrs,
    }),
    draggableID() {
      return `#draggable${this.containerID}`;
    },
    appContainerID() {
      return `#app-container${this.containerID}`;
    },
    polymorphicAppID() {
      return `polymorphic-app${this.container.id}`;
    },
    draggableID2() {
      return `draggable${this.container.id}`;
    },
    containerIdJS() {
      return `#app-container${this.container.id}`;
    },
    appContainerID2() {
      return `app-container${this.container.id}`;
    },
    imageInputID() {
      return `image-input-${this.containerID}`;
    },
    videoInputID() {
      return `video-input-${this.containerID}`;
    },
    documentInputID() {
      return `document-input-${this.containerID}`;
    },
    imageIconID() {
      return `image-icon-${this.containerID}`;
    },
    videoIconID() {
      return `video-icon-${this.containerID}`;
    },
    documentIconID() {
      return `document-icon-${this.containerID}`;
    },
    imageTypeAccept() {
      return 'image/png, image/jpg, image/jpeg, image/gif';
    },
    videoTypeAccept() {
      return 'video/mp4';
    },
    documentTypeAccept() {
      return 'application/pdf';
    },
    userProfilePictureUrl() {
      return _.get(this.$store.getters.currentUser, 'profilePictureUrl');
    },
    containerWidth() {
      return $('#publication-box').width().toString().concat('px');
    },
    commentsEnabled() {
      return this.appAttrs[this.container.id].commentsEnabled;
    },
    resourcesUpload() {
      if (this.fileName !== null || this.formattedText !== '') {
        return true;
      }

      return false;
    },
    formattedText() {
      return this.urlIdentification(this.newPublication);
    },
    dispatchCreateMetadata() {
      const params = [CREATE_LINK_METADATA,
          { url: this.formattedText, publicationId: null }
        ];
      return params;
    },
    dispatchMorePublicationsParams() {
      return [MORE_PUBLICATIONS, { containerId: this.containerID,
        clicks: this.morePublicationsClicks }];
    },
    dispatchCreateParams() {
      let params;
      if (this.containerID) {
        params = [CREATE_APP_CONTAINER_PUBLICATION,
          { content: this.newPublication, containerId: this.containerID,
            fileUrl: this.fileUrl, file: this.fileSignedId }
        ];
      } else if (this.parentId) {
        params = [CREATE_PUBLICATION_THREAD_COMMENT, { content: this.newComment, parentCommentId: this.parentId }];
      }

      return params;
    },
    publicationsSorted() {
      return _.orderBy(this.publications, ['createdAt'], ['desc']);
    },
    resourcesNotLoaded() {
      return this.previewType !== null || this.formattedText !== '';
    },
    rolesToCompare() {
      return {
        'platform_owner': 'Platform Owners',
        'owner': 'Owners',
        'dev': 'Developers',
        'viewer': 'Readers',
        'admin': 'Admins',
      };
    },
    defaultPublishersRoles() {
      return [
        'Todos',
        'Admins',
        'Readers',
        'Devs',
        'Owners',
        'Platform Owners',
      ];
    },
    currentUserCanPublish() {
      if (this.$store.getters.currentUser) {
        const currentUserRole = this.rolesToCompare[this.$store.getters.currentUser.role];
        const publisherMatch = this.publishersRoles.find(role => role === currentUserRole);
        if (publisherMatch) {
          return true;
        }
      }

      return false;
    },
    shouldShowMorePublications() {
      return this.publicationsSorted.length > 0 && this.publicationsSorted.length < this.publicationsTotal;
    }
  },
  methods: {
    async asyncPreviewMetadata() {
      this.$store.dispatch(...this.dispatchCreateMetadata).then((response) => {
        return response;
      });
    },
    defineFileName(container) {
      const fileName = _.get(container, 'app.fileName', null);
      if (fileName === 'default_placeholder.png') {
        return null;
      }

      return fileName;
    },
    changeBorderRadiusInContainer() {
      const containerID = this.appContainerID;
      $(containerID).removeClass('rounded-lg');
    },
    changeContainerBackground() {
      const containerID = this.appContainerID;
      $(containerID).css('background-color', '#FBFBFB');
    },
    createPublication() {
      if (!this.newPublication) return;
      const self = this;
      this.$store.dispatch(...this.dispatchCreateParams).then(() => {
        self.newPublication = null;
        self.fileUrl = null;
        self.fileSignedId = null;
        self.fileName = null;
        self.previewType = null;
      });
    },
    handleUploaded(url, fileSignedId, name) {
      this.fileUrl = url;
      this.fileSignedId = fileSignedId;
      this.fileName = name;
    },
    handleImageUploaded({ url, fileSignedId, name }) {
      this.previewType = 'image';
      this.handleUploaded(url, fileSignedId, name);
      document.getElementById(this.videoIconID).disabled = true;
      document.getElementById(this.documentIconID).disabled = true;
    },
    handleVideoUploaded({ url, fileSignedId, name }) {
      this.previewType = 'video';
      this.handleUploaded(url, fileSignedId, name);
      document.getElementById(this.imageIconID).disabled = true;
      document.getElementById(this.documentIconID).disabled = true;
    },
    handleDocumentUploaded({ url, fileSignedId, name }) {
      this.previewType = 'document';
      this.handleUploaded(url, fileSignedId, name);
      document.getElementById(this.imageIconID).disabled = true;
      document.getElementById(this.videoIconID).disabled = true;
    },
    toDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    imageClick() {
      document.getElementById(this.imageInputID).click();
    },
    videoClick() {
      document.getElementById(this.videoInputID).click();
    },
    documentClick() {
      document.getElementById(this.documentInputID).click();
    },
    onImageChange(e) {
      const image = e.target.files[0];
      this.uploadImageFile(image);
    },
    onVideoChange(e) {
      const video = e.target.files[0];
      this.uploadVideoFile(video);
    },
    onDocumentChange(e) {
      const document = e.target.files[0];
      this.uploadDocumentFile(document);
    },
    uploadImageFile(file) {
      const upload = new DirectUpload(file, '/api/v1/direct_uploads');
      this.loadingFile = true;
      upload.create(async (error, blob) => {
        if (!error) {
          const imageUrl = await this.toDataUrl(file);
          this.loadingFile = false;
          this.handleImageUploaded({ url: imageUrl, fileSignedId: blob.signed_id, name: file.name });
        }
      });
    },
    uploadVideoFile(file) {
      const upload = new DirectUpload(file, '/api/v1/direct_uploads');
      this.loadingFile = true;
      upload.create(async (error, blob) => {
        if (!error) {
          const imageUrl = await this.toDataUrl(file);
          this.loadingFile = false;
          this.handleVideoUploaded({ url: imageUrl, fileSignedId: blob.signed_id, name: file.name });
        }
      });
    },
    uploadDocumentFile(file) {
      const upload = new DirectUpload(file, '/api/v1/direct_uploads');
      this.loadingFile = true;
      upload.create(async (error, blob) => {
        if (!error) {
          const imageUrl = await this.toDataUrl(file);
          this.loadingFile = false;
          this.handleDocumentUploaded({ url: imageUrl, fileSignedId: blob.signed_id, name: file.name });
        }
      });
    },
    urlIdentification(text) {
      if (!text) {
        return '';
      }
      const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      const match = text.match(urlRegex);

      return match ? match[0] : '';
    },
    morePublications() {
      this.morePublicationsClicks += 1;
      this.$store.dispatch(...this.dispatchMorePublicationsParams);
    },
    scroll() {
      $(window).scroll(function(counter) {
        if($(window).scrollTop() + $(window).height() == $(document).height()) {
          document.getElementById('give-me-more-publications').click();
        }
      });
    },
    defineWallAppAttrs() {
      const params = {
        commentsEnabled: _.get(this.container, 'app.commentsEnabled', false),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    addTransparency(draggableID, polymorphicAppID) {
      $(draggableID).addClass('transparent-bg');
      $(polymorphicAppID).addClass('transparent-bg');
      $(this.containerIdJS).addClass('transparent-bg');
      if (this.container.appType === 'ArticleApp') {
        $(this.polymorphicAppIdJS).find('.vueperslide__content-wrapper').addClass('transparent-bg');
      }
    },
    removeTransparency(draggableID, polymorphicAppID) {
      $(draggableID).removeClass('transparent-bg');
      $(polymorphicAppID).removeClass('transparent-bg');
      $(this.containerIdJS).removeClass('transparent-bg');
    },
    applyBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', this.containerBackgroundColor);
      $(polymorphicAppID).css('background-color', this.containerBackgroundColor);
      $(this.containerIdJS).css('background-color', this.containerBackgroundColor);
    },
    applyTransparentBackgroundColor(draggableID, polymorphicAppID) {
      $(draggableID).css('background-color', 'transparent');
      $(polymorphicAppID).css('background-color', 'transparent');
      $(this.containerIdJS).css('background-color', 'transparent');
    },
    colorSelected() {
      const draggableID = `#${this.draggableID2}`;
      const polymorphicAppID = `#${this.polymorphicAppID}`;
      if (this.showTransparency || this.containerBackgroundColor === '') {
        this.applyTransparentBackgroundColor(draggableID, polymorphicAppID);
        this.addTransparency(draggableID, polymorphicAppID);
      } else {
        this.removeTransparency(draggableID, polymorphicAppID);
        this.applyBackgroundColor(draggableID, polymorphicAppID);
      }
    },
    borderColorSelected() {
      if (this.borderColor === '') {
        return;
      }
      const draggableID = `#${this.draggableID}`;
      const appContainerID = `#${this.appContainerID2}`;
      const borderValue = `0.5px solid ${this.borderColor}`;
      if (this.roundShape) {
        $(draggableID).css({
          borderRadius: '80px',
        });
        $(appContainerID).css({
          borderRadius: '80px',
          border: borderValue,
        });
      } else {
        $(draggableID).css({
          borderRadius: '6px',
        });
        $(appContainerID).css({
          borderRadius: '6px',
          border: borderValue,
        });
      }
    },
  },
};
</script>

<style scoped>

.container-top {
  position: relative;
  width: 100%;
  max-height: 400px;
  top: 0;
  left: 0;
}

.icon {
  color: #B3B3B3;
  height: 30px;
  width: 30px;
  font-size: 1.2em;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.publication-box {
  padding-top: 20.56px;
  padding-bottom: 20.56px;
  margin-right: 15.75px;
  width: inherit;
  height: auto;
  z-index: 100;
}

.sticky-publication-box {
  position: relative;
  height: auto;
  width: inherit;
  z-index: 100;
}

.avatar-image {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  object-fit: cover;
}

.publication-text-box {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
}

.centered {
  display:block;
  margin:auto;
}

button:disabled {
  cursor: not-allowed;
}

.publication-font {
  font: normal normal normal 16px/20px Inter;
  letter-spacing: 0px;
  color: #2B2B2B;
}

.publish-button-container {
  width: 140px;
  height: 55px;
}

.publish-button {
  width: 100px;
  margin-bottom: 6px;
  background: #0031ff 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/20px Inter;
  text-align: center;
  letter-spacing: 0px;
  border-radius: 6px;
  margin-left: 12px;
}

.publish-disable {
  width: 100px;
  margin-bottom: 6px;
  font: normal normal normal 14px/20px Inter;
  text-align: center;
  letter-spacing: 0px;
  border-radius: 6px;
  margin-left: 12px;
}

.card-title {
  padding-top: 15px;
  font: normal normal bold 16px/20px Inter;
  text-align: left;
  letter-spacing: 0px;
  width: 80%;
  margin: 0 auto;
}

.card-text {
  padding-top: 6px;
  font: normal normal normal 10px/10px Inter;
  text-align: left;
  letter-spacing: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  margin: 0 auto;
}

.card-block {
  background-color: #DDE6F779;
  padding-bottom: 15px;
  border-radius: 0 0 0.5rem 0.5rem;
}

.card-img-top {
  border-radius: 0.5rem 0.5rem 0 0;
}

.wall-background {
  background-color: #FBFBFB;
}

.aux-background {
  background-color: #FBFBFB;
}

.publications-background {
  /* background-color: #FBFBFB; */
}

.show-more-publications-font {
  font: normal normal 800 17px/20px Inter;
  letter-spacing: 0.34px;
  color: #2B2B2B;
  opacity: 1;
}

.more-publications-margins {
  padding-top: 17.8px;
  padding-bottom: 17.8px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
  opacity: 1;
  margin: 17.8px 17.8px 0 17.8px;
}

.publication-resource-margin {
  padding-top: 22px;
  margin-top: 0;
  margin-left: .55rem;
  margin-right: .55rem;
}

</style>
