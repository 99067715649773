<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">tab</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Tabs
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENIDO TABS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENIDO TABS
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div
            :key="index"
            v-for="(tab, index) in customTabs"
            class="flex items-center mb-4"
          >
            <input
              type="text"
              placeholder="Nombre del tab..."
              @input="autoSaveTitle(tab.id)"
              maxlength="60"
              v-model="tabTitles[tab.id]"
              class="w-10/12 text-left text-sm font-inter-type-1 cells"
              style="padding-top: 2px; padding-left: 12px;"
            >
            <i
              class="w-2/12 material-icons delete-outline-icon cursor-pointer"
              style="font-size: 1.3em; padding-top: 2px;"
              @click="deleteTab(tab.id)"
            >delete_outline</i>
          </div>
          <div
            class="inter-select-title text-left"
            style="margin-bottom: 13.86px;"
          >
            <a
              class="add-file cursor-pointer"
              style="text-decoration: none;"
              @click="addNewTab()"
            >+ AGREGAR TAB
            </a>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
          style="margin-bottom: 170px;"
        >
          <div class="text-left text-menu-label text-sm font-inter-type-1">
            Posición:
          </div>
          <div class="flex flex-row mt-2 mb-4 ml-2">
            <div class="flex items-center flex-row w-1/2">
              <input
                type="radio"
                id="horizontal"
                value="horizontal"
                v-model="orientation"
                @change="updateOrientation"
              >
              <label
                class="text-menu-label text-sm font-inter-type-1 ml-2"
                for="horizontal"
              >Horizontal</label>
            </div>
            <br>
            <div class="flex items-center flex-row w-1/2">
              <input
                type="radio"
                id="Vertical"
                value="vertical"
                v-model="orientation"
                @change="updateOrientation"
              >
              <label
                class="text-menu-label text-sm font-inter-type-1 ml-2"
                for="vertical"
              >Vertical</label>
            </div>
            <br>
          </div>
          <template>
            <div class="flex p-0 form__field">
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 mb-0 pb-0">
                Color Fondo
              </p>
              <div
                class="w-2/6 mb-0 pb-0 form__input"
              >
                <v-swatches
                  id="containerBackgroundSwatch"
                  class="mr-8"
                  :swatches="containerColorsWithTransparent"
                  :trigger-style="triggerStyles"
                  :swatch-style="swatchStyles"
                  :close-on-select="true"
                  show-fallback
                  fallback-input-type="color"
                  v-model="tabColor"
                  @input="tabsColorsSelected"
                  row-length="4"
                />
              </div>
            </div>
          </template>
          <template>
            <div class="flex mt-4 p-0 form__field">
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1 mb-0 pb-0">
                Color Texto
              </p>
              <div
                class="w-2/6 mb-0 pb-0 form__input"
              >
                <v-swatches
                  id="borderColorSwatch"
                  class="mr-8"
                  :swatches="containerColorsWithTransparent"
                  :trigger-style="triggerStyles"
                  :swatch-style="swatchStyles"
                  :close-on-select="true"
                  show-fallback
                  fallback-input-type="color"
                  v-model="fontColor"
                  @input="tabsColorsSelected"
                  row-length="4"
                />
              </div>
            </div>
          </template>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px; margin-bottom: 50px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import VSwatches from 'vue-swatches';
import Collapsible from 'vue-collapsible-component';
import {
  SET_CUSTOM_TABS,
  SET_APP_TAB_COLORS,
  SAVE_ALERT,
  UPDATE_CONTAINER,
} from '../store/mutation-types';
import {
  SAVE_CONTAINER,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import { CONTAINER_COLORS_WITH_TRANSPARENT, VUE_SWATCHES_ATTRIBUTES, BASE_COLORS } from '../constants';
import api from '../api';
import showMessageMixin from '../mixins/showMessageMixin';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEBOUNCE_TIME = 1000;
const MIN_TABS_NUMBER = 2;
const MAX_TABS_NUMBER = 10;

export default {
  mixins: [showMessageMixin],
  components: {
    VSwatches,
    Collapsible,
    AppTitleInOptions,
    ContainerSection,
  },
  data() {
    const container = this.$store.getters.selectedContainer;

    return {
      orientation: _.get(container, 'app.orientation', 'horizontal'),
      baseColors: BASE_COLORS,
      tabColor: _.get(container, 'app.colors.backgroundColor', '#555859'),
      fontColor: _.get(container, 'app.colors.fontColorActive', '#ffffff'),
      customTabs: _.get(container, 'app.customTabs', []),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      borderColor: _.get(container, 'borderColor', ''),
      containerID: _.get(container, 'id', -1),
      showShading: _.get(container, 'showShading', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialOrientation: _.get(container, 'app.orientation', 'horizontal'),
      initialTabColor: _.get(container, 'app.colors.backgroundColor', '"#555859"'),
      initialFontColor: _.get(container, 'app.colors.fontColorActive', '#ffffff'),
      title: _.get(container, 'title', ''),
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      container: 'selectedContainer',
    }),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
    appWithContainer() {
      return {
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        showShading: this.showShading,
        commentsEnabled: this.commentsEnabled,
        isPrivate: this.isPrivate,
        ...this.appWithType,
      };
    },
    appWithType() {
      const appAndType = {
        app: {
          orientation: this.orientation,
          colors: {
            backgroundColor: this.tabColor,
            fontColorActive: this.fontColor,
            fontColor: `${this.fontColor}80`,
          },
        },
        appType: 'AppTab',
      };

      return appAndType;
    },
    appInputsReady() {
      return !!(true);
    },
    tabParams() {
      return { title: 'Escribe aqui' };
    },
    appTabParams() {
      return {
        orientation: this.orientation,
        colors: {
          backgroundColor: this.tabColor,
          fontColorActive: this.fontColor,
          fontColor: `${this.fontColor}80`,
        } };
    },
    displayableTabs() {
      return this.container.app.customTabs;
    },
    tabTitles() {
      return this.customTabs.reduce((obj, currentTab) => {
        obj[currentTab.id] = currentTab.title;

        return obj;
      }, {});
    },
    changesToSend() {
      if (
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowShading !== this.showShading ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialOrientation !== this.orientation ||
        this.initialTabColor !== this.tabColor ||
        this.initialFontColor !== this.fontColor
      ) {
        return true;
      }

      return false;
    },
    canSave() {
      const canSave = this.changesToSend;
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
  },
  methods: {
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      const response = await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      console.log('response: ', response);
      this.saveMessage();
      this.resetInitialValues();
    },
    resetInitialValues2() {
      this.initialOrientation = this.orientation;
      this.initialTabColor = this.tabColor;
      this.initialFontColor = this.fontColor;
    },
    resetInitialValues() {
      this.initialContainerBackgroundColor = this.containerBackgroundColor.toLowerCase();
      this.initialBorderColor = this.borderColor.toLowerCase();
      this.initialShowShading = this.showShading;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialIsPrivate = this.isPrivate;
      this.resetInitialValues2();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    async updateOrientation() {
      const response = await api.updateAppTab({ id: this.container.app.id, params: this.appTabParams });
      console.log('response is', response);
      this.$store.commit(SET_APP_TAB_COLORS, { containerId: this.container.id, value: response });
    },
    async deleteTab(tabId) {
      if (this.customTabs.length === MIN_TABS_NUMBER) {
        this.minTabsLimitMessage();

        return;
      }
      const response = await api.deleteTab({ appTabId: this.container.app.id, tabId });
      console.log('response after deletation', response.customTabs);
      this.$store.commit(SET_CUSTOM_TABS, { containerId: this.container.id, value: response.customTabs });
      this.customTabs = response.customTabs;
    },
    async addNewTab() {
      if (this.customTabs.length === MAX_TABS_NUMBER) {
        this.maxTabsLimitMessage();

        return;
      }
      const response = await api.createTab({ id: this.container.app.id, params: this.tabParams });
      this.$store.commit(SET_CUSTOM_TABS, { containerId: this.container.id, value: response.customTabs });
      this.customTabs = response.customTabs;
    },

    autoSaveTitle: _.debounce(async function (tabId) {
      console.log('tabId', tabId);
      this.saveName(tabId);
    }, DEBOUNCE_TIME),
    async saveName(tabId) {
      const response = await api.updateTab({
        id: this.container.app.id,
        tabId,
        params: { customTab: { title: this.tabTitles[tabId] },
        },
      });
      this.$store.commit(SET_CUSTOM_TABS, { containerId: this.container.id, value: response.customTabs });
    },
    async colorSelected() {
      const response = await api.updateAppTab({ id: this.container.app.id, params: this.appTabParams });
      console.log('response is', response);
      this.$store.commit(SET_APP_TAB_COLORS, { containerId: this.container.id, value: response });
    },
    tabsColorsSelected() {
      const containerCopy = JSON.parse(JSON.stringify(this.container));
      containerCopy.app.colors = { backgroundColor: this.tabColor, fontColorActive: this.fontColor,
        fontColor: `${this.fontColor}80`,
      };

      this.$store.commit(UPDATE_CONTAINER, containerCopy);
    },
    minTabsLimitMessage() {
      this.showMessage(
        `No puedes tener menos de ${MIN_TABS_NUMBER} tabs`,
      );
    },
    maxTabsLimitMessage() {
      this.showMessage(
        `No puedes tener más de ${MAX_TABS_NUMBER} tabs`,
      );
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>

<style scoped>

.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #4ECA85;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 193px;
  height: 32px;
  color: #555859;
}

.add-file {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.delete-outline-icon {
  color: #2C6EEB;
  opacity: 1;
  width: 30px;
  height: 30px;
}

.scrollable-toolbar-item-option {
  height: 850px;
  overflow: scroll;
  position: relative;
  z-index: 100;
  padding-bottom: 60px;
}

</style>
