<link href="node_modules/gridstack/dist/gridstack.min.css" rel="stylesheet"/>
<script src="node_modules/gridstack/dist/gridstack-all.js"></script>
<template :key="componentKey">
  <div>
    <div
      v-if="appsEditable"
      style="position: absolute; top: 0; z-index: 0; height: 0; padding-left: 9.5px; margin-top: 35px;"
      class="wrapper"
    >
      <div
        :id="`matrix-element-${square}`"
        v-for="(square) in squares"
        :key="`matrix-element-${square}`"
        :class="{
          'item': appsEditable,
        }"
      />
    </div>
    <div
      style="top: 0; z-index: 1; margin-top: 25.25px;"
    >
      <section
        class="grid-stack"
        id="wrapper-grid"
      >
        <div
          v-for="(container, index) in appContainersFiltered"
          :key="index"
          :gs-x="container.position.x"
          :gs-y="container.position.y"
          :gs-w="container.position.w"
          :gs-h="container.position.h"
          :gs-min-w="minWidthByApp(container)"
          :gs-min-h="minHeightByApp(container)"
          :gs-max-w="maxWidthByApp(container)"
          :gs-max-h="maxHeightByApp(container)"
          :gs-no-resize="appsEditable ? false : true"
          :gs-no-move="appsEditable ? false : true"
          :gs-sub-grid-dynamic="container.appType === 'AppTab'"
          :id="container.id"
          class="grid-stack-item"
        >
          <app-floating
            :id="container.id"
            :container="container"
            :app-type="container.appType"
            :apps-editable="appsEditable"
            class="grid-stack-item-content menu-conflict-solution"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import { mapState } from 'vuex';
import {
  SAVE_CONTAINER,
  UPDATE_APP_CONTAINERS_FROM_TAB_TO_MAIN_GRID,
} from '../store/action-types';
import api from '../api';
import { APP_DIMENSIONS_BY_TYPE } from '../constants';
import undoRedoMixin from '../mixins/undoRedoMixin';

const COLUMNS_NUMBER = 12;
const ROWS_NUMBER = 95;
const CELL_HEIGHT = 89;
const GAP_BETWEEN_CELLS = 9.5;

export default {
  mixins: [undoRedoMixin],
  props: {
    appsEditable: { type: Boolean, default: false },
  },
  data() {
    return {
      grid: undefined,
      count: 0,
      timerId: undefined,
      gridOptions: {
        cellHeight: CELL_HEIGHT,
        margin: GAP_BETWEEN_CELLS,
        column: COLUMNS_NUMBER,
        acceptWidgets: true,
        disableDrag: false,
        disableResize: false,
        float: true,
        minRow: 1,
        resizable: {
          handles: 'all',
        },
        disableOneColumnMode: true,
      },
      appDimensionsByType: APP_DIMENSIONS_BY_TYPE,
      elementIdToExcludeUpdateOnChange: null,
    };
  },
  mounted() {
    window.appsEditable = this.appsEditable;
    const newGridOptions = this.gridOptions;
    newGridOptions['disableDrag'] = this.appsEditable ? false : true;
    newGridOptions['disableResize'] = this.appsEditable ? false : true;
    this.grid = GridStack.init(newGridOptions);
    window.grid = this.grid;
    this.grid.on('dragstop', (event, element) => {
      this.elementIdToExcludeUpdateOnChange = element.id;
      if (!window.withZoom && this.appsDraggable) {
        this.updateContainerPosition(element);
      }
    });
    this.grid.on('resizestop', (event, element) => {
      this.elementIdToExcludeUpdateOnChange = element.id;
      if (!window.withZoom && this.appsDraggable) {
        this.updateContainerPosition(element);
      }
    });
    this.grid.on('change', (event, items) => {
      if (!window.withZoom && this.appsDraggable) {
        items.forEach(item => {
          if (this.elementIdToExcludeUpdateOnChange !== item.el.id) {
            this.updateContainerPosition(item.el);
          }
        });
        this.elementIdToExcludeUpdateOnChange = null;
      }
    });
    this.grid.on('dropped', (event, previousWidget, newWidget) => {
      this.updatePositionAndGrid(newWidget);
    });
  },
  computed: {
    ...mapState({
      tabSelectedId: state => state.views.tabSelectedId,
      appContainers: state => state.views.appContainers,
      appsDraggable: state => state.views.appsDraggable,
      appFloatingUpdater: state => state.views.appFloatingUpdater,
    }),
    appContainersFiltered() {
      const filtered = _.pickBy(this.appContainers, (container) => container.appType !== undefined);

      return filtered;
    },
    squares() {
      const squares = [];
      for (let rowIndex = 1; rowIndex <= ROWS_NUMBER; rowIndex++) {
        for (let columnIndex = 1; columnIndex <= COLUMNS_NUMBER; columnIndex++) {
          const elementDenomination = `${rowIndex}-${columnIndex}`;
          squares.push(elementDenomination);
        }
      }

      return squares;
    },
    appsFloatingUpdater() {
      if (Object.keys(this.appFloatingUpdater).length === 0) {
        return 0;
      }

      return Object.keys(this.appFloatingUpdater).map(v => this.appFloatingUpdater[v]).join('-');
    },
    componentKey() {
      return `${Object.keys(this.appContainers).length}-${this.appsFloatingUpdater}`;
    },
  },
  methods: {
    updateContainerPosition(element) {
      const node = element.gridstackNode;
      const containerId = node.el.id;
      const newPosition = {
        x: node.x,
        y: node.y,
        w: node.w,
        h: node.h,
      };
      const params = {
        position: newPosition,
      };
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId: containerId });
    },
    maxWidthByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.maxWidth.ArticleAppVertical;
        }

        return this.appDimensionsByType.maxWidth.ArticleAppHorizontal;
      }

      return 12;
    },
    maxHeightByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.maxHeight.ArticleAppVertical;
        }

        return this.appDimensionsByType.maxHeight.ArticleAppHorizontal;
      }

      return 95;
    },
    minWidthByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.minWidth.ArticleAppVertical;
        }

        return this.appDimensionsByType.minWidth.ArticleAppHorizontal;
      }

      return this.appDimensionsByType.minWidth[container.appType];
    },
    minHeightByApp(container) {
      if (container.appType === 'ArticleApp') {
        if (container.app.contentOrientation === 'vertical') {
          return this.appDimensionsByType.minHeight.ArticleAppVertical;
        }

        return this.appDimensionsByType.minHeight.ArticleAppHorizontal;
      }

      return this.appDimensionsByType.minHeight[container.appType];
    },
    async updatePositionAndGrid(newWidget) {
      const containersInTab = await this.$store.dispatch(UPDATE_APP_CONTAINERS_FROM_TAB_TO_MAIN_GRID,
        { tabId: this.tabSelectedId, params: this.positionContainerParams(newWidget), containerId: newWidget.el.id });
    },
    positionContainerParams(newWidget) {
      return {
        position: {
          x: newWidget.x,
          y: newWidget.y
        }
      }
    },
  },
};
</script>

<style>
.wrapper {
  display: grid;
  grid-template-columns: 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px 70px;
  grid-auto-rows: 70px;
  grid-gap: 19px;
}

.item {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.grid-stack {
  background: transparent;
  width: 1068px;
}

.grid-stack-item-content {
  background-color: rgba(255, 255, 255, var(--bg-opacity));
}

.ui-resizable-se {
  width: 0;
  height: 0;
  background-image: none !important;
}

.ui-resizable-sw {
  width: 0;
  height: 0;
  background-image: none !important;
}

.menu-conflict-solution {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.grid-stack>.grid-stack-item>.ui-resizable-s {
  bottom: 13px !important;
  cursor: url('../../assets/images/cursor-down.png'), auto;
  transform: translate(0, -31px);
}

.grid-stack>.grid-stack-item>.ui-resizable-n {
  top: -2px !important;
  cursor: url('../../assets/images/cursor-up.png'), auto;
}

.grid-stack>.grid-stack-item>.ui-resizable-ne {
  cursor: url('../../assets/images/cursor-up-right.png'), auto;
  transform: translate(-20px, 0);
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
  cursor: url('../../assets/images/cursor-down-right.png'), auto;
  transform: translate(-20px, -29px);
}

.grid-stack>.grid-stack-item>.ui-resizable-nw {
  cursor: url('../../assets/images/cursor-up-left.png'), auto;
  transform: translate(-18px, -17px);
}

.grid-stack>.grid-stack-item>.ui-resizable-sw {
  cursor: url('../../assets/images/cursor-down-left.png'), auto;
  transform: translate(-24px, -23px);
}

.grid-stack>.grid-stack-item>.ui-resizable-e {
  cursor: url('../../assets/images/cursor-right.png'), auto;
  transform: translate(-35px, 0);
}

.grid-stack>.grid-stack-item>.ui-resizable-w {
  cursor: url('../../assets/images/cursor-left.png'), auto;
  transform: translate(-12px, 0);
}
</style>
