<template>
  <div
    :class="{
      'come-up': isOpen,
      'come-down': !isOpen
    }"
  >
    <label
      id="listbox-label"
      class="block text-sm font-medium"
    >
      <slot name="SelectLabel" />
    </label>
    <div
      class="relative"
      v-click-outside="closeDropdown"
    >
      <span class="inline-block rounded-md shadow-sm btn-dimensions">
        <button
          type="button"
          @click="openDropdown"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="cursor-pointer relative rounded-md border-gray-300 bg-white pl-3 pr-10 py-1 text-left
            focus:outline-none focus:shadow-outline-blue transition ease-in-out duration-150
            sm:text-sm sm:leading-5 btn-dimensions select"
        >
          <div class="flex items-center space-x-3">
            <span
              class="block truncate selected-bold"
              v-if="value != ''"
            >
              <i
                class="material-icons format-icon cursor-pointer"
              >{{ value.name }}</i>
            </span>
            <span
              class="block truncate"
              v-else
            >
              {{ placeholder }}
            </span>
          </div>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </span>
        </button>
      </span>
      <!-- Select popover, show/hide based on select state. -->
      <div
        v-show="isOpen"
        class="absolute mt-1 rounded-md shadow-lg scrollable-option ul-style"
        :style="heightStyle"
      >
        <ul
          class=""
        >
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
            Highlighted: "text-white bg-selector", Not Highlighted: "text-gray-900"
          -->
          <li
            tabindex="0"
            @click="select(dataHash)"
            id="listbox-item-0"
            role="option"
            v-for="dataHash in data"
            :key="dataHash.id"
            class="z-100 text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer
              hover:text-white hover:bg-selector focus:outline-none focus:text-white focus:bg-selector"
          >
            <div class="flex items-center space-x-3">
              <span
                class="block truncate small-letter"
                :class="{ 'font-normal' : !isSelected(dataHash.name) , 'selected-bold' : isSelected(dataHash.name)}"
              >
                <i
                  class="material-icons format-icon cursor-pointer"
                >{{ dataHash.name }}</i>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import googleApiClientMixin from '../mixins/googleApiClientMixin';

const MAX_ELEMENTS_TO_DISPLAY_IN_THE_LIST = 4;
const INDIVIDUAL_HEIGHT_FOR_A_LIST_ELEMENT = 38;

Vue.directive('click-outside', {
  bin(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

export default {
  mixins: [googleApiClientMixin],
  name: 'ToolbarIconSelector',
  props: {
    value: { type: Object, default() {
      return {
        name: 'dato 1',
        id: 1,
      };
    } },
    data: { type: Array, default: () => [
      { id: 1, name: 'Dato 1' },
      { id: 2, name: 'Dato 2' },
      { id: 3, name: 'Dato ...' },
      { id: 4, name: 'Dato n' },
    ] },
    placeholder: { type: String, default: 'Selecciona una opción...' },
    listener: { type: String, default: 'valueSelect' },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    heightStyle() {
      const length = this.data.length;
      if (length >= MAX_ELEMENTS_TO_DISPLAY_IN_THE_LIST) {
        return 'height: 152px;';
      }

      return 'height: '.concat((length * INDIVIDUAL_HEIGHT_FOR_A_LIST_ELEMENT).toString(), 'px;');
    },
  },
  methods: {
    isSelected(value) {
      return this.value.name === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    openDropdown() {
      this.isOpen = true;
    },
    select(value) {
      this.isOpen = false;
      this.$emit(this.listener, value);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>

.btn-dimensions {
  height: 32px;
}

.come-up {
  z-index: 100;
  position: relative;
}

.come-down {
  z-index: 0;
  position: relative;
}

.arrows-padding {
  padding-left: 18px;
}

.select {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
}

.ul-style {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000058;
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  width: 75px;
  margin-left: 12px;
}

.scrollable-option{
  height: 162px;
  overflow: auto;
  position: relative;
  z-index: 100;
}

.small-letter {
  font-style: normal;
  font-variant: normal;
  font-size: 9px;
  line-height: 22px;
  font-family: Inter;
}

.selected-bold {
  filter: brightness(0) saturate(100%) invert(0%) sepia(95%)
    saturate(19%) hue-rotate(274deg) brightness(89%) contrast(104%);
}
</style>
