<template>
  <div class="confirmation-modal__wrapper">
    <modal
      name="confirmation"
      :width="900"
      height="auto"
      :scrollable="true"
      :click-to-close="false"
      :classes="['modal confirmation-modal']"
    >
      <div
        class="confirmation-modal__close-btn"
        @click="close"
      >
        <i class="material-icons">close</i>
      </div>
      <div class="confirmation-modal__body">
        <div class="confirmation-modal__msg">
          {{ 'No has guardado los cambios. ¿Estás seguro que quieres continuar?' }}
        </div>
        <slot />
      </div>
      <div
        class="btn confirmation-modal__confirm-btn"
        @click="confirm"
      >
        {{ 'Continuar' }}
      </div>
      <div
        class="btn btn--inverted-colors confirmation-modal__cancel-btn"
        @click="close"
      >
        {{ 'Guardar' }}
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import showMessageMixin from '../mixins/showMessageMixin';

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  props: {
    msg: { type: String, default: null },
  },
  mounted() {
    this.openModal();
  },
  computed: mapState({
    confirmationAction: state => state.ui.confirmationAction,
    deconfirmationAction: state => state.ui.deconfirmationAction,
  }),
  methods: {
    openModal() {
      this.$modal.show('confirmation');
    },
    confirm() {
      this.$store.dispatch(this.confirmationAction)
        .then()
        .catch(err => {
          this.close();
          this.showMessage(err);
        });
    },
    close() {
      this.$store.dispatch(this.deconfirmationAction);
    },
  },
};
</script>
