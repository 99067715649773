var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-modal__inputs flex flex-col items-center justify-center"},[_c('div',{staticClass:"mb-4 relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"self-center h-10 bg-gray-100 p2 rounded-lg pl-10 search-bar border border-gray-300 outline-none",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchTerm=$event.target.value}}}),_vm._v(" "),_c('i',{staticClass:"material-icons absolute inset-y-0 pl-2 at-middle"},[_vm._v("search")])]),_vm._v(" "),_c('div',{staticClass:"mt-4 mb-4 flex flex-row"},[_c('div',{staticClass:"cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm",class:{
        'bg-indigo-600 text-white': _vm.selectedFilter === 'name',
        'text-indigo-500': _vm.selectedFilter !== 'name'},on:{"click":function($event){_vm.selectedFilter = 'name'}}},[_vm._v("\n      Name\n    ")]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm",class:{
        'bg-indigo-600 text-white': _vm.selectedFilter === 'dataDisplayTypeList',
        'text-indigo-500': _vm.selectedFilter !== 'dataDisplayTypeList'},on:{"click":function($event){_vm.selectedFilter = 'dataDisplayTypeList'}}},[_vm._v("\n      Display Type\n    ")]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm",class:{
        'bg-indigo-600 text-white': _vm.selectedFilter === 'appType',
        'text-indigo-500': _vm.selectedFilter !== 'appType'
      },on:{"click":function($event){_vm.selectedFilter = 'appType'}}},[_vm._v("\n      Type\n    ")]),_vm._v(" "),_c('div',{staticClass:"cursor-pointer m-2 border border-indigo-400 p-2 pr-4 pl-4 rounded-full text-sm",class:{
        'bg-indigo-600 text-white': _vm.selectedFilter === 'integrationList',
        'text-indigo-500': _vm.selectedFilter !== 'integrationList'
      },on:{"click":function($event){_vm.selectedFilter = 'integrationList'}}},[_vm._v("\n      Integrations\n    ")])]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-3 gap-4 items-center"},_vm._l((_vm.filteredResources),function(template){return _c('div',{key:template.id,staticClass:"p-2 border border-gray-300 flex rounded-lg items-center h-full app-height"},[_c('div',{staticClass:"w-2/6 h-full rounded-lg bg-gray-400 mt-2 mb-2"},[_c('img',{staticClass:"object-cover w-full h-full rounded-lg",attrs:{"src":template.thumbnailUrl || _vm.profilePlaceholder,"alt":"app's thumbnail"}})]),_vm._v(" "),_c('div',{staticClass:"flex flex-col w-4/6 pl-4 h-full"},[_c('div',[_c('p',{staticClass:"text-sm text-gray-500"},[_vm._v("\n            "+_vm._s(template.name)+"\n          ")]),_vm._v(" "),_c('p',{staticClass:"text-xs text-gray-400 truncate min-description"},[_vm._v("\n            "+_vm._s(template.description)+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row items-center h-full"},[_c('div',{staticClass:"p-1  w-16 bg-indigo-200 text-indigo-600 rounded-lg text-center text-sm cursor-pointer"},[_vm._v("\n            "+_vm._s(template.disabled ? 'Use': 'Added')+"\n          ")])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }