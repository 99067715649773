import * as mutations from '../../mutation-types';

export default {
  [mutations.OPEN_CONFIRMATION_MODAL](state) {
    state.confirmationModalOpened = true;
  },
  [mutations.CLOSE_CONFIRMATION_MODAL](state) {
    state.confirmationModalOpened = false;
  },
  [mutations.OPEN_SHARE_RESOURCE_MODAL](state) {
    state.shareResourceModalOpened = true;
  },
  [mutations.CLOSE_SHARE_RESOURCE_MODAL](state) {
    state.shareResourceModalOpened = false;
  },
  [mutations.OPEN_MOVE_RESOURCE_MODAL](state) {
    state.moveResourceModalOpened = true;
  },
  [mutations.CLOSE_MOVE_RESOURCE_MODAL](state) {
    state.moveResourceModalOpened = false;
  },
  [mutations.OPEN_FULLSCREEN_APP_MODAL](state) {
    state.fullscreenAppModalOpened = true;
  },
  [mutations.CLOSE_FULLSCREEN_APP_MODAL](state) {
    state.fullscreenAppModalOpened = false;
  },
  [mutations.OPEN_FOLDER_MODAL](state) {
    state.folderModalOpened = true;
  },
  [mutations.CLOSE_FOLDER_MODAL](state) {
    state.folderModalOpened = false;
  },
  [mutations.OPEN_USER_MODAL](state) {
    state.userModalOpened = true;
  },
  [mutations.CLOSE_USER_MODAL](state) {
    state.userModalOpened = false;
  },

  [mutations.OPEN_USER_DEFINED_TYPE_MODAL](state) {
    state.userDefinedTypeModalOpened = true;
  },

  [mutations.CLOSE_USER_DEFINED_TYPE_MODAL](state) {
    state.userDefinedTypeModalOpened = false;
  },

  [mutations.OPEN_USER_DEFINED_TYPE_COLUMN_MODAL](state) {
    state.userDefinedTypeColumnsModalOpened = true;
  },

  [mutations.CLOSE_USER_DEFINED_TYPE_COLUMN_MODAL](state) {
    state.userDefinedTypeColumnsModalOpened = false;
  },
  [mutations.OPEN_MEMBER_MODAL](state) {
    state.memberModalOpened = true;
  },
  [mutations.CLOSE_MEMBER_MODAL](state) {
    state.memberModalOpened = false;
  },
  [mutations.OPEN_USER_TAGS_MODAL](state) {
    state.userTagsModalOpened = true;
  },
  [mutations.CLOSE_USER_TAGS_MODAL](state) {
    state.userTagsModalOpened = false;
  },
  [mutations.OPEN_WORKSPACE_MODAL](state) {
    state.openWorkspaceModalOpened = true;
  },
  [mutations.CLOSE_WORKSPACE_MODAL](state) {
    state.openWorkspaceModalOpened = false;
  },
  [mutations.OPEN_CONNECTION_MODAL](state) {
    state.connectionModalOpened = true;
  },
  [mutations.CLOSE_CONNECTION_MODAL](state) {
    state.connectionModalOpened = false;
  },
  [mutations.SET_CONFIRMATION_ACTION](state, actionType) {
    state.confirmationAction = actionType;
  },
  [mutations.SET_DECONFIRMATION_ACTION](state, actionType) {
    state.deconfirmationAction = actionType;
  },
  [mutations.SET_CONFIRMATION_BTN_TEXT](state, text) {
    state.confirmationBtnText = text;
  },
  [mutations.OPEN_TEMPLATE_MODAL](state) {
    state.templateModalOpened = true;
  },
  [mutations.CLOSE_TEMPLATE_MODAL](state) {
    state.templateModalOpened = false;
  },
  [mutations.OPEN_VIEW_SETTINGS_MODAL](state) {
    state.viewSettingsModalOpened = true;
  },

  [mutations.CLOSE_VIEW_SETTINGS_MODAL](state) {
    state.viewSettingsModalOpened = false;
  },
  [mutations.SELECT_VIEW_FOR_SETTINGS](state) {
    state.viewSettingsModalOpened = false;
  },
  [mutations.SET_DATA_SOURCE_OPEN_MODAL](state, value) {
    state.dataSourceModalOpened = value;
  },
  [mutations.SET_BIGSTORE_OPEN_MODAL](state, value) {
    state.bigstoreAppTemplateModalOpened = value;
  },
  [mutations.SET_USER_MODAL_EDIT_MODE](state, value) {
    state.userModalEditMode = value;
  },
  [mutations.SET_COLUMNS_FROM_DOCUMENT](state, value) {
    state.columnsToShow = value;
  },
  [mutations.SET_FILTERS_FROM_DOCUMENT](state, value) {
    state.filtersToShow = value;
  },
  [mutations.SET_HEADERS_READY](state, value) {
    state.headersReady = value;
  },
  [mutations.SET_COLUMN_FIELD](state, value) {
    state.columnField = value;
  },
  [mutations.SET_FILTERS_CHECKED](state, value) {
    state.filtersChecked = value;
  },
  [mutations.SET_FILTERS_UNCHECKED](state, value) {
    state.filtersUnchecked = value;
  },
  [mutations.REMOVE_FILTER_CHECKED](state, index) {
    const filtersCheckedCopy = [...state.filtersChecked];
    filtersCheckedCopy.splice(index, 1);
    state.filtersChecked = [...filtersCheckedCopy];
  },
  [mutations.REMOVE_FILTER_UNCHECKED](state, index) {
    const filtersUncheckedCopy = [...state.filtersUnchecked];
    filtersUncheckedCopy.splice(index, 1);
    state.filtersUnchecked = [...filtersUncheckedCopy];
  },
  [mutations.SAVE_ALERT](state, value) {
    state.saveAlert = value;
  },
  [mutations.OPEN_UNSAVED_CHANGES_MODAL](state) {
    state.unsavedChangesModalOpened = true;
  },
  [mutations.CLOSE_UNSAVED_CHANGES_MODAL](state) {
    state.unsavedChangesModalOpened = false;
  },
  [mutations.OPEN_DELETE_CONFIRMATION_MODAL](state) {
    state.deleteConfirmationModalOpened = true;
  },
  [mutations.CLOSE_DELETE_CONFIRMATION_MODAL](state) {
    state.deleteConfirmationModalOpened = false;
  },
  [mutations.SET_FILENAMES](state, params) {
    state.fileNames = { ...state.fileNames,
      [params.containerId]: params.value.map(fileName => (fileName.replace(/<(.|\n)*?>/g, ''))) };
  },
  [mutations.SET_FILE_URLS](state, params) {
    state.fileUrls = { ...state.fileUrls, [params.containerId]: params.value };
  },
  [mutations.SET_FILENAMES_IN_APP](state, params) {
    state.filenamesInApp = { ...state.filenamesInApp, [params.containerId]: params.value };
  },
  [mutations.SET_BLOCK_APP_TAB_SELECTION](state, value) {
    state.blockAppTabContainerSelected = value;
  },
  [mutations.SET_TEXT_APP_BEEN_EDITED](state, value) {
    state.textAppBeenEdited = value;
  },
  [mutations.SET_BOOLEAN_SELECTED](state, value) {
    state.booleanSelected = value;
  },
  [mutations.SET_SELECTED_ITEM_ID](state, value) {
    state.selectedItemId = value;
  },
};
