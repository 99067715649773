<template>
  <div
    class="app-grid"
    v-if="appContainers"
  >
    <app-container-floating
      v-for="(container, index) in appContainersFiltered"
      :key="index"
      :container="container"
      :app-type="container.appType"
      :apps-editable="appsEditable"
      :is-new="container.app ? container.app.isNew : false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  props: {
    appsEditable: { type: Boolean, default: false },
  },
  mounted() {
    console.log('App Containers', this.appContainers);
  },
  computed: {
    ...mapState({
      appContainers: state => state.views.appContainers,
    }),
    appContainersFiltered() {
      const filtered = _.pickBy(this.appContainers, (container) => container.appType !== undefined);

      return filtered;
    },
  },
};
</script>
