<template>
  <div>
    <div
      name="app"
      class="app"
    >
      <div class="title">
        {{ this.title }}
      </div>
      <div class="header-container">
        <i
          class="material-icons icon"
          v-if="headerIcon"
        >
          {{ headerIcon }}
        </i>
        <div class="header-text">
          {{ headerText }}
        </div>
      </div>
    </div>
    <div
      name="app"
      class="mt-8 pl-2"
    >
      <div class="">
        <template v-if="showTitle">
          <div class="app-modal__input-header">
            {{ $t("view.apps.title") }}
          </div>
          <input
            v-model="title"
            @input="autoSaveTitle"
          >
        </template>
        <div class="mt-6">
          <slot />
        </div>
        <div
          class="flex flex-col w-full mt-6 min-h-300px"
          v-if="showCommentsToggle || showBordersToggle"
        >
          <Collapsible
            class="width-collapsible"
            :is-open="true"
          >
            <div
              slot="trigger"
            >
              <div class="flex items-center">
                <p class="w-5/6  text-left color-gray-title border-t border-gray-400 pt-4 text-category ">
                  GENERAL
                </p>
                <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">expand_more</i>
              </div>
            </div>
            <div slot="closedTrigger">
              <div class="flex margin-top-40px items-center">
                <p class="w-5/6  text-left color-gray-title border-t border-gray-400 pt-4 text-category ">
                  GENERAL
                </p>
                <i class="w-1/6 material-icons material-icons-round cursor-pointer pt-4">chevron_right</i>
              </div>
            </div>
            <div class="flex flex-col mt-4">
              <template
                v-if="showCommentsToggle"
              >
                <div class="flex">
                  <p class="text-left text-menu-label w-4/6 text-sm">
                    {{ $t("view.apps.comments") }}
                  </p>
                  <toggle-button
                    color="#2c6eeb"
                    class="w-2/6"
                    :value="commentsEnabled"
                    :sync="true"
                    @input="toggleCommentsEnabled"
                  />
                </div>
              </template>
              <template v-if="showBordersToggle">
                <div class="flex mt-2">
                  <p class="text-left text-menu-label w-4/6 text-sm">
                    {{ $t("view.apps.showBorders") }}
                  </p>
                  <toggle-button
                    color="#2c6eeb"
                    class="w-2/6"
                    :value="showBorders"
                    :sync="true"
                    @input="showBorders = !showBorders"
                  />
                </div>
              </template>

              <template v-if="showPrivateToggle">
                <div class="flex mt-2">
                  <p class="text-left text-menu-label w-4/6 text-sm">
                    {{ $t("view.apps.isPrivate") }}
                  </p>
                  <toggle-button
                    color="#2c6eeb"
                    class="w-2/6"
                    :value="isPrivate"
                    :sync="true"
                    @input="isPrivate= !isPrivate"
                  />
                </div>
              </template>
            </div>
          </Collapsible>
          <!-- <h4 class="text-left">
            General
          </h4> -->
        </div>
      </div>
    </div>
    <div class="save-toolbar-button-container">
      <div
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        :class="{ 'app-modal__save-btn--disabled': !canSave }"
        @click="saveApp"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
// import { VueEditor } from 'vue2-editor';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import {
  SAVE_CONTAINER,
} from '../store/action-types';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import customToolbarHelper from '../helpers/custom-toolbar';
import showMessageMixin from '../mixins/showMessageMixin';

const DEBOUNCE_TIME = 1000;

export default {
  mixins: [escapeMixinHandler, showMessageMixin],
  components: {
    Collapsible,
  },
  props: {
    headerText: { type: String, required: true },
    headerIcon: { type: String, default: null },
    appFilled: { type: Boolean, required: true },
    appWithType: { type: Object, required: true },
    showCommentsToggle: { type: Boolean, default: true },
    showBordersToggle: { type: Boolean, default: true },
    showPrivateToggle: { type: Boolean, default: true },
    showTitleToggle: { type: Boolean, default: true },
    // showTitle: { type: Boolean, default: false },
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  mounted() {
    // this.openModal();
  },
  data() {
    const container = this.$store.getters.selectedContainer;

    return {
      customToolbar: customToolbarHelper.customToolbar,
      title: _.get(container, 'title', ''),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      showBorders: _.get(container, 'showBorders', false),
      isPrivate: _.get(container, 'isPrivate', false),
      showTitle: _.get(container, 'showTitle', false),
    };
  },
  computed: {
    appWithContainer() {
      return {
        title: this.title,
        row: this.row,
        width: this.width || 0,
        commentsEnabled: this.commentsEnabled,
        showBorders: this.showBorders,
        isPrivate: this.isPrivate,
        ...this.appWithType,
      };
    },
    canSave() {
      const canSave = !!(this.appFilled);
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedView: state => state.views.selected,
    }),
    ...mapGetters([
      'selectedContainer',
    ]),
  },
  methods: {
    autoSaveTitle: _.debounce(function () {
      this.saveTitle();
    }, DEBOUNCE_TIME),
    saveTitle() {
      if (this.selectedContainerId === null || this.selectedContainerId === undefined) return;
      const params = { title: this.title };
      const containerId = this.selectedContainerId;
      this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    openModal() {
      this.$modal.show('app');
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      if (!this.canSave) return;

      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    toggleCommentsEnabled() {
      this.commentsEnabled = !this.commentsEnabled;
    },
    updateEditorTitle({ target }) {
      this.title = target.value;
    },
    selectedRow(currentCell) {
      if (this.row === Math.ceil(currentCell / this.maxWidth)) {
        const tempPos = currentCell % this.maxWidth;
        const currentPositionInRow = tempPos === 0 ? this.maxWidth : tempPos;
        let startPoint;
        if (this.orderInRow > 1) {
          startPoint = this.maxWidth - this.width + 1;
        } else {
          startPoint = this.orderInRow;
        }
        const endPoint = startPoint + this.width;

        return currentPositionInRow >= startPoint && currentPositionInRow < endPoint;
      }

      return false;
    },
  },
  watch: {
    row() {
      this.orderInRow = this.maxValidOrder;
      this.resetWidth();
    },
    orderInRow() {
      this.resetWidth();
    },
  },
};
</script>
<style scoped>
.min-h-300px {
  min-height: 300px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.app {
  margin-top: 12px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.icon {
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}
</style>
