import { FETCH_GOOGLE_ACCESS_TOKEN } from '../store/action-types';

export default {
  props: {
    connections: { type: Array, required: false },
  },
  mounted() {
    if (this.connections){
      this.connections.forEach(connection => {
        if (connection.service === 'gsheet') {
          this.$store.dispatch(FETCH_GOOGLE_ACCESS_TOKEN, connection.id);
        }
      });
    }
  },
};
