export default {
  dashboard: {
    title: 'Mi Unidad',
  },
  workspace: {
    title: 'Workspace',
  },
  view: {
    share: {
      title: 'Compartir vista {viewTitle}',
      publicLink: 'Link público',
      addEmail: 'Ingresa correos',
    },
    thumbnail: 'Subir Thumbnail',
    save: 'Guardar',
    listTitle: 'Vistas',
    newViewButton: 'Bigpicture',
    newFolderButton: 'Carpeta',
    edit: 'Editar',
    copy: 'Duplicar',
    copyAsTemplate: 'Dup. como Plantilla',
    shareButton: 'Compartir',
    settingsButton: 'Ajustes',
    settingsTitle: 'Ajustes',
    settings: {
      title: 'Ajustes de la Vista',
      titleTemplate: 'Ajustes del Template',
      button: 'Ajustes',
      variablesTitle: 'Variables',
      addVariableButton: 'Nueva Variable',
    },
    duplicateButton: 'Duplicar',
    show: 'Ver',
    goBack: 'Volver',
    appsTitle: 'Elements',
    customAppsTitle: 'Apps',
    delete: 'Eliminar Vista',
    folder: {
      title: 'Carpeta',
      name: 'Nombre',
      delete: 'Eliminar Carpeta',
      deleteShort: 'Eliminar',
      move: 'Mover',
      edit: 'Editar',
    },
    duplicateSuccessMessage: '¡Se ha duplicado correctamente esta vista!',
    duplicateFailedMessage: '¡Ocurrió un problema!. La vista no se pudo duplicar',
    implementAsTemplateSuccessMessage: 'La vista a sido duplicada como Plantilla!',
    apps: {
      delete: 'Eliminar app',
      title: 'Título',
      row: 'Fila',
      rowOrder: 'Orden en Fila',
      width: 'Ancho',
      doesNotFitMessage: 'App no cabe en esta posición',
      comments: 'Comentarios',
      title: 'Mostrar Título',
      showBorders: 'Mostrar bordes',
      isPrivate: 'Privada',
      text: {
        name: 'Texto',
        writeText: '<- Escribe tu texto en el contenedor',
      },
      source: {
        name: 'Fuente',
        writeYourCode: 'Escribe tu código',
      },
      image: {
        name: 'Imagen',
        chooseImage: 'Elige una imagen',
        chooseImageMessage: 'Selecciona una imagen desde tu computador',
      },
      tab: {
        name: 'Tabs',
        writeHere: 'Escribe aqui...',
        tabColor: 'Color del Tab',
        fontColor: 'Color Tipografia',
      },
      menu: {
        formName: 'Agregar Item',
      },
      file: {
        name: 'File',
        chooseFile: 'Choose a File',
        chooseFileMessage: 'Select a file from your computer',
      },
      feed: {
        name: 'Comentarios',
        deleteComment: 'Eliminar comentario',
        respondToComment: 'Responder',
        showComments: 'Comentarios ({number})',
        closeComments: 'Cerrar',
      },
      space: {
        name: 'Espacio',
        height: 'Alto (solo para cuando el espacio ocupa una fila completa)',
      },
      duplicateSuccessMessage: '¡Se ha duplicado correctamente esta aplicación!',
      duplicateFailedMessage: '¡Ocurrió un problema!. La aplicación no se pudo duplicar',
    },
    listTableTitles: {
      name: 'Nombre',
      tenant: 'Workspace',
      owner: 'Owner',
      updatedAt: 'Ultima Actualización',
    },
  },
  resource: {
    noTitle: 'Sin título',
    share: {
      addEmail: 'Ingresa correos',
      addTag: 'O comparte con Usuarios Etiquetados',
    },
    move: {
      current: 'Carpeta Actual',
      destination: 'Mover a:',
      selectDestinationFolder: 'Selecciona una Carpeta Destino',
    },
  },
  template: {
    title: 'Template | Templates',
    name: 'Nombre',
    newButton: 'Nuevo Template',
    save: 'Guardar',
    delete: 'Eliminar Template',
    use: 'Usar Template',
    password: 'Contraseña',
    verifyPassword: 'Confirmar Contraseña',
    passwordLengthMessage: 'Contraseña debe ser de un mínimo de 6 caracteres',
    passwordMatchMessage: 'Las contraseñas deben coincidir',
    goToLogIn: 'Ir a Login',
    implementSuccessMessage: '¡El template se ha implementado exitosamente!',
    implementFailedMessage: '¡Ocurrió un problema!. No se ha podido utilizar el template',
  },
  generalActions: {
    confirmDeleteMessage: 'Esta acción no se puede deshacer. ¿Deseas continuar?',
    cancel: 'Cancelar',
    save: 'Guardar',
  },
  user: {
    name: 'Usuario | Usuarios',
    invite: 'Invitar a un nuevo usuario',
    inviteMember: 'Invitar a nuevo Team Member',
    inviteConsultant: 'Invitar a nuevo Consultor',
    manage: 'Equipo',
    nameTag: 'Nombre Completo',
    lastNameTag: 'Apellido',
    email: 'Email',
    rol: 'Rol',
    delete: 'Eliminar Usuario',
    config: 'Mi Cuenta',
    logInAgain: 'Debe volver a ingresar',
    logout: 'Cerrar sesion',
    tags: 'Etiquetas',
    deleteTag: 'Eliminar Tag',
    deleteWorkspace: 'Delete Workspace',
    tagsModal: {
      title: 'Agrega un nuevo grupo',
      tagPlaceholder: 'Seleccionar tags',
      userPlaceholder: 'Usuarios Seleccionados',
    },
  },
  connection: {
    listTitle: 'Conexiones',
    new: 'Nueva Conexión',
    delete: 'Eliminar Conexión',
  },
  toasts: {
    connectionAlreadyExists: 'Ya estás conectado a este servicio',
    dataSaved: 'Información de cuenta actualizada',
  },
  customAppTemplate: {
    title: 'Apps',
    workspaceTitle: 'Apps Integradas',
    new: 'Nueva App',
    delete: 'Eliminar App',
    disable: 'Deshabilitar App',
    affectedViews: 'Las siguientes vistas se verían afectadas:',
    noAffectedViews: 'No hay vistas que usen esta app',
    confirmDisable: 'Deshabilitar una app la removería de las vistas donde se usó, permanentemente. ¿Deseas continuar?',
  },
};
