<template>
  <div class="grid-index ">
    <div
      class="flex flex-row mt-20 bg-white h-20 cursor-pointer items-center justify-center
                border-b border-gray-400 rounded-t-lg text-size-18 mb-5 shadow-sm"
    >
      <!-- <button
        v-if="isOwner"
        @click="selectTab('workspace')"
        class="w-1/4 h-full"
        :class="{
          'text-icon-secondary border-b-4 border-icon-secondary': workspaceActive,
          'text-menu-label': !workspaceActive}"
      >
        General
      </button> -->
      <button
        @click="selectTab('users')"
        class="w-1/4 h-full"
        :class="{'font-medium text-settings-selected border-b-8 border-settings-selected': usersActive, 'text-menu-label': !usersActive}"
      >
        Usuarios
      </button>
      <button
        @click="selectTab('tags')"
        class="w-1/4 h-full"
        :class="{'font-medium text-settings-selected border-b-8 border-settings-selected': tagsActive, 'text-menu-label': !tagsActive}"
      >
        Grupos
      </button>

      <!-- <button
        class="w-1/4 h-full cursor-not-allowed"
        :class="{
          'text-icon-secondary border-b-4 border-icon-secondary': connectionsActive,
          'text-menu-label': !connectionsActive}"
      >
        Integrations
      </button> -->
      <!-- <button
        @click="selectTab('apps')"
        class="w-1/4 h-full"
        :class="{'text-icon-secondary border-b-4 border-icon-secondary': appsActive, 'text-menu-label': !appsActive}"
      >
        Apps
      </button> -->
    </div>
    <!-- Tab content Users-->
    <div
      v-if="isOwner"
      :class="{hidden: !workspaceActive}"
      class="bg-white min-h-full shadow-sm"
    >
      <div
        class="pt-12 h-full pt-4 pb-4 w-full flex flex-col text-sm
          text-gray-700  rounded-t-lg items-left padding-x-32"
      >
        <div>
          <p class="text-size-27 mb-8">
            Organización
          </p>
          <p class="text-menu-label text-size-13">
            Logo
          </p>
          <image-upload
            title="Change Workspace Logo"
            class="logo bg-action-secondary rounded-md mt-2 flex items-center align-middle"
            @loading-start="loadingImage = true"
            @loading-end="loadingImage = false"
            @uploaded="handleImageUploaded"
          >
            <i
              v-if="logoUrl === null"
              class="material-icons material-icons--blue align-middle my-8"
            >add</i>
            <img
              v-else
              :src="logoUrl"
              class="object-cover rounded-md h-full"
              alt="logo company"
            >
          </image-upload>
        </div>
        <div>
          <p class="text-size-13 text-menu-label mt-6">
            Nombre Organización
          </p>
          <input
            v-model="workspaceName"
            @input="autoSaveWorkspaceName"
            class="input-name border border-input outline-none focus:border-icon-secondary rounded-md mb-24"
            type="text"
          >
        </div>
      </div>
    </div>
    <div
      class="bg-white min-h-full w-full shadown-sm"
      :class="{ hidden: !usersActive }"
    >
      <div
        class="px-8 pt-4 pb-1 h-16 flex items-center"
      >
        <p class="w-3/12 text-setting-label text-size-12 self-end">
          Nombre
        </p>
        <p class="w-3/12 text-setting-label text-size-12 self-end">
          Grupos
        </p>
        <div
          v-if="isOwner"
          title="Ver historial de importaciones de usuarios"
          class="w-1/12 cursor-pointer text-center mt-8"
          @click="userExcelUploadsIndex(companyProp.id)"
        >
          <i
            class="material-icons"
            style="color: #0031FF; padding-right: 6rem;"
          >history</i>
        </div>
        <div
          v-if="isOwner"
          class="w-2/12 massive-import-button mt-8"
          @click="massiveImport()"
          style="cursor: pointer;"
        >
          <div class="flex flex-row">
            <i
              class="material-icons add-entry-text-position"
              style="width: 30%; color: #0031FF; padding-left: 13%; font-size: 1.3em;"
            >upload_file</i>
            <div
              class="massive-import-font add-entry-text-position"
              style="width: 70%; padding-left: 1%;"
            >
              Carga masiva
            </div>
          </div>
        </div>
        <div class="w-3/12 flex justify-end">
          <button
            v-if="isOwner"
            @click="openUserModal"
            class="border border-gray-400 rounded-full px-4 py-2 cursor-pointer  mt-8 bg-buttom-primary shadow-md disabled-button"
            disabled
          >
            <i class="cursor-pointer material-icons-outlined material-icons-inline material-icons-active">add</i>
            <span class="text-settings-selected pl-2 font-semibold cursor-pointer">Nuevo Usuario</span>
          </button>
        </div>
      </div>
      <div class="block">
        <user-list-item
          v-for="(user, index) in displayableUsers"
          :key="index"
          :user="user"
          :is-owner="isOwner"
          :default-profile="defaultProfile"
        />
      </div>
    </div>
    <!-- Tags Tab -->
    <div
      :class="{ hidden: !tagsActive }"
      class="bg-white min-h-full w-full shadow-sm"
    >
      <div class="px-8 pt-4 pb-1 h-16 flex items-center">
        <div class="w-2/12 text-setting-label text-size-12 self-end">
          Group
        </div>
        <div class="w-7/12 text-setting-label text-size-12 self-end">
          Collaborators
        </div>
        <div class="w-3/12 text-center flex justify-end">
          <button
            @click="openUserTagsModal"
            class="border border-gray-400 rounded-full px-4 py-2 cursor-pointer  mt-8 bg-buttom-primary shadow-md"
          >
            <i
              class="material-icons material-icons-inline material-icons-active cursor-pointer"
            >add</i>
            <span class="text-icon-secondary font-semibold">Crear Grupo</span>
          </button>
        </div>
      </div>
      <div class="block">
        <settings-tag-item
          v-for="(item, key, index) in usersTagged"
          :key="index"
          :tag="{users: item, tag: key}"
        />
      </div>
    </div>
    <!-- Apps Tab -->
    <div
      :class="{ hidden: !appsActive }"
      class="bg-white min-h-full"
    >
      <div class="px-32 py-4 h-auto items-center">
        <div
          class="mt-12 h-full p-4 w-full flex flex-row  text-sm
          text-gray-700 border-gray-400 border rounded-t-lg items-center"
        >
          <custom-app-template-list
            :custom-app-templates-visibility-options="customAppTemplatesVisibilityOptions"
            :custom-app-templates-prop="customAppTemplatesProp"
            :is-workspace-apps="true"
          />
        </div>
      </div>
    </div>

    <user-modal
      v-if="userModalOpened"
      :role-options="roleOptions"
      :edit-mode="userModalEditMode"
    />

    <user-tags-modal
      v-if="userTagsModalOpened"
      :edit-mode="tagModalEditMode"
    />
    <confirmation-modal v-if="confirmationModalOpened" />
    <users-import-modal
      v-if="selectedModalOfType('UsersImportModal')"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import humps from 'humps';
import _ from 'lodash';
import UserModal from './user-modal';
import UserTagsModal from './user-tags-modal';
import UserListItem from './user-list-item';
import SettingsTagItem from './settings-tag-item';
import ConfirmationModal from './confirmation-modal';
import CustomAppTemplateList from './custom-app-template-list';
import UsersImportModal from './users-import-modal.vue';
import api from '../api';
import {
  ADD_NORMALIZED_USERS,
  OPEN_USER_MODAL,
  OPEN_USER_TAGS_MODAL,
  ADD_TENANT_TAGS,
  SET_USERS_TAGGED,
  SET_TAG_MODAL_EDIT_MODE,
  SET_APP_MODAL_TYPE,
} from '../store/mutation-types';
import { SELECT_USER_FOR_DELETION, SELECT_USER_FOR_EDITION, SELECT_CURRENT_COMPANY } from '../store/action-types';

import escapeMixinHandler from '../mixins/escapeMixinHandler';

const DEBOUNCE_TIME = 1000;
export default {
  mixins: [escapeMixinHandler],
  components: {
    UserModal,
    UserTagsModal,
    ConfirmationModal,
    UserListItem,
    SettingsTagItem,
    CustomAppTemplateList,
    UsersImportModal,
  },
  props: {
    usersProp: { type: Array, required: true },
    roleOptions: { type: Array, required: true },
    tenantTagsProp: { type: Array, required: true },
    companyProp: { type: Object, required: true },
    defaultProfile: { type: Object, default: () => ({ url: 'account' }) },
    customAppTemplatesVisibilityOptions: { type: Array, required: true },
    customAppTemplatesProp: { type: Array, required: true },
    isOwner: { type: Boolean, required: true },
  },
  data() {
    return {
      selectedTab: 'users',
      openDropdown: false,
      fileSignedId: null,
      logoUrl: _.get(this.companyProp, 'logoUrl'),
      workspaceName: _.get(this.companyProp, 'name'),
      loadingImage: false,
    };
  },
  mounted() {
    const usersObject = this.usersProp.reduce((obj, user) => {
      obj[user.id] = user;

      return obj;
    }, {});
    this.$store.commit(ADD_NORMALIZED_USERS, humps.camelizeKeys(usersObject));
    this.$store.commit(ADD_TENANT_TAGS, this.tenantTagsProp);
    this.$store.dispatch(SELECT_CURRENT_COMPANY, this.companyProp);
    console.log(this.companyProp);
    const normalized = this.companyProp.usersTagged.reduce((normalObject, tagged) => {
      normalObject[tagged.tag] = tagged.users;

      return normalObject;
    }, {});
    this.$store.commit(SET_USERS_TAGGED, normalized);
  },
  computed: {
    ...mapGetters(['selectedModalOfType']),
    ...mapState({
      displayableUsers: state => state.users.list,
      confirmationModalOpened: state => state.ui.confirmationModalOpened,
      userModalOpened: state => state.ui.userModalOpened,
      userTagsModalOpened: state => state.ui.userTagsModalOpened,
      tagModalEditMode: state => state.users.tagModalEditMode,
      userModalEditMode: state => state.ui.userModalEditMode,
      usersTagged: state => state.users.usersTagged,
    }),
    usersActive() {
      return this.selectedTab === 'users';
    },
    tagsActive() {
      return this.selectedTab === 'tags';
    },

    appsActive() {
      return this.selectedTab === 'apps';
    },

    connectionsActive() {
      return this.selectedTab === 'connections';
    },
    workspaceActive() {
      return this.selectedTab === 'workspace';
    },
    workspaceParams() {
      if (this.fileSignedId) {
        return {
          company: {
            logo: this.fileSignedId,
          },
        };
      }

      return { company: { name: this.workspaceName } };
    },
  },

  methods: {
    autoSaveWorkspaceName: _.debounce(function () {
      this.saveName();
    }, DEBOUNCE_TIME),
    saveName() {
      api.updateWorkspace(this.companyProp.id, this.workspaceParams);
    },
    close() {
      this.openDropdown = false;
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    openUserModal() {
      this.$store.commit(OPEN_USER_MODAL);
    },
    openUserTagsModal() {
      this.$store.commit(SET_TAG_MODAL_EDIT_MODE, false);
      this.$store.commit(OPEN_USER_TAGS_MODAL);
    },
    selectForEdition(userId) {
      this.$store.dispatch(SELECT_USER_FOR_EDITION, userId);
    },
    selectForDeletion(userId) {
      this.$store.dispatch(SELECT_USER_FOR_DELETION, userId);
    },
    handleImageUploaded({ url, fileSignedId }) {
      this.logoUrl = url;
      this.fileSignedId = fileSignedId;
      api.updateWorkspace(this.companyProp.id, this.workspaceParams);
    },
    massiveImport() {
      this.$store.commit(SET_APP_MODAL_TYPE, 'UsersImportModal');
    },
    userExcelUploadsIndex(companyId) {
      window.location = `/excel_uploads/?company_id=${companyId}`;
    },
    excelUploadsIndex(userTypeId) {
      window.location = `/excel_uploads/?user_type_id=${userTypeId}`;
    },
  },
};
</script>
<style>
.material-icons-gray {
  color: #b3b3b3;
  display: block;

}

.text-size-18 {
  font-size: 18px;
}

.text-size-12 {
  font-size: 12px;
}

.text-size-13 {
  font-size: 13px;
}

.input-name {
  height: 48px;
  width: 292px;
}

.logo {
  height: 150px;
  width: 150px;
}

.padding-x-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.rounded-19px {
  border-radius: 19px;
}

.massive-import-button {
  height: 38.92px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0239F5;
  border-radius: 6px;
  opacity: 1;
}

.add-entry-text-position {
  vertical-align: middle;
  line-height: 38.92px !important;
}

.massive-import-font {
  text-align: left;
  font: normal normal normal 14px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  line-height: 38.92px;
}

.disabled-button {
  opacity: 0.5;
  cursor: not-allowed;
}

</style>
