<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app mb-4"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">feed</i>
        <div
          class="header-text"
          style="white-space: nowrap;"
        >
          Feed
        </div>
      </div>
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="flex">
            <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
              Activar comentarios
            </p>
            <toggle-button
              color="#2c6eeb"
              class="w-2/6"
              :value="commentsEnabled"
              :sync="true"
              @input="commentsEnabled = !commentsEnabled; updateAppAttributes();"
            />
          </div>
          <div class="text-left text-menu-label text-sm font-inter-type-1 mt-4">
            Dar acceso a publicar a:
          </div>
          <div class="mt-2 mb-40">
            <DropdownCheckbox
              class="text-center text-sm vue-select-container"
              :options="publishersRoles"
              @dropdownCheckboxListener="onPublisherRoleSelect"
              :listener="'dropdownCheckboxListener'"
            />
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
          :has-comments-enabled="false"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';
import { mapState } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import DropdownCheckbox from './dropdown-checkbox';
import {
  SAVE_CONTAINER,
  SET_APP_ATTRIBUTES,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
} from '../store/action-types';
import {
  SAVE_ALERT,
} from '../store/mutation-types';
import {
  CONTAINER_COLORS_WITH_TRANSPARENT,
  VUE_SWATCHES_ATTRIBUTES,
} from '../constants';
import showMessageMixin from '../mixins/showMessageMixin';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

export default {
  mixins: [showMessageMixin],
  components: {
    Collapsible,
    DropdownCheckbox,
    AppTitleInOptions,
    ContainerSection,
  },
  data() {
    const container = this.$store.getters.selectedContainer;
    let publishersRoles = _.get(container, 'app.publishersRoles', null);
    if (publishersRoles === null) {
      publishersRoles = this.defaultPublishersRoles();
    } else {
      publishersRoles = this.definePublishersRoles(publishersRoles);
    }

    return {
      container,
      containerID: _.get(container, 'id', -1),
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      showShading: _.get(container, 'showShading', false),
      commentsEnabled: _.get(container, 'app.commentsEnabled', true),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      visualizationOption: _.get(container, 'app.visualizationOption', 'Click en botón'),
      containerColorsWithTransparent: CONTAINER_COLORS_WITH_TRANSPARENT,
      triggerStyles: VUE_SWATCHES_ATTRIBUTES.triggerStyles,
      swatchStyles: VUE_SWATCHES_ATTRIBUTES.swatchStyles,
      initialTitle: _.get(container, 'title', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialShowShading: _.get(container, 'showShading', false),
      initialCommentsEnabled: _.get(container, 'app.commentsEnabled', true),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialVisualizationOption: _.get(container, 'app.visualizationOption', 'Click en botón'),
      toggleList: false,
      publishersRoles: JSON.parse(JSON.stringify(publishersRoles)),
      initialPublishersRoles: JSON.parse(JSON.stringify(publishersRoles)),
    };
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.containerID]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.containerID, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  computed: {
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowShading !== this.showShading ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialVisualizationOption !== this.visualizationOption ||
        JSON.stringify(this.initialPublishersRoles) !== JSON.stringify(this.publishersRoles)
      ) {
        return true;
      }

      return false;
    },
    canSave() {
      const canSave = this.changesToSend;
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: false,
        isPrivate: this.isPrivate,
        showShading: this.showShading,
        backgroundColor: this.containerBackgroundColor,
        borderColor: this.borderColor,
        ...this.appWithType,
      };
    },
    publishersRolesFormat() {
      const checked = this.publishersRoles.filter(role => role.checked);

      return checked.map(c => c.name);
    },
    appWithType() {
      return {
        app: {
          visualizationOption: this.visualizationOption,
          publishersRoles: this.publishersRolesFormat,
          commentsEnabled: this.commentsEnabled,
        },
        appType: 'WallApp',
      };
    },
  },
  methods: {
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    async saveApp() {
      await this.dispatchSaveContainer(this.appWithContainer, this.selectedContainerId);
      this.saveMessage();
      this.resetInitialValues();
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    resetInitialValues2() {
      this.initialVisualizationOption = this.visualizationOption;
      this.initialPublishersRoles = this.publishersRoles;
    },
    resetInitialValues() {
      this.initialTitle = this.title;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
      this.initialBorderColor = this.borderColor;
      this.initialShowShading = this.showShading;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.initialIsPrivate = this.isPrivate;
      this.resetInitialValues2();
    },
    allSelectedHandle() {
      if (this.publishersRoles[0].checked === false) {
        if (![this.publishersRoles[1].checked, this.publishersRoles[2].checked,
          this.publishersRoles[3].checked, this.publishersRoles[4].checked,
          this.publishersRoles[5].checked].includes(false)) {
          this.publishersRoles[0].checked = true;
        }
      }
      if ([this.publishersRoles[1].checked, this.publishersRoles[2].checked,
        this.publishersRoles[3].checked, this.publishersRoles[4].checked,
        this.publishersRoles[5].checked].includes(false)) {
        this.publishersRoles[0].checked = false;
      }
    },
    checkEveryPublishersRoles() {
      for (const option of this.publishersRoles) {
        option.checked = true;
      }
    },
    uncheckEveryPublishersRoles() {
      for (const option of this.publishersRoles) {
        option.checked = false;
      }
    },
    onPublisherRoleSelect(value) {
      if (value.name === 'Todos') {
        if (value.checked === false) {
          this.checkEveryPublishersRoles();
        } else {
          this.uncheckEveryPublishersRoles();
        }
      } else {
        this.publishersRoles[value.id - 1].checked = !value.checked;
        this.allSelectedHandle();
      }
    },
    definePublishersRoles(roles) {
      const initialRoles = this.defaultPublishersRoles();
      for (const initialRole of initialRoles) {
        if (!roles.includes(initialRole.name)) {
          initialRoles[initialRole.id - 1].checked = false;
        }
      }

      return initialRoles;
    },
    defaultPublishersRoles() {
      return [
        {
          id: 1,
          name: 'Todos',
          checked: true,
        },
        {
          id: 2,
          name: 'Admins',
          checked: true,
        },
        {
          id: 3,
          name: 'Readers',
          checked: true,
        },
        {
          id: 4,
          name: 'Devs',
          checked: true,
        },
        {
          id: 5,
          name: 'Owners',
          checked: true,
        },
        {
          id: 6,
          name: 'Platform Owners',
          checked: true,
        },
      ];
    },
    updateAppAttributes() {
      const params = {
        commentsEnabled: this.commentsEnabled,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.containerID, params });
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.isPrivate = params.isPrivate;
    },
  },
};
</script>

<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #28C6DA;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.selected-alignment {
  background: #DDE6F7 left top no-repeat padding-box;
}

.icon-limits {
  border-radius: 3px;
  width: 24px;
  margin-left: 5px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 0 3px 3px 0;
  opacity: 1;
  width: 61.15px;
  height: 32px;
}

.cells2 {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  color: black;
  border: 0.5px solid #7A7A7A;
  border-right-style: none;
  border-radius: 3px 0 0 3px;
  opacity: 1;
  height: 32px;
  padding-top: 5.4px;
  padding-left: 10px;
  letter-spacing: 0px;
  color: #555859;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}

.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}
/* FILTERS SECTION STYLE end*/

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}

.scale {
  background: #B3B3B3 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

input[type=range]:focus {
  outline: none;
}

input[type=range] {
  width: 138px;
  cursor: pointer;
  -webkit-appearance: none;
  outline: 0;
  margin: 10px;
  padding: 0;
  height: 3.62px;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 9.74px;
  width: 9.74px;
  border-radius: 50%;
  background: #555859;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.scrollable-toolbar-item-option {
  padding-bottom: 50px;
}

</style>
