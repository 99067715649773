<template>
  <div class="scrollable-toolbar-item-option">
    <div
      name="app"
      class="app"
    >
      <app-title-in-options
        :container="container"
      />
      <div class="header-container">
        <i class="material-icons icon">table_chart</i>
        <div class="header-text">
          Tabla
        </div>
      </div>
    </div>
    <div>
      <new-data-sources-selector
        :key="newDataSourceSelectorKey"
        :current-datasource="selectedDataSource"
        :columns-checked="fieldValues.columns"
        :tooltip-file-uri="tooltipF"
        :container="container"
        @sendShowDataSourceToOptions="updateShowDataSource"
      />
    </div>
    <div
      class="bg-white border-t"
      style="padding-left: 14.14px;"
    >
      <columns-toolbar-section
        :key="componentKey"
        :container="container"
        :template="template"
      />
    </div>
    <div
      class="bg-white border-t z-0"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FORMATO
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div
            class="flex flex-col"
          >
            <div class="flex flex-row">
              <i
                class="material-icons format-icon w-4/6"
                style="padding-top: 3px; text-align: left"
                title="Interlineado: espacio entre filas."
              >format_line_spacing</i>
              <input
                type="number"
                v-model="formatLineSpacing"
                class="text-center text-sm font-inter-type-1 cells format-input w-2/6"
                placeholder="10"
                min="0"
              >
            </div>
            <div
              v-show="true"
            >
              <div class="text-left text-menu-label text-sm font-inter-type-1 inter-sub-section mt-4">
                Título
              </div>
              <div
                class="inter-select-title vue-select-container"
                style="margin-bottom: 10px;"
              >
                <input
                  v-model="appTableTitle"
                  type="text"
                  class="w-full text-left text-sm font-inter-type-1 input btn-dimensions"
                  placeholder="Nombre..."
                >
              </div>
            </div>
            <template>
              <div
                class="flex mt-4"
                style="padding-bottom: 20px;"
              >
                <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                  Mostrar título
                </p>
                <toggle-button
                  color="#2c6eeb"
                  class="w-2/6"
                  :value="showTitle"
                  :sync="true"
                  @input="showTitle = !showTitle"
                />
              </div>
            </template>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              FILTRAR DATOS
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div slot="closedTrigger">
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              FILTRAR DATOS
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <div
          class="flex flex-col"
        >
          <div class="inter-select-title">
            <VueSelectStandard
              class="text-center text-sm vue-select-container"
              :data="this.$store.state.ui.filtersToShow"
              v-model="columnFilter"
              @documentListener="onColumnFilterSelect"
              :placeholder="'Columna'"
              :listener="'documentListener'"
            />
          </div>
          <div
            class="flex flex-row inter-select-title"
          >
            <VueSelectStandard
              class="text-center text-sm vue-select-container conditions-filter-container"
              :data="filterConditions"
              v-model="filterCondition"
              @documentListener="onConditionFilterSelect"
              :placeholder="'Condición'"
              :listener="'documentListener'"
            />
            <input
              v-model="valueForCondition"
              class="ml-1 mr-1 text-center text-sm font-inter-type-1 cells value-input value-filter-container"
              placeholder="Valor"
            >
          </div>
          <div
            class="inter-select-title text-left"
          >
            <a
              class="add-filter cursor-pointer"
              style="text-decoration: none;"
              @click="addFilter"
            >+ AGREGAR FILTRO
            </a>
          </div>
          <template>
            <div
              class="flex mt-4"
              :class="{
                'padding-for-conditions-display' : this.$store.state.ui.filtersChecked.length +
                  this.$store.state.ui.filtersUnchecked.length === 0,
                'normal-padding-for-condition': this.$store.state.ui.filtersChecked.length +
                  this.$store.state.ui.filtersUnchecked.length > 0,
              }"
            >
              <p class="text-left text-menu-label w-4/6 text-sm font-inter-type-1">
                Aplicar filtros
              </p>
              <toggle-button
                color="#2c6eeb"
                class="w-2/6"
                :value="applyFilters"
                :sync="true"
                @input="applyFilters= !applyFilters"
              />
            </div>
          </template>
          <div
            v-if="this.$store.state.ui.filtersChecked.length > 0"
          >
            <div
              class="flex flex-col"
              v-for="filterChecked in this.$store.state.ui.filtersChecked"
              :key="filterChecked.column + filterChecked.condition + filterChecked.conditionValue"
            >
              <template>
                <div
                  class="flex flex-row mb-4"
                  :title="fromFilterHashToText(filterChecked)"
                >
                  <label class="flex w-4/6">
                    <span
                      class="new-toggle-filter-checked text-center"
                    >
                      {{ filterTruncation(filterChecked) }}
                    </span>
                  </label>
                  <i
                    class="flex material-icons visibility-icon w-1/6 cursor-pointer"
                    style="padding-left: 6.43px; padding-top: 3px; text-align: left;"
                    title="Desactivar filtro"
                    @click="disableCheckedFilter(filterChecked)"
                  >visibility</i>
                  <i
                    class="flex material-icons delete-outline-icon w-1/6 cursor-pointer"
                    style="padding-top: 3px; text-align: left;"
                    title="Borrar filtro"
                    @click="deleteCheckedFilter(filterChecked)"
                  >delete_outline</i>
                </div>
              </template>
            </div>
          </div>
          <div
            v-if="this.$store.state.ui.filtersUnchecked.length > 0"
          >
            <div
              class="flex flex-col"
              v-for="filterUnchecked in this.$store.state.ui.filtersUnchecked"
              :key="filterUnchecked.column + filterUnchecked.condition + filterUnchecked.conditionValue"
            >
              <template>
                <div
                  class="flex flex-row mb-4"
                  :title="fromFilterHashToText(filterUnchecked)"
                >
                  <label class="flex w-4/6">
                    <span
                      class="new-toggle-filter-unchecked text-center"
                    >
                      <span class="unchecked-text">
                        {{ filterTruncation(filterUnchecked) }}
                      </span>
                    </span>
                  </label>
                  <i
                    class="flex material-icons visibility-icon w-1/6 cursor-pointer"
                    style="padding-left: 6.43px; padding-top: 3px; text-align: left;"
                    title="Activar filtro"
                    @click="enableUncheckedFilter(filterUnchecked)"
                  >visibility_off</i>
                  <i
                    class="flex material-icons delete-outline-icon w-1/6 cursor-pointer"
                    style="padding-top: 3px; text-align: left;"
                    title="Borrar filtro"
                    @click="deleteUncheckedFilter(filterUnchecked)"
                  >delete_outline</i>
                </div>
              </template>
            </div>
          </div>
        </div>
      </Collapsible>
    </div>
    <div
      class="bg-white border-t flex flex-col"
      style="padding-left: 14.14px;"
    >
      <Collapsible
        class="width-collapsible"
        :is-open="true"
      >
        <div
          slot="trigger"
        >
          <div class="flex items-center">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class="w-2/6 material-icons material-icons-round cursor-pointer"
            >expand_more</i>
          </div>
        </div>
        <div
          slot="closedTrigger"
          class="mb-4"
        >
          <div class="flex items-center ">
            <p class="w-4/6 container-section-title text-base">
              CONTENEDOR
            </p>
            <i
              class=" w-2/6 material-icons material-icons-round cursor-pointer"
            >chevron_right</i>
          </div>
        </div>
        <container-section
          :container="container"
          @sendParamsToAppOptions="containerSectionHandle"
          :has-show-search="true"
        />
      </Collapsible>
    </div>
    <div class="save-toolbar-button-container">
      <div
        v-if="canSave()"
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer"
        @click="saveApp"
      >
        Guardar
      </div>
      <div
        v-else
        class="save-toolbar-button border-t mt-4 p-1 bg-menu-apps rounded-md text-white cursor-pointer
          app-modal__save-btn--disabled"
      >
        Guardar
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import Collapsible from 'vue-collapsible-component';
import api from '../api';
import NewDataSourcesSelector from './new-data-sources-selector';
import VueSelectStandard from './vue-select-standard';
import ColumnsToolbarSection from './columns-toolbar-section';
import googleApiClientMixin from '../mixins/googleApiClientMixin';
import showMessageMixin from '../mixins/showMessageMixin';
import {
  SAVE_CONTAINER, CLOSE_APP_MODAL,
  SELECT_CONTAINER_FOR_EDITION,
  SELECT_CHECKED_FILTER_INDEX_FOR_DELETION,
  SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION,
  UNSELECT_CONTAINER_FOR_EDITION,
  SET_SHOULD_SAVE,
  SET_APP_ATTRIBUTES,
} from '../store/action-types';
import {
  SET_HEADERS_READY,
  SET_SELECTED_DATA_SOURCE,
  REMOVE_FILTER_CHECKED,
  REMOVE_FILTER_UNCHECKED,
  SET_FILTERS_CHECKED,
  SET_FILTERS_UNCHECKED,
  SET_TOOGLE_TOOLBAR_COLLAPSIBLE,
  SET_COLUMN_FIELD,
  SET_COLUMNS_FROM_DOCUMENT,
  SAVE_ALERT,
} from '../store/mutation-types';
import AppTitleInOptions from './app-title-in-options';
import ContainerSection from './container-section';

const DEFAULT_FORMAT_LINE_SPACING = 10;
const DEFAULT_COLUMN_FILTER = { id: -1, name: 'Columna' };
const DEFAULT_FILTER_CONDITION = { id: -1, name: 'Condición' };
const DEFAULT_VALUE_FOR_CONDITION = '';
const DECIMAL_BASE = 10;

export default {
  mixins: [googleApiClientMixin, showMessageMixin],
  components: {
    NewDataSourcesSelector,
    Collapsible,
    VueSelectStandard,
    ColumnsToolbarSection,
    AppTitleInOptions,
    ContainerSection,
  },
  props: {
    showBordersToggle: { type: Boolean, default: true },
    showShadingToggle: { type: Boolean, default: true },
    showSearchToggle: { type: Boolean, default: true },
    showCommentsToggle: { type: Boolean, default: true },
    showPrivateToggle: { type: Boolean, default: true },
    showTitleToggle: { type: Boolean, default: true },
    modalWidth: { type: Number, default: null },
    modalHeight: { type: Number, default: null },
  },
  async mounted() {
    let initialColumns = this.fieldValues.columns;
    if (initialColumns === undefined || initialColumns === 'undefined' || initialColumns.length === 0) {
      initialColumns = this.$store.state.ui.columnsToShow;
    } else {
      this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, initialColumns);
      this.componentKey++;
      this.newDataSourceSelectorKey++;
      this.$store.commit(SET_COLUMN_FIELD, this.getColumnField(initialColumns));
    }
    this.initialColumns = initialColumns;
  },
  watch: {
    shouldSaves(newValue) {
      if (newValue) {
        if (newValue[this.container.id]) {
          this.saveApp();
          this.$store.dispatch(SET_SHOULD_SAVE, { containerId: this.container.id, value: false });
          this.$store.dispatch(UNSELECT_CONTAINER_FOR_EDITION);
        }
      }
    },
  },
  data() {
    this.resetCheckedAndUncheckedFilters();
    const container = this.$store.getters.selectedContainer || this.selectedContainer;
    const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
    const initialFieldValues = this.setInitialFieldValues(container, template);
    const currentDataSource = this.selectedDataSourceHandler(container);
    this.defineFiltersChecked(initialFieldValues);
    this.defineFiltersUnchecked(initialFieldValues);
    let applyFilters = true;
    if (initialFieldValues.applyFilters !== undefined) applyFilters = initialFieldValues.applyFilters;
    let appTableTitle = 'Título de App...';
    if (initialFieldValues.title !== undefined) appTableTitle = initialFieldValues.title;
    let initialColumns = null;
    if (initialFieldValues) {
      initialColumns = initialFieldValues.columns;
      if (initialColumns === undefined || initialColumns === 'undefined' || initialColumns.length === 0) {
        initialColumns = this.$store.state.ui.columnsToShow;
      }
    }

    return {
      fieldValues: initialFieldValues,
      container,
      template,
      tabs: ['Data Sources', 'Custom Fields'],
      selectedTab: 'DataSourcesTab',
      borderColor: _.get(container, 'borderColor', ''),
      containerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      showShading: _.get(container, 'showShading', false),
      showDataSource: _.get(container, 'showDataSource', false),
      showSearch: _.get(container, 'showSearch', false),
      commentsEnabled: _.get(container, 'commentsEnabled', false),
      isPrivate: _.get(container, 'isPrivate', false),
      title: _.get(container, 'title', ''),
      appTableTitle,
      showTitle: _.get(container, 'showTitle', false),
      formatLineSpacing: parseInt(initialFieldValues.format, 10) || DEFAULT_FORMAT_LINE_SPACING,
      columns: [],
      applyFilters,
      columnFilter: DEFAULT_COLUMN_FILTER,
      columnsFilter: [],
      filterCondition: DEFAULT_FILTER_CONDITION,
      filterConditions: [
        { id: 1, name: '=' }, { id: 2, name: '>' }, { id: 3, name: '<' },
        { id: 4, name: '>=' }, { id: 5, name: '<=' },
        { id: 6, name: 'contiene' }, { id: 7, name: 'no contiene' }, { id: 8, name: 'comienza con' },
        { id: 9, name: 'no comienza con' }, { id: 10, name: 'termina con' }, { id: 11, name: 'no termina con' },
      ],
      valueForCondition: DEFAULT_VALUE_FOR_CONDITION,
      columnsReady: false,
      maxLengthForFilterStatement: 24,
      maxLengthOfColumnPartForFilter: 4,
      currentDataSource,
      initialTitle: _.get(container, 'title', ''),
      initialCommentsEnabled: _.get(container, 'commentsEnabled', false),
      initialBorderColor: _.get(container, 'borderColor', ''),
      initialContainerBackgroundColor: _.get(container, 'backgroundColor', '#ffffff'),
      initialIsPrivate: _.get(container, 'isPrivate', false),
      initialShowTitle: _.get(container, 'showTitle', false),
      initialShowShading: _.get(container, 'showShading', false),
      initialShowSearch: _.get(container, 'showSearch', false),
      initialShowDataSource: _.get(container, 'showDataSource', false),
      initialFieldValues: JSON.parse(JSON.stringify(initialFieldValues)),
      initialDataSource: currentDataSource,
      initialAppTableTitle: appTableTitle,
      initialFormatLineSpacing: parseInt(initialFieldValues.format, 10) || DEFAULT_FORMAT_LINE_SPACING,
      initialApplyFilters: applyFilters,
      initialColumns,
      componentKey: 0,
      newDataSourceSelectorKey: 0,
      tooltipF: 'https://docs.google.com/spreadsheets/d/1soD3MGaArhof7lnoh1ErYgmbm6XcqbUpc-MX--r-J7w/edit?usp=sharing',
    };
  },
  computed: {
    filtersCheckedFromStore() {
      return this.$store.state.ui.filtersChecked;
    },
    filtersUncheckedFromStore() {
      return this.$store.state.ui.filtersUnchecked;
    },
    appWithContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        borderColor: this.borderColor,
        backgroundColor: this.containerBackgroundColor,
        isPrivate: this.isPrivate,
        showTitle: this.showTitle,
        showShading: this.showShading,
        showDataSource: this.showDataSource,
        showSearch: this.showSearch,
        ...this.appWithType,
      };
    },
    appWithDataSourceAndContainer() {
      return {
        title: this.title,
        commentsEnabled: this.commentsEnabled,
        borderColor: this.borderColor,
        backgroundColor: this.containerBackgroundColor,
        isPrivate: this.isPrivate,
        showTitle: this.showTitle,
        showShading: this.showShading,
        showDataSource: this.showDataSource,
        showSearch: this.showSearch,
        ...this.appWithDataSourceAndType,
      };
    },
    appWithType() {
      return {
        app: {
          fieldValues: this.fieldValues,
          customAppTemplateId: this.template.id,
          dataSource: this.selectedDataSource,
        },
        appType: 'CustomApp',
      };
    },
    appWithDataSourceAndType() {
      return {
        app: {
          customAppTemplateId: this.template.id,
          dataSource: this.selectedDataSource,
        },
        appType: 'CustomApp',
      };
    },
    ...mapGetters(['selectedView']),
    ...mapState({
      selectedContainerId: state => state.views.selectedContainerId,
      selectedContainer: state => state.views.selectedContainer,
      connections: state => state.views.connections,
      dataSourceModalOpened: state => state.ui.dataSourceModalOpened,
      selectedDataSource: state => state.users.selectedDataSource,
      columnsToShow: state => state.ui.columnsToShow,
      headersReady: state => state.ui.headersReady,
      columnField: state => state.ui.columnField,
      saveAlert: state => state.ui.saveAlert,
      shouldSaves: state => state.views.shouldSaves,
    }),
  },
  methods: {
    headersReadyHandler() {
      if (this.selectedDataSource.id === null) {
        return true;
      }

      return this.headersReady;
    },
    columnsToSave() {
      const keys = Object.keys(this.columnField);
      const columnsToSave = keys.filter(column => this.columnField[column].show) || [];
      if (columnsToSave.length === 0) {
        if ('columns' in this.fieldValues) {
          return this.fieldValues.columns;
        }

        return undefined;
      }

      return columnsToSave;
    },
    setInitialFieldValues(container, template) {
      const initialFieldValues = {};
      template.fields.forEach((field) => {
        initialFieldValues[field.name] = container ? container.app.fieldValues[field.name] : null;
      });

      return initialFieldValues;
    },
    resetCheckedAndUncheckedFilters() {
      this.$store.commit(SET_FILTERS_CHECKED, []);
      this.$store.commit(SET_FILTERS_UNCHECKED, []);
    },
    fromFilterHashToText(filterHash) {
      const text = [filterHash.column, filterHash.condition, filterHash.conditionValue].join(' ');

      return text;
    },
    defineFiltersChecked(initialFieldValues) {
      const filtersChecked = [];
      if (initialFieldValues.filtersChecked !== undefined &&
          initialFieldValues.filtersChecked !== '' &&
          initialFieldValues.filtersChecked.length !== 0
      ) {
        const filtersArray = initialFieldValues.filtersChecked;
        for (const filterText of filtersArray) {
          const filterApplied = filterText.split('|');
          const oldFilter = { column: filterApplied[0],
            condition: filterApplied[1], conditionValue: filterApplied[2],
          };
          filtersChecked.push(oldFilter);
        }
      }
      this.$store.commit(SET_FILTERS_CHECKED, filtersChecked);
    },
    defineFiltersUnchecked(initialFieldValues) {
      const filtersUnchecked = [];
      if (initialFieldValues.filtersUnchecked !== undefined &&
          initialFieldValues.filtersUnchecked !== '' &&
          initialFieldValues.filtersUnchecked.length !== 0
      ) {
        const filtersArray = initialFieldValues.filtersUnchecked;
        for (const filterText of filtersArray) {
          const filterApplied = filterText.split('|');
          const oldFilter = { column: filterApplied[0],
            condition: filterApplied[1], conditionValue: filterApplied[2],
          };
          filtersUnchecked.push(oldFilter);
        }
      }
      this.$store.commit(SET_FILTERS_UNCHECKED, filtersUnchecked);
    },
    toggleCommentsEnabled() {
      this.commentsEnabled = !this.commentsEnabled;
    },
    close() {
      this.$store.dispatch(CLOSE_APP_MODAL);
    },
    reopen() {
      this.$store.dispatch(SELECT_CONTAINER_FOR_EDITION, this.container);
      this.$store.commit(SET_TOOGLE_TOOLBAR_COLLAPSIBLE, false);
    },
    dispatchSaveContainer(params, containerId) {
      return this.$store.dispatch(SAVE_CONTAINER, { params, containerId });
    },
    resetInitialValues3() {
      this.initialBorderColor = this.borderColor;
      this.initialContainerBackgroundColor = this.containerBackgroundColor;
    },
    resetInitialValues2() {
      this.initialIsPrivate = this.isPrivate;
      this.initialShowTitle = this.showTitle;
      this.initialShowShading = this.showShading;
      this.initialShowDataSource = this.selectedContainer.showDataSource;
      this.initialShowSearch = this.showSearch;
      this.initialAppTableTitle = this.appTableTitle;
      this.initialFormatLineSpacing = parseInt(this.formatLineSpacing, DECIMAL_BASE);
      this.initialApplyFilters = this.applyFilters;
    },
    resetInitialValues() {
      this.initialFieldValues = Object.assign({}, this.fieldValues);
      this.initialDataSource = this.selectedDataSource;
      this.initialColumns = this.fieldValues.columns;
      this.initialTitle = this.title;
      this.initialCommentsEnabled = this.commentsEnabled;
      this.resetInitialValues2();
      this.resetInitialValues3();
    },
    async saveApp() {
      if (!this.canSave()) return;

      const fieldsSaved = await this.saveFields();
      const containerSaved = await this.dispatchSaveContainer(this.appWithContainer,
        this.container.id);
      console.log(fieldsSaved);
      console.log(containerSaved);
      this.saveMessage();
      this.newDataSourceSelectorKey += 1;
      this.componentKey += 1;
      this.resetInitialValues();
      this.attrsParams();
    },
    attrsParams() {
      const params = {
        dataSource: this.selectedDataSource,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    saveMessage() {
      this.showMessage(
        'Guardado exitosamente',
      );
    },
    canSave() {
      const canSave = this.requiredAppInputsFilled();
      if (canSave) {
        this.$store.commit(SAVE_ALERT, true);
      } else {
        this.$store.commit(SAVE_ALERT, false);
      }

      return canSave;
    },
    getColumnsCheckedFromColumnField(columnField) {
      const arrayColumnField = Object.entries(columnField).map((e) => ({ [e[0]]: e[1] }));
      const columnsChecked = [];
      arrayColumnField.forEach(columnWithShow => {
        const column = Object.keys(columnWithShow);
        if (columnWithShow[column].show) {
          columnsChecked.push(column[0]);
        }
      });

      return columnsChecked;
    },
    changesToSend() {
      if (
        this.initialTitle !== this.title ||
        this.initialCommentsEnabled !== this.commentsEnabled ||
        this.initialBorderColor.toLowerCase() !== this.borderColor.toLowerCase() ||
        this.initialContainerBackgroundColor.toLowerCase() !== this.containerBackgroundColor.toLowerCase() ||
        this.initialIsPrivate !== this.isPrivate ||
        this.initialShowTitle !== this.showTitle ||
        this.initialShowShading !== this.showShading ||
        this.initialShowDataSource !== this.showDataSource ||
        this.initialShowSearch !== this.showSearch ||
        JSON.stringify(this.initialFieldValues) !== JSON.stringify(this.fieldValues) ||
        JSON.stringify(_.pick(this.initialDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) !==
        JSON.stringify(_.pick(this.selectedDataSource,
          'dataRange', 'dataSourceFilename', 'dataSourceType', 'id', 'name', 'service', 'sourceId')) ||
        this.initialAppTableTitle !== this.appTableTitle ||
        parseInt(this.initialFormatLineSpacing, DECIMAL_BASE) !== parseInt(this.formatLineSpacing, DECIMAL_BASE) ||
        this.initialApplyFilters !== this.applyFilters ||
        JSON.stringify(this.initialColumns) !== JSON.stringify(this.columnsToSave()) ||
        JSON.stringify(this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked)) !==
          JSON.stringify(this.fieldValues.filtersChecked) ||
        JSON.stringify(this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked)) !==
          JSON.stringify(this.fieldValues.filtersUnchecked)
      ) {
        return true;
      }

      return false;
    },
    correctDataRange(dataSourceInAnalysis) {
      if (!['', 'No hay resultados', null].includes(dataSourceInAnalysis.dataRange)) {
        const rangeAnalysis = dataSourceInAnalysis.dataRange.split('!');
        const fromAndToCells = rangeAnalysis[1].split(':');
        if (fromAndToCells[0] === '' || fromAndToCells[1] === '') {
          return false;
        }
      }

      return true;
    },
    requiredAppInputsFilled() {
      let dataSourceDone = false;
      const dataSourceInAnalysis = this.selectedDataSource;
      if (dataSourceInAnalysis !== undefined && dataSourceInAnalysis !== null) {
        const service = dataSourceInAnalysis.service;
        if (['gsheet', 'excel'].includes(service)) {
          if (dataSourceInAnalysis.name !== null &&
              ['table', 'excel'].includes(dataSourceInAnalysis.dataSourceType) &&
              this.correctDataRange(dataSourceInAnalysis) &&
              dataSourceInAnalysis.sourceId !== null) {
            dataSourceDone = true;
          }
        } else if (service === 'custom') {
          if (dataSourceInAnalysis.name !== null &&
              dataSourceInAnalysis.dataSourceType === 'table_internal' &&
              dataSourceInAnalysis.source_id !== null) {
            dataSourceDone = true;
          }
        }
      }

      return dataSourceDone && this.changesToSend();
    },
    filterDefaultState() {
      this.columnFilter = DEFAULT_COLUMN_FILTER;
      this.filterCondition = DEFAULT_FILTER_CONDITION;
      this.valueForCondition = DEFAULT_VALUE_FOR_CONDITION;
    },
    addFilter() {
      if (this.columnFilter.name === 'Columna' ||
          this.filterCondition.name === 'Condición' ||
          this.valueForCondition === '') {
        this.showMessage('Se requiere columna, condición y valor para agregar un nuevo filtro');
      } else {
        const checkedToUpdate = [...this.$store.state.ui.filtersChecked];
        const newFilter = {
          column: this.columnFilter.name,
          condition: this.filterCondition.name,
          conditionValue: this.valueForCondition,
        };
        checkedToUpdate.splice(checkedToUpdate.length, 0, newFilter);
        this.$store.commit(SET_FILTERS_CHECKED, checkedToUpdate);
        this.showMessage('Filtro agregado exitosamente');
        this.filterDefaultState();
        this.fieldValues.filtersChecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked);
      }
    },
    onColumnFilterSelect(value) {
      this.columnFilter = value;
    },
    onConditionFilterSelect(value) {
      this.filterCondition = value;
    },
    firstPossibleText(filter) {
      const columnValue = filter.column;
      const columnPart = columnValue.slice(0, this.maxLengthOfColumnPartForFilter).toUpperCase();
      let spaceOrDots;
      if (columnValue.length < this.maxLengthOfColumnPartForFilter) {
        spaceOrDots = ' ';
      } else {
        spaceOrDots = '... ';
      }
      const text = columnPart.concat(spaceOrDots, filter.condition, ' ', filter.conditionValue);

      return text;
    },
    secondPossibleText(filter, textAndColumnPart) {
      let text = textAndColumnPart[0];
      let spaceOrDots;
      const delta = this.maxLengthForFilterStatement - text.length;
      const columnPart = filter.column.slice(0, delta + this.maxLengthOfColumnPartForFilter).toUpperCase();
      if (delta + this.maxLengthOfColumnPartForFilter > filter.column.length) {
        spaceOrDots = ' ';
      } else {
        spaceOrDots = '... ';
      }
      text = columnPart.concat(spaceOrDots, filter.condition, ' ', filter.conditionValue);

      return text;
    },
    filterTruncation(filter) {
      let text = this.firstPossibleText(filter);
      if (text.length < this.maxLengthForFilterStatement &&
          filter.column.length >= this.maxLengthOfColumnPartForFilter
      ) {
        text = this.secondPossibleText(filter, text);
      }

      return text;
    },
    fromFiltersToFilterField(filters) {
      const filtersAsText = filters.map(filter =>
        [filter.column, filter.condition, filter.conditionValue].join('|'),
      );

      return filtersAsText;
    },
    async createDataSource() {
      const containerSaved = await this.dispatchSaveContainer(this.appWithDataSourceAndContainer,
        this.selectedContainerId);
      console.log(containerSaved);
      const response = await api.getDataSourceValues(this.selectedDataSource.id);
      this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, response.values[0]);
      this.$store.commit(SET_HEADERS_READY, true);

      return response;
    },
    getColumnField(columns) {
      const container = this.$store.getters.selectedContainer;
      const template = this.$store.state.views.selectedCustomAppTemplate || container.app.customAppTemplate;
      const fieldValues = {};
      template.fields.forEach((field) => {
        fieldValues[field.name] = container ? container.app.fieldValues[field.name] : null;
      });
      const columnField = {};
      if (this.currentDataSource === null || fieldValues.columns === undefined || fieldValues.columns.length === 0) {
        columns.forEach(column => {
          columnField[column] = { show: true };
        });
      } else {
        columns.forEach(column => {
          if (fieldValues.columns === undefined) {
            columnField[column] = { show: true };
          } else if (fieldValues.columns.includes(column)) {
            columnField[column] = { show: true };
          } else {
            columnField[column] = { show: false };
          }
        });
      }

      return columnField;
    },
    saveFields2() {
      this.$store.commit(SET_COLUMN_FIELD, this.getColumnField(this.fieldValues.columns));
      this.fieldValues.showSearch = this.showSearch;
      this.fieldValues.format = this.formatLineSpacing;
      this.fieldValues.title = this.appTableTitle;
      this.fieldValues.showTitle = this.showTitle;
      this.fieldValues.filtersChecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked);
      this.fieldValues.filtersUnchecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked);
      this.fieldValues.applyFilters = this.applyFilters;
    },
    async saveFields() {
      if (this.columnsToSave() !== undefined && this.columnsToSave().length > 0) {
        this.fieldValues.columns = this.columnsToSave();
        this.$store.commit(SET_COLUMNS_FROM_DOCUMENT, this.fieldValues.columns);
      } else {
        const response = await this.createDataSource();
        this.fieldValues.columns = response.values[0];
      }
      this.saveFields2();
    },
    getNewFilters(column) {
      let filtersNames = this.$store.state.ui.filtersToShow.map(idName => idName.name);
      if (this.columnField[column].show === true) {
        filtersNames.push(column.toUpperCase());
      } else {
        const filterToDelete = column.toUpperCase();
        filtersNames = filtersNames.filter(e => e !== filterToDelete);
      }
      const newFilters = filtersNames.map((header, index) => ({ id: index, name: header }));

      return newFilters;
    },
    updateFiltersState() {
      this.fieldValues.filtersChecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersChecked);
      this.fieldValues.filtersUnchecked = this.fromFiltersToFilterField(this.$store.state.ui.filtersUnchecked);
    },
    disableCheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersChecked.findIndex(filter => filter === filterApplied);
      this.$store.commit(REMOVE_FILTER_CHECKED, index);
      const uncheckedToUpdate = [...this.$store.state.ui.filtersUnchecked];
      uncheckedToUpdate.splice(uncheckedToUpdate.length, 0, filterApplied);
      this.$store.commit(SET_FILTERS_UNCHECKED, uncheckedToUpdate);
      this.updateFiltersState();
    },
    enableUncheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersUnchecked.findIndex(filter => filter === filterApplied);
      this.$store.commit(REMOVE_FILTER_UNCHECKED, index);
      const checkedToUpdate = [...this.$store.state.ui.filtersChecked];
      checkedToUpdate.splice(checkedToUpdate.length, 0, filterApplied);
      this.$store.commit(SET_FILTERS_CHECKED, checkedToUpdate);
      this.updateFiltersState();
    },
    deleteCheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersChecked.findIndex(filter => filter === filterApplied);
      this.$store.dispatch(SELECT_CHECKED_FILTER_INDEX_FOR_DELETION, { index });
      this.deletingFilter = true;
    },
    deleteUncheckedFilter(filterApplied) {
      const index = this.$store.state.ui.filtersUnchecked.findIndex(filter => filter === filterApplied);
      this.$store.dispatch(SELECT_UNCHECKED_FILTER_INDEX_FOR_DELETION, { index });
      this.deletingFilter = true;
    },
    selectedDataSourceHandler(container) {
      const currentDataSource = this.$store.state.users.selectedDataSource || container.app.dataSource;
      this.$store.commit(SET_SELECTED_DATA_SOURCE, currentDataSource);

      return currentDataSource;
    },
    updateAppAttrs() {
      const params = {
        dataSource: this.selectedDataSource,
        showDataSource: this.showDataSource,
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    updateShowDataSource(value) {
      this.showDataSource = value;
      this.updateAppAttrs();
    },
    containerSectionHandle({ params }) {
      this.containerBackgroundColor = params.containerBackgroundColor;
      this.borderColor = params.borderColor;
      this.showShading = params.showShading;
      this.commentsEnabled = params.commentsEnabled;
      this.isPrivate = params.isPrivate;
      this.showSearch = params.showSearch;
    },
  },
};
</script>
<style scoped>
.app {
  margin-top: 12px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  background: #DDE6F779 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 0px;
  opacity: 1;
}

.icon {
  color: #FAAE56;
  opacity: 1;
  width: 24px;
  height: 24px;
}

.header-text {
  font: normal normal normal 16px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-left: 3px;
  padding-top: 2px;
}

.header-container {
  height: 35.88px;
  display: flex;
  justify-content: left;
  padding-left: 14.14px;
  padding-top: 12px;
}

.format-padding {
  display: flex;
  justify-content: left;
}

.container-section {
  padding-left: 14.14px;
  height: 370px;
}

.font-inter-type-1 {
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  font-family: Inter;
}

.min-h-300px {
  min-height: 300px;
}

.cells {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  width: 61.15px;
  height: 32px;
  margin-right: 17.61px;
}

.format-input {
  outline: 0;
  border-width: 0 0 2px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  border-color: black;
  height: 16px;
  width: 33.65px;
  border-radius: 0px;
  margin-top: 8px;
  margin-left: 18px;
  padding-bottom: 1.5px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.format-icon {
  color: #555859;
  opacity: 1;
}

.visibility-icon {
  color: #7A7A7A;
  opacity: 1;
}

.delete-outline-icon {
  color: #2C6EEB;
  opacity: 1;
}

/* TITLE SECTION STYLE start */
.inter-select-title {
  padding-top: 10px;
}

.input {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 0.5px solid #7A7A7A;
  border-radius: 3px;
  opacity: 1;
  padding-left: 12px;
}

.btn-dimensions {
  height: 32px;
}
/* TITLE SECTION STYLE end */

/* FILTERS SECTION STYLE start*/
.inter-select-title {
  padding-top: 10px;
}

.vue-select-container {
  width: 206.27px;
  height: 42px;
}

.value-input {
  width: 100%;
}

.add-filter {
  text-align: left;
  font: normal normal 600 11px/22px Inter;
  letter-spacing: 0px;
  color: #2C6EEB;
  opacity: 1;
}

.conditions-filter-container {
  width: 130px;
}

.value-filter-container {
  width: 70px;
}

.toggle-filter {
  --width: 200px;
  --height: 32px;

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  opacity: 1;
  cursor: pointer;
}

.toggle-filter input {
  display: none;
}

.toggle-filter .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(191, 203, 217);
  transition: all 0.4s ease-in-out;
}

.toggle-filter .slider::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: calc(var(--height)*0.75);
  height: calc(var(--height)*0.75);
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}

.toggle-filter input:checked+.slider {
  background: #DDE6F7 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.toggle-filter input:checked+.slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle-filter .labels {
  position: absolute;
  top: 3px;
  left: 1px;
  width: 100%;
  height: 100%;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
}

.toggle-filter .labels::after {
  content: attr(data-off);
  position: absolute;
  top: 2px;
  right: 1px;
  text-align: left;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  transition: all 0.4s ease-in-out;
  margin-right: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter .labels::before {
  content: attr(data-on);
  position: absolute;
  top: 2px;
  left: 1px;
  font: normal normal normal 11px/22px Inter;
  color: #555859;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  margin-left: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.toggle-filter input:checked~.labels::after {
  opacity: 0;
}

.toggle-filter input:checked~.labels::before {
  opacity: 1;
}

.new-toggle-filter-checked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #DDE6F7 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 5px;
}

.new-toggle-filter-unchecked {
  --width: 160px;
  --height: 32px;

  width: var(--width);
  height: var(--height);
  border-radius: var(--height);
  border: 0.5px solid #7A7A7A;
  background: #D3D3D3 0% 0% no-repeat padding-box;
  position: relative;
  font: normal normal normal 11px/22px Inter;
  letter-spacing: 0px;
  color: #555859;
  opacity: 1;
  padding-top: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.unchecked-text {
  opacity: 0.18;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
}
/* FILTERS SECTION STYLE end*/

.padding-for-conditions-display {
  padding-bottom: 80px;
}

.normal-padding-for-condition {
  padding-bottom: 30px;
}

</style>
