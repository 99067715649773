<template>
  <div>
    <div
      v-if="dataSource === null"
    >
      <i class="material-icons icon2">{{ appIcons[appType] }}</i>
    </div>
    <div
      v-else-if="searchDone && dataSource && dataFound"
    >
      <div class="flex flex-col">
        <div
          v-if="imageAlignment === 'top'"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': contentAlignment === 'center',
          }"
        >
          <img
            v-if="showImage"
            :src="currentUserData.image"
            class="rounded-circle profile-image"
            alt="Profile pic"
            :class="{
              'image-round' : imageShape === 'round',
              'image-square': imageShape === 'square'
            }"
            style="margin-bottom: 20px;"
          >
        </div>
        <div
          :class="{
            'flex flex-row': ['right', 'left'].includes(imageAlignment),
          }"
        >
          <div
            class="flex"
            v-if="imageAlignment === 'left'"
          >
            <img
              v-if="showImage"
              :src="currentUserData.image"
              class="rounded-circle profile-image"
              alt="Profile pic"
              :class="{
                'image-round' : imageShape === 'round',
                'image-square': imageShape === 'square'
              }"
              style="margin-right: 20px;"
            >
          </div>
          <div
            class="flex flex-col"
            style="justify-content: center; align-items: center;"
          >
            <div
              v-if="showName"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
              style="align-items: center;"
            >
              <i
                v-if="showNameIcon"
                class="material-icons icon name"
              >
                {{ nameIcon }}
              </i>
              <div
                class="name-font"
              >
                {{ currentUserData.name }}
              </div>
            </div>
            <div
              v-if="showProfession"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
              style="align-items: center;"
            >
              <i
                v-if="showProfessionIcon"
                class="material-icons icon"
              >
                {{ professionIcon }}
              </i>
              <div
                class="data-font"
              >
                {{ currentUserData.profession }}
              </div>
            </div>
            <div
              v-if="showEmail"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
              style="align-items: center;"
            >
              <i
                v-if="showEmailIcon"
                class="material-icons icon"
              >
                {{ emailIcon }}
              </i>
              <div
                class="data-font"
              >
                {{ currentUserData.email }}
              </div>
            </div>
            <div
              v-if="showPhone"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
              style="align-items: center;"
            >
              <i
                v-if="showPhoneIcon"
                class="material-icons icon"
              >
                {{ phoneIcon }}
              </i>
              <div
                class="data-font"
              >
                {{ currentUserData.phone }}
              </div>
            </div>
            <div
              v-if="showBirthday"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
              style="align-items: center;"
            >
              <i
                v-if="showBirthdayIcon"
                class="material-icons icon"
              >
                {{ birthdayIcon }}
              </i>
              <div
                class="data-font"
              >
                {{ currentUserData.birthday }}
              </div>
            </div>
            <div
              v-for="(extraColumn, index) in currentUserData.extraColumns"
              :key="`extra-column-${index}`"
              class="flex flex-row"
              :class="{
                'self-start text-left-padding': contentAlignment === 'left',
                'self-end text-right-padding': contentAlignment === 'right',
                'self-center center-elements': contentAlignment === 'center',
              }"
            >
              <div
                v-if="extraColumn.show"
                class="flex flex-row"
                style="align-items: center;"
              >
                <i
                  v-if="extraColumn.showIcon"
                  class="material-icons icon"
                >
                  {{ extraColumn.icon }}
                </i>
                <div
                  v-if="detectURL(extraColumn.value)"
                  class="flex flex-row"
                  style="align-items: center;"
                >
                  <a
                    target="_blank"
                    class="data-font link-style"
                    :href="extraColumn.value">
                    {{ extraColumn.name }}
                  </a>
                </div>
                <div v-else>
                  <div
                    class="data-font"
                  >
                    {{ extraColumn.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex"
            v-if="imageAlignment === 'right'"
          >
            <img
              v-if="showImage"
              :src="currentUserData.image"
              class="rounded-circle profile-image"
              alt="Profile pic"
              :class="{
                'image-round' : imageShape === 'round',
                'image-square': imageShape === 'square'
              }"
              style="margin-left: 20px;"
            >
          </div>
        </div>
        <div
          v-if="imageAlignment === 'bottom'"
          :class="{
            'self-start icon-left-padding': contentAlignment === 'left',
            'self-end icon-right-padding': contentAlignment === 'right',
            'self-center center-elements': contentAlignment === 'center',
          }"
        >
          <img
            v-if="showImage"
            :src="currentUserData.image"
            class="rounded-circle profile-image"
            alt="Profile pic"
            :class="{
              'image-round' : imageShape === 'round',
              'image-square': imageShape === 'square'
            }"
            style="margin-top: 20px;"
          >
        </div>
      </div>
    </div>
    <div
      v-else
    >
      <!-- EN ESTE CASO NO SE MOSTRARÁ NADA -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import _ from 'lodash';
import api from '../api';
import { APP_ICONS } from '../constants';
import {
  SET_DATA_SOURCE_UPDATED,
} from '../store/mutation-types';
import {
  SET_APP_ATTRIBUTES,
} from '../store/action-types';

const DECIMAL_BASE = 10;
const DEFAULT_IMAGE_SIZE = 120.67;

export default {
  props: {
    container: { type: Object, default: null },
    editable: { type: Boolean, default: false },
  },
  data() {
    this.defineProfileAppAttrs();

    return {
      searchDone: false,
      dataFound: false,
      currentUserData: null,
      systemFilter: _.get(this.container, 'app.systemFilter', 'user_email'),
      extraColumns2: [],
      appIcons: APP_ICONS,
      appType: _.get(this.container, 'appType', null),
    };
  },
  async mounted() {
    if (this.dataSource) {
      const profileData = await this.getDataSourceValues(this.dataSource);
      this.dataFound = profileData[0];
      this.currentUserData = profileData[1];
    }
    this.searchDone = true;
    this.setImageScale();
  },
  async updated() {
    this.setImageScale();
    if (this.dataSource && this.dataSourceUpdated) {
      this.systemFilter = _.get(this.container, 'app.systemFilter', 'user_email');
      const profileData = await this.getDataSourceValues(this.dataSource);
      this.dataFound = profileData[0];
      this.currentUserData = profileData[1];
      this.$store.commit(SET_DATA_SOURCE_UPDATED, false);
    }
  },
  computed: {
    ...mapState({
      appAttrs: state => state.views.appsAttrs,
      dataSourceUpdated: state => state.views.dataSourceUpdated,
    }),
    sheetColumns() {
      const firstAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
      const secondAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
      const extendedAlphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

      for (const firstLetter of firstAlphabet) {
        if (firstLetter === 'd') {
          break;
        }
        for (const secondLetter of secondAlphabet) {
          extendedAlphabet.push(firstLetter.concat(secondLetter));
        }
      }

      return extendedAlphabet;
    },
    imageCell() {
      return this.appAttrs[this.container.id].imageCell;
    },
    nameCell() {
      return this.appAttrs[this.container.id].nameCell;
    },
    professionCell() {
      return this.appAttrs[this.container.id].professionCell;
    },
    emailCell() {
      return this.appAttrs[this.container.id].emailCell;
    },
    phoneCell() {
      return this.appAttrs[this.container.id].phoneCell;
    },
    birthdayCell() {
      return this.appAttrs[this.container.id].birthdayCell;
    },
    dataSource() {
      return _.get(this.container, 'app.dataSource', null);
    },
    currentUserEmail() {
      return _.get(this.$store.getters.currentUser, 'email');
    },
    imageShape() {
      return this.appAttrs[this.container.id].imageShape;
    },
    imageAlignment() {
      return this.appAttrs[this.container.id].imageAlignment;
    },
    contentAlignment() {
      return this.appAttrs[this.container.id].contentAlignment;
    },
    showDataSource() {
      return this.appAttrs[this.container.id].showDataSource;
    },
    googleSheetUrl() {
      return `https://docs.google.com/spreadsheets/d/${this.dataSource.sourceId}`;
    },
    nameIcon() {
      return this.appAttrs[this.container.id].nameIcon;
    },
    professionIcon() {
      return this.appAttrs[this.container.id].professionIcon;
    },
    emailIcon() {
      return this.appAttrs[this.container.id].emailIcon;
    },
    phoneIcon() {
      return this.appAttrs[this.container.id].phoneIcon;
    },
    birthdayIcon() {
      return this.appAttrs[this.container.id].birthdayIcon;
    },
    iconsScale() {
      return this.appAttrs[this.container.id].iconsScale;
    },
    showName() {
      return this.appAttrs[this.container.id].showName;
    },
    showProfession() {
      return this.appAttrs[this.container.id].showProfession;
    },
    showEmail() {
      return this.appAttrs[this.container.id].showEmail;
    },
    showPhone() {
      return this.appAttrs[this.container.id].showPhone;
    },
    showBirthday() {
      return this.appAttrs[this.container.id].showBirthday;
    },
    showImage() {
      return this.appAttrs[this.container.id].showImage;
    },
    showNameIcon() {
      return this.appAttrs[this.container.id].showNameIcon;
    },
    showProfessionIcon() {
      return this.appAttrs[this.container.id].showProfessionIcon;
    },
    showEmailIcon() {
      return this.appAttrs[this.container.id].showEmailIcon;
    },
    showPhoneIcon() {
      return this.appAttrs[this.container.id].showPhoneIcon;
    },
    showBirthdayIcon() {
      return this.appAttrs[this.container.id].showBirthdayIcon;
    },
    imageScale() {
      return this.appAttrs[this.container.id].iconsScale;
    },
    extraColumns() {
      return this.appAttrs[this.container.id].extraColumns;
    },
  },
  methods: {
    defineProfileAppAttrs() {
      const params = {
        imageCell: _.get(this.container, 'app.columnsData.cells.image', 'B'),
        nameCell: _.get(this.container, 'app.columnsData.cells.name', 'C'),
        professionCell: _.get(this.container, 'app.columnsData.cells.profession', 'D'),
        emailCell: _.get(this.container, 'app.columnsData.cells.email', 'E'),
        phoneCell: _.get(this.container, 'app.columnsData.cells.phone', 'F'),
        birthdayCell: _.get(this.container, 'app.columnsData.cells.birthday', 'G'),
        nameIcon: _.get(this.container, 'app.columnsData.icons.name', 'person'),
        professionIcon: _.get(this.container, 'app.columnsData.icons.profession', 'work'),
        emailIcon: _.get(this.container, 'app.columnsData.icons.email', 'email'),
        phoneIcon: _.get(this.container, 'app.columnsData.icons.phone', 'phone'),
        birthdayIcon: _.get(this.container, 'app.columnsData.icons.birthday', 'cake'),
        showNameIcon: _.get(this.container, 'app.columnsData.showIcons.name', true),
        showProfessionIcon: _.get(this.container, 'app.columnsData.showIcons.profession', true),
        showEmailIcon: _.get(this.container, 'app.columnsData.showIcons.email', true),
        showPhoneIcon: _.get(this.container, 'app.columnsData.showIcons.phone', true),
        showBirthdayIcon: _.get(this.container, 'app.columnsData.showIcons.birthday', true),
        showImage: _.get(this.container, 'app.columnsData.shows.image', true),
        showName: _.get(this.container, 'app.columnsData.shows.name', true),
        showProfession: _.get(this.container, 'app.columnsData.shows.profession', true),
        showEmail: _.get(this.container, 'app.columnsData.shows.email', true),
        showPhone: _.get(this.container, 'app.columnsData.shows.phone', true),
        showBirthday: _.get(this.container, 'app.columnsData.shows.birthday', true),
        dataSource: _.get(this.container, 'app.dataSource', null),
        showDataSource: _.get(this.container, 'showDataSource', false),
        imageShape: _.get(this.container, 'app.imageShape', null),
        imageAlignment: _.get(this.container, 'app.imageAlignment', null),
        contentAlignment: _.get(this.container, 'app.contentAlignment', null),
        iconsScale: _.get(this.container, 'app.iconsScale', null),
        extraColumns: _.get(this.container, 'app.columnsData.extraColumns', []),
      };
      this.$store.dispatch(SET_APP_ATTRIBUTES, { containerId: this.container.id, params });
    },
    findProfileDataByEmail(dataSourceValues, rangeIndexes) {
      const emailsInDataSource = dataSourceValues.map((row) => {
        const email = row[rangeIndexes.email];

        return email;
      });
      const indexOfCurrentUserEmail = emailsInDataSource.indexOf(this.currentUserEmail);
      if (indexOfCurrentUserEmail === -1) {
        return [false, null];
      }

      return [true,
        {
          image: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.image],
          name: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.name],
          profession: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.profession],
          email: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.email],
          phone: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.phone],
          birthday: dataSourceValues[indexOfCurrentUserEmail][rangeIndexes.birthday],
          extraColumns: this.findExtraColumnsData(dataSourceValues, indexOfCurrentUserEmail, rangeIndexes),
        }];
    },
    defineMinIndex(sheetColumns, rangeIndexes) {
      let min;
      for (const extraColumn of this.extraColumns) {
        const indexOfExtraColumn = sheetColumns.indexOf(extraColumn.cell.toLowerCase());
        min = Math.min(...[rangeIndexes.min, indexOfExtraColumn]);
      }

      return min;
    },
    findExtraColumnsData(dataSourceValues, indexOfCurrentUserEmail, rangeIndexes) {
      if (this.extraColumns.length > 0) {
        this.extraColumns2 = [];
        const sheetColumns = this.sheetColumns;
        const min = this.defineMinIndex(sheetColumns, rangeIndexes);
        for (const extraColumn of this.extraColumns) {
          const indexOfExtraColumn = sheetColumns.indexOf(extraColumn.cell.toLowerCase()) - min;
          this.extraColumns2.push({
            show: extraColumn.show,
            showIcon: extraColumn.showIcon,
            icon: extraColumn.icon,
            value: dataSourceValues[indexOfCurrentUserEmail][indexOfExtraColumn],
            name: extraColumn.name,
          });
        }
      }

      return this.extraColumns2;
    },
    async getDataSourceValues(dataSource) {
      const response = await api.getDataSourceValues(dataSource.id);
      const dataSourceValues = response.values;
      const rangeIndexes = this.rangeIndexesDefinition();
      let profileData = [false, null];
      if (rangeIndexes) {
        if (this.systemFilter === 'user_email') {
          profileData = this.findProfileDataByEmail(dataSourceValues, rangeIndexes);
        }
      }

      return profileData;
    },
    rangeIndexesDefinition() {
      const sheetColumns = this.sheetColumns;
      const indexOfImage = sheetColumns.indexOf(this.imageCell.toLowerCase());
      const indexOfName = sheetColumns.indexOf(this.nameCell.toLowerCase());
      const indexOfProfession = sheetColumns.indexOf(this.professionCell.toLowerCase());
      const indexOfEmail = sheetColumns.indexOf(this.emailCell.toLowerCase());
      const indexOfPhone = sheetColumns.indexOf(this.phoneCell.toLowerCase());
      const indexOfBirthday = sheetColumns.indexOf(this.birthdayCell.toLowerCase());

      const min = Math.min(...[indexOfImage, indexOfName, indexOfProfession,
        indexOfEmail, indexOfPhone, indexOfBirthday]);

      return {
        image: indexOfImage - min,
        name: indexOfName - min,
        profession: indexOfProfession - min,
        email: indexOfEmail - min,
        phone: indexOfPhone - min,
        birthday: indexOfBirthday - min,
        min,
      };
    },
    setImageScale() {
      const factor = parseFloat(this.imageScale, DECIMAL_BASE)/100;
      $('.profile-image').css({
        'width': `${factor * DEFAULT_IMAGE_SIZE}px`,
        'height': `${factor * DEFAULT_IMAGE_SIZE}px`,
      });
    },
    detectURL(url) {
      const parts = (url).toString().split('.');
      if (parts[0].indexOf('http') >= 0 || parts[0].includes('active_storage')) return true;

      return false;
    },
  },
};
</script>

<style scoped>
.data-font {
  text-align: center;
  font: normal normal normal 11px/25px Inter;
  letter-spacing: 0px;
  color: #7A7A7A;
  opacity: 1;
  height: 14px;
}

.name-font {
  text-align: center;
  font: normal normal medium 13px/25px Inter;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  height: 16px;
}

.image-round {
  border-radius: 50%;
}

.image-square {
  border-radius: 3px;
}

.profile-image {
  object-fit: cover;
  height: 120.67px;
  width: 120.67px;
}

.data-source-link {
  text-align: center;
  font: normal normal normal 12px/22px Inter;
  letter-spacing: 0px;
  color: #0031FF;
  opacity: 1;
  height: 34px;
  position: absolute;
  top: 5px;
  right: 0;
  margin-right: 1rem;
}

.icon {
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;
  color: #7A7A7A;
}

.name {
  font-size: 18px;
  margin-top: 6.5px;
  color: black;
}

.icon2 {
  color: #FAAE56 !important;
  opacity: 1;
  font-size: 70px !important;
}

.link-style {
  color: #0031FF;
  opacity: 1;
  text-decoration: underline;
}
</style>
