<template>
  <div
    @click.stop="open = true"
    class="height-32 w-208px flex absolute header-position items-center bg-buttom-primary text-settings-selected
           cursor-pointer border-t border-workspace rounded-md shadow-md"
  >
    <div class="w-10/12 overflow-hidden">
      <p class="py-1 px-4 truncate font-14">
        {{ currentWorkspace.name }}
      </p>
    </div>
    <div
      class="w-2/12 py-2 cursor-pointer overflow-hidden"
    >
      <span class="flex align-center">
        <i class="material-icons material-icons--primary">keyboard_arrow_down</i>
      </span>
    </div>

    <div
      id="switcher"
      ref="dropDown"
      v-if="open"
      class="width-240 absolute z-300 bg-white items-center cursor-pointer shadow-md"
      :class="{
        'dropdown': expandTo === 'top',
        'dropup': expandTo === 'bottom'
      }"
    >
      <div
        :key="ws.id"
        v-for="ws in displayableWorkspace"
        class="flex border-b border-workspace cursor-pointer height-42 items-center w-full hover:bg-menu-hover"
        :class="{'bg-gray-100': currentWorkspace.id !== ws.id}"
        @click="goToWorkspace(ws.id)"
      >
        <div class="w-12/12">
          <p
            class="py-1 px-4 truncate font-14  "
            :class="{
              'text-settings-selected': currentWorkspace.id === ws.id,
              'text-label': currentWorkspace.id !== ws.id,
              'font-semibold': !ws.id
            }"
          >
            {{ ws.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import escapeMixinHandler from '../mixins/escapeMixinHandler';
import clickHandler from '../mixins/clickMixinHandler';

export default {
  mixins: [escapeMixinHandler, clickHandler],
  props: {
    expandTo: { type: String, default: 'bottom' },
    workspaces: { type: Array, required: true },
    currentWorkspace: { type: Object, required: true },
    isPlatformOwner: { type: Boolean, default: false },
    isDarkSolid: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
  },
  computed: {
    displayableWorkspace() {
      return this.workspaces;
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    goToWorkspace(workspaceId) {
      if (!workspaceId) {
        window.location.href = '/manage-workspaces';

        return;
      }
      window.location.href = `/workspace/switch?workspace_id=${workspaceId}`;
    },
  },

};
</script>
<style scoped>
.height-57 {
  height: 57px;
}

.height-32 {
  height: 32px;
}

.height-25px {
  height: 25px;
}

.width-32 {
  width: 32px;
}

.width-240 {
  width: 202px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.dropdown {
  bottom: 0;

}

.dropup {
  top: 32px;
}

.w-208px {
  width: 208px;
}

.header-position {
  top: 20%;
  left: 220px;

}

.height-42 {
  height: 42px;

}
</style>
